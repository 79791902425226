//#region Imports

//#region Angular Imports
import { Component, ViewChild } from "@angular/core";
//#endregion Angular Imports

//#region React Imports
import { AppRegistry } from "react-native-web";
//#endregion React Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from "rxjs";
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { reactRendererWrapper } from "./react-renderer-wrapper.component";
//#endregion Component Imports

//#region Providers Imports
import { DataService } from "@Globals/providers/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { EventManagerService } from "./providers/event-manager.service";
//#endregion Providers Imports

//#region Other Imports
import { REACT_ROUTE_PACKAGE_MAP } from "./constants";
import {
  MEMBERSHIP_CACHE_TYPES,
  CALENDAR_EXTERNAL,
  REACT_WIDGET_MAP,
} from "@Globals/constants/react/widget-map.constants";
import { ApolloClientService } from "./providers/apollo-cache.service";
import { AuthService } from "@Globals/providers/auth.service";
import { MasterDataService } from "@Globals/providers/master-data.service";
import { ShoppingCreditsServices } from "@Globals/providers/shopping-credits.service";
import { environment } from "@Env/environment";

//#endregion Other Imports

//#endregion Imports

// import() returns a Promise

export function normalizeCommonJSImport(
  importPromise: Promise<any>
): Promise<any> {
  // CommonJS's `module.exports` is wrapped as `default` in ESModule.
  return importPromise.then((m: any) => m.default || m);
}

const importReactPackage = (packageName) =>
  normalizeCommonJSImport(REACT_ROUTE_PACKAGE_MAP[packageName]());

declare global {
  interface Window {
    global: any;
  }
}

@Component({
  selector: "mbw-react-host",
  templateUrl: "./react-renderer.component.html",
  styleUrls: ["./react-renderer.component.scss"],
})
export class ReactRendererComponent {
  @ViewChild("reactHost") reactHost;

  subscriptions: Subscription[] = [];
  viewEnteredOnce = false;
  componentName;
  packageImportSub;
  isHome = false;

  constructor(
    public route: ActivatedRoute,
    public eventManager: EventManagerService,
    public apolloClient: ApolloClientService,
    public dataService: DataService,
    public authService: AuthService,
    public masterDataService: MasterDataService,
    public shoppingCreditsService: ShoppingCreditsServices,
    private router: Router,
  ) {
    const routeData = this.route.snapshot.data;
    this.loadReactPackage(routeData);
    window.global = window.global || window;
    this.setSubscriptionForPageReloads(routeData);
    this.handleAppSubscriptions(routeData);
  }

  ngOnInit() {
    // this.initializeEnvVariables();
    /* this.apolloClient.configureApolloClient(); */
  }

  initializeEnvVariables() {
    let processKey = "process";
    let envKey = "env";
    if (!window[processKey]) {
      window[processKey] = window[processKey] || {};
      window[processKey][envKey] = window[processKey][envKey] || {};
    }
    let reactVariables = environment.reactProcessVars;
    for (let i in reactVariables) {
      if (!window[processKey][envKey][i]) {
        window[processKey][envKey][i] = reactVariables[i];
      }
    }
  }

  setSubscriptionForPageReloads(routeData) {
    let membershipSub = this.dataService.runChangeDetForMembership.subscribe(
      () => {
        if (this.componentName === REACT_WIDGET_MAP.USER_MENU) {
          this.loadReactPackage(routeData);
          this.runReactApplication(false);
        }
      }
    );
    this.subscriptions.push(membershipSub);
    let valuePassSub = this.dataService.runChangeDetForMBPass.subscribe(() => {
      if (
        this.componentName === REACT_WIDGET_MAP.PRODUCT_CATEGORY ||
        this.componentName === REACT_WIDGET_MAP.HOME
      ) {
        this.loadReactPackage(routeData);
        this.runReactApplication(false);
      }
    });
    this.subscriptions.push(valuePassSub);

    let userInfoSub = this.dataService.runChangeDetForUserInfo.subscribe(() => {
      if (this.componentName === REACT_WIDGET_MAP.USER_MENU) {
        this.apolloClient.evictUserDetailsCache();
        this.loadReactPackage(routeData);
        this.runReactApplication(false);
      }
    });
    this.subscriptions.push(userInfoSub);
    // let walletSub = this.masterDataService.refreshBalance.subscribe(() => {
    //   if(this.componentName === REACT_WIDGET_MAP.SHOPPING_CREDIT) {
    //     this.loadReactPackage(routeData);
    //     this.runReactApplication(false);
    //   }
    // });
    // this.subscriptions.push(walletSub);

    let membershipStripSub = this.dataService.refetchMembershipStrip.subscribe(
      (res) => {
        if (res === MEMBERSHIP_CACHE_TYPES.ALL) {
          this.apolloClient.evictMembershipStripCache();
        } else if (res === MEMBERSHIP_CACHE_TYPES.USER) {
          this.apolloClient.evictUserDetailsCache();
        } else if (res === MEMBERSHIP_CACHE_TYPES.BASKET) {
          this.apolloClient.handleBasketUpdates();
        }
      }
    );
    this.subscriptions.push(membershipStripSub);

    let logoutSub = this.authService.isAuthenticated.subscribe((res) => {
      if (this.componentName === REACT_WIDGET_MAP.HOME && !res) {
        this.loadReactPackage(routeData);
        this.runReactApplication(false);
      }
    });
    this.subscriptions.push(logoutSub);

    let mbPassSub = this.dataService.refreshMbPass.subscribe((res) => {
      if (res) {
        this.apolloClient.evictValuePassCache();
      }
    });
    this.subscriptions.push(mbPassSub);
  }

  handleAppSubscriptions(routeData) {
    this.dataService.runChangeDetForA2B.subscribe((res) => {
      if (
        this.componentName === REACT_WIDGET_MAP.RECYCLE_BAG ||
        this.componentName === REACT_WIDGET_MAP.RECYCLE_CARTON
      ) {
        this.apolloClient.evictRecycleBagCache();
      }
    });
    if (
      this.componentName === REACT_WIDGET_MAP.SHOPPING_CREDIT ||
      this.componentName === REACT_WIDGET_MAP.VALUE_PASS ||
      this.componentName === REACT_WIDGET_MAP.PROFILE
    ) {
      this.masterDataService.refreshBalance.subscribe((res) => {
        if (res) {
          this.apolloClient.handlePaymentTransactionEffects(res);
        }
      });
    }
    this.dataService.runChangeDetForA2B.subscribe((res) => {
      this.apolloClient.handleBasketUpdates();
      if (this.componentName === REACT_WIDGET_MAP.SUBSCRIPTION_ORDERS) {
        this.apolloClient.evictSubscriptionOrdersCache();
      } 
    });
    this.dataService.runChangeDetForBasketUpdate.subscribe((res) => {
      if (res) {
        this.apolloClient.handleBasketUpdates();
      }
    });

    this.dataService.recurringOrdersChange.subscribe((res) => {
      this.apolloClient.handleRecurringOrdersChange();
      this.apolloClient.evictFutureBaskets();
    });

    this.dataService.runChangeDetectionForProductOrders.subscribe((res) => {
      this.apolloClient.handleFutureProductOrder(res);
    });
    this.dataService.refreshFutureBaskets.subscribe((res) => {
      if (res) {
        this.apolloClient.evictFutureBaskets();
      }
    });
  }
  loadReactPackage({ componentName }: any) {
    this.componentName = componentName;

    const componentProps = {
      data: {
        ...this.route.snapshot.params,
        ...this.dataService.paramData
        // some object to pass down some data
      },
      onChange: this.onChange.bind(this),
    };
    if (componentName === REACT_WIDGET_MAP.SHOPPING_CREDIT) {
      this.shoppingCreditsService.checkJiopaySegment();
      if (this.dataService.openPromoModal) {
        componentProps.data.showInviteModal = this.dataService.openPromoModal;
        setTimeout(() => {
          this.dataService.openPromoModal = false;
        }, 1000);
      }
    }

    if (componentName === REACT_WIDGET_MAP.PAYMENT_METHOD) {
      componentProps.data.shoppingCreditAmount = this.dataService.paramData?.shoppingCreditAmount;
      componentProps.data.trackPayment = this.dataService.paramData?.trackPayment;
    }

    if (componentName === REACT_WIDGET_MAP.CALENDAR_JOURNEY) {
      this.dataService.externalUrl.title = CALENDAR_EXTERNAL.FAQS;
      this.dataService.externalUrl.url = CALENDAR_EXTERNAL.URL;
    }

    if (componentName === REACT_WIDGET_MAP.FRAUD_USER) {
      const paramOptions = JSON.parse(
        JSON.stringify(this.dataService.paramData)
      );
      if (paramOptions && paramOptions?.errorType && paramOptions?.errorData) {
        componentProps.data = {
          ...componentProps.data,
          errorType: paramOptions.errorType,
          errorData: paramOptions.errorData,
        };
        delete this.dataService.paramData.errorType;
        delete this.dataService.paramData.errorData;
      }
    }

    if (componentName === REACT_WIDGET_MAP.EXTERNAL) {
      let paramOptions = this.dataService.paramData;
      if (!paramOptions?.url && this.dataService.externalUrl?.url) {
        paramOptions = this.dataService.externalUrl;
      }
      if (paramOptions && paramOptions?.url) {
        componentProps.data = {
          ...componentProps.data,
          testID: "external",
          url: paramOptions?.url,
          title: paramOptions?.title,
        };
        delete this.dataService.paramData?.url;
        delete this.dataService.paramData?.title;
        delete this.dataService.paramData?.replaceUrl;
      }
    }

    if (componentName === REACT_WIDGET_MAP.FAQ) {
      componentProps.data.faqID = this.route.snapshot.params.faqType;
      componentProps.data.showFaqModal = this.dataService.openFaqModal;
      setTimeout(() => {
        this.dataService.openFaqModal = false;
      }, 1000);
    }

    if (componentName === REACT_WIDGET_MAP.COLLECTION_DASHBOARD) {
      componentProps.data.locationTypeId = parseInt(
        this.route.snapshot.params.locationType,
        10
      );
    }
    if (componentName === REACT_WIDGET_MAP.CATEGORY_PRODUCT_LIST) {
      componentProps.data.categoryId = +this.route.snapshot.params.categoryId;
      componentProps.data.subCategoryId =
        +this.route.snapshot.params.subcategoryId;
      componentProps.data.source = this.route.snapshot.params.source;
    }
    if (componentName === REACT_WIDGET_MAP.BANNER_PRODUCT_LIST) {
      componentProps.data.id = +this.route.snapshot.params.bannerId;
      componentProps.data.listId = +this.route.snapshot.params.listId;
      componentProps.data.index = +this.route.snapshot.params.bannerIndex;
    }
    if (componentName === REACT_WIDGET_MAP.PRODUCT_LAUNCH_LIST) {
      const source = this.route.snapshot.params.source;
      if (source) {
        componentProps.data.source = source;
      }
    }
    if (componentName === REACT_WIDGET_MAP.SPONSORED_PRODUCT_LIST) {
      componentProps.data.listId = +this.route.snapshot.params.sponsoredListId;
    }
    if (componentName === REACT_WIDGET_MAP.CONTACT_US) {
      componentProps.data.isBot = this.dataService.paramData.isBot;
      componentProps.data.accountClosureStatus =
      this.dataService.paramData.accountClosureStatus;
      componentProps.data.issueId = this.dataService.paramData.issueId;
    }

    this.packageImportSub = importReactPackage(this.componentName);
    this.packageImportSub.then((args) => {
      let reactComponent = args[this.componentName];
      try {
        this.preDispatchComponent(this.componentName, reactComponent, args);
      } catch (e) {}
      AppRegistry.registerComponent(this.componentName, () =>
        reactRendererWrapper(
          reactComponent,
          componentProps,
          this.eventManager,
          this.apolloClient.client,
          this.authService,
          this.isHome
        )
      );
    });
  }

  async ionViewDidEnter() {
    if (this.viewEnteredOnce) {
      return;
    }
    if(this.router.url.includes("/home") && !this.router.url.includes("/home-")){
      this.isHome = true
    }
    else{
      this.isHome = false  
    }
    await this.runReactApplication(true);
  }

  async runReactApplication(withDelay = true) {
    if (withDelay && this.componentName !== REACT_WIDGET_MAP.USER_MENU) {
      await new Promise((res) => setTimeout(res, 100));
    }
    this.packageImportSub.then((args) => {
      AppRegistry.runApplication(this.componentName, {
        rootTag: this.reactHost.nativeElement,
      });
      this.viewEnteredOnce = true;
    });
  }

  onChange($event) {
    // dummy implementation
  }

  preDispatchComponent(componentName, Component, ModuleImports) {
    this.handleCacheEvictions(componentName, Component, ModuleImports);
    // else if (componentName === REACT_WIDGET_MAP.PRODUCT_CATEGORY) {
    //   this.setupUserAddressCache(Component, ModuleImports);
    // }
    /* else if (componentName === REACT_WIDGET_MAP.VALUE_PASS) {
      this.setupValuePassCache(Component, ModuleImports);
    } */
  }

  handleCacheEvictions(componentName, Component, ModuleImports) {
    this.handleUserDetailsCacheEvictions(
      componentName,
      Component,
      ModuleImports
    );
    this.handleUserAddressCacheEviction(
      componentName,
      Component,
      ModuleImports
    );
    if (
      componentName === REACT_WIDGET_MAP.PRODUCT_CATEGORY ||
      componentName === REACT_WIDGET_MAP.VALUE_PASS
    ) {
      this.handleValuePassCacheEviction(
        componentName,
        Component,
        ModuleImports
      );
    }
  }

  handleUserDetailsCacheEvictions(componentName, Component, ModuleImports) {
    const runChangeDetForAddressChangeSubscription =
      this.dataService.runChangeDetForAddressChange.subscribe((response) => {
        if (response) {
          this.apolloClient.evictSubscriptionCache();
          this.apolloClient.evictUserDetailsCache();
        }
      });
    this.subscriptions.push(runChangeDetForAddressChangeSubscription);
  }

  handleValuePassCacheEviction(componentName, Component, ModuleImports) {
    this.dataService.runChangeDetForMBPass.subscribe((success) => {
      if (success) {
        this.apolloClient.evictValuePassCache();
      }
    });
  }

  handleUserAddressCacheEviction(componentName, Component, ModuleImports) {
    const runChangeDetForAddressChangeSubscription =
      this.dataService.runChangeDetForAddressChange.subscribe((response) => {
        if (response) {
          this.apolloClient.evictUserAddressCache();
        }
      });
    this.subscriptions.push(runChangeDetForAddressChangeSubscription);
  }

  /* setupValuePassCache(Component, ModuleImports) {} */

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
