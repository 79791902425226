//#region Imports

//#region Angular Imports
import { Injectable } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
//#endregion Ionic Imports

//#region Library Imports
import { Observable } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports

//#endregion Other Imports
//#endregion Importsimport { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InAppBrowserService {
  constructor(private iab: InAppBrowser) { }
  public browser;

  create(url: string, hidden = false, showToolbar = false, target = '_blank') {
    let options = {};
    if (hidden) {
      options = { location: 'no', toolbar: 'no', hidden: 'yes' };
    } else {
      options = { location: 'no', toolbar: showToolbar ? 'yes' : 'no' };
    }
    this.browser = this.iab.create(url, target, options);
  }
  open(url: string) {
    this.browser = this.iab.create(url);
  }
  close() {
    if (this.browser) {
      this.browser.close();
    }
  }
  show() {
    if (this.browser) {
      this.browser.show();
    }
  }
  openPaymentUrl(url: string) {
    this.browser = this.iab.create(url);
  }

  eventSubscription(event: string): Observable<object> {
    return this.browser.on(event);
  }
}
