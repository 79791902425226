import { InfoPopupData } from "../interfaces/core-interfaces";

export const EVENT_NAMES = {
  SIGNUP_SPLASHSCREEN_VIEWED: 'Sign up - Splash Screen Viewed',
  SHOPPING_CREDIT_MENU: 'Shopping Credits',
  SIGNUP_START: 'Signup Start',
  LOGIN_START: 'Login Start',
  WANT_TO_EXPLORE: 'Want to explore Start',
  REFERRAL_START: 'Referral-Start',
  ACCOUNT_HISTORY: 'Account History',
  SUPERCHARGE_DISCOVERY_PAGE: 'SuperCharge Discovery page',
  SUPERCHARGE_EXPIRED_PAGE: 'SuperCharge Expired page',
  STORAGE_FREE_SPACE: 'Storgae Free Space',
  COLLECTION_LSITING: 'Collection Listing',
  OFFER_CLICKED: 'Offer Clicked',
  MBEYOND_CLICKED: 'Mbeyond Clicked',
  CALENDAR_MY_CALENDAR: 'Calendar - My Calendar',
  MY_PROFILE: 'My Profile',
  CONTACT_US: 'Self Help Open',
  HAMBURGER_OPEN: 'Hamburger Open',
  APP_OPENED: 'App Opened',
  HOLIDAY_CLICKED: 'Holiday Clicked',
  OTP_TRIGGER: 'OTP trigger',
  LOCATION_SCREEN_VISIT: 'Enable location screen visit',
  ENABLE_LOCATION_CLICKED: 'Click on Enable location',
  LOCATION_ALLOWED: 'Click on location allow',
  LOCATION_DENIED: 'Click on location deny',
  UPDATE_ADDRESS: 'Click on update address',
  GET_STARTED: 'Click on get started',
  GENERATE_SIGN_UP_OTP: 'Generate Sign-up otp',
  GENERATE_LOGIN_OTP: 'Generate login otp',
  CLICK_ON_TNC: 'Click on Terms of Service',
  CLICK_ON_RECYCLE_BAGS_MENU: 'Click on Recycle Bags Menu',
  CODE_PUSH_DOWNLOADED: 'Code push success',
  CODE_PUSH_ERROR: 'Code push error',
  APP_TRYING_TO_LOGOUT: 'App trying to logout',
  CAMPAIGN_APP_OPENED: 'Campaign App Opened',
  CODE_PUSH_STARTED: 'Code push started',
  CODE_PUSH_NOT_AVAILABLE: 'Code push not available',
  CODE_PUSH_FAILED: 'Code push failed',
  SSL_PIN_FAILED: 'Ssl pin failed'
};

export const EVENT_SOURCES = {
  MENU: 'Menu',
  MOBILE_SCREEN: 'Mobile number page',
  CHANGE_ADDRESS: 'Change address',
  SIGN_UP_INTRO: 'Sign up splash screens',
  WEB: 'web',
  JIO: 'via jio'
};

export const MENU_TITLES = {
  NOTIFICATIONS: 'Notifications',
  WALLET: 'Wallet',
  SUBSCRIPTION: 'Subscription',
  MBEYOND: 'mBeyond',
  VALUE_PASS: 'Value Pass',
  MY_ORDERS: 'My Orders',
  TRANSACTIONS: 'Transactions',
  HOLIDAYS: 'Holidays',
  INVITE_A_FRIEND:'Invite a Friend',
  MY_PROFILE: 'My Profile',
  CONTACT_US: 'Contact Us',
  RECYCLE_PACKING_MATERIAL: 'Recycle Packing material'
};

export const PAGE_TAGS = {
  MOBILE_SCREEN_WIDGET: 'Mobile no. enter screen visit',
  SEARCH_SOCIETY: 'Select society screen visit',
  CONFIRM_SOCIETY: 'Confirm society screen visit',
  SELECT_TOWER: 'Select tower and flat screen visit',
  ADD_ADDRESS: 'Add address screen visit',
  ADD_ADDRESS_SUCCESS: 'Add add address success screen visit',
  SELECT_CITY: 'Select city screen visit'
}

export const APPSFLYER = {
  NON_ORGANIC: 'Non-organic',
  ON_APP_OPEN_ATTRIBUTION: 'onAppOpenAttribution',
  REFERRAL: 'referral',
  WHATSAPP_SHARING: 'whatsappSharing',
  COLLECTION: 'collection'
};

export enum FIREBASE_REMOTE_CONFIG_CALL {
  All = 'ALL',
  MAP_KEY_RIL = 'MAP_KEY_RIL'
}

export const CLEVERTAP_CHANNELS = [
  { ID: 'mb_marketing', NAME: 'Personal', DESCRIPTION: 'Marketing Purpose', IMPORTANCE: 5, SHOW_BADGE: true,SOUND: '' },
  { ID: 'mb_impupdates', NAME: 'Important Updates', DESCRIPTION: 'Important Updates', IMPORTANCE: 5, SHOW_BADGE: true ,SOUND: ''},
  { ID: 'mb_delivery', NAME: 'Delivery Updates', DESCRIPTION: 'Delivery Updates', IMPORTANCE: 5, SHOW_BADGE: true,SOUND: '' },
  { ID: 'mb_deals', NAME: 'Offers & Deals', DESCRIPTION: 'Offers & Deals', IMPORTANCE: 5, SHOW_BADGE: true,SOUND: '' },
  { ID: 'mb_topupdeals', NAME: 'Top-up offers', DESCRIPTION: 'Top-up offers', IMPORTANCE: 5, SHOW_BADGE: true ,SOUND: ''},
  { ID: 'mb_balance', NAME: 'Balance Reminders', DESCRIPTION: 'Balance Reminders', IMPORTANCE: 5, SHOW_BADGE: true ,SOUND: ''},
  { ID: 'mb_mbucks', NAME: 'MBucks', DESCRIPTION: 'Loyalty Points', IMPORTANCE: 5, SHOW_BADGE: true,SOUND: '' },
  { ID: 'mb_cold', NAME: 'Cold Orders', DESCRIPTION: 'Cold Orders', IMPORTANCE: 5, SHOW_BADGE: true ,SOUND: ''},
  { ID: 'mb_alerts', NAME: 'Alerts', DESCRIPTION: 'Alerts', IMPORTANCE: 5, SHOW_BADGE: true ,SOUND: ''},
  { ID: 'mb_rewards', NAME: 'Rewards', DESCRIPTION: 'Rewards', IMPORTANCE: 5, SHOW_BADGE: true, SOUND: '' },
  { ID: 'mb_holiday', NAME: 'Holiday Alerts', DESCRIPTION: 'Holiday Alerts', IMPORTANCE: 5, SHOW_BADGE: true, SOUND: '' },
  { ID: 'mb_reusebag', NAME: 'Delivery Alerts', DESCRIPTION: 'Delivery Alerts', IMPORTANCE: 5, SHOW_BADGE: true, SOUND: 'doorbell.wav' },
  { ID: 'mb_christmas', NAME: 'Christmas Alerts', DESCRIPTION: 'Christmas Alerts', IMPORTANCE: 5, SHOW_BADGE: true, SOUND: 'christmas.wav' }
];

export const DEFAULT_PROPERTY_VALUE_FOR_EVENTS = 'NA';

export const CITY_MODAL_CSS_CLASS = 'city-modal';

export const UPCOMING_DELIVERY_INFO = 'upcoming-delivery-info';

export const EXIT_PAGE_ROUTES_MAPPER = {
  HOME: 'home',
  BOARDING: 'boarding',
  SIGNUP_JOURNEY: 'signup-journey'
};

export const LIVE_NOW = 'Live Now';
export const CAL_MODAL_CSS_CLASS = 'cal-modal';

export const BRAND_LOGO = 'assets/img/mb-logo.svg';

export const SIGNUP_INTRO_ITEMS = [
  {
    imgUrl: 'assets/img/signup-spalsh-img1@2x.png',
    heading: 'Order till midnight',
    subHeading: 'Get your essentials delivered <br>to you by 7 AM',
    visited: false
  },
  {
    imgUrl: 'assets/img/signup-spalsh-img2@2x.png',
    heading: 'No minimum order',
    subHeading: 'Want a lemon? You got it!<br> One bar of soap? Sure',
    visited: false
  },
  {
    imgUrl: 'assets/img/signup-spalsh-img3@2x.png',
    heading: 'Seamless Ordering!',
    subHeading: 'Simply add items throughout the day & receive it the next morning',
    visited: false
  }
];

export const LOGIN_SIGNUP_TAB_POPUP: InfoPopupData = {
    IS_CLOSE_ICON_REQUIRED: true,
    IMAGE_URL: 'assets/img/non-login.svg',
    HEADER_TEXT: 'Oh yes!',
    MESSAGE: 'You just tapped on your treasure chest! Login/Signup to view',
    BUTTON_TEXT: 'Login/Signup'
};

export const NEGATIVE_BALANCE_POPUP: InfoPopupData = {
  IS_CLOSE_ICON_REQUIRED: true,
  IMAGE_URL: 'assets/img/account-delete.svg',
  HEADER_TEXT: 'Settle your wallet balance to proceed with account deletion',
  MESSAGE: 'You’ve negative wallet balance. Please clear your due amount to delete your account',
  BUTTON_TEXT: 'Settle your wallet',
  CANCEL_BUTTON_TEXT: 'I’ll delete later'
};

export const ACCOUNT_DELETE_CONFIRMATION_POPUP: InfoPopupData = {
  IS_CLOSE_ICON_REQUIRED: true,
  IMAGE_URL: null,
  HEADER_TEXT: 'Are you sure you want to delete your Milkbsket account?',
  MESSAGE: 'Your account will be deleted shortly. You can create a new account to continue using Milkbasket',
  CANCEL_BUTTON_TEXT: 'Cancel',
  BUTTON_TEXT: 'Yes, Delete'
};

export const LOGIN_SIGNUP_A2B_POPUP: InfoPopupData = {
  IS_CLOSE_ICON_REQUIRED: true,
  IMAGE_URL: 'assets/img/non-login-cart.svg',
  HEADER_TEXT: 'Hey Stranger!',
  MESSAGE: 'Please Login/Signup before adding items to the cart.',
  BUTTON_TEXT: 'Login/Signup'
};

