//#region Imports

//#region Angular Imports
import { Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-food-wallet-guidelines',
  templateUrl: './food-wallet-guidelines.component.html',
  styleUrls: ['./food-wallet-guidelines.component.scss'],
})
export class FoodWalletGuidelinesComponent {
  
  constructor(
    private modalCtrl: ModalController,
  ) { }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }
}
