//#region Imports

//#region Angular Imports
import { Injectable } from "@angular/core";
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { CAMPAIGN_SHOPPING_ADS_URL } from "@Globals/constants/registration/constants";
//#endregion Other Imports
//#endregion Imports
declare var window: any;

@Injectable({
    providedIn: "root"
})
export class WebAppflyerService {
    constructor() { }
    oneLinkResultUrl: string;
    createOnelinkFromUrl(url) {
        const oneLinkBaseUrl: string = CAMPAIGN_SHOPPING_ADS_URL;
        var result = window.AF_SMART_SCRIPT.generateOneLinkURL({
            oneLinkURL: oneLinkBaseUrl,
            afParameters:{
                mediaSource: this.getParameterByName('utm_source', url),
                campaign: this.getParameterByName('utm_campaign', url),
                adSet: this.getParameterByName('adset_name', url),
                googleClickIdKey: this.getParameterByName('af_sub2', url),
                afSub3: this.getParameterByName('utm_term', url),
            }
        });
        if(result && result.clickURL) {
            this.oneLinkResultUrl = result.clickURL;
        }
    }


    getParameterByName(name, url) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


}