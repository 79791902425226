//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { PaymentModalService } from '@Globals/providers/payment-modal/payment-modal-service';
//#endregion Providers Imports

//#region Other Imports
import { LowBalanceComponent } from '@Shared/payment/low-balance/low-balance.component';
import { PaymentOfferModule } from '@Shared/payment/payment-offer/payment-offer.module';
import { PaymentOfferListingModule } from '@Shared/payment/payment-offer-listing/payment-offer-listing.module';
import { AutoPayModule } from '@Features/payment/auto-pay/auto-pay.module';
import { PaymentSharedModule } from '@Shared/payment-shared/payment-shared.module';
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PaymentOfferModule, 
    PaymentOfferListingModule, 
    AutoPayModule,
    PaymentSharedModule
  ],
  exports: [LowBalanceComponent],
  declarations: [LowBalanceComponent],
  providers: [ PaymentModalService]
})
export class LowBalanceModule extends DefaultModule { }