<div *ngIf="lowBalancePopUp ? (dataService.autoDebitUserData.status === autoDebitStatus.InActive ||
  dataService.autoDebitUserData.status === autoDebitStatus.New) :
  dataService.autoDebitUserData.status > -1" [ngSwitch]="dataService.autoDebitUserData.status" (click)="navigate()">

  <!-- Auto Debit state when user activated the AUTO Debit -->
  <div class="auto-debit has-auto-debit-on" *ngSwitchCase="autoDebitStatus.Activated">
    <ion-item lines="none" *ngIf="!lowBalancePopUp">
      <ion-label class="ion-no-margin ion-text-wrap">
        <p>Your AutoPay is on</p>
        <small class="d-block">Your wallet is topped up with ₹{{dataService.autoDebitUserData?.rechargeAmount}} when
          your
          wallet balance
          drops to less than ₹{{dataService.autoDebitUserData?.minAmount}}
        </small>
      </ion-label>
      <ion-icon class="ion-align-self-start" slot="end" name="chevron-forward-custom"></ion-icon>
    </ion-item>
  </div>

  <!-- Auto Debit state when user activated the AUTO Debit but while deducting money facing error -->
  <div class="auto-debit has-auto-debit-disabled" *ngSwitchCase="autoDebitStatus.Error">
    <ion-item lines="none" *ngIf="!lowBalancePopUp">
      <ion-label class="ion-no-margin ion-text-wrap">
        <p class="has-info-icon">AutoPay is temporarily disabled<ion-icon color="danger" class="icon-info icons-property icon-md"></ion-icon>
        </p>
        <small class="d-block">{{dataService.autoDebitUserData.errorMsg}}</small>
      </ion-label>
      <ion-icon class="ion-align-self-start" slot="end" name="chevron-forward-custom"></ion-icon>
    </ion-item>
  </div>

  <!-- Auto Debit state when user activated the AUTO Debit but while deducting money facing error -->
  <div class="auto-debit has-auto-debit-disabled" *ngSwitchCase="autoDebitStatus.ActivationPending">
    <ion-item lines="none">
      <ion-label class="ion-no-margin ion-text-wrap">
        <p class="has-info-icon">AutoPay Payment is Pending<ion-icon color="danger" class="icon-info icons-property icon-md"></ion-icon>
        </p>
        <small class="d-block">Please come back after some time and check the status again</small>
      </ion-label>
      <ion-icon class="ion-align-self-start" slot="end" name="chevron-forward-custom"></ion-icon>
    </ion-item>
  </div>

  <!-- Auto Debit state when user stopped the AUTO Debit  -->
  <div class="auto-debit has-restart-auto-debit" *ngSwitchCase="autoDebitStatus.InActive">
    <img alt="Restart AutoPay" class="ion-margin-start ion-margin-bottom icon-rupee" width="62"
      src="../../../../assets/icon/icon-rupee@2x.png" />
    <ion-item lines="none">
      <ion-label class="ion-no-margin ion-text-wrap">
        <p>Restart AutoPay</p>
        <small class="d-block">and never run out of wallet balance</small>
      </ion-label>
      <ion-icon class="ion-align-self-start" slot="end" name="chevron-forward-custom"></ion-icon>
    </ion-item>
  </div>

  <!-- Auto Debit state when user never activated the AUTO Debit -->
  <div class="auto-debit has-new-auto-debit auto-debit-banner" *ngSwitchDefault>
    <div class="banner-section ion-padding-horizontal" *ngIf="autoDebitBanner?.length">
      <wgt-banner-block [data]="data"></wgt-banner-block>
    </div>
  </div>

</div>
