//#region Imports

//#region Angular Imports
//#endregion Angular Imports

//#region Ionic Imports
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { Utilities } from '@Globals/classes/utilities';
//#endregion Other Imports

//#endregion Imports

export class LogoutPlugins {
  constructor(private appsflyer: Appsflyer) {}

  doLogoutPlugins(userProfile) {
    if (Utilities.isApp()) {
      this.logoutAppsflyer(userProfile?.id);
    }
  }

  private logoutAppsflyer(userId) {
    this.appsflyer.Stop(userId);
  }
}
