<ion-header class="ion-main-header visible-jio-header" [ngClass]="{ shadow: isShadowAllowed && dataService.isShadowRequired }">
  <ng-container *ngIf="isJioBAckButtonNeeded">
    <mbs-jio-mart-header></mbs-jio-mart-header>
  </ng-container>
  <ion-toolbar class="margin-top-0 main-toolbar" [ngClass]="{ disablearrow: !showBackButton}">
    <ion-buttons class="has-notification-badge" slot="start">
      <ion-menu-button title="hamburger" *ngIf="isDrawerNeeded">
        <ion-icon class="icon-hamburger icons-property" color="dark"></ion-icon>
      </ion-menu-button>
    </ion-buttons>
    <ion-buttons class="ion-buttons-arrow-back ion-buttons-arrow-back-sm" slot="start" *ngIf="!isDrawerNeeded && showBackButton">
      <img class="back-arrow-custom-svg" (click)="updateBack ? componentBack() : navigateBack()" src="/assets/icon/right-searc-arrow.svg" alt="">
      <ion-button class="no-ripple-effect" (click)="updateBack ? componentBack() : navigateBack()">
        <!-- <ion-icon *ngIf="!showCrossButton; else crossButton" name="arrow-back" slot="icon-only"></ion-icon> -->
        <!-- <img *ngIf="!showCrossButton; else crossButton" name="arrow-back" src="../../../../../assets/img/icon-arrow-right@2x.png" alt=""> -->
        <ion-icon *ngIf="!showCrossButton; else crossButton" class="icon-arrow-back icons-property icon-md" slot="icon-only"></ion-icon>
        <ng-template #crossButton>
          <ion-icon color="dark" class="icon-close icons-property" slot="icon-only"></ion-icon>
        </ng-template>
      </ion-button>
    </ion-buttons>
    <ion-title [hidden]="(showSearchBar && dataService.firebaseMasterData.isSearchBar)" class="ion-no-padding">
      <div #headerSelector>
        <ng-content select="[header]"></ng-content>
      </div>
      <ng-container *ngIf="!headerSelector.innerHTML.trim()">
        <ng-content></ng-content>
      </ng-container>
    </ion-title>

    <div class="search-wrap" [hidden]="!(showSearchBar && dataService.firebaseMasterData.isSearchBar)"
      [ngClass]="{'is-user-logout': !authService.isLoggedIn()}">
      <!-- commted alredy -->
      <!-- <ion-searchbar clear-icon="close" (click)="navigateTo(SEARCH, true)"
        class="ion-no-padding header-search-bar animation" mode="ios"
        placeholder="Search for products" type="text">
        <ion-icon class="icon-search"></ion-icon>
      </ion-searchbar> -->
      <div class="header-search-bar-sec" (click)="navigateTo(SEARCH, true)">
        <ion-icon class="icon-search icons-property" color="dark"></ion-icon>
        <span class="placeholder-text ion-text-nowrap">Search for products</span>
      </div>
    </div>

    <ion-buttons id="btn-icons" slot="end">
      <ion-button id="cal-icon" class="btn-icon" *ngIf="isCalendarNeeded && authService.isLoggedIn()"
        (click)="callMethod($event)">
        <ion-icon slot="icon-only" class="icon-calendar icons-property"></ion-icon>
        <span *ngIf="dataService.subscribeAndSaveNudge" class="notification-badge-cal"></span>
      </ion-button>
      <div #rightButtonSelector>
        <ng-content select="[rightButton]"></ng-content>
      </div>

      <mbs-social-sharing-button class="has-white-icons"
        *ngIf="likeAndShareData.metaData"
        [metaData]="likeAndShareData.metaData" [componentType]="likeAndShareData.componentType">
      </mbs-social-sharing-button>

      <ion-button class="like-btn" (click)="likeCollection()" *ngIf="likeCount">
        <ion-icon class="icon-favourite" [ngClass]="{'filled' : likeAndShareData.isLiked ===  true}" slot="icon-only">
        </ion-icon>
        <small class="likes-counter">{{likeCount}}</small>
      </ion-button>
      <ng-container *ngIf="isSearchAndCartNeeded">        
        <ion-button [hidden]="(showSearchBar && dataService.firebaseMasterData.isSearchBar)"
          (click)="navigateTo(SEARCH)">
          <ion-icon slot="icon-only" color="dark" class="icon-search icons-property"></ion-icon>
        </ion-button>
        <ion-button title="cart" class="btn-icon" *ngIf="authService.isLoggedIn()" (click)="navigateTo(YOUR_CART)">
          <ion-badge *ngIf="
              masterDataService.baskets?.length &&
              (masterDataService.baskets)[0] &&
              (masterDataService.baskets)[0].quantity > 0
            " class="cart-badge ion-no-padding" color="primary">{{
            masterDataService.baskets?.length &&
            (masterDataService.baskets)[0]?.quantity
            }}</ion-badge>
          <ion-icon slot="icon-only" class="icon-basket icons-property" color="dark"></ion-icon>
        </ion-button>
      </ng-container>
    </ion-buttons>

    <ion-buttons slot="end" class="info-button" *ngIf="bottomSheetVisiblity">
      <ion-button slot="icon-only" (click)="navtoTerms()">
        <ion-icon color="dark" slot="icon-only" class="icon-info icons-property icon-md"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>