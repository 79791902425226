import { environment } from 'src/environments/environment';

export const SHOPPING_CREDIT_ERROR_MESSAGES = {
  SHOPPING_CREDIT_AMOUNT_MIN: 'Minimum top-up required is ',
  SHOPPING_CREDIT_AMOUNT_MAX: 'Maximum top-up required is ',
  CASH_COLLECTION_AMOUNT_MIN: 'Minimum top-up required is',
  CASH_COLLECTION_AMOUNT_MAX: 'Maximum top-up required is'
};
export const AUTO_DEBIT_ERROR_MESSAGES = {
  MINIMUM_AMOUNT: 'Minimum amount required is Rs ',
  MAXIMUM_AMOUNT: 'Maximum amount required is Rs ',
  MINIMUM_RECHARGED_AMOUNT: 'Minimum recharge amount is Rs ',
  MAXIMUM_RECHARGED_AMOUNT: 'Maximum recharge amount is Rs '
};
export const EVENT_NAMES = {
  PAYMENT_SUCCESS: 'Payment Success',
  PAYMENT_FAILURE: 'Payment Failure',
  PAYMENT_START: 'Payment start',
  CASH_COLLECTION_START: 'Cash Collection Start',
  CASH_COLLECTION_COMPLETE: 'Cash Collection Complete',
  CASH_COLLECTION_REQUEST_UPDATE_POPUP: 'Cash Collection Update Popup',
  FIRST_PAYMENT: 'First Payment',
  INITIATE_TRANSACTION_SUCCESS: 'initiate transaction success',
  INITIATE_TRANSACTION_FAILURE: 'initiate transaction failure',
  SUPERCHARGE_DISCOVERY_PAGE: 'SuperCharge Discovery page',
  SUPERCHARGE_PAYMENT_MODE: 'SuperCharge Payment Mode',
  SUPERCHARGE_PAYMENT_START: 'SuperCharge Payment Start',
  SUPERCHARGE_PAYMENT_SUCCESS: 'SuperCharge Payment Success',
  SUPERCHARGE_PAYMENT_PENDING: 'SuperCharge Payment Pending',
  SUPER_CHARGE_NOTIFY_ME: 'SuperCharge Notify me',
  AUTO_DEBIT_START: 'Auto-debit start',
  AUTO_DEBIT_CARD_CLICKED: 'Auto-debit card clicked',
  AUTO_DEBIT_SETUP_SUCCESS: 'Auto-debit setup success',
  AUTO_DEBIT_SETUP_FAILED: 'Auto-debit setup failed',
  AUTO_DEBIT_CARD_CHANGED: 'Auto-debit card change',
  AUTO_DEBIT_VALUE_CHANGE: 'Auto-debit value change clicked',
  AUTO_DEBIT_FEEDBACK: 'Auto-debit feedback',
  AUTO_DEBIT_DISABLED: 'Auto-debit disabled',
  AUTO_DEBIT_RESTART: 'Auto-debit restart',
  AUTO_DEBIT_THRESHOLD_CHANGE: 'Auto-debit threshold change',
  MB_PASS_VIEWED: 'MB Pass Viewed',
  MB_PASS_BUY_START: 'MB Pass Buy Start',
  MB_PASS_BUY_CONFIRMATION: 'MB Pass Buy Confirmation',
  MB_PASS_TNC: 'MB Pass TnC',
  MB_PASS_FAQS: 'MB Pass FAQs',
  TRANSACTION_CLICKED: 'Transaction Clicked',
  ADD_BASKET_ERROR: 'add_basket_error',
  BALANCE_CLICKED: 'Balance clicked',
  ADD_MONEY_CLICK: 'Add money clicked',
  ADD_MONEY_SCREEN_VISITS: 'Add money page visited',
  PROMO_CODE_VIEW: 'Promo code viewed',
  PROMO_CODE_APPLY: 'Promo code applied',
  PROMO_CODE_SUCCESS: 'Promo code success',
  PROMO_CODE_COPY: 'Promo code copy',
  MINIMUM_TOPUP: 'Minimum top up required msg viewed',
  MAXIMUM_TOPUP: 'Maximum top up msg viewed',
  DENOMINATION_CLICKED: 'Click on denomination buttons',
  JIO_CASHBACK_TAB: 'Jio Cashback Tab',
  VIEW_ALL_OFFER: 'view_all_offer',
  OFFER_DETAILS: 'offer_details',
  FOOD_WALLET_EDUCATION: 'Food wallet education',
  CASHBACK_WALLET_EDUCATION: 'Cashback wallet education',
  WALLET_BREAKUP: 'Wallet Breakup',
  TRANSACTION_VIEW: 'Transaction View',
};
export const EVENT_SOURCE = {
  HAMBURGER: 'Hamburger',
  ONLINE: 'online',
  CASH_COLLECTION: 'Cash Collection',
  PAYMENT_OFFERS: 'PaymentOffers',
  PAYMENTS: 'Payments',
  SHOPPING_CREDITS: 'Shopping Credits',
  ATB_ERROR: 'ATB error',
  ONE_PAGER: 'one_pager',
  MBEYOND: 'mbeyond',
  BANNER: 'Banner',
  EXTERNAL: 'External',
  MB_PASS: 'MBPass',
  CART_PAGE: 'Cart',
  SHOPPING_CREDIT_PAGE: 'Shopping credit page',
  TRANSACTION_PAGE: 'Transaction page',
  OUT_OF_SHOPPING_CREDIT_POPUP: 'Out of shopping credit popup',
  PAYMENT_PAGE: 'Payment page',
  WB_SHOPPING_CREDIT_PAGE: 'wallet breakup on shopping credit page',
  WB_OUT_OF_SHOPPING_CREDIT_POPUP: 'wallet breakup on out of shopping credit popup',
  WB_TRANSACTION_PAGE: 'wallet breakup on transaction page',
};

export const MB_PASS_TYPE_VALVE = {
  1: 'Category',
  2: 'Sub Category',
  4: 'Min Basket Value'
}
export const EVENT_ARGUMENTS = {
  SHOPPING_CREDITS: 'shoppingcredits'
};
export const SUCCESS_PAYMENT = {
  message: `<div class="toast-message-box"><img alt="Transaction Successful" src="assets/img/success.gif" width="159" height="159" /><h1>₹##balance##</h1><img alt="balance updated" [src]="'assets/img/updated.png'" width="110" height="13" />
     <h2>Transaction Successful !</h2></div>`,
  class: 'toast-custom',
  position: 'top'
};
export const SUCCESS_PAYMENT_THIRD_PARTY = {
  message:
    `<div class="toast-message-box"><img alt="Transaction Successful" src="assets/img/success.gif" width="159" height="159" /><h2>Transaction Successful !</h2></div>`,
  class: 'toast-custom',
  position: 'top'
};
export const SUCCESS_PURCHASED_MB_PASS = {
  message:
    `<div class="toast-message-box"><img alt="Your Value pass is active now" src="assets/img/success.gif" width="159" height="159" /><h2>Its Done!</h2><p>Your Value pass is active now. </p><br><p>$$mb_pass_description$$</p></div>`,
  class: 'toast-custom',
  position: 'top'
};
export const FAIL_PAYMENT = {
  message:
    // tslint:disable-next-line: max-line-length
    `<div class="toast-message-box"><img alt="Transaction Failed" src="assets/img/failed.png" width="34" height="34" /><div></div><h2 color="red" style="color:red">Transaction Failed</h2><br><p color="red" style="color:red">##ERROR_MESSAGE##</p></div>`,
  class: 'toast-custom',
  position: 'top'
};
export const PENDING_PAYMENT = {
  message:
    `<div class="toast-message-box"><img alt="Transaction Pending" src="assets/img/transaction-pending.svg" width="159" height="159" /><h2>Transaction Pending !</h2><br><p color="red" style="color:red">##ERROR_MESSAGE##</p></div>`,
  class: 'toast-custom',
  position: 'top'
};
export const CANCELLED_PAYMENT = {
  message:
    // tslint:disable-next-line: max-line-length
    `<div class="toast-message-box"><img alt="Transaction Cancelled" src="assets/img/failed.png" width="34" height="34" /><div></div><h2 color="red" style="color:red">Transaction Cancelled</h2><br><p color="red" style="color:red">##ERROR_MESSAGE##</p></div>`,
  class: 'toast-custom',
  position: 'top'
};
export const INITIATE_PAYMENT = {
  message:
    `<div class="toast-message-box"><img alt="Transaction in progress" src="assets/img/app-loader.gif" width="84" height="36" /><h2 style="color:red">Transaction in progress..</h2><h3>Please do not press back button</h3></div>`,
  class: 'toast-custom',
  position: 'top'
};
export const SUPER_CHARGE_STATUS_BAR_COLOR = {
  DEFAULT: '#fff',
  SUPER_CHARGE: '#3f4662'
};

export const PAYMENT_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed'
};

export const NOTIFY_ME = 'Awesome. We\'ll notify you';
export const JIOPAY_ERROR_MESSAGES = {
  genericFailedCase: {
    code: 'ERROR_CODE_NOT_PRESENT_IN_JIOPAY_DOCS',
    message: 'Please try again later.'
  }
};
export const JUSPAY_ERROR_MESSAGES = {
  pendingVBV: {
    code: 'PENDING_VBV',
    message: 'Authentication is in progress. Please wait.'
  },
  authorizationFailed: {
    code: 'AUTHORIZATION_FAILED',
    message: 'Bank declined the transaction.'
  },
  authenticationFAILED: {
    code: 'AUTHENTICATION_FAILED',
    message: 'Transaction failed because you cancelled the transaction.'
  },
  juspayDeclined: {
    code: 'JUSPAY_DECLINED',
    message: 'Transaction declined by payment aggregator.'
  },
  authorizing: {
    code: 'AUTHORIZING',
    message: 'Waiting for bank to update the status. Please wait.'
  },
  new: {
    code: 'NEW',
    message: ''
  },
  started: {
    code: 'STARTED',
    message: 'Routing your payment to a gateway. Please wait.'
  },
  autoRefunded: {
    code: 'AUTO_REFUNDED',
    message: 'Any amount if deducted will be refunded within "x" business days.'
  },
  captureInitiated: {
    code: 'CAPTURE_INITIATED',
    message: 'Routing your payment to a gateway. Please wait.'
  },
  captureFAILED: {
    code: 'CAPTURE_FAILED',
    message: 'Could not connect to a gateway.'
  },
  voidInitiated: {
    code: 'VOID_INITIATED',
    message: 'Routing your payment to a gateway. Please wait.'
  },
  voided: {
    code: 'VOIDED',
    message: ''
  },
  voidFailed: {
    code: 'VOID_FAILED',
    message: 'Could not connect to a gateway.'
  },
  charged: {
    code: 'CHARGED',
    message: ''
  },
  genericFailedCase: {
    code: 'ERROR_CODE_NOT_PRESENT_IN_JUSPAY_DOCS', // Added an made up code in case when error status is out of the Juspay's Docs.
    message: 'Please try again later.'
  },
  webhookUpdated : {
    code: 'Transaction already processed', //Added this case for scenarios when webshook updates the status even before query call
    message: ''
  }
};
export const JUSPAY_BASE_AND_SERVICE_PARAMS = {
  baseParams: {
    merchant_id: 'milkbasket',
    client_id: 'milkbasket_',
    transaction_id: '',
    order_id: '',
    customer_email: '',
    customer_id: '',
    customer_phone_number: '',
    environment: environment.production ? 'production' : 'sandbox',
    amount: ''
  },
  serviceParams: {
    service: 'in.juspay.ec',
    session_token: '',
    endUrls: [],
    payload: ''
  }
};
export const DEACTIVATE_AUTO_DEBIT = {
  BUTTONS: {
    CANCEL: { text: 'Don’t Stop', role: 'cancel', handler: null },
    CONFIRM: {
      text: 'Yes, Stop',
      handler: null,
      cssClass: 'alert-button-primary-outline'
    }
  },
  META_DATA: {
    HEADER_TEXT: 'Are you sure you want to stop AutoPay?',
    MESSAGE: 'You can also change the amounts instead of stopping Autopay.'
  },
  CSS_CLASS: 'alert-custom stop-auto-debit'
};
export const BUY_MB_PASS = {
  BUTTONS: {
    CANCEL: { text: 'No', role: 'cancel', handler: null, cssClass: 'alert-button-primary-outline' },
    CONFIRM: {
      text: 'Yes',
      handler: null
    }
  },
  META_DATA: {
    HEADER_TEXT: '₹$$Balance$$ will be deducted from your MB Wallet. Do you want to proceed?'
  },
  CSS_CLASS: 'alert-custom stop-auto-debit'
};
export const TERMS_CONDITIONS = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    HEADER_TEXT: 'Terms & Conditions',
    MESSAGE: ''
  },
  CSS_CLASS: 'alert-custom stop-auto-debit'
};
export const FEEDBACK_FROM_USER = {
  BUTTONS: {
    CANCEL: {
      text: 'Cancel',
      role: 'cancel',
      handler: null,
      cssClass: 'button-hidden'
    },
    CONFIRM: {
      text: 'Okay',
      handler: null,
      cssClass: 'alert-button-primary-outline'
    }
  },
  META_DATA: {
    HEADER_TEXT: 'Please share your feedback',
    // tslint:disable-next-line: max-line-length
    MESSAGE: '<div class="feed-back-wrap margin-bottom-medium"><p>How was your experience with Auto payment?</p><ion-row><ion-button class="active" size="small" color="light">Average</ion-button><ion-button size="small" color="light">Happy</ion-button><ion-button size="small" color="light">Very happy</ion-button></ion-row></div><div class="feed-back-wrap"><p>How was your experience with Auto payment?</p><ion-row><ion-button size="small" color="light">Average</ion-button><ion-button size="small" color="light">Happy</ion-button><ion-button size="small" color="light">Very happy</ion-button></ion-row></div>'
  },
  CSS_CLASS: 'alert-custom stop-auto-debit'
};

export const SAVED_CARD_DELETE_CONFIRMATION = {
  BUTTONS: {
    CANCEL: { text: 'No', role: 'cancel', handler: null },
    CONFIRM: {
      text: 'Yes',
      handler: null,
      cssClass: 'alert-button-primary-outline'
    }
  },
  META_DATA: {
    HEADER_TEXT: '',
    MESSAGE: ''
  },
  CSS_CLASS: 'alert-custom'
};

export const PRE_FETCH_SDK = {
  service: 'in.juspay.hyperapi',
  payload: {
    clientId: 'milkbasket_'
  }
}

export const PROCESS_SDK = {
  requestId: '',
  service: 'in.juspay.hyperapi',
  payload: {
    merchantId: 'milkbasket',
    clientId: 'milkbasket_',
    customerId: '',
    orderId: '',
    clientAuthToken: '',
    environment: environment.production ? 'production' : 'sandbox'
  }
}

export const CRED_ELIGIBILITY = {
  requestId: '',
  service: 'in.juspay.hyperapi',
  payload: {
    action: "eligibility",
    amount: '', //mandatory
    clientAuthToken: '',
    data: {
      apps: [
        {
          checkType: ["cred"],
          mobile: ''
        }
      ],
      cards: [],
      wallets: []
    }
  }
}

export const INITIATE_SDK = {
  requestId: '',
  service: 'in.juspay.hyperapi',
  payload: {
    action: 'initiate',
    merchantId: 'milkbasket',
    clientId: 'milkbasket_',
    customerId: '',
    environment: environment.production ? 'production' : 'sandbox'
  }
}

export const APPS_ELIGIBILITY = {
  requestId: '',
  service: 'in.juspay.hyperapi',
  payload: {
    action: "upiTxn",
    orderId: '',
    getAvailableApps: true,
    showLoader: false
  }
}

export const TWID_ELIGIBILITY = {
  requestId: '',
  service: 'in.juspay.hyperapi',
  payload: {
    action: "eligibility",
    amount: '1', //mandatory
    clientAuthToken: '',
    data: {
      "wallets": [
        {
       "customerId": "",
        "mobile": "",
        "checkType": ["TWID"]
        }
        ],
      "cards": []
    }
  }
}

export const PG_WISE_JUSPAY_PAY_LOAD = {
  amazon_pay: {
    pgName: 'amazon_pay',
    name: 'Amazon pay',
    icon: '../../../../assets/img/amazon-pay.svg',
    desc: 'Pay using amazon pay wallet',
    keyToBeReplaced: 'platform',
    payload: {
      paymentMethod: 'AMAZONPAY',
      action: 'walletTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'WALLET',
      payment_method: 'AMAZONPAY',
      sdk_present: 'platform_AMAZONPAY_NONTOKENIZED',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  google_pay: {
    pgName: 'google_pay',
    name: 'Google pay',
    icon: '../../../../assets/img/gpay.png',
    keyToBeReplaced: 'mobile_number',
    payload: {
      payWithApp: '',
      action: 'upiTxn',
      upiSdkPresent: true
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'WALLET',
      payment_method: 'GOOGLEPAY',
      redirect_after_payment: true,
      txn_type: 'PUSH_PAY',
      mobile_number: '',
      format: 'json'
    }
  },
  airtel_money: {
    pgName: 'airtel_money',
    name: 'Airtel Payment Bank',
    icon: '../../../../assets/img/airtel.svg',
    desc: 'Pay using airtel money',
    payload: {
      paymentMethod: 'AIRTELMONEY',
      action: 'walletTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      directWalletToken: '',
      payment_method_type: 'WALLET',
      payment_method: 'AIRTELMONEY',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  ola_money: {
    pgName: 'ola_money',
    name: 'Ola Money',
    icon: '',
    payload: {
      paymentMethod: 'OLAMONEY',
      action: 'walletTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'NB',
      payment_method: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  phone_pe: {
    pgName: 'phone_pe',
    name: 'Phone Pay',
    icon: '../../../../assets/img/phone-pay.png',
    payload: {
      payWithApp: '',
      action: 'upiTxn',
      upiSdkPresent: true
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'NB',
      payment_method: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  lazy_pay: {
    pgName: 'lazy_pay',
    name: 'Lazypay',
    icon: '../../../../assets/img/lazypay.svg',
    payload: {
      paymentMethod: 'LAZYPAY',
      action: 'walletTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'WALLET',
      payment_method: 'LAZYPAY',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  UPI: {
    pgName: 'UPI',
    name: 'UPI',
    icon: '../../../../assets/img/upi-icon.svg',
    payload: {
      custVpa: '',
      action: 'upiTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'UPI',
      payment_method: 'UPI',
      txn_type: 'UPI_COLLECT',
      redirect_after_payment: true,
      upi_vpa: '',
      format: 'json'
    }
  },
  UPI_INTENT: {
    pgName: 'UPI',
    icon: '../../../../assets/img/upi-icon.svg',
    name: 'UPI',
    payload: {
      custVpa: 'UPI',
      action: 'upiTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'NB',
      payment_method: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  paytm_upi: {
    pgName: 'paytm_upi',
    icon: '../../../../assets/img/paytm-sq.svg',
    name: 'Paytm Upi',
    payload: {
      payWithApp: '',
      action: 'upiTxn',
      upiSdkPresent: true
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'NB',
      payment_method: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  paytm: {
    pgName: 'paytm',
    icon: '../../../../assets/img/paytm-sq.svg',
    name: 'Paytm',
    desc: 'Pay using paytm wallet',
    payload: {
      paymentMethod: 'PAYTM',
      action: 'walletTxn',
      directWalletToken: ''
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'WALLET',
      directWalletToken: '',
      payment_method: 'PAYTM',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  cred: {
    pgName: 'cred',
    icon: '../../../../assets/img/cred-bank.png',
    payload: {
      paymentMethod: 'CRED',
      action: 'appPayTxn',
      application:'CRED',
      walletMobileNumber:''
    },
    webPayload: {
      merchant_id: 'milkbasket',
      directWalletToken: '',
      payment_method: 'CRED',
      payment_method_type: 'WALLET',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  freecharge: {
    pgName: 'freecharge',
    name: 'Freecharge',
    icon: '../../../../assets/img/freecharge.svg',
    desc: 'Pay using freecharge wallet',
    payload: {
      paymentMethod: 'FREECHARGE',
      action: 'walletTxn',
      directWalletToken: ''
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'WALLET',
      payment_method: 'FREECHARGE',
      redirect_after_payment: true,
      format: 'json'
    }
  }, 
  mobikwik: {
    pgName: 'mobikwik',
    name: 'Mobikwik',
    icon: '../../../../assets/img/mobiwink.svg',
    desc: 'Pay using mobikwik wallet',
    payload: {
      paymentMethod: 'MOBIKWIK',
      action: 'walletTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'WALLET',
      payment_method: 'MOBIKWIK',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  TWID: {
    pgName: 'TWID',
    name: 'Pay with Rewards',
    icon: '../../../../assets/img/twid.svg',
    desc: 'Save up to 100%. T&C Apply',
    payload : {
      action: 'rewardTxn',
      paymentMethod: 'TWID',
      directWalletToken: '',
      endUrls: [environment.juspayEndUrl]
     }
  },
  CREDIT_CARD: {
    pgName: 'CREDIT_CARD',
    name: 'Credit Card',
    icon: '../../../../assets/img/credit-card.svg',
    payload: {
      paymentMethod: '',
      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardSecurityCode: '',
      saveToLocker: false,
      nameOnCard: '',
      action: 'cardTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'CARD',
      payment_method: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  DEBIT_CARD: {
    pgName: 'DEBIT_CARD',
    name: 'Debit Cards',
    icon: '../../../../assets/img/debit-card.svg',
    payload: {
      paymentMethod: '',
      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardSecurityCode: '',
      saveToLocker: false,
      nameOnCard: '',
      action: 'cardTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'CARD',
      payment_method: '',
      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardSecurityCode: '',
      saveToLocker: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  AUTO_DEBIT: {
    pgName: 'AUTO_DEBIT',
  },
  saved_CREDIT_CARD: {
    pgName: 'saved_CREDIT_CARD',
    name: 'Credit Card',
    icon: '../../../../assets/img/credit-card.svg',
    payload: {
      paymentMethod: '',
      cardToken: '',
      cardSecurityCode: '',
      action: 'cardTxn',
      saveToLocker: true
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'CARD',
      payment_method: '',
      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardSecurityCode: '',
      cardToken: '',
      shouldCreateMandate: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  saved_DEBIT_CARD: {
    pgName: 'saved_DEBIT_CARD',
    icon: '../../../../assets/img/debit-card.svg',
    name: 'Debit Cards',
    payload: {
      paymentMethod: '',
      cardToken: '',
      cardSecurityCode: '',
      action: 'cardTxn',
      saveToLocker: true
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'CARD',
      payment_method: '',
      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardSecurityCode: '',
      cardToken: '',
      shouldCreateMandate: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  SODEXO: {
    pgName: 'SODEXO',
    icon: '../../../../assets/img/sodexo.svg',
    name: 'Pluxee',
    payload: {
      paymentMethod: 'SODEXO',
      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardSecurityCode: '',
      saveToLocker: false,
      nameOnCard: '',
      action: 'cardTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'CARD',
      payment_method: 'SODEXO',
      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardSecurityCode: '',
      saveToLocker: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  EDENRED: {
    pgName: 'EDENRED',
    name: 'Edenred',
    icon: '',
    payload: {
      paymentMethod: 'MASTERCARD',
      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardSecurityCode: '',
      saveToLocker: false,
      nameOnCard: '',
      action: 'cardTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'CARD',
      payment_method: '',
      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardSecurityCode: '',
      saveToLocker: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  saved_SODEXO: {
    pgName: 'saved_SODEXO',
    name: 'Pluxee',
    icon: '../../../../assets/img/sodexo.svg',
    payload: {
      paymentMethod: 'SODEXO',
      cardToken: '',
      cardSecurityCode: '',
      action: 'cardTxn',
      saveToLocker: true
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'CARD',
      payment_method: '',
      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardSecurityCode: '',
      cardToken: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  saved_EDENRED: {
    pgName: 'saved_EDENRED',
    name: 'Edenred',
    icon: '',
    payload: {
      paymentMethod: '',
      cardToken: '',
      cardSecurityCode: '',
      action: 'MASTERCARD',
      saveToLocker: true
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'CARD',
      payment_method: '',
      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardSecurityCode: '',
      cardToken: '',
      redirect_after_payment: true,
      format: 'json'
    }
  },
  NET_BANKING: {
    pgName: 'NET_BANKING',
    name: 'Net Banking',
    icon: '../../../../assets/img/other-banks.svg',
    payload: {
      paymentMethod: '',
      action: 'nbTxn'
    },
    webPayload: {
      merchant_id: 'milkbasket',
      payment_method_type: 'NB',
      payment_method: '',
      redirect_after_payment: true,
      format: 'json'
    }
  }
};

export const PG_WISE_JIOPAY_PAY_LOAD = {
  SODEXO: {
    pgName: 'Sodexo',
  },
}

export const REQUEST_COLLECTION_API_ERRORS = {
  CASH_COLLECTION_EXISTS: 'cash_collection_exists'
};

export const CASH_COLLECTION_REQUEST_UPDATE_CONFIRMATION = {
  BUTTONS: {
    NO: { text: 'No', role: 'cancel', handler: null },
    YES: { text: 'Yes', handler: null, cssClass: 'alert-button-primary-outline' }
  },
  MESSAGE: '<br>Do you want to update your request?',
  CSS_CLASS: 'alert-custom',
  BACKDROP_DISMISS: false
};

export const EVENT_SOURCES = {
  REQUEST_CASH_COLLECTION: 'Request Cash Collection',
  SHOPPING_CREDIT: 'Shopping Credit'
};

export const CASH_COLLECTION_REMOVAL_INFO_DEFAULT_DATA = {
  heading: 'We\’re discontinuing this service till further notice.',
  message: 'We\’re taking all measures to meet COVID-19 exigencies.<p class="ion-margin-top">You can try our online payment options. They are easy and fast.</p>',
  ctaData: 'Okay, Pay Online',
  showInfo: true
};

export const FOOD_WALLET = {
  processingFeePercentage: {
    percentage: 3,
    calculate: 0
  },

};

export const CASH_COLLECTION_REMOVAL_INFO = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'icon-close alert-button-close icon-base icons-property icon-dark'
    },
    OKAY: { text: '', handler: null }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    HEADER_TEXT: '',
    MESSAGE: ''
  },
  CSS_CLASS: 'alert-custom',
};

export const CARD_ERROR_MESSAGES = {
  EDENRED_INVALID_CARD: 'Entered EDENRED CARD NUMBER is not valid',
  SODEXO_INVALID_CARD: 'Transition not supported for the entered card type',
  CREDIT_INVALID_CARD: 'Entered CREDIT CARD NUMBER is not valid',
  DEBIT_INVALID_CARD: 'Entered DEBIT CARD NUMBER is not valid',
  AUTO_DEBIT_INVALID_CARD: 'Entered CARD is not valid for Auto Debit',
  INVALID_CARD: 'Entered CARD NUMBER is not valid',
  CVV_INFO: 'Cvv info present in the back'
};

export const EDENRED_BIN = '524469';
export const SODEXO_BIN = '637513';
export const SAVED_CARD_DEFAULT_CVV = '786';

export const WALLET_ELIGIBILITY_STATUS = {
  SUCCESS: 'SUCCESS',
  INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  BAD_REQUEST: 'BAD_REQUEST',
  INVALID_DATA: 'INVALID_DATA',
  NOT_ACCESSIBLE: 'NOT_ACCESSIBLE',
  PENDING_DUES: 'PENDING_DUES',
  UNAUTHORIZED: 'PENDING_DUES'
};

export const PAYMENT_OPTION_TAGS = {
  NEW: 'NEW',
  OFFER: 'OFFER'
};

export const CARD_TYPE = {
  AMEX: {
    maxCardLength: 15,
    minCardLength: 15,
    cvvLength: 4,
    cvvPattern: /^[0-9]{4}$/,
    cardPattern: /^[0-9]{15}$/,
    gaps: [ 4, 10 ],
    icon: '../../../../assets/img/amex.svg'
  },
  DINERS: {
    maxCardLength: 14,
    minCardLength: 14,
    cvvLength: 3,
    cvvPattern: /^[0-9]{3}$/,
    cardPattern: /^[0-9]{14}$/,
    gaps: [ 4, 10 ],
    icon: '../../../../assets/img/diners.svg'
  },
  JCB: {
    maxCardLength: 16,
    minCardLength: 16,
    cvvLength: 3,
    cvvPattern: /^[0-9]{3}$/,
    cardPattern: /^[0-9]{16}$/,
    gaps: [ 4, 10 ],
    icon: '../../../../assets/img/jcb.svg'
  },
  LASER: {
    maxCardLength: 19,
    minCardLength: 16,
    cvvLength: 3,
    cvvPattern: /^[0-9]{3}$/,
    cardPattern: /^[0-9]{16,19}$/,
    gaps: [ 4, 8, 12, 16 ],
    icon: '../../../../assets/img/LASER.png'
  },
  VISA: {
    maxCardLength: 16,
    minCardLength: 16,
    cvvLength: 3,
    cvvPattern: /^[0-9]{3}$/,
    cardPattern: /^[0-9]{16}$/,
    gaps: [ 4, 8, 12 ],
    icon: '../../../../assets/img/visa.svg'
  },
  MASTERCARD: {
    maxCardLength: 16,
    minCardLength: 16,
    cvvLength: 3,
    cvvPattern: /^[0-9]{3}$/,
    cardPattern: /^[0-9]{16}$/,
    gaps: [ 4, 8, 12 ],
    icon: '../../../../assets/img/mastercard.svg'
  },
  MESTRO: {
    maxCardLength: 19,
    minCardLength: 12,
    cvvLength: 3,
    cvvPattern: /^[0-9]{3}$/,
    cardPattern: /^[0-9]{12,19}$/,
    gaps: [ 4, 8, 12, 16 ],
    icon: '../../../../assets/img/maestro.svg'
  },
  RUPAY: {
    maxCardLength: 16,
    minCardLength: 16,
    cvvLength: 3,
    cvvPattern: /^[0-9]{3}$/,
    cardPattern: /^[0-9]{16}$/,
    gaps: [ 4, 8, 12 ],
    icon: '../../../../assets/img/rupay.svg'
  },
  ELECTRON: {
    maxCardLength: 16,
    minCardLength: 16,
    cvvLength: 3,
    cvvPattern: /^[0-9]{3}$/,
    cardPattern: /^[0-9]{16}$/,
    gaps: [ 4, 8, 12 ],
    icon: '../../../../assets/img/credit-card.svg'
  },
  SODEXO: {
    maxCardLength: 16,
    minCardLength: 16,
    cvvLength: 3,
    cvvPattern: /^[0-9]{3}$/,
    cardPattern: /^[0-9]{16}$/,
    gaps: [ 4, 8, 12 ]
  },
  DISCOVER: {
    maxCardLength: 16,
    minCardLength: 16,
    cvvLength: 3,
    cvvPattern: /^[0-9]{3}$/,
    cardPattern: /^[0-9]{16}$/,
    gaps: [ 4, 8, 12 ],
    icon: '../../../../assets/img/discover.svg'
  },
  DEFAULT: {
    maxCardLength: 16,
    minCardLength: 16,
    cvvLength: 3,
    cvvPattern: /^[0-9]{3,4}$/,
    cardPattern: /^[0-9]{16}$/,
    gaps: [ 4, 8, 12 ],
    icon: '../../../../assets/img/credit-card.svg'
  }
};

export const CARD_BRANDS = {
  AMEX : 'AMEX'
}

export const SHOPPING_CREDIT = {
  BALANCE_INFO: {
    BRAND_LOGO_URL: 'assets/img/mb-logo.svg',
    BALANCE: 'Your balance',
    MAX_RECHARGE_AMOUNT: 'You can add upto ₹ 50,000',
    RECHARGE_AMOUNT_LABEL: 'Please enter an amount',
  },
  NOTES_LABEL: 'Please Note',
};

export const TRANSACTION_HISTORY = {
  EMPTY_STATE: {
    EMPTY_STATE_IMG: 'assets/img/transactions-empty-state.svg',
    NO_TRANSACTION_HISTORY: `Currently you don't have any<br>
    transaction history.<br><br>
    Want to add some?`
  }
}

export const PROMO_CODE_APPLY = {
  BUTTONS: {
    OKAY: {
      text: 'Cheers!',
      handler: null,
      cssClass: 'clear-btn f-w-m margin-0',
    }
  },
  META_DATA: {
    MESSAGE: `  <div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
    <img alt="Promo code applied" src="../../../../assets/img/successPopup.svg" />
    <p>Promo code <span class="ion-text-uppercase f-w-b">{promoCode}</span> applied</p> 
    <h2 class="ion-text-center payment-title">{shortDescription}</h2>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group'
};
export const TRANSACTION_FAILED = {
  BUTTONS: {
    OKAY: {
      text: 'Ok, Got It!',
      handler: null,
      cssClass: 'clear-btn f-w-m margin-0',
    }
  },
  META_DATA: {
    MESSAGE: `  <div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
    <img alt="Payment is failed" src="../../../../assets/img/error-upi-popup.svg" />
    <p>Payment is failed</p> 
    <h2 class="ion-text-center payment-title">##ERROR_MESSAGE##</h2>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group custom-position'
};

export const PAYMENT_SUCCESS = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    },
    OKAY: {
      text: 'Ok, Got it!',
      handler: null,
      cssClass: '',
    }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    MESSAGE: ``
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group custom-position food-wallet'
};

export const FOOD_WALLET_INFO = `<div class="fw-list">
  <p class="ion-text-center fw-subHeading">Use your Food Wallet to buy</p>
  <div class="d-flex ion-align-items-center">
    <img src="assets/img/fw-milk.png" alt="Milk info">
    <p>Milk and dairy products.</p>
  </div>
  <div class="d-flex ion-align-items-center">
    <img src="assets/img/fw-fruit.png" alt="Food info">
    <p>Fresh fruits, vegetables, groceries & other edibles.</p>
  </div>
  <div class="d-flex ion-align-items-center">
    <img src="assets/img/fw-non-fruit.png" alt="Non-food info">
    <p class="txt-red">Cannot be used on non-edibles like cleaning products, services etc.</p>
  </div>
</div> `;

export const WALLET_MESSAGE = {
  NON_FOOD_INFO: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
    <img src="assets/img/successPopup.svg" alt="Payment success">
    <h2 class="ion-text-center">₹ ##balance## has been added to your Wallet</h2>
    <p class="ion-text-center fw-subHeading pb-8">You can use this balance on<br/> Milkbasket's entire range of products &<br/> services</p>
  </div>`,

  FOOD_INFO: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
    <img src="assets/img/successPopup.svg" alt="Payment success">
    <h2 class="ion-text-center">₹ ##balance## added to Food Wallet</h2>
    ${FOOD_WALLET_INFO}
  </div>`
};
export const SELECTED_OFFER = {
  imgUrl: 'assets/img/add-address.svg',
  termsAndCondition: 'Terms and conditions apply'
}

export const PROMO_CODE_COPIED = 'Copied to Clipboard';

export const TransactionTypo = {
  order: {
    title: 'Order'
  },
  order_refund: {
    title: 'Order Refund',
    subTitle: 'Refund Money Credited',
  },
  refund_revert: {
    title: 'Refund Revert',
    subTitle: 'Refund Money Debited',
  },
  value_pass: {
    title: 'Value Pass Buy',
    subTitle: 'Savings on order'
  },
  neft_refund: {
    title: 'NEFT Refund',
    subTitle: 'Money credited to your bank a/c'
  },
  bulk_order: {
    title: 'Bulk Order',
  },
  cashback: {
    title: 'Cashback',
    subTitle: 'Savings credited to your wallet'
  },
  membership: {
    subTitle: 'Savings Membership Fees'
  }
}

export const MAHA_CASHBACK = {
  TC: 'TnCs',
  URL: 'https://s3.ap-south-1.amazonaws.com/adhoc.milkbasket.com/HTML/cash-back-tnc.html'
};

export const MODAL_IDS = {
  PAYMENT_OPTIONS: 'paymentOptions',
  PAYMENT_TYPE: 'pgType',
  LOW_BALANCE_POPUP: 'lowBalancePupup',
  OFFER_DESCRIPTION: 'offerDescription',
  FAQ_DATA: 'faq',
  PRODUCT_VARIANT_POPUP: 'ProductVariantPopup'
}

export const OFFER_SOURCE = {
  LOW_BAL : 'low balance popup',
  SHOPPING_CREDITS: 'shopping credits page',
  PAYMENT: 'payments page'
}

export const PAYMENT_METHOD = {
    UPI : 'UPI',
    DEBIT_CARD: 'DEBIT_CARD',
    CREDIT_CARD: 'CREDIT_CARD',
    NET_BANKING: 'NET_BANKING',
    PAY_LATER:'PAY_LATER',
    FOOD_WALLET:'FOOD_WALLET',
    CRED:'CRED',
    WALLET: 'WALLET',
    REDEEM_REWARDS: 'REDEEM_REWARDS'
}

export const PAYMENT_VALUES = {
  TWID : 'TWID',
  CRED: 'cred',
  PAYTM_UPI: 'paytm_upi',
  GOOGLE_PAY: 'google_pay',
  PHONEPE: 'phone_pe',
}

export const PAYMENT_GATEWAY = {
  JUSPAY: 'JUSPAY',
  JIOPAY: 'JIOPAY'
}

export const CVV_ALERT = {
  BUTTONS: {
    CLOSE: {
      text: 'Ok, Got it!',
      handler: null,
      role: 'cancel',
      cssClass: 'clear-btn f-w-m margin-0'
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details cvv-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <p class="pt-0">What is CVV Number?</p> 
    <img alt="What is CVV Number?" src="assets/img/cvv-card.png" />
    <h2 class="f-w-n text-size-lg margin-vertical-medium pb-0"> The CVV Number is the last digits <br> at the back of your card. For <br> American Express 
    Cards, the <br> CVV is a 4-digit number on <br> the front of the card.</h2>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group '
};

export const ADDITIONAL_CHARGES_TYPE = {
  OTDF: 'OTDF',
  MEMBERSHIP: 'Membership',
  INSUFF_NF_CREDITS: 'insufficient_non_food_credits'
}