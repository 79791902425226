//#region Imports

//#region Angular Imports
import { Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component'; 
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, EVENT_SOURCES } from '@Globals/constants/listing/constants';
import { PaymentOfferType } from '@Globals/constants/listing/enums';
import { PAYMENT_ROUTES } from '@Globals/constants/payment/routes';

import { environment } from '@Env/environment';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-payment-offer-block',
  templateUrl: './payment-offer.component.html',
  styleUrls: [ './payment-offer.component.scss' ], 
})
export class PaymentOfferComponent extends BaseCollectionComponent {
  selectedOffer;
  PaymentOfferType = PaymentOfferType;
  bannerPath = environment.bannerPath;

  mbOnInit(): void {
    this.dataService.paymentOfferAmountChanged.subscribe((amount) => {
      this.selectedOffer = this.masterDataService.masterData?.wallet_increase_offers.find(ele => ele.amount === amount);
    });
    super.mbOnInit();
  }

  navigateToAllOffers() {
    this.dataService.firebaseMasterData.paymentOffers = this.data;
    let componentType = this.props && this.props.componentType;
    this.eventsService.trackEvents(EVENT_NAMES.PAYMENT_OFFERS,
      { Source: this.PaymentOfferType.HomeStrip === componentType ? EVENT_SOURCES.FREE_SUBSCRIPTION : EVENT_SOURCES.SHOPPING_CREDITS });
    this.dataService.checkAndCloseAllModals();
    this.navCtrl.navigateForward(
      PAYMENT_ROUTES.BASE +
      PAYMENT_ROUTES.OFFERS, { animated: false }
    );
  }

  fetchImageUrl(url: string) {
    if (/https/i.test(url)) {
      return url;
    } else {
      return environment.bannerPath + url;
    }
  }

  async showPaymentOfferDetails(offer) {
    this.dataService.checkAndCloseAllModals();
    if (offer.url) {
      this.navigateToCollectionList(offer);
    } else {
      this.modalService.openPaymentOfferDetailModal({ offer });
    }
  }
}
