//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { ProductVariantListComponent } from '@Shared/product-variant-list/product-variant-list.component';
import { ListingModule } from '@Features/product-list/components/vertical/listing/listing.module';
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ListingModule
  ],
  declarations: [ProductVariantListComponent],
  exports: [ProductVariantListComponent],
  providers: []
})
export class ProductVariantListModule extends DefaultModule { }