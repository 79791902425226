export enum SuperChargeType {
    Super_Charge_Purchased,
    Super_Charge_Cash_Collection,
    Super_Charge_New,
    Super_Charge_Payment_Pending,
    Super_Charge_Expiry,
    Super_Charge_On_Going_Journey,
    Super_Charge_Plan
}

export enum CardBrandType {
    SODEXO = 'SODEXO',
    EDENRED = 'MASTERCARD',
    DEBIT_CARD = 'DEBIT',
    CREDIT_CARD = 'CREDIT'
}

export enum TransactionHistoryIconType {
    order = 'assets/img/order.svg',
    purchase = 'assets/img/purchase.svg',
    membership = 'assets/img/membership.svg',
    value_pass = 'assets/img/valuePassBuy.svg',
    neft_Refund = 'assets/img/neftRefund.svg',
    bulk_order = 'assets/img/bulkOrder.svg',
    order_refund = 'assets/img/orderRefund.svg',
    cashback = 'assets/img/cashback.svg',
    refund_fee_revert = 'assets/img/refundFeeRevert.svg',
    order_fee_refund = 'assets/img/orderRefund.svg',  
}

export enum TransactionType {
    order = 'order',
    purchase = 'purchase',
    membership = 'membership',
    value_pass = 'value_pass',
    neft_refund = 'neft_refund',
    bulk_order = 'bulk_order',
    order_refund = 'order_refund',
    cashback = 'cashback',
    refund_revert = 'refund_revert'
}

export enum TransactionTypo {
    order = 'Order',
    order_refund = 'Order Refund',
    purchase = 'Purchase',
    value_pass = 'Value Pass',
    refund_fee_revert = 'Refund Revert',
    order_fee_refund = 'Order Fee Refund',
    membership = 'Membership'
}
export enum CashbackType {
    REDEEM = 'REDEEM',
    OFFER = 'OFFER',
}
export enum TRANSACTION_TYPE {
    CREDITED= 'credited',
    DEBITED= 'debited',
  }