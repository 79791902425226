//#region Imports
//#region Angular Imports
import { Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports
//#endregion Imports

@Component({
  selector: 'mbpc-twid-logo',
  templateUrl: './twid-logo.component.html',
  styleUrls: ['./twid-logo.component.scss'],
})
export class TwidLogosComponent extends DefaultComponent {
}
