//#region Imports

//#region Angular Imports
import { Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController, NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { CORE_ROUTES } from '@Globals/constants/listing/routes';
import { InfoPopupData } from '@Globals/interfaces/listing/interfaces';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss'],
})

export class InfoPopupComponent extends DefaultComponent {
  @Input() data: InfoPopupData;

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
  ) {
    super();
   }

  mbOnInit(): void { }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }

  loginSignUp(source?): void {
    this.modalCtrl.getTop().then(topModal => {
      if (topModal) {
        this.modalCtrl.dismiss();
      }
    });
    const navigateTo = source
      ? CORE_ROUTES.BOARDING + '/' + source
      : CORE_ROUTES.BOARDING;
    this.navCtrl.navigateRoot(navigateTo);
  }
}
