<ion-list lines="none" class="ion-no-padding ion-list-image-card ion-list-grid book-store-list layout-spacer">
  <ion-list-header class="ion-list-header" *ngIf="data?.name">
    <ion-row class="ion-justify-content-between full-width ion-align-items-center">
      <div>
        <span class="padding-right-small">{{ data.name }}</span>
        <p *ngIf="data?.description">{{ data.description }}</p>
      </div>
    </ion-row>
  </ion-list-header>

  <ion-item lines="none" slot="start" class="ion-padding-horizontal">
    <ion-grid class="ion-no-padding book-store">
      <ion-row *ngFor="let row of data?.table?.rows;let rowIndex=index;">
        <ion-col size="6" [class]="'ion-align-self-start col-list-'+rowIndex+colIndex"
          (click)="navigateToCollectionList(col)"
          *ngFor="let col of row.columns;let colIndex=index;">
          <div [class]="'col-inner-content-'+rowIndex+colIndex">
            <app-img-on-demand [actualImgSrc]="bannerFilePath + col.images.leafBanner.image"
              [defaultImgSrc]="'assets/img/default-banner.png'" [showDefaultImage]="props?.showDefaultImage"
              alt_image="img_banner_{{col.images.leafBanner.image}}"
              [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER" [useImageOnDemand]="false"
              [imageName]="col.images.leafBanner.image">
            </app-img-on-demand>
            <ion-button color="light" *ngIf="col.title.text" shape="round" size="small">{{ col.title.text }} <ion-icon
                name="chevron-forward-custom"></ion-icon>
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>

</ion-list>