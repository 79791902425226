<form class="form email-opt-form" #otpForm="ngForm" (keydown.enter)="$event.preventDefault()">
  <div class="f-group otp-input form-content otp-spaccer" [ngClass]="{'has-error' : isError}">
    <ion-label position="floating" class="f-w-b ion-label-spaccer">Enter OTP</ion-label>
    <ion-item class="ion-no-padding ion-invalid ion-item-spaccer" lines="none" color="none">
      <ion-input type="tel" title="otp1" name="otp1" required [(ngModel)]="OTP.otp1" ngControl="otp1" #otp1 required
        (click)="otp1.setFocus()">
      </ion-input>

      <ion-input type="tel" name="otp2" title="otp2" [(ngModel)]="OTP.otp2" ngControl="otp2" #otp2 required
        (click)="otp2.setFocus()" (keyup.backspace)="otp1.setFocus()">
      </ion-input>

      <ion-input type="tel" name="otp3" title="otp3" required [(ngModel)]="OTP.otp3" ngControl="otp3" #otp3
        (click)="otp3.setFocus()" (keyup.backspace)="otp2.setFocus()">
      </ion-input>

      <ion-input type="tel" name="otp4" title="otp4" required [(ngModel)]="OTP.otp4" ngControl="otp4" #otp4
        (click)="otp4.setFocus()" (keyup.backspace)="otp3.setFocus()">
      </ion-input>

      <ion-input #hidden style="opacity:0"></ion-input>
    </ion-item>
    <ion-text class="help-text">
      <span color="danger" *ngIf="isError">{{errorMessage}}</span>
      <span class="help-text-sender" *ngIf="!isError">An OTP has been sent to your E-mail ID</span>
    </ion-text>
  </div>
  <div class="resend resend-no d-flex ion-justify-content-center ion-align-items-center">
    <p class="help-text" [innerHTML]="updateOTPText ? 'A new OTP has been sent' : 'Didn’t receive the code'"></p>
    <div class="spinner">
      <ion-button [disabled]="!enableResend" class="resend-now no-ripple-effect" fill="clear" (click)="resend()"
        size="small">Resend Now
      </ion-button>
      <ng-container *ngIf="!enableResend">
        <mbs-count-down-timer [progress]="30" (updateTimer)="timerValue($event)"></mbs-count-down-timer>
      </ng-container>
    </div>
  </div>
</form>