<ion-list lines="none" class="ion-no-padding ion-list-image-card ion-list-grid gvc layout-spacer">
<ion-list-header class="ion-list-header" *ngIf="data?.name">
  <ion-row class="ion-justify-content-between full-width ion-align-items-center">
    <div>
      <span class="padding-right-small">{{ data.name }}</span>
      <p *ngIf="data?.description">{{ data.description }}</p>
    </div>
  </ion-row>
</ion-list-header>
<div class="festival-collection-widget"
  (click)="navigateToCollectionList(exploreCollectionData)">
  <div class="festival-collection padding-bottom-medium"
    [ngStyle]="{ 'background-image': 'url(' + backgroundUrl + ') ' }">
    <ng-container *ngIf="collectionTableData.table.rows[0]?.columns?.length === 5; else defaultView">
      <app-seven-grid-collection [collections]="collectionTableData" (click)="stopPropagation($event)"
        (navigate)="navigateToCollectionList($event.column)"
        [showDefaultImage]="props?.showDefaultImage"></app-seven-grid-collection>
    </ng-container>
    <ng-template #defaultView>
      <app-horizontal-collection [collections]="collectionTableData"
        [ngClass]="{ 'single-horizontal-collection': coloumIteration <= 4 }" (click)="stopPropagation($event)"
        (navigate)="navigateToCollectionList($event.column)"
        [showDefaultImage]="props?.showDefaultImage"></app-horizontal-collection>
    </ng-template>
    <mb-pl-single-brand [brandData]="productList" [showNew]="false" (click)="stopPropagation($event)"
      [showViewAll]="collectionTableData.table.rows[0]?.columns?.length > 2 ? true : false"
      [eventSource]="eventData?.Source" [eventData]="eventData">
    </mb-pl-single-brand>
  </div> 
</div>
</ion-list>
