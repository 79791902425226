//#region Imports

//#region Angular Imports
import { Component, AfterViewInit, ElementRef, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES } from '@Globals/constants/listing/constants';
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-info-pop-up-payment',
  templateUrl: './info-pop-up.component.html',
  styleUrls: ['./info-pop-up.component.scss'],
})
export class InfoPopUpPaymentComponent extends DefaultComponent {
  @Input() source: boolean;
  constructor(private modalCtrl: ModalController,
    public dataService: DataService,
    private elementRef: ElementRef,
    private eventsService: EventsService
  ) {
    super();
   }

   mbAfterViewInit() {
    this.elementRef.nativeElement.querySelector('#close-button').addEventListener('click', this.closeModal.bind(this));
    this.eventsService.trackEvents(EVENT_NAMES.INFO_POP_UP_VIEW, { Source: this.source });
  }

  closeModal() {
    this.eventsService.trackEvents(EVENT_NAMES.INFO_POP_CLICKED, { Source: this.source });
    this.modalCtrl.dismiss();
  }

}
