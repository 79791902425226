
<ion-list class="ion-no-padding horizontal-collection" >
 <ion-item class="ion-no-padding" lines="none" color="none">
    <div class="scroll" scroll-x="true" *ngFor="let row of collections?.table?.rows">
        <div class="ion-item-inner">
            <ion-card class="ion-card-custom fixed-size" (click)="triggerCounterChange(column)" *ngFor="let column of row.columns">
                    <div class="ion-no-padding img-holder" *ngIf="column.images.leafBanner.image">
                        <app-img-on-demand
                        [actualImgSrc]="bannerFilePath + column.images.leafBanner.image"
                        [defaultImgSrc]="'assets/img/default-banner.png'" [showDefaultImage]="showDefaultImage"
                        [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER"
                        alt_image="img_banner_{{column.images.leafBanner.image}}"
                        [useImageOnDemand]="false" [imageName]="column.images.leafBanner.image">
                      </app-img-on-demand> 
                    </div>
            </ion-card>
        </div>
    </div>
 </ion-item>
</ion-list>
