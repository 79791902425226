//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { MomentModule } from 'ngx-moment';
//#endregion Library Imports

//#region Module Imports
import { BannerModule } from '@Widgets/blocks/banner/banner.module';

import { PaymentOfferModule } from '@Shared/payment/payment-offer/payment-offer.module';
import { AutoPayModule } from '@Features/payment/auto-pay/auto-pay.module';
import { PromoCodeOfferModule } from '@Shared/payment/promo-code-offer/promo-code-offer.module';
import { PaymentOfferListingModule } from '@Shared/payment/payment-offer-listing/payment-offer-listing.module';

import { DefaultModule } from '@Lib/defaults/default.module';

import { LayoutModule } from '@Layouts/one/layout.module';

import { ShoppingCreditsRoutingModule } from '@Pages/payment/shopping-credits/shopping-credits-routing.module';

import { HeaderModule } from '@MbUi/headers/two/header.module';

// import { PaymentModule } from '@App/payment/payment.module';
// import { SharedModule } from '@App/shared/shared.module';
//#endregion Module Imports

//#region Component Imports
import { ShoppingCreditsPage } from '@Pages/payment/shopping-credits/shopping-credits.page';
import { PromocodeListingModule } from '@Features/payment/promocode-listing/promocode-listing.module';
import { PaymentOptionsComponent } from '@Pages/payment/shopping-credits/components/payment-options/payment-options.component';
import { TwidLogosComponent } from '@Pages/payment/shopping-credits/components/twid-logo/twid-logo.component';
//#endregion Component Imports

//#region Providers Imports
import { PaymentModalService } from '@Globals/providers/payment-modal/payment-modal-service';
import { PaymentSharedModule } from '@Shared/payment-shared/payment-shared.module';
import { AddressUnverifiedModule } from '@Shared/address-unverified/address-unverified.module';
import { AccountFreezeModule } from '@Shared/account-freeze/account-freeze.module';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ShoppingCreditsRoutingModule,
    LayoutModule,
    MomentModule,
    ScrollingModule,
    HeaderModule,
    BannerModule,
    AutoPayModule,
    PaymentOfferModule,
    PaymentOfferListingModule,
    PromoCodeOfferModule,
    PromocodeListingModule,
    PaymentSharedModule,
    AddressUnverifiedModule,
    AccountFreezeModule 
  ],
  declarations: [ShoppingCreditsPage,PaymentOptionsComponent,TwidLogosComponent],
  entryComponents: [ShoppingCreditsPage],
  exports: [ShoppingCreditsPage,PaymentOptionsComponent,TwidLogosComponent],
  providers: [ PaymentModalService]
})
export class ShoppingCreditsModule extends DefaultModule { }
