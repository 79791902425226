//#region Imports

//#region Angular Imports
import { Injectable } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { HttpService } from '@Globals/providers/http.service';
//#endregion Providers Imports

//#region Other Imports
import { LISTING_APIS } from '@Globals/constants/listing/apis';
//#endregion Other Imports

//#endregion Imports

@Injectable()
export class RateAppService {

  constructor(private httpService: HttpService) { }

  saveUserRating(dataToBeSent) {
    return this.httpService.post(LISTING_APIS.USER_RATING, dataToBeSent);
  }

  updateUserRating(dataToBeSent) {
    return this.httpService.put(LISTING_APIS.USER_RATING, dataToBeSent);
  }

  NPSUserRating(dataToBeSent) {
    return this.httpService.post(LISTING_APIS.USER_NPS_RATING, dataToBeSent);
  }

}
