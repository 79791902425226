export const REGISTRATION_CONSTANTS = {
  LOG_IN: 'Log In',
  LOGIN_FROM_SIGNUP_HEADING: 'Looks like <br/> you’ve been here before',
  SIGN_UP: 'Sign Up',
  LOGIN_WITH_OTP: 'Login With OTP',
  CONTINUE: 'Continue',
  RADIX: 10,
  SIGNUP_MOBILE_HEADING: 'Please Enter',
  SIGNUP_MOBILE_SUBHEADING: 'Your Mobile Number',
  SIGNUP_MOBILE_INFO: 'Don’t worry! We’ll not spam you.',
  PLEASE_LOGIN_TO_CONTINUE: 'Please Login to continue.'
};

export const SIGNUP_ERROR_MESSAGES = {
  HOUSE_NO_REQUIRED: 'House No is required',
  FIRST_NAME_REQUIRED: 'First Name is required',
  LAST_NAME_REQUIRED: 'Last Name is required',
  EMAIL_REQUIRED: 'Email Id is required',
  EMAIL_INVALID: 'Please enter valid E-mail Address',
  CITY_REQUIRED: 'City is required',
  SOCIETY_REQUIRED: 'Society is required',
  NAME_REQUIRED: 'Name is required'
};

export const EVENT_NAMES = {
  CITY_NOT_SERVICEABLE: 'city_not_serviceable',
  SIGNUP_SPLASH_SCREEN: 'signup_splash_screen',
  SIGNUP_CITY: 'Sign up - City',
  SIGNUP_CITY_NOT_SERVICEABLE: 'Sign up - City Not Serviceable',
  REFERRAL_START: 'Referral-Start',
  REFERRAL_COPY: 'Referral-Copy',
  SIGNUP_MOBILE_NO_ENTRY: 'Sign-up - Mobile No entry',
  SIGN_UP_MOBILE_NO_VERIFICATION: 'Sign-up - Mobile No verification',
  SIGN_UP_MOBILE_NO_VERIFIED: 'Sign-up - Mobile No verified',
  OTP_VERIFICATION: 'otp verification',
  SIGNUP_COMPLETE: 'Signup Complete',
  SIGNUP_SOCIETY_PAGE_VIEW: 'Signup - Society PageView',
  SIGNUP_SOCIETY_SELECTED: 'Signup Society Selected',
  SOCIETY_SEARCHED: 'Society Searched',
  TRUECALLER_VERICATION: 'truecaller verification',
  OTP_AUTO_READ: 'OTP auto read',
  SIGNUP_CODE_APPLIED: 'Signup - Code applied',
  DOING_LOGIN_WITH_OTP: 'Doing login with otp',
  DOING_SIGN_UP_WITH_OTP: 'Doing sign-up with otp',
  SIGN_UP_CALL_INSTEAD: 'Sign-up - Call Instead',
  LOGIN_WITH_PASSWORD: 'Login with password',
  RESEND_OTP: 'Click on resend OTP',
  OTP_VERIFIED: 'OTP verified',
  ENABLE_LOCATION_CLICKED: 'Click on Enable location',
  TOWER_SEARCHED: 'Tower searched',
  OTP_TRIGGER: 'OTP trigger',
  ADDRESS_UPDATED: 'Address update - complete',
  HUB_CHANGE_ALERT: 'Address update - Hub/city change alert presented',
  HUB_CHANGE_CONFIRM:'Address update - Hub/city change confirmed',
  HUB_CHANGE_DENIED:'Address update - Hub/city change denied',
  MARKER_SET:'Location marker - set',
  MARKER_DRAGGED:'Location-marker - dragged',
  ADD_ADDRESS:'Click on Add address',
  SOCIETY_CONFIRMED:'Society-confirmed',
  CHANGE_SOCIETY: 'Click on change society'
};

export const EVENT_SOURCES = {
  SIGNUP: 'signup',
  CITY_NOT_SERVICEABLE: 'city_not_serviceable',
  SIGN_UP_CITY: 'sign up-city',
  SIGN_UP: 'sign up',
  SIGNUP_SOCIETY_PAGE_VIEW: 'Signup - Society PageView',
  TRUECALLER_VERICATION: 'truecaller verification',
  SEARCHED: 'searched',
  NEARBY_SOCIETIES: 'nearby_societies',
  ATB_ERROR: 'ATB error',
  LOCATION_ACCESS: 'location-access-page',
  TOWER_SELECTION: 'Tower selection page',
  SOCIETY_CONFIRMATION: 'Society confirmation page',
  ADD_ADDRESS: 'Add address page'
};

export const PROMOCODE_TYPES = {
  REFERRAL: 'referral',
  INVITE: 'invite'
};

export const DATA_UI_EVENT_SOURCE_TYPES = {
  APP_INITIALIZATION: 'App Component Initialization',
  APP_RESUME: 'App Resume',
  DATA_UI_INTERVAL: 'Data Ui Interval',
  SIGN_UP_PAGE: "Sign Up Page",
  DEEPLINK_SERVICE: "Deeplink Service",
  GEOLOCATION_SERVICE_REGISTRATION: "Geolocation Service Registration",
  GEOLOCATION_SERVICE_CHANGE_ADDRESS: "Geolocation Service Change Address",
  SESSION_LOGOUT: "Session Logout",
  LOGIN_PAGE: "Login Page",
  SIGNUP_WITH_NEW_NUMBER: "Signup With New Number",
  CONTINUE_WITH_EXISTING_NUMBER: "Continue With Existing Number",
  LOGIN_HOOK: "Login Hook",
  SET_DEFAULT_AND_GO_HOME: "Set Default City And Go Home"
};

export const DATA_UI_TIMER = 600000;//10 minutes
export const MASTER_DATA_CATEGORY_EXPIRATION_TIME = 3600000;//60 minutes
export const MASTER_DATA_BANNER_EXPIRATION_TIME = 1800000;//30 minutes
export const TWID_ELIGIBILITY_EXPIRATION_TIME = 21600000;//6 hour
export const HOME_COLLECTION_EXPIRATION_TIME = 0;

export const TRUECALLER_LOGIN = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icon-base icons-property icon-dark'
    },
    OKAY: { text: 'Ok', handler: null }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    HEADER_TEXT: 'You are an already register user.',
    MESSAGE: 'Press Ok to continue to app.'
  },
  CSS_CLASS: 'alert-custom'
};

export const ALREADY_REGISTERED_NUMBER = 'mobile_error';

export const SIGNUP_ERRORS = {
  NO_LONGER_DELIVER: 'welcome_msg_no_longer_deliver',
  NOT_IN_SERVICE_AREA: 'welcome_msg_not_in_service_area',
  UPCOMING_DELIVERY: 'welcome_msg_upcoming_deliver',
  DUPLICATE_ADDRESS: "DUPLICATE_ADDRESS",
  DUPLICATE_UDID: "DUPLICATE_UDID"
};
export const EMPTYCART_ALERT = {
  BUTTONS: {
    CLOSE: {
      text: 'Continue',
      handler: null,
      cssClass: 'f-w-m'
    },
    OKAY: { 
    text: 'Back to Home', 
    handler: null,
    cssClass: 'clear-btn f-w-m t-t-n' 
  }
  },
  META_DATA: {
    MESSAGE: `  <div class="alert-details d-flex ion-justify-content-cente ion-align-items-center ion-text-center">
    <img src="assets/img/empty-cart.svg" alt="Empty Cart" />
    <p>Empty Cart</p> 
    <h2 class="ion-text-center">New address registration will remove all previous subscriptions & items from your cart!</h2>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group'
};

export const INVALID_ADDRESS = {
  META_DATA: {
    MESSAGE: `  <div class="alert-details d-flex ion-justify-content-cente ion-align-items-center ion-text-center">
    <img src="assets/img/empty-cart.svg" alt="0 Items in Cart">
    <p>0 Items in Cart</p> 
    <h2 class="ion-text-center">Sorry, your new address <br> isn't serviceable! Due to <br> this your current items & <br> subscriptions will be <br> removed.</h2>
  </div>`
  },
}

export const OTP_ENTRY_TYPES = {
  MANUAL: 'manual',
  AUTO_READ: 'auto_read'
};

export const USER_DOES_NOT_EXIST = "generate_login_otp_error";
export const LOCATION_INFO = '<h4>Your order will be delivered here</h4> <p>Please place the pin accurately on the map</p>';
export const MY_LOCATION = 'My Location';
export const SUSPENDED_USER = `generate_login_otp_error_account_suspended`;

export const INPUT = {
  APARTMENT: 'Apartment',
  TOWER: 'Tower',
  FLAT: 'Flat'
}

export const INPUT_VALUE = {
  LOCALITY_HOUSE: 'localityHouse',
  USER_SIGNUP_HOUSE: 'UserSignupHouse',
  BUILDING_NAME:'BuildingName',
  STREET:'Street',
  FLOOR:'Floor',
  LANDMARK:'Landmark',
  HOUSE_NO:'houseNo',
  TOWER_NAME:'towerName'
}

export const ADDRESS_VALIDATION_TYPE = {
  REGISTER: 'Register',
  CHANGE_ADDRESS: 'ChangeAddress'
}

export interface LoginObject {
  mb_version: MbVersion
  source: string
  mobile: string
  otp: string
}

export interface MbVersion {
  app_store: string
  code_push: string
}
