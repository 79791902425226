<!-- Collections blocks starts here -->
<ng-container *ngIf="type" [ngSwitch]="type">
  <ng-container *ngSwitchCase="'c_image'"> 
    <wgt-image-collection-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-image-collection-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_bookstore_widget'">
    <wgt-book-store-collection-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-book-store-collection-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_payment_offer'">
    <wgt-payment-offer-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-payment-offer-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_video'">
    <wgt-video-collection-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-video-collection-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_lottie'">
    <wgt-lottie-collection-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-lottie-collection-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_horizontal_widget'">
    <wgt-horizontal-widget-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-horizontal-widget-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_mBeyond'">
    <wgt-mBeyond--widget-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-mBeyond--widget-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_banner'">
    <wgt-collection-banner-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-collection-banner-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_brand_launch'">
    <wgt-brand-launch-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-brand-launch-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_product_launch'">
    <wgt-product-launch-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-product-launch-block>
  </ng-container> 
  <ng-container *ngSwitchCase="'c_thee_column_in_single_row'">
    <wgt-three-column-in-single-row-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-three-column-in-single-row-block>
  </ng-container> 
  <ng-container *ngSwitchCase="'c_explore'">
    <wgt-explore-collection-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-explore-collection-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_auto_swipe'">
    <wgt-auto-swipe-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-auto-swipe-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_horizontal_products_extra_width'">
    <wgt-horizontal-products-extra-width-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-horizontal-products-extra-width-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_product_launch_listing'">
    <wgt-product-launch-list-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-product-launch-list-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_recommendation_widget'">
    <wgt-recommendation-products-list-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-recommendation-products-list-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_two_row'">
    <wgt-two-row-products-list-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-two-row-products-list-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_recurring'">
    <wgt-recommendation-products-list-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-recommendation-products-list-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_video_card'">
    <wgt-video-card-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-video-card-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_deals_of_the_day'">
    <wgt-deals-of-the-day-list-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-deals-of-the-day-list-block>
  </ng-container>
  <ng-container *ngSwitchCase="'c_category_collection'">
    <wgt-category-collection-block [schema]="schema" [panelSchema]="panelSchema" [data]="data" [props]="props"></wgt-category-collection-block>
  </ng-container>
</ng-container>
<!-- Collections blocks ends here -->