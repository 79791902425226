//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { ModalService } from '@Globals/providers/modal.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, EVENT_SOURCES } from '@Globals/constants/listing/constants';
import { PaymentOfferType } from '@Globals/constants/listing/enums';
import { PAYMENT_ROUTES } from '@Globals/constants/payment/routes';

import { environment } from '@Env/environment';
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Other Imports

//#endregion Imports


@Component({
  selector: 'mbs-payment-offer',
  templateUrl: './payment-offer.component.html',
  styleUrls: [ './payment-offer.component.scss' ],
})
export class PaymentOfferComponent extends DefaultComponent {
  selectedOffer;
  @Input() paymentOffers: any;
  @Input() componentType: PaymentOfferType;
  @Output() navigate = new EventEmitter();
  PaymentOfferType = PaymentOfferType;
  bannerPath = environment.bannerPath;
  constructor (
    private navCtrl: NavController,
    public modalService: ModalService,
    public masterDataService: MasterDataService,
    private dataService: DataService,
    private eventsService: EventsService) { 
      super();
    }

  mbOnInit() {
    this.dataService.paymentOfferAmountChanged.subscribe((amount) => {
      this.selectedOffer = this.masterDataService.masterData?.wallet_increase_offers.find(ele => ele.amount === amount);
    });
  }
  navigateToAllOffers() {
    this.dataService.firebaseMasterData.paymentOffers = this.paymentOffers;
    this.eventsService.trackEvents(EVENT_NAMES.PAYMENT_OFFERS,
      { Source: this.PaymentOfferType.HomeStrip === this.componentType ? EVENT_SOURCES.FREE_SUBSCRIPTION : EVENT_SOURCES.SHOPPING_CREDITS });
    this.dataService.checkAndCloseAllModals();
    this.navCtrl.navigateForward(
      PAYMENT_ROUTES.BASE +
      PAYMENT_ROUTES.OFFERS, { animated: false }
    );
  }

  fetchImageUrl(url: string) {
    if (/https/i.test(url)) {
      return url;
    } else {
      return environment.bannerPath + url;
    }
  }

  async showPaymentOfferDetails(offer) {
    this.dataService.checkAndCloseAllModals();
    if (offer.url) {
      this.triggerEventEmitter(offer);
    } else {
      this.modalService.openPaymentOfferDetailModal({ offer });
    }
  }
  triggerEventEmitter(column) {
    this.navigate.emit({ column });
  }
}
