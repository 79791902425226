//#region Imports

//#region Angular Imports
import { Component, Input, OnInit } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { EventsService } from '@Globals/providers/events.service';
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, EVENT_SOURCES } from '@Globals/constants/listing/constants';
import { PAYMENT_ROUTES } from '@Globals/constants/payment/routes';
import { DefaultComponent } from '@Lib/defaults/default.component';
import { CORE_ROUTES } from '@Globals/constants/listing/routes';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent extends DefaultComponent {
  showDeliveryDate = false;
  @Input() source: string;

  constructor(
    public masterDataService: MasterDataService,
    private navCtrl: NavController,
    public dataService: DataService,
    private eventsService: EventsService,
  ) {
    super();
  }

  mbOnInit() {
    if(this.masterDataService.masterData?.user?.delivery_start_date && this.masterDataService.baskets?.[0]?.date) {
      this.showDeliveryDate =
        new Date(this.masterDataService.masterData?.user?.delivery_start_date) >
        new Date(this.masterDataService.baskets?.[0]?.date);
    }
    const eventArgs = {
      Source: this.source,
      isDelivering: this.showDeliveryDate ? 'No' : 'Yes'
    };
    this.eventsService.trackEvents(
      EVENT_NAMES.INVITE_CODE_POPUP_VIEWED,
      eventArgs
    );
  }

  closeModal(navigateToPayment, openPromoSheet = false) {
    if(this.source === EVENT_SOURCES.RECURRING){
      this.dataService.dismissAllModal();
      this.dataService.updateBAckButtonHandler();
    } else {
      this.dataService.checkAndCloseAllModals();
    }
    if (
      navigateToPayment &&
      window.location.href.indexOf(PAYMENT_ROUTES.SHOPPING_CREDITS) === -1
    ) {
      this.dataService.showInviteModalOnShoppingCredits = false;
      this.eventsService.trackEvents(EVENT_NAMES.ADD_MONEY_CLICK,{src: EVENT_SOURCES.INVITE_MODAL});
      this.navCtrl.navigateForward(CORE_ROUTES.SHOPPING_CREDIT);
    } else if (navigateToPayment && openPromoSheet &&
      window.location.href.indexOf(PAYMENT_ROUTES.SHOPPING_CREDITS) > -1) {
      this.dataService.openPromoListing.next(true);
    }
  }

  onOpenBottomSheet(eve){
    this.dataService.openPromoModal = true;
    this.closeModal(true, true);
  }
}
