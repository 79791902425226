export enum REACT_WIDGET_MAP {
    ACCOUNT_HISTORY = 'MbAccountHistory',
    TRANSACTION_HISTORY = 'MbTransactionHistory',
    VALUE_PASS = 'MbValuePass',
    VALUE_PASS_HISTORY = 'MbValuePassHistory',
    LOGIN = 'MbLogin',
    USER_MENU = 'MbUserMenu',
    GO_GREEN = 'MbGoGreen',
    RECYCLE_BAG = 'MbRecycleDeliveryBags',
    RECYCLE_CARTON = 'MbRecycleCartons',
    RECYCLE_CARTON_KNOW_MORE = 'MbRecycleCartonsKnowMore',
    SHOPPING_CREDIT = 'MbWallet',
    PRODUCT_CATEGORY = 'MbProductCategory',
    REFERRAL = "MbReferral",
    REFERRAL_HOW_IT_WORKS = "MbReferralHowItWorks",
    REFERRAL_HISTORY = "MbReferralHistory",
    HOME = "MbHome",
    PROFILE = "MbProfile",
    ACCOUNT_CLOSURE = "MbAccountClosure",
    ACCOUNT_DELETED = "MbAccountDeleted",
    CALENDAR_JOURNEY = "MbCalendarJourney",
    FRAUD_USER = "MbFraudUser",
    EXTERNAL = "MbExternal",
    FAQ = "MbFaq",
    CATEGORY_PRODUCT_LIST = "MbCategoryProductList",
    SIGNUP_JOURNEY = "MbSignupJourney",
    SAVING = "MbUserSavings",
    PRODUCT_DETAIL = "MbProductDetail",
    COLLECTION_DASHBOARD = "MbCollectionDashboard",
    MEMBERSHIP = "MbUserMembership",
    BANNER_PRODUCT_LIST = 'MbBannerProductList',
    PRODUCT_LAUNCH_LIST = 'MbProductLaunchList',
    SPONSORED_PRODUCT_LIST = 'MbSponsoredProductList',
    CART = 'MbCart',
    HOME_CART = 'MbHomeCart',
    SUBSCRIPTION_ORDERS = "MbSubscriptionOrders",
    SUBSCRIBE_TO_SAVE = "MbSubscribeToSave",
    SUBSCRIBE_TO_SAVE_EDUCATION = "MbSubscribeToSaveEducation",
    SUBSCRIPTION_CASHBACK = "MbSubscriptionCashback",
    SUBSCRIPTION_SEARCH = "MbSubscriptionSearch",
    BILL_BUSTER = "MbBillBuster",
    ORDER_DETAILS = "MbOrderDetail",
    SET_SUBSCRIPTION_PLAN = "MbSetSubscriptionPlan",
    PAYMENT_METHOD = "MbPaymentMethods",
    SEARCH = "MbSearch",
    SET_USER_HOLIDAYS = "MbSetUserHolidays",
    USER_HOLIDAYS_LIST = "MbUserHolidays",
    GEOLOCATION = "MbGeolocation",
    COLLECTION_SINGLE_CELL = "MbCollectionProductList",
    CONTACT_US = "MbContactUs",
  };

export const MEMBERSHIP_CACHE_TYPES = {
  USER: "USER",
  ALL: "ALL",
  BASKET: "BASKET",
};

export const CALENDAR_EXTERNAL = {
  FAQS: "FAQs",
  URL: "https://adhoc.milkbasket.com/recurring/recurringFAQs.html",
};
