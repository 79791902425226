//#region Imports

//#region Angular Imports
import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
//#endregion Angular Imports

//#region Ionic Imports
import { NavController, ModalController } from "@ionic/angular";
import { HTTPResponse } from "@ionic-native/http";
//#endregion Ionic Imports

//#region Library Imports
import * as moment from "moment";
import { noop, Observable } from "rxjs";
//#endregion Library Imports

//#region Component Imports
import { InfoPopupComponent } from "@Shared/info-popup/info-popup.component";
import { InviteComponent } from "@Features/payment/invite/invite.component";
import { JourneyComponent } from "@Shared/journey/journey.component";
import { GreenBagRemovalComponent } from "@Shared/green-bag-removal/green-bag-removal.component";
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from "@Globals/providers/master-data.service";
import { DataService } from "@Globals/providers/data.service";
import { AuthService } from "@Globals/providers/auth.service";
import { AlertService } from "@Globals/providers/alert.service";
import { HttpService } from "@Globals/providers/http.service";
import { ModalService } from "@Globals/providers/modal.service";
import { ToastService } from "@Globals/providers/toast.service";
import { EventsService } from "@Globals/providers/events.service";

//Non-root thrd party services
import { RecurringService } from "@Globals/providers/recurring.service";
import { CartService } from "@Features/product-list/providers/cart.service";
import { MembershipService } from "@Pages/membership/providers/membership.service";
import { ShoppingCreditsServices } from "@Globals/providers/shopping-credits.service";
//#endregion Providers Imports

//#region Other Imports
import { Utilities } from "@Globals/classes/utilities";
import {
  A2B_API_ERRORS,
  BUNDLE_VIEW,
  EVENT_NAMES,
  EVENT_SOURCES,
  EVENT_ARGS,
  HOLIDAY_CUT_OFF_TIME_CONFIRMATION,
  PAST_CUT_OFF_TIME_CONFIRMATION,
  TIME_RECHECK_GET_CONFIRMATION,
  PRE_ORDER_POST_ORDER_END_DATE_ERROR,
  NON_MBEYOND_USER_REDIRECTION,
  HOLIDAY_BASKET_TOAST_MESSAGE,
  STORAGE_KEYS,
  GENERATE_CONTENT_FOR_ALERT_LAYER,
  ALERT_BUTTON_TEXT,
  REC_COACHMARK_REPEAT_FREQUENCY,
  SUBSCRIPTION_BASKET_REMOVAL_CONFIRMATION,
  OFFER_VIEW_TYPE,
  MAX_QUANTITY_REACHED_ALERT,
  CAL_MODAL_CSS_CLASS,
  LOGIN_SIGNUP_A2B_POPUP,
  LOCAL_IMG_PATH,
} from "@Globals/constants/listing/constants";

import {
  ProductListType,
  SeeRelated,
  SeeSimilar,
  RefillTypes,
  recurringCampaignCashbackType,
  SubscribeAndSaveSlab,
  RecommendationType,
  DeliveryType,
  OfferType,
  RecurringViewType,
  ScheduleType,
  JOURNEY_TYPE,
  EventTrackingPlugins,
} from "@Globals/constants/listing/enums";
import {
  MODAL_IDS,
  PAYMENT_GATEWAY,
  PAYMENT_VALUES,
} from "@Globals/constants/payment/constants";
import { LISTING_APIS } from "@Globals/constants/listing/apis";
import { CORE_ROUTES, LISTING_ROUTES } from "@Globals/constants/listing/routes";
import {
  ProductInSearch,
  RecurringOrder,
} from "@Globals/interfaces/listing/interfaces";
import { environment } from "@Env/environment";
import {
  MBPASS_OFFER_CATEGORY_TYPE,
  MBPASS_TYPE,
} from "@App/listing/constants/listing-constants";
import { MEMBERSHIP_STATUS } from "@Pages/membership/membership-constants/membership-constants";
import { ProductVariantListComponent } from "@Shared/product-variant-list/product-variant-list.component";
import { OfferDiscountedProduct } from "@App/listing/constants/listing-enums";
//#endregion Other Imports

//#endregion Imports

@Injectable()
export class ProductListManagerService {
  imagePath = LOCAL_IMG_PATH;
  resolveReference: any;
  timeoutForHideCoachmark;
  timeoutForProcessProducts;
  timeoutForHideDiscoveryCoachMark;
  aToBForLowBalance;

  currentHoursAndMinutes = Utilities.getCurrentHoursAndMinutes();
  rcNewlyAddedProducts;

  productImageUrl;
  fileStorageDirectories;
  deliverySlotInfo;
  isFrozenCheckEnabled;
  recRecommendationObject;
  showSeeSimilar;
  isOOS;
  refillTypesMetaData;
  popularSuggestionImageUrl;
  constructor(
    public masterDataService: MasterDataService,
    public dataService: DataService,
    public authService: AuthService,
    protected modalService: ModalService,
    protected toastService: ToastService,
    private navCtrl: NavController,
    public recurringService: RecurringService,
    public cartService: CartService,
    private membershipService: MembershipService,
    protected eventsService: EventsService,
    public httpService: HttpService,
    protected alertService: AlertService,
    protected zone: NgZone,
    protected modalCtrl: ModalController,
    private shoppingCreditsServices: ShoppingCreditsServices,
    private router: Router
  ) {
    this.productImageUrl = environment.productPath;
    this.popularSuggestionImageUrl = environment.popularSuggestion;
    this.fileStorageDirectories = this.dataService.fileStorageDirectories;
    this.deliverySlotInfo = dataService.firebaseMasterData.deliverySlotInfo;
    this.isFrozenCheckEnabled = dataService.isFrozenCheckEnabled;
    this.recRecommendationObject = dataService.recRecommendationObject;
    this.showSeeSimilar = dataService.showSeeSimilar;
    this.isOOS = masterDataService.staticMasterData?.isOOS;
    this.refillTypesMetaData =
      dataService.firebaseMasterData.refillTypesMetaData;

    try {
      this.rcNewlyAddedProducts = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.RC_NEWLY_ADDED_PRODUCTS)
      );
    } catch (e) {}
  }

  getIsMBeyond() {
    return this.masterDataService.masterData?.user?.is_mbeyond;
  }

  setupTomorrowBasket(forceRun = false) {
    if (
      (!this.authService.userData.tomorrowBasket &&
        this.masterDataService.baskets?.length) ||
      forceRun
    ) {
      this.authService.userData.tomorrowBasket = {};
      this.masterDataService.baskets?.[0]?.products?.forEach((product) => {
        this.authService.userData.tomorrowBasket[
          product.product_id || product.id
        ] = product.quantity;
      });
    }
  }

  async openProductVariantModel(
    variantProduct,
    parentProductData: any,
    componentType?,
    eventSource?: string,
    eventData?: any,
    itemType?: string
  ) {
    return await this.modalCtrl.create({
      component: ProductVariantListComponent,
      cssClass: "modal-bottom-sheet product-variant-modal auto-height",
      // breakpoints: [0, 0.75, 0.9],
      // initialBreakpoint: 0.75,
      //handle: false,
      componentProps: {
        variantProduct,
        parentProductData,
        componentType,
        eventSource,
        eventData,
      },
      id: MODAL_IDS.PRODUCT_VARIANT_POPUP,
    });
  }

  //@TODO convert this modal to routable page
  recycleBagBottomSheetConfirmation() {
    this.modalService.openGenericRecycleBagsModal(GreenBagRemovalComponent);
    return new Promise((resolve) => {
      this.dataService.promiseResolve = resolve;
    });
  }

  async subscriptionPercentProductRemovalPopup(component) {
    this.showSubscriptionRemovalModal(component);
    return new Promise((resolve, reject) => {
      this.resolveReference = resolve;
    });
  }

  showSubscriptionRemovalModal(component) {
    const BASKET_REMOVAL = Utilities.deepCloningOfAnArray(
      SUBSCRIPTION_BASKET_REMOVAL_CONFIRMATION
    );
    BASKET_REMOVAL.META_DATA.MESSAGE = BASKET_REMOVAL.META_DATA.MESSAGE.replace(
      "{currentSlab}",
      this.recurringService.restOfTheMonthSlabValueSet(
        component.productsAsPerDisplayLimit,
        SubscribeAndSaveSlab.CURRENT_SLAB,
        component.recCampaignSubsribeToSave,
        component.basketDate
      )
    );
    BASKET_REMOVAL.META_DATA.MESSAGE = BASKET_REMOVAL.META_DATA.MESSAGE.replace(
      "{previousSlab}",
      this.recurringService.restOfTheMonthSlabValueSet(
        component.productsAsPerDisplayLimit,
        SubscribeAndSaveSlab.PREVIOUS_SLAB,
        component.recCampaignSubsribeToSave,
        component.basketDate
      )
    );
    if (!component.recCampaignSubsribeToSave) {
      BASKET_REMOVAL.META_DATA.MESSAGE =
        BASKET_REMOVAL.META_DATA.MESSAGE.replace(
          `tomorrow's order`,
          `that day’s drop`
        );
    }

    BASKET_REMOVAL.BUTTONS.CANCEL.handler = () => {
      this.resolveReference(false);
    };
    BASKET_REMOVAL.BUTTONS.CONFIRM.handler = () => {
      this.resolveReference(true);
    };
    BASKET_REMOVAL.BUTTONS.CLOSE.handler = () => {
      this.resolveReference(false);
    };
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(BASKET_REMOVAL.META_DATA),
      [
        BASKET_REMOVAL.BUTTONS.CLOSE,
        BASKET_REMOVAL.BUTTONS.CONFIRM,
        BASKET_REMOVAL.BUTTONS.CANCEL,
      ],
      BASKET_REMOVAL.CSS_CLASS
    );
  }

  // function to update storagefor recommendations
  updateStorage(value) {
    this.dataService.recRecommendationFromStorage = value;
    localStorage.setItem(
      STORAGE_KEYS.RECURRING_RECOMMENDATIONS,
      JSON.stringify(value)
    );
  }

  // to hide coachmark after 2 sec
  hideCoachmark(component) {
    let cb = (componentArg) => {
      componentArg.productIdToShowCoachmark = undefined;
    };
    cb = cb.bind(this, component);
    this.timeoutForHideCoachmark = setTimeout(cb, 2000);
  }

  clearTimers() {
    if (this.timeoutForProcessProducts) {
      clearTimeout(this.timeoutForProcessProducts);
      this.timeoutForProcessProducts = null;
    }
    if (this.timeoutForHideDiscoveryCoachMark) {
      clearTimeout(this.timeoutForHideDiscoveryCoachMark);
      this.timeoutForHideDiscoveryCoachMark = null;
    }
    if (this.timeoutForHideCoachmark) {
      clearTimeout(this.timeoutForHideCoachmark);
      this.timeoutForHideCoachmark = null;
    }
  }

  validateLoggedIn() {
    if (!this.authService.isLoggedIn()) {
      if (
        !Utilities.isApp() &&
        this.masterDataService.navigateFromDeeplink &&
        Utilities.isMobileBrowser()
      ) {
        this.masterDataService.getRedirectionToPlayStoreOrAppStore();
      } else {
        this.openBottomSheet(
          InfoPopupComponent,
          [0, 0.45, 0.5, 1],
          0.5,
          LOGIN_SIGNUP_A2B_POPUP
        );
      }
      return false;
    }
    return true;
  }

  async validateRecycleBagBottomSheetConfirmation(component, counterResponse) {
    //Await for the recycleBagBottomSheetConfirmation function to complete
    if (
      this.dataService.userExistInRecycleBagHub &&
      this.masterDataService.baskets?.length &&
      this.masterDataService.baskets[0]?.products?.length === 1 &&
      this.masterDataService.baskets[0]?.products[0]?.quantity === 1 &&
      !counterResponse.isAdded &&
      !component.basketDate
    ) {
      if (localStorage.getItem(STORAGE_KEYS.RECYCLE_BAG_DATE)) {
        const recycleBagDate = JSON.parse(
          localStorage.getItem(STORAGE_KEYS.RECYCLE_BAG_DATE)
        );
        if (
          recycleBagDate &&
          Utilities.equalDates(recycleBagDate, new Date())
        ) {
          const result = await this.recycleBagBottomSheetConfirmation();
          if (!result) {
            return false;
          }
        }
      }
    }
    return true;
  }

  async validateOrderEndDate(counterResponse, product) {
    // Added check to not let user add preorder product after order end date
    if (
      product.isPreOrder &&
      moment(product.order_end_date) < moment() &&
      counterResponse.isAdded
    ) {
      this.toastService.presentToast(PRE_ORDER_POST_ORDER_END_DATE_ERROR);
      return false;
    }
    return true;
  }

  getProductIndex(currentBasketIndex, productId) {
    return (
      (this.masterDataService.baskets &&
        this.masterDataService.baskets[currentBasketIndex] &&
        this.masterDataService.baskets[currentBasketIndex]?.products?.findIndex(
          (element) => {
            return element.product_id === productId;
          }
        )) ||
      -1
    );
  }

  getProductIdentity(component, product: any) {
    return !product._hasVariants
      ? product.product_id || product.id
      : product.variants[product.selectedVariantIndex].product_id ||
          product.variants[product.selectedVariantIndex].id;
  }

  getProductQuantity(component, product, productId) {
    return (
      (!product._hasVariants
        ? product.quantity
        : product.variants[product.selectedVariantIndex].quantity) ||
      this.authService.userData.tomorrowBasket[productId] ||
      0
    );
  }

  counterChange = async (
    component,
    counterResponse,
    product,
    index,
    orderDate?: string,
    bypassTimeCheck = false,
    quantityFromFutureOrder = 0
  ) => {
    if (!this.validateLoggedIn()) {
      return;
    }
    if (this.masterDataService.masterData?.user?.wf) {
      return;
    }
    let isValid = await this.validateRecycleBagBottomSheetConfirmation(
      component,
      counterResponse
    );
    if (!isValid) {
      return;
    }

    let isValidOrder = await this.validateOrderEndDate(
      counterResponse,
      product
    );
    if (!isValidOrder) {
      return;
    }

    let currentBasketIndex = component.basketIndex || 0;
    const productId = this.getProductIdentity(component, product);
    let quantity = this.getProductQuantity(component, product, productId);
    // Added this const to trigger product quantity for cases where current basket is not asking for plus one day delivery
    const isPlusOneDayDelivery = orderDate;
    if (!orderDate || bypassTimeCheck) {
      orderDate =
        this.masterDataService.baskets &&
        this.masterDataService.baskets[currentBasketIndex].date;
    } else if (!(orderDate && currentBasketIndex > 0)) {
      currentBasketIndex++;
    }
    // Assigning date to handle filtered data on Rest of the Month tab as basket index passed along is not the ideal one
    product.pre_order &&
      component.basketDate &&
      (orderDate = component.basketDate);
    !!product._hasVariants && (component.itemType = EVENT_ARGS.VARIANT);
    const productIndex = this.getProductIndex(currentBasketIndex, productId);
    if (productIndex > -1) {
      quantity =
        this.masterDataService.baskets &&
        this.masterDataService.baskets[currentBasketIndex].products[
          productIndex
        ].quantity;
    } else if (!quantity || (quantity < 0 && !quantityFromFutureOrder)) {
      quantity = 0;
    }
    if (quantityFromFutureOrder) {
      quantity = quantityFromFutureOrder;
    }
    const productQuantity = counterResponse.isAdded
      ? quantity + 1
      : quantity - 1;
    let args: any = {
      date: orderDate,
      get_product_check: !!counterResponse.isAdded,
      product_id: productId,
      quantity: productQuantity,
      sellPrice: product?._sp,
    };
    if (
      bypassTimeCheck ||
      this.dataService.timeRecheckErrorLastShownOn ===
        moment().format("MM/DD/YYYY")
    ) {
      args = {
        ...{ bypass_time_check: true },
        ...args,
      };
    }
    let recSubscribeTosaveProduct = false;
    let productInRecMapper =
      this.recurringService.recurringCampaignProducts
        ?.recurringCampaignMapper?.["products"]?.[args.product_id];
    if (
      productInRecMapper &&
      ((this.recurringService.subscribedRecurringProduct(args.product_id) &&
        this.recurringService.currentDayPercentProductCount.length > 1) ||
        !component.recCampaignSubsribeToSave)
    ) {
      if (
        productInRecMapper["cb_type"] ===
        recurringCampaignCashbackType.PERCENT_TYPE
      ) {
        if (args["quantity"] === 1 && counterResponse.isAdded) {
          recSubscribeTosaveProduct = true;
        }

        if (component.restOfTheMonth && !counterResponse.isAdded) {
          let prevSlabValue = this.recurringService.restOfTheMonthSlabValueSet(
            component.productsAsPerDisplayLimit,
            SubscribeAndSaveSlab.PREVIOUS_SLAB,
            component.recCampaignSubsribeToSave,
            component.basketDate
          );
          if (
            prevSlabValue &&
            prevSlabValue !==
              this.recurringService.restOfTheMonthSlabValueSet(
                component.productsAsPerDisplayLimit,
                SubscribeAndSaveSlab.CURRENT_SLAB,
                component.recCampaignSubsribeToSave,
                component.basketDate
              )
          ) {
            const product = component.productsAsPerDisplayLimit.filter(
              (product) => product.product_id === args.product_id
            )[0];
            if (product.quantity === 1) {
              const result = await this.subscriptionPercentProductRemovalPopup(
                component
              );
              if (!result) {
                return false;
              }
              this.eventsService.trackEvents(
                EVENT_NAMES.RECURRING_CAMPAIGN_PERCENT_TYPE_BASKET_REMOVAL,
                { source: "" }
              );
            }
          }
        }
      }

      args["recurringCampaign"] = {
        cashbackType: productInRecMapper["cb_type"],
        cashbackValue:
          productInRecMapper["cb_val"] !== "Paused"
            ? productInRecMapper["cb_val"]
            : 0,
      };
    }
    if (/discounted-product/i.test(this.router.url)) {
      args["recurring"] = OfferDiscountedProduct.DiscountedProduct;
    }
    this.cartService
      .addToBasket(
        args,
        product.pre_order ? -1 : currentBasketIndex,
        product.pre_order || product.isPreOrder
      )
      .subscribe((response: any) => {
        return this.manageAddToBasketResponse(
          component,
          response,
          counterResponse,
          product,
          index,
          orderDate,
          productId,
          productQuantity,
          currentBasketIndex,
          isPlusOneDayDelivery,
          recSubscribeTosaveProduct,
          args
        );
      });
  };

  manageAddToBasketResponse(
    component,
    response: any,
    counterResponse,
    product,
    index,
    orderDate,
    productId,
    productQuantity,
    currentBasketIndex,
    isPlusOneDayDelivery,
    recSubscribeTosaveProduct,
    args
  ) {
    if (response.data.status) {
      if (component.componentType === ProductListType.Product_Variant_List) {
        this.dataService.productVariantInfo.isVariantAddedToCart = true;
        this.dataService.productVariantInfo.latestVariantIndexIntoCart = index;
      }
      return this.handleAddToBasketSuccess(
        component,
        response,
        counterResponse,
        product,
        index,
        orderDate,
        productId,
        productQuantity,
        currentBasketIndex,
        isPlusOneDayDelivery,
        recSubscribeTosaveProduct
      );
    } else {
      return this.handleAddToBasketError(
        component,
        response,
        counterResponse,
        product,
        index,
        orderDate,
        productId,
        productQuantity,
        currentBasketIndex,
        isPlusOneDayDelivery,
        recSubscribeTosaveProduct,
        args
      );
    }
  }

  getBasketToastMessage(response, counterResponse, orderDate) {
    let showExhaustedMessageOrNot = false;
    if (response.data.credit_started) {
      const exhaustedStorageValue = localStorage.getItem(
        STORAGE_KEYS.DONT_SHOW_EXHAUSTED_AGAIN
      );
      if (!exhaustedStorageValue || exhaustedStorageValue !== orderDate) {
        showExhaustedMessageOrNot = true;
        this.eventsService.trackEvents(EVENT_NAMES.CREDIT_LIMIT_START, {
          source: EVENT_SOURCES.CREDIT_LIMIT_STARTED,
        });
      }
      localStorage.setItem(STORAGE_KEYS.DONT_SHOW_EXHAUSTED_AGAIN, orderDate);
    }
    const basketToastMessage = counterResponse.isAdded
      ? showExhaustedMessageOrNot
        ? `Your wallet balance has exhausted, you have a credit of Rs${response.data.credit_limit}. Continue shopping.`
        : "Added to Basket"
      : "Removed from Basket";
    return basketToastMessage;
  }

  async showRelatedOrSimilar(
    component,
    response,
    counterResponse,
    product,
    productQuantity,
    orderDate,
    index
  ) {
    const basketEventName = counterResponse.isAdded
      ? EVENT_NAMES.ADD_TO_BASKET
      : EVENT_NAMES.REMOVE_FROM_BASKET;
    let showHideRelated;
    if (productQuantity === 0) {
      showHideRelated = SeeRelated.hide;
    } else {
      if (productQuantity === 1 && counterResponse.isAdded) {
        showHideRelated = SeeRelated.show;
      }
    }
    (component.componentType === ProductListType.Product_Listing ||
      component.componentType === ProductListType.Search_Page ||
      component.componentType === ProductListType.Collection ||
      component.componentType === ProductListType.Product_Variant_List) &&
      this.showOrHideRecommendedProducts(component, product, showHideRelated);
    component.recommendationType === RecommendationType.See_Similar &&
      (component.itemType = EVENT_ARGS.SIMILAR);
    component.recommendationType === RecommendationType.Related &&
      (component.itemType = EVENT_ARGS.RELATED);
  }

  // This is the common method to handel Recommendations for the user.
  // based on the showOrHideRecommendedProducts variable passed we perform the actions.
  showOrHideRecommendedProducts(
    component,
    product,
    showOrHideRecommendedProducts
  ) {
    const dataToBeSent = {
      city_id:
        (this.masterDataService.masterData?.user &&
          this.masterDataService.masterData?.user?.city_id) ||
        (this.authService.userData && this.authService.userData.cityObj.id) ||
        1,
      product_id: product.product_id || product.id,
    };
    this.dataService.parentProductId = dataToBeSent.product_id;
    switch (showOrHideRecommendedProducts) {
      case SeeSimilar.hide:
      case SeeRelated.hide: {
        component.recommendedProductList.product = {
          id: 0,
        };
        break;
      }
      case SeeSimilar.show: {
        component.recommendationComponentType = RecommendationType.See_Similar;
        this.getSimilarProducts(component, product, dataToBeSent);
        break;
      }
      case SeeRelated.show: {
        component.isRequestDivAllowed = false;
        component.recommendationComponentType = RecommendationType.Related;
        this.getRelatedProduct(component, product, dataToBeSent);
        break;
      }
    }
  }

  getSimilarProducts(component, product, dataToBeSent) {
    const seeSimilarEventArgs = {
      SubCategory_type: product.subcategory_type,
      SubCategory: product.subcategory,
      Source: component.eventSource,
      product_id: dataToBeSent.product_id,
    };
    this.eventsService.trackEvents(
      EVENT_NAMES.VIEW_SIMILAR,
      seeSimilarEventArgs
    );
    this._getSimilarProducts(
      product,
      dataToBeSent,
      component.successHandlerForRecommendedProducts.bind(component)
    );
  }

  // Method to get the list of Related products to a product added by user in basket.
  getRelatedProduct(component, product, dataToBeSent) {
    this._getRelatedProducts(dataToBeSent).subscribe(
      (productList: HTTPResponse) => {
        if (productList.data.status) {
          if (productList.data.data.length > 0) {
            this.eventsService.trackEvents(EVENT_NAMES.VIEW_RELATED, {
              SubCategory_type: product.subcategory_type,
              SubCategory: product.subcategory,
              Source: component.eventSource,
              product_id: dataToBeSent.product_id,
            });
          }
          component.successHandlerForRecommendedProducts(
            productList.data.data,
            product
          );
        }
      }
    );
  }

  manageRecurringCoachMark(component, counterResponse, product) {
    // to show recurring coach mark for 2 sec
    if (
      component.componentType === ProductListType.Product_Listing &&
      this.dataService.recRecommendationObject.hasOwnProperty(product.id) &&
      counterResponse.isAdded
    ) {
      if (this.dataService.recRecommendationFromStorage === null) {
        component.productIdToShowCoachmark = product.id;
        this.hideCoachmark(component);
        this.updateStorage({
          [product.id]: { count: 1, date: moment().format("MM/DD/YYYY") },
        });
      } else if (
        !this.dataService.recRecommendationFromStorage.hasOwnProperty(
          product.id
        ) ||
        (this.dataService.recRecommendationFromStorage.hasOwnProperty(
          product.id
        ) &&
          moment().isAfter(
            moment(
              this.dataService.recRecommendationFromStorage[product.id].date,
              "MM/DD/YYYY"
            ).add(14, "days")
          ))
      ) {
        component.productIdToShowCoachmark = product.id;
        this.hideCoachmark(component);
        this.dataService.recRecommendationFromStorage[product.id] = {
          count: 1,
          date: moment().format("MM/DD/YYYY"),
        };
        this.updateStorage(this.dataService.recRecommendationFromStorage);
      } else if (
        this.dataService.recRecommendationFromStorage[product.id].count < 2 &&
        moment().isBetween(
          this.dataService.recRecommendationFromStorage[product.id].date,
          moment(
            this.dataService.recRecommendationFromStorage[product.id].date,
            "MM/DD/YYYY"
          )
            .add(REC_COACHMARK_REPEAT_FREQUENCY, "days")
            .format("MM/DD/YYYY")
        )
      ) {
        component.productIdToShowCoachmark = product.id;
        this.hideCoachmark(component);
        this.dataService.recRecommendationFromStorage[product.id].count = 2;
        this.updateStorage(this.dataService.recRecommendationFromStorage);
      }
    }
  }

  trackBasketEvent(
    component,
    response,
    counterResponse,
    product,
    productQuantity,
    orderDate,
    index
  ) {
    const basketEventName = counterResponse.isAdded
      ? EVENT_NAMES.ADD_TO_BASKET
      : EVENT_NAMES.REMOVE_FROM_BASKET;
    this.cartService.trackBasketEvent(
      basketEventName,
      product,
      orderDate,
      component.eventSource,
      component.pdpProductIndex || index,
      response.data.status,
      product.hasOwnProperty("type")
        ? EVENT_ARGS.RECOMMENDED
        : EVENT_ARGS.NORMAL,
      product.highlightColor ? EVENT_ARGS.HIGHLIGHTED : component.itemType,
      component.itemType === EVENT_ARGS.RELATED ||
        component.itemType === EVENT_ARGS.SIMILAR,
      component.eventData,
      component.appliedFilter,
      response.data
    );
  }

  calculateBasketValue(product, isAdded) {
    if (this.masterDataService.categoryType === MBPASS_TYPE.PLATFORM) {
      if (this.masterDataService.baskets.length) {
        this.masterDataService.jioStripData.mbpassBasketValue =
          this.masterDataService.baskets[0]?.bill_details?.sub_total -
            this.masterDataService.baskets[0]?.bill_details?.sub_savings || 0;
      } else {
        this.masterDataService.jioStripData.mbpassBasketValue = 0;
      }
      this.masterDataService.currentCashback();
    } else {
      if (
        this.masterDataService.masterData?.customer_mb_pass
          ?.offerCategoryType === MBPASS_OFFER_CATEGORY_TYPE.CATEGORY &&
        this.masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.includes(
          product.cid
        ) &&
        !product?.sample
      ) {
        this.updateBasketValue(isAdded, product);
      }
      if (
        this.masterDataService.masterData?.customer_mb_pass
          ?.offerCategoryType === MBPASS_OFFER_CATEGORY_TYPE.SUBCATEGORY &&
        this.masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.includes(
          product?.scid
        ) &&
        !product?.sample
      ) {
        this.updateBasketValue(isAdded, product);
      }
    }
  }

  updateBasketValue(isAdded, product) {
    if (isAdded) {
      if (product?.sp) {
        this.masterDataService.jioStripData.mbpassBasketValue =
          this.masterDataService.jioStripData.mbpassBasketValue +
          Number.parseInt(product.sp);
      } else {
        this.masterDataService.jioStripData.mbpassBasketValue = Number.parseInt(
          "" +
            (this.masterDataService.jioStripData.mbpassBasketValue +
              Number.parseInt(product.price))
        );
      }
    } else {
      if (product?.sp) {
        this.masterDataService.jioStripData.mbpassBasketValue =
          this.masterDataService.jioStripData.mbpassBasketValue -
          Number.parseInt(product.sp);
      } else {
        this.masterDataService.jioStripData.mbpassBasketValue = Number.parseInt(
          "" +
            (this.masterDataService.jioStripData.mbpassBasketValue -
              Number.parseInt(product.price))
        );
      }
    }
    this.masterDataService.currentCashback();
    this.masterDataService.cashbackCharacterCount();
  }

  handleAddToBasketSuccess(
    component,
    response: any,
    counterResponse,
    product,
    index,
    orderDate,
    productId,
    productQuantity,
    currentBasketIndex,
    isPlusOneDayDelivery,
    recSubscribeTosaveProduct
  ) {
    if (this.masterDataService.isShownJioStrip) {
      this.calculateBasketValue(product, counterResponse.isAdded);
    }
    if (recSubscribeTosaveProduct) {
      this.eventsService.trackEvents(
        EVENT_NAMES.RECURRING_CAMPAIGN_PERCENT_TYPE_BASKET_ADD,
        { source: "" }
      );
    }
    const basketToastMessage = this.getBasketToastMessage(
      response,
      counterResponse,
      orderDate
    );
    this.dataService.runChangeDetForA2B.next(true);
    if (orderDate) {
      this.dataService.runChangeDetectionForProductOrders.next({
        basketResponse: response,
        product,
      });
    }
    if (
      this.dataService.firebaseMasterData.holidayConfigData?.showHolidayData
    ) {
      HOLIDAY_BASKET_TOAST_MESSAGE.META_DATA.MESSAGE = basketToastMessage;
      const holidayBasketToastContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
        HOLIDAY_BASKET_TOAST_MESSAGE.META_DATA,
        { date: response.data.error_next_date }
      );
      this.alertService.presentDismissAlert(
        holidayBasketToastContent,
        [],
        HOLIDAY_BASKET_TOAST_MESSAGE.CSS_CLASS
      );
    } else {
      this.toastService.presentToast(basketToastMessage);
    }
    setTimeout(() => {
      this.showRelatedOrSimilar(
        component,
        response,
        counterResponse,
        product,
        productQuantity,
        orderDate,
        index
      );
      this.trackBasketEvent(
        component,
        response,
        counterResponse,
        product,
        productQuantity,
        orderDate,
        index
      );
    }, 10);
    setTimeout(() => {
      this.manageRecurringCoachMark(component, counterResponse, product);
    }, 10);
    if (
      component.componentType === ProductListType.Product_Variant_List &&
      this.router.url.includes("/listing/product-detail/")
    ) {
      this.productDetailsModal(
        productId,
        index,
        component.componentType,
        component.eventSource,
        component.eventData,
        component.itemType,
        product,
        component.eventSource,
        currentBasketIndex
      );
    }
    this.zone.run(async () => {
      return await this.postAddToBasket(
        component,
        response,
        counterResponse,
        product,
        productId,
        productQuantity,
        orderDate,
        currentBasketIndex,
        isPlusOneDayDelivery
      );
    });
  }

  async postAddToBasket(
    component,
    response,
    counterResponse,
    product,
    productId,
    productQuantity,
    orderDate,
    currentBasketIndex,
    isPlusOneDayDelivery
  ) {
    counterResponse.counterSubscriber &&
      counterResponse.counterSubscriber.next(true);
    if (component.isCancelledProduct) {
      this.masterDataService.baskets[0].removed_products &&
        (this.masterDataService.baskets[0].removed_products =
          this.masterDataService.baskets[0]?.removed_products.filter(
            (removedProduct) =>
              removedProduct.product_id !== this.dataService.parentProductId
          ));
      this.removeCancelProduct(component, this.dataService.parentProductId);
    }
    const needToShowFrozenInformation =
      this.dataService.showFrozenProductA2BAlert &&
      product.delivery_types_slot &&
      product.delivery_types_slot[0] === DeliveryType.frozen &&
      this.dataService.isFrozenCheckEnabled;
    if (response.data?.current_membership?.endDate) {
      this.masterDataService.customerCurrentMembership(
        response.data.current_membership
      );
    } else {
      this.userCurrentMembership();
    }
    // Don't show membership info to New Members Discoverability state(3)
    if (
      !this.dataService.dontShowSubsciptionAgain &&
      !this.dataService.doesUserBelongToBulkBuyHub &&
      response.data.current_membership &&
      response.data.current_membership.show_membership &&
      this.masterDataService.masterData?.user?.membershipVisibility &&
      this.masterDataService.masterData?.current_membership &&
      this.masterDataService.masterData?.current_membership?.state !== 3
    ) {
      this.dataService.showMembershipInfo(
        this.masterDataService.masterData?.current_membership,
        EVENT_SOURCES.A2B,
        needToShowFrozenInformation
      );
    } else if (needToShowFrozenInformation) {
      this.cartService.showFrozenProductDeliveryInfo(orderDate);
    }
    this.dataService.knockKnock.next(true);
    !isPlusOneDayDelivery &&
      currentBasketIndex !== 0 &&
      (product.quantity = productQuantity);
    if (currentBasketIndex === 0) {
      this.authService.userData.tomorrowBasket[productId] = productQuantity;
      (component.componentType ===
        ProductListType.Impulse_Buy_Listing_On_Cart ||
        component.componentType === ProductListType.Your_Cart) &&
        this.checkIfAllImpulseProductsBought();
    }
  }

  userCurrentMembership() {
    this.membershipService
      .getCustomerMembership()
      .subscribe((success: HTTPResponse) => {
        if (success?.data?.status) {
          this.masterDataService.customerCurrentMembership(
            success.data.currentMembershipUserBean
          );
        }
      });
  }

  checkIfAllImpulseProductsBought() {
    if (this.masterDataService?.impulse_products) {
      const impulseBuyProductNotBought =
        this.masterDataService?.impulse_products.filter((product) => {
          return !this.authService.userData.tomorrowBasket[
            product.product_id || product.id
          ];
        });
      this.dataService.allImpulseProductsBought =
        !impulseBuyProductNotBought.length;
    }
  }

  handleAddToBasketError(
    component,
    response: any,
    counterResponse,
    product,
    index,
    orderDate,
    productId,
    productQuantity,
    currentBasketIndex,
    isPlusOneDayDelivery,
    recSubscribeTosaveProduct,
    args
  ) {
    this.cartService.trackBasketErrorEvent(
      EVENT_NAMES.ADD_BASKET_ERROR,
      response,
      product,
      counterResponse.isAdded,
      component.eventSource,
      component.pdpProductIndex || index,
      args
    );
    counterResponse.counterSubscriber &&
      counterResponse.counterSubscriber.next(false);
    if (response.data.error === A2B_API_ERRORS.CUTOFF_TIME_EXCEEDED) {
      PAST_CUT_OFF_TIME_CONFIRMATION.BUTTONS.OKAY.handler = () => {
        this.counterChange(
          component,
          counterResponse,
          product,
          undefined,
          response.data.error_next_date
        );
      };
      PAST_CUT_OFF_TIME_CONFIRMATION.BUTTONS.CLOSE.handler = noop();
      PAST_CUT_OFF_TIME_CONFIRMATION.META_DATA.MESSAGE =
        response.data.error_msg;
      const pastCutOffTimeContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
        PAST_CUT_OFF_TIME_CONFIRMATION.META_DATA,
        { date: response.data.error_next_date }
      );
      this.alertService.presentAlertConfirm(
        pastCutOffTimeContent,
        [
          PAST_CUT_OFF_TIME_CONFIRMATION.BUTTONS.OKAY,
          PAST_CUT_OFF_TIME_CONFIRMATION.BUTTONS.CLOSE,
        ],
        PAST_CUT_OFF_TIME_CONFIRMATION.CSS_CLASS
      );
    } else if (
      response.data.error === A2B_API_ERRORS.TIME_RECHECK_GET ||
      response.data.error === A2B_API_ERRORS.TIME_RECHECK
    ) {
      this.dataService.timeRecheckErrorLastShownOn =
        moment().format("MM/DD/YYYY");
      TIME_RECHECK_GET_CONFIRMATION.BUTTONS.OKAY.handler = () => {
        this.counterChange(
          component,
          counterResponse,
          product,
          undefined,
          response.data.errorData[0],
          true
        );
      };
      TIME_RECHECK_GET_CONFIRMATION.BUTTONS.OKAY.text = counterResponse.isAdded
        ? TIME_RECHECK_GET_CONFIRMATION.OKAY_TEXTS.add
        : TIME_RECHECK_GET_CONFIRMATION.OKAY_TEXTS.remove;
      TIME_RECHECK_GET_CONFIRMATION.BUTTONS.CLOSE.handler = noop();
      TIME_RECHECK_GET_CONFIRMATION.META_DATA.MESSAGE =
        response.data.error_msg ||
        (counterResponse.isAdded
          ? TIME_RECHECK_GET_CONFIRMATION.MESSAGES.add
          : TIME_RECHECK_GET_CONFIRMATION.MESSAGES.remove);
      const pastCutOffTimeContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
        TIME_RECHECK_GET_CONFIRMATION.META_DATA,
        { date: response.data.errorData[0] }
      );
      this.alertService.presentAlertConfirm(
        pastCutOffTimeContent,
        [
          TIME_RECHECK_GET_CONFIRMATION.BUTTONS.OKAY,
          TIME_RECHECK_GET_CONFIRMATION.BUTTONS.CLOSE,
        ],
        TIME_RECHECK_GET_CONFIRMATION.CSS_CLASS
      );
    } else if (response.data.error === A2B_API_ERRORS.HOLIDAY_OFF) {
      HOLIDAY_CUT_OFF_TIME_CONFIRMATION.BUTTONS.OKAY.handler = () => {
        this.counterChange(
          component,
          counterResponse,
          product,
          undefined,
          response.data.error_next_date
        );
      };
      HOLIDAY_CUT_OFF_TIME_CONFIRMATION.BUTTONS.CLOSE.handler = noop();
      HOLIDAY_CUT_OFF_TIME_CONFIRMATION.META_DATA.MESSAGE =
        response.data.error_msg;
      const holidayOffTimeContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
        HOLIDAY_CUT_OFF_TIME_CONFIRMATION.META_DATA,
        { date: response.data.error_next_date }
      );
      this.alertService.presentAlertConfirm(
        holidayOffTimeContent,
        [
          HOLIDAY_CUT_OFF_TIME_CONFIRMATION.BUTTONS.OKAY,
          HOLIDAY_CUT_OFF_TIME_CONFIRMATION.BUTTONS.CLOSE,
        ],
        HOLIDAY_CUT_OFF_TIME_CONFIRMATION.CSS_CLASS
      );
    } else if (response.data.error === A2B_API_ERRORS.VENDOR_OFF) {
      this.cartService.showVendorOffToast(response, () => {
        this.counterChange(
          counterResponse,
          product,
          undefined,
          response.data.error_next_date
        );
      });
    } else if (
      response.data.error === A2B_API_ERRORS.INSUFFICIENT_CREDITS ||
      (response.data.error === A2B_API_ERRORS.INSUFFICIENT_CREDITS_UNPAID &&
        this.dataService.doesUserBelongToBulkBuyHub)
    ) {
      this.aToBForLowBalance = {
        counterResponse,
        product,
        index,
        orderDate,
      };
      const paymentResponse = response.data.payment || {};
      paymentResponse.credit_exhausted = response.data.credit_exhausted;
      paymentResponse.is_credit_recommended =
        response.data.is_credit_recommended;
      if (response.data?.additionalCharges) {
        paymentResponse.additionalCharges = response.data?.additionalCharges;
      }
      if (paymentResponse.credit_exhausted) {
        this.eventsService.trackEvents(EVENT_NAMES.CREDIT_LIMIT_EXHAUSTED, {
          source: EVENT_SOURCES.CREDIT_LIMIT_EXHAUSTED,
        });
      }
      this.modalCtrl.getTop().then((topModal) => {
        if (
          (topModal && topModal.id !== MODAL_IDS.LOW_BALANCE_POPUP) ||
          !topModal
        ) {
          let trackPaymentBound = this.trackPayment.bind(this, component);
          this.cartService.openLowBalanceModel(
            paymentResponse,
            response.data.error_msg,
            trackPaymentBound
          );
        }
      });
    } else if (
      response.data.error === A2B_API_ERRORS.INSUFFICIENT_CREDITS_UNPAID &&
      !this.dataService.doesUserBelongToBulkBuyHub
    ) {
      const eventArgs = {
        af_price: product.sp,
        af_content: "",
        af_content_id: product.id,
        af_content_type: product.subcategory,
        af_currency: "INR",
        af_quantity: counterResponse.count,
      };
      this.eventsService.trackEvents(
        EVENT_NAMES.A2B_UNPAID,
        eventArgs,
        false,
        EventTrackingPlugins.AppsFlyer
      );
      this.modalService.openGenericInviteModal(
        EVENT_NAMES.ADD_TO_BASKET,
        InviteComponent
      );
    } else if (response.data.error === A2B_API_ERRORS.MAX_QUANTITY_EXCEEDED) {
      this.showMaxQuantityAlert();
    } else if (response.data.error === A2B_API_ERRORS.ORDER_LIMIT_REACHED) {
      this.showOrderLimitReachedAlert(response.data.errorData);
    } else if (response.data.error === A2B_API_ERRORS.NOT_INSERVICEABLE_AREA) {
      this.alertService.presentAlert(
        "Oops! We currently do not deliver in your area.",
        [ALERT_BUTTON_TEXT],
        ""
      );
    } else if (response.data.error === A2B_API_ERRORS.MBEYOND_ONLY_PRODUCT) {
      NON_MBEYOND_USER_REDIRECTION.BUTTONS.OKAY.handler = () => {
        this.modalCtrl.dismiss();
        this.navCtrl.navigateForward(
          LISTING_ROUTES.BASE + LISTING_ROUTES.M_BEYOND
        );
      };
      NON_MBEYOND_USER_REDIRECTION.META_DATA.MESSAGE = response.data.error_msg;
      const nonMbeyondUserErrorContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
        NON_MBEYOND_USER_REDIRECTION.META_DATA
      );
      this.alertService.presentAlertConfirm(nonMbeyondUserErrorContent, [
        NON_MBEYOND_USER_REDIRECTION.BUTTONS.OKAY,
      ]);
    } else {
      this.alertService.presentAlert(
        response.data.error_msg,
        [ALERT_BUTTON_TEXT],
        ""
      );
    }
  }

  trackPayment = (component, paymentResponse, txnId, paymentData) => {
    const paymentdata = {
      txnId,
      amount: paymentData.amount,
      payment_option: paymentData.pg,
      PaymentGateway: paymentData.PaymentGateway || PAYMENT_GATEWAY.JUSPAY,
      category: "Event",
      Source: paymentData.source ? paymentData.source : "",
    };
    if (paymentResponse === "success") {
      if (paymentData.pg === PAYMENT_VALUES.CRED) {
        const offerText = this.shoppingCreditsServices.getCredOffer();
        offerText && (paymentdata["cred_offer"] = offerText.offer);
      }
      this.eventsService.trackEvents(EVENT_NAMES.PAYMENT_SUCCESS, paymentdata);
      this.counterChange(
        component,
        this.aToBForLowBalance.counterResponse,
        this.aToBForLowBalance.product,
        this.aToBForLowBalance.index
      );
    } else {
      const paymentResponses = {
        amount: paymentData.amount,
        option: paymentData.pg,
        type: "retry",
        credit_exhausted: paymentData.credit_exhausted,
        is_credit_recommended: paymentData.is_credit_recommended,
      };
      if (paymentData.credit_exhausted) {
        this.eventsService.trackEvents(EVENT_NAMES.CREDIT_LIMIT_EXHAUSTED, {
          source: EVENT_SOURCES.CREDIT_LIMIT_EXHAUSTED,
        });
      }
      this.eventsService.trackEvents(EVENT_NAMES.PAYMENT_FAILURE, paymentdata);
      let trackPaymentBound = this.trackPayment.bind(this, component);
      this.cartService.openLowBalanceModel(
        paymentResponses,
        null,
        trackPaymentBound
      );
    }
  };

  requestProduct(component, product, isSourceSeeSimilar = false) {
    if (!this.authService.isLoggedIn()) {
      if (
        !Utilities.isApp() &&
        this.masterDataService.navigateFromDeeplink &&
        Utilities.isMobileBrowser()
      ) {
        this.masterDataService.getRedirectionToPlayStoreOrAppStore();
      } else {
        this.authService.openLoginPrompt();
      }
      return;
    }
    const productId = product.product_id || product.id;
    let eventArgs = {};
    if (isSourceSeeSimilar) {
      eventArgs = {
        SubCategory_type: product.subcategory_type,
        SubCategory: product.subcategory,
        Source: component.eventSource,
        Is_substitute: "no",
      };
    } else {
      eventArgs = {
        ProductId: productId,
        SubCategory: product.subcategory,
        Category: product.category,
        Source: component.eventSource,
        OOSType: product.refillType,
      };
    }
    this.eventsService.trackEvents(
      isSourceSeeSimilar
        ? EVENT_NAMES.VIEW_SIMILAR_REQUEST
        : EVENT_NAMES.NOTIFY_ME,
      eventArgs
    );

    this._requestProduct(productId).subscribe((response: any) => {
      if (response.data.status) {
        this.alertService.presentAlert(response.data.msg_code, [
          ALERT_BUTTON_TEXT,
        ]);
      } else if (
        this.masterDataService.masterData &&
        this.masterDataService.masterData.welcome_code &&
        this.masterDataService.masterData.welcome_code.title
      ) {
        if (!this.dataService.doesUserBelongToBulkBuyHub) {
          this.modalService.openGenericInviteModal("Request", InviteComponent);
        } else {
          const paymentResponse = response.data.payment || {};
          paymentResponse.credit_exhausted = response.data.credit_exhausted;
          paymentResponse.is_credit_recommended =
            response.data.is_credit_recommended;
          if (paymentResponse.credit_exhausted) {
            this.eventsService.trackEvents(EVENT_NAMES.CREDIT_LIMIT_EXHAUSTED, {
              source: EVENT_SOURCES.CREDIT_LIMIT_EXHAUSTED,
            });
          }
          let trackPaymentBound = this.trackPayment.bind(this, component);
          this.cartService.openLowBalanceModel(
            paymentResponse,
            response.data.error_msg,
            trackPaymentBound
          );
        }
      } else {
        this.alertService.presentAlert(
          response.data.error_msg,
          [ALERT_BUTTON_TEXT],
          ""
        );
      }
    });
  }

  processProductsData(component, products) {
    if (products) {
      this.timeoutForProcessProducts = setTimeout(() => {
        products = products.map((product, index) => {
          if (
            product._isEval === true &&
            product._lastType !== component.componentType
          ) {
            product._isEval = false;
          }
          if (product._isEval !== true) {
            product._lastType !== component.componentType;
            product._id = product.product_id || product.id;

            // product._hasVariants = (component.componentType === ProductListType.Product_Listing ||
            //     component.componentType === ProductListType.Search_Page ||
            //     component.componentType === ProductListType.Product_Details)
            //     && product.variants?.length > 0;

            if (product._hasVariants) {
              product.variants.sort((variant1, variant2) => {
                const variant1Wgt = this.getIntegerValueFromWeight(
                  variant1.wgt
                );
                const variant2Wgt = this.getIntegerValueFromWeight(
                  variant2.wgt
                );
                return variant1Wgt - variant2Wgt;
              });

              let maxScore = 0;
              product.selectedVariantIndex = 0;
              product.variants.forEach((variant, variantindex) => {
                if (variant.score > maxScore) {
                  maxScore = variant.score;
                  product.selectedVariantIndex = variantindex;
                }
              });
            }

            product._idVar = !product._hasVariants
              ? product.product_id || product.id
              : product.variants[product.selectedVariantIndex].id;
            product._hgColor =
              !product.oos && !product.forcedOos && product.highlightColor
                ? "#" + product.highlightColor
                : "";
            product._img = product.image || product.img;
            product?.offerImg &&
              (product._offerImage = this.imagePath + product?.offerImg);
            product._imgSrc = environment.productPath + product._img;
            product._oos = product.oos || product.forcedOos;
            product._nm = product.product_name || product.nm || product.name;
            product._wgt =
              product.product_weight || product.wgt || product.weight;
            product._flHide =
              product.oos ||
              product.forcedOos ||
              !product.highlightColor ||
              !product.title;
            product._fsHide =
              product.oos ||
              product.forcedOos ||
              (!product.source && !product.location);
            product._isNew =
              product.isNew ||
              (component.showRcNewlyAddedProducts &&
                ((this.recurringService.recurringCampaignProducts
                  ?.recurringCampaignMapper?.products?.[product.product_id]
                  ?.add_date &&
                  !this.rcNewlyAddedProducts) ||
                  (this.recurringService.recurringCampaignProducts
                    ?.recurringCampaignMapper?.products?.[product.product_id]
                    ?.add_date &&
                    this.rcNewlyAddedProducts &&
                    !this.rcNewlyAddedProducts[product.product_id])));
            product._offerText = !product._hasVariants
              ? product.offerText
              : product.variants[product.selectedVariantIndex].offerText;
            product._dsi =
              component.componentType !== ProductListType.Your_Cart &&
              product.delivery_types_slot &&
              product.delivery_types_slot[0] === DeliveryType.frozen &&
              this.dataService.isFrozenCheckEnabled;
            product._ct = product.ct && product.ct !== "0";
            product._ctf = this.currentHoursAndMinutes <= product.ct;
            product._svp =
              (product.saving_per_unit && product.saving_per_unit !== 0) ||
              (product.hasOwnProperty("sp") && product.mrp - product.sp > 0) ||
              (product.hasOwnProperty("price") &&
                product.mrp - product.price > 0);
            product._mrp = product.mrp_per_unit || product.mrp;
            product._off =
              product.saving_per_unit ||
              product.mrp - product.sp ||
              product.mrp - product.price;
            product._rsss =
              this.recurringService.recurringCampaignProducts
                ?.recurringCampaignMapper?.products?.[product.product_id] &&
              component.showSubscribeToSaveStrip;
            product._offerAvailable =
              this.dataService.offerTypeMapping.hasOwnProperty(
                OfferType.product
              ) &&
              this.dataService.offerTypeMapping[
                OfferType.product
              ].hasOwnProperty(product.id || product.product_id);
            product._btnDark =
              !product.oos &&
              !product.forcedOos &&
              (product.highlightColor ||
                component.componentType === ProductListType.Product_Details);
            product._showCalendar =
              component.componentType !==
                ProductListType.Recurring_Order_Listing &&
              component.componentType !== ProductListType.Offers &&
              component.componentType !== ProductListType.Your_Cart &&
              component.componentType !==
                ProductListType.Recurring_Search_Listing &&
              !product.oos &&
              !product.forcedOos &&
              product.recurring === 1 &&
              !component.isPDP &&
              component.componentType !== ProductListType.Product_Details &&
              component.showCalenderButton;
            product._isPlusOneDayDelivery =
              product.delivery_types_slot &&
              product.delivery_types_slot[0] === DeliveryType.frozen &&
              product.ct &&
              product.ct !== "0" &&
              this.currentHoursAndMinutes > product.ct;
            product._showSimilar =
              (product.id || product.product_id) ===
                component.recommendedProductList.product.id &&
              (component.recommendedProductList.data.length > 0 ||
                component.isRequestDivAllowed);
            product._source =
              (this.masterDataService.staticMasterData?.productSource &&
                this.masterDataService.staticMasterData?.productSource[
                  product.source
                ]) ||
              "";

            product._offerUndisclosed =
              product.offer_view_type === OFFER_VIEW_TYPE.UNDISCLOSED;
            product._bundleView =
              product.prop?.bundle_view !== BUNDLE_VIEW ||
              component.componentType === ProductListType.Product_Details;
            product._visible =
              (component.componentType !== ProductListType.Product_Listing &&
                component.componentType !== ProductListType.Search_Page) ||
              (!product.order_start_date && !product.order_end_date) ||
              (product.order_start_date &&
                product.order_end_date &&
                product.isPreOrder);
            product._show =
              !product.oos &&
              !product.forcedOos &&
              component.componentType !==
                ProductListType.Cancelled_Products_On_Cart;
            product._showMrp =
              component.componentType === ProductListType.Product_Details &&
              +(product?.sp || product?.price) ===
                +(product?.mrp_per_unit || product?.mrp);
            product._itemClasses = {
              "vod-box":
                !product.oos && !product.forcedOos && product.highlightColor,
              "item-fresh": product.location || product.source,
            };
            product._classes = {
              "cancel-product":
                component.componentType ===
                ProductListType.Cancelled_Products_On_Cart,
              "item-out-of-stock": component.showOOSOnProduct && product._oos,
              "pre-order": product.isPreOrder,
              "pre-order-detail":
                product.preorderedDate ||
                (component.componentType === ProductListType.Product_Details &&
                  product.isPreOrder),
              "vod-pdp-detail":
                component.componentType === ProductListType.Product_Details &&
                product.highlightColor,
            };

            product._isActive = this.isSetRecurring(product);
            product._showCounter =
              !(
                component.componentType ===
                ProductListType.Cancelled_Products_On_Cart
              ) &&
              !product.oos &&
              !product.forcedOos;
            product._counter =
              component.componentType === ProductListType.Your_Cart ||
              (component.basketIndex && component.basketIndex > 0)
                ? !product._hasVariants
                  ? product.quantity
                  : product.variants[product.selectedVariantIndex].quantity
                : this.authService.userData.tomorrowBasket?.[
                    !product._hasVariants
                      ? product.product_id || product.id
                      : product.variants?.[product.selectedVariantIndex]?.id
                  ];

            product._showDefaultImg =
              component.componentType === ProductListType.Product_Listing ||
              component.componentType ===
                ProductListType.Your_Frequent_Orders ||
              component.componentType === ProductListType.Collection ||
              component.componentType ===
                ProductListType.Product_Variant_List ||
              component.componentType === ProductListType.Search_Page
                ? false
                : true;
            product._showShimmer = !product._showDefaultImg;

            product._showThumbnail =
              component.componentType !== ProductListType.Product_Details &&
              component.isProductImageRequired;
            product._isEval = true;
          }

          if (
            this.masterDataService.masterData?.user &&
            this.masterDataService.masterData?.user?.is_mbeyond &&
            product.mbeyondSp
          ) {
            product.sp = product.mbeyondSp;
          }
          if (
            component.componentType === ProductListType.Product_Listing &&
            (this.dataService.calendarCoachmarkObject === null ||
              (this.dataService.calendarCoachmarkObject &&
                !this.dataService.calendarCoachmarkObject.hasOwnProperty(
                  "calendarCoachMark"
                ))) &&
            component.indexToShowRecurringCoachOn === -1 &&
            !product.oos &&
            !product.forcedOos &&
            !product.highlightColor &&
            product.recurring === 1
          ) {
            component.indexToShowRecurringCoachOn = index;
            this.timeoutForHideDiscoveryCoachMark = setTimeout(() => {
              this.hideDiscoveryCoachMark(component);
            }, 3000);
          }
          // setting product to oos if it is past cut off time and it's schedule is not allowed
          product.ct &&
            product.ct !== "0" &&
            this.currentHoursAndMinutes > product.ct &&
            !product.schedule &&
            (product.oos = 1);
          // update refillType if it is null and update UI Message if required
          // (for refillType 4(WillReturnInXDays) where we need to add day with month name in UIMessage)
          this.updateRefillTypeAndUIMessage(product);
          this.dataService.parseAndAssignStartEndDateToDateObject(product);
          this.checkIfProductIsPreordered(product);
          this.getTotalSPOfBundleProducts(product);
          if (product.oos === 1 && component.OosProductStartingIndex === null) {
            component.OosProductStartingIndex = index;
          }
          product._sp = product.hasOwnProperty("price_per_unit")
            ? product.price_per_unit
            : product.hasOwnProperty("sp")
            ? product.sp
            : product.price;
          return product;
        });
      });
    }
    return products;
  }

  getVariantDefaultProduct(product) {
    let processedVariant = [];
    if (!product._isSetDefaultVariant) {
      let finalVariants = [{ ...product }, ...product.variantProducts];
      if (product.oos === 1) {
        finalVariants = [...product.variantProducts, { ...product }];
      }
      processedVariant = [...finalVariants];
    } else {
      processedVariant = [...product._newVariantProducts];
    }
    const tomrwBasket = this.masterDataService.baskets[0]?.products?.length
      ? [...this.masterDataService.baskets[0]?.products]
      : [];
    let newDefault = processedVariant?.find((variantProduct) => {
      return [...tomrwBasket].find(
        (basketProduct) =>
          !variantProduct.oos && basketProduct.product_id == variantProduct.id
      );
    });
    if (!newDefault) {
      if (product.oos) {
        let inStockProduct = processedVariant.find((product) => !product.oos);
        if (inStockProduct) {
          newDefault = { ...inStockProduct };
        }
      }
    }
    if (newDefault && Object.keys(newDefault).length) {
      product = { ...newDefault };
    }
    product._newVariantProducts = processedVariant;
    product._isSetDefaultVariant = true;
    return product;
  }

  getIntegerValueFromWeight(weightString) {
    const weightInteger = weightString.match(/\s?\d*/);
    return /(kg|ltr)/i.test(weightString)
      ? weightInteger[0] * 1000
      : weightInteger[0];
  }

  checkIfProductIsPreordered(currentProduct) {
    // Added check for baskets to handle Just wanna explore flow
    if (
      this.masterDataService.preorderedBaskets &&
      (currentProduct.isStartDateEndDateSame || currentProduct.isPreOrder)
    ) {
      const basketIndex = this.masterDataService.preorderedBaskets.findIndex(
        (basket) => {
          return (
            basket.products.findIndex((product) => {
              return currentProduct.id === product.product_id;
            }) > -1
          );
        }
      );
      basketIndex > -1 &&
        (currentProduct.preorderedDate = moment(
          this.masterDataService.preorderedBaskets[basketIndex].date
        ));
    }
  }

  getTotalSPOfBundleProducts(product) {
    if (
      product.prop &&
      product.prop.show_sp &&
      product.prop.bundle_view === BUNDLE_VIEW
    ) {
      product.mrp = 0;
      product.bun_products.forEach((bundleProduct) => {
        product.mrp += bundleProduct.sp;
      });
    }
  }

  updateRefillTypeAndUIMessage(product) {
    if (product.oos || product.forcedOos) {
      if (
        product.refillType === RefillTypes.WillReturnInXDays &&
        product.refillDate
      ) {
        // converting string date to date object
        product.refillDate = moment(product.refillDate);
        // checking if refill date is greater than current date else changing refillType to 6(LeadTimeNotKnown)
        if (product.refillDate > moment()) {
          // checking if refill date is greater and only 1 day ahead of current date then changing refillType to 3(NextDay)
          if (product.refillDate.diff(moment().startOf("day"), "days") === 1) {
            product.refillType = RefillTypes.NextDay;
          } else {
            product.UIMessage =
              this.dataService.firebaseMasterData.refillTypesMetaData[
                product.refillType
              ].UIMessage.replace(
                this.dataService.firebaseMasterData.refillTypesMetaData[
                  product.refillType
                ].contentKeyToBeReplaced,
                product.refillDate.format(
                  this.dataService.firebaseMasterData.refillTypesMetaData[
                    product.refillType
                  ].dateFormat
                )
              );
          }
        } else {
          product.refillType = RefillTypes.LeadTimeNotKnown;
        }
      }
      if (product.refillType !== RefillTypes.WillReturnInXDays) {
        // if there is no type coming and baOos is coming and its 0 then type 3(NextDay) else type 6(LeadTimeNotKnown)
        !product.refillType &&
          (product.refillType = product.baOos
            ? RefillTypes.LeadTimeNotKnown
            : RefillTypes.NextDay);
        this.dataService.firebaseMasterData.refillTypesMetaData[
          product.refillType
        ] &&
          (product.UIMessage =
            this.dataService.firebaseMasterData.refillTypesMetaData[
              product.refillType
            ].UIMessage);
      }
    }
  }

  hideDiscoveryCoachMark(component) {
    if (this.dataService.calendarCoachmarkObject === null) {
      this.dataService.calendarCoachmarkObject = {};
    }
    component.indexToShowRecurringCoachOn = -1;
    this.dataService.calendarCoachmarkObject.calendarCoachMark = true;
    localStorage.setItem(
      STORAGE_KEYS.CALENDAR_COACHMARK_OBJECT,
      JSON.stringify(this.dataService.calendarCoachmarkObject)
    );
  }

  // Remove Cancel Products from basket.
  removeCancelProduct(component, productId) {
    this.removeCancelProductRequest(
      productId,
      component.isCancelledProduct
    ).subscribe((productList: HTTPResponse) => {
      if (productList.data.status) {
        this.eventsService.trackEvents(EVENT_NAMES.REMOVED_CANCELLED_ITEMS, {
          product_Id: productId,
        });
        this.masterDataService.baskets = productList.data.data;
        this.dataService.knockKnock.next(true);
      } else {
        this.alertService.presentAlert(
          productList.data.error_msg,
          [ALERT_BUTTON_TEXT],
          ""
        );
      }
    });
  }

  productDetailsModal(
    productId: number,
    productIndex: number,
    componentType,
    eventSource?: string,
    eventData?: any,
    itemType?: string,
    product?: any,
    appliedFilter?: string,
    basketIndex?: number
  ) {
    if (
      [
        ProductListType.Product_Details,
        ProductListType.Recurring_Search_Listing,
        ProductListType.Recurring_Order_Listing,
        ProductListType.Account_History_Details,
        ProductListType.Discount_Type_Product_List,
      ].indexOf(componentType) === -1 &&
      product?.recurring !== OfferDiscountedProduct.DiscountedProduct
    ) {
      let componentProp = {
        productId,
        productIndex,
        source: eventSource,
        eventData: eventData,
        itemType,
        product,
        appliedFilter,
        basketIndex,
      };
      this.dataService.selectedPDPProduct.next(componentProp);
      let source =
        eventData?.Source ||
        eventData?.source ||
        eventSource ||
        EVENT_NAMES.PDP;
      if (eventData?.type === EVENT_NAMES.PRODUCT_LAUNCH) {
        source = EVENT_NAMES.PRODUCT_LAUNCH;
      }
      if (!this.router.url.includes("/listing/product-detail/")) {
        this.navCtrl.navigateForward(
          LISTING_ROUTES.BASE +
            LISTING_ROUTES.PRODUCT_DETAIL +
            "/" +
            productId +
            "/" +
            source
        );
      } else {
        this.navCtrl.navigateForward(
          LISTING_ROUTES.BASE +
            LISTING_ROUTES.PRODUCT_DETAIL +
            "/" +
            productId +
            "/" +
            source,
          { replaceUrl: true }
        );
      }
    }
  }

  ngOnDestroy() {
    this.clearTimers();
  }

  _getSimilarProducts(product, dataToBeSent, successCallback) {
    this.dataService.parentProductId = dataToBeSent.product_id;
    this.httpService
      .post(LISTING_APIS.SIMILAR_PRODUCTS, dataToBeSent)
      .subscribe((response: HTTPResponse) => {
        if (response.data.status) {
          successCallback(response.data.data, product);
        } else {
          this.alertService.presentAlert(response.data.error_msg, [
            ALERT_BUTTON_TEXT,
          ]);
        }
      });
  }

  _getRelatedProducts(dataToBeSent): Observable<object> {
    dataToBeSent.hideLoader = true;
    return this.httpService.post(LISTING_APIS.RELATED_PRODUCTS, dataToBeSent);
  }

  getProductSubcategories(productId): Observable<object> {
    return this.httpService.get(
      `${LISTING_APIS.PRODUCTS}/${productId}/${LISTING_APIS.SUBCATEGORIES}`,
      { hideLoader: true }
    );
  }

  showMaxQuantityAlert() {
    const maxQuantityReachedContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
      MAX_QUANTITY_REACHED_ALERT.META_DATA
    );
    this.alertService.presentAlert(
      maxQuantityReachedContent,
      MAX_QUANTITY_REACHED_ALERT.BUTTONS,
      MAX_QUANTITY_REACHED_ALERT.CSS_CLASS
    );
  }

  showOrderLimitReachedAlert(errorData) {
    // errorData array will either have amount_{value} or quantity_{value}
    if (errorData && errorData.length) {
      const reasonKeyValue = errorData[0].replace(/\.\d+/, "").split("_");
      this.dataService.firebaseMasterData.orderLimitReachedAlertData.META_DATA.HEADER_TEXT =
        this.dataService.firebaseMasterData.orderLimitReachedAlertData.HEADER_TEXTS[
          reasonKeyValue[0]
        ];
      this.dataService.firebaseMasterData.orderLimitReachedAlertData.META_DATA.MESSAGE =
        this.dataService.firebaseMasterData.orderLimitReachedAlertData.MESSAGES[
          reasonKeyValue[0]
        ];
      const contentKeysToBeReplaced = {};
      contentKeysToBeReplaced[reasonKeyValue[0]] = reasonKeyValue[1];
      this.alertService.presentAlert(
        GENERATE_CONTENT_FOR_ALERT_LAYER(
          this.dataService.firebaseMasterData.orderLimitReachedAlertData
            .META_DATA,
          contentKeysToBeReplaced
        ),
        this.dataService.firebaseMasterData.orderLimitReachedAlertData.BUTTONS,
        this.dataService.firebaseMasterData.orderLimitReachedAlertData.CSS_CLASS
      );
    }
  }

  _requestProduct(productId): Observable<object> {
    return this.httpService.post(LISTING_APIS.PRODUCT_REQUEST, {
      product_id: productId,
    });
  }

  removeCancelProductRequest(
    productId,
    loaderHide = false
  ): Observable<object> {
    const dataToSend: any = { product_id: productId };
    if (loaderHide) {
      dataToSend.hideLoader = true;
    }
    return this.httpService.post(
      LISTING_APIS.REMOVE_CANCEL_PRODUCTS,
      dataToSend
    );
  }

  isSetRecurring(product: ProductInSearch | RecurringOrder) {
    return (
      this.masterDataService.recurringOrder &&
      this.masterDataService.recurringOrder.find((item) => {
        return (
          item.product_id === product.product_id ||
          (!product.product_id && item.product_id === product.id)
        );
      })
    );
  }

  async openCalendarModal(viewType) {
    this.dataService.calendarMetaData.viewType = viewType;
    const productInfo = this.dataService.calendarMetaData.productInfo;
    const { eventSource = '', productIndex = 0 } = productInfo;
    const productId = productInfo.product.product_id || productInfo.product.id || productInfo.product.pid;
     return this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.SET_SUBSCRIPTION_PLAN + '/' + productId + '/' + productIndex+ '/' + eventSource);
  }

  trackShowAll() {
    return this.eventsService.trackEvents(EVENT_NAMES.VIEW_ALL_RECOMMENDATION, {
      source: EVENT_NAMES.HOME,
    });
  }

  setToCalendar(product, productIndex) {
    this.dataService.calendarMetaData.productInfo.productIndex = productIndex;
    this.dataService.calendarMetaData.productInfo.eventSource =
      EVENT_SOURCES.CALENDAR_EXPLORE;
    const foundProduct: any = this.isSetRecurring(product);
    if (foundProduct) {
      foundProduct.isEdit = true;
      this.dataService.calendarMetaData.productInfo.product = foundProduct;
      this.openCalendarModal(RecurringViewType.ViewPlan);
    } else {
      this.dataService.calendarMetaData.productInfo.product = product;
      this.dataService.calendarMetaData.productInfo.product.product_id =
        product.id;
      this.openCalendarModal(RecurringViewType.SelectPlanType);
    }
  }

  getRunChangeDetForA2B() {
    return this.dataService.runChangeDetForA2B;
  }

  getRunChangeDetForRecurringOrdersChange() {
    return this.dataService.recurringOrdersChange;
  }

  getSelectedProductForCalendar() {
    return this.dataService.calendarMetaData?.productInfo?.product;
  }

  setBranchLaunchData(metaData) {
    this.dataService.brandLaunchData = metaData;
  }

  goToCalendar(product, productIndex, eventSource, itemType, appliedFilter) {
    if (this.authService.isLoggedIn()) {
      if (this.masterDataService.masterData?.user?.wf) {
        return;
      }
      if (/subscribe-to-save/i.test(this.router.url)) {
        this.eventsService.trackEvents(
          EVENT_NAMES.RECURRING_CAMPAIGN_SUBSCRIBE_ICON_CLICK,
          {
            source: EVENT_SOURCES.SUBSCRIBE_TO_SAVE_PAGE,
            category: product.category,
            product_id: product.id,
          }
        );
      }
      this.dataService.calendarMetaData.productInfo.product = product;
      this.dataService.calendarMetaData.productInfo.productIndex = productIndex;
      this.dataService.calendarMetaData.productInfo.eventSource = eventSource;
      this.dataService.calendarMetaData.productInfo.itemType = itemType;
      this.dataService.calendarMetaData.productInfo.appliedFilter =
        appliedFilter;
      this.dataService.calendarMetaData.flow_type = "new";
      const ifCalendarJourneyVisited =
        this.dataService.ifCalendarJourneyVisited();
      if (ifCalendarJourneyVisited) {
        this.calendarRedirect();
      } else {
        this.calendarRedirect();
        setTimeout(() => {
          this.dataService.updateJourneyTakenFlag();
          this.navCtrl.navigateForward(
            LISTING_ROUTES.BASE + LISTING_ROUTES.CALENDAR_JOURNEY,
            { animated: false }
          );
        }, 150);
      }
    } else {
      if (
        !Utilities.isApp() &&
        this.masterDataService.navigateFromDeeplink &&
        Utilities.isMobileBrowser()
      ) {
        this.masterDataService.getRedirectionToPlayStoreOrAppStore();
      } else {
        this.openBottomSheet(
          InfoPopupComponent,
          [0, 0.45, 0.5, 1],
          0.5,
          LOGIN_SIGNUP_A2B_POPUP
        );
      }
    }
  }

  calendarRedirect = () => {
    const foundProduct: any = this.isSetRecurring(
      this.dataService.calendarMetaData.productInfo.product
    );
    if (foundProduct) {
      foundProduct.isEdit = true;
      this.dataService.calendarMetaData.flow_type = "edit";
      this.dataService.calendarMetaData.productInfo.product = foundProduct;
      this.openCalendarModal(RecurringViewType.ViewPlan);
    } else {
      this.openCalendarModal(RecurringViewType.SelectPlanType);
    }
  };

  openScheduleModal(component, product, productIndex) {
    return this.cartService.checkProductDailyLimitAndOpenScheduleModal(
      product,
      component.eventSource,
      productIndex,
      EVENT_ARGS.PRE_ORDER,
      component.appliedFilter,
      ScheduleType.Pre_Order,
      product.isStartDateEndDateSame && product.startDate,
      this.checkIfProductIsPreordered.bind(this)
    );
  }

  trackVariantSwitch(product) {
    this.eventsService.trackEvents(EVENT_NAMES.VARIANT_VIEWED, {
      Product_Name: product.product_name || product.nm || product.name,
      Category_Name: product.category || "",
      Subcategory_Name: product.subcategory || "",
      Subcategory_type: product.subcategory_type || "",
      Product_Weight:
        product.variants[product.selectedVariantIndex].wgt ||
        product.variants[product.selectedVariantIndex].weight,
    });
  }

  async openBottomSheet(
    component,
    breakpoints,
    initialBreakpoint,
    data?,
    callback?,
    cssClass?
  ) {
    const modal = await this.modalCtrl.create({
      component: component,
      breakpoints: breakpoints,
      initialBreakpoint: initialBreakpoint,
      componentProps: { data },
      backdropDismiss: true,
      cssClass: cssClass || "modal-bottom-sheet",
      handle: false,
    });
    await modal.present();
    modal.onDidDismiss().then((res) => {
      if (callback) {
        callback(res);
      }
    });
  }
}
