import { Injectable } from '@angular/core';
import { Utilities } from 'src/app/shared/classes/utility/utilities';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/providers/http/http.service';
import { LoggingService } from '../../../shared/providers/logging/logging.service';
import { APIs } from '../../constants/APIs';


@Injectable({
  providedIn: 'root'
})
export class JuspayService {

  constructor(
    private httpService: HttpService,
    private loggingService: LoggingService
  ) { }
  getRequest(url) {
    return new Observable<object>(subscribe => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const parsedResponse = Utilities.parseJSONString(xhr.response);
          if (xhr.status === 200) {
            const response = {
              data: parsedResponse
            };
            subscribe.next(response);
          } else {
            subscribe.error(parsedResponse || '');
          }
        }
      };

      xhr.open('GET', url, true);
      xhr.setRequestHeader('x-merchantid', 'milkbasket');
      xhr.send();
    }).pipe(
      take(1),
      tap(response => {
        this.loggingService.logMessage('juspay card bin response: ', response);
      })
    );
  }
  cardInfo(
    curdNumber: number
  ): Observable<object> {
    const requestUrl = `${APIs.CARD_BINS}${curdNumber}?merchant_id=milkbasket&options.check_mandate_support=true`;
    return this.getRequest(
      requestUrl
    );
  }
  getSavedCard(): Observable<object> {
    return this.httpService.get(
      APIs.GET_CARDS
    );
  }

  upiVerify(upi: string): Observable<object> {
    return this.httpService.post(
      `${APIs.VERIFY_UPI}/${upi}`, {}
    );
  }

  generateOtp(gateway, mobileNumber): Observable<object> {
    return this.httpService.post(
      `${APIs.CREATE_WALLET}${gateway}&phone=${mobileNumber}`, {}
    );
  }

  verifyOtp(dataToSend): Observable<object> {
    return this.httpService.post(
      `${APIs.LINK_WALLET}/${dataToSend.wallet_id}?otp=${dataToSend.otp}`, {}
    );
  }

  refreshWallet(dataToSend): Observable<object> {
    return this.httpService.post(
      `${APIs.REFRESH_WALLET}/${dataToSend.walletId}`, {}
    );
  }


  deleteSavedCard(cardToken: string, dataToSend): Observable<object> {
    return this.httpService.post(
      `${APIs.DELETE_CARDS}/${cardToken}`, dataToSend
    );
  }
  getAutoDebit(): Observable<object> {
    return this.httpService.get(APIs.AUTO_DEBIT, { hideLoader: true });
  }
}
