export class ProductPricing {
    __typename = 'ProductPricing';
    amount: string;
    value: number;
    text: string;

    setMbeyondFromApi(productApi) {
        this.value = this.roundOff(productApi.mbeyondSp || 0);
        this.amount = `${this.value}`;
        return this;
    }

    setMrpFromApi(productApi) {
        this.value = this.roundOff(productApi.mrp_per_unit || productApi.mrp || 0);
        this.amount = `${this.value}`;

        return this;
    }

    setPriceFromApi(productApi) {
        this.value = this.roundOff(
            productApi.price_per_unit || productApi.sp || productApi.price || 0,
        );
        this.amount = `${this.value}`;

        return this;
    }

    roundOff(number) {
        return Number((+number || 0).toFixed(2));
    }
}
