//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Output } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { AuthService } from '@Globals/providers/auth.service';
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
import { SignupService } from '@Globals/providers/signup.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, EVENT_SOURCES } from '@Globals/constants/registration/constants';
import { AddressSelectionType } from '@Globals/constants/registration/enums';
import { SOCIETIES, SocietyPayload } from '@Globals/interfaces/registration/interfaces';
import { NavController } from '@ionic/angular';
import { CORE_ROUTES } from '@App/core/constants/coreRoutes';
import { REGISTRATION_ROUTES } from '@App/registration/constants/registration-routes';
//#endregion Other Imports
declare var google;

//#endregion Imports

@Component({
  selector: 'mbpc-select-delivery-location',
  templateUrl: './select-delivery-location.component.html',
  styleUrls: ['./select-delivery-location.component.scss'],
})
export class SelectDeliveryLocationComponent extends DefaultComponent {

  searchSocietiesListing:Array<SOCIETIES>;
  nearBySocieities: Array<SOCIETIES> = [];
  @Output() selectSociety = new EventEmitter();
  @Output() addNewAddress = new EventEmitter();
  @Output() turnLocationOn = new EventEmitter();
  @Output() changeSheetState = new EventEmitter();
  @Output() recenterUser = new EventEmitter();
  @Output() changeSheetBackToNormal = new EventEmitter();
  hasFocus:boolean = false;
  viewTypes = AddressSelectionType;
  subscriptions: Subscription[] = [];
  eventSource = EVENT_SOURCES;
  searchData: any = {
    placeholder: 'Search for your society',
    text: ''
  };
  isUnserviceableFlow: boolean = false; 
  autoCompleteSocieties: any[] = [];
  constructor(public signupService: SignupService,
    public authService: AuthService,
    private dataService: DataService,
    private eventsService: EventsService,
    private navCtrl: NavController) {
      super();
     }

  mbOnInit() {
    this.isUnserviceableFlow = false;
    const nearBySocietySubjectSubscription = this.signupService.nearBySocietySubject.subscribe(data => {
      this.nearBySocieities = data;
    });
    this.subscriptions.push(nearBySocietySubjectSubscription);
    this.subscriptions.push(
      this.dataService.goBackSubjectForUnserviceableFlow.subscribe((response:boolean)=>{
        if(response) {
          this.isUnserviceableFlow = this.dataService.isUnserviceableFlow;
        }
      })
    );
  }
  
  onSelect(society:SOCIETIES,src:string) {
    this.selectSociety.emit({society,src});
  }

  triggerRecenter() {
    this.recenterUser.emit();
  }

  onFocus(focusEvent) {
    this.hasFocus = focusEvent;
    this.hasFocus = true;
    this.changeSheetState.emit(true);
  }

  enableLocation(){
    this.eventsService.trackEvents(EVENT_NAMES.ENABLE_LOCATION_CLICKED, {source : EVENT_SOURCES.SIGNUP_SOCIETY_PAGE_VIEW});
    this.turnLocationOn.emit();
  }

  getSearchSocieties(searchText:string) {
    if (searchText.length > 2) {
      let dataToSend:SocietyPayload = {search_text: searchText,all_type:true};
      if(this.signupService.latLong) {   
        dataToSend = {...dataToSend, latitude:this.signupService.latLong.latitude , longitude: this.signupService.latLong.longitude}
      } else if(Object.keys(this.dataService.ipCoordinates).length) {
        dataToSend = {...dataToSend, latitude:this.dataService.ipCoordinates.latitude , longitude: this.dataService.ipCoordinates.longitude}
      }
      this.authService.isLoggedIn() && ( dataToSend = {active : 1 ,...dataToSend});
      const getNearBySocietiesSubscription = this.signupService
        .getNearBySocieties(dataToSend)
        .subscribe((success: any) => {
          if (success.data.status && success.data.data.length > 0) {
              this.searchSocietiesListing = success.data.data;
              this.tracksocietySearched(searchText,this.searchSocietiesListing.length);
          } else {
            this.searchSocietiesListing = [];
            this.authService.userData.cityObj = {
              name: null,
              id: null
            }
            this.tracksocietySearched(searchText);
          }
        });
      this.subscriptions.push(getNearBySocietiesSubscription);
    } 
  }

  tracksocietySearched(searchText:string, societyCount = 0) {
    const eventArgs = {
      Keyword: searchText,
      'Result Count': societyCount,
      isGPS: !!this.signupService.latLong
    };
    this.eventsService.trackEvents(EVENT_NAMES.SOCIETY_SEARCHED, eventArgs);
  }

  addAddress() {
    this.authService.source = EVENT_SOURCES.ADD_ADDRESS;
    this.addNewAddress.emit();
  }

  addNewAddr() {
    this.searchData.text = "";
    this.searchData.placeholder = "Search for your society";
    this.isUnserviceableFlow = true;
    this.dataService.isUnserviceableFlow = true;
    this.changeSheetBackToNormal.emit(true);
  }

  autoCompleteList(societies:any[]) {
    this.autoCompleteSocieties = societies;
  }

  autoCompleteSelect(society: any) {
    this.getLatLongAndLocation(society);
  }

  getLatLongAndLocation(society: any) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: society.place_id })
    .then(({ results }) => {
      if (results[0]) {
        const locationAddress = results[0];
        const city = locationAddress?.address_components?.filter(comp => comp.types.includes("locality"))?.map(cityObj => cityObj.long_name)[0];
        const societyObj = {
          city: city,
          latitude: locationAddress.geometry.location.lat(),
          longitude: locationAddress.geometry.location.lng(),
          location: society.description,
          name: society.structured_formatting.main_text
        };
        this.onSelect(societyObj,this.eventSource.GOOGLE_AUTOCOMPLETE);
      }
    })
  }

  mbOnDestroy(){
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
