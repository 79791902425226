//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { ProductListType } from '@Globals/constants/listing/enums';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-two-row-products-list-block',
  templateUrl: './two-row-products-list.component.html',
  styleUrls: ['./two-row-products-list.component.scss']
})
export class TwoRowProductsListComponent extends BaseCollectionComponent {
  twoRowProductListing = ProductListType.Two_Row_Horizontal_Listing;
  eventData: any;
  
  mbOnInit(): void {
    this.data && this.getCollectionListProducts();
    if(this.props) {
      this.eventData = { Source: this.props?.eventSource, collection_id: this.data.id };
    }
    super.mbOnInit();
  }
}
