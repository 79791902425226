<div class="top-fixed" [ngClass]= "{ 'current-detail' : hasFocus && !isUnserviceableFlow }">
  <div class="fixed-bottom-sheet" *ngIf="!isUnserviceableFlow">
    <mbs-bottom-sheet-header [component]="viewTypes.SelectSociety">
      Select Delivery Location
    </mbs-bottom-sheet-header>
    <mbf-search class="delivery-search" (searchText)="getSearchSocieties($event)" [autoFocus]='false' (getFocus)="onFocus($event)"
      [(searchData)]="searchData" (searchDataChange)="searchData=$event"></mbf-search>

      <div class="available-location">
        <div class=" ctn-gt-space-p">
          <ng-container *ngIf="hasFocus">
            <div *ngIf="signupService.latLong ; else noLocation" (click)="triggerRecenter()"
              class="location-detail current-location">
              <div class="location-img">
                <ion-icon class="icon-location-gps icons-property"></ion-icon>
              </div>
              <div class="location-text ion-no-margin">
                <h3 text-ellipsis-ml>Current location</h3>
                <p text-ellipsis-ml>using GPS</p>
              </div>
            </div>
            <ng-template #noLocation>
              <div class="location-detail  unable-location">
                <div class="location-img">
                  <ion-icon class="icon-location-gps icons-property"></ion-icon>
                </div>
                <div class="location-text ion-no-margin">
                  <h3 text-ellipsis-ml>Unable to get location</h3>
                  <p text-ellipsis-ml>Turn on location for smooth delivery</p>
                </div>
                <ion-button class=" t-t-n no-ripple-effect turn-on ion-no-padding" (click)="enableLocation()" margin-vertical-large
                  fill="clear" size="default">
                  Turn On
                </ion-button>
              </div>
            </ng-template>
          </ng-container>
          <p class="near-society" text-ellipsis-ml *ngIf="searchData.text.length ; else nearBySocietyCount" >Search results for <span
              class="current-search" >{{searchData.text}}</span></p>
          <ng-template #nearBySocietyCount>
            <p class="near-society" text-ellipsis-ml >Near By Societies/Apartments ({{nearBySocieities.length}})</p>
          </ng-template>
        </div>
      </div>
  </div>
  <div class="fixed-bottom-sheet" *ngIf="!!isUnserviceableFlow">
    <mbs-bottom-sheet-header [component]="viewTypes.SelectSociety">
      New Address Request
    </mbs-bottom-sheet-header>
    <mbf-search class="delivery-search" (getFocus)="onFocus($event)" [autoFocus]='false' [isGoogleAddressAutoComplete]="isUnserviceableFlow" [latLng]="!!this.signupService.latLong ? this.signupService.latLong : this.dataService.ipCoordinates"
    (searchText)="getSearchSocieties($event)" [(searchData)]="searchData" (autocompleteItemsOutput)="autoCompleteList($event)"></mbf-search>
    <div class="available-location">
      <div class="ctn-gt-space-p">
        <p class="near-society" *ngIf="autoCompleteSocieties && autoCompleteSocieties.length" text-ellipsis-ml >Search results for <span
          class="current-search" >{{searchData.text}}</span></p>
        <div *ngIf="autoCompleteSocieties && autoCompleteSocieties.length === 0" class="near-society-text">
          Use the search bar or drop a pin on the map to select your exact address
        </div>
      </div>
    </div>
  </div>
</div>
<ion-content class="ctn-gt-space-p" [ngClass]= "{ 'unavailable-location' : hasFocus && !isUnserviceableFlow}">
  <!-- search results data -->
  <ng-container *ngIf="searchData.text.length; else nearBySocieties">
    <div class="search-not-found"
      *ngIf="searchSocietiesListing && searchSocietiesListing.length === 0 && !isUnserviceableFlow; else searchListing">
        <ion-item class="address-not-found" lines="none" color="none" (click)="addNewAddr()">
          <ion-label class="ion-text-wrap">
            <h4 class="f-w-b text-size-md">Can’t find your address?</h4>
            <p class="text-size-sm f-w-m">Please share your complete address details for us to check the serviceability of your request.</p>
            <ion-button class="t-t-n text-size-md f-w-s-b share-details" fill="clear" color="primary">Share details <ion-icon class="icons-property icon-arrow-right" color="primary"></ion-icon></ion-button>
          </ion-label>
        </ion-item>
    </div>
    <ng-template #searchListing>
      <ion-list class="ion-no-padding">
        <ion-item *ngFor="let society of searchSocietiesListing" (click)="onSelect(society,eventSource.SEARCHED)" class="location-list ion-no-padding"
          margin-vertical-medium lines="none">
            <ion-icon slot="start" class="ion-no-margin icon-location-active icons-property map"></ion-icon>
            <ion-label class="location-text ion-no-margin" >
             <h3 class="text-ellipsis-tl" appTextHighlight [term]="searchData.text" [data]="society.name">{{society.name}}</h3>
              <p text-ellipsis-ml> {{ society.location }} {{society.location.length ? ',' : ''}} {{ society.cityName || society.city }}</p>
            </ion-label>
        </ion-item>
      </ion-list>
      <ion-item class="address-not-found" lines="none" color="none" *ngIf="!isUnserviceableFlow" (click)="addNewAddr()">
        <ion-label class="ion-text-wrap">
          <h4 class="f-w-b text-size-md">Can’t find your address?</h4>
          <p class="text-size-sm f-w-m">Please share your complete address details for us to check the serviceability of your request.</p>
          <ion-button class="t-t-n text-size-md f-w-s-b share-details" fill="clear" color="primary">Share details <ion-icon class="icons-property icon-arrow-right" color="primary"></ion-icon></ion-button>
        </ion-label>
      </ion-item>
    </ng-template>
  </ng-container>

  <!-- near by socities data -->
  <ng-template #nearBySocieties>
    <ion-list *ngIf="nearBySocieities.length && !isUnserviceableFlow" class="ion-no-padding">
      <ion-item *ngFor="let society of nearBySocieities" (click)="onSelect(society,eventSource.NEARBY_SOCIETIES)" class="location-list ion-no-padding"
        margin-vertical-medium lines="none">
          <ion-icon slot="start" class="ion-no-margin icon-location-active icons-property"></ion-icon>
          <ion-label class="location-text ion-no-margin">
            <h3 class="text-ellipsis-tl">{{society.name}}</h3>
            <p text-ellipsis-ml> {{ society.location }} {{society.location.length ? ',' : ''}} {{ society.cityName || society.city}}</p>
          </ion-label>
      </ion-item>
    </ion-list>
    <ion-item class="address-not-found" lines="none" color="none" *ngIf="!isUnserviceableFlow" (click)="addNewAddr()">
      <ion-label class="ion-text-wrap">
        <h4 class="f-w-b text-size-md">Can’t find your address?</h4>
        <p class="text-size-sm f-w-m">Please share your complete address details for us to check the serviceability of your request.</p>
        <ion-button class="t-t-n text-size-md f-w-s-b share-details" fill="clear" color="primary">Share details <ion-icon class="icons-property icon-arrow-right" color="primary"></ion-icon></ion-button>
      </ion-label>
    </ion-item>
  </ng-template>
  <ng-container #autoCompleteSocities *ngIf="isUnserviceableFlow">
    <ion-list *ngIf="autoCompleteSocieties && autoCompleteSocieties.length" class="ion-no-padding">
      <ion-item *ngFor="let society of autoCompleteSocieties" class="location-list ion-no-padding" (click)="autoCompleteSelect(society)"
        margin-vertical-medium lines="none">
          <ion-icon slot="start" class="ion-no-margin icon-location-active icons-property"></ion-icon>
          <ion-label class="location-text ion-no-margin" >
            <h3 class="text-ellipsis-tl">{{society.description}}</h3>
          </ion-label>
      </ion-item>
    </ion-list>
  </ng-container>
</ion-content>