//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Output } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AlertService } from '@Globals/providers/alert.service';
import { AuthService } from '@Globals/providers/auth.service';
import { EventsService } from '@Globals/providers/events.service';
import { SignupService } from '@Globals/providers/signup.service';
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
import {
  PAGE_TAGS,
  GENERATE_CONTENT_FOR_ALERT_LAYER,
} from '@Globals/constants/listing/constants';
import {
  EMPTYCART_ALERT
} from '@Globals/constants/registration/constants';
import { EVENT_NAMES, EVENT_SOURCES } from '@Globals/constants/registration/constants';
import { AddressSelectionType, SocietyType } from '@Globals/constants/registration/enums';
import { REGISTRATION_ROUTES } from '@Globals/constants/registration/routes';
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbpc-confirm-society',
  templateUrl: './confirm-society.page.html',
  styleUrls: ['./confirm-society.page.scss'],
})
export class ConfirmSocietyPage extends DefaultComponent {
  viewTypes = AddressSelectionType;
  societyType = SocietyType;
  @Output() confirmSociety = new EventEmitter();
  constructor(private navCtrl: NavController,
    public authService: AuthService,
    public dataService: DataService,
    public signupService: SignupService,
    private eventService: EventsService,
    private alertService: AlertService) {
    super();
  }

  mbOnInit() {
    this.eventService.trackEvents(PAGE_TAGS.CONFIRM_SOCIETY, {});
  }

  goBack() {
    this.eventService.trackEvents(EVENT_NAMES.CHANGE_SOCIETY, { src: EVENT_SOURCES.SOCIETY_CONFIRMATION });
    this.authService.source = EVENT_SOURCES.SOCIETY_CONFIRMATION;
    this.navCtrl.navigateBack(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.GEOLOCATION + '/' + AddressSelectionType.SelectSociety);
  }

  confirmSelection() {
    this.confirmSociety.emit();
  }

  openAlert() {
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(
        EMPTYCART_ALERT.META_DATA
      ),
      [
        EMPTYCART_ALERT.BUTTONS.CLOSE,
        EMPTYCART_ALERT.BUTTONS.OKAY,
      ],
      EMPTYCART_ALERT.CSS_CLASS
    );
  }
}
