<ion-header class="ion-main-header sticky-header">
  <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium pl-0">
      <ion-buttons class="ion-buttons-arrow-back" id="custom-arrow-back" slot="start">
          <ion-button (click)="closeModal()">
              <ion-icon class="icon-arrow-back icons-property icon-md" color="dark" slot="icon-only"></ion-icon>
          </ion-button>
      </ion-buttons>
      <ion-title class="ion-no-padding ">
          Payment
      </ion-title>
      <ion-buttons slot="end">
          <ion-button (click)="navigateToExternalUrl()" class="help-button">
            <ion-icon slot="icon-only" class="icon-help icon-md icons-property" color="dark"></ion-icon>
          </ion-button>
        </ion-buttons>
  </ion-toolbar>
</ion-header>
<p class="top-up padding-left-medium padding-bottom-medium padding-right-extra-big-large">
  You are doing a top up of <span class="inr-g f-w-b"> {{shoppingCreditAmount}}</span> Choose a payment method
</p>
<ion-content class="payment-option theme-light">
  <div class="payment-list padding-top-base theme-light" *ngIf="((dataService.currentPlatform === currentPlatform.IOS || dataService.currentPlatform === currentPlatform.WEB) && masterDataService.paymentOffers.length) || (dataService.currentPlatform === currentPlatform.ANDROID && masterDataService.paymentConfig.showPaymentOffers)">
      <mbs-payment-offer-listing [isSort]="false"></mbs-payment-offer-listing>
  </div>
  <ng-container *ngFor="let option of masterDataService.paymentMethodList">
      <div [ngSwitch]="option.name">
          <ng-container *ngSwitchCase="paymentMethod.WALLET">
              <ion-list-header *ngIf="masterDataService.paymentOptionSection.wallet.options.length" class="sub-heading f-w-b padding-top-medium theme-light">Wallets</ion-list-header>
              <div class="ion-no-padding payment-option-list" *ngIf="masterDataService.paymentOptionSection.wallet.options.length">
                  <ion-item (click)="activePayment = wallet.option.value" [ngClass]="{'active-card-details': activePayment === wallet.option.value}" color="none" lines="none" class="br-bottom padding-vertical-extra-medium" *ngFor="let wallet of masterDataService.paymentOptionSection.wallet.options">
                      <div class="w-100" *ngIf="wallet.option.isAllowed">
                          <div alt="wallet icon" class="d-flex ctn-gt-space-p ion-align-items-center">
                              <img [src]="wallet.icon" slot="start" alt="" class="pm-w">
                              <ion-label>
                                  <p class="p-title f-w-m">{{wallet.option.name}}
                                      <ng-container *ngIf="wallet.option.offers?.length || wallet.option.commonOffers?.length">
                                          <small class="ion-text-uppercase">
                                              {{(wallet.option.offers?.length || 0 )+ (wallet.option.commonOffers?.length || 0) }} {{((wallet.option.offers?.length || 0) + (wallet.option.commonOffers?.length || 0)) === 1 ? 'Offer' : 'Offers'}}</small>
                                      </ng-container>
                                  </p>
                                  <p class="f-w-n" *ngIf="wallet.option.linked; else tagLine">Balance <span class="f-w-b">₹{{wallet.option.balance}}</span></p>
                                  <ng-template #tagLine>
                                      <p class="f-w-n">{{PG_WISE_JUSPAY_PAY_LOAD[wallet.option.value].desc}}</p>
                                  </ng-template>
                              </ion-label>
                          </div>
                          <div class="payment-details padding-horizontal-medium padding-top-extra-medium active-details">
                              <div *ngIf="wallet.option.offers?.length || wallet.option.commonOffers?.length" class="payment-list row-medium other-payment-offers bg-unset">
                                  <mbs-payment-offer-listing [showLabel]="false" [offerList]="wallet.option.offers || []" [commonOffers] = 'wallet.option.commonOffers || []'></mbs-payment-offer-listing>
                              </div>
                              <ion-button (click)="initiatePayment(wallet.option)" size="large" shape="round" mode="md" expand="block" class=" margin-bottom-extra-medium mw-large margin-top-medium t-t-n "> Pay ₹ {{shoppingCreditAmount}}</ion-button>
                          </div>
                      </div>
                  </ion-item>
              </div>
          </ng-container>
          <ng-container *ngSwitchCase="paymentMethod.CREDIT_CARD">
              <div class="ion-no-padding payment-option-list" *ngIf="masterDataService.paymentOptionSection.CREDIT_CARD.options.length">
                  <ion-list-header class="sub-heading f-w-b padding-top-medium theme-light">Credit Card
                  </ion-list-header>
                  <ion-item *ngIf="masterDataService.paymentOptionSection.CREDIT_CARD.offers.length || masterDataService.paymentOptionSection.CREDIT_CARD.commonOffers.length" color="none" lines="none" class="overflow-hidden overflow-none padding-vertical-small other-offers">
                      <div class="payment-details">
                          <div class="payment-list other-payment-offers">
                              <mbs-payment-offer-listing [showLabel]="false" [offerList]="masterDataService.paymentOptionSection.CREDIT_CARD.offers" [commonOffers] = "masterDataService.paymentOptionSection.CREDIT_CARD.commonOffers"></mbs-payment-offer-listing>
                          </div>
                      </div>
                  </ion-item>
                  <ion-item color="none" lines="none" class="padding-left-medium br-bottom padding-vertical-extra-medium" (click)="initiatePayment(masterDataService.paymentOptionSection.CREDIT_CARD.options[0].option)">
                      <img alt="credit card icon" src="../../../../assets/img/credit-card.svg" slot="start" class="pm-w">
                      <ion-label>
                          <p class="p-title f-w-m">{{masterDataService.paymentOptionSection.CREDIT_CARD.options[0].option.name}} <small *ngIf="(masterDataService.paymentOptionSection.CREDIT_CARD.offers.length + masterDataService.paymentOptionSection.CREDIT_CARD.commonOffers.length) >= 1" class="ion-text-uppercase">
                          {{masterDataService.paymentOptionSection.CREDIT_CARD.offers.length + masterDataService.paymentOptionSection.CREDIT_CARD.commonOffers.length}} 
                          {{masterDataService.paymentOptionSection.CREDIT_CARD.offers.length + masterDataService.paymentOptionSection.CREDIT_CARD.commonOffers.length === 1 ? 'Offer' : 'Offers'}}</small> </p>
                          <p class="f-w-n">Add Another Credit Card</p>
                      </ion-label>
                      <ion-buttons>
                          <ion-button fill="clear" slot="end" class="ion-no-padding tc btn-tirgger">
                              <ion-icon class="icon-arrow-right icons-property" color="primary" slot="icon-only"></ion-icon>
                          </ion-button>
                      </ion-buttons>
                  </ion-item>
              </div>
          </ng-container>
          <ng-container *ngSwitchCase="paymentMethod.DEBIT_CARD">
              <div class="ion-no-padding payment-option-list" *ngIf="masterDataService.paymentOptionSection.DEBIT_CARD.options.length">
                  <ion-list-header class="sub-heading f-w-b padding-top-medium theme-light">Debit Card
                  </ion-list-header>
                  <ion-item *ngIf="masterDataService.paymentOptionSection.DEBIT_CARD.offers.length || masterDataService.paymentOptionSection.DEBIT_CARD.commonOffers.length" color="none" lines="none" class="overflow-hidden overflow-none padding-vertical-small other-offers">
                      <div class="payment-details">
                          <div class="payment-list other-payment-offers">
                              <mbs-payment-offer-listing [showLabel]="false" [offerList]="masterDataService.paymentOptionSection.DEBIT_CARD.offers" [commonOffers] = "masterDataService.paymentOptionSection.DEBIT_CARD.commonOffers"></mbs-payment-offer-listing>
                          </div>
                      </div>
                  </ion-item>
                  <ion-item color="none" lines="none" class="padding-left-medium padding-vertical-extra-medium" (click)="initiatePayment(masterDataService.paymentOptionSection.DEBIT_CARD.options[0].option)">
                      <img alt="debit card icon" src="../../../../assets/img/debit-card.svg" slot="start" class="pm-w" />
                      <ion-label>
                          <p class="p-title f-w-m">{{masterDataService.paymentOptionSection.DEBIT_CARD.options[0].option.name}} <small *ngIf="masterDataService.paymentOptionSection.DEBIT_CARD.offers.length +  masterDataService.paymentOptionSection.DEBIT_CARD.commonOffers.length >= 1" class="ion-text-uppercase">
                          {{masterDataService.paymentOptionSection.DEBIT_CARD.offers.length + masterDataService.paymentOptionSection.DEBIT_CARD.commonOffers.length}} 
                          {{masterDataService.paymentOptionSection.DEBIT_CARD.offers.length + masterDataService.paymentOptionSection.DEBIT_CARD.commonOffers.length === 1 ? 'Offer' : 'Offers'}}</small></p>
                          <p class="f-w-n">Add Another Debit Card </p>
                      </ion-label>
                      <ion-buttons>
                          <ion-button fill="clear" slot="end" class="ion-no-padding tc btn-tirgger">
                              <ion-icon class="icon-arrow-right icons-property" color="primary" slot="icon-only"></ion-icon>
                          </ion-button>
                      </ion-buttons>
                  </ion-item>
              </div>
          </ng-container>
          <ng-container *ngSwitchCase="paymentMethod.FOOD_WALLET">
              <div class="ion-no-padding payment-option-list" *ngIf="masterDataService.paymentOptionSection.SODEXO.options.length">
                  <ion-list-header class="sub-heading f-w-b padding-top-medium theme-light">Food Wallets
                  </ion-list-header>
                  <ion-item *ngIf="masterDataService.paymentOptionSection.SODEXO.offers.length || masterDataService.paymentOptionSection.SODEXO.commonOffers.length" color="none" lines="none" class="overflow-hidden overflow-none padding-vertical-small other-offers">
                      <div class="payment-details">
                          <div class="payment-list other-payment-offers">
                              <mbs-payment-offer-listing [showLabel]="false" [offerList]="masterDataService.paymentOptionSection.SODEXO.offers" [commonOffers]="masterDataService.paymentOptionSection.SODEXO.commonOffers"></mbs-payment-offer-listing>
                          </div>
                      </div>
                  </ion-item>
                  <ion-item color="none" lines="none" class="padding-left-medium br-bottom padding-vertical-extra-medium" (click)="initiatePayment(masterDataService.paymentOptionSection.SODEXO.options[0].option)">
                      <img alt="sodexo icon" src="../../../../assets/img/sodexo.svg" slot="start" class="pm-w" />
                      <ion-label>
                          <p class="p-title f-w-m">{{masterDataService.paymentOptionSection.SODEXO.options[0].option.name}}<small class="ion-text-uppercase" *ngIf="masterDataService.paymentOptionSection.SODEXO.options[0].option?.offers?.length + masterDataService.paymentOptionSection.SODEXO.options[0].option?.commonOffers?.length >=1 ">
                              {{masterDataService.paymentOptionSection.SODEXO.offers.length + masterDataService.paymentOptionSection.SODEXO.commonOffers.length}}
                              {{masterDataService.paymentOptionSection.SODEXO.offers.length + masterDataService.paymentOptionSection.SODEXO.commonOffers.length === 1 ? 'Offer' : 'Offers'}}</small></p>
                          <p class="f-w-n">Top-Up food wallet balance</p>
                      </ion-label>
                      <ion-buttons>
                          <ion-button fill="clear" slot="end" class="ion-no-padding tc btn-tirgger">
                              <ion-icon class="icon-arrow-right icons-property" color="primary" slot="icon-only"></ion-icon>
                          </ion-button>
                      </ion-buttons>
                  </ion-item>
              </div>
            <div>
                <ion-item class="food-wallet-msg" lines="none">
                    <ion-label class="ion-text-wrap">
                        <ion-text class="padding-left-medium text-size-sm d-block">
                            Food Wallet can only be used on food & edibles and not for non-food items
                        </ion-text>
                    </ion-label>
                    <ion-button size="small" fill="clear" class="text-size-sm padding-left-small padding-right-extra-medium no-ripple-effect view-all" color="primary" (click)="shoppingCreditsServices.openFW_Guidelines(eventSourcePaymentPage)">
                        Know more <ion-icon class="icons-property icon-arrow-right" color="primary"></ion-icon>
                    </ion-button>
                </ion-item>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="paymentMethod.NET_BANKING">
              <div class="ion-no-padding payment-option-list" *ngIf="masterDataService.paymentOptionSection.netBanking.options.length">
                  <ion-list-header class="sub-heading f-w-b padding-top-medium theme-light">Net Banking
                  </ion-list-header>
                  <ion-item *ngIf="masterDataService.paymentOptionSection.netBanking.offers.length || masterDataService.paymentOptionSection.netBanking.commonOffers.length" color="none" lines="none" class="overflow-hidden overflow-none padding-vertical-small other-offers">
                      <div class="payment-details">
                          <div class="payment-list other-payment-offers">
                              <mbs-payment-offer-listing [showLabel]="false" [offerList]="masterDataService.paymentOptionSection.netBanking.offers" [commonOffers]="masterDataService.paymentOptionSection.netBanking.commonOffers"></mbs-payment-offer-listing>
                          </div>
                      </div>
                  </ion-item>
                  <ion-item color="none" lines="none" class="padding-left-medium br-bottom padding-vertical-extra-medium" (click)="initiatePayment(masterDataService.paymentOptionSection.netBanking.options[0].option)">
                      <img alt="other banks icon" src="../../../../assets/img/other-banks.svg" slot="start" class="pm-w" />
                      <ion-label>
                          <p class="p-title f-w-m">{{masterDataService.paymentOptionSection.netBanking.options[0].option.name}}<small *ngIf="masterDataService.paymentOptionSection.netBanking.offers.length + masterDataService.paymentOptionSection.netBanking.commonOffers.length >= 1" class="ion-text-uppercase">
                          {{masterDataService.paymentOptionSection.netBanking.offers.length + masterDataService.paymentOptionSection.netBanking.commonOffers.length}} 
                          {{masterDataService.paymentOptionSection.netBanking.offers.length + masterDataService.paymentOptionSection.netBanking.commonOffers.length === 1 ? 'Offer' : 'Offers'}}</small></p>
                          <p class="f-w-n">Use Bank Website portal for Payment</p>
                      </ion-label>
                      <ion-buttons>
                          <ion-button fill="clear" slot="end" class="ion-no-padding tc btn-tirgger">
                              <ion-icon class="icon-arrow-right icons-property" color="primary" slot="icon-only"></ion-icon>
                          </ion-button>
                      </ion-buttons>
                  </ion-item>
              </div>
          </ng-container>
          <ng-container *ngSwitchCase="paymentMethod.UPI">
              <div class="ion-no-padding payment-option-list" *ngIf="masterDataService.paymentOptionSection.upi.options.intent.length || masterDataService.paymentOptionSection.upi.options.collect.length">
                  <ion-list-header class="sub-heading f-w-b padding-top-medium theme-light">UPI</ion-list-header>
                  <ion-item *ngIf="((dataService.currentPlatform === currentPlatform.IOS || dataService.currentPlatform === currentPlatform.WEB) && (masterDataService.paymentOptionSection.upi.offers.length || masterDataService.paymentOptionSection.upi.commonOffers.length)) || (dataService.currentPlatform === currentPlatform.ANDROID && (masterDataService.paymentConfig.showOffers || masterDataService.paymentConfig.showCommonOffers))" color="none" lines="none" class="overflow-hidden overflow-none padding-vertical-small other-offers">
                      <div class="payment-details">
                          <div class="payment-list other-payment-offers">
                              <mbs-payment-offer-listing [showLabel]="false" [offerList]="masterDataService.paymentOptionSection.upi.offers" [commonOffers] = "masterDataService.paymentOptionSection.upi.commonOffers"></mbs-payment-offer-listing>
                          </div>
                      </div>
                  </ion-item>
                  <ion-item color="none" lines="none" *ngIf="((dataService.currentPlatform === currentPlatform.IOS || dataService.currentPlatform === currentPlatform.WEB) && (masterDataService.paymentOptionSection.upi.options.intent.length)) || (dataService.currentPlatform === currentPlatform.ANDROID && masterDataService.paymentConfig.showUpiOptions)" class="overflow-hidden overflow-none padding-vertical-extra-medium">
                      <div class="payment-details">
                          <div class="payment-list">
                              <ion-list class="ion-no-padding" lines="none">
                                  <ion-item class="ion-no-padding offer-payment" mode="md">
                                      <div class="scroll" scroll-x="true">
                                          <ion-card class="ion-card-image ion-no-margin ion-text-center horizontal-upi-payment br-0" *ngFor="let option of masterDataService.paymentOptionSection.upi.options.intent" (click)="initiatePayment(option.option)">
                                            <div class="w-100" *ngIf="option.option.isAllowed">
                                              <img [alt]="option.option.name" [src]="option.icon" />
                                              <ion-card-title class="f-w-n"> {{option.option.name}}</ion-card-title>
                                            </div>
                                    </ion-card>
                                      </div>
                                  </ion-item>
                              </ion-list>
                          </div>
                      </div>
                  </ion-item>
                  <ion-item color="none" *ngIf="masterDataService.paymentOptionSection.upi.options.collect.length" lines="none" class="padding-left-medium br-bottom padding-vertical-extra-medium" (click)="initiatePayment(masterDataService.paymentOptionSection.upi.options.collect[0].option)">
                      <img alt="UPI icon" src="../../../../assets/img/upi-icon.svg" slot="start" class="pm-w">
                      <ion-label>
                          <p class="p-title f-w-m">UPI 
                          </p>
                          <p class="f-w-n">Use UPI Id’s for online Payment</p>
                      </ion-label>
                      <ion-buttons>
                          <ion-button fill="clear" slot="end" class="ion-no-padding tc btn-tirgger">
                              <ion-icon slot="icon-only" class="icon-arrow-right icons-property" color="primary"></ion-icon>
                          </ion-button>
                      </ion-buttons>
                  </ion-item>
              </div>
          </ng-container>
          <ng-container *ngSwitchCase="paymentMethod.CRED">
              <div *ngIf="masterDataService.paymentOptionSection.cred.options.length" class="ion-no-padding payment-option-list">
                  <ion-list-header class="sub-heading f-w-b padding-top-medium theme-light">Cred</ion-list-header>
                  <ion-item (click)="activePayment = masterDataService.paymentOptionSection.cred.options[0].option.value" [ngClass]="{'active-card-details': activePayment === masterDataService.paymentOptionSection.cred.options[0].option.value}" color="none" lines="none" class="br-bottom padding-vertical-extra-medium">
                      <div class="w-100">
                          <div class="d-flex padding-horizontal-medium ion-align-items-center">
                              <img alt="Cred bank icon" src="../../../../assets/img/cred-bank.png" slot="start" class="pm-w" />
                              <ion-label class="ion-no-margin">
                                  <p class="p-title f-w-m">{{masterDataService.paymentOptionSection.cred.options[0].option.name}}<small *ngIf="masterDataService.paymentOptionSection.cred.offers.length || masterDataService.paymentOptionSection.cred.commonOffers.length"class="ion-text-uppercase">
                                  {{masterDataService.paymentOptionSection.cred.offers.length + masterDataService.paymentOptionSection.cred.commonOffers.length}} {{masterDataService.paymentOptionSection.cred.offers.length + masterDataService.paymentOptionSection.cred.commonOffers.length  === 1 ? 'Offer' : 'Offers'}}</small>
                                  </p>
                              </ion-label>
                          </div>
                          <div class="payment-details padding-horizontal-medium padding-top-extra-medium active-details overflow-hidden overflow-none padding-vertical-extra-medium">
                              <div *ngIf="masterDataService.paymentOptionSection.cred.offers.length" class="payment-list row-medium other-payment-offers bg-unset">
                                  <mbs-payment-offer-listing [showLabel]="false" [offerList]="masterDataService.paymentOptionSection.cred.offers" [commonOffers] = "masterDataService.paymentOptionSection.cred.commonOffers"></mbs-payment-offer-listing>
                              </div>
                              <ion-button (click)="initiatePayment(masterDataService.paymentOptionSection.cred.options[0].option)" size="large" shape="round" mode="md" expand="block" class=" margin-bottom-extra-medium mw-large margin-top-medium t-t-n "> Pay ₹ {{shoppingCreditAmount}}</ion-button>
                          </div>
                      </div>
                  </ion-item>
              </div>
          </ng-container>
          <ng-container *ngSwitchCase="paymentMethod.PAY_LATER">
              <div class="ion-no-padding payment-option-list" *ngIf="masterDataService.paymentOptionSection.payLater.options.length">
                  <ion-list-header class="sub-heading f-w-b padding-top-medium theme-light">Pay Later
                  </ion-list-header>
                  <ion-item color="none" lines="none" *ngIf="masterDataService.paymentOptionSection.payLater.offers.length || masterDataService.paymentOptionSection.payLater.commonOffers.length" class="overflow-hidden overflow-none padding-vertical-extra-medium">
                      <div class="payment-details">
                          <div class="payment-list other-payment-offers">
                              <mbs-payment-offer-listing [showLabel]="false" [offerList]="masterDataService.paymentOptionSection.payLater.offers" [commonOffers]="masterDataService.paymentOptionSection.payLater.commonOffers"></mbs-payment-offer-listing>
                          </div>
                      </div>
                  </ion-item>
                  <ion-item color="none" lines="none" class="padding-left-medium br-bottom padding-vertical-extra-medium" (click)="initiatePayment(masterDataService.paymentOptionSection.payLater.options[0].option)">
                      <img alt="lazypay icon" src="../../../../assets/img/lazypay.svg" slot="start" class="pm-w" />
                      <ion-label>
                          <ion-label>
                              <p class="p-title f-w-m">{{masterDataService.paymentOptionSection.payLater.options[0].option.name}}<small *ngIf="masterDataService.paymentOptionSection.payLater.offers.length + masterDataService.paymentOptionSection.payLater.commonOffers.length >= 1" class="ion-text-uppercase">
                              {{masterDataService.paymentOptionSection.payLater.offers.length + masterDataService.paymentOptionSection.payLater.commonOffers.length }} 
                              {{masterDataService.paymentOptionSection.payLater.offers.length + masterDataService.paymentOptionSection.payLater.commonOffers.length === 1 ? 'Offer' : 'Offers'}}</small></p>
                          </ion-label>
                      </ion-label>
                      <ion-buttons>
                          <ion-button fill="clear" slot="end" class="ion-no-padding tc btn-tirgger">
                              <ion-icon slot="icon-only" class="icon-arrow-right icons-property" color="primary"></ion-icon>
                          </ion-button>
                      </ion-buttons>
                  </ion-item>
              </div>
          </ng-container>
          <ng-container *ngSwitchCase="paymentMethod.REDEEM_REWARDS">
            <div class="ion-no-padding payment-option-list pay-reward" *ngIf="masterDataService.paymentOptionSection.redeemRewards?.options?.length && masterDataService.paymentOptionSection.redeemRewards?.visible">
                <ion-list-header class="sub-heading f-w-b padding-top-medium theme-light">Redeem Rewards</ion-list-header>
                <ion-item (click)="activePayment = masterDataService.paymentOptionSection.redeemRewards.options[0].option.value; runChangeDetection()" [ngClass]="{'active-card-details': activePayment === masterDataService.paymentOptionSection.redeemRewards.options[0].option.value}" color="none" lines="none" class="br-bottom padding-vertical-extra-medium">
                    <div class="w-100">
                        <div class="d-flex padding-horizontal-base ion-align-items-center">
                            <img alt="twid pay icon" src="../../../../assets/img/twid.svg" slot="start" class="pm-w" />
                            <ion-label>
                                <p class="p-title f-w-m"> {{masterDataService.paymentOptionSection.redeemRewards.options[0].option.name}}
                                    <ng-container *ngIf="masterDataService.paymentOptionSection.redeemRewards.offers.length || masterDataService.paymentOptionSection.redeemRewards.commonOffers.length">
                                        <small class="ion-text-uppercase">
                                            {{masterDataService.paymentOptionSection.redeemRewards.offers.length + masterDataService.paymentOptionSection.redeemRewards.commonOffers.length }} {{masterDataService.paymentOptionSection.redeemRewards.offers.length + masterDataService.paymentOptionSection.redeemRewards.commonOffers.length === 1 ? 'Offer' : 'Offers'}}</small>
                                    </ng-container>
                                </p>
                                <p *ngIf="PG_WISE_JUSPAY_PAY_LOAD[masterDataService.paymentOptionSection.redeemRewards.options[0].option.value].desc" class="f-w-n margin-bottom-0">
                                    Redeem <span class="f-w-b">{{PG_WISE_JUSPAY_PAY_LOAD[masterDataService.paymentOptionSection.redeemRewards.options[0].option.value].desc}}</span> & pay less
                                </p>
                            </ion-label>
                        </div>
                        <div *ngIf="activePayment !== masterDataService.paymentOptionSection.redeemRewards.options[0].option.value">
                            <div class="banner-reward d-flex padding-horizontal-base">
                                <mbpc-twid-logo></mbpc-twid-logo>
                            </div>
                        </div>
                        <div class="payment-details padding-horizontal-base padding-top-base active-details">
                            <div *ngIf="masterDataService.paymentOptionSection.redeemRewards.offers.length" class="payment-list row-medium other-payment-offers bg-unset twid-offer">
                                <mbs-payment-offer-listing [showLabel]="false" [offerList]="masterDataService.paymentOptionSection.redeemRewards.offers" [commonOffers]="masterDataService.paymentOptionSection.redeemRewards.commonOffers"></mbs-payment-offer-listing>
                            </div>
                            <div *ngIf="activePayment === masterDataService.paymentOptionSection.redeemRewards.options[0].option.value">
                                <div class="twid-banner d-flex margin-top-extra-medium">
                                    <mbpc-twid-logo></mbpc-twid-logo>
                                </div>
                            </div>
                            <ion-button (click)="initiatePayment(masterDataService.paymentOptionSection.redeemRewards.options[0].option)" size="large" shape="round" mode="md" expand="block" class="margin-bottom-base mw-large margin-top-base t-t-n"> Pay ₹ {{shoppingCreditAmount}}</ion-button>
                        </div>
                    </div>
                </ion-item>
            </div>
        </ng-container>
      </div>
  </ng-container>
</ion-content>
