//#region Imports

//#region Angular Imports
import { Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { HTTPResponse } from '@ionic-native/http';
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { ProductListingService } from '@Globals/providers/product-listing.service'; 
import { EventsService } from '@Globals/providers/events.service';
//#endregion Providers Imports

//#region Other Imports
import { STORAGE_KEYS, EVENT_NAMES } from '@Globals/constants/listing/constants';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-green-bag-removal',
  templateUrl: './green-bag-removal.component.html',
  styleUrls: ['./green-bag-removal.component.scss'],
})
export class GreenBagRemovalComponent  {
  constructor(private _productListingService: ProductListingService, private _modalCtrl: ModalController,private eventService: EventsService) {
    this.eventService.trackEvents(EVENT_NAMES.RECYCLE_BAG_SHOW_BASKET_REMOVAL_BOTTOM_SHEET,{});
   }

  removeBasket(bagRemoval:boolean) {
    if (bagRemoval) {
      this._productListingService.basketStatus({ action: 0 }).subscribe((res: HTTPResponse) => {
        if (res && (res.status || res.data.status)) {
          localStorage.removeItem(STORAGE_KEYS.RECYCLE_BAG_DATE);
          this._modalCtrl.dismiss(bagRemoval);
          this.eventService.trackEvents(EVENT_NAMES.RECYCLE_BAG_BASKET_REMOVAL_CONFIRMED,{});
        }
      });
    } else {
      this._modalCtrl.dismiss(bagRemoval);
    }
  }

}
