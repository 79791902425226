<ion-list class="ion-list-collection ion-no-padding swiper-no-swiping" lines="none" *ngIf="recommendation?.data?.length > 0"
  [ngClass]="{ 'has-no-cuf-off-time': hasNoCutoffTime, 'fixed-list-header': recommendationType === recommendationTypes.Related }">
  <ion-list-header class="ion-list-header" *ngIf="recommendationType === recommendationTypes.Recommendation
    || recommendationType === recommendationTypes.Related
    || recommendationType === recommendationTypes.Home_Recommendation
    || recommendationType === recommendationTypes.PDP_See_Similar 
    || isCancelledProduct">
    <ion-row class="ion-justify-content-between ion-align-items-center w-100">
      {{ recommendation?.title }}
      <ion-button *ngIf="recommendationType === recommendationTypes.Recommendation || recommendationType === recommendationTypes.Home_Recommendation"
        class="no-ripple-effect ion-no-padding view-all" mode="md" fill="clear" (click)="showAll()" size="small">
          View all <ion-icon class="icons-property icon-arrow-right icon-dark"></ion-icon> 
      </ion-button>
    </ion-row>
  </ion-list-header>
  <ion-item class="ion-no-padding">
    <div class="scroll" *ngIf="recommendation.data.length > 0" scroll-x="true">
      <div class="ion-item-inner">
        <div class="card-flex-view">
        <ng-container *ngIf="recommendationType === recommendationTypes.Home_Recommendation; else oldFormat;">
          <mb-pl-single-brand 
            [brandData]="{products: recommendation.data,isRecommendation: true }" 
            [eventSource]="eventSourceHome" 
            [eventData]="eventData" 
            [itemType]="itemType"
            [showNew]="false" 
            [showViewAll]="false">

          </mb-pl-single-brand> 
        </ng-container>
        <ng-template #oldFormat>
          <ng-container *ngFor="let product of recommendation.data.slice(0,15); let productIndex = index">
            <ion-card class="ion-card-custom fixed-size pdt-card to-flex">
              <div class="img-holder" (click)="handleItemClick(product, productIndex)">
                <div class="img-holder-inner">
                  <app-img-on-demand 
                    [actualImgSrc]="plmService.productImageUrl + product?.img" 
                    [defaultImgSrc]="'assets/img/default-product.svg'" 
                    alt_image="img_product_{{product?.img}}"
                    [imageName]="product?.img" 
                    [fileStorageDirectoryName]="plmService.fileStorageDirectories.PRODUCT">
                  </app-img-on-demand>
                </div>
              </div>
              <ion-card-header (click)="handleItemClick(product, productIndex)">
                <ion-card-title text-ellipsis-ml>{{ product?.nm }}</ion-card-title>
              </ion-card-header>
              <ion-card-content (click)="handleItemClick(product, productIndex)">
                <div class="description pl-card">
                  <div (click)="openProductsVariantModal(product, productIndex); $event.stopPropagation()" class="variants-wrap" [ngClass]="{'no-variants': !(product?._newVariantProducts?.length)}">
                    <div><span class="text-holder">{{product.wgt}}</span></div>
                    <ion-icon *ngIf="product?._newVariantProducts?.length" class="icons-property icon-down-arrow"></ion-icon>
                  </div>
                 <div class="highlight-qty">
                    <p class="qty" *ngIf="(product.delivery_types_slot && product.delivery_types_slot[0] === deliveryType.frozen && plmService.isFrozenCheckEnabled) || (product.ct && product.ct !== '0')">
                    <span>
                      <span *ngIf="product.delivery_types_slot && product.delivery_types_slot[0] === deliveryType.frozen && plmService.isFrozenCheckEnabled; else cutOff">
                      <span class="highlight"> {{plmService.deliverySlotInfo}} </span>
                    </span>
                    <ng-template #cutOff>
                      <span *ngIf="product.ct && product.ct !== '0'">
                        <span *ngIf="currentHoursAndMinutes <= product.ct; else oneDayDelivery">{{ product?.ct_text }} Cutoff</span>
                          <ng-template #oneDayDelivery>
                            <span *ngIf="product.schedule" class="highlight">+1 Day Delivery</span>
                          </ng-template>
                        </span>
                      </ng-template>
                    </span>
                    </p>
                 <div class="price-detail">
                    <p>
                      <span class="sale-price icon-inr">{{ product?.sp }}</span>
                      <ng-container *ngIf="product.mrp - product.sp > 0">
                        <span class="actual-price icon-inr">{{ product?.mrp }}</span>
                        <span class="discount icon-inr">{{ product?.mrp - product?.sp | number: '1.2-2' }} OFF</span>
                      </ng-container>
                    </p>
                 </div>
                 </div>
                </div>
              </ion-card-content>
              <div class="action recurring" *ngIf="recommendationType === recommendationTypes.Recurring_Suggestions; else counterSection">
                <ion-button [disabled]="!masterDataService.isValidAddress" (click)="setToCalendar(product,productIndex)" mode="md" color="primary" fill="outline" size="small" expand="block" class="t-t-n f-w-n l-s-0 f-f-p">
                  Subscribe Now
                </ion-button>
              </div>
              <ng-template #counterSection>
                <div class="action has-counter-btn">
                  <ion-button *ngIf="product.oos || product.forcedOos || (product.ct && product.ct !== '0' && currentHoursAndMinutes > product.ct && !product.schedule); else showAdd" 
                    mode="md" fill="outline" size="small" color="primary" class="full-width" (click)="triggerRequestProduct(product)">
                    Request
                  </ion-button>
                  <ng-template #showAdd>
                    <mb-product-counter [isPlusOneDayDelivery]="product.delivery_types_slot && product.delivery_types_slot[0] ===
                deliveryType.frozen && product.ct && product.ct !== '0' && currentHoursAndMinutes > product.ct" 
                        [productId]="product.product_id || product.id"
                        [counter]="authService.userData?.tomorrowBasket?.[product.product_id || product.id]" 
                        (counterChange)="triggerCounterChange($event, product, productIndex)">
                    </mb-product-counter>
                  </ng-template>
                </div>
              </ng-template>
            </ion-card>
          </ng-container>
          <ion-card
            (click)="viewAllCollection()"
            *ngIf="displayType === collectionDisplayType.RecommendationWidgetProductListingBg && recommendation.data.length >= 15"
            class="ion-card-custom fixed-size pdt-card to-flex">
            <div class="view-all">
              <ion-button class="no-ripple-effect ion-text-uppercase ion-no-padding height-auto f-w-n" fill="clear" color="dark" size="small">
                View all
                <ion-icon class="icon-arrow-right" color="primary"></ion-icon>
              </ion-button>
            </div>
          </ion-card>
        </ng-template>
        <ion-card *ngIf="recommendationType === recommendationTypes.See_Similar" class="ion-card-custom fixed-size pdt-card to-flex req-original-item">
          <div class="img-holder">
            <img alt="request for original item" width="24" [src]="'assets/img/icon-original-item.png'" />
          </div>
          <ion-card-content>
            <div class="description">
              <p class="what-wants">
                <span class="icon-quote-opening"></span>
                <span class="text">The heart wants what it wants..</span>
                <span class="icon-quote-closing"></span>
              </p>
              <p class="place-req">Place request for the original item</p>
            </div>
          </ion-card-content>
          <div class="action has-counter-btn">
            <ion-button mode="md" fill="outline" (click)="triggerRequestProduct(recommendation.product, true)" size="small" color="primary" class="full-width">
              Request
            </ion-button>
          </div>
        </ion-card>
      </div>
      </div>
    </div>
  </ion-item>
</ion-list>