//#region Imports

//#region Angular Imports
import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
//#endregion Angular Imports

//#region Ionic Imports
import { Platform } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from '@Globals/providers/master-data.service';
import { DataService } from '@Globals/providers/data.service';
import { StorageService } from 'src/app/shared/providers/storage/storage.service';
//#endregion Providers Imports

//#region Other Imports
import { CACHED_IMAGE_URL, IMAGE_TYPE_METADATA, IOS } from '@Globals/constants/listing/constants';
import { ImageOnDemandType } from '@Globals/constants/listing/enums';

import { Utilities } from '@Globals/classes/utilities';

import { environment } from '@Env/environment';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-img-on-demand',
  templateUrl: './img-on-demand.component.html',
  styleUrls: [ './img-on-demand.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgOnDemandComponent implements OnChanges {
  @Input() actualImgSrc: string;
  @Input() imageName: string;
  @Input() defaultImgSrc: string;
  @Input() fileStorageDirectoryName: string;
  @Input() useImageOnDemand = true;
  @Input() componentType: ImageOnDemandType;
  @Input() productId: number;
  @Input() showDefaultImage = true;
  @Input() showDefaultShimmer = false;
  @Input() useGumletSrc = false;
  @Input() alt_image = "";
  isActualImgLoaded = false;
  imageSrc: any;
  isFallbackImageLoadedOnce = false;
  subscription: Subscription;
  hasServiceWorkerSupport = false;
  constructor (
    private storageService: StorageService,
    private masterDataService: MasterDataService,
    private dataService: DataService,
    private file: File,
    private platform: Platform,
    private domSanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef // being used with scope inside checkIfImageExistInStorageElseDownload
  ) {
    if('serviceWorker' in navigator) {
      this.hasServiceWorkerSupport = true;
    }
  }

  ngOnChanges() {
    if (this.componentType) {
      this.actualImgSrc = Utilities.returnImageWebPSrc(this.imageName, IMAGE_TYPE_METADATA[ this.componentType ].width, IMAGE_TYPE_METADATA[ this.componentType ].height);
      this.imageName = IMAGE_TYPE_METADATA[ this.componentType ].prefix + this.imageName + IMAGE_TYPE_METADATA[ this.componentType ].postfix;
    }
    if (this.hasServiceWorkerSupport || this.useGumletSrc) {
      this.imageSrc = this.actualImgSrc;
      this.isActualImgLoaded = true;
    } else {
      if (Utilities.isApp() && this.storageService.imageStorage.hasOwnProperty(this.imageName)) {
        if (this.platform.is(IOS) && !this.useImageOnDemand) {
          let src = (window as any).Ionic.WebView.convertFileSrc(`${this.file.dataDirectory}${this.fileStorageDirectoryName}/${this.imageName}`);
          this.imageSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(src);
        } else {
          this.imageSrc = this.storageService.imageStorage[ this.imageName ];
        }
        this.isActualImgLoaded = true;
      } else {
        if (this.imageName && this.fileStorageDirectoryName) {
          if (this.showDefaultImage && Utilities.isApp()) {
            this.imageSrc = this.defaultImgSrc;
          } else {
            this.imageSrc = this.actualImgSrc;
          }
        }
      }
    }
  }
  onError(event) {
    if (this.imageSrc.includes(CACHED_IMAGE_URL) && Utilities.isApp()) {
      this.imageSrc = this.actualImgSrc;
      if(!this.hasServiceWorkerSupport && !this.useGumletSrc) {
          this.storageService.downloadImage(this,false);
        } else {
          this.changeDetectorRef && this.changeDetectorRef.detectChanges();
        }
    } else {
      this.isActualImgLoaded = false;
      !this.showDefaultImage && (this.showDefaultImage = true);
      if (!this.isFallbackImageLoadedOnce && this.componentType) {
        this.isFallbackImageLoadedOnce = true;
        const imageNameArray =
          this.imageName.replace(IMAGE_TYPE_METADATA[ this.componentType ].prefix, '').replace(IMAGE_TYPE_METADATA[ this.componentType ].postfix, '').split('.');
        this.imageName = imageNameArray[ 0 ] + '_fallback.' + imageNameArray[ 1 ];
        this.actualImgSrc = environment.productPath + this.imageName;
        if(this.hasServiceWorkerSupport) {
          if (this.showDefaultImage) {
            this.imageSrc = this.defaultImgSrc;
          } else {
            this.imageSrc = this.actualImgSrc;
          }
        } else if (this.imageName && this.fileStorageDirectoryName) {
          if (this.storageService.directoriesCreated[ this.fileStorageDirectoryName ] && this.showDefaultImage) {
            this.imageSrc = this.defaultImgSrc;
          } else {
            this.imageSrc = this.actualImgSrc;
          }
        }
      } else {
        event.target.style.display = 'none';
      }
    }
  }
  onLoad(event) {
      if (this.imageSrc === this.defaultImgSrc && Utilities.isApp()) {
        if (!this.hasServiceWorkerSupport && !this.useGumletSrc) {
          this.storageService.downloadImage(this,true);
        } else {
          this.changeDetectorRef && this.changeDetectorRef.detectChanges();
        }
      }
      else {
        (!this.dataService.isFirstImageLoaded) &&
          (this.dataService.isFirstImageLoaded = true);
        event.target.style.display = 'block';
        this.isActualImgLoaded = true;
      }
    }
}