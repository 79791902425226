//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { LottieModule } from 'ngx-lottie';
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
import { FooterStripModule } from '@Shared/footer-strip/footer-strip.module';
//#endregion Module Imports

//#region Component Imports
import { BottomNavComponent } from '@MbUi/bottom-navs/one/bottom-nav.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FooterStripModule,
    LottieModule
  ],
  declarations: [BottomNavComponent],
  entryComponents: [BottomNavComponent],
  exports: [BottomNavComponent]
})
export class BottomNavModule extends DefaultModule { }
