//#region Imports

//#region Angular Imports
import { Injectable } from "@angular/core";
import { DecimalPipe } from "@angular/common";
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { DateFormatPipe } from "ngx-moment";
import {
  ApolloClientOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from "@Globals/providers/data.service";
import { HttpService } from "@Globals/providers/http.service";
import { AuthService } from "@Globals/providers/auth.service";
import { MasterDataService } from "@Globals/providers/master-data.service";
//#endregion Providers Imports

//#region Other Imports
import { environment } from "@Env/environment";
import { Utilities } from "@Globals/classes/utilities";
import { SocietyType } from "@App/registration/constants/registration-enums";
import { configureClient } from "@mb-lib/apollo-client";
import {
  GET_BASKET_QUERY,
  GET_SUBSCRIPTION_PRODUCT_ORDERS_QUERY,
  PRODUCT_ORDER_FRAGMENT,
  USER_DETAILS_QUERY,
  FUTURE_BASKET_PRODUCT_ORDER_FRAGMENT,
} from "@mb-lib/data-hub";
// import {FUTURE_BASKET_QUERY} from "@mb-widgets/cart";
import { Basket } from "./graph-transformers/basket/src";
import { EVENT_NAMES, PAYMENT_CACHE } from "@App/shared/constants/constants";
import { UserDetails } from "./graph-transformers/user-details/src";
import { ProductSubscriptionOrder } from "./graph-transformers/product-order/src";
import { EventsService } from "@Globals/providers/events.service";
import { EventTrackingPlugins } from "@Globals/constants/listing/enums";
import { EVENT_SOURCES } from "@App/listing/constants/listing-constants";
//#endregion Other Imports

//#endregion Imports

let apolloClientInitiated = false;
let apolloClient;
let trackedProductsMap = {};

export const clearApolloCache = () => {
  if (apolloClient) {
    apolloClient.clearStore();
    apolloClientInitiated = false;
    apolloClient = undefined;
  }
};

@Injectable({ providedIn: "root" })
export class ApolloClientService {
  client;
  cacheOptions;
  constructor(
    public masterDataService: MasterDataService,
    public dataService: DataService,
    public numberPipe: DecimalPipe,
    public amDateFormat: DateFormatPipe,
    public httpService: HttpService,
    public authService: AuthService,
    public eventsService: EventsService
  ) {
    console.log("ApolloCache:", this);
  }

  configureApolloClient() {
    if (!!apolloClientInitiated) {
      this.client = apolloClient;
      return true;
    }
    this.cacheOptions = this.configureCacheOptions();
    const apolloClientOptions = {
      uri: environment.apolloClientUrl,
      cache: new InMemoryCache(this.cacheOptions),
    };
    apolloClient = configureClient(
      apolloClientOptions as unknown as ApolloClientOptions<NormalizedCacheObject>,
      this.authService.getAuthKey.bind(this.authService),
      this.fetchAccessTokenAfterRefresh.bind(this)
    );
    this.client = apolloClient;
    if (this.masterDataService?.masterData?.user) {
      apolloClientInitiated = true;
    }
    return apolloClient;
  }

  getUniqueProducts(existing, incoming, page, limit)  {
    const productsMap = {};
    // Slicing is necessary because the existing data is
    // immutable, and frozen in development.
    const merged = {
        ...existing,
        ...incoming,
    };
    try {
        const mergedProducts = existing?.products ? existing.products.slice(0) : [];
        for (let i = 0; i < mergedProducts.length; i++) {
            productsMap[mergedProducts[i].product.__ref] = 1;
        }
        for (let i = 0; i < incoming.products.length; i++) {
            if (productsMap[incoming.products[i].product.__ref] !== 1) {
                productsMap[incoming.products[i].product.__ref] = 1;
                mergedProducts.push(incoming.products[i]);
            }
        }
        merged.products = mergedProducts;
    } catch (e) {
      console.log('error:', e);
    }
    return merged;
  };
  
  setupApolloCachePolicies() {
    // const detailsCache = this.prepareUserDetailsCache();
    // const addressCache = this.prepareUserAddressCache();
    const basketCache = this.prepareBasketCache();
    // const valuePass = this.prepareValuePassData();
    // const appliedCode = this.prepareAppliedCodeData();
    const segmentIdList = this.preapreSegmentIDs();
    const segmentList = this.prepareSegmentGroups();

    const instance = this;
    const getCategoryProductList = {
      getCategoryProductList: {
        // Don't cache separate results based on
        // any of this field's arguments.
        keyArgs: ["category", "cityId", "appVersion"],

        merge(
          existing,
          incoming,
          {
            args: {
              category,
              page: { page = 1, limit },
            },
          }
        ) {
          instance.trackProductImpressions(incoming.products, category);
          return instance.getUniqueProducts(existing, incoming, page, limit);
        },
      },
    };

    const getBannerProductList = {
      getBannerProductList: {
        // Don't cache separate results based on
        // any of this field's arguments.
        keyArgs: ["banner"],

        merge(
          existing,
          incoming,
          {
              args: {
                  page: { page = 1, limit },
              },
          },
      ) {
        return instance.getUniqueProducts(existing, incoming, page, limit);
      },
    }
  };

    const getProductLaunchAllProducts = {
      getProductLaunchAllProducts: {
        // Don't cache separate results based on
        // any of this field's arguments.
        keyArgs: ["cityId", "hubId"],

        merge(
          existing,
          incoming,
          {
              args: {
                  page: { page = 1, limit },
              },
          },
      ) {
        return instance.getUniqueProducts(existing, incoming, page, limit);
      },
    }
  };

    const getSponsoredProductList = {
      getSponsoredProductList: {
        // Don't cache separate results based on
        // any of this field's arguments.
        keyArgs: ["cityId", "hubId", "listId"],

        merge(
          existing,
          incoming,
          {
              args: {
                  page: { page = 1, limit },
              },
          },
      ) {
        return instance.getUniqueProducts(existing, incoming, page, limit);
      },
    },
   }
    
   const getSubscriptionProductsAndCategories = {
    getSubscriptionProductsAndCategories: {
      // Don't cache separate results based on
      // any of this field's arguments.
      keyArgs: ['subscription', 'hubId', 'productIds'],

      merge(
          existing,
          incoming,
          {
              args: {
                  page: { page = 1, limit },
              },
          },
      ) {
        return instance.getUniqueProducts(existing, incoming, page, limit);
      },
    },
   }
    
   const getSubscriptionSearchProducts = {
    getSubscriptionSearchProducts: {
      // Don't cache separate results based on
      // any of this field's arguments.
      keyArgs: ['searchText', 'cityId'],

      merge(
          existing,
          incoming,
          {
              args: {
                  page: { page = 1, limit },
              },
          },
      ) {
        return instance.getUniqueProducts(existing, incoming, page, limit);
      },
    },
   }

       const getBillBusterProductList = {
         getBillBusterProductList: {
           // Don't cache separate results based on
           // any of this field's arguments.
           keyArgs: ["listId"],
           merge(
             existing,
             incoming,
             {
               args: {
                 page: { page = 1, limit },
               },
             }
           ) {
             return instance.getUniqueProducts(existing, incoming, page, limit);
           },
         },
       };

  const getSearchProduct = {
    getSearchProduct: {
      // Don't cache separate results based on
      // any of this field's arguments.
      keyArgs: ['searchPayload', 'cityId'],
      merge(
        existing,
        incoming,
        {
          args: {
            page: { page = 1, limit },
          },
        },
    ) {
        return instance.getUniqueProducts(existing, incoming, page, limit);
      },
    }
  };

  const getCollectionAllProducts = {
    getCollectionAllProducts: {
      // Don't cache separate results based on
      // any of this field's arguments.
      keyArgs: ['collection'],
      merge(
        existing,
        incoming,
        {
          args: {
            page: { page = 1, limit },
          },
        },
    ) {
        return instance.getUniqueProducts(existing, incoming, page, limit);
      },
    }
  }
  
    
    return {
      //  ...detailsCache,
      // ...addressCache,
      // ...valuePass /* , ...basketCache */,
      ...basketCache,
      // ...appliedCode,
      ...segmentIdList,
      ...segmentList,
      ...getCategoryProductList,
      ...getBannerProductList,
      ...getProductLaunchAllProducts,
      ...getSponsoredProductList,
      ...getSubscriptionProductsAndCategories,
      ...getSubscriptionSearchProducts,
      ...getBillBusterProductList,
      ...getSearchProduct,
      ...getCollectionAllProducts
    };
  }

  categoryMap = {};
  subCategoryMap = {};
  trackedProductsMap = {};
  trackProductImpressions(products, categoryImpl) {
    if (!products || !products.length) {
      return;
    }
    try {
      const categoryId = categoryImpl?.id;
      const subCategoryId = categoryImpl?.subCategoryIds?.[0];

      let subcat = "";
      let categoryName = "";

      if (!this.categoryMap[categoryId]) {
        this.masterDataService.categories.forEach((category) => {
          if (category.id === categoryId) {
            categoryName = category.name;
          }
          category.subcategories.forEach((subcategory) => {
            if (subcategory.id === subCategoryId) {
              subcat = subcategory.name;
            }
          });
        });
        this.categoryMap[categoryId] = categoryName;
        this.subCategoryMap[subCategoryId] = subcat;
      } else {
        categoryName = this.categoryMap[categoryId];
        subcat = this.subCategoryMap[subCategoryId];
      }

      this.trackedProductsMap[subcat] = this.trackedProductsMap[subcat] || {};
      const productIds = [];
      for (let i in products) {
        const productIdSplit = products[i].product?.__ref?.split(":");
        const productId = +(productIdSplit?.[1] || 0);
        if (!this.trackedProductsMap[subcat][productId]) {
          productIds.push(productId);
          this.trackedProductsMap[subcat][productId] = 1;
        }
      }

      const eventArgs = {
        subcategory_name: subcat,
        category_name: categoryName,
        Source: EVENT_SOURCES.CATEGORY,
      };
      eventArgs["products"] = JSON.stringify(productIds);
      this.eventsService.trackEvents(
        EVENT_NAMES.PRODUCT_IMPRESSION,
        { ...eventArgs },
        false,
        EventTrackingPlugins.Firebase
      );
    } catch (e) {
      console.log("error:", e);
    }
  }

  async fetchAccessTokenAfterRefresh() {
    await this.callRefreshToken();
    return this.authService.getAuthKey();
  }

  async callRefreshToken() {
    if (this.authService.isLoggedIn() && window.navigator.onLine) {
      return await this.httpService.updateToken();
    } else {
      return Promise.resolve("");
    }
  }

  evictUserDetailsCache() {
    // this.client.cache.evict({ id: "ROOT_QUERY", fieldName: "getUserDetails" });
    this.handleUserUpdates();
  }

  evictUserAddressCache() {
    this.client.cache.evict({ id: "ROOT_QUERY", fieldName: "getUserAddress" });
  }

  evictValuePassCache() {
    this.client.cache.evict({ id: "ROOT_QUERY", fieldName: "getValuePass" });
  }

  evictInviteCodeCache() {
    this.client.cache.evict({ id: "ROOT_QUERY", fieldName: "getAppliedCode" });
  }

  evictRecycleBagCache() {
    this.client.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "getRecycleRequest",
    });
  }

  evictSubscriptionOrdersCache() {
    this.client.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "getSubscriptionOrders",
    });
    this.evictSubscriptionCache();
  }

  evictSubscriptionCache() {
    this.client.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "getSubscriptionOffer",
    });
    this.client.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "getProductSubscriptionOrders",
    });
  }

  evictUserBalance() {
    this.client.cache.evict({ id: "ROOT_QUERY", fieldName: "getUserBalance" });
  }

  handlePaymentTransactionEffects(res) {
    this.handleUserUpdates();
    if (res === PAYMENT_CACHE.WITHOUT_INVITE) {
      return this.client.refetchQueries({
        updateCache: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "getUserBalance" });
          /* cache.evict({ id: "ROOT_QUERY", fieldName: "getUserDetails" });
          cache.evict({ id: "ROOT_QUERY", fieldName: "getUserMenu" }); */
        },
      });
    } else {
      return this.client.refetchQueries({
        updateCache: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "getUserBalance" });
          /* cache.evict({ id: "ROOT_QUERY", fieldName: "getUserDetails" });
          cache.evict({ id: "ROOT_QUERY", fieldName: "getUserMenu" }); */
          cache.evict({ id: "ROOT_QUERY", fieldName: "getAppliedCode" });
        },
      });
    }
  }

  evictMembershipStripCache() {
    this.client.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "getUserMembershipSummary",
    });
  }

  evictHomeDashboard() {
    this.client.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "getHomeDashboard",
    });
  }

  evictFutureBaskets() {
    this.client.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "getFutureBaskets",
    });
  }

  setupApolloCacheFieldPolicies() {
    const productTypePolicies = this.getProductTypePolicies();
    return { ...productTypePolicies };
  }

  // @TODO: this is only to avoid the race condition, setting values in component load as well as type policies
  configureCacheOptions() {
    const fieldPolicies = this.setupApolloCacheFieldPolicies();

    let typePolicies: any = {
      ProductOrder: {
        keyFields: ["productId"],
      },
      FutureBasketProductOrder: {
        keyFields: ["productId", "basketId"],
      },
      ...fieldPolicies,
    };

    try {
      typePolicies.RecycleRequestGet = this.configureRecycleRequestGet();
    } catch (e) {}
    // typePolicies.ActiveValuePass = this.configureActiveValuePass();
    try {
      typePolicies.UserMenu = this.configureUserMenu();
    } catch (e) {}

    try {
      typePolicies.Query = {
        fields: this.setupApolloCachePolicies(),
      };
    } catch (e) {}
    return { typePolicies };
  }

  getProductTypePolicies() {
    return {
      Product: {
        fields: {
          availability: {
            read: (availabilityImpl, ...args) => {
              const availability = { ...availabilityImpl };
              if (!!availabilityImpl.isOos) {
                return availabilityImpl;
              }
              if (
                !availabilityImpl.schedule &&
                availabilityImpl.cutOff.time &&
                this.getCurrentTime() > availabilityImpl.cutOff.time
              ) {
                availability.isOos = true;
              }
              return availability;
            },
          },
        },
      },
    };
  }

  getCurrentTime() {
    const date = new Date();
    // Get hours and minutes from the date object
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Pad single-digit hours and minutes with leading zeros
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    // Concatenate hours and minutes in HHMM format
    const formattedTime = formattedHours + formattedMinutes;
    return formattedTime;
  }

  configureRecycleRequestGet() {
    const that = this;
    return {
      fields: {
        cartStatus: {
          read(value) {
            if (!value) {
              return that.masterDataService.baskets?.length > 0
                ? (that.masterDataService.baskets[0].bill_details?.sub_total ||
                    0) -
                    (that.masterDataService.baskets[0].bill_details
                      ?.sub_savings || 0) >
                  0
                  ? true
                  : false
                : false;
            }
            return value;
          },
        },
      },
    };
  }

  configureUserMenu() {
    const that = this;
    return {
      fields: {
        items: {
          read(values: Array<any>) {
            let newValues;
            try {
              newValues = JSON.parse(JSON.stringify(values));
            } catch (e) {
              newValues = [];
            }
            if (newValues) {
              newValues = newValues.map((value) => {
                if (value.name === "notification") {
                  if (
                    !(
                      Utilities.isApp() &&
                      that.masterDataService.staticMasterData?.isAppInbox
                    )
                  ) {
                    return false;
                  }
                } else if (value.name === "recycle") {
                  if (!that.readShowRecyclePage()) {
                    return false;
                  }
                  value.liveText = "NEW";
                } else if (value.name === "jiocashback") {
                  if (
                    !that.dataService.firebaseMasterData.jioCashBackConstruct
                      .VISIBILITY
                  ) {
                    return false;
                  }
                  value.title =
                    that.dataService.firebaseMasterData.jioCashBackConstruct.HEADER;
                }
                return value;
              });
              newValues = newValues.filter((value) => !!value);
            }
            return newValues;
          },
        },
      },
    };
  }

  prepareUserAddressCache() {
    try {
      if (!this.masterDataService?.masterData?.user) {
        return null;
      }

      let userAddress = {
        getUserAddress: {
          read: (_existing) => this._prepareUserAddressCache(),
        },
      };
      return userAddress;
    } catch (e) {}
  }

  _prepareUserAddressCache() {
    if (!this.masterDataService?.masterData?.user) {
      return null;
    }

    let address = {
      line1: undefined,
      line2: undefined,
      status: undefined,
      cityId: undefined,
      hubId: undefined,
    };
    if (
      this.masterDataService.masterData?.user?.societyType ===
      SocietyType.Locality
    ) {
      address.line1 =
        this.masterDataService.masterData?.user?.addressUnit.slice(0, 10) +
        (this.masterDataService.masterData?.user?.addressFloor
          ? "-" + this.masterDataService.masterData?.user?.addressFloor
          : "") +
        (this.masterDataService?.masterData?.user?.buildingName
          ? ", " + this.masterDataService?.masterData?.user?.buildingName
          : "");

      address.line2 =
        this.masterDataService.masterData?.user?.society +
        "," +
        this.masterDataService?.masterData?.user?.streetName;
    } else {
      address.line1 = this.masterDataService.masterData?.user?.address;
      address.line2 = this.masterDataService.masterData?.user?.society;
    }

    address.status = this.masterDataService.masterData?.user?.is_valid_address;

    address.cityId = this.masterDataService.masterData?.user?.city_id;
    address.hubId = this.masterDataService.masterData?.user?.hubId;
    return address;
  }

  prepareUserDetailsCache() {
    try {
      if (!this.masterDataService?.masterData?.user) {
        return null;
      }
      let response = {
        getUserDetails: {
          read: (_existing) => {
            return {
              __typename: "UserDetails",
              user: this._prepareUserDetailsCache(),
            };
          },
        },
      };
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  _prepareUserDetailsCache() {
    if (!this.masterDataService?.masterData?.user) {
      return null;
    }

    let user = {
      __typename: "UserDetail",
      id: this.masterDataService?.masterData?.user?.id,
      name: this.masterDataService?.masterData?.user?.name,
      phone: "+91" + "-" + this.masterDataService?.masterData?.user?.phone,
      email: this.masterDataService?.masterData?.user?.email,
      walletFreeze: this.masterDataService.masterData?.user?.wf,
      paid: this.masterDataService.masterData?.user?.paid,
      address: {
        __typename: "UserAddress",
        line1: undefined,
        line2: undefined,
        fullAddress: undefined,
        status: undefined,
        cityId: this.masterDataService.masterData?.user?.city_id,
        hubId: this.masterDataService.masterData?.user?.hubId,
      },
      accountStatus: this.masterDataService.masterData?.user?.closed,
      firstOrderDate:
        this.masterDataService.masterData?.user?.first_order_date || "",
      deliveryStartDate:
        this.masterDataService.masterData?.user?.delivery_start_date || "",
      communicationFlags: {
        __typename: "CommunicationFlag",
        email: !!this.masterDataService.masterData?.user?.is_email,
        push: true,
        sms: !!this.masterDataService.masterData?.user?.is_sms,
        whatsapp: true,
      },
      flags: {
        __typename: "UserFlag",
        showMembership:
          !!this.masterDataService.masterData?.user?.membershipVisibility,
        autoRenewMembership:
          !!this.masterDataService.masterData?.user?.cinfo?.arm,
        tncAccepted:
          !!this.masterDataService.masterData?.user?.cinfo?.acceptTnc,
        isEmailVerified: !!this.masterDataService.masterData?.user?.cinfo?.iev,
        noCheckoutVisted:
          this.masterDataService.masterData?.user?.cinfo?.ncv || 0,
      },
    };

    if (
      this.masterDataService.masterData?.user?.societyType ===
      SocietyType.Locality
    ) {
      user.address.line1 =
        this.masterDataService.masterData?.user?.addressUnit.slice(0, 10) +
        (this.masterDataService.masterData?.user?.addressFloor
          ? "-" + this.masterDataService.masterData?.user?.addressFloor
          : "") +
        (this.masterDataService?.masterData?.user?.buildingName
          ? ", " + this.masterDataService?.masterData?.user?.buildingName
          : "");

      user.address.line2 =
        this.masterDataService.masterData?.user?.society +
        "," +
        this.masterDataService?.masterData?.user?.streetName;
    } else {
      user.address.line1 = this.masterDataService.masterData?.user?.address;
      user.address.line2 = this.masterDataService.masterData?.user?.society;
    }

    user.address.status =
      this.masterDataService.masterData?.user?.is_valid_address;
    return user;
  }

  prepareBasketCache() {
    try {
      let response = {
        getBasket: {
          read: (_existing) => {
            if (_existing) {
              return _existing;
            }
            const basketApi = this.masterDataService.baskets[0];
            if (basketApi) {
              const processedBasket = new Basket();
              processedBasket.setBasketFromApi(basketApi);
              return processedBasket;
            }
            return null;
          },
        },
      };
      return response;
    } catch (e) {}
  }

  // prepareValuePassData() {
  //   try {
  //     const valuePassData = {
  //       getValuePass: {
  //         read: (_existing) => ({
  //           __typename: "ActiveValuePass",
  //           pass: this._prepareValuePassData(),
  //         }),
  //       },
  //     };
  //     return valuePassData;
  //   } catch (e) {}
  // }

  // _prepareValuePassData() {
  //   try {
  //     const passData: any = {};
  //     const appPassData: any = this.dataService.mbPassData || false;
  //     if (appPassData) {
  //       passData.passId = appPassData.passId;
  //       passData.passName = appPassData.passName;
  //       passData.totalSavings = appPassData.totalSavings || 0;
  //       passData.maxCashback = appPassData.maxCashback;
  //       passData.passDescription = appPassData.passDescription;
  //       passData.spAmount = appPassData.sp || 0;
  //       passData.sp = appPassData.sp ? `₹ ${appPassData.sp}` : "Free";

  //       const tncs = (
  //         appPassData.termsAndConditions as unknown as string
  //       ).replace(/<[^>]+>/g, "\n");
  //       const tnc = tncs.split("\n").filter((item) => !!item);
  //       passData.termsAndConditions = tnc;
  //       passData.startDate =
  //         typeof appPassData.startDate === "string"
  //           ? appPassData.startDate
  //           : this.amDateFormat.transform(
  //               new Date(appPassData.startDate),
  //               "Do MMMM YYYY"
  //             );
  //       passData.endDate =
  //         typeof appPassData.endDate === "string"
  //           ? appPassData.endDate
  //           : this.amDateFormat.transform(
  //               new Date(appPassData.endDate),
  //               "Do MMMM YYYY"
  //             );
  //       passData.purchased = appPassData.purchased;
  //       passData.validity = appPassData.validity;
  //       passData.totalSavingPercentage =
  //         ((appPassData.totalSavings || 0) / appPassData.maxCashback) * 100;

  //       passData.almostRedeemed =
  //         passData.totalSavingPercentage > 80 &&
  //         passData.totalSavingPercentage < 100;

  //       passData.offerCategoryType = appPassData.offerCategoryType;
  //       passData.multipleScreenDescription =
  //         appPassData.multipleScreenDescription; // string
  //       passData.offerCategoryValue = appPassData.offerCategoryValue; // array

  //       passData.cashbackLedger = this.prepareCashbackLedger(
  //         appPassData.cashbackLedger
  //       );

  //       passData.__typename = "ValuePass";
  //     }
  //     return passData;
  //   } catch (e) {}
  //   return null;
  // }

  // prepareCashbackLedger(cashbackLedgerData) {
  //   const cashbackLedger = (cashbackLedgerData || []).map(
  //     (valuePassCashback) => {
  //       return {
  //         orderId: valuePassCashback.orderId,
  //         date: this.amDateFormat.transform(
  //           new Date(valuePassCashback.date),
  //           "Do MMMM YYYY"
  //         ),
  //         refund: valuePassCashback.refund,
  //         savingsCredited: 0,
  //         desc: valuePassCashback.refund
  //           ? "Cashback Refund"
  //           : "Cashback Received",
  //         passSaving: `₹ ${valuePassCashback.refund ? "" : "+"}${
  //           valuePassCashback.passSaving
  //         }`,
  //         __typename: "ValuePassCashback",
  //       };
  //     }
  //   );
  //   return cashbackLedger;
  // }

  /* prepareAppliedCodeData() {
      const appliedCodeData = {
        getAppliedCode: {
          read: (_existing) => {
            const returnData = this._prepareAppliedCodeData() || {code: null};
            return {
            __typename: "InviteCodeApplied",
            ...returnData}
          },
        },
      };
      return appliedCodeData;
  } */

  // _prepareAppliedCodeData () {
  //   try{
  //     let appliedCode: any = {};
  //     if (this.masterDataService.masterData?.welcome_code?.detail) {
  //       const codeData =
  //         this.masterDataService.masterData?.welcome_code?.detail;
  //       appliedCode = {
  //         code: codeData.code,
  //         description: codeData.shortDescription,
  //         isRemovable: codeData.isRemovable,
  //         successMsg: codeData.text,
  //         tnc: this.dataService.formatHtml2String(codeData.info),
  //       };
  //     } else {
  //       appliedCode = {
  //         code: null,
  //         description: null,
  //         isRemovable: null,
  //         successMsg: null,
  //         tnc: null,
  //       };
  //     }
  //     return appliedCode;

  //   } catch(e) {
  //     console.log('error:', e);
  //   }
  //   return {};
  // }

  prepareSegmentGroups() {
    try {
      let segmentGroupsCache;
      const segmentList = {
        getCustomerSegmentsList: {
          read: async (_existing) => {
            if (!!segmentGroupsCache) {
              return {
                __typename: "SegmentList",
                segments: segmentGroupsCache,
              };
            }
            segmentGroupsCache =
              (await this.masterDataService.fetchCustomerSegments()) || [];
            return {
              __typename: "SegmentList",
              segments: segmentGroupsCache,
            };
          },
        },
      };
      return segmentList;
    } catch (e) {
      console.log("error:", e);
    }
  }

  preapreSegmentIDs() {
    try {
      let segmentIdsCache;
      const segmentIdList = {
        getCustomerSegments: {
          read: async (_existing) => {
            if (!!segmentIdsCache) {
              return {
                __typename: "CustomerSegment",
                ids: segmentIdsCache,
              };
            }
            segmentIdsCache =
              (await this.masterDataService.getSegmentsIds()) || [];
            return {
              __typename: "CustomerSegment",
              ids: segmentIdsCache,
            };
          },
        },
      };
      return segmentIdList;
    } catch (e) {
      console.log("error:", e);
    }
  }
  readShowRecyclePage() {
    let showRecyclePage = false;
    if (
      (this.dataService.firebaseMasterData.showRecycleHubs[
        this.masterDataService.masterData?.userHub
      ] ||
        this.dataService.firebaseMasterData.showTetraPackHubs[
          this.masterDataService.masterData?.userHub
        ]) &&
      this.masterDataService.masterData?.user?.first_order_date
    ) {
      this.dataService.userExistInRecycleBagHub = true;
      showRecyclePage = true;
    } else {
      this.dataService.userExistInRecycleBagHub = false;
    }
    return showRecyclePage;
  }

  handleBasketUpdates() {
    const basketApi = this.masterDataService.baskets[0];
    if (basketApi) {
      const processedBasket = new Basket();
      processedBasket.setBasketFromApi(basketApi);
      if (processedBasket) {
        this.client.cache.updateQuery(
          { query: GET_BASKET_QUERY, overwrite: true },
          (data) => {
            const { getBasket } = data || {};

            const basketProducts = processedBasket?.products || [];
            const { products: prevBasketProducts } = getBasket || {
              products: [],
            };

            this.compareAndRemoveProductsFromPreviousBasket(
              prevBasketProducts,
              basketProducts
            );
            return { getBasket: processedBasket };
          }
        );
      }
    }
  }

  handleFutureProductOrder(res) {
    const { basketResponse, product } = res;
    const productId = product.id || product.product_id;
    const futureBasketProduct = basketResponse.data.data?.[0].products.find(
      (orderProduct) => {
        return orderProduct.product_id === productId;
      }
    );
    if (futureBasketProduct) {
      const productId = futureBasketProduct.product_id;
      const basketId = basketResponse.data.data?.[0]?.basket_id;

      this.client.cache.updateFragment(
        {
          id: `FutureBasketProductOrder:{"productId":${productId},"basketId":${basketId}}`,
          fragment: FUTURE_BASKET_PRODUCT_ORDER_FRAGMENT,
          fragmentName: "FutureBasketProductOrderFragment",
        },
        (data) => {
          return { ...data, quantity: futureBasketProduct.quantity };
        }
      );
    } else {
      this.evictFutureBaskets();
    }
  }

  handleRecurringOrdersChange() {
    const subscriptionOrdersApi = this.masterDataService.recurringOrder;

    if (!subscriptionOrdersApi) {
      this.client.cache.updateQuery(
        { query: GET_SUBSCRIPTION_PRODUCT_ORDERS_QUERY, overwrite: true },
        (data) => {
          return { getProductSubscriptionOrders: [] };
        }
      );
      return;
    }

    this.client.cache.updateQuery(
      { query: GET_SUBSCRIPTION_PRODUCT_ORDERS_QUERY, overwrite: true },
      (data) => {
        const orders: ProductSubscriptionOrder[] = [];
        subscriptionOrdersApi.forEach((subscriptionOrderApi) => {
          const subscriptionOrderObject = new ProductSubscriptionOrder();
          const subscriptionOrder =
            subscriptionOrderObject.setProductSubscriptionOrderFromApi(
              subscriptionOrderApi
            );

          if (subscriptionOrder) {
            orders.push(subscriptionOrder);
          }
        });
        return { getProductSubscriptionOrders: orders };
      }
    );
  }

  handleUserUpdates() {
    if (this.masterDataService.masterData.user) {
      const userApi = this.masterDataService.masterData.user;
      if (userApi) {
        const userObj = new UserDetails();
        userObj.setUserDetail(userApi);
        if (userObj) {
          this.client.cache.updateQuery(
            { query: USER_DETAILS_QUERY, overwrite: true },
            (_data) => {
              return { getUserDetails: userObj };
            }
          );
        }
      }
    }
  }

  compareAndRemoveProductsFromPreviousBasket(
    prevBasketProducts,
    basketProducts
  ) {
    try {
      const diffProductOrders = [];
      for (let i in prevBasketProducts) {
        let found = false;
        for (let j in basketProducts) {
          if (
            +prevBasketProducts[i].order.productId ===
              +basketProducts[j].order.productId &&
            basketProducts[j].order.quantity !== 0
          ) {
            found = true;
          }
        }
        if (!found) {
          diffProductOrders.push(prevBasketProducts[i].order);
        }
      }

      for (let i in diffProductOrders) {
        this.client.writeFragment({
          productId: `ProductOrder:${diffProductOrders[i].productId}`,
          fragment: PRODUCT_ORDER_FRAGMENT,
          fragmentName: "ProductOrderFragment",
          data: { ...diffProductOrders[i], quantity: 0 },
        });
        /* this.client.updateFragment({
            id: `ProductOrder:{"productId":${oldProductIds[i]}}`,
            fragment: PRODUCT_ORDER_FRAGMENT,
            fragmentName: 'ProductOrderFragment',
            overwrite: true,
          },
          (data) => {
            console.log('data:', {...data, quantity: Math.max(data.quantity - 1, 0)});
            return {...data, quantity: Math.max(data.quantity - 1, 0)};
          },
        ) */
        /* this.client.cache.evict({ id: `ProductOrder:{"productId":${oldProductIds[i]}}` });
        console.log('this.client.cache:', this.client.cache); */
      }
    } catch (e) {}
  }
}
