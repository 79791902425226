<ion-header class="sticky-header">
  <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium padding-horizontal-0">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
          <ion-icon class="icon-close alert-button-close icon-md icons-property" color="dark" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-no-padding ion-padding-start">
      <h2>Food Wallet Usage Guidelines</h2>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ctn-gt-space-p fw-list-guide">
  <p class="fw-sub-heading">Use your Food Wallet to buy</p>
  <ion-item class="d-flex ion-align-items-center ion-no-padding" lines="none">
    <ion-img src="assets/img/fw-milk.png"></ion-img>
    <ion-label>Milk and dairy products.</ion-label>
  </ion-item>
  <ion-item class="d-flex ion-align-items-center ion-no-padding" lines="none">
    <ion-img src="assets/img/fw-fruit.png"></ion-img>
    <ion-label>Fresh fruits, vegetables, groceries & other food items.</ion-label>
  </ion-item>
  <ion-item class="d-flex ion-align-items-center ion-no-padding" lines="none">
    <ion-img src="assets/img/fw-non-fruit.png"></ion-img>
    <ion-label class="txt-red">Cannot be used for non-food items and services like membership & delivery charges.</ion-label>
  </ion-item>
</ion-content>
