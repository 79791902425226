//#region Imports

//#region Angular Imports
import { ChangeDetectorRef, Component, EventEmitter, Input, Output }  from '@angular/core';
import { Router } from '@angular/router';
//#endregion Angular Imports

//#region Ionic Imports
import { IonSlides } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
import { InViewportMetadata } from 'ng-in-viewport'; 
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthService } from '@Globals/providers/auth.service';
import { ProductListManagerService } from '@Features/product-list/providers/product-list-manager.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { EventsService } from '@Globals/providers/events.service';
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, NUMBER_OF_ITEMS_TO_DISPLAY, NUMBER_OF_ITEMS_TO_DISPLAY_INC } from "@Globals/constants/listing/constants";
import { CollectionPositionsInSubcategoryPLP, ImageOnDemandType, ProductListType, RecurringCampaignStripType, SeeSimilar, RecommendationType, CollectionDisplayType, EventTrackingPlugins } from '@Globals/constants/listing/enums';
import { SimilarProducts } from '@Globals/interfaces/listing/interfaces';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mb-pl-vlist',
  templateUrl: './listing.component.html',
  styleUrls: [ '../../common.scss', './listing.component.scss' ],
  providers: [ ProductListManagerService ]
})
export class ListingComponent { 
  @Input() componentType: ProductListType;
  @Input() collections;
  @Input() sponseredProductList;
  @Input() products: Array<any>; // ProductInSearch, ProductInAccountHistoryDetails, & RecurringOrder
  @Input() eventSource: string;
  @Input() eventData: string;
  @Input() itemType?: string;
  @Input() sliderFromPage?: IonSlides;
  @Input() appliedFilter?: string;
  @Input() isProductImageRequired = true;
  @Input() showCancelIcon = false;
  
  @Input() isPDP;
  @Input() pdpProductIndex?: number;
  
  @Input() basketIndex?: number; // Use this only with Your cart Rest of the week tab
  @Input() basketDate?: string;
  
  @Input() showAddToBasketButton: boolean = true;
  @Input() recCampaignSubsribeToSave: boolean = true;
  @Input() restOfTheMonth: boolean = false;
  @Input() showRcNewlyAddedProducts: boolean = false;
  @Input() showCalenderButton: boolean = true;
  @Input() showSubscribeToSaveStrip: boolean = true;

  @Input() useServerPagination = false;
  @Input() useClientPagination = false;
  @Input() isHideSimilar = false;
  @Input() incPageSize: number = NUMBER_OF_ITEMS_TO_DISPLAY_INC;
  @Input() collectionDisplayType?:number | string;
  @Input() showPdpPage:boolean= true;
  @Input() showDivider = false;
  allCollectionDisplayType = CollectionDisplayType;

  @Output() allProductsLoaded = new EventEmitter();
  @Output() selectProduct = new EventEmitter();

  recommendationComponentType: RecommendationType; // type of the Recommendation.
  isRequestDivAllowed = true; // Used to show and hide the Requested Div.
  productListing = RecurringCampaignStripType.PRODUCT_LISTING_STRIP;
  collectionPositionsBeforeOOS = CollectionPositionsInSubcategoryPLP.BeforeOutOfStock;
  ProductListType = ProductListType;
  PLPTypeImageOnDemand = ImageOnDemandType.PLP;

  OosProductStartingIndex: number = null;
  isPageLoaded = false;
  showLoader = true;

  indexToShowRecurringCoachOn = -1;
  productIdToShowCoachmark;
  subscription:Subscription;
  calendarSubscription:Subscription;

  recommendedProductList: SimilarProducts = {
    product: {
        id: 0
    },
    title: 'Suggestions for you'
  };

  productsAsPerDisplayLimit: Array<any> = []; // ProductInSearch, ProductInAccountHistoryDetails, & RecurringOrder

  event = null;
  isCancelledProduct = false;

  showOOSOnProduct = false;
  minHeight = undefined;
  isPDPForVariant;
  showDefaultImage = true;
  showDefaultShimmer = false;
  showRecurringButton = false;
  recurringButtonText = '';
  showSimilarButton = false;
  showVariantSubscription: Subscription;
  @Input()offerProductDisable:boolean= false;
  constructor(
    private authService: AuthService,
    public plmService: ProductListManagerService,
    public masterDataService: MasterDataService,
    private eventsService: EventsService,
    private cdRef: ChangeDetectorRef,
    public dataService: DataService,
    private router: Router
    ) { }

  initializeComponent() {
    if (this.componentType === ProductListType.Product_Listing ||
      this.componentType === ProductListType.Search_Page ||
      this.componentType === ProductListType.Collection) {
      this.dataService.productImpressionConfigData.componentType = this.componentType;
    }
    this.showOOSOnProduct = (this.componentType === ProductListType.Product_Listing ||
      this.componentType === ProductListType.Search_Page ||
      this.componentType === ProductListType.Collection ||
      this.componentType === ProductListType.Variant ||
      this.componentType === ProductListType.Product_Variant_List );

    this.showRecurringButton = this.componentType === ProductListType.Recurring_Search_Listing || this.componentType === ProductListType.Recurring_Order_Listing;
    this.minHeight = this.componentType === ProductListType.Product_Listing && '100%';
    this.isPDPForVariant = this.componentType === ProductListType.Product_Details;
    this.showDefaultImage = (this.componentType === ProductListType.Product_Listing || this.componentType === ProductListType.Your_Frequent_Orders || this.componentType === ProductListType.Collection) ? false : true
    this.showDefaultShimmer = (this.componentType === ProductListType.Product_Listing || this.componentType === ProductListType.Your_Frequent_Orders || this.componentType === ProductListType.Collection) ? true : false;
    this.recurringButtonText = this.componentType === ProductListType.Recurring_Order_Listing ? 'Edit' : 'Select';
    this.showSimilarButton = (this.componentType === ProductListType.Product_Listing ||
      this.componentType === ProductListType.Cancelled_Products_On_Cart ||
      this.componentType === ProductListType.Search_Page ||
      this.componentType === ProductListType.Collection ||
      this.componentType === ProductListType.Variant || this.componentType === ProductListType.Product_Variant_List) &&
      this.plmService.isOOS && this.plmService.showSeeSimilar && !this.isHideSimilar;
  } 

  setProductAsPerDisplayLimit() {
    if (this.useClientPagination && this.products && this.products.length > NUMBER_OF_ITEMS_TO_DISPLAY) {
      this.productsAsPerDisplayLimit = this.products.slice(0, NUMBER_OF_ITEMS_TO_DISPLAY);
    } else {
      this.productsAsPerDisplayLimit = this.products;
    }
  }

  manageProductsAsPerDisplayLimit() {
    if (this.useClientPagination && this.products && this.products.length > NUMBER_OF_ITEMS_TO_DISPLAY) {
        this.event && this.event.target && (this.event.target.disabled = false);
        if(this.productsAsPerDisplayLimit && this.productsAsPerDisplayLimit.length) {
            if((this.productsAsPerDisplayLimit[0].id && this.productsAsPerDisplayLimit[0].id !== this.products[0].id) || 
                (this.productsAsPerDisplayLimit[0].product_id && this.productsAsPerDisplayLimit[0].product_id !== this.products[0].product_id)) {
                this.productsAsPerDisplayLimit = [];
            }
        }
        if(this.productsAsPerDisplayLimit && this.productsAsPerDisplayLimit.length) {
            this.isPageLoaded = false;
            this.showLoader = true;
            this.productsAsPerDisplayLimit = this.products.slice(0, this.productsAsPerDisplayLimit.length + (this.incPageSize || NUMBER_OF_ITEMS_TO_DISPLAY_INC));
            this.event && this.event.target && this.event.target.complete();
        } else {
            this.productsAsPerDisplayLimit = this.products.slice(0, NUMBER_OF_ITEMS_TO_DISPLAY);
        }
    } else {
        this.event && this.event.target && (this.event.target.disabled = false);
        this.productsAsPerDisplayLimit = this.products;
    }
  }

  ngOnInit() {
      this.initializeComponent();
      this.setProductAsPerDisplayLimit();
      this.products = this.plmService.processProductsData(this, this.products);
      this.plmService.setupTomorrowBasket();
      !this.basketIndex && (this.basketIndex = 0);
      !this.authService.isLoggedIn() && (this.authService.userData.tomorrowBasket = {});
      this.subscription = this.plmService.getRunChangeDetForA2B().subscribe(res => {
        this.cdRef.markForCheck();
      });
      this.calendarSubscription = this.plmService.getRunChangeDetForRecurringOrdersChange().subscribe(res => {
        let selectedProduct = this.plmService.getSelectedProductForCalendar();
        this.products?.map((product, index) => {
          if(product.id === selectedProduct?.id || product.product_id === selectedProduct?.product_id) {
            product._isActive = this.plmService.isSetRecurring(product);
          }
        });
      });
      this.showVariantSubscription = this.dataService.openVariantModal.subscribe(response => {
        if(response){
          const variantData = this.dataService.variantShouldOpen;
          if(variantData && variantData?.status && variantData?.data?.product && variantData?.data?.parentIndex >= 0){
            this.openProductsVariantModal(variantData.data.product, variantData.data.parentIndex);
          }
          this.dataService.variantShouldOpen.status = false;
          this.dataService.variantShouldOpen.data = {};
        }
      });
  }

  ngOnChanges() {
    this.manageProductsAsPerDisplayLimit();
    this.products = this.plmService.processProductsData(this, this.products);
    this.cdRef.detectChanges();
  }

  async openProductsVariantModal(product, selectedProductIndex) {
    this.dataService.productVariantInfo.isVariantAddedToCart = false;
    this.dataService.productVariantInfo.latestVariantIndexIntoCart = null;
    this.dataService.productVariantInfo.parentProductIndex = selectedProductIndex;
    const variantProduct = product?._newVariantProducts;

    if (variantProduct && variantProduct.length) {
      const modal = await this.plmService.openProductVariantModel(variantProduct, product, ProductListType.Product_Variant_List, this.eventSource, this.eventData);
      await modal.present();
      let eventAgrs = { source: this.eventSource, product_id: product.id, variant_id: product.variantId };
      this.eventsService.trackEvents(EVENT_NAMES.PRODUCT_VARIANT_CLICKED, { ...eventAgrs });

      modal.onDidDismiss().then(res => {
        if (this.dataService.productVariantInfo.isVariantAddedToCart && !this.router.url.includes("/listing/product-detail/")) {
          this.productsAsPerDisplayLimit[selectedProductIndex] = {...variantProduct[this.dataService.productVariantInfo.latestVariantIndexIntoCart]};
          this.productsAsPerDisplayLimit[selectedProductIndex]._newVariantProducts = [...variantProduct];
        }
      });
    }
  }

  productGoToCalendar(product, productIndex) {
    if(this.componentType === ProductListType.Product_Variant_List){
      this.dataService.variantShouldOpen.status = true;
      this.dataService.variantShouldOpen.data = { product:{_newVariantProducts: this.products, ...product}, parentIndex: this.dataService.productVariantInfo.parentProductIndex };
    }
    return this.plmService.goToCalendar(
        product,
        productIndex,
        this.eventSource,
        this.itemType, 
        this.appliedFilter
    );
  }

  openProductsDetailModal(product, productIndex) {
    !product.oos && !product.forcedOos && this.dataService.getVariantModal();
    return !product.oos && !product.forcedOos && this.plmService.productDetailsModal(
        product.product_id || product.id,
        productIndex,
        this.componentType,
        this.eventSource,
        this.eventData,
        this.itemType,
        product, 
        this.appliedFilter,
        this.basketIndex
    );
  }

  openProductsDetailModalForNonCancelled(product, productIndex) {
    if(!this.showPdpPage){
      return
    }
    !product.oos && !product.forcedOos && this.dataService.getVariantModal();
    return (this.componentType !== ProductListType.Cancelled_Products_On_Cart) && !product.offer_view_type && !product.oos && !product.forcedOos &&
      this.plmService.productDetailsModal(
        product.product_id || product.id,
        productIndex,
        this.componentType,
        this.eventSource,
        this.eventData,
        this.itemType,
        product, 
        this.appliedFilter,
        this.basketIndex
      );
  }
  
  openScheduleModal(product, productIndex) {
    return this.plmService.openScheduleModal(this, product, productIndex);
  }

  removeCancelProduct(productId) {
    return this.plmService.removeCancelProduct(this, productId);
  }

  navigateToRecurring(product, productIndex) {
    this.selectProduct.emit({ product, productIndex, eventSource: this.eventSource || 'productListing', itemType: this.itemType, appliedFilter: this.appliedFilter });
  }

  counterChangeEvent(event, product, productIndex, isCancelledProduct = false) {
    this.isCancelledProduct = isCancelledProduct;
    this.plmService.counterChange(this, event.event, product, productIndex);
  }

  counterChange(counterDataForImpluseListing, product, productIndex) {
    this.plmService.counterChange(this, counterDataForImpluseListing, product, productIndex);
    if(this.masterDataService.masterData?.user?.wf){
      return;
  }
  }

  productShowHideRecommended(product, productIndex) {
    let hideShowflag = (product.id || product.product_id) === this.recommendedProductList.product.id ? SeeSimilar.hide : SeeSimilar.show;
    return this.plmService.showOrHideRecommendedProducts(this, product, hideShowflag);
  }

  requestProduct(product, isSourceSeeSimilar = false) {
    return this.plmService.requestProduct(this, product, isSourceSeeSimilar);
  }
  
  trackVariantSwitch(product, variantIndex) {
    product.selectedVariantIndex = variantIndex; 
    return this.plmService.trackVariantSwitch(product);
  }

  loadData(event) {

    if(!this.useClientPagination) {
      event.target.complete(); 
      return;
    }

    this.event = event;
    this.isPageLoaded = false;
    this.showLoader = true;
    if(this.productsAsPerDisplayLimit?.length < this.products?.length) {
        this.productsAsPerDisplayLimit = this.products?.slice(0, this.productsAsPerDisplayLimit?.length + (this.incPageSize || NUMBER_OF_ITEMS_TO_DISPLAY_INC));
        event.target.complete();
        event.target.disabled = false;
        this.isPageLoaded = true;
        this.showLoader = false;
    } else if (this.useServerPagination) {
        event.target.complete();
        event.target.disabled = true;
        this.isPageLoaded = true;
        this.showLoader = false;
        this.allProductsLoaded.emit(true);
    } else if(this.productsAsPerDisplayLimit?.length >= this.products?.length) {
        event.target.complete();
        event.target.disabled = true;
        this.isPageLoaded = false;
        this.showLoader = false;
    }
  }

  successHandlerForRecommendedProducts = (productList, product) => {
    if (this.componentType === ProductListType.Cancelled_Products_On_Cart) {
        this.recommendedProductList.title = 'Similar items to choose from';
    }
    this.recommendedProductList.data = productList;
    this.recommendedProductList.product = product;
  }

  getProductId(index : number, product: any) {
    return product ? (product.product_id || product.id) : undefined;
  }

  onIntersectionCollection($event, data) {
    const { [InViewportMetadata]: { entry }, target } = $event;
    const ratio = entry.intersectionRatio;
    this.dataService.trackCollectionImpression(ratio, data, this.eventData['SourceKeyword'] || this.eventSource);
  }
  
  onIntersectionProduct($event, product) {
    if (!product) {
      return;
    }
    const { [InViewportMetadata]: { entry }, target, visible } = $event;
    const ratio = entry.intersectionRatio;
    if (visible && (ratio >= 0.65 || ratio === 1)) {
      let PI_UniqueKey: string = '';
      let productId;
      switch (this.componentType) {
        case ProductListType.Search_Page:
          PI_UniqueKey = this.eventData['keyword'];
          productId = product.id;
          break;
        case ProductListType.Product_Listing:
          PI_UniqueKey = product.subcategory;
          productId = product.id;
          break;
        case ProductListType.Collection:
          if (this.eventData && this.eventData['collection_id']) {
            PI_UniqueKey = this.eventData['collection_id'];
            productId = product.product_id;
          }
          if (this.eventData && this.eventData['banner_id']) {
            PI_UniqueKey = this.eventData['banner_id'];
            productId = product.product_id;
          }
          break;
      }
      if (PI_UniqueKey && productId) {        
        if (!Object.keys(this.dataService.trackedProductImpression).includes(PI_UniqueKey.toString())) {
          this.dataService.trackedProductImpression[PI_UniqueKey] = [];
          this.dataService.trackedProductImpression[PI_UniqueKey].push(productId);
        } else if (!this.dataService.trackedProductImpression[PI_UniqueKey].includes(productId)) {
          this.dataService.trackedProductImpression[PI_UniqueKey].push(productId);
        }
      }
    }
    
    if(this.dataService.productImpressionConfigData.componentType !== this.componentType){
      this.dataService.productImpressionConfigData.componentType = this.componentType;
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.calendarSubscription?.unsubscribe();
    this.showVariantSubscription?.unsubscribe();
  }
}
