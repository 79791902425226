import { AuthService } from './../../../shared/providers/auth/auth.service';
import { HttpService } from '../../../shared/providers/http/http.service';
import { Observable, Subject } from 'rxjs';
import { REGISTRATION_APIS } from '../../constants/registration-APIs';
import { DATA_UI_EVENT_SOURCE_TYPES, EVENT_NAMES } from '../../constants/registration-constants';
import { EventsService } from '../../../shared/providers/events/events.service';
import { LISTING_ROUTES } from 'src/app/listing/constants/listing-routes';
import { MasterDataService } from 'src/app/shared/providers/master-data/master-data.service';
import { NavController } from '@ionic/angular';
import { STORAGE_KEYS } from 'src/app/shared/constants/constants';
import { take } from 'rxjs/operators';
import { StorageService } from 'src/app/shared/providers/storage/storage.service';
import { environment } from 'src/environments/environment';
import { AddressUpdate, ANFDetails } from '../../interfaces/registration-interfaces';
import { Injectable } from "@angular/core";
import { PROFILE_APIS } from '@Globals/constants/profile/apis';
import { DataService } from '@Globals/providers/data.service';

@Injectable()
export class SignupService {
  constructor(private httpService: HttpService, 
    private eventsService: EventsService, 
    private authService: AuthService,
    private masterDataService: MasterDataService,
    private navCtrl: NavController,
    private storageService: StorageService) {}

  public latLong;
  public isGpsAsked = false;
  secondsTimer: any;
  timeInSeconds = 0;
  isOtpAutoRead: boolean;
  isOtpEntered: boolean;
  updateCoordinates: Subject<boolean> = new Subject<boolean>();
  nearBySocietiesTotal:number = 0;
  public nearBySocietySubject: Subject<any> = new Subject<any>();
  anfDetails:ANFDetails = {}; 
  societyErrorMessage:string;
  towers:Array<any>= null;
  selectedTower: Subject<any> = new Subject();
  cleanTowerData: Subject<any> = new Subject();
  isShowPinAlert:boolean=false;
  getNearBySocieties(dataToSend): Observable<object> {
    return this.httpService.post(
      REGISTRATION_APIS.NEAREST_SOCIETIES,
      {...dataToSend, avoidDuplicateCalls: true}
    );
  }
  
  getSocietyTower(sId): Observable<object> {
    return this.httpService.post(REGISTRATION_APIS.SOCIETY_TOWER, {
      societyid: sId
    });
  }

  getSearchSocieties(dataToBeSent): Observable<object> {
    return this.httpService.post(REGISTRATION_APIS.SOCIETIES, dataToBeSent);
  }

  getCities():Observable<object> {
    return this.httpService.get(REGISTRATION_APIS.NON_SERVICABLE_CITY_LIST);
  }

  registerMobileNumber(mobileNumber, retry: boolean, appHash?: string): Observable<object> {
    let dataToBeSent;
    if (retry) {
      dataToBeSent = {
        retry: true,
        mobile: mobileNumber,
        udid: this.eventsService.uuid,
        retryType: 'voice',
        loaderMessage: 'Initializing call..'
      };
      this.eventsService.trackEvents(EVENT_NAMES.SIGN_UP_CALL_INSTEAD, {});
    } else {
      dataToBeSent = { mobile: mobileNumber,
        udid: this.eventsService.uuid,
        appHash };
    }
    return this.httpService.post(
      REGISTRATION_APIS.REGISTER_MOBILE_NUMBER,
      dataToBeSent
    );
  }

  verifyOTP(mobileNumber, enteredOtp): Observable<object> {
    this.eventsService.trackEvents(EVENT_NAMES.DOING_SIGN_UP_WITH_OTP, {
      mobileNumber,
       enteredOtp,
       isSocietyActive: (this.authService.userData.societyObj && this.authService.userData.societyObj.active),
       hubId: this.authService.userData.societyObj && this.authService.userData.societyObj.hubId
      });
    return this.httpService.post(REGISTRATION_APIS.VERIFY_OTP, {
      mobile: mobileNumber,
      otp: enteredOtp
    });
  }

  verifyWelcomeCode(welcomeCode): Observable<object> {
    return this.httpService.post(PROFILE_APIS.REFERRAL_STATUS, {
      referCode: welcomeCode
    });
  }

  registerUser(dataToBeSent): Observable<object> {
    return this.httpService.post(REGISTRATION_APIS.REGISTER_NEW, dataToBeSent);
  }

  updateAddress(dataToBeSent : AddressUpdate): Observable<object>  {
    return this.httpService.put(REGISTRATION_APIS.UPDATE_ADDRESS, dataToBeSent);
  }

  setDefaultCityAndTakeToHome() {
    this.authService.userData.cityObj = {
      name: environment.defaultCity.name,
      id: environment.defaultCity.id
    };
    localStorage.setItem(STORAGE_KEYS.CITY,JSON.stringify(this.authService.userData.cityObj));
    this.masterDataService.masterDataUpdateObs.next(false);
    this.masterDataService.masterDataUpdateObs
      .pipe(take(2))
      .subscribe(dataLoaded => {
        if (dataLoaded) {
          this.navCtrl.navigateRoot(
           '/' +  LISTING_ROUTES.HOME );
        }
      });
    this.masterDataService.getMasterData(true,true, false, true, false, DATA_UI_EVENT_SOURCE_TYPES.SIGN_UP_PAGE);
  }

  validateAddress(dataToBeSent) : Observable<object>  {
    return this.httpService.post(REGISTRATION_APIS.VALIDATE_ADDRESS, dataToBeSent);
  }

  addUnservicableAddress(dataToBeSent) : Observable<object>  {
    return this.httpService.post(REGISTRATION_APIS.CHANGE_ADDRESS, dataToBeSent);
  }

  loginJio(dataToBeSent): Observable<object> {
    return this.httpService.post(REGISTRATION_APIS.LOGIN_JIO, dataToBeSent);
  }
}
