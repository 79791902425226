import { CORE_ROUTES } from '@Globals/constants/listing/routes';

export const PAYMENT_ROUTES = {
  BASE: `/${CORE_ROUTES.PAYMENT}/`,
  SHOPPING_CREDITS: 'shopping-credits',
  OFFERS: 'offers',
  SUPER_CHARGE: 'super-charge',
  AUTO_DEBIT: 'auto-debit',
  PROMOCODE_LISTING: 'promocode-listing',
  TRANSACTION_HISTORY: 'transaction-history',
  PAYMENT_METHOD: 'payment-method',
  COMPLETE: 'complete',
  JIO_CASHBACK: 'jio-cashback'
};
