export const CORE_ROUTES = {
  BOARDING: "boarding",
  PAYMENT: "payment",
  LISTING: "listing",
  REGISTRATION: "registration",
  USER: "user",
  HOLIDAY_LIST:"user/holiday",
  SIGNUP_JOURNEY: "signup-journey",
  BOARDING_FROM_MENU: "boarding/menu",
  HOME_PAGE: "home",
  CART_PAGE: "cart",
  CATEGORY: "category",
  SHOPPING_CREDIT: "shopping-credit",
  PAYMENT_METHOD: "payment-method",
  MORE_PAGE: "more",
  SAVINGS: "savings/homepage",
  DISCOUNTED_PRODUCT: "discounted-product",
  COLLECTION: "collection",
  HOLIDAY:"user/holiday/calender"
};
