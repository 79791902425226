//#region Imports
//#region Angular Imports
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from '@Globals/providers/master-data.service';
import { ShoppingCreditsServices } from '@Globals/providers/shopping-credits.service';
import { DataService } from '@Globals/providers/data.service';
import { PaymentModalService } from '@Globals/providers/payment-modal/payment-modal-service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_SOURCE, PAYMENT_METHOD, PAYMENT_OPTION_TAGS, PG_WISE_JUSPAY_PAY_LOAD } from '@Globals/constants/payment/constants';
import { SheetState } from '@Globals/constants/listing/enums';
import { Utilities } from '@Globals/classes/utilities';
import { ANDROID, IOS, WEB } from '@Globals/constants/listing/constants';
//#endregion Other Imports
//#endregion Imports

@Component({
  selector: 'mbpc-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss'],
})
export class PaymentOptionsComponent extends DefaultComponent {
  @Input() shoppingCreditAmount: string;
  showPayment: boolean = false;
  showRecharge: boolean = false;
  paymentOptionTags = PAYMENT_OPTION_TAGS;
  PG_WISE_JUSPAY_PAY_LOAD = PG_WISE_JUSPAY_PAY_LOAD;
  minHeight: number = 498;
  sheetState = SheetState.Bottom;
  dockedHeight: number = 498;
  disableDrag: boolean = true;
  enableBackdropDismiss = true;
  showBackdrop = false;
  sheetStates = SheetState;
  paymentOption = {
    offers: [],
    options: this.masterDataService.paymentOptions
  }
  activePayment:string;
  paymentMethod = PAYMENT_METHOD;
  subscription:Subscription;
  closeModal = (data = null) => {
    this.modalCtrl.dismiss(data);
  };
  currentPlatform ={
    ANDROID:ANDROID,
    IOS:IOS,
    WEB:WEB
  }
  eventSourcePaymentPage = EVENT_SOURCE.PAYMENT_PAGE;
  constructor(
    private modalCtrl: ModalController,
    public masterDataService: MasterDataService,
    public shoppingCreditsServices: ShoppingCreditsServices,
    public dataService: DataService,
    public paymentModalBaseService: PaymentModalService,
    private _cd: ChangeDetectorRef
  ) {
    super();
    this.shoppingCreditsServices.updateTwidPaymentData(false, false)
   }

  mbOnInit() {
    Utilities.isApp() && this.shoppingCreditsServices.checkTwidEligibilityExpiration(this.shoppingCreditAmount, true);
    this.shoppingCreditsServices.walletRefreshRequired && this.shoppingCreditsServices.refreshWallets();
    this.subscription = this.dataService.runChangeDetForPaymentData.subscribe(() => {
      this.runChangeDetection()
    });
  }

  initiatePayment(pg) {
    this.activePayment = '';
    this.paymentModalBaseService.initiatePayment(pg, this.closeModal, this.shoppingCreditAmount);
  }

  navigateToExternalUrl() {
    this.paymentModalBaseService.openFaq(this.dataService.firebaseMasterData.faq[3]);
  }
  
  runChangeDetection() {
    this._cd.detectChanges();
  }

  mbOnDestroy(): void {
    this.subscription.unsubscribe(); 
  }
}
