//#region Imports

//#region Angular Imports
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
//#endregion Angular Imports

//#region Ionic Imports
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Platform } from '@ionic/angular';
import { HTTPResponse } from '@ionic-native/http/ngx';
import { File } from '@ionic-native/file/ngx';
//#endregion Ionic Imports

//#region Library Imports
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { Observable } from 'rxjs';
import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getBoolean, getString } from 'firebase/remote-config';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
// import { InfoPopupComponent } from '@Featu/info-popup/info-popup.component';
//#endregion Component Imports

//#region Providers Imports
import { HttpService } from '@Globals/providers/http.service';
import { DeepLinkService } from '@Globals/providers/deep-link.service';
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { ModalService } from '@Globals/providers/modal/modal.service';
import { LoggingService } from '@Globals/providers/logging.service';
import { StorageService } from '@Globals/providers/storage.service';
//#endregion Providers Imports

//#region Other Imports
import { APIs } from '@Globals/constants/listing/apis';
import { ANDROID, CITY_MODAL_CSS_CLASS, EVENT_SOURCES, FIREBASE, FIREBASE_KEYS, IOS, PAYMENT_OFFER, STORAGE_KEYS } from '@Globals/constants/listing/constants';

import { Utilities } from '@Globals/classes/utilities';
import { InfoPopUpPaymentComponent } from '@Features/payment/info-pop-up/info-pop-up.component';
import { environment } from '@Env/environment';
import { FIREBASE_REMOTE_CONFIG_CALL } from '@App/core/constants/core-constants';
//#endregion Other Imports

//#endregion Imports

let app;
if (!Utilities.isApp()) {
  app = initializeApp(environment.FIREBASE);
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  retry = 1;
  tokenStored:boolean = false;

  constructor(
    private firebaseX: FirebaseX,
    private platform: Platform,
    private httpService: HttpService,
    private deepLinkService: DeepLinkService,
    private dataService: DataService,
    private masterDataService: MasterDataService,
    private appsflyer: Appsflyer,
    public file: File,
    private modalService: ModalService,
    public sanitizer: DomSanitizer,
    private loggingService: LoggingService,
    private storageService: StorageService
  ) { }

  registerForPushNotification(callRemoteConfig: FIREBASE_REMOTE_CONFIG_CALL) {
    if (Utilities.isApp()) {
      setTimeout(() => {
        this.requestFirebaseToken();
        this.onNotificationOpen();
        this.fetchRemoteConfig(callRemoteConfig)
      }, 2000);
    } else {
      this.fetchConfigVersionForWeb(callRemoteConfig);
    }
  }

  fetchRemoteConfig(callRemoteConfig: FIREBASE_REMOTE_CONFIG_CALL) {
    this.firebaseX.fetch(FIREBASE.TIME).then(() => {
      this.activateFetchRemoteConfig(callRemoteConfig);
      this.retry = 1;
    }, (error) => {
      this.loggingService.logMessage('Firebase fetch error: ', error);
      if (++this.retry <= 3) {
        this.fetchRemoteConfig(callRemoteConfig);
      }
    });
  }
  activateFetchRemoteConfig(callRemoteConfig: FIREBASE_REMOTE_CONFIG_CALL) {
    this.firebaseX.activateFetched().then(() => {
      this.fetchRemoteValues(callRemoteConfig);
    }, (error) => {
      this.loggingService.logMessage('activateFetched error: ', error);
      // config is already activated occurs on IOS only
      if (this.platform.is(IOS) && error && error.match(FIREBASE.CONFIG_IS_ALREADY_ACTIVATED)) {
        this.fetchRemoteValues(callRemoteConfig);
      } else if (++this.retry <= 3) {
        this.activateFetchRemoteConfig(callRemoteConfig);
      }
    });
  }

  fetchRemoteValues(callRemoteConfig: FIREBASE_REMOTE_CONFIG_CALL) {
    this.retry = 1;
    if(callRemoteConfig === FIREBASE_REMOTE_CONFIG_CALL.All) {
      this.getFirebaseConfigVersion(this.retry);
      this.getFirebaseRetryTimeout(this.retry);  
    }
    this.getFirebaseMapUrl(this.retry);
  }

  getFirebaseConfigVersion(retry: number) {
    const firebaseData = localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG);
    let firebaseMasterData;
    firebaseData && (firebaseMasterData = JSON.parse(firebaseData));
    this.firebaseX.getValue(FIREBASE_KEYS.FIREBASE_CONFIG_VERSION).then((versionInfo) => {
      if (versionInfo !== firebaseMasterData?.firebaseFetchVersion) {
        const retryCount = 1;
        this.getValues(retryCount);
      } else {
        this.assignDataAndTriggerCalls(firebaseMasterData);
      }
    },(error) => {
      this.loggingService.logMessage(`Firebase getValue error: `, error);
      if (++retry <= 3) {
        this.getFirebaseConfigVersion(retry);
      }
    })
  }


  getFirebaseRetryTimeout(retry: number) {
    this.firebaseX.getValue(FIREBASE_KEYS.REFRESH_API_CONFIG).then((refreshConfig) => {
      if(refreshConfig) {
        this.httpService.retryConfig = JSON.parse(refreshConfig);
      }
    },(error) => {
      this.loggingService.logMessage(`Firebase getValue error: `, error);
      if (++retry <= 3) {
        this.getFirebaseRetryTimeout(retry);
      }
    })
  }

  getFirebaseMapUrl(retry: number) {
    this.firebaseX.getValue(FIREBASE_KEYS.MAP_KEY_RIL).then((mapLoadUrl) => {
      if(mapLoadUrl) {
        this.loadScript(mapLoadUrl, 'google-map', () => {
          // load complete complete
        });
      } else {
        this.loadScript(environment.mapLoadUrl, 'google-map', () => {
          // load complete complete
        });
      }
    },(error) => {
      this.loggingService.logMessage(`Firebase getValue error: `, error);
      if (++retry <= 3) {
        this.getFirebaseMapUrl(retry);
      } else {
        this.loadScript(environment.mapLoadUrl, 'google-map', () => {
         // load map complete
        });
      }
    })
  }

  getValues(retry: number) {
    this.firebaseX.getValue(FIREBASE_KEYS.FIREBASE_METADATA).then((data) => {
      const firebaseMasterdata = JSON.parse(data);
      this.assignDataAndTriggerCalls(firebaseMasterdata, true);
      this.dataService.firebaseMasterData.clearStorage && this.storageService.clearDeviceStorage();
      this.dataService.firebaseMasterData.jioCashBackConstruct.VISIBILITY && this.masterDataService.getJioCashback();
    }, (error) => {
      this.loggingService.logMessage(`Firebase getValue error: `, error);
      if (++retry <= 3) {
        this.getValues(retry);
      }
    })
  }

  assignDataAndTriggerCalls(firebaseMasterData, updateStorage = false) {
    this.masterDataService.masterData?.user && (this.dataService.showSeeSimilar = (firebaseMasterData.seeSimilarHubIds.indexOf(this.masterDataService.masterData?.user.hubId) > -1));
    this.initiateInfoPopup(firebaseMasterData.infoPopUp);
    if (this.masterDataService.masterData?.wallet_increase_offers && this.masterDataService.masterData?.wallet_increase_offers.length) {
       this.initiatePaymentOffer();
    } else {
      delete firebaseMasterData.paymentOffers;
    }
   this.dataService.firebaseMasterData = firebaseMasterData;
   this.memberShipDataSet();
   localStorage.setItem(STORAGE_KEYS.HOME_SCHEMA, JSON.stringify(firebaseMasterData?.homeSchema));
   updateStorage && (localStorage.setItem(STORAGE_KEYS.FIREBASE_CONFIG, JSON.stringify(this.dataService.firebaseMasterData)));
   try {
     updateStorage && (localStorage.setItem(STORAGE_KEYS.FIREBASE_CONFIG_MAX_LIMIT, JSON.stringify(this.dataService.firebaseMasterData.maxLimits)));
   } catch(e) {}
   this.dataService.knockKnock.next(true);
  }

  private requestFirebaseToken() {
    if(this.platform.is(ANDROID) && this.storageService.deviceOSVersion.versionCode >= 13) {
      this.storageService.requestNotificationPermission((data)=> {
       if(data) {
        this.getToken();
       }
      });
    } else {
      this.getToken();
    }
  }

  private getToken(){
    this.firebaseX
      .hasPermission()
      .then(permissionResponse => {
        if (permissionResponse) {
          this.fetchFirebaseToken();
        } else {
          this.firebaseX.grantPermission().then(grantPermissionResponse => {
            if (grantPermissionResponse) {
              this.fetchFirebaseToken();
            }
          });
        }
      })
      .catch(error => {
        this.loggingService.logMessage('FCM token fetch failed with error: ', error);
    });
  }

  fetchFirebaseToken() {
    this.firebaseX
      .getToken()
      .then(FCMToken => {
        if (FCMToken) {
          localStorage.setItem(STORAGE_KEYS.FIREBASE_PUSH_TOKEN, FCMToken);
            const dataToBeSent = {
              push_id: FCMToken,
              device_os: this.platform.is(ANDROID)
                ? FIREBASE.ANDROID
                : FIREBASE.IOS,
              // @ts-ignore
              udid: this.dataService.uuid,
              hideLoader: true
            };
            // appflyer uninstall token
            this.appsflyer.updateServerUninstallToken(FCMToken);
            this.registerPushNotificationToken(dataToBeSent).subscribe(
              (registerPushResponse: HTTPResponse) => {
                if (registerPushResponse?.status) {
                  localStorage.setItem(STORAGE_KEYS.FIREBASE_PUSH_TOKEN, FCMToken);
                }
              }
            );
        }
      })
      .catch(error => {
        this.loggingService.logMessage('firebase getToken error: ', error);
      });
  }

  storeNotificationToken(FCMToken: string) {
    const dataToBeSent = {
      push_id: FCMToken,
      device_os: this.platform.is(ANDROID)
        ? FIREBASE.ANDROID
        : FIREBASE.IOS,
      // @ts-ignore
      udid: this.dataService.uuid,
      hideLoader: true
    };
    // appflyer uninstall token
    this.appsflyer.updateServerUninstallToken(FCMToken);
    this.registerPushNotificationToken(dataToBeSent).subscribe(
      (registerPushResponse: HTTPResponse) => {
        if (registerPushResponse?.status) {
          this.tokenStored = true;
          localStorage.removeItem(STORAGE_KEYS.FIREBASE_PUSH_TOKEN);
        }
      }
    );
  }

  private onNotificationOpen() {
    this.firebaseX.onMessageReceived().subscribe((notificationData) => {
      let link = '';
      if (notificationData.wzrk_dl) {
        link = notificationData.wzrk_dl?.split(':/')[1];
      } else if (notificationData.link) {
        link = notificationData.link?.split(':/')[1];
      } else if (notificationData.uri) {
        link = notificationData.uri?.split(':/')[1];
      }
      this.deepLinkService.navToDeepLinkUrl(link);
    }, (error) => {
      this.loggingService.logMessage('firebase onMessageReceived: ', error);
    });
  }

  private registerPushNotificationToken(dataToBeSent): Observable<object> {
    return this.httpService.post(APIs.REGISTER_PUSH, dataToBeSent);
  }

  fetchConfigVersionForWeb(callRemoteConfig: FIREBASE_REMOTE_CONFIG_CALL) {
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings = {
      fetchTimeoutMillis: 60000,
      minimumFetchIntervalMillis: 1
    };
    fetchAndActivate(remoteConfig).then(() => {
      this.retry = 1;
      if(callRemoteConfig === FIREBASE_REMOTE_CONFIG_CALL.All) {
        const firebaseData = localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG);
        let firebaseMasterData;
        firebaseData && (firebaseMasterData = JSON.parse(firebaseData));
        // retry count
        const refreshConfig = this.getValueForWeb(remoteConfig,FIREBASE_KEYS.REFRESH_API_CONFIG, false);
        if(refreshConfig) {
          this.httpService.retryConfig = JSON.parse(refreshConfig);
        }
        // config version
        const versionInfo = this.getValueForWeb(remoteConfig,FIREBASE_KEYS.FIREBASE_CONFIG_VERSION, false);
        if (versionInfo !== firebaseMasterData?.firebaseFetchVersion) {
          const retryCount = 1;
          this.fetchConfigForWeb(remoteConfig,retryCount);
        } else {
        this.assignDataAndTriggerCalls(firebaseMasterData);
        }
      }
      const mapLoadUrl = this.getValueForWeb(remoteConfig,FIREBASE_KEYS.MAP_KEY_RIL, false);
      if(mapLoadUrl) {
        this.loadScript(environment.mapLoadUrl, 'google-map', () => {
          // loaded map api
        });
      } else {
        this.loadScript(environment.mapLoadUrl, 'google-map', () => {
          // loaded map api
        });
      }
    })
    .catch((error) => {
      this.loggingService.logMessage('Firebase fetch error: ', error);
      if (++this.retry <= 3) {
        this.fetchConfigVersionForWeb(callRemoteConfig);
      }
    });
  }

  fetchConfigForWeb(remoteConfig, retry) {
    fetchAndActivate(remoteConfig).then(() => {
     const firebaseMasterData = this.getValueForWeb(remoteConfig,FIREBASE_KEYS.FIREBASE_METADATA);
     this.assignDataAndTriggerCalls(firebaseMasterData, true);
    })
      .catch((error) => {
        this.loggingService.logMessage('Firebase fetch error: ', error);
        if (++retry <= 3) {
          this.fetchConfigForWeb(remoteConfig, retry);
        }
      });
    }

  initiateInfoPopup(infoPopUp) {
    this.dataService.firebaseMasterData.infoPopUp = infoPopUp;
    this.dataService.firebaseMasterData.infoPopUp.info = this.sanitizer.bypassSecurityTrustHtml(this.dataService.firebaseMasterData.infoPopUp.info);
    if (infoPopUp.location.home) {
      if (infoPopUp.isThrottle) {
        if (this.masterDataService.masterData?.user && this.masterDataService.masterData?.user?.throttle_status) {
          this.modalService.openModalWithCallback({
                component: InfoPopUpPaymentComponent,
                backdropDismiss: false,
                cssClass: CITY_MODAL_CSS_CLASS,
                componentProps: { source: EVENT_SOURCES.HOME }
              });
        }
      } else {
        this.modalService.openModalWithCallback({
                component: InfoPopUpPaymentComponent,
                backdropDismiss: false,
                cssClass: CITY_MODAL_CSS_CLASS,
                componentProps: { source: EVENT_SOURCES.HOME }
              });
      }
    }
  }

  initiatePaymentOffer() {
    this.dataService.firebaseMasterData.paymentOffers = Object.assign({}, PAYMENT_OFFER);
    this.masterDataService.masterData?.wallet_increase_offers.forEach(metaData => {
      this.dataService.firebaseMasterData.paymentOffers.table.rows[ 0 ].columns.push({
        title: { text: this.dataService.firebaseMasterData.paymentOffers.offerTextValue ? Utilities.replaceKeysWithValues(this.dataService.firebaseMasterData.paymentOffers.offerTextValue, metaData) : ''},
        images: { leafBanner: { image: this.dataService.firebaseMasterData.paymentOffers.image ? Utilities.replaceKeysWithValues(this.dataService.firebaseMasterData.paymentOffers.image, metaData) : ''} },
        amount: metaData.amount,
        membership_id: metaData.membership_id
      });
    });
  }

  getValueForWeb(remoteConfig, key: string, isJson = true, isBoolean = false) {
    const remoteValue = isBoolean ? getBoolean(remoteConfig, key) : getString(remoteConfig, key);
    if (remoteValue) {
      const data = isJson ? Utilities.parseJSONString(remoteValue) : remoteValue;
      return data;
    }
    return null;
  }

  memberShipDataSet(){
    if (this.masterDataService.masterData && this.masterDataService.masterData?.current_membership) {
      const membershipDynamicValues = {
        price: this.masterDataService.masterData.current_membership.cp,
        validityDays: this.masterDataService.masterData.current_membership.validity,
        startDate: this.masterDataService.masterData.current_membership.displayStartDate,
        endDate: this.masterDataService.masterData.current_membership.displayEndDate,
        daysLeft: this.masterDataService.masterData?.current_membership?.daysLeft
      };
      for (const key in this.dataService.firebaseMasterData.cartPromotionData[this.masterDataService.masterData.current_membership.state]) {
        if (this.dataService.firebaseMasterData.cartPromotionData[this.masterDataService.masterData.current_membership.state].hasOwnProperty(key)) {
          this.dataService.firebaseMasterData.cartPromotionData[this.masterDataService.masterData.current_membership.state][key] =
            Utilities.replaceKeysWithValues(this.dataService.firebaseMasterData.cartPromotionData[this.masterDataService.masterData.current_membership.state][key], membershipDynamicValues);
        }
      }
    }
  }

  public loadScript(url, id, c): void {
    if (!document.getElementById(id)) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.async = true;
      script.defer = true;
      if (c) {
        script.addEventListener('load', function (e) {
          c(null, e);
        }, false);
      }
      document.head.appendChild(script);
    }
  }

}