
//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
//#endregion Module Imports

//#region Component Imports
import { TermsConditionPopupComponent } from '@Shared/payment/terms-condition-popup/terms-condition-popup.component'; 
import { BottomSheetHeaderModule } from '@Shared/bottom-sheet-header/bottom-sheet-header.module';

//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Importsimport { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BottomSheetHeaderModule
  ],
  exports: [TermsConditionPopupComponent],
  declarations: [TermsConditionPopupComponent]
})
export class TermsConditionPopupModule extends DefaultModule { }
