//#region Imports

//#region Angular Imports
import { Component, NgZone, OnInit } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController, PopoverController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
import { AnimationItem } from 'lottie-web';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { CoachmarkPopoverComponent } from '@Shared/coachmark-popover/coachmark-popover.component';
//#endregion Component Imports

//#region Providers Imports
import { AuthService } from '@Globals/providers/auth.service';
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, STORAGE_KEYS } from '@Globals/constants/listing/constants';
import { LISTING_ROUTES } from '@Globals/constants/listing/routes';
import { HeroMediaType } from '@Globals/constants/listing/enums';
import { environment } from '@Env/environment';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-header-one',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  heroMediaType = HeroMediaType;
  element: HTMLElement;
  popover;
  takeToCalendar = true;
  showFestiveHeader = false;
  animationItem: AnimationItem;
  subscriptions: Subscription[] = [];
  bannerFilePath = environment.bannerPath;
  
  constructor( private navCtrl: NavController,
    public masterDataService: MasterDataService,
    public dataService: DataService,
    public authService: AuthService,
    private eventsService: EventsService,
    public zone: NgZone,
    public popoverController: PopoverController,) {
  }

  ngOnInit() {
    const selectedTabSubscription = this.dataService.showFestiveHeader.subscribe(showHeader => {
      if(this.showFestiveHeader !== showHeader) {
        this.showFestiveHeader = showHeader;
        this.showFestiveHeader ? this.play() : this.pause();
      }
    });
    this.subscriptions.push(selectedTabSubscription);
  }

  navigateToSearch() {
    let navigateUrl = '';
    navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.SEARCH;
    const eventArgs = {
      Clicked: true,
      Flag: 1
    };
    this.eventsService.trackEvents(EVENT_NAMES.SEARCH_BAR_CLICKED, eventArgs);
    this.navCtrl.navigateForward(navigateUrl, { animated: false });
  }

  showCoachMark() {
    if (this.dataService.calendarCoachmarkObject === null || (this.dataService.calendarCoachmarkObject &&
      !this.dataService.calendarCoachmarkObject.hasOwnProperty('discoveryCoachMark'))) {
      this.takeToCalendar = false;
      this.element = document.getElementById('cal-icon') as HTMLElement;
      this.element && this.element.click();
    }
  }

  callMethod($event) {
    if (this.takeToCalendar) {
      this.openCalendar();
    } else {
      this.presentPopover($event);
      this.takeToCalendar = true;
      setTimeout(
        () => {
          this.popoverController?.dismiss({}, '', 'calendar');
          this.updateCoachMarkInStorage();
        }, 8000);
    }
  }

  async presentPopover($event) {
    this.popover = await this.popoverController.create({
      component: CoachmarkPopoverComponent,
      cssClass: 'popover-custom',
      event: $event,
      mode: 'ios',
      translucent: true,
      id: 'calendar'
    });
    return await this.popover.present();
  }

  openCalendar() {
    this.eventsService.trackEvents(EVENT_NAMES.CALENDAR_MY_CALENDAR, { source: 'Homepage' });
    this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.RECURRING_ORDERS);
  }

  updateCoachMarkInStorage() {
    if (this.dataService.calendarCoachmarkObject === null) {
      this.dataService.calendarCoachmarkObject = {};
    }
    this.dataService.calendarCoachmarkObject.discoveryCoachMark = true;
    localStorage.setItem(STORAGE_KEYS.CALENDAR_COACHMARK_OBJECT, JSON.stringify(this.dataService.calendarCoachmarkObject));
  }


  animationCreated(animationItem: AnimationItem) {
    this.animationItem = animationItem;
  }

  pause() {
      this.animationItem && this.zone.runOutsideAngular(() => {
          this.animationItem.pause();
      });
  }

  play() {
      this.animationItem && this.zone.runOutsideAngular(() => {
          this.animationItem.play();
      });
  }

  mbOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
