import { Injectable, NgZone } from '@angular/core';
import { MasterDataService } from '../master-data/master-data.service';
import { LISTING_ROUTES } from 'src/app/listing/constants/listing-routes';
import { REGISTRATION_ROUTES } from 'src/app/registration/constants/registration-routes';
import { PROFILE_ROUTES } from 'src/app/profile/constants/profile-routes';
import { PAYMENT_ROUTES } from '@Globals/constants/payment/routes';
import { CORE_ROUTES } from 'src/app/core/constants/coreRoutes';
import { AuthService } from '../auth/auth.service';
import { NavController } from '@ionic/angular';
import { EventsService } from '../events/events.service';
import { Utilities } from '../../classes/utility/utilities';
import { EVENT_NAMES, EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION, GENERATE_CONTENT_FOR_ALERT_LAYER, MODALS, REFERRED_BY_ALERT, STORAGE_KEYS } from '../../constants/constants';
import { DataService } from '../data/data.service';
import { AlertService } from '../alert/alert.service'; 
import { SuperChargeType } from '@Globals/constants/payment/enums';
import { InAppBrowserService } from '../in_app_browser/in_app_browser.service';
import { ModalService } from '../modal/modal.service';
import { SignupService } from 'src/app/registration/providers/signup/signup.service';
import { take } from 'rxjs/operators';
import { EVENT_SOURCES } from 'src/app/listing/constants/listing-constants';
import { VideoService } from 'src/app/listing/providers/video.service';
import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links/ngx';
import { DATA_UI_EVENT_SOURCE_TYPES } from '@App/registration/constants/registration-constants';
@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {
  navigateProductIdDetails: {
    id: number;
  };
  deepLinkHandler = data => {
    let redirectUrl;
    if (data.redirect_url) {
      redirectUrl = data.redirect_url;
      if (data.referrer_code) {
        this.showReferredbyPopup(data);
      }
    } else if (data.deeplink) {
      redirectUrl = data.deeplink.split(':/')[1];
    } else if (data.deepLink) {
      redirectUrl = data.deepLink.split(':/')[1];
    }
    if (data.campaign) {
      this.eventsService.trackEvents(EVENT_NAMES.CAMPAIGN_CLICKED, {
        campaign: data.campaign
      });
    }
    this.callNavToDeepLinkUrl(redirectUrl);
  };

  showReferredbyPopup = async referredby => {
    this.authService.inviteCode = referredby.referrer_code;
    localStorage.setItem(STORAGE_KEYS.REFERRER_CODE, JSON.stringify(this.authService.inviteCode));
    const referredbyContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
      REFERRED_BY_ALERT.META_DATA,
      { name: referredby.referrer_name, society: referredby.referrer_society }
    );
    this.alertService.presentAlert(
      referredbyContent,
      REFERRED_BY_ALERT.BUTTONS,
      REFERRED_BY_ALERT.CSS_CLASS
    );
  };

  callNavToDeepLinkUrl = redirectUrl => {
    if (redirectUrl) {
      this.dataService.deepLinkOnAppLaunch.url = redirectUrl;
      this.navToDeepLinkUrl(redirectUrl, EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION);
    }
  };
  navToDeepLinkUrl = (url, source?, bannerName?, isFromContactUs?) => {
    if (!url || url === '') {
      return false;
    }
    let navigateUrl = '';
    this.dataService.checkAndCloseAllModals();
    if (/subcategory/i.test(url)) {
      const splitURl = url.split('/subcategory/');
      const routeParams = splitURl[1]?.split('/');
      const subcategoryId = parseInt(routeParams[0], 10);
      const categoryId = parseInt(routeParams[1], 10);
        source = source ? source : EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION;
        navigateUrl =
          LISTING_ROUTES.BASE + LISTING_ROUTES.SUBCATEGORY + subcategoryId + '/' + categoryId + '/' + source;
      
    } else if (/listing-category/i.test(url)) {
      const categoryId = parseInt(url.split('/listing-category/')[1], 10);
      let categories = this.masterDataService.categories?.find(item => item.id === categoryId);
      this.masterDataService.selectedCategory = categories;
      this.masterDataService.selectedSubcategoryIndex = 0;
      navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.CATEGORY + '' + categoryId;
    } else if (/category/i.test(url)) {
      const categoryId = parseInt(url.split('category/')[1], 10);
        source = source ? source : EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION;
        navigateUrl =
          LISTING_ROUTES.BASE + LISTING_ROUTES.CATEGORY + categoryId + '/' + source; 
    } else if (/similarproducts/i.test(url)) {
      const productId = url.split('similarproducts/')[1];
      navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.SIMILAR_PRODUCTS_LIST + productId;
    } else if (/categories/i.test(url)) {
      navigateUrl = '/'+ CORE_ROUTES.CATEGORY;
    } else if (/banner-product/i.test(url)) {
      const productId = { id: null };
      productId.id = url.split('/banner-product/')[1].split('/')[0];
      const bannerId =  url.split('/banner-product/')[1].split('/')[1];
      this.dataService.selectedPDPProduct.next({ 'productId':  productId.id,'bannerId': bannerId, eventData: { Source: source ? source : EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION, pdp: true}}); 
      navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.PRODUCT_DETAIL + "/" + productId.id + "/" + source ? source : EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION + "/" + bannerId; 
    } else if (/product-detail/i.test(url)) {
      this.navigateProductIdDetails = { 
        id: 1 * url.split('/product-detail/')[1].split('/')[0]
      };
      if (this.authService.isLoggedIn()) {
        this.dataService.selectedPDPProduct.next({
          productId :this.navigateProductIdDetails.id ,
          productIndex: 0,
          source: undefined,
          eventData: undefined,
          itemType: undefined,
          product:undefined,
          appliedFilter:undefined,
          basketIndex: undefined
         });
        navigateUrl = '/' + CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.PRODUCT_DETAIL + `/${this.navigateProductIdDetails.id}`+`/${1 * url.split('/product-detail/')[1].split('/')[1]}`;
      }
      else {
        navigateUrl = CORE_ROUTES.SIGNUP_JOURNEY;
      }
    } else if (/product/i.test(url)) {
      const productId = { id: null };
      productId.id = url.split('/product/')[1];
      this.dataService.selectedPDPProduct.next({ 'productId':  productId.id, eventData: { Source: source ? source : EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION, pdp: true}}); 
      navigateUrl =
        '/' + LISTING_ROUTES.HOME +
        '/' +
        MODALS.PDP +
        '/' +
        productId.id + `/${Utilities.createRandom4DigitNumber()}`;
    } else if (/inbox/i.test(url)) {
      navigateUrl =
        '/' + LISTING_ROUTES.HOME +
        '/' +
        MODALS.INBOX +
        '/' +
        `${Utilities.createRandom4DigitNumber()}`;
    }
    else if (/wallet-increase/i.test(url)) {
      navigateUrl =
        '/' + LISTING_ROUTES.HOME +
        '/' +
        MODALS.WALLET_INCREASE +
        `/${Utilities.createRandom4DigitNumber()}`;
    } else if (/invite/i.test(url)) {
      if (this.authService.isLoggedIn()) {
        navigateUrl = '/' + LISTING_ROUTES.HOME;
      } else {
        const inviteCode = url.split('/invite/')[1];
        if (inviteCode && inviteCode !== '') {
          this.authService.setPromoCodeToLocalstorage(inviteCode);
          navigateUrl =
            REGISTRATION_ROUTES.BASE +
            REGISTRATION_ROUTES.ACCEPT_INVITE +
            '/' +
            inviteCode;
        } else {
          this.goToHome();
        }
      }
    } else if (/promo-code/i.test(url)) {
      if (this.authService.isLoggedIn()) {
        navigateUrl = '/' + LISTING_ROUTES.HOME;
      } else {
        const promoCode = url.split('/promo-code/')[1];
        if (promoCode && promoCode !== '') {
          this.authService.promoCode = promoCode;
          this.authService.setPromoCodeToLocalstorage(promoCode);
          navigateUrl =
            CORE_ROUTES.SIGNUP_JOURNEY;
        } else {
          this.goToHome();
        }
      }
    } else if (/auto-login/i.test(url)) {
      if (this.authService.isLoggedIn()) {
        navigateUrl = '/' + LISTING_ROUTES.HOME +
          '/' +
          MODALS.HELP +
          `/${Utilities.createRandom4DigitNumber()}`;
      } else {
        const authKey = url.split('/auto-login/')[1];
        if (authKey && authKey !== '') {
          this.authService.setAuthKey(authKey);
          this.masterDataService.masterDataUpdateObs.next(false);
          this.masterDataService.masterDataUpdateObs
            .pipe(take(2))
            .subscribe(dataLoaded => {
              if (dataLoaded) {
                this.navCtrl.navigateRoot(
                  '/' + LISTING_ROUTES.HOME +
                  '/' +
                  MODALS.HELP +
                  `/${Utilities.createRandom4DigitNumber()}`
                );
              }
            });
          this.masterDataService.getMasterData(false, false, false, true, false, DATA_UI_EVENT_SOURCE_TYPES.DEEPLINK_SERVICE);
          return;
        }
      }
    }
    else if (/auto-debit/i.test(url)) {
      source = source ? source : EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION;
      navigateUrl = PAYMENT_ROUTES.BASE + PAYMENT_ROUTES.AUTO_DEBIT + `/${source}`;
    } else if (/jio-cashback/i.test(url)) {
      source = source ? source : EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION;
      navigateUrl = PAYMENT_ROUTES.BASE + PAYMENT_ROUTES.JIO_CASHBACK + `/${source}`;
    } else if (/search/i.test(url)) {
      const searchText = url.split('/search/')[1];
      this.dataService.searchEventSource = source;
      navigateUrl =
        LISTING_ROUTES.BASE + LISTING_ROUTES.SEARCH + '/' + searchText;
    } else if (/mbeyond/i.test(url)) {
      navigateUrl =
        LISTING_ROUTES.BASE + LISTING_ROUTES.M_BEYOND;
    } else if(/single-cell/i.test(url)){
      const collectionIds = url.split('/');
      const collectionListID = collectionIds[4];
      navigateUrl =
      `/${LISTING_ROUTES.COLLECTION_LISTING_NAV_SINGLE_URL}${collectionListID}/collection`;
    } else if (/collection-listing/i.test(url)) {
      const locationType = parseInt(url.split('collection-listing/')[1], 10);
      navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.COLLECTION_LISTING + '/' + locationType;
    } else if (/banner-collection-plp/i.test(url)) {
      const parts = url.split('/');
      const listId = parseInt(parts[3], 10);
      const bannerId = parseInt(parts[4], 10);
      const bannerIndex = parseInt(parts[5], 10);
      if (bannerIndex) {
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.BANNER_COLLECTION_PLP + '' + listId + '/' + bannerId + '/' + bannerIndex;
      } else {
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.BANNER_COLLECTION_PLP + '' + listId + '/' + bannerId;
      }
    } else if (/product-launch/i.test(url)) {
      source = source ? source : EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION;
      navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.PRODUCT_LAUNCH_PLP + '/' + 0 + '/' + source;
    } else if (/subscribe-to-save/i.test(url) && this.authService.isLoggedIn()) {
      source = source ? source : EVENT_SOURCES.HOME;
      navigateUrl =
      LISTING_ROUTES.BASE + LISTING_ROUTES.SUBSCRIBE_TO_SAVE + `/${source}`;
    } else if (/recycle-bags/i.test(url) && this.authService.isLoggedIn()) {
      navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.RECYCLE_BAGS;
    } else if (/recycle-tetra-bag/i.test(url) && this.authService.isLoggedIn()) {
      navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.RECYCLE_TETRA_BAG;
    } else if (/nps-survey/i.test(url) && this.authService.isLoggedIn()) {
      navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.NPS_SURVEY;
    } 
    else if (/list/i.test(url)) {
      if (/faq/i.test(url)) {
        const faqId = parseInt(url.split('listing/faq/')[1], 10);
        faqId && (navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.FAQ + `/${faqId}`);
      } else {
        const listIds = url.split('/');
        const listId = listIds[listIds.length - 3];
        const bannerId = listIds[listIds.length - 2];
        const bannerIndex = listIds[listIds.length - 1];
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.BANNER_COLLECTION_PLP + listId + '/' + bannerId + '/' + bannerIndex;
      }
    } else if (this.authService.isLoggedIn()) {
      if (/refer/i.test(url)) {
        if (source) {
          this.eventsService.trackEvents('Referral-Start', { Source: source });
        }
        navigateUrl = PROFILE_ROUTES.BASE + PROFILE_ROUTES.REFER;
      } else if (/ssph-confirm/i.test(url)) {
        this.dataService.showSSPHConfirmation = true;
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.YOUR_CART;
      } else if (/rate/i.test(url)) {
        navigateUrl = '/' + LISTING_ROUTES.HOME;
        // TODO: Rate your app popup
        // Product team needs to update if we need this
      } else if (/holiday/i.test(url)) {
        navigateUrl = PROFILE_ROUTES.BASE + PROFILE_ROUTES.HOLIDAY;
      } else if (/history/i.test(url)) {
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.ACCOUNT_HISTORY;
      } else if (
        /shoppingcredits/i.test(url) ||
        /topup/i.test(url) ||
        /shopping-credits/i.test(url)
      ) {
        if(!this.masterDataService.paymentOptions) {
          setTimeout(() => {
            this.navToDeepLinkUrl(url, source);
          }, 1000);
          return;
        }
        const urlArray = url.split('/');
        // if its length is equal to 3, it means it has pg_value at last
        if (urlArray.length === 3) {
          const pgValue = urlArray.pop();
          const pg = this.masterDataService.paymentOptions.find((option) => {
            return option.value === pgValue;
          });
          if (pg && pg.offers && pg.offers.length) {
            this.modalService.openPaymentOfferDetailModal({ pg });
            return;
          }
          // if we have pg and it is not having any offers going on then we are assigning it to data service variable here as well
          // for having pg name in CTA on shopping credit page and it will initiate payment directly on click of it
          pg && (this.dataService.pgFromDeepLink = pg);
        }
        navigateUrl = CORE_ROUTES.SHOPPING_CREDIT;
      } else if( /transactions/i.test(url)) {
        navigateUrl =PAYMENT_ROUTES.BASE + PAYMENT_ROUTES.TRANSACTION_HISTORY;
      } else if (
        /super-charge/i.test(url)
      ) {
        let eventName = EVENT_NAMES.SUPERCHARGE_DISCOVERY_PAGE;
        if (this.masterDataService.masterData.superChargeType === SuperChargeType.Super_Charge_Expiry) {
          eventName = EVENT_NAMES.SUPERCHARGE_EXPIRED_PAGE;
        }
        this.eventsService.trackEvents(eventName, { Source: source ? source : 'External Communications' });
        navigateUrl = PAYMENT_ROUTES.BASE + PAYMENT_ROUTES.SUPER_CHARGE + `/${this.masterDataService.masterData.superChargeType}`;
      } else if (/todayorder/i.test(url)) {
        const orderId = url.split('/todayorder/')[1];
        navigateUrl =
          LISTING_ROUTES.BASE + LISTING_ROUTES.ACCOUNT_HISTORY_DETAILS;
        if (orderId) {
          navigateUrl += '/' + orderId;
        }
      } else if (
        /accounthistorydetails/i.test(url) ||
        /account-history-details/i.test(url)
      ) {
        navigateUrl =
          LISTING_ROUTES.BASE + LISTING_ROUTES.ACCOUNT_HISTORY_DETAILS; // check for order id
      } else if (/frequently/i.test(url)) {
        // Product team needs to update if we need this
      } else if (/shortlists/i.test(url)) {
        // Product team needs to update if we need this
      } else if (
        /yourprofile/i.test(url) ||
        /savedaddress/i.test(url) ||
        /setting/i.test(url)
      ) {
        navigateUrl = PROFILE_ROUTES.BASE + PROFILE_ROUTES.SETTING;
      } else if (/change-password/i.test(url)) {
        navigateUrl = PROFILE_ROUTES.BASE + PROFILE_ROUTES.CHANGE_PASSWORD;
      } else if (/mb-pass/i.test(url)) {
        source = source ? source : EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION;
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.MB_PASS + `/${source}`;
      } else if (/recurring/i.test(url)) {
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.RECURRING_ORDERS;
      } else if (/yourcart/i.test(url) || /your-cart/i.test(url)) {
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.YOUR_CART;
      } else if (/cart/i.test(url)) {
        navigateUrl = '/' + CORE_ROUTES.CART_PAGE;
      }else if (/savings/i.test(url)) {
        navigateUrl = '/' + CORE_ROUTES.SAVINGS;
      }else if (/more/i.test(url)) {
        navigateUrl = '/' + CORE_ROUTES.MORE_PAGE;
      } else if (/selfhelp/i.test(url)) {
        navigateUrl = PROFILE_ROUTES.BASE + PROFILE_ROUTES.CONTACT;
      } else if (/issue-self-help/i.test(url)) {
        // please send issueId as parameter for this route
        const pathArray = url.split('/');
        this.dataService.openSelfHelpUrl = `&issue_id=${pathArray[pathArray.length - 1]}`;
        navigateUrl = PROFILE_ROUTES.BASE + PROFILE_ROUTES.CONTACT;
      } else if (/help/i.test(url)) {
        // please send isBot as parameter for this route
        this.dataService.externalUrl.parameters = true;
        navigateUrl = PROFILE_ROUTES.BASE + PROFILE_ROUTES.CONTACT;
      } else if (/browser/i.test(url)) {
        // Opening URL in external browser.
        const externalUrlData = url.match(/url=(.*)/);
        if (externalUrlData.length === 2) {
          this.inAppBrowserService.create(externalUrlData[1], false, true, '_system');
          navigateUrl = null;
        }
      } else if (/external/i.test(url)) {
        // matches the url for (url And title) in the url string {Group1 will be Url and Group3 will be title}.
        const externalUrlData = url.match(/url=([^&]*)(&title=([^&]*))?(&iframe=(.*))?/);
        if (externalUrlData && externalUrlData[5] === 'false') {
          this.inAppBrowserService.create(externalUrlData[1], false, true);
          navigateUrl = null;
        } else {
          this.dataService.externalUrl.url = (externalUrlData && externalUrlData[1]) || url;
          this.dataService.externalUrl.title = bannerName || externalUrlData[3];
          navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.EXTERNAL;
        }
      } else if (/collection-video/i.test(url)) {
        this.videoService.selectedVideoCollectionData.next(null);
        const collectionIds = url.split('/');
        const collectionListID = collectionIds[collectionIds.length - 3];
        const collectionLeafId = collectionIds[collectionIds.length - 2];
        const collectionID = collectionIds[collectionIds.length - 1];
        source = source ? source : EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION;
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.COLLECTION_VIDEO + `${collectionListID}/${collectionLeafId}/${collectionID}/${source}`;
      } else if (/collection/i.test(url)) {
        const collectionIds = url.split('/');
        const collectionListID = collectionIds[collectionIds.length - 2];
        const collectionLeafId = collectionIds[collectionIds.length - 1];
        source = source ? source : EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION;
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.COLLECTIONPLP + collectionListID + '/' + collectionLeafId + '/' + source;
      } else if (/membership-plans/i.test(url)) {
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.MEMBERSHIP;
      } else if (/membership/i.test(url)) {
        navigateUrl =
        CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.MEMBERSHIP_DETAILS_POPUP;
      } else if (/home/i.test(url)) {
        navigateUrl = '/' + LISTING_ROUTES.HOME;
      }
      else if (/geolocation/i.test(url)) {
        navigateUrl =
        REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.GEOLOCATION + '/' + 0;
        this.dataService.isBackButtonPressed = isFromContactUs ?  true : false;
        this.dataService.changeAddressPrepare();
      } 
    } else if (!this.authService.isLoggedIn()) {
      if (/register/i.test(url)) {
        navigateUrl =
          CORE_ROUTES.BOARDING
      } else if (/login/i.test(url)) {
        navigateUrl = CORE_ROUTES.BOARDING;
      }
    } else if (
      (/refer/i.test(url) ||
        /holidays/i.test(url) ||
        /history/i.test(url) ||
        /frequently/i.test(url) ||
        /shortlists/i.test(url) ||
        /topup/i.test(url) ||
        /yourcart/i.test(url) ||
        /cart/i.test(url) ||
        /more/i.test(url) ||
        /recurring/i.test(url) ||
        /holidayslist/i.test(url) ||
        /shoppingcredits/i.test(url) ||
        /todayorder/i.test(url)) &&
      !this.authService.isLoggedIn
    ) {
      this.goToHome();
    } else if (/boarding/i.test(url)) {
      navigateUrl = CORE_ROUTES.BOARDING;
    } else if (/location/i.test(url)) {
      navigateUrl =
        CORE_ROUTES.BOARDING;
    }
    else {
      if (!this.authService.isLoggedIn()) {
        navigateUrl = CORE_ROUTES.BOARDING;
      } else {
        navigateUrl = '/' + LISTING_ROUTES.HOME;
      }
    }
    if (navigateUrl) {
      this.zone.run(() => {
        if (!Utilities.isEmpty(this.masterDataService.masterData)) {
          this.navCtrl.navigateForward(navigateUrl);
        } else if(Utilities.isEmpty(this.masterDataService.masterData)) {
          setTimeout(() => {
            this.navToDeepLinkUrl(url, source);
          }, 1000);
          return;
        } else {
          this.dataService.deepLinkOnAppLaunch.dismissLoaderTimer = navigateUrl === ('/' + LISTING_ROUTES.HOME) ? 0 : 1000;
        }
      });
    }
  };

  constructor(
    private eventsService: EventsService,
    private navCtrl: NavController,
    private authService: AuthService,
    private masterDataService: MasterDataService,
    private dataService: DataService,
    private videoService: VideoService,
    private zone: NgZone,
    private alertService: AlertService,
    private inAppBrowserService: InAppBrowserService,
    private modalService: ModalService,
    private signupService: SignupService
  ) { }

  goToHome() {
    if (this.authService.userData.city_id) {
      this.navCtrl.navigateForward('/' + LISTING_ROUTES.HOME);
    } else {
      this.signupService.setDefaultCityAndTakeToHome();
    }
  }
}
