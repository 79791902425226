//#region Imports

//#region Angular Imports
import { Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { ModalService } from '@Globals/providers/modal.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
import { NET_BANKING_BANKS, FREQUENT_NET_BANKING_BANKS } from '@Globals/constants/listing/constants';
import { MODAL_IDS,PG_WISE_JUSPAY_PAY_LOAD} from '@Globals/constants/payment/constants';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-net-banking',
  templateUrl: './net-banking.component.html',
  styleUrls: ['./net-banking.component.scss'],
})
export class NetBankingComponent {
  @Input() pg: any;
  searchText = '';
  netBankingBanks = NET_BANKING_BANKS;
  frequentNetBankingBanks = FREQUENT_NET_BANKING_BANKS;

  constructor(
    private modalCtrl: ModalController,
    public modalService: ModalService,
    public masterDataService: MasterDataService
  ) { }

  submit(selectedBank: string) {
    PG_WISE_JUSPAY_PAY_LOAD[this.pg.value].webPayload.payment_method = selectedBank;
    PG_WISE_JUSPAY_PAY_LOAD[this.pg.value].payload.paymentMethod = selectedBank;
    this.modalCtrl.dismiss(this.pg.value, '', MODAL_IDS.PAYMENT_TYPE);
  }

  closeModal() {
    this.modalCtrl.dismiss(null, '', MODAL_IDS.PAYMENT_TYPE);
  }
}