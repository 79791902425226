import { RecRecommendationText } from "@App/shared/interfaces/interfaces";

export const EVENT_NAMES = {
  HOME: 'home',
  CATEGORY: 'category',
  BANNER_VIEWED: 'Banner viewed',
  ADD_TO_BASKET: 'Add to Basket',
  REMOVE_FROM_BASKET: 'Remove From basket',
  ADD_BASKET_ERROR: 'Add_Basket_Error',
  SUBCATEGORY_VIEWED: 'Subcategory viewed',
  CATEGORY_VIEWED: 'Category viewed',
  CATEGORY_IMPRESSION: 'category_impression',
  SUBCATEGORY_IMPRESSION: 'sub_category_impression',
  HOME_TAB: 'Home tab',
  CATEGORIES_TAB: 'Categories tab',
  BASKET_TAB: 'Basket tab',
  SHOPPING_CREDITS: 'Shopping Credits',
  HAMBURGER_OPEN: 'Hamburger Open',
  RECURRING_ICON_PLP_CLICK: 'product_popup_recurring_click',
  RECURRING_EDIT: 'Edit Recurring',
  RECURRING_SET: 'Set Recurring',
  RECURRING_SHOPPING_CREDITS: 'Shopping Credits',
  SEARCHED: 'Searched',
  SEARCH_BAR_CLICKED: 'searchBarClicked',
  VIEW_ALL_RECOMMENDATION: 'View All Recommendation',
  BASKET_REST_OF_THE_WEEK: 'Basket - Rest of the week',
  BASKET_TOMORROW: 'Basket Viewed',
  PRODUCT_VIEWED: 'Product viewed',
  CONTENT_VIEW: 'af_content_view',
  PAYMENT_SUCCESS: 'Payment Success',
  PAYMENT_START: 'Payment Start',
  PAYMENT_FAILURE: 'Payment Failure',
  VIEW_SIMILAR: 'View Similar',
  VIEW_RELATED: 'View Related',
  VIEW_SIMILAR_REQUEST: 'View Similar - Request',
  PRODUCT: 'product',
  SCHEDULE: 'schedule',
  WELCOME_POPUP_VIEWED: 'Welcome Popup Viewed',
  RATE_US: 'Rateus',
  IN_APP_NOTIFICATION: 'In-App Notification',
  TODAYS_ORDER: 'Today\'s Order',
  BASKET_VOUCHERTAB: 'Basket_Vouchertab',
  COLLECTION_VIEW: 'Collection view',
  BUY_MEMBERSHIP_FAILURE: 'Buy membership failure',
  BUY_MEMBERSHIP: 'Buy membership',
  FEE_DETAILS: 'Fee Details',
  BANNER_IMPRESSION: 'banner_impression',
  MB30_DETAILS_PAGE_DISCOVERY: 'MB30 Details Page - Discovery',
  MB30_DETAILS_PAGE_USAGE: 'MB30 Details Page - Usage',
  SIMILAR_PRODUCTS_LIST_VIEWED: 'Similar Products List Viewed',
  FREE_SUBSCRIPTION: 'Free Subscription',
  FILTER_VIEW: 'Filter View',
  VARIANT_VIEWED: 'Variant Viewed',
  CATEGORY_WIDGET: 'Category Widget',
  COLLECTION_IMPRESSION: 'collection_impression',
  RECURRING_RECOMMENDED_IMPRESSION: 'recurring_recommended_impression',
  PRODUCT_LAUNCH_IMPRESSION: 'product_launch_impression', 
  BRAND_LAUNCH_IMPRESSION: 'brand_launch_impression',
  MB_PASS_IMPRESSION: 'mb_pass_impression',
  SAMPLE_OFFER_IMPRESSION: 'sample_offer_impression',
  COLLECTION_VIWED: 'collection_view',
  ONE_PAGER: 'one_pager',
  BRAND_LAUNCH: 'brand_launch',
  PRODUCT_LAUNCH: 'product_launch', 
  SAMPLE_OFFER: 'sample_offer',
  NEWLY_ADDED_ITEMS: 'newly_added_items', 
  BRAND_LAUNCH_CLICKED: 'brand_launch_click',
  SAMPLE_OFFER_CLICKED: 'sample_offer_click',
  SSPH_PAGE_VIEWED: 'SSPH Page Viewed',
  SSPH_CHARGES_MODAL_VIEWED: 'SSPH Charges Modal Viewed',
  SSPH_CHARGES_OPT_IN_MODAL_VIEWED: 'SSPH Charges Opt-In Modal Viewed',
  SSPH_CHARGES_MODAL_CLOSED: 'SSPH Charges Modal Closed',
  SSPH_CHARGES_OPT_IN_MODAL_CLOSED: 'SSPH Charges Opt-In Modal Closed',
  REMOVED_CANCELLED_ITEMS: 'Removed Cancelled Items',
  RECURRING_NEW: 'Recurring - New',
  RECURRING_EXISTING: 'Recurring - Existing',
  RECURRING_SETTING: 'Recurring -Settings',
  PDP: 'PDP',
  NOTIFY_ME: 'Notify Me',
  SHARING_START: 'Sharing Start',
  SHARING_COMPLETE: 'Sharing Complete',
  COLLECTION_FEATURE_VIDEO_VIEWED: 'Viewed Collection Featured Video',
  COLLECTION_FULL_VIDEO_VIEWED: 'Viewed Collection Fullscreen Video',
  SWIPE_COLLECTION: 'Swipe Collection',
  PLP_SCROLLED: 'PLP Scrolled',
  PDP_IMAGE_CLICKED: 'PDP Image Clicked',
  HOME_SCROLLED: 'Home Scrolled',
  CREDIT_LIMIT_START: 'Credit limit start',
  CREDIT_LIMIT_EXHAUSTED: 'Credit limit exhausted',
  SAMPLE_ADDED: 'Sample added',
  SAMPLE_REMOVED: 'Sample removed',
  SAMPLE_NUDGE_CLICKED: 'Sample nudge clicked',
  FILTER_APPLIED: 'Filter Applied',
  HOME_RECURRING_RECOMMENDATION: 'Home recurring recommendation viewed',
  RECURRING_RECOMMENDED: 'recurring_recommended',
  CART: 'cart',
  MBEYOND_STRIP_CLICKED: 'Mbeyond Strip Clicked',
  MEMBERSHIP_CHECKBOX_VALUE: 'Membership Checkbox Value',
  CALENDAR_REMOVE_START: 'Calendar - Remove Start',
  CALENDAR_REMOVE_REASONS: 'Calendar - Remove Reasons',
  CALENDAR_REMOVE_CONFIRM: 'Calendar - Remove Confirm',
  CALENDAR_PAUSE_START: 'Calendar - Pause Start',
  CALENDAR_PAUSE_START_DATE: 'Calendar - Pause Start date',
  CALENDAR_PAUSE_END_DATE: 'Calendar - Pause End Date',
  CALENDAR_PAUSE_COMPLETE: 'Calendar - Pause Complete',
  CALENDAR_COMPLETE: 'Calendar - Complete',
  CALENDAR_EDIT_COMPLETE: 'Calendar - Edit Complete',
  CALENDAR_PLAN_CHANGE: 'Calendar - Plan Change',
  CALENDAR_EDIT: 'Calendar - Edit',
  CALENDAR_WEEK_DAYS_SELECTION: 'Calendar - Weekly days Selection',
  CALENDAR_MONTHLY_DATE_SELECTION: 'Calendar - Monthly date selection',
  CALENDAR_ONCE: 'Calendar - Once',
  CALENDAR_DELIVERY_STRT_DATE: 'Calendar - Delivery Start Date',
  CALENDAR_START: 'Calendar - Start/Plan type',
  CALENDAR_MY_CALENDAR: 'Calendar - My Calendar',
  CALENDAR_EXPLORE: 'Calendar - Explore',
  SPONSORED_SEARCH_VIEWED: 'Sponsored search viewed',
  CALENDAR_EDIT_QUANTITY: 'Calendar - Edit quantity',
  CALENDAE_PLAN_UPDATED: 'Calendar - Plan Updated',
  SELF_HELP_OPEN: 'Self Help Open',
  NATIVE_APP_RATING_POPUP_CLICKED: 'Native App Rating Popup Clicked',
  NATIVE_APP_RATING_POPUP_ERROR: 'Native App Rating Popup Error',
  SUBSCRIBE_TO_SAVE_PAGE_VIEW: 'Subscribe To Save Page View',
  FLAT_CASHBACK_TAB_VIEW: 'Flat Cashaback Tab View',
  PERCENT_CASHBACK_TAB_VIEW: 'Percent Cashaback Tab View',
  SUBSCRIBE_AND_SAVE_CATEGORY_SELECT: 'Subscribe And Save Category Select',
  RECURRING_CAMPAIGN_DETAILS_VIEW: 'Recurring Campaign Details View',
  RECURRING_CAMPAIGN_TERMS_AND_CONDITION_VIEW: 'Recurring Campaign Terms And Condition View',
  RECURRING_CAMPAIGN_PERCENT_TYPE_BASKET_REMOVAL: 'Recurring Campaign Percent Type Basket Removal',
  RECURRING_CAMPAIGN_PERCENT_TYPE_BASKET_ADD: 'Recurring Campaign Percent Type Basket Add',
  RECURRING_CAMPAIGN_NUDGE: 'Recurring Campaign Nudge View',
  RECURRING_CAMPAIGN_NUDGE_CLICKED: 'Recurring Campaign Nudge View Clicked',
  RECURRING_CAMPAIGN_SUCCESS_SCREEN: 'Recurring Campaign Success Screen',
  PERCENT_TYPE_PRODUCT_SUBSCRIBED: 'Percent Type Product Subscribed',
  FLAT_TYPE_PRODUCT_SUBSCRIBED: 'Flat Type Product Subscribed',
  RECURRING_CAMPAIGN_SUBSCRIBE_ICON_CLICK: 'Recurring Campaign Subscribed Icon Click',
  RECURRING_CAMPAIGN_SAVING_PAGE_VIEW: 'Recurring Campaign Saving Page View',
  RECYCLE_BAG_EMPTY_BASKET_VIEW:'Recycle Bag Empty Basket View',
  RECYCLE_BAG_SAME_DAY_REQUEST_ALREADY_RAISED:'Recycle Bag Same Day Request Already Raised',
  RECYCLE_BAG_SHOW_BASKET_REMOVAL_BOTTOM_SHEET:'Recycle Bag Show Basket Removal Bottom Sheet',
  RECYCLE_BAG_BASKET_REMOVAL_CONFIRMED:'Recycle Bag Basket Removal Confirmed',
  UPDATE_ADDRESS: 'Click on update address',
  HOLIDAY_CLICKED: 'Holiday Clicked',
  MY_ORDERS_CLICKED: 'My Orders Clicked',
  TRANSACTION_CLICKED: 'Transaction Clicked',
  SHOPPING_CREDIT_MENU: 'Shopping Credits',
  REFERRAL_START: 'Referral-Start',
  OFFER_CLICKED: 'Offer Clicked',
  ACCOUNT_HISTORY: 'Account History',
  MBEYOND_CLICKED: 'Mbeyond Clicked',
  COLLECTION_LSITING: 'Collection Listing',
  MY_PROFILE: 'My Profile',
  CONTACT_US: 'Self Help Open',
  CLICK_ON_RECYCLE_BAGS_MENU: 'Click on Recycle Bags Menu',
  PAYMENT_OFFERS: 'Payment Offers',
  VIEW_ALL_OFFER: 'view_all_offer',
  PROMO_CODE_APPLY: 'Promo code applied',
  PROMO_CODE_SUCCESS: 'Promo code success',
  GENDER_DETAILS: 'Gender Details',
  FOOD_PREFERNECE_DETAILS: 'Food Prefernece Details',
  MARITAL_STATUS_DETAILS: 'Marital Status Details',
  FAMILY_MEMBER_COUNT: 'Family Member count',
  ANNIVERSARY_DETAILS: 'Anniversary Details',
  BIRTHDAY_DETAILS: 'Birthday Details',
  CHARACTERISTICS_DETAILS: 'Chracteristics Details',
  EDIT_PROFILE_DETAILS: 'Edit Profile details',
  SMS_TOGGLE: 'SMS toggle',
  EMAIL_TOGGLE: 'Email toggle',
  FEEDBACK_SUBMIT: 'Feedback submit',
  SETTING: 'setting',
  UPDATE_PROFILE_PAGE: 'update_profile_page',
  PERSONALIZED_DETAILS: 'Personalized details',
  REFERRAL_ADD_MONEY_POP_UP: 'Referral AddMoneyPopup',
  SHOPPING_CREDIT_REFERRAL: 'Shopping Credits',
  ADD_MONEY_CLICK: 'Add money clicked',
  MINIMUM_TOPUP: 'Minimum top up required msg viewed',
  MAXIMUM_TOPUP: 'Maximum top up msg viewed',
  ADD_MONEY_SCREEN_VISITS: 'Add money page visited',
  SUPERCHARGE_PAYMENT_MODE: 'SuperCharge Payment Mode',
  SUPERCHARGE_PAYMENT_START: 'SuperCharge Payment Start',
  CASH_COLLECTION_START: 'Cash Collection Start',
  CASH_COLLECTION_COMPLETE: 'Cash Collection Complete',
  PROMO_CODE_VIEW: 'Promo code viewed',
  DENOMINATION_CLICKED: 'Click on denomination buttons',
  INFO_POP_UP_VIEW: 'Info Pop Up View',
  INFO_POP_CLICKED: 'Info Pop Up Clicked',
  SAVE_TO_LOCKER: 'Save To Locker',
  INVITE_CODE_POPUP_VIEWED: 'Invite Code - Popup Viewed',
  PROMO_CODE_COPY: 'Promo code copy',
  SIGN_UP_CITY: 'Sign up - City',
  A2B_UNPAID: 'af_add_to_cart',
  SUBSCRIBE_UNPAID: 'Subscribe & save clicked unpaid',
  MB_PASS_VIEWED: 'MB Pass Viewed',
  MB_PASS_BUY_START: 'MB Pass Buy Start',
  MB_PASS_BUY_CONFIRMATION: 'MB Pass Buy Confirmation',
  MB_PASS_FAQS: 'MB Pass FAQs',
  MB_PASS_TNC: 'MB Pass TnC',
  REFERRER_STATUS:'Referrer_status',
  REMOVE_MEMBERSHIP:'Remove membership',
  YES_REMOVE_MEMBERSHIP:'Yes remove membership',
  NO_KEEP_MY_MEMBERSHIP:'No keep my membership',
  ADD_MEMBERSHIP:'Add membership',
  SAVING_STRIP_CLICK:'Saving strip click',
  SAVING_LANDING_PAGE_VISIT:'Saving landing page visit',
  MONTH_CARD_CLICK:'Month card click',
  SEE_PRVIOUS_MONTHS_CLICK:'See previous months click',
  S2S_CARD_VIEW:'S2S card view',
  TOPUP_OFFER_VIEW:'Topup offer view',
  VALUE_PASS_VIEW:'Value pass view',
  S2S_CARD_CLICK:'S2s card click',
  TOPUP_OFFER_CLICK:'Topup offer click',
  VALUE_PASS_CLICK:'Value pass click',
  SAVING_STRIP_DISPLAYED: 'Saving strip displayed',
  REPEAT_ORDER:'Repeat Order',
  ORDER_DETAILS:'Order Details',
  CROSSED_PRODUCT_LIST:'Crossed product list',
  CUSTOMER_INVOICE_DOWNLOADED:'Customer invoice downloaded',
  PRODUCT_VARIANT_CLICKED: 'Product_Variant_Clicked',
  SEARCH_SUGGESTION_CLICK:'Search Suggestion Click',
  LOGIN_DATA_UI_SUB: 'Login data ui subscription',
  SEARCH_AS_YOU_TYPE: 'Search as you type',
  ORDER_INVOICE_DOWNLOADED:"Order Invoice Downloaded"
};

export const APP_INBOX_STYLE_CONFIG = {
  NAVBAR_TITLE_COLOR: '#000000',
  NAVBAR_TITLE: 'Inbox',
  NAVBAR_COLOR: '#FFFFFF',
  BACKGROUND_COLOR: '#F2F2F2'
};

export const EVENT_SOURCES = {
  PRODUCT_LISTING: 'productListing',
  YOUR_CART: 'yourcart',
  HOME: 'home',
  SAMPLE_OFFER_HOME: 'sample_offer_home',
  SAMPLE_OFFER_CATEGORY: 'sample_offer_category',
  SAMPLE_OFFER_CART: 'sample_offer_cart',
  HOMEPAGE: 'homepage',
  BANNERS: 'banner',
  CATEGORY: 'category',
  CART_VIEWED: 'Cart - Viewed',
  VIEW_ALL: 'view all',
  PAYMENT_POPUP_VIEWED: 'Payment_popup_viewed',
  INR: 'INR',
  YFP: 'Your_Frequent_Products',
  NEW_USER: 'New User',
  EXISTING_USER: 'Existing User',
  HOMESTRIP: 'Homestrip',
  A2B: 'A2B',
  SIMILAR_PRODUCTS_LIST: 'similar_products_list',
  COLLECTION: 'collection',
  IMPULSE_BUY: 'Impulse_buy',
  ADD_TO_BASKET: 'Add To Basket',
  SET_RECURRING: 'Set Recurring',
  SSPH_PAGE: 'SSPH Page',
  HAMBURGER: 'Hamburger',
  ADD_NEW_RECURRING: 'Add_new_recurring',
  CREDIT_LIMIT_STARTED: 'Credit limit started',
  CREDIT_LIMIT_EXHAUSTED: 'Credit limit exceeded',
  SEARCH: 'Search',
  COLLECTION_LISTING: 'Collection_Listing',
  MB_PASS: 'MBPass',
  RECURRING: 'Recurring',
  MBEYOND_COLLECTION_LISTING: 'MBeyond Collection Listing',
  PDP: 'PDP',
  HOME_RECURRING_WIDGET: 'Home Recurring',
  MY_CALENDAR: 'My_Calendar',
  CALENDAR_EXPLORE: 'Calendar - Explore',
  MBEYOND_ALL_COLLECTION: 'MBeyond ALL Collection',
  INVITE_MODAL: 'Invite modal',
  CALENDAR: 'calender',
  CALENDAR_SUCCESS_PAGE: 'Calendar Success Page',
  SUBSCRIBE_TO_SAVE_PAGE: 'subscribe to save page',
  MENU: 'Menu',
  MOBILE_SCREEN: 'Mobile number page',
  CHANGE_ADDRESS: 'Change address',
  SIGN_UP_INTRO: 'Sign up splash screens',
  FREE_SUBSCRIPTION: 'FreeSubscription',
  SHOPPING_CREDITS: 'Shopping Credits',
  ATB_ERROR: 'ATB error',
  BOTTOM_NAV: 'Bottom Nav',
  MEMBERSHIP_BENEFIT_PAGE: 'Membership Benefit Page',
  ADDRESS_REJECTED_STRIP:'Address rejected strip',
  SAVINGS:'Savings',
  ORDER_DETAILS:'My Order',
  VARIANT: "variant",
  MY_ORDER:"My Order"
};

export const EVENT_ARGS = {
  NORMAL: 'normal',
  SIMILAR: 'substitute',
  RELATED: 'related',
  ADD: 'add',
  SUBTRACT: 'subtract',
  SIMILAR_PRODUCT: 'similar_products',
  SCHEDULED: 'scheduled',
  PRE_ORDER: 'preOrder',
  HIGHLIGHTED: 'highlighted',
  VARIANT: 'Variant',
  SINGLE_BRAND: 'Single brand',
  MULTIPLE_BRAND_MEDIUM: 'Multiple brand medium',
  MULTIPLE_BRAND_HIGH: 'Multiple brand high',
  SUGGESTED: 'suggested',
  RECOMMENDED: 'recommended',
  DEALS_OF_THE_DAY:'Deals of the day'
};

export const ITEM_TYPE_FOR_BUNDLE_PRODUCT = {
  1: 'combined_bundle',
  2: 'individual_bundle'
};

export const PRE_ORDER_POST_ORDER_END_DATE_ERROR = 'Pre Order Quantity can not be changed after Order End Date';

export const RATING_CONTENT = {
  PLAYSTORE_URL: 'com.milkbasket.app',
  APPSTORE_URL: 'https://itunes.apple.com/us/app/milkbasket/id1088165798',
  RATE_US_ON_PLAYSTORE: 'Rate us on Playstore',
  RATE_US_ON_APPSTORE: 'Rate us on AppStore'
};

export const BUNDLE_VIEW = 2;

export const ORDER_TYPE = {
  ORDER: 'order',
  ORDER_FEE: 'order_fee',
  ORDER_REFUND: 'order_refund'
};
export const VIDEO_TYPE = {
  FULL_SCREEN: 'Full Screen',
  HALF_VIEW: 'Half View'
};
export const COLLECTION_PAGE_URL_COMPONENT = 'frequent_order';
export const NUMBER_OF_ITEMS_TO_DISPLAY = 10;
export const NUMBER_OF_ITEMS_TO_DISPLAY_INC = 5;
export const DEFAULT_BANNER_MAX_LIMIT = 12;
export const SEARCH_PLACEHOLDER = 'Search for your product';

export const MAX_QUANTITY_REACHED_ALERT = {
  BUTTONS: ['OK'],
  META_DATA: {
    HEADER_TEXT: 'Sorry!',
    MESSAGE:
      'You have reached max quantity.'
  },
  CSS_CLASS: 'alert-custom'
};

export const VENDOR_OFF = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    },
    OKAY: { text: 'Add', handler: null }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    HEADER_TEXT: 'Oops!',
    MESSAGE:
      'Good choice! But its not available for {errorDate}! Please proceed for {nextDate} dalivery!'
  },
  CSS_CLASS: 'alert-custom'
};

export const TIME_RECHECK_GET_CONFIRMATION = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    },
    OKAY: { text: 'Add', handler: null }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    HEADER_TEXT: 'Oops!',
    MESSAGE: ''
  },
  OKAY_TEXTS: {
    remove: 'Remove',
    add: 'Add'
  },
  MESSAGES: {
    add: 'Oops! It\'s past midnight. The item would be added in your tomorrow morning\'s ({date}) basket.',
    remove: 'Time Alert! It\'s past midnight. You are modifying your tomorrow morning\'s ({date}) basket.'
  },
  CSS_CLASS: 'alert-custom'
};

export const EXTRA_FEE_DETAILS = {
  HEADER_TEXT: 'Select your favorite color',
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    },
    OK: {
      text: 'Remove',
      role: 'ok',
      handler: null,
    //  cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    cssClass: 'mem-remove'
    },
    OKAY: {
      text: 'Add membership',
      role: 'okay',
      handler: null,
     // cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    },
    CONFIRM: {
      text: 'OK Got it!',
      role: 'okay',
      handler: null,
     // cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
     cssClass: 'ok-button'
    }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    HEADER_TEXT: 'Packaging & Handling Fee',
    MESSAGE: '',
    NOTE: 'Note: We\'re charging packaging and handling fee to ensure quality in everything we send out to you. Enjoy zero fee till 14th October 2019.'
  },
  CSS_CLASS: 'alert-custom no-button max-height-auto basket-saving'
};

export const SCHEDULE_SUCCESS_ALERT = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    },
    OKAY: { text: 'Got It', handler: null }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    HEADER_TEXT: '',
    MESSAGE:
      '<b>Order placed successfully!</b><br/>Your order will be delivered on<br/><b>{orderDate}</b>'
  },
  CSS_CLASS: 'alert-custom'
};

export const NPS_SUCCESS = {
  BUTTONS: {
    OKAY: { text: 'Ok Got it!', handler: null,cssClass: 'nps-button' }
  },
  META_DATA: {
    HEADER_TEXT: '',
    MESSAGE:
      `<ion-item lines="none" class="nps-thanks w-100">
      <ion-label class="ion-text-center ion-text-wrap">
         <ion-img alt="Feedback success icon" src="assets/img/successPopup_nps.svg"></ion-img>
         <h2>Thank you for the Feedback!</h2>
         <p>Your Response has been recorded.</p>
      </ion-label>
   </ion-item>`
  },
  CSS_CLASS: 'alert-custom'
};

export const PAST_CUT_OFF_TIME_CONFIRMATION = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    },
    OKAY: { text: 'Add', handler: null }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    HEADER_TEXT: 'Past Cut- off time!',
    MESSAGE:
      'Your order will be delivered on <b>{date}</b>.<br> The current price may vary.'
  },
  CSS_CLASS: 'alert-custom'
};
export const NON_MBEYOND_USER_REDIRECTION = {
  BUTTONS: {
    OKAY: { text: 'Learn about mBeyond', handler: null }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: false,
    HEADER_TEXT: '',
    MESSAGE: ''
  }
};
export const HOLIDAY_CUT_OFF_TIME_CONFIRMATION = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    },
    OKAY: { text: 'Add', handler: null }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    HEADER_TEXT: 'Holiday!',
    MESSAGE:
      ''
  },
  CSS_CLASS: 'alert-custom'
};
export const HOLIDAY_BASKET_TOAST_MESSAGE = {
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: false,
    HEADER_TEXT: '',
    MESSAGE: ''
  },
  CSS_CLASS: 'alert-custom vertical-alert-bar'
};
export const LOGIN_SIGNUP_CONFIRMATION = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'icon-close alert-button-close icon-base icons-property icon-dark'
    },
    OKAY: { text: 'Sign Up', handler: null , cssClass: ''}
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    HEADER_TEXT: 'Hey stranger!',
    MESSAGE: 'Please sign up before adding items to cart.'
  },
  CSS_CLASS: 'alert-custom'
};
export const BUY_MEMBERSHIP_CONFIRMATION = {
  BUTTONS: {
    CANCEL: { text: 'No', role: 'cancel', handler: null, cssClass: '' },
    CONFIRM: {
      text: 'Yes',
      handler: null,
      cssClass: 'alert-button-primary-outline '
    }
  },
  META_DATA: {
    HEADER_TEXT: '',
    MESSAGE: ''
  },
  CSS_CLASS: 'alert-custom'
};

export const MEMBERSHIP_INFO = {
  BUTTONS: {
    OKAY: {
      text: 'Okay',
      handler: null,
      cssClass: 'alert-button-primary '
    }
  },
  META_DATA: {
    HEADER_TEXT: '',
    // tslint:disable-next-line:max-line-length
    MESSAGE: '<div class="msg-wrap"><div class="headline"><h1>{heading}</h1><p class="margin-top-small"><small class="ion-text-uppercase">{subHeading}</small></p><p>{desc}</p></div><div class="divider-content" margin-top-small><small padding-right-small><strong>Continue to enjoy</strong></small></div><div class="list-holder"><div class="ion-no-margin ion-no-padding"><div class="list-item"><div class="icon-holder"><img alt="no_minimum_order" src="assets/img/icon-mo@2x.png" /></div><div class="text-holder">No minimum order</div></div></div><div class="list-item"><div class="icon-holder"><img alt="Hassle free refunds" src="assets/img/icon-hfr@2x.png" /></div><div class="text-holder">Hassle free refunds </div></div><div class="list-item"><div class="icon-holder"><img alt="free_delivery" src="assets/img/icon-free-delivery@2x.png" /></div><div class="text-holder">Free delivery</div></div></div>'
  },
  INPUT: [
    {
      name: 'dontShowAgain',
      type: 'checkbox',
      label: '',
      value: 'dontShowAgain',
      checked: false
    }
  ],
  CSS_CLASS: 'alert-custom has-full-height soft-grey-theme'
};

export const MEMBERSHIP_INFO_METADATA = {
  0: {
    heading: 'We’re levying a small charge of ₹{price} for {validityDays} days',
    subHeading: '',
    desc: 'with this order to keep delivering on our promise of quality and convenience.',
    ctaLabel: 'Okay',
    frozenInfo: 'A nominal fee ₹9 (+ GST) to be levied on this item which will be hand delivered between 7.00 am - 8.00 am.'
  },
  2: {
    heading: '{daysLeft} Power Days Left!',
    subHeading: 'VALID FROM {startDate} TILL {endDate}',
    desc: 'After expiry, a small amount of ₹{price} will be charged for the next {validityDays} days from the day you place your order.',
    checkLabel: 'Ok, I got it. Do not ask again',
    ctaLabel: 'Okay'
  },
  3: {
    heading: 'Recharge your Milkbasket wallet & enjoy benefits for next {validityDays} days for FREE',
    subHeading: '',
    desc: '',
    ctaLabel: 'Okay'
  },
  4: {
    heading: 'Yay! You’ve unlocked a 7 Days of Milkbasket’s benefits!',
    subHeading: '',
    // tslint:disable-next-line:max-line-length
    desc: 'Ditch long queues & parking woes. Order from milkbasket and enjoy quality wrapped in convenience.<br>After a month, a small amount of ₹{price} will be charged for the next {validityDays} days from the day you place an order. ',
    checkLabel: 'Ok, I got it. Do not ask again',
    ctaLabel: 'Okay'
  },
  inviteModalFreeMembershipText: 'Enjoy 7 Days of uninterrupted benefits for FREE.'
};

export const FROZEN_PRODUCT_DELIVERY_INFO = {
  BUTTONS: {
    OKAY: {
      text: 'Okay',
      handler: null,
      cssClass: 'alert-button-primary '
    }
  },
  META_DATA: {
    HEADER_TEXT: null,
    MESSAGE: ''
  },
  CSS_CLASS: 'alert-custom has-full-height'
};

export const RECURRING_DELETE_CONFIRMATION = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icon-base icons-property icon-dark icon-md'
    },
    CANCEL: {
      text: 'Pause',
      handler: null,
      cssClass: 'button-lg '
    },
    CONFIRM: {
      text: 'Remove',
      handler: null,
      cssClass: 'alert-button-primary-clear button-lg '
    }
  },
  META_DATA: {
    HEADER_TEXT: '',
    MESSAGE: '<p class="text-size-lg">Removing your subscribed items will permanently delete them. To resume with ease, would you like to pause it instead? </p>'
  },
  CSS_CLASS: 'delete-calendar alert-custom obs'
};

export const SUBSCRIPTION_BASKET_REMOVAL_CONFIRMATION = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icon-base icons-property icon-dark'
    },
    CANCEL: {
      text: 'Cancel',
      handler: null,
      cssClass: 'button-lg '
    },
    CONFIRM: {
      text: 'Remove',
      handler: null,
      cssClass: 'alert-button-primary-clear button-lg '
    }
  },
  META_DATA: {
    HEADER_TEXT: '',
    MESSAGE: `<p class="text-size-lg">Removing this item will reduce your savings from {currentSlab}% to {previousSlab}% on percentage type subscribed items for tomorrow's order.</p>`,
  },
  CSS_CLASS: 'delete-calendar alert-custom obs'
};

export const ADDITEM_ALERT = {
  BUTTONS: {
    CLOSE: {
      text: 'Shop Now',
      handler: null,
      role: 'cancel',
      cssClass: 'f-w-m '
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <img alt="Basket empty" src="assets/img/add-item-to-cart.svg" />
    <p>Your Basket is Empty.</p> 
    <h2> Good food is always <br> cooking!<br> Go ahead, order some <br> yummy items.</h2>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group'
};
export const RECURRING_SUCCESS_ALERT = {
  BUTTONS: {
    CONFIRM: {
      text: 'Ok',
      handler: null,
    },
    CANCEL: { text: 'Explore Items', role: 'cancel', handler: null, cssClass: 'set-close-buttom ' },

  },
  META_DATA: {
    MESSAGE:
      `<div class="toast-message-box padding-horizontal-medium-top ion-padding-bottom">
  <img alt="Your subscription is set" src="assets/img/success-new.svg" width="114" height="118" />
  <div class="text-holder ion-padding-horizontal">
    <p>Yay! Your subscription is set!</p>
    <h3 class="f-w-b ion-padding-top">
     {heading}</h3>
  </div>
</div>

<div class="read-only-info">
<ol>
  <li>You can change or pause your subscription <br> whenever you like
     </li>
  <li>Keep your wallet topped-up to ensure non-stop <br/>deliveries </li>
  <li>If you wish to not get delivery the next day, just<br> modify your cart before 12 midnight. </li>
</ol>
</div>
<div class="ion-padding-vertical ctn-gt-space-p">
<div class="holder banner padding-medium">
  <div class="img">
    <img alt="Cashbak saving" class="full-width" src="../../../../assets/img/cashback-saving.svg" />
  </div>
  <div class="text-holder">
    <p class="text-size-sm f-m">{SAVING_TEXT}</p>
    <ion-button class="margin-top-small"  color="light">Explore Items</ion-button>
  </div>
</div>`,
MESSAGE_NON_RC_USER:
`<div class="toast-message-box padding-horizontal-medium-top ion-padding-bottom">
<img alt="Your subscription is set" src="assets/img/success-new.svg" width="114" height="118" />
<div class="text-holder ion-padding-horizontal">
<p>Yay! Your subscription is set!</p>
<h3 class="f-w-b ion-padding-top">
{heading}</h3>
</div>
</div>

<div class="read-only-info">
<ol>
<li>You will receive your order(s) automatically <br>
as set on your Subscription. </li>
<li>Maintain a positive balance in your wallet to<br> ensure your deliveries aren’t put on hold. </li>
<li>If you change your mind about your scheduled<br> order, you can modify it before 12 midnight. </li>
</ol>
</div>`
  }
};

export const PAUSE_CALENDAR_ALERT = {
  BUTTONS: {
    CONFIRM: {
      text: 'Ok',
      handler: null
    }
  },
  META_DATA: {
    MESSAGE:
      `<div class="toast-message-box padding-horizontal-small">
      <img alt="Subscription paused successfully" src="assets/img/success-new.svg" />
    <div class="text-holder">
      <p>Subscription paused successfully!</p>
      <h3 class="f-w-b ion-padding-top ion-bottom-bottom">
      Your plan is paused from<br>
      {pausePeriod}</h3>
      <p>If you change your mind, tap on ‘Resume’<br> 
      to restart delivery. </p>
    </div>
  </div>`
  }
};

export const DELETE_RECURRING_ALERT = {
  BUTTONS: {
    CONFIRM: {
      text: 'Ok',
      handler: null
    }
  },
  META_DATA: {
    MESSAGE:
      `<div class="toast-message-box ion-padding-horizontal">
      <img alt="Subscription deleted successfully" src="assets/img/success-new.svg" />
    <div class="text-holder">
      <p>Subscription deleted successfully!</p>
    </div>
    <div class="bottom-sucess-mgs">
    <h3 class="f-w-b ion-padding-top padding-bottom-medium">
    Your repeat order would cancel from {date}</h3>
    </div>
  </div>`
  }
};

export const EDIT_RECURRING_ALERT = {
  BUTTONS: {
    CONFIRM: {
      text: 'Ok',
      handler: null
    }
  },
  META_DATA: {
    MESSAGE:
      `<div class="toast-message-box ion-padding-horizontal">
      <img alt="Subscription updated successfully" src="assets/img/success-new.svg" />
    <div class="text-holder">
      <p>Subscription updated successfully!</p>
    </div>
    <div class="bottom-sucess-mgs">
    <h3 class="f-w-b ion-padding-top padding-bottom-medium">
      Your repeat order would would be updated from {date}</h3>
    </div>
  </div>`
  }
};
export const TERMS_USES = {
  BUTTONS: {
    OKAY: {
      text: 'Email Us',
      handler: null,
      cssClass: 'f-w-m clear-btn margin-0 small-btn ' 
    },
    CLOSE: {
      text: 'Chat with US',
      handler: null,
      cssClass: 'clear-btn f-w-m margin-top-medium margin-left-0 small-btn '
    },
    CONFIRM: {
      text: '',
      handler: null,
      cssClass: 'clear-btn icon-close icons-property f-w-n margin-top-0'
    }
  },
  META_DATA: {
    MESSAGE: `  <div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
    <img alt="Account temporarily restricted" src="../../../../assets/img/user-chat.svg" />
    <p>Attention</p> 
    <h2 class="ion-text-center">Your account has been temporarily restricted</h2>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default custom-position user-alert'
};

export const NO_CHECKOUT = {
  BUTTONS: {
    CONFIRM: {
      text: '',
      handler: null,
      cssClass: 'clear-btn icon-close icons-property f-w-n margin-top-0'
    }
  },
  META_DATA: {
    MESSAGE: `  <div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
    <img alt="No Checkout Required" src="../../../../assets/img/no-checkout.svg" />
    <h2 class="ion-text-center">Item Successfully Added!</h2>
    <p class="no-checkout-req">‘No Checkout Required’</p>
    <div class="grey-list-info">
      <ul class="color-dark-300">
        <li><span>Your Order Value gets directly deducted from your Milkbasket wallet at midnight.</span></li>
        <li><span>All items in your basket are auto-checked out & paid from the prepaid wallet.</span></li>
        <li><span>Your order gets delivered at your doorstep by 7 AM.</span></li>
      </ul>
    </div> 
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default custom-position no-checkout-alert'
};
export const ADDRESS_UNDER_VERIFICATION = {
  BUTTONS: {
    CLOSE: {
      text: 'Ok, Got it!',
      handler: null,
      cssClass: 'f-w-m margin-top-extra-small'
    },
    CONFIRM: {
      text: '',
      handler: null,
      cssClass: 'clear-btn icon-close icons-property f-w-n margin-top-0'
    }
  },
  META_DATA: {
    MESSAGE: `  <div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
    <img alt="Address is under verification" src="../../../../assets/img/caution.svg" />
    <h2 class="ion-text-center text-size-lg">Your Address is</h2>
    <h2 class="ion-text-center text-size-lg">Under Verification</h2>
    <p class="ion-text-center text-size-md">You can continue exploring products & <br> categories while we verify your address <br> details!</p> 
  </div>`
  },
  CSS_CLASS: 'alert-custom has-cross-alert-posoition-top alert-custom-default custom-position user-alert address-pop-up'
};
export const ADDRESS_VERIFIED_SUCCESSFULLY = {
  BUTTONS: {
    CLOSE: {
      text: 'Shop Now!',
      handler: null,
      cssClass: 'f-w-m margin-top-extra-small'
    },
    CONFIRM: {
      text: '',
      handler: null,
      cssClass: 'clear-btn icon-close icons-property f-w-n margin-top-0'
    }
  },
  META_DATA: {
    MESSAGE: `  <div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
    <img alt="Address Verification Is Successful" src="../../../../assets/img/success-cheer.svg" />
    <h2 class="ion-text-center">Hooray! Your Address Verification Is Successful!</h2>
    <p class="ion-text-center">Start your journey with us to enjoy a <br> hassle-free grocery shopping <br> experience!</p> 
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default custom-position user-alert address-pop-up'
};
export const ADDRESS_CHANGE_REQUEST = {
  BUTTONS: {
    CLOSE: {
      text: 'Ok, Got it!',
      handler: null,
      cssClass: 'f-w-m margin-top-extra-small'
    },
    CONFIRM: {
      text: '',
      handler: null,
      cssClass: 'clear-btn icon-close icons-property f-w-n margin-top-0'
    }
  },
  META_DATA: {
    MESSAGE: `  <div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
    <img alt="Address is under verification" src="../../../../assets/img/caution.svg" />
    <h2 class="ion-text-center text-size-lg">Request Received</h2>
    <p class="ion-text-center text-size-md">We'll get back to you with an <br>update on your request.</p> 
  </div>`
  },
  CSS_CLASS: 'alert-custom has-cross-alert-posoition-top alert-custom-default custom-position user-alert address-pop-up'
};
export const EMPTYCART_ADDRESS = {
  BUTTONS: {
    CLOSE: {
      text: 'Continue',
      handler: null,
      cssClass: 'f-w-m margin-top-extra-small margin-bottom-base'
    },
    OKAY: {
      text: 'Re-enter Details',
      handler: null,
      cssClass: 'f-w-m clear-btn margin-0' 
    },
    CONFIRM: {
      text: '',
      handler: null,
      cssClass: 'clear-btn icon-close icons-property f-w-n margin-top-0'
    }
  },
  META_DATA: {
    MESSAGE: `  <div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
    <img src="assets/img/empty-cart.svg" alt="Empty Cart" />
    <h2 class="ion-text-center text-size-lg">Empty Cart</h2>
    <p class="ion-text-center text-size-md">All existing subscriptions & cart items <br> will be removed!</p> 
  </div>`
  },
  CSS_CLASS: 'alert-custom has-cross-alert-posoition-top alert-custom-default custom-position user-alert address-pop-up'
};
export const UNDER_REJECTED_ADDRESS = {
  BUTTONS: {
    CLOSE: {
      text: 'Got It!',
      handler: null,
      cssClass: 'f-w-m margin-top-extra-small'
    },
    CONFIRM: {
      text: '',
      handler: null,
      cssClass: 'clear-btn icon-close icons-property f-w-n margin-top-0'
    }
  },
  META_DATA: {
    MESSAGE: `  <div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
    <img src="assets/img/error-upi-popup.svg" alt="We Couldn’t Verify Your Address" />
    <h2 class="ion-text-center">Sorry! We Couldn’t Verify <br> Your Address.</h2>
    <p class="ion-text-center">Kindly re-check your address <br> details & try again.</p> 
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default custom-position user-alert address-pop-up'
};
export const TERMS_AND_CONDITIONS = {
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    HEADER_TEXT: 'Terms & Conditions',
    MESSAGE: ''
  },
  CSS_CLASS: 'alert-custom',
  BACKDROP_DISMISS: true
};

export const STORAGE_PERMISSION_CONFIRMATION = {
  BUTTONS: {
    CANCEL: { text: `Don't Ask Again`, role: 'cancel', handler: null, cssClass: 'alert-button-primary-outline ' },
    CONFIRM: {
      text: 'Give Permission',
      handler: null
    }
  },
  META_DATA: {
    HEADER_TEXT: 'We Request Again',
    MESSAGE: 'Milkbasket need this permission to cache images on your device for smoother experience'
  },
  CSS_CLASS: 'alert-custom',
  BACKDROP_DISMISS: false
};

export const STORAGE_PERMISSION_DENIED_ALWAYS = {
  BUTTONS: {
    CONFIRM: { text: `Okay`, role: 'cancel', handler: null, cssClass: 'alert-button-primary-outline ' },
  },
  META_DATA: {
    HEADER_TEXT: 'Permission Denied',
    MESSAGE: 'Go to the app setting and enable permission to access files & storage'
  },
  CSS_CLASS: 'alert-custom',
  BACKDROP_DISMISS: false
};

export const HOME_STRIP_CONTENT = {
  showHomeStrip: true
};

export const SSPH_CHARGES_CONFIRMATION = {
  BUTTONS: {
    CANCEL: {
      text: 'Cancel',
      handler: null,
      cssClass: 'alert-button-primary-outline '

    },
    CONFIRM: {
      text: 'Confirm',
      handler: null,
      cssClass: 'alert-button-primary '
    }
  },
  META_DATA: {
    HEADER_TEXT: '',
    MESSAGE: ''
  },
  CSS_CLASS: 'alert-custom',
  BACKDROP_DISMISS: false
};

export const CART_PROMOTION_DATA_DEFAULT_VALUE = {
  0: {
    title: 'Enjoy benefits for next {validityDays} days',
    ctaLabelForNotAppliedState: 'VIEW DETAILS!!',
    checkLabel: 'ADDED'
  },
  2: {
    title: 'Enjoy uninterrupted benefits for the next {daysLeft} days!',
    desc: 'VALID TILL {endDate}',
    ctaLabelForAppliedState: 'VIEW DETAILS'
  },
  3: {
    title: 'Enjoy {validityDays} days of FREE trial',
    ctaLabelForNotAppliedState: 'VIEW DETAILS',
    checkLabel: 'ADDED'
  },
  4: {
    title: 'Enjoy uninterrupted benefits for the next {daysLeft} days!',
    desc: 'VALID TILL {endDate}',
    ctaLabelForAppliedState: 'VIEW DETAILS'
  },
  showCartComponentPromotion: true
};

export const BILL_DETAIL_LABELS = {
  sub_total: 'Sub-total',
  savings: 'Savings',
  monthly_charge: 'Monthly charge',
  in_hand_delivery: 'In-hand delivery charge',
  gst_services: 'GST (on services)',
  total: 'Total',
  covid_fee: 'Covid Fee',
  ssph_charges: {
    content: 'SSPH Charge:<strong> ₹price (+GST) added to your cart</strong>',
    ctaLabel: 'Whats this',
    optIn: { content: 'SSPH Charge: You have opted out', ctaLabel: 'Opt-in' },
    externalUrl: '/external?url=http://file.milkbasket.com/static/SSPH/anants-notes.html&title=SSPH&iframe=false'
  },
  cashback: "Cashback applied"
};

// Remote config fallback data
export const EXTRA_FEE_DETAILS_DEFAULT_VALUES = {
  heading: '',
  details: 'Note: Product MRPs are inclusive of GST.'
};
// Remote config fallback data
export const CANCELLED_PRODUCTS_DEFAULT_CONFIG = {
  heading: 'ITEMS cancelled due to unavailability',
  subHeading: '0 amount was deducted from your wallet'
};

export const GENERATE_LIST_FROM_ARRAY = (termsAndConditionsArray: Array<string>) => {
  let content = '';
  if (termsAndConditionsArray.length === 0) {
    return content;
  }
  content = '<ul>';
  for (const term of termsAndConditionsArray) {
    content += '<li>' + term + '</li>';
  }
  content += '</ul>';
  return content;
};

export const RECURRING_FREQUENCIES = {
  EVERY_TWO_WEEKS: 'Every 2 Weeks',
  EVERY_FOUR_WEEKS: 'Every 4 Weeks',
  DISPLAY_WEEKLY: 'Weekly',
  BI_WEEKLY: 'bi-weekly',
  MONTHLY: 'monthly',
  WEEKLY: 'weekly',
  ONCE_IN_2_WEEKS: 'Once in 2 weeks',
  ONCE_IN_4_WEEKS: 'Once in 4 weeks'
};

export const A2B_API_ERRORS = {
  CUTOFF_TIME_EXCEEDED: 'cutoff_time_exceeded',
  INSUFFICIENT_CREDITS: 'insufficient_credits',
  INSUFFICIENT_CREDITS_UNPAID: 'insufficient_credits_unpaid',
  MAX_QUANTITY_EXCEEDED: 'max_quantity_exceeded',
  NOT_INSERVICEABLE_AREA: 'not_in_service_area',
  SCHEDULE_NOT_ALLOWED: 'schedule_not_allowed',
  TIME_RECHECK_GET: 'time_recheck_get',
  TIME_RECHECK: 'time_recheck',
  HOLIDAY_OFF: 'holiday_off',
  VENDOR_OFF: 'vendor_off',
  ORDER_LIMIT_REACHED: 'limit_reached',
  MBEYOND_ONLY_PRODUCT: 'mbeyond_only_product',
  INSUFFICIENT_NON_FOOD_CREDITS:'insufficient_non_food_credits',
  OUT_OF_STOCK:'out_of_stock',
  OUT_OF_LIMITED_STOCK:'out_of_limited_stock'
};


export const LOW_BALANCE_ERROR_MESSAGES = {
  SHOPPING_CREDIT_AMOUNT_MIN: 'Minimum topup required is',
  SHOPPING_CREDIT_AMOUNT_MAX: 'Maximum topup amount is'
};

export const EDIT_RECURRING_GIF = {
  message:
    `<div class="toast-message-box"><img alt="Calendar updated successfully" src="assets/img/success.gif" width="159" height="159" /><div>Calendar updated successfully!</div></div>`,
  class: 'toast-custom',
  position: 'top'
};

export const SUCCESS_RECURRING_GIF = {
  message:
    `<div class="toast-message-box"><img alt="Recurring Set Successfully" src="assets/img/success.gif" width="159" height="159" /><div>Recurring Set Successfully!</div></div>`,
  class: 'toast-custom',
  position: 'top'
};

export const DELETE_RECURRING_GIF = {
  message:
    `<div class="toast-message-box"><img alt="Calendar deleted successfully" src="assets/img/success.gif" width="159" height="159" /><div>Calendar deleted successfully!</div></div>`,
  class: 'toast-custom',
  position: 'top'
};

export const SUCCESS_BASKET_MESSAGES = {
  ADDED_TO_BASKET: 'Added to Basket',
  REMOVED_FROM_BASKET: 'Removed from Basket'
};

export const MB_PASS_STRIP_CONTENT =
  'Now, enjoy an additional 10% cashback on all your orders';

export const PRODUCT_DETAILS = 'Product_Details';

export const SCROLL_UP_DURATION = 1000;

export const SEARCH_EMPTY_SCREEN_CONTENT =
  'We looked everywhere..still couldn’t find it! <br/>Try a different name';

export const SEARCH_PAGE_EMPTY_SCREEN_CONTENT =
`Apologies! <br /> We couldn't find any results for @keyword@. Please double-check your spelling or refine the search`

export const SOCIAL_SHARING_DESCRIPTION =`Trusted by over a million families across 20+ cities in India
for delivering milk, fresh fruits & veggies, dairy products & 5000+ daily essentials`;

export const SOCIAL_SHARING_MESSAGE =`Hey, Here is an awesome collection on Milkbasket that I think you'll love! Ready to explore?`;

export const COLLECTION_KEYS = {
  OFFER: 'offer',
  URL: 'url'
};

export const COUNTER_DATA_FOR_IMPULSE_PRODUCT = {
  isAdded: true,
  count: 1
};

export const ORDER_LIMIT_REACHED_ALERT = {
  BUTTONS: ['OK'],
  META_DATA: {
    HEADER_TEXT: '',
    MESSAGE: ''
  },
  CSS_CLASS: 'alert-custom',
  HEADER_TEXTS: {
    amount: 'You\'ve reached max basket value allowed per day!',
    quantity: 'You\'ve added max products allowed per day!'
  },
  MESSAGES: {
    amount: `Due to an increase in demand, we\'re capping the basket value at Rs {amount} per day to discourage stocking essentials so it is available to more people.
               <br>Kindly order more items for a different date.`,
    quantity: `Due to an increase in demand, we\'re capping the order limit at {quantity} products per day to discourage stocking essentials so it is available to more people.
                 <br>Kindly order more items for a different date.`
  }
};

export const SSPH_CONFIG = {
  ssph_description: '<p>SSPH stands for Super Safety Packaging & Handling. </p>' +
    '<p>SSPH Charge is a portion of the total attributable costs incurred in implementing anti-CoViD measures like non-recycling of bags, extra consumables ' +
    'like masks & sanitizers, extra/overtime wages and costlier consumables and transportation amongst others.</p>' +
    '<p><strong>These costs are further enhanced</strong> ' +
    'by the restrictions on timings and number of people that can operate at a given time in our facilities,' +
    'aka social distancing, staggered breaks and contracted shift schedules - in place since 3rd week of March.' +
    '</p><p>SSPH charge is being added to your daily order as below:</p>' +
    '<p>a.  ₹7 (+ GST) for any order (any size, any volume)<br/>b.  ₹3 (+ GST) for ‘fresh milk’ only order (any size, any volume)</p>' +
    '<p><strong><span class="color-primary">*</span> SSPH charges are non - refundable once deducted.</strong></p>' +
    '<div class=\'divider\'></div>',
  anants_two_bits: '<em class=\'d-block\'>My two bits on SSPH and why we have to introduce it</em>Anant Goel on behalf of Team MB',
  tips_to_save: {
    heading: 'Tips to Save',
    description: 'Based on our internal processes, we have drafted a couple of tips that can help you and us save on the costs associated with anti-CoViD measures.'
  },
  here_they_are: {
    heading: 'Here they are:',
    description: '<p><strong class=\'padding-right-small\'>a.</strong>' +
      'If you order only fresh milk (say 1.5L) everyday, try to order once (say 3L or 4L) every two or three days – and save ₹30-60 every month' +
      '</p><p><strong class=\'padding-right-small\'>b.</strong>' +
      'Whenever you order, plan and order for 2 days. We believe many of you are already doing it. If not, this can further reduce your cost by half or more.' +
      '</p><p><strong class=\'padding-right-small\'>c.</strong>' +
      'The more types of items you order, e.g. Food, Non-Food, Fresh, Cold, Hazardous, etc., the more is our packaging and handling cost. ' +
      'By consolidating the type of things you order, you can help reduce our costs and we would be grateful for those savings.</p>'
  },
  ssph_optout_content: {
    before: 'If you really do not want to or are unable to pay the SSPH Charges, you can also delete these charges everyday from your basket by clicking',
    after: '<br><br>Thank you so much for your consideration. We really appreciate it!<br>Team Mb'
  },
  alertMessage: 'Though I understand that Milkbasket is incurring extra costs to ensure safe delivery of my order, I would not like to pay for the SSPH charges.',
  optInAlertMessage: 'I would like to pay the SSPH Charges.'
};

export const PG_IMAGES_DEFAULT_DATA = {
  CREDIT_CARD: 'credit_card.png',
  amazon_pay: 'amazon_pay.png',
  mobikwik: 'mobikwik.png',
  lazy_pay: 'lazy_pay.png',
  SODEXO: 'sodexo.svg',
  EDENRED: 'edenred.png',
  UPI: 'upi.png',
  google_pay: 'google_pay.png',
  paytm: 'paytm.png',
  DEBIT_CARD: 'debit_card.png',
  NET_BANKING: 'net_banking.png',
  paytm_upi: 'paytm_upi.png',
  airtel_money: 'airtel_money.png'
};

export const CATEGORY_SUBCATEGORY_CLOSED_INFO_DEFAULT_TEXT = {
  categories: 'We’ve run out of capacity & can’t take more orders from these categories for tomorrow’s delivery. We regret the inconvenience.',
  subcategory: 'We’ve run out of capacity & can’t take more orders from this section for tomorrow’s delivery. We regret the inconvenience',
  category: 'We’ve run out of capacity & can’t take more orders from these categories for tomorrow’s delivery. We regret the inconvenience.'
};
export const REFILL_TYPES_META_DATA = {
  3: { CTAText: 'Notify me', UIMessage: 'Back in stock tomorrow' },
  4: { CTAText: 'Notify me', UIMessage: 'Back in stock by {date}', dateFormat: 'Do MMM', contentKeyToBeReplaced: '{date}' },
  5: { CTAText: 'Notify me', UIMessage: 'Currently out of production' },
  6: { CTAText: 'Notify me', UIMessage: 'Currently unavailable' }
};
export const WHATSAPP = 'whatsapp';

export const WHATSAPP_SHARING_COLLECTION_METADATA_DEFAULT_VALUE = { "isPDPEnabled": { "android": true, "ios": true }, "notAllowedSKUIDs": [29108, 29109] }

export const COLLECTION_LOCATION_TYPE = {
  SEARCH: 3,
  COLLECTION_LISTING: 4,
  MBEYOND_COLLECTION_LISTING: 5,
  MBEYOND_EXCLUSIVE_OFFER: 6,
  RECURRING_SUGGESTIONS: 7,
  SEARCH_KEYWORD: 9,
  CATEGORY_COLLECTION: 10
};

export const MB_PASS = {
  FAQS: 'FAQs',
  URL: 'https://s3.ap-south-1.amazonaws.com/adhoc.milkbasket.com/mb-pass/mb-faq.html',
  NO_MB_PASS_AVAILABLE: 'No Mb pass Available'
};

export const MBEYOND = {
  FAQS: 'FAQs',
  URL: 'https://s3.ap-south-1.amazonaws.com/adhoc.milkbasket.com/mbeyond-faq-new.html'
};

export const CALENDAR = {
  FAQS: 'FAQs',
  URL: 'https://adhoc.milkbasket.com/recurring/recurringFAQs.html'
};

export const YFO_CATEGORY_ID = 36;

export const REC_RECOMMENDATION_TEXT: RecRecommendationText = {
  multiple_item: {
    heading: 'Here are your frequent buys!',
    sub_heading: 'Set them on subscription & save time on future buys.'
  },
  single_item: {
    heading: 'Here is your frequent buy!',
    sub_heading: 'Set it on subscription & save time on future buys.'
  }
};

export const SUBS_N_SAVE_REC_RECOMMENDATION_TEXT = {
  multiple_item: {
    heading: 'Subscribe to Save upto {maxPercent}%!',
    sub_heading: 'Frequently bought items' + '<br />' + 'Subscribe Now and start saving!'
  },
  single_item: {
    heading: 'Set on Calendar. Save Time',
    sub_heading: ''
  }
};

export const REC_COACHMARK_REPEAT_FREQUENCY = 14;

export const FREQUENCY_CONSTANTS = {
  WEEKLY: 1,
  WEEKDAYS: 2,
  WEEKENDS: 3,
  DAILY: 4
};

export const CALENDAR_FREQUENCIES = {
  DAILY: { value: 'daily', headerText: 'Daily' },
  WEEKLY: { value: 'weekly', headerText: 'Weekly' },
  MONTHLY: { value: 'month', headerText: 'Monthly' },
  ALTERNATE: { value: 'alternate', headerText: 'Alternate days' },
  // ONCE: { value: 'once', headerText: 'Once' },
};

export const PRICE_FLUCTUATION_INFO = '*Price of products on subscription may change as per market changes';

export const DAYS_LIST = {
  1: 'Mo',
  2: 'Tu',
  3: 'We',
  4: 'Th',
  5: 'Fr',
  6: 'Sa',
  7: 'Su'
};

export const SUCCESS_MESSAGE_LIST_RECURRING = {
  DAILY: ` is the start date for <br> your daily subscription`,
  ALTERNATE: ` is the start date for <br> your alternate day subscription`,
  WEEKLY: ` is the start date for <br> your weekly subscription`,
  MONTHLY: ` is the start date for <br> your monthly subscription`
};

export const START_DATE = 'Start date';
export const END_DATE = 'End date';

export const CALENDAR_JOURNEY_BASE_URL = 'https://adhoc.milkbasket.com/';

export const PAUSE_ALERT = {
  BUTTONS: {
    CONFIRM: {
      text: 'Ok',
      handler: null,
      cssClass : ''
    }
  },
  META_DATA: {
    MESSAGE: `This plan is paused from {date}. please resume first.`
  }
};

export const DAY29_ALERT = 'Please note that month of February does not have days beyond 28';
export const DAY31_ALERT = 'Please note that not all months have 31 days.';

export const CALENDAR_HEADING = {
  ONCE: { create: 'Select delivery date', edit: 'Edit delivery date' },
  MONTHLY: { create: 'Select date(s) for monthly delivery', edit: 'Edit date(s) for monthly delivery' }
};

export const OFFER_DETAIL_ALERT = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icon-base icons-property icon-dark'
    },
    OKAY: {
      text: '',
      role: 'okay',
      handler: null,
      cssClass: 'alert-button-close icon-base icons-property icon-dark'
    },
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    MESSAGE: ` 
      <div>
      <ul class="offer-available-alert">
      {offers_info}
      </ul> 
      </div>`,
  },
  OKAY_TEXTS: {
    remove: 'Remove',
    add: 'Add'
  },

  CSS_CLASS: 'alert-custom offer-available-alert'
};

export const GST_NOTE = 'NOTE: GST is applicable only on services. <br> Products are inclusive of GST.';

export const ORDER_EMPTY_STATE = {
  imgUrl: 'assets/img/transactions-empty.svg',
  label: 'You have not placed <br> any order yet'
}
export const RECURRING_CAMPAIGN = {
  TC: 'TnCs',
  URL: 'https://s3.ap-south-1.amazonaws.com/adhoc.milkbasket.com/HTML/cash-back-tnc.html'
};


export const RECURRING_CAMPAIGN_TERMS_AND_CONDITION = `<p>SSPH stands for Super Safety Packaging & Handling. </p>' +
'<p>SSPH Charge is a portion of the total attributable costs incurred in implementing anti-CoViD measures like non-recycling of bags, extra consumables ' +
'like masks & sanitizers, extra/overtime wages and costlier consumables and transportation amongst others.</p>' +
'<p><strong>These costs are further enhanced</strong> ' +
'by the restrictions on timings and number of people that can operate at a given time in our facilities,' +
'aka social distancing, staggered breaks and contracted shift schedules - in place since 3rd week of March.' +
'</p><p>SSPH charge is being added to your daily order as below:</p>' +
'<p>a.  ₹7 (+ GST) for any order (any size, any volume)<br/>b.  ₹3 (+ GST) for ‘fresh milk’ only order (any size, any volume)</p>' +
'<p><strong><span class="color-primary">*</span> SSPH charges are non - refundable once deducted.</strong></p>' +
'<div class=\'divider\'></div>`

export const RECURRING_CAMPAIGN_BOTTOM_TEXT = {
  FLAT: 'Yay, you’re saving ₹{cashback} on every order with this subscription.<br/>Add more items to save more!',
  //PERCENT: '<small class="f-w-s-b">Yay, you’re saving {currentSlab}% on every order with this subscription. Add 1 more item to save {nextSlab}%! </small>',
  PERCENT:`You'll get {currentSlab}% cashback on this subscription.<br/>Add 1 more item to get {nextSlab}%`,
  NONE: 'You can save on your daily essentials by adding items from the Subscribe to Save range'
}

export const RECURRING_TEXT = {
  RECURRING_PAUSE_TEXT: {
    FLAT_TYPE_PRODUCT: `Pausing the subscription will put your savings of ₹{flatValue} on hold.`,
    MULTIPLE_PERCENT_PRODUCT: `Pausing the subscription will reduce your savings on subscribed items from {currentSlab}% to {prevSlab}%`,
    SINGLE_PERCENT_PRODUCT: `Pausing the subscription will put your savings of {currentSlab}% on hold.`
  },
  RECURRING_DELETE_TEXT: {
    FLAT_TYPE_PRODUCT: `By removing this subscription, you will miss out on ₹{flatValue} savings per order!`,
    MULTIPLE_PERCENT_PRODUCT: `By removing this subscription, your savings will reduce from  {currentSlab}% to {prevSlab}%`,
    SINGLE_PERCENT_PRODUCT: `By removing this subscription, you will miss out on   {currentSlab}% on savings!`,
  }
}



export const RECYCLE_BAGS_INSTRUCTION = [
  { icon: 'recycle-now-icon', text: 'Request for excess bag return by clicking on ‘Recycle Now’.' },
  { icon: 'place-icon', text: 'Put the excess MB delivery bags in a bag and place it outside your door before midnight.' },
  { icon: 'hand-icon', text: 'We will collect your bags the next morning and send them for recycling.' }
]

export const TETRA_PACKS_BAGS_INSTRUCTION = [
  { src: '../../../../assets/img/flip.png', text: `Empty your <br> carton` },
  { src: '../../../../assets/img/flap.png', text: `Rinse it` },
  { src: '../../../../assets/img/flat.png', text: `Lift the flaps <br> and flatten the <br> carton` }
];

export const TETRA_PACK_KNOW_MORE = {
  heading: 'After your used Tetra Pak cartons are collected, this is how they are recycled.',
  imgUrl:"https://www.tetrapak.com/content/dam/tetrapak/publicweb/in/en/campaigns/gngc/steps.jpg"
}

export const RECYCLE_BAG_MATERIAL_TAGS = [
  {
    ROUTE: '/listing/recycle-bag',
    HEADING: 'Recycle Delivery Bags',
    CONTENT: 'Request to recycle your<br/>excess delivery bags',
    NAME:'DELIVERY_BAG',
    icon:'delivery-bags'
  },
  {
    ROUTE: '/listing/recycle-tetra-bag',
    HEADING: 'Recycle Tetra Pak Cartons',
    CONTENT: 'Request to recycle your<br>used Tetra Pak cartons',
    NAME:'TETRA_PAK',
    icon:'fully-empty-container'
  }
]

export const CAMPAIGN_CASHBACK_TYPE={
  PERCENTAGE:'PERCENTAGE',
  FLAT:'FLAT'
}

export const MBPASS_OFFER_TYPE={
  FLAT: 1,
  PERCENTAGE: 2
}

export const MBPASS_OFFER_CATEGORY_TYPE={
  CATEGORY:1,
  SUBCATEGORY:2,
  MIN_BASKET_VALUE:4
}

export const MBPASS_TYPE ={
  CAT_SUBCAT:'catSubcat',
  PLATFORM:'platform'
}

export const MBPASS_STRIP_CONTENT ={
   ADD_ANY_ITEM:'Add any item',
   TO:'to',
   TO_EARN:'earn',
   CASHBACK:'cashback',
   FROM:'from',
   OF_RS:'of ₹',
   YOU_HAVE_EARNED:'You have earned',
   RS:'₹',
   CONGRATS_YOU_HAVE_EARNED:'Congrats! You’ve earned',
   TODAY:'today',
   FOR:'for',
   MORE:'more',
   ADD_ITEMS_OF:'Add items of',
   MAX:'max'
}

export const NPS_RATING = [1,2,3,4,5,6,7,8,9,10];

export const MAX_LIMITS = {
  banners: DEFAULT_BANNER_MAX_LIMIT
};

export const festiveSeason = {
  countDownStrip: {},
  cashbackStrip: {},
  splash:{}
};

export const INSUFFICIENT_WALLET_BALANCE = {
  BUTTONS: {
    CONFIRM: {
      text: 'Recharge my wallet',
      handler: null,
      role: 'ok',
      cssClass: 'f-w-m button-lg button-lnk t-t-n alert-button-primary-outline'
    },
    OK: {
      text: 'Continue with membership',
      handler: null,
      role: 'confirm',
      cssClass: 'f-w-m button-lg t-t-n button-spaccer'
    },
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-md icon-dark slot-end '
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <img src="assets/img/membership-saving-remove.svg" alt="If you remove Membership fees" />
    <h2>Wallet Balance Will Become Insufficient</h2>
    <p class="messages-spaccer">Only {nonMembersPercentage}% of the cashback wallet can be utilized per order for non-members. We recommend keeping your membership to avail the {membersPercentage}% cashback usage & maximize your savings.</p>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group membership-alert alert-bottom'
};

export const BUY_OTDF = {
  BUTTONS: {
    CONFIRM: {
      text: 'No, keep my membership',
      handler: null,
      role: 'confirm',
      cssClass: 'f-w-m button-lg t-t-n'
    },
    OK: {
      text: 'Yes, remove my membership',
      handler: null,
      role: 'ok',
      cssClass: 'f-w-m button-lg button-lnk t-t-n'
    },
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark slot-end'
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <img src="assets/img/membership-saving.svg" alt="If you remove Membership fees" />
    <h2>If you remove Membership fees, a<br/> delivery fee will be levied on your<br/> orders. Do you still wish to remove<br/> it?</h2>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group membership-alert'
};

export const BUY_MEMBERSHIP = {
  BUTTONS: {
     CONFIRM: {
      text: 'Ok, got it!',
      handler: null,
      role: 'okay',
      cssClass: 'f-w-m button-lg t-t-n'
    },
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark slot-end'
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <img src="assets/img/membership-saving-add.svg" alt="Membership added" />
    <h2 class="pb-16">Membership added! Enjoy free<br/> delivery on your orders for<br/> {MEMBERSHIP_DAYS} days!</h2>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group membership-alert'
};
export const TARGET_MEMBERSHIP_COMPLETED = {
  BUTTONS: {
     CONFIRM: {
      text: 'OK, got it.',
      handler: null,
      role: 'okay',
      cssClass: 'f-w-m button-lg t-t-n'
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <img src="assets/img/crown.svg" alt="">
    <h2 class="ion-no-margin">Hurray! Membership extension<br/> is on the way</h2>
    <p>Your membership extension is just a successful delivery away!</p>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group membership-alert'
};
export const TARGET_MEMBERSHIP_EXTENDED = {
  BUTTONS: {
     CONFIRM: {
      text: 'OK, got it.',
      handler: null,
      role: 'okay',
      cssClass: 'f-w-m button-lg t-t-n'
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <img src="assets/img/crown.svg" alt="">
    <h2 class="ion-no-margin">Congrats! Your Membership has been extended by {numExtensionDays} days!</h2>
    <p>Your free membership will get activated seamlessly once your current membership expires (for existing members) or will start from your next order (for non members).</p>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group membership-alert'
};
export const FREE_MEMBERSHIP_ACTIVATED = {
  BUTTONS: {
     CONFIRM: {
      text: 'OK, got it.',
      handler: null,
      role: 'okay',
      cssClass: 'f-w-m button-lg t-t-n'
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <img src="assets/img/crown.svg" alt="">
    <h2 class="ion-no-margin">Congratulations! <br/> Your free membership trial is activated</h2>
    <p>Save  ₹{deliver_charge} delivery charge on every order for the next {MEMBERSHIP_DAYS} days</p>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group membership-alert'
};
export const FREE_MEMBERSHIP_PLAIN_DETAILS = {
  BUTTONS: {
     CONFIRM: {
      text: 'Confirm',
      handler: null,
      role: 'okay',
      cssClass: 'f-w-m button-lg t-t-n'
    },
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark slot-end'
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details plan-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <img src="assets/img/crown.svg" alt="">
    <h2 class="title ion-no-margin">Plan Details</h2>
    
    <div class="d-flex ion-justify-content-between w-100 row-1">
      <p class="sub-title text-size-md f-w-b">plans</p>
      <p class="sub-title text-size-md f-w-b"><span class="inline-through text-size-sm padding-right-extra-small f-m">₹mrp</span> ₹sp</p>
    </div>

    <div class="d-flex ion-justify-content-between w-100">
    <p class="sub-title text-size-md f-w-b">Validity</p>
    <p class="sub-title text-size-sm f-w-b"> startDate - endDate </p>
    </div>
    <p class="l-s-0 padding-top-extra-small">Membership will be extended from the<br/> date of expiry of your current plan</p>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group membership-alert'
};
export const EXPIRED_MEMBERSHIP_PUPUP = {
  BUTTONS: {
     CONFIRM: {
      text: 'Confirm',
      handler: null,
      role: 'okay',
      cssClass: 'f-w-m button-lg t-t-n'
    },
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark slot-end'
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details plan-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <img src="assets/img/crown.svg" alt="">
    <h2 class="title ion-no-margin">Plan Details</h2>
    
    <div class="d-flex ion-justify-content-between w-100 row-1">
      <p class="sub-title text-size-md f-w-b">plans</p>
      <p class="sub-title text-size-md f-w-b"><span class="inline-through text-size-sm padding-right-extra-small f-m">₹mrp</span> ₹sp</p>
    </div>
    <p class="l-s-0 padding-top-extra-small">Membership will be activated from your <br> next order.</p>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group membership-alert'
};
export const FREE_MEMBERSHIP= {
  BUTTONS: {
     CONFIRM: {
      text: 'Ok, got it!',
      handler: null,
      role: 'okay',
      cssClass: 'f-w-m button-lg t-t-n'
    },
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark slot-end'
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <img src="assets/img/crown.svg" alt="">
    <h2 class="ion-no-margin">Almost there! <br/> Your free membership is ready to <br/> be activated after your <br/> 1st delivered order.</h2>
    <p>Save Rs. 15 delivery charge on every order.</p>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group membership-alert'
};