import { ProductPricing } from './product-pricing';
import { ProductDiscount } from './product-discount';

export class ProductPrice {
    __typename = 'ProductPrice';
    currency: string;
    perUnit?: string; // pricePerUnit
    mbeyond?: ProductPricing; // was mbeyondSp
    mrp: ProductPricing; // was mrp_per_unit, mrp in same order
    price: ProductPricing; // was price_per_unit, sp, price in same order of preference
    discount: ProductDiscount; // was product.saving_per_unit or computed as (product.saving_per_unit || product.mrp - product.sp || product.mrp - product.price)
    dealDiscount: ProductDiscount; // was product.saving_per_unit or computed as (product.saving_per_unit || product.mrp - product.sp || product.mrp - product.price)
    setPriceFromApi(productApi) {
        this.currency = '₹';
        this.perUnit = productApi.pricePerUnit || '';

        const mbeyond = new ProductPricing();
        this.mbeyond = mbeyond.setMbeyondFromApi(productApi);

        const mrp = new ProductPricing();
        this.mrp = mrp.setMrpFromApi(productApi);

        const price = new ProductPricing();
        this.price = price.setPriceFromApi(productApi);

        const discount = new ProductDiscount();
        this.discount = discount.setDiscountFromApi(productApi);

        const dealDiscount = new ProductDiscount();
        this.dealDiscount = dealDiscount.setDealDiscountFromApi(productApi);

        return this;
    }
}
