//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { ExtraWidthModule } from '@Features/product-list/components/horizontal/extra-width/extra-width.module';
//#endregion Module Imports

//#region Component Imports
import { TwoRowComponent } from '@Features/product-list/components/horizontal/two-row/two-row.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import annotations from '@Features/product-list/product-list.annotations';
//#endregion Other Imports

//#endregion Imports


@NgModule({
  declarations: [TwoRowComponent],
  imports: [...annotations.imports, ExtraWidthModule],
  exports: [...annotations.exports, TwoRowComponent],
  providers: [...annotations.providers],
})
export class TwoRowModule { }