<ion-item lines="none" color="none" class="wallet-info-strip">
  <ion-icon class="user-suspend icons-property icon-dark margin-right-small"></ion-icon>
  <ion-label class="ion-no-margin">
    <h2 class="f-w-s-b color-dark-300 text-size-sm" *ngIf="!cartPageView">Your account has been temporarily restricted</h2>
      <h2 class="f-w-s-b color-dark-300 text-size-sm" *ngIf="tomorrowBasketExists">Order will not be delivered</h2>
    <ng-container  *ngIf="cartPageView">
    <p class="f-w-n color-dark-300" [ngClass]="{'has-not-basket':!tomorrowBasketExists}">Your account has been temporarily restricted</p>
    </ng-container>
    <p class="f-w-n color-dark-300">To know more, <strong class="f-w-s-b know-more" (click)="chatWithAgent()"> Chat with us</strong> or <strong class="f-w-s-b know-more" (click)="sendEmail()"> email us</strong> </p>
  </ion-label>
</ion-item> 