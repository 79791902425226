<div class="overlay-bar"></div>
<div class="map-location" [ngClass]="{'show-jio-header': dataService.isJioUserObs | async}">
  <ion-header *ngIf = "!utility.isApp()" class="shadow-none visible-jio-header">
    <mbs-jio-mart-header></mbs-jio-mart-header>
  </ion-header>
  <div #map id="map">
  </div>
  <div  *ngIf="((signupService.latLong && viewType === addressSelectionType.SelectSociety) ||
   (viewType === addressSelectionType.ConfirmSociety || viewType === addressSelectionType.SelectTower))" 
  class="marker d-flex ion-justify-content-center ion-align-items-center">
    <div class="marker-details" [ngClass]= "{ 'current-marker' : location !== locationConstant}">
      <div class="delivered-marker-non-deliver" *ngIf="dataService.isUnserviceableFlow && viewType === addressSelectionType.ConfirmSociety">
        <p class="f-w-n ion-text-center">Not serviceable yet</p>
      </div>
      <div class="delivered-marker" *ngIf="!dataService.isUnserviceableFlow">
        <h4 class="f-m ion-text-center">Your order will be delivered here</h4>
        <p class="f-w-n ion-text-center">Please place the pin accurately on the map</p>
      </div>
      <p class="f-m ion-justify-content-center">{{location}}</p>
    </div>
  </div>
  <div class="location-gps"  *ngIf="signupService.latLong && (viewType === addressSelectionType.SelectSociety || viewType === addressSelectionType.ConfirmSociety)" >
    <ion-icon (click)="recenter()" class="icon-location-gps icons-property" ></ion-icon>
  </div>
 </div>
 <mbpc-bottom-sheet class="bottom-sheet" 
 [disableDrag] = "setDisable()"
 [dockedHeight]="setUpBottomSheet()" 
 [sheetState]="sheetState">
  <div [ngSwitch]="viewType"> 
    <ng-container *ngSwitchCase="addressSelectionType.SelectSociety">
      <mbpc-select-delivery-location  (selectSociety)="selectSociety($event)"
      (addNewAddress) = "addAddress()"
      (turnLocationOn) = "enableLocation()"
      (changeSheetState)="sheetStates($event)"
      (changeSheetBackToNormal)="sheetBackToNormalStates($event)"
      (recenterUser) = "recenter()"
      ></mbpc-select-delivery-location>
    </ng-container>
    <ng-container *ngSwitchCase="addressSelectionType.ConfirmSociety">
    <mbpc-confirm-society (confirmSociety)="confirmSociety()">
    </mbpc-confirm-society>
    </ng-container>
    <ng-container *ngSwitchCase="addressSelectionType.SelectTower">
      <mbpc-select-tower (changeSheetState)="sheetStates($event)" (selectTower)="setTower($event)" (addLocality)="addLocalityAddress($event)" (updateAddress)="changeAddress($event)" ></mbpc-select-tower>
    </ng-container>
    <ng-container *ngSwitchCase="addressSelectionType.AddAddress">
      <mbpc-add-address *ngIf="!showCity" [cityData]="geolocationService.cityInfo" (addAddress) ="addNewAddress($event)" (openCity)="showCityModal()"></mbpc-add-address>
      <mbf-select-city *ngIf="showCity" (onSheetClose) = "closeCitySelection($event)"></mbf-select-city>
    </ng-container>
    <ng-container *ngSwitchCase="addressSelectionType.AddressAddSuccess">
    <mbpc-address-add-success></mbpc-address-add-success>
    </ng-container >
  </div>
</mbpc-bottom-sheet>