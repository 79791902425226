import * as DateUtils from './date';
import * as HtmlUtils from './html-to-string';
import * as NumberUtils from './number';
import * as JSONUtils from './json';
import deepmerge from './deep-merge';

export const Utilities = {
    date: DateUtils,
    html: HtmlUtils,
    number: NumberUtils,
    json: JSONUtils,
    deepmerge,
};
