//#region Imports

//#region Angular Imports
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import * as moment from 'moment';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { RecurringService } from '@Globals/providers/recurring.service';
//#endregion Providers Imports

//#region Other Imports
import { Utilities } from '@Globals/classes/utilities';
import { CALENDAR_FREQUENCIES } from '@Globals/constants/listing/constants';
import { CalendarPlanType, ProductListType, recurringCampaignCashbackType, RecurringCampaignStripType } from '@Globals/constants/listing/enums';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mb-rc-subsave-strip',
  templateUrl: './rc-subsave-strip.component.html',
  styleUrls: ['./rc-subsave-strip.component.scss'],
})
export class RcSubscribeSavingStripComponent implements OnInit {
  @Input() recurringProductDetails: object;
  @Input() stripType: string;
  cashbackType = recurringCampaignCashbackType;
  stripTypes = RecurringCampaignStripType;
  calendarPlanType = CalendarPlanType;
  @Input() productId: number;
  @Input() recEdit: boolean = false;
  onSubscriptionTextView: boolean = false;
  showPausedText:boolean=false;
  @Input() componentType: ProductListType;
  @Input() product: string;
  componentTypeView = ProductListType;
  showAccountStrip:boolean =true;
  momentRef= moment;
  hideStripForMonthlyRecurringProduct = true;
  constructor(public dataService: DataService, public router: Router, public recurringService: RecurringService,  public masterDataService: MasterDataService) {
  }
  ngOnInit(): void {
    if (/subscribe-to-save/i.test(this.router.url) || /recurring-orders/i.test(this.router.url) || /recurring-search/i.test(this.router.url)) {
      this.onSubscriptionTextView = true;
    }
    this.checkForPause();
    if(this.product){
      this.showAccountStrip = this.product['cb_per'] ||this.product['cb_amount']?true:false;
    }
  }

  checkForPause(){
    this.masterDataService.recurringOrder && this.masterDataService.recurringOrder.forEach((item:any)=>{
      if((item.product_id === this.recurringProductDetails['product_id']) && item.frequency === CALENDAR_FREQUENCIES.MONTHLY.value){
        this.hideStripForMonthlyRecurringProduct =false;
      }
      if ((item.product_id === this.recurringProductDetails['product_id']) &&  item.pausePeriod) {
        let startDate = new Date(item.pausePeriod.startDate);
        let endDate = new Date(item.pausePeriod.endDate);
        const currentDate = new Date();  
       if(moment(currentDate).isBetween(startDate,endDate) || Utilities.equalDates(currentDate,startDate) ||  Utilities.equalDates(currentDate,endDate)){
        this.recurringProductDetails['cb_val']='Paused';
       }
     }
    })
  }

}
