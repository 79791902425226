import { Injectable } from "@angular/core";
import {
  IonSlides,
  ModalController,
  NavController,
  Platform,
} from "@ionic/angular";
import { BehaviorSubject, noop, Observable, Subject } from "rxjs";
import {
  A2BOfferData,
  BillDetails,
  BulkBuyData,
  CartVoucherPromotionData,
  ExternalUrl,
  ExtraFeeDetails,
  HomeStripPromotion,
  MembershipConfirmationData,
  PaymentOption,
  SegmentData,
  YFOConfigData,
  RecRecommendations,
  CalendarPlan,
  AutoDebitBannersType,
  WelcomeCode,
  FirebaseMasterData,
  Basket,
  TargetMembership,
} from "../../interfaces/interfaces";
import { environment } from "src/environments/environment";
import {
  APP_INBOX_STYLE_CONFIG,
  BILL_DETAIL_LABELS,
  CALENDAR,
  CANCELLED_PRODUCTS_DEFAULT_CONFIG,
  CART_PROMOTION_DATA_DEFAULT_VALUE,
  CATEGORY_SUBCATEGORY_CLOSED_INFO_DEFAULT_TEXT,
  EVENT_NAMES,
  EVENT_SOURCES,
  EXTRA_FEE_DETAILS,
  EXTRA_FEE_DETAILS_DEFAULT_VALUES,
  HOME_STRIP_CONTENT,
  MEMBERSHIP_INFO,
  MEMBERSHIP_INFO_METADATA,
  OFFER_DETAIL_ALERT,
  ORDER_LIMIT_REACHED_ALERT,
  PG_IMAGES_DEFAULT_DATA,
  REFILL_TYPES_META_DATA,
  SSPH_CONFIG,
  WHATSAPP_SHARING_COLLECTION_METADATA_DEFAULT_VALUE,
  MAX_LIMITS,
  festiveSeason,
  ADDITEM_ALERT,
  BUY_OTDF,
  BUY_MEMBERSHIP,
  REC_RECOMMENDATION_TEXT,
} from "src/app/listing/constants/listing-constants";
import { AlertService } from "../alert/alert.service";
import { EventsService } from "../events/events.service";
import {
  ANDROID,
  DELIVERY_SLOT_INFO,
  DELIVERY_TIMING,
  EXTRA_FEE_LABEL,
  FARM_ROUTE_INFO,
  FILE_STORAGE_DIRECTORIES,
  FROZEN_PRODUCT_A2B_DATA,
  FROZEN_PRODUCT_A2B_META_DATA,
  GENERATE_CONTENT_FOR_ALERT_LAYER,
  HOME_STRIP_CONTENTS,
  IOS,
  OFFER_APPLIED_INFO,
  OFFER_VIEW_TYPES,
  OFFER_VIEW_TYPES_METADATA,
  SETTINGS_MEMBERSHIP_CONTENT,
  STORAGE_KEYS,
  SUPER_CHARGE_CONFIG_DATA,
  TAXES_LABEL,
  YFO_TRENDING_CONFIG_DEFAULT_VALUES,
  ACCOUNT_CLOSURE_REASON,
  MEMBERSHIP_REVISED_CONSTRUCT,
  CASHBACK_METADATA,
  MB_PASS_METADATA,
  PERCENT_CASHBACK_METADATA,
  OFFER_VIEW_TYPE,
  FAQs,
  AUTO_DEBIT_BANNERS,
  BULK_BUY_DATA,
  INFO_POP_UP,
  JIO_CASHBACK_CONSTRUCT,
  WEB,
  MILKBASKET_SUPPORT_EMAIL,
  JIO_EVENTS,
  MEMBERSHIP,
  DISCOUNT_PRODUCT_METADATA,
  DISOUNTED_PRODUCT_OFFER_APPLIED_INFO,
} from "../../constants/constants";
import { AuthService } from "../auth/auth.service";
import { Utilities } from "../../classes/utility/utilities";
import * as moment from "moment";
import { CashCollectionRemovalInfoData } from "@Globals/interfaces/payment/interfaces";
import { CASH_COLLECTION_REMOVAL_INFO_DEFAULT_DATA } from "@Globals/constants/payment/constants";
import { LoggingService } from "../logging/logging.service";
import { MBeyondfirebaseData } from "src/app/listing/interfaces/listing-interface";
import { MembershipPlansData } from "src/main/pages/membership/interfaces/membership.interfaces";
import {
  ProductListType,
  RewardType,
} from "src/app/listing/constants/listing-enums";
import { CleverTap } from "@ionic-native/clevertap/ngx";
import { ToastService } from "../toast/toast.service";
import {
  LIVE_NOW_OBJECT,
  REFER_CODE_COPIED,
  REFUND_BALANCE_TNC,
  USER_CHARACTERISTICS,
} from "../../../profile/constants/profile-constants";
import {
  TETRA_PACK_KNOW_MORE,
  DEFAULT_BANNER_MAX_LIMIT,
} from "../../../listing/constants/listing-constants";
import { Clipboard } from "@ionic-native/clipboard/ngx";
import { LISTING_ROUTES } from "src/app/listing/constants/listing-routes";
import { EventTrackingPlugins } from "@Globals/constants/listing/enums";
import { Router } from "@angular/router";
import { CORE_ROUTES } from "@App/core/constants/coreRoutes";
import { EXIT_PAGE_ROUTES_MAPPER } from "@App/core/constants/core-constants";
declare var window: any;

@Injectable({
  providedIn: "root",
})
export class DataService {
  successCbResult = {};
  isShadowRequired: boolean;
  deepLinkOnAppLaunch: { url?: string; dismissLoaderTimer?: number } = {};
  showInviteModalOnHome = false;
  showInviteModalOnShoppingCredits = true;
  showReferralSuccessModal = true;
  keyboardHeight = "0";
  knockKnock: Subject<any> = new Subject();
  oneTimeTimerForSlidingActiveSegmentIntoView = 0; // milliseconds
  paymentOfferThroughLowBalancePopup: Subject<any> = new Subject();
  paymentOfferAmountChanged: Subject<any> = new Subject();
  saveUserRating = null;
  mBeyondAllCollection;
  parentProductId: number;
  showFrozenProductA2BAlert = true;
  allImpulseProductsBought = false;
  // categoryProductsSegregatedOnSubCategories will have complete products data for YFO category
  // And for others categories it will just have initial set of products data(page 1, pageSize 10) for each subcategories for just checking that current subcategory has products
  categoryProductsSegregatedOnSubCategories: { string?: Array<any> } = {};
  productImpressionConfigData: any = {
    componentType: null,
    banner: {},
  };
  collectionsConfigData = {
    leafRequest: [],
    listImageUrl: null,
    collection: null,
  };
  segmentData: SegmentData = {};
  addVoucherComponentData: CartVoucherPromotionData;
  homeStripContent = Object.assign({}, HOME_STRIP_CONTENTS);
  homeOfferStripContent: string;
  membershipConfirmationData: MembershipConfirmationData;
  isFrozenCheckEnabled = false;
  membershipData;
  externalUrl: ExternalUrl = {
    url: "",
    parameters: false,
    title: "",
    backgroundColor: "",
  };
  contentScrollPosition = 0;
  dontShowSubsciptionAgain = false;
  membershipInfoMetaDataMessage = MEMBERSHIP_INFO.META_DATA.MESSAGE;
  fileStorageDirectories = FILE_STORAGE_DIRECTORIES;
  membershipInfoMetaDataFirebaseValue = Object.assign(
    {},
    MEMBERSHIP_INFO_METADATA
  );
  // Added default platform as Android because of payments changes (we might need to work on this for web app release)
  currentPlatform = Utilities.isApp()
    ? this.platform.is(ANDROID)
      ? ANDROID
      : IOS
    : WEB;
  doesUserBelongToBulkBuyHub = false;
  pgFromDeepLink: PaymentOption;
  offerViewTypesMetaData = OFFER_VIEW_TYPES_METADATA;
  searchEventSource = "";
  autoDebitUserData: any = {};
  showSSPHConfirmation = false;
  doubleClickJourneyOnSearch = false;
  mbPassData: any = {
    applyCategoryAndSubcategory: {},
  };
  showRecurringCoachMark = true;
  isFirstImageLoaded = false;
  openSelfHelpUrl = null;
  timeRecheckErrorLastShownOn;
  ishomeRecommendationExpanded = true;
  recurringRecommendations: RecRecommendations = {};
  recRecommendationObject = {};
  recRecommendationFromStorage = {};
  showSeeSimilar = false;
  subscribeAndSaveNudge = false;
  brandLaunchData: any;
  likedCollectionsList = [];
  public updateCollection: Subject<boolean> = new Subject<boolean>();
  calendarMetaData: any = {
    plan: null,
    headerText: "",
    productInfo: {},
    flow_type: "",
    updateDefaultMonth: false,
  };
  calendarPlanObject: CalendarPlan = { constantQuantity: 1, quantity: {} };
  calendarCoachmarkObject: any = {};
  cashbackCoachmarkObject: any = {};
  recurringOrdersChange: Subject<boolean> = new Subject<boolean>();
  runChangeDetForPromo: Subject<boolean> = new Subject<boolean>();
  runChangeDetForA2B: Subject<boolean> = new Subject<boolean>();
  runChangeDetectionForProductOrders: Subject<any> = new Subject<any>();
  runChangeDetForBasketUpdate: Subject<boolean> = new Subject<boolean>();
  refreshFutureBaskets: Subject<boolean> = new Subject<boolean>();
  runChangeDetForPaymentData: Subject<boolean> = new Subject<boolean>();
  runChangeDetForMBPass: Subject<boolean> = new Subject<boolean>();
  runChangeDetForMembership: Subject<boolean> = new Subject<boolean>();
  runChangeDetForUserInfo: Subject<boolean> = new Subject<boolean>();
  runChangeDetForAddressChange: Subject<boolean> = new Subject<boolean>();
  showFestiveHeader: Subject<boolean> = new Subject<boolean>();
  activeOffers = [];
  activeOffersObject = {};
  offerTypeMapping = {};
  membershipDiscount = {};
  offersLastUpdatedOn;
  frequentSearches: Array<any> = [];
  uuid: string;
  isApp = window.cordova.platformId !== "browser";
  public _isJioUserSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isJioUserObs: Observable<boolean> =
    this._isJioUserSubject.asObservable();
  addressUpdateAlert: Subject<boolean> = new Subject<boolean>();
  offerUpdate: Subject<boolean> = new Subject<boolean>();
  inviteCodes: Array<WelcomeCode>;
  openPromoModal: boolean = false;
  ipCoordinates: any = {};
  draggenedCoordinate: any;
  openPromoListing: Subject<boolean> = new Subject<boolean>();
  societyErrorCode: string;
  denyCount: number = 0;
  yfoTrendingConfigData: any = {};
  quantitySum: number = 0;
  userExistInRecycleBagHub: boolean = false;
  firebaseMasterData: FirebaseMasterData = {
    jioCashBackConstruct: JIO_CASHBACK_CONSTRUCT,
    showInternalAppRatingPopUp: true,
    membershipRevisedConstruct: MEMBERSHIP_REVISED_CONSTRUCT,
    accountClosureLeaveReason: ACCOUNT_CLOSURE_REASON,
    isSearchBar: true,
    settingsMembershipContent: SETTINGS_MEMBERSHIP_CONTENT,
    frozenProductA2BData: FROZEN_PRODUCT_A2B_DATA,
    paymentOfferStrip: null,
    frozenProductA2BMetaData: FROZEN_PRODUCT_A2B_META_DATA,
    yfo_config_data: YFO_TRENDING_CONFIG_DEFAULT_VALUES,
    billDetailLabels: BILL_DETAIL_LABELS,
    extraFeeDetails: EXTRA_FEE_DETAILS_DEFAULT_VALUES,
    homeStripPromotion: HOME_STRIP_CONTENT,
    cartPromotionData: CART_PROMOTION_DATA_DEFAULT_VALUE,
    auto_debit_banners: AUTO_DEBIT_BANNERS,
    pdpInfo: {},
    whatsAppSharingCollectionMetadata:
      WHATSAPP_SHARING_COLLECTION_METADATA_DEFAULT_VALUE,
    refillTypesMetaData: REFILL_TYPES_META_DATA,
    pgImagesData: PG_IMAGES_DEFAULT_DATA,
    categorySubcategoryClosedInfoText:
      CATEGORY_SUBCATEGORY_CLOSED_INFO_DEFAULT_TEXT,
    newPaymentGateways: {},
    ssph_config: SSPH_CONFIG,
    orderLimitReachedAlertData: ORDER_LIMIT_REACHED_ALERT,
    bulkBuyData: BULK_BUY_DATA,
    superChargeData: SUPER_CHARGE_CONFIG_DATA,
    infoPopUp: INFO_POP_UP,
    showSubCategoriesOnPDP: true,
    cashCollectionRemovalInfoData: CASH_COLLECTION_REMOVAL_INFO_DEFAULT_DATA,
    farmRouteInfo: FARM_ROUTE_INFO,
    cancelledProductsConfig: CANCELLED_PRODUCTS_DEFAULT_CONFIG,
    removeFrozenCheckForRespectiveCities: [7],
    deliverySlotInfo: DELIVERY_SLOT_INFO,
    deliveryTiming: DELIVERY_TIMING,
    taxesLabel: TAXES_LABEL,
    extraFeeLabel: EXTRA_FEE_LABEL,
    membershipInfoMetaData: Object.assign({}, MEMBERSHIP_INFO_METADATA),
    selfhelp_version: environment.SELFHELP.version,
    showRatingThankYouText: true,
    recurringCampaignThreshold: 0,
    showRecycleHubs: [],
    liveNowObj: LIVE_NOW_OBJECT,
    knowMoreTetraPack: TETRA_PACK_KNOW_MORE,
    showTetraPackHubs: {},
    milkbasketSupportEmail: MILKBASKET_SUPPORT_EMAIL,
    characteristics: USER_CHARACTERISTICS,
    homeSchema: {},
    maxLimits: MAX_LIMITS,
    festiveSeason: festiveSeason,
    RecentSearchItemsCount: 15,
    OTDFDeliveryFee: 15,
    userMembership: {
      freeMembership: "2",
      membershipPrice: 149,
    },
    showLiveNowOn: {
      "icon-recycle": "icon-recycle",
      "icon-orders": "icon-orders",
    },
    cashbackPercentage: {
      members: 10,
      nonMembers: 5,
    },
    maxOTDFFeeValue: 400,
    subscribeToSaveOnePager: 439,
    refundBalanceTnc: REFUND_BALANCE_TNC,
    bottomStripSortOder: [1, 2],
    recRecommendationText: REC_RECOMMENDATION_TEXT,
    jiopaySegment: null,
    newSearchProductListingFlowHubIds: {},
    MbUniversalWidgets: {
      "pages": {},
      "widgets": {
        "MbCalendarJourney": {
          "baseUrl": "https://adhoc.milkbasket.com/",
          "sliderImages": [
            "recurring/subs_bg.png",
            "recurring/journey2.png",
            "recurring/journey3.png"
          ],
          "externalLinkText": "learn how to use subscription"
        },
        "MbAddressVerificationInfo": {
          "ADDRESS_VERIFICATION_INFO": {
            "PLEASE_NOTE": "Please note:",
            "INFO_LINE_1": "Address Verification might take upto 7 days.",
            "INFO_LINE_2": "You will be notified once your address is successfully verified."
          }
        },
        "MbCaptureInstantRefund": {
          "REFUND_BALANCE_TNC": [
            "Upon deactivating your account, the refundable amount will be credited to your source account within 9-12 business days.",
            "The refundable amount is subject to any deductions such as subscription fees, etc.",
            "The refundable amount excludes cashbacks."
          ]
        },
        "MbSubscriptionSearch": {
          "suggestions": [
            {
              "value": "Milk",
              "text": "Milk"
            },
            {
              "value": "Bread",
              "text": "Bread"
            },
            {
              "value": "Real Juice",
              "text": "Real Juice"
            },
            {
              "value": "Eggs",
              "text": "Eggs"
            },
            {
              "value": "Yoghurt",
              "text": "Yoghurt"
            },
            {
              "value": "Dahi",
              "text": "Dahi"
            },
            {
              "value": "Apple",
              "text": "Apple"
            },
            {
              "value": "Tomato",
              "text": "Tomato"
            },
            {
              "value": "Nutrela",
              "text": "Nutrela"
            },
            {
              "value": "Juice",
              "text": "Juice"
            },
            {
              "value": "Washing Powder",
              "text": "Washing Powder"
            },
            {
              "value": "Noodles",
              "text": "Noodles"
            }
          ]
        },
        "MbProfilePersonalPreferences": {
          "food": [
            {
              "value": "Eggetarian",
              "text": "Eggetarian"
            },
            {
              "value": "Vegetarian",
              "text": "Vegetarian"
            },
            {
              "value": "Non-Vegetarian",
              "text": "Non-Vegetarian"
            },
            {
              "value": "Vegan",
              "text": "Vegan"
            }
          ],
          "characteristics": [
            {
              "value": "tea_lover",
              "text": "Tea Lover"
            },
            {
              "value": "coffee_lover",
              "text": "Coffee Lover"
            },
            {
              "value": "fan_of_organic_foods",
              "text": "Fan of organic foods"
            },
            {
              "value": "health_freak",
              "text": "Health Freak"
            },
            {
              "value": "mid_night_snacking",
              "text": "Mid-night snacking"
            },
            {
              "value": "clean_freak",
              "text": "Clean freak"
            },
            {
              "value": "enjoy_cooking_meals",
              "text": "Enjoy cooking meals"
            },
            {
              "value": "sweet_tooth",
              "text": "Sweet tooth"
            },
            {
              "value": "crave_savory_items",
              "text": "Crave savory items"
            },
            {
              "value": "fan_of_baked_goods",
              "text": "Fan of baked goods"
            },
            {
              "value": "rely_on_ready_to_eat",
              "text": "Rely on ready to eat"
            }
          ]
        },
        "MbCashbackWalletGuideline": {
          "EXCLUDED_SUBCATEGORIES": [],
          "CASHBACK_CONTENT_CART": "Not applicable on items from Oils & Ghee, Fresh Milk, and Baby Food categories",
          "CASHBACK_GUIDELINES_SUBHEADING": "Applicable to all services and items, except Oils & Ghee, Fresh Milk & Baby Food.",
          "MEMBERS": [
            "Membership charges (buying or renewal) included in your order value shall be fully paid by this wallet balance, if available.",
            "Cashback wallet balance shall be used to pay 10% of your total order value, excluding Oil & ghee, Fresh Milk, and Baby Food.",
            "Cashback balance not applicable on Oil & ghee, Fresh Milk, and Baby Food."
          ],
          "NON_MEMBERS": [
            "Membership charges (buying or renewal) included in your order value shall be fully paid by this wallet balance, if available.",
            "Cashback wallet balance shall be used to pay 5% of your total order value, excluding Oil & ghee, Fresh Milk, and Baby Food.",
            "Cashback balance not applicable on Oil & ghee, Fresh Milk, and Baby Food."
          ]
        },
        "MbSignupJourney": {
          "SIGNUP_JOURNEY_SWIPER": [
            {
              "img": "https://adhoc.milkbasket.com/signup-spalsh-img1@2x.webp",
              "heading": "Order till midnight",
              "description": "Get your essentials delivered \nto you by 7 AM"
            },
            {
              "img": "https://adhoc.milkbasket.com/signup-spalsh-img3@2x_.png",
              "heading": "Seamless Ordering!",
              "description": "Simply add items throughout the day receive it the next morning"
            }
          ]
        },
        "MbSearch": {
          "RecentSearchItemsCount": 5
        }
      }
    },
  };
  restOfTheMonthDisplayData: Array<Basket>;
  restOfTheMonthDisplayDataBackUp: string;
  promiseResolve: any;
  isBackButtonPressed: boolean = false;
  isUserWalletFreeze: boolean = false;
  selectedPDPProduct: BehaviorSubject<Object> = new BehaviorSubject(null);
  addressUnderVerificationAlert: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  getHomeSchema: Subject<boolean> = new Subject<boolean>();
  membershipPlanMaxDiscount = 0;
  isBottomBilldetailsStripVisible = true;
  scrollStartOnClick: BehaviorSubject<string> = new BehaviorSubject("");
  targetMembership: BehaviorSubject<TargetMembership> = new BehaviorSubject(
    null
  );
  refreshMbPass: Subject<boolean> = new Subject<boolean>();
  membershipCardSlideIndex = 0;
  productVariantInfo = {
    isVariantAddedToCart: false,
    latestVariantIndexIntoCart: null,
    parentProductIndex: null,
  };

  // Events variables
  onePagerCollectionRedirect: any = {};
  trackedCollectionImpression: any = {};
  trackedCategoryImpressionHome: any = {};
  trackedCategoryImpressionCategory: any = {};
  trackedProductImpression: any = {};
  // Events variables

  // membership plans
  membershipPlans: MembershipPlansData[] = [];
  isUnserviceableFlow: boolean = false;
  goBackSubjectForUnserviceableFlow: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(this.isUnserviceableFlow);
  isVariantBottomSheetOpen: boolean = false;
  variantShouldOpen: any = {
    status: false,
    data: { product: {}, parentIndex: null },
  };
  openVariantModal: Subject<boolean> = new Subject<boolean>();
  backButtonOfferHandling: boolean = false;
  isMembershipApplied: boolean = false;
  refetchMembershipStrip: Subject<string> = new Subject<string>();
  showUpcomingSocietyModal: Subject<boolean> = new Subject<boolean>();

  paramData: any = {};
  openFaqModal: boolean = false;
  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private authService: AuthService,
    private platform: Platform,
    private loggingService: LoggingService,
    private cleverTap: CleverTap,
    private toastService: ToastService,
    private clipboard: Clipboard,
    private navCtrl: NavController,
    private eventsService: EventsService,
    private router: Router
  ) {}

  toggleSliderSwipes = (slider: IonSlides, sliderSwipeState: boolean) => {
    slider && slider.lockSwipes && slider.lockSwipes(sliderSwipeState);
  };

  getVariantModal() {
    if (
      this.isVariantBottomSheetOpen &&
      (this.router.url.includes("/listing/product-detail/") ||
        this.router.url.includes("/listing/calendar-modal/"))
    ) {
      this.checkAndCloseAllModals();
    }
  }

  checkAndCloseAllModals = () => {
    this.modalCtrl
      .getTop()
      .then((topModal) => {
        if (topModal) {
          this.modalCtrl.dismiss().catch(noop);
        }
      })
      .catch((error) => {
        this.loggingService.logMessage(
          "Error while fetching top modal: ",
          error
        );
      });
  };

  dismissAllModal = () => {
    this.modalCtrl
      .getTop()
      .then((topModal) => {
        if (topModal) {
          topModal.dismiss().then(() => {
            this.dismissAllModal();
          });
        }
      })
      .catch((error) => {
        this.loggingService.logMessage(
          "Error while fetching top modal: ",
          error
        );
      });
  };

  showOfferAppliedState = (offerData, imageBasePath) => {
    if (offerData?.length === 1) {
      const contentKeysToBeReplaced = this.generateOfferContent(
        offerData[0],
        imageBasePath
      );
      OFFER_APPLIED_INFO.BUTTONS.CLOSE.handler = noop();
      DISOUNTED_PRODUCT_OFFER_APPLIED_INFO.BUTTONS.OKAY.handler = () => {
        this.navCtrl.navigateForward(
          `${CORE_ROUTES.DISCOUNTED_PRODUCT}/${offerData[0]?.discountedProductsListId}`,
          { animated: false }
        );
      };
      const pastCutOffTimeContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
        OFFER_APPLIED_INFO.META_DATA,
        contentKeysToBeReplaced
      );

      if (offerData[0]?.benefit_type === RewardType.DiscountedProduct) {
        this.alertService.presentAlertConfirm(
          pastCutOffTimeContent,
          [DISOUNTED_PRODUCT_OFFER_APPLIED_INFO.BUTTONS.OKAY],
          DISOUNTED_PRODUCT_OFFER_APPLIED_INFO.CSS_CLASS,
          DISOUNTED_PRODUCT_OFFER_APPLIED_INFO.BACK_DROP_DISMISS
        );
      } else {
        this.alertService.presentAlertConfirm(
          pastCutOffTimeContent,
          [OFFER_APPLIED_INFO.BUTTONS.CLOSE],
          OFFER_APPLIED_INFO.CSS_CLASS,
          OFFER_APPLIED_INFO.BACK_DROP_DISMISS
        );
      }
    } else {
      let offerMessage = "";
      let message = `
      <li class="d-flex">
      <div>
      <ion-thumbnail>
        <img alt="offer-gifts" src="../../../../assets/img/offer-gits.svg" /> 
      </ion-thumbnail>
      </div>
      <div>
        <h4>offer_text</h4>
      </div>
      </li>
      `;
      for (const offer of offerData) {
        const offerText = message.replace(
          "offer_text",
          this.generateOfferContent(offer, imageBasePath, true)
        );
        offerMessage += offerText;
        const eventArgs = {
          "Offer id": offer.offer_id,
          "Offer type": offer.offer_type,
          "Reward type": offer.benefit_type,
        };
        this.eventsService.trackEvents(EVENT_NAMES.SAMPLE_ADDED, eventArgs);
      }
      const offerInfo = GENERATE_CONTENT_FOR_ALERT_LAYER(
        OFFER_DETAIL_ALERT.META_DATA,
        { offers_info: offerMessage }
      );
      this.alertService.presentAlertConfirm(
        offerInfo,
        [OFFER_DETAIL_ALERT.BUTTONS.CLOSE],
        OFFER_DETAIL_ALERT.CSS_CLASS
      );
    }
  };

  generateOfferContent(
    offerData,
    imageBasePath,
    generateForMultipleOffers = false
  ) {
    let contentKeysToBeReplaced: any = {};
    switch (offerData.benefit_type) {
      case RewardType.Sample:
        if (
          OFFER_VIEW_TYPES.DISCLOSED ===
          this.activeOffersObject[offerData.offer_id].offerDetails.viewType
        ) {
          contentKeysToBeReplaced.img = `<img src="${
            imageBasePath + offerData.product_image
          }">`;
          contentKeysToBeReplaced.heading = this.offerViewTypesMetaData[
            OFFER_VIEW_TYPE.DISCLOSED
          ].heading.replace(
            `{${
              this.offerViewTypesMetaData[OFFER_VIEW_TYPE.DISCLOSED]
                .keyToBeReplaced
            }}`,
            offerData.product_name
          );
        } else {
          contentKeysToBeReplaced =
            this.offerViewTypesMetaData[OFFER_VIEW_TYPE.UNDISCLOSED];
        }
        contentKeysToBeReplaced.subheading = "";
        break;
      case RewardType.MbPass:
        contentKeysToBeReplaced = MB_PASS_METADATA;
        break;
      case RewardType.Cashback:
        if (offerData.cashback_type === "FLAT") {
          contentKeysToBeReplaced.img = CASHBACK_METADATA.img;
          contentKeysToBeReplaced.heading = CASHBACK_METADATA.heading.replace(
            `{${CASHBACK_METADATA.keyToBeReplaced}}`,
            offerData.max_cashback
          );
          contentKeysToBeReplaced.subheading = CASHBACK_METADATA.subheading;
        } else {
          contentKeysToBeReplaced.img = PERCENT_CASHBACK_METADATA.img;
          contentKeysToBeReplaced.heading =
            PERCENT_CASHBACK_METADATA.heading.replace(
              `{${PERCENT_CASHBACK_METADATA.keyToBeReplaced[0]}}`,
              offerData.cashback_rate
            );
          contentKeysToBeReplaced.heading =
            contentKeysToBeReplaced.heading.replace(
              `{${PERCENT_CASHBACK_METADATA.keyToBeReplaced[1]}}`,
              offerData.cashback_validity
            );
          contentKeysToBeReplaced.subheading =
            PERCENT_CASHBACK_METADATA.subheading.replace(
              `{${PERCENT_CASHBACK_METADATA.keyToBeReplaced[2]}}`,
              offerData.max_cashback
            );
        }
        break;
      case RewardType.DiscountedProduct:
        contentKeysToBeReplaced = DISCOUNT_PRODUCT_METADATA;
        break;
    }
    return generateForMultipleOffers
      ? contentKeysToBeReplaced.heading
      : contentKeysToBeReplaced;
  }

  onScroll(event) {
    this.isShadowRequired = event.detail.scrollTop !== 0;
  }

  clearFirebaseConfigData() {
    this.segmentData = null;
  }

  updateFlagIsFrozenCheckEnabled() {
    this.isFrozenCheckEnabled =
      this.firebaseMasterData.removeFrozenCheckForRespectiveCities.indexOf(
        this.authService.userData.cityObj.id
      ) === -1;
  }

  parseAndAssignStartEndDateToDateObject(product) {
    if (product.startDate && product.endDate) {
      product.startDate = moment(product.startDate);
      product.endDate = moment(product.endDate);
      const currDate = moment();
      product.isPreOrder =
        moment(product.order_start_date) < currDate &&
        moment(product.order_end_date) > currDate;
      product.isStartDateEndDateSame = product.startDate.isSame(
        product.endDate
      );
    }
  }

  showMembershipInfo(
    membership,
    eventSource,
    needToShowFrozenInformation = false
  ) {
    this.firebaseMasterData.membershipInfoMetaData = Object.assign(
      {},
      this.membershipInfoMetaDataFirebaseValue
    );
    MEMBERSHIP_INFO.META_DATA.MESSAGE = this.membershipInfoMetaDataMessage;
    MEMBERSHIP_INFO.META_DATA.MESSAGE = Utilities.replaceKeysWithValues(
      MEMBERSHIP_INFO.META_DATA.MESSAGE,
      this.firebaseMasterData.membershipInfoMetaData[membership.state]
    );
    if (
      this.firebaseMasterData.membershipInfoMetaData[membership.state]
        .frozenInfo &&
      needToShowFrozenInformation
    ) {
      MEMBERSHIP_INFO.META_DATA.MESSAGE +=
        this.firebaseMasterData.membershipInfoMetaData[
          membership.state
        ].frozenInfo;
      this.showFrozenProductA2BAlert = false;
      localStorage.setItem(STORAGE_KEYS.SHOW_FROZEN_PRODUCT_A2B_ALERT, "false");
    }
    MEMBERSHIP_INFO.META_DATA.MESSAGE += "</div>";
    MEMBERSHIP_INFO.BUTTONS.OKAY.text =
      this.firebaseMasterData.membershipInfoMetaData[membership.state].ctaLabel;
    MEMBERSHIP_INFO.BUTTONS.OKAY.handler = (selectedCheckbox) => {
      if (
        this.firebaseMasterData.membershipInfoMetaData[membership.state]
          .checkLabel
      ) {
        this.dontShowSubsciptionAgain = selectedCheckbox.length > 0;
        localStorage.setItem(
          STORAGE_KEYS.DONT_SHOW_SUBSCRIPTION_AGAIN,
          this.dontShowSubsciptionAgain.toString()
        );
        this.eventsService.trackEvents(EVENT_NAMES.MEMBERSHIP_CHECKBOX_VALUE, {
          isMembershipCheckboxChecked: this.dontShowSubsciptionAgain,
        });
      }
    };
    MEMBERSHIP_INFO.INPUT[0].label =
      this.firebaseMasterData.membershipInfoMetaData[
        membership.state
      ].checkLabel;
    MEMBERSHIP_INFO.INPUT[0].checked = this.dontShowSubsciptionAgain;
    const membershipDynamicValues = {
      price: membership.cp,
      validityDays: membership.validity,
      startDate: membership.displayStartDate,
      endDate: membership.displayEndDate,
      daysLeft: membership.daysLeft,
    };
    const membershipInfoContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
      MEMBERSHIP_INFO.META_DATA,
      membershipDynamicValues
    );
    this.alertService.presentAlertConfirm(
      membershipInfoContent,
      [MEMBERSHIP_INFO.BUTTONS.OKAY],
      MEMBERSHIP_INFO.CSS_CLASS,
      true,
      this.firebaseMasterData.membershipInfoMetaData[membership.state]
        .checkLabel
        ? MEMBERSHIP_INFO.INPUT
        : []
    );
    this.eventsService.trackEvents(
      membership.state === 2 || membership.state === 4
        ? EVENT_NAMES.MB30_DETAILS_PAGE_USAGE
        : EVENT_NAMES.MB30_DETAILS_PAGE_DISCOVERY,
      {
        userType:
          membership.sp === 0
            ? EVENT_SOURCES.NEW_USER
            : EVENT_SOURCES.EXISTING_USER,
        Source: eventSource,
      }
    );
  }

  //Required in future

  // showDetailedExtraPrice(billDetails: BillDetails, eventSource, membershipVisibility,userCurrentMembership,isUserExistsInOTDF?) {
  //   EXTRA_FEE_DETAILS.BUTTONS.CLOSE.handler = noop();
  //   EXTRA_FEE_DETAILS.META_DATA.HEADER_TEXT = this.firebaseMasterData.extraFeeDetails.heading;
  //   EXTRA_FEE_DETAILS.META_DATA.MESSAGE = '<ul class="packing-items-list 111 ion-no-margin ion-no-padding">';
  //   EXTRA_FEE_DETAILS.BUTTONS.OK.handler = () => {
  //   };
  //   EXTRA_FEE_DETAILS.BUTTONS.OKAY.handler = () => {
  //    };
  //   if (billDetails.covid_fee && billDetails.covid_fee !== 0 && !this.doesUserBelongToBulkBuyHub) {
  //     (EXTRA_FEE_DETAILS.META_DATA.MESSAGE += '<li><p>' +
  //       this.firebaseMasterData.billDetailLabels.covid_fee
  //       + '</p><p class="f-w-m fnt-14"><i class="icon-rupee icon-base icons-property"></i>' +
  //       billDetails.covid_fee?.toFixed(2)
  //       + '</p><div class="divider"></div></li>');
  //   }
  //   EXTRA_FEE_DETAILS.META_DATA.MESSAGE += '<li><p>' +
  //     this.firebaseMasterData.billDetailLabels.sub_total
  //     + '</p><p class="f-w-m fnt-14"><i class="icon-rupee icon-base icons-property"></i>' +
  //     billDetails.sub_total?.toFixed(2)
  //     + '</p></li><li><p>' +
  //     this.firebaseMasterData.billDetailLabels.savings
  //     + '</p><p class="f-w-m fnt-14 color-success"><i class="icon-subtract icons-property"></i><i class="icon-rupee icon-base icons-property"></i>' +
  //     billDetails.sub_savings?.toFixed(2)
  //     + `</p>${membershipVisibility ? '<div class="divider"></div>' : ''}</li>`;

  //   if (billDetails.gst_services || billDetails.monthly_charge || billDetails.in_hand_delivery) {
  //     if (membershipVisibility) {
  //       if(userCurrentMembership){
  //         const validityKey = this.getMembershipPlanMonths(userCurrentMembership.validity);
  //         this.firebaseMasterData.billDetailLabels.monthly_charge = `${validityKey} charge`;
  //       }
  //       (billDetails.monthly_charge && billDetails.monthly_charge !== 0) && (EXTRA_FEE_DETAILS.META_DATA.MESSAGE += '<li><p><span id="plan-width"> ' +
  //         this.firebaseMasterData.billDetailLabels.monthly_charge
  //         + '</span></p><p class="f-w-m fnt-14"><i class="icon-rupee icon-base icons-property"></i>' +
  //         billDetails.monthly_charge?.toFixed(2)
  //         + '</p></li>');
  //         setTimeout(function(){
  //           let calcPlanWidth = document.getElementById('plan-width');
  //           if(calcPlanWidth){
  //               (document.querySelector('.mem-remove') as HTMLElement).style.left = calcPlanWidth.getBoundingClientRect().width + 21 +'px';
  //           }
  //         },500)

  //     }
  //     (billDetails.in_hand_delivery && billDetails.in_hand_delivery !== 0) && (EXTRA_FEE_DETAILS.META_DATA.MESSAGE += '<li><p>' +
  //       this.firebaseMasterData.billDetailLabels.in_hand_delivery
  //       + '</p><p class="f-w-m fnt-14"><i class="icon-rupee icon-base icons-property"></i>' +
  //       billDetails.in_hand_delivery?.toFixed(2)
  //       + '</p></li>');
  //       // if(billDetails.isOTDFDeliveryFeeOpted){
  //       //   EXTRA_FEE_DETAILS.META_DATA.MESSAGE += '<li><p>' +
  //       // 'Delivery fees'
  //       // + '</p><p><i class="icon-add icons-property"></i><i class="icon-rupee icon-base icons-property"></i>' +
  //       // 20
  //       // + '</p></li>'

  //       // }

  //     (billDetails.gst_services && billDetails.gst_services !== 0) && (EXTRA_FEE_DETAILS.META_DATA.MESSAGE += '<li><p>' +
  //       this.firebaseMasterData.billDetailLabels.gst_services
  //       + '</p><p class="f-w-m fnt-14"><i class="icon-rupee icon-base icons-property"></i>' +
  //       billDetails.gst_services?.toFixed(2)
  //       + '</p>');

  //     EXTRA_FEE_DETAILS.META_DATA.MESSAGE += '</li><li><p>Delivery fees</p><p class="f-w-m fnt-14"><span class="price-strikethrough"><i class="icon-rupee icon-base icons-property"></i>15</span><i class="icon-rupee icon-base icons-property"></i>0</p></li><li><p class="free-deli-txt">(free delivery with membership)</p><p></p><div class="divider"></div></li></ul>';
  //   }
  //   EXTRA_FEE_DETAILS.META_DATA.MESSAGE += '<ul class="packing-items-list ion-no-margin ion-no-padding">';
  //   EXTRA_FEE_DETAILS.META_DATA.MESSAGE += '<li class="f-w-b saving-total"><p>' +
  //     this.firebaseMasterData.billDetailLabels.total
  //     + '</p><p><i class="icon-rupee icon-base icons-property"></i>'
  //     + billDetails.total?.toFixed(2) +
  //     '</p></li></ul>';
  //   // EXTRA_FEE_DETAILS.META_DATA.NOTE = this.firebaseMasterData.extraFeeDetails.details;
  //   EXTRA_FEE_DETAILS.META_DATA.NOTE = '';
  //   EXTRA_FEE_DETAILS.META_DATA.HEADER_TEXT = 'Tomorrow’s order';
  //   const extraFeeContent = GENERATE_CONTENT_FOR_ALERT_LAYER(EXTRA_FEE_DETAILS.META_DATA);
  //   if(true){
  //    // console.log(billDetails.isOTDFDeliveryFeeOpted,'dhjfdafdsfhjdsghjdsfghj')
  //     this.alertService.presentAlertConfirm(
  //       extraFeeContent,
  //       [
  //        EXTRA_FEE_DETAILS.BUTTONS.CLOSE,
  //       // billDetails.isOTDFDeliveryFeeOpted?EXTRA_FEE_DETAILS.BUTTONS.OKAY:EXTRA_FEE_DETAILS.BUTTONS.OK,
  //        EXTRA_FEE_DETAILS.BUTTONS.CONFIRM,
  //       ],
  //       EXTRA_FEE_DETAILS.CSS_CLASS
  //     );
  //   } else {
  //     this.alertService.presentAlertConfirm(
  //       extraFeeContent,
  //       [
  //        EXTRA_FEE_DETAILS.BUTTONS.CLOSE
  //       ],
  //       EXTRA_FEE_DETAILS.CSS_CLASS
  //     );

  //   }

  //   this.eventsService.trackEvents(EVENT_NAMES.FEE_DETAILS, {
  //     source: eventSource
  //   });
  // }

  ifCalendarJourneyVisited() {
    if (
      this.calendarCoachmarkObject &&
      this.calendarCoachmarkObject.hasOwnProperty("calendarJourneyTaken")
    ) {
      return true;
    } else {
      return false;
    }
  }

  resetOffersData() {
    this.activeOffersObject = {};
    this.activeOffers = [];
    this.offerTypeMapping = {};
    this.offersLastUpdatedOn = undefined;
  }

  updateBAckButtonHandler() {
    this.platform.backButton.observers.splice(2);
  }

  openUserInbox() {
    const inboStyleConfig = {
      navBarTitleColor: APP_INBOX_STYLE_CONFIG.NAVBAR_TITLE_COLOR,
      navBarTitle: APP_INBOX_STYLE_CONFIG.NAVBAR_TITLE,
      navBarColor: APP_INBOX_STYLE_CONFIG.NAVBAR_COLOR,
      inboxBackgroundColor: APP_INBOX_STYLE_CONFIG.BACKGROUND_COLOR,
    };
    this.eventsService.trackEvents(EVENT_NAMES.IN_APP_NOTIFICATION, {});
    this.cleverTap
      .showInbox(inboStyleConfig)
      .then(() => {})
      .catch((error) => {
        this.loggingService.logMessage("Error while opening inbox: ", error);
      });
  }

  app_sendToApp(payload) {
    const encodedData = window.btoa(JSON.stringify(payload));
    if (window.android && window.android.__externalCall)
      window.android.__externalCall(encodedData);
    if (window.__externalCall) window.__externalCall(encodedData);
    if (
      this.platform.is(IOS) &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.callback
    ) {
      window.webkit.messageHandlers.callback.postMessage(encodedData);
    }
  }

  closeMBApp() {
    if (this.authService.jioMartData.isCalledFromHomeBanner === "banner") {
      window.open(environment.jioHomeUrl, "_self");
    } else {
      this.app_sendToApp({
        type: JIO_EVENTS.CLOSE,
      });
    }
    localStorage.removeItem(STORAGE_KEYS.ISJIOUSER);
  }

  copyCode(data: string, eventName?: string) {
    if (Utilities.isApp()) {
      this.clipboard.copy(data).then(() => {
        this.presentTextCopiedToast(eventName);
      });
    } else if (navigator.clipboard) {
      try {
        navigator.clipboard.writeText(data).then(() => {
          this.presentTextCopiedToast(eventName);
        });
      } catch (err) {
        this.copyCodeToClipboard(data, eventName);
      }
    } else {
      this.copyCodeToClipboard(data, eventName);
    }
  }

  copyCodeToClipboard(data: string, eventName?: string) {
    const clipboardCopyText = document.createElement("textarea");
    clipboardCopyText.value = data;
    clipboardCopyText.readOnly = true;
    clipboardCopyText.style.position = "fixed";
    clipboardCopyText.style.opacity = "0";
    document.body.appendChild(clipboardCopyText);
    clipboardCopyText.select();
    document.execCommand("copy");
    document.body.removeChild(clipboardCopyText);
    this.presentTextCopiedToast(eventName);
  }

  presentTextCopiedToast(eventName?) {
    eventName && this.eventsService.trackEvents(eventName, {});
    this.toastService.presentToast(REFER_CODE_COPIED);
  }

  updateJourneyTakenFlag() {
    if (this.calendarCoachmarkObject === null) {
      this.calendarCoachmarkObject = {};
    }
    this.calendarCoachmarkObject.calendarJourneyTaken = true;
    localStorage.setItem(
      STORAGE_KEYS.CALENDAR_COACHMARK_OBJECT,
      JSON.stringify(this.calendarCoachmarkObject)
    );
  }

  redirectToCalendarFAQ() {
    this.externalUrl.title = CALENDAR.FAQS;
    this.externalUrl.url = CALENDAR.URL;
    this.navCtrl.navigateForward(
      LISTING_ROUTES.BASE + LISTING_ROUTES.EXTERNAL,
      { animated: false }
    );
  }

  changeAddressPrepare() {
    this.eventsService.trackEvents(EVENT_NAMES.UPDATE_ADDRESS, {
      Source: EVENT_SOURCES.MENU,
    });
    this.authService.source = EVENT_SOURCES.CHANGE_ADDRESS;
    this.draggenedCoordinate = null;
  }

  addListener() {
    const el = document.querySelector(".option");
    el?.addEventListener("touchstart", () => {
      el.classList.add("scroll-x");
    });
    el?.addEventListener("touchend", () => {
      el.classList.remove("scroll-x");
    });
  }

  getMembershipPlanMonths(
    validityDays,
    FromBillDetailsPopup = false,
    isFreePlan = false
  ) {
    let plan: string = "";
    switch (validityDays) {
      case 30:
        plan = FromBillDetailsPopup ? "Monthly" : "1 Month";
        break;
      case 90:
        plan = "3 Months";
        break;
      case 180:
        plan = "6 Months";
        break;
      case 365:
        plan = "1 Year";
        break;
      default:
        plan = `${validityDays} Days`;
        break;
    }
    if (isFreePlan) {
      plan += " Free plan";
    }
    return plan;
  }

  getMembershipStatus(statusId: number) {
    if (statusId) {
      return {
        status: MEMBERSHIP.STATUS[statusId],
        cssClass: MEMBERSHIP.STATUS[statusId].toLowerCase(),
      };
    } else {
      return "";
    }
  }

  trackCollectionImpression(ratio, data, source) {
    if (!data) {
      return;
    }
    if (
      ratio >= 0.64 &&
      ratio <= 1 &&
      data.table &&
      !this.trackedCollectionImpression[data.id]
    ) {
      const eventArgs = {
        "Collection id": data.id,
        "Collection name": data.name || "",
        "Sort order": data.sortOrder,
        "Display position": data.displayPosition,
        Source: source,
      };
      this.trackedCollectionImpression[data.id] = true;
      this.eventsService.trackEvents(
        EVENT_NAMES.COLLECTION_IMPRESSION,
        eventArgs,
        false,
        EventTrackingPlugins.Firebase
      );
    }
  }

  formatHtml2String(data) {
    if (typeof data === "string") {
      const offerTnc = (data as unknown as string).replace(/<[^>]+>/g, "\n");
      return offerTnc.split("\n").filter((item) => !!item);
    } else {
      return data;
    }
  }
}
