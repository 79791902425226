export const PAYMENT_APIS = {
    REQUEST_CASH_COLLECTION: 'cash-collection/request',
    BALANCE: 'user/balance',
    INITIATE_TRANSACTION: 'transactions_new/initiate',
    INITIATE_TRANSACTION_JIO:'transaction_new/initiate_jio',
    TRACTION_QUERY: 'transactions/query',
    GET_SUPER_CHARGE_HISTORY: 'user/supercharge',
    APPLY_MEMBERSHIP: 'user/apply_membership',
    GET_CASH_COLLECTION_CONFIG: 'cash-collection/config',
    WALLET_ELIGIBILITY: 'payment/wallet-eligibility',
    PAYMENT_BANNER: 'banners/payment',
    UPDATE_AUTO_DEBIT: 'payment/auto-debit/',
    INVITE_CODES: 'user/active-invite-codes',
    DELETE_INVITE_CODE: 'user/delete_welcome_code',
    PAYMENT_BY_URL: 'payment/txn-juspay-call',
    RECURRING_CAMPAIGN_OFFER: 'offers',
    JUSPAY_CUSTOMER_ID: 'customers/ext?extSystem=JUSPAY',
    CUSTOMER_SEGMENT: 'home/customer-segment',
    TOP_UP:'home/topup-range'
};  
  
  