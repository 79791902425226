//#region Imports

//#region Angular Imports
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { OTP } from '@Globals/interfaces/registration/interfaces';
import { Utilities } from '@Globals/classes/utilities';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-email-otp',
  templateUrl: './email-otp.component.html',
  styleUrls: ['./email-otp.component.scss']
})
export class EmailOtpComponent extends DefaultComponent {
  otpTimings = {
    startTime: null,
    endTime: null
  };
  @Input() isError = false;
  @Input() errorMessage: string;
  OTP: OTP = {
    otp1: undefined,
    otp2: undefined,
    otp3: undefined,
    otp4: undefined
  };
  @ViewChild('otp1', { read: ElementRef, static: true }) otp1: ElementRef;
  @ViewChild('otp2', { read: ElementRef, static: true }) otp2: ElementRef;
  @ViewChild('otp3', { read: ElementRef, static: true }) otp3: ElementRef;
  @ViewChild('otp4', { read: ElementRef, static: true }) otp4: ElementRef;
  @ViewChild('hidden', { read: ElementRef, static: true }) hidden: ElementRef;
  @Output() onInputChange = new EventEmitter();
  enableResend: boolean = false;
  updateOTPText: boolean = false;
  constructor() {
    super();
  }

  mbAfterViewInit(): void {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    setTimeout(() => {
      this.otp1.nativeElement.setFocus();
    }, 150);
    this.otp1.nativeElement.addEventListener(Utilities.isApp() ? 'ionInput' : 'keyup', this.restrictToDigitAndSetFocusOnNext.bind(this, this.otp1.nativeElement, this.otp2.nativeElement, 'otp1'));
    this.otp2.nativeElement.addEventListener(Utilities.isApp() ? 'ionInput' : 'keyup', this.restrictToDigitAndSetFocusOnNext.bind(this, this.otp2.nativeElement, this.otp3.nativeElement, 'otp2'));
    this.otp3.nativeElement.addEventListener(Utilities.isApp() ? 'ionInput' : 'keyup', this.restrictToDigitAndSetFocusOnNext.bind(this, this.otp3.nativeElement, this.otp4.nativeElement, 'otp3'));
    this.otp4.nativeElement.addEventListener(Utilities.isApp() ? 'ionInput' : 'keyup', this.restrictToDigitAndSetFocusOnNext.bind(this, this.otp4.nativeElement, this.hidden.nativeElement, 'otp4'));
  }

  restrictToDigitAndSetFocusOnNext(event, nextOtpInput, currentInputKey) {
    const enteredValue = parseInt(event.value, 10);
    if (enteredValue >= 0 && enteredValue <= 9) {
      event.value = enteredValue;
      this.OTP[currentInputKey] = enteredValue;
      if (nextOtpInput) {
        nextOtpInput.setFocus();
      }
    } else if (enteredValue && enteredValue > 999) {
      const enteredValueArray = event?.value?.split('');
      this.OTP.otp1 = parseInt(enteredValueArray[0], 10);
      this.OTP.otp2 = parseInt(enteredValueArray[1], 10);
      this.OTP.otp3 = parseInt(enteredValueArray[2], 10);
      this.OTP.otp4 = parseInt(enteredValueArray[3], 10);
    } else if (enteredValue && enteredValue > 9) {
      this.OTP[currentInputKey] = event.value = parseInt(
        event?.value?.split('')[1],
        10
      );
      if (nextOtpInput) {
        nextOtpInput.setFocus();
      }
    } else {
      this.OTP[currentInputKey] = event.value = undefined;
    }
    if (
      this.OTP.otp1 > -1 &&
      this.OTP.otp1 <= 9 &&
      this.OTP.otp2 > -1 &&
      this.OTP.otp2 <= 9 &&
      this.OTP.otp3 > -1 &&
      this.OTP.otp3 <= 9 &&
      this.OTP.otp4 > -1 &&
      this.OTP.otp4 <= 9
    ) {
      const currentOTP = `${this.OTP.otp1}${this.OTP.otp2}${this.OTP.otp3}${this.OTP.otp4}`;
      this.onInputChange.emit({ value: currentOTP, validOtp: true });
    } else {
      this.onInputChange.emit({ value: '', validOtp: false });
    }
  }

  resend() {
    this.enableResend = false;
    this.updateOTPText = true;
  }

  timerValue(time: number) {
    if (time === 0) {
      this.enableResend = true;
    }
  }
}
