<div class="top-fixed">
  <div class="fixed-bottom-sheet">
 <mbs-bottom-sheet-header [component]="viewTypes.AddAddress">
   Add Address
</mbs-bottom-sheet-header>
  </div>
  </div>
 <ion-content class="ctn-gt-space-p">
   <form id="addAddress" class="form pb-0"  
   #address_form="ngForm">
     <div class="f-body">
       <div class="details-section f-group">
        <div class="form-content margin-bottom-extra-big-large">
          <ion-label position="floating" class="f-w-b">City Name*</ion-label>
          <ion-item class="ion-no-padding" lines="none">
            <ion-input 
            (ionFocus) = "triggerCityChange()"
            [(ngModel)] = "cityData.name"
            required
            [ngModelOptions]="{standalone: true}"
            type="text" placeholder="Enter your City Name"></ion-input>
            <ion-icon class="icon-down-arrow icons-property"></ion-icon>
          </ion-item>
        </div>
        <div class="form-content margin-bottom-extra-big-large">
          <ion-label position="floating" class="f-w-b">Apartment Name*</ion-label>
          <ion-item class="ion-no-padding" lines="none">
            <ion-input 
            id="society_name"
            required
            #society_name
            (keyup) = "removeSpace(Input.APARTMENT, society_name.value)"
            name="society_name"
            [(ngModel)]="addressData.society_name"
            type="text" placeholder="Enter your Apartment Name"></ion-input>
          </ion-item>
        </div>
         <div class="form-content margin-bottom-extra-big-large margin-top-extra-big-large">
           <ion-label class="f-w-b">Tower No./Block/Locality*</ion-label>
           <ion-item class="ion-no-padding tower-no ion-invalid" lines="none">
             <ion-input 
             id = "tower"
             name = "tower"
             #tower
             (keyup) = "removeSpace(Input.TOWER, tower.value)"
             required
             [(ngModel)]="addressData.tower"
             type="text" placeholder="eg. Tower 1, Block A"></ion-input>
           </ion-item>
         </div>
         <div class="form-content margin-bottom-extra-big-large">
           <ion-label position="floating" class="f-w-b">Flat No./House No.*</ion-label>
           <ion-item class="ion-no-padding" lines="none">
             <ion-input 
             id = "unit"
             #unit
             name = "unit"
             type="text"
             required
             (keyup) = "removeSpace(Input.FLAT, unit.value)"
             [(ngModel)]="addressData.unit"
              placeholder="eg. 41"></ion-input>
           </ion-item>
         </div>
       </div>
       <ion-button margin-top-extra-big-large margin-bottom-extra-big-large fill="solid" (click)="submit()"
       size="large" 
        [disabled]="!address_form.valid"
         shape="round" expand="block">Submit</ion-button>
     </div>
   </form>
 </ion-content>
