import { ProductDelivery } from './product-delivery';
import { ProductAssets } from './assets/product-assets';
import { ProductPrice } from './product-price';
import { ProductWeight } from './product-weight';
import { ProductAvailability } from './product-availability';
import { ProductTag } from './product-tag';
import { ProductCategoryInfo } from './product-category-info';
import { ProductBadgeTypes, ProductTypes } from '../enums/product.enums';
import { ProductRecurrence } from './product-recurrence';
import { ProductSource } from './product-source';
import { ProductBundle } from './product-bundle';

export class Product {
    __typename = 'Product';
    id: number; // was product_id, id in same order
    name: string; // was product_name, nm, name in same order
    type: number; // merged with sample, product type can be, 1: Product, 2: Sample
    bundle?: ProductBundle;
    badge: number; // merged with isNew, product type can be, 0: None, 1: New
    stickerType?: string; // was sticker
    recurrence: ProductRecurrence; // merged recurring, recText
    category?: ProductCategoryInfo; // merged cid, scid
    delivery: ProductDelivery; // merged delivery_types_slot, deliveryTypeId
    availability: ProductAvailability;
    assets: ProductAssets;
    tag?: ProductTag;
    source?: ProductSource;
    weight: ProductWeight;
    price: ProductPrice; // merged mbeyondSp, mrp, pricePerUnit, sp

    setProductFromProductApi(productApi) {
        this.id = productApi.product_id || productApi.id;
        this.name = productApi.product_name || productApi.nm || productApi.name;
        this.type = productApi.sample ? ProductTypes.Sample : ProductTypes.Product;
        this.badge = productApi.isNew ? ProductBadgeTypes.New : ProductBadgeTypes.None;
        this.stickerType = productApi.sticker || null;

        const recurrence = new ProductRecurrence();
        this.recurrence = recurrence.setRecurrenceFromApi(productApi);

        const category = new ProductCategoryInfo();
        this.category = category.setCategoryFromApi(productApi);

        const delivery = new ProductDelivery();
        this.delivery = delivery.setDeliveryFromApi(productApi);

        const availability = new ProductAvailability();
        this.availability = availability.setAvailabilityFromApi(productApi);

        const assets = new ProductAssets();
        this.assets = assets.setAssetsFromApi(productApi);

        const tag = new ProductTag();
        this.tag = tag.setTagFromApi(productApi);

        const source = new ProductSource();
        this.source = source.setSourceFromApi(productApi);

        const weight = new ProductWeight();
        this.weight = weight.setWeightFromApi(productApi);

        const price = new ProductPrice();
        this.price = price.setPriceFromApi(productApi);

        const bundle = new ProductBundle();
        this.bundle = bundle.setProductBundleFromProductApi(productApi);

        return this;
    }
}
