//#region Imports

//#region Angular Imports
import { Component, HostListener, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from 'src/app/shared/providers/master-data/master-data.service';
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
import { ProductListType } from '@App/listing/constants/listing-enums';
import { MODAL_IDS } from '@Globals/constants/payment/constants';
import { EVENT_SOURCES } from '@Globals/constants/listing/constants';
import { Utilities } from '@Globals/classes/utilities';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-product-variant-list',
  templateUrl: './product-variant-list.component.html',
  styleUrls: ['./product-variant-list.component.scss']
})
export class ProductVariantListComponent extends DefaultComponent {
  @Input() parentProductData;
  @Input() variantProduct: Array<any> = [];
  @Input() componentType = ProductListType.Product_Variant_List;
  @Input() eventSource;
  @Input() itemType = EVENT_SOURCES.VARIANT;
  @Input() eventData;
  @HostListener('scroll', ['$event']) 
  scrollHandler(event) {
    const variantHeader = Utilities.getElementsByTagName('ion-header');
    if(variantHeader){
      variantHeader.classList.add('has-shadow');
    }
    if(event.target.scrollTop === 0){
      variantHeader.classList.remove('has-shadow');

    }
  }
  constructor(
    private modalCtrl: ModalController,
    public masterDataService: MasterDataService,
    public dataService: DataService,
  ) {
    super();
  }

  mbOnInit(): void {
    this.dataService.isVariantBottomSheetOpen = true;
    this.parentProductData._flHide = this.parentProductData.oos || this.parentProductData.forcedOos || !this.parentProductData.highlightColor || !this.parentProductData.title;
    this.parentProductData._source = this.masterDataService.staticMasterData?.productSource && this.masterDataService.staticMasterData?.productSource[ this.parentProductData.source ] || '';
  }

  closeModal(data?) {
    this.dataService.isVariantBottomSheetOpen = false;
    this.modalCtrl.dismiss(data, '', MODAL_IDS.PRODUCT_VARIANT_POPUP);
  }

  mbOnDestroy(): void {
    this.dataService.isVariantBottomSheetOpen = false;
  }
}
