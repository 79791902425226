<ion-list *ngIf="paymentOffers?.name" class="ion-no-padding payment-offer-sec"
  [ngClass]="{'grid-view-ion-card': componentType === PaymentOfferType.LowBalancePopUp}">
  <ion-list-header class="ion-padding-end">
    <ion-label class="padding-right-small">{{paymentOffers.name}}</ion-label>
    <ion-button class="no-ripple-effect ion-text-uppercase ion-no-padding" (click)="navigateToAllOffers()" mode="md"
      fill="clear" color="dark" size="small">View all</ion-button> 
  </ion-list-header>
  <ion-item lines="none" class="ion-no-padding">
    <div class="scroll" scroll-x="true">
      <div class="payment-offer-list">
        <ion-card class="ion-no-margin" [ngClass]="{'active': offer.amount === selectedOffer?.amount}"
          *ngFor="let offer of paymentOffers?.table?.rows[0].columns" (click)="showPaymentOfferDetails(offer)">
          <ion-card-content class="ion-no-padding">
            <ion-thumbnail slot="start">
              <ion-img
                [alt]="offer.title.text"
                [src]="bannerPath + offer.images.leafBanner.image">
              </ion-img>
            </ion-thumbnail>
            <ion-label class="ion-text-wrap" margin-top-medium text-ellipsis-ml>{{offer.title.text}}</ion-label>
            <ion-button class="no-ripple-effect ion-text-uppercase ion-no-padding" fill="clear" color="light"
              size="small">{{offer.url ? 'GET CODE' : 'View details'}}</ion-button>
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </ion-item>
</ion-list>
