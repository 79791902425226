<ion-list lines="none" class="ion-no-padding ion-list-image-card ion-list-grid book-store-list layout-spacer">
  <ion-list-header class="ion-list-header" *ngIf="data?.name">
    <ion-row class="ion-justify-content-between full-width ion-align-items-center">
      <div>
        <span>{{ data.name }}</span>
        <p *ngIf="data?.description">{{ data.description }}</p>
      </div>
    </ion-row>
  </ion-list-header>
  
  <div class="collection-slides-wrap" *ngIf="collectionTableData.table.rows[0].columns?.length" 
    inViewport
    [inViewportOptions]="{ threshold: [0, 0.65], partial: true }"
    (inViewportAction)="onIntersection($event)">
    <div (click)="navigateToCollectionList(exploreCollectionData)" class="bg-image"  [ngStyle]="{ 'background-image': 'url(' + backgroundUrl + ') ' }"></div>
      <swiper class="collection-slides"
      [slidesPerView]="2"
      [spaceBetween]="15"
      [centeredSlides]="true"
      [cssMode]="false"
      [loop]="true"
      [loopAdditionalSlides]="2"
      [autoplay]="autuPlayPros"
      [pagination]="{
        type: 'fraction'
      }"
      (swiper)="swiperInit($event)" 
      #swiper
      >
        <ng-template *ngFor="let  cell of collectionTableData.table.rows[0].columns"  swiperSlide>
          <ion-card (click)="navigateToCollectionList(cell)" >
            <p class="offer-strip" *ngIf="cell?.cashBack?.text">{{ cell.cashBack.text }}</p>
            <app-img-on-demand [actualImgSrc]="bannerFilePath + cell.images.leafBanner.image"
              [defaultImgSrc]="'assets/img/default-banner.png'" [showDefaultImage]="showDefaultImage"
              alt_image="img_collection_{{cell.images.leafBanner.image}}"
              [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER" [useImageOnDemand]="false"
              [imageName]="cell.images.leafBanner.image">
            </app-img-on-demand>
          </ion-card>
        </ng-template>
      </swiper>
    </div>
</ion-list>






