import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/shared/providers/storage/storage.service';
import { FILE_STORAGE_DIRECTORIES, IOS } from 'src/app/shared/constants/constants';
import { DataService } from 'src/app/shared/providers/data/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  videoName;
  fileStorageDirectoryName = FILE_STORAGE_DIRECTORIES.MILK_LABEL_VIDEO;
  selectedVideoCollectionData: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    private file: File,
    private fileTransfer: FileTransfer,
    private storageService: StorageService,
    public dataService: DataService,
    public platform: Platform,
    public domSanitizer: DomSanitizer
  ) { }

  checkForDirectoryCreatedOrNot(localReferenceVariable , videoUrl) {
    if (this.storageService.directoriesCreated[this.fileStorageDirectoryName]) {
        this.checkIfVideoExistInStorageElseDownload(localReferenceVariable, videoUrl);
      } else {
        localReferenceVariable.url = videoUrl;
      }
  }

  checkIfVideoExistInStorageElseDownload(localReferenceVariable, videoUrl) {
    this.videoName = videoUrl.split(/(\\|\/)/g).pop();
    this.file.checkFile(`${this.file.dataDirectory}${this.fileStorageDirectoryName}/`, this.videoName).then((success) => {
      if (success) {
        this.convertFileSrcUsingIonicWebView(localReferenceVariable, this.videoName);
      }
    }, () => {
      localReferenceVariable.url = videoUrl;
      this.downloadVideo(videoUrl);
    });
  }
  downloadVideo(fileUrl: string) {
    const fileTransfer: FileTransferObject = this.fileTransfer.create();
    fileTransfer.download(fileUrl, `${this.file.dataDirectory}/${this.fileStorageDirectoryName}/` + this.videoName).then(() => {});
  }
  convertFileSrcUsingIonicWebView(localReferenceVariable, video) {
    localReferenceVariable.url = (window as any).Ionic.WebView.convertFileSrc(`${this.file.dataDirectory}${this.fileStorageDirectoryName}/${video}`);
    if (this.platform.is(IOS)) {
      localReferenceVariable.url = this.domSanitizer.bypassSecurityTrustResourceUrl(localReferenceVariable.url);
    }
  }
}
