//#region Imports

//#region Angular Imports
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports 
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports 
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
// import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports 
import { ToastService } from '@Globals/providers/toast.service';
import { AlertService } from '@Globals/providers/alert.service';
import { EventsService } from '@Globals/providers/events.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { SettingService } from '@Globals/providers/setting.service';
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports

import { SIGNUP_ERROR_MESSAGES } from '@Globals/constants/registration/constants';
import { ALERT_BUTTON_TEXT, EVENT_NAMES, GENERATE_CONTENT_FOR_ALERT_LAYER, UPDATE_USER_DETAILS_SUCCESS, UPDATE_USER_DETAILS_SUCCESS_ALERT, USER_DETAIL_DEFAULT_VALUES } from 'src/app/shared/constants/constants';
import { APIs } from 'src/app/shared/constants/APIs';
import { UserDetailsModalSourceType } from '@Globals/constants/listing/enums';
import { UserSignupDetails } from '@App/registration/interfaces/registration-interfaces';
import { Utilities } from '@Globals/classes/utilities';
import { EmailVerification } from '@Globals/constants/profile/enums';
import { HttpService } from '@Globals/providers/http.service';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  userDetails: UserSignupDetails = { fullName: '', emailId: '' };
  errorMessage = SIGNUP_ERROR_MESSAGES;
  UserDetailsModalSourceTypeEnum = UserDetailsModalSourceType;
  @Input() userDetailsModalSourceType = UserDetailsModalSourceType.ShoppingCreditsPage;
  @Input() isMailPreFilled = true;
  showOtpScreen: boolean;
  enteredOtp: { value: string, validOtp: boolean };
  subscriptions: Subscription[] = [];
  otpError = { isError: false, message: '' };
  isEmailNeedToVerify: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private httpService: HttpService,
    private eventsService: EventsService,
    public masterDataService: MasterDataService,
    private alertService: AlertService,
    private toastService: ToastService,
    private settingService: SettingService,
    private dataService: DataService,
  ) { }

  ngOnInit() {
    if (
      this.userDetailsModalSourceType ===
      UserDetailsModalSourceType.SettingsPage
    ) {
      if (this.masterDataService.masterData?.user?.name &&
        USER_DETAIL_DEFAULT_VALUES.FIRSTNAME + ' ' + USER_DETAIL_DEFAULT_VALUES.LASTNAME !==
        this.masterDataService.masterData?.user?.name?.toLowerCase()
      ) {
        this.userDetails.fullName = this.masterDataService.masterData?.user?.name;
      }
      if (this.isMailPreFilled) {
        this.userDetails.emailId = this.masterDataService.masterData?.user?.email;
      }
    }
    this.isEmailNeedToVerify = this.checkIfEmailNeedToVerify();
  }

  checkIfUserNumberNotExistInEmail() {
    return this.masterDataService.masterData?.user?.email &&
      this.masterDataService.masterData?.user?.phone &&
      !this.masterDataService.masterData?.user?.email.includes(
        this.masterDataService.masterData?.user?.phone + '@milkbasket.com'
      );
  }

  checkIfEmailNeedToVerify(){
    if(this.masterDataService.masterData?.user?.cinfo?.iev && (this.masterDataService.masterData?.user?.email === this.userDetails.emailId)){
      return false;
    } else {
      return true;
    }
  }

  closeModal(isSubmit = false) {
    !isSubmit &&
      this.eventsService.trackEvents(EVENT_NAMES.POST_REGISTRATION_CAPTURE, {
        Is_Close: true
      });
    this.modalCtrl.dismiss();
  }

  updateProfilePopup() {
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(
        UPDATE_USER_DETAILS_SUCCESS.META_DATA
      ), [UPDATE_USER_DETAILS_SUCCESS.BUTTONS.CLOSE, UPDATE_USER_DETAILS_SUCCESS.BUTTONS.CONFIRM],
      UPDATE_USER_DETAILS_SUCCESS.CSS_CLASS
    );
  }

  // method invoked when form is submitted and it gets firstname and last name from fullName.
  submit() {
    if (this.userDetails.fullName || this.userDetails.emailId) {
      const fullName = this.userDetails.fullName
        ? this.userDetails.fullName
        : this.masterDataService.masterData?.user?.name;
      const nameObj = Utilities.extractFirstNameAndLastNameFromFullName(fullName);
      this.userDetails.firstName = nameObj.firstName;
      this.userDetails.lastName = nameObj.lastName;
      let email = this.userDetails.emailId || null;
      this.isMailPreFilled && this.masterDataService.masterData.user.email === this.userDetails.emailId && (email = null);
      const userData = {
        firstname: this.userDetails.firstName,
        lastname: this.userDetails.lastName,
        email: email
      };
      this.httpService
        .put(APIs.SEND_USER_DETAILS, userData)
        .subscribe((response: any) => {
          if (response.data.status) {
            const eventArgs = {
              Submit: true,
              Is_Name: !!this.userDetails.fullName,
              Is_Mail: !!this.userDetails.emailId
            };
            this.eventsService.trackEvents(
              this.userDetailsModalSourceType ===
                UserDetailsModalSourceType.ShoppingCreditsPage
                ? EVENT_NAMES.POST_REGISTRATION_CAPTURE
                : EVENT_NAMES.UPDATED_PROFILE_PAGE,
              eventArgs
            );
            this.userDetails.fullName && this.masterDataService.masterData && this.masterDataService.masterData.user &&
              (this.masterDataService.masterData.user.name = this.userDetails.fullName);
            this.userDetails.emailId && this.masterDataService.masterData && this.masterDataService.masterData.user &&
              (this.masterDataService.masterData.user.email = this.userDetails.emailId);
            email && (this.masterDataService.masterData.user.cinfo.iev = EmailVerification.NOT_VERIFIED);
            this.dataService.runChangeDetForUserInfo.next(true);
            this.masterDataService.registerPlugins(true);
            if (this.isEmailNeedToVerify) { 
              this.generateEmailOTP();
            }else{
              this.userDetailsModalSourceType ===
              UserDetailsModalSourceType.SettingsPage &&
              this.toastService.presentToast(
                UPDATE_USER_DETAILS_SUCCESS_ALERT.MESSAGE
              );
              this.closeModal(true);
            }
          } else {
            this.alertService.presentAlert(response.data.error_msg, [
              ALERT_BUTTON_TEXT
            ]);
          }
        });
    }
  }

  onEmailChange() {
    this.userDetails.emailId = this.userDetails.emailId.toLowerCase();
    this.showOtpScreen = false;
    this.enteredOtp = null;
    this.otpError.isError = false;
    this.otpError.message = '';
    this.isEmailNeedToVerify = this.checkIfEmailNeedToVerify();
  }

  verifyEmailOTP() {
    if (this.enteredOtp && !this.enteredOtp?.value) return;
    const verifyEmailOTPSubscription = this.settingService.verifyEmailOTP(this.userDetails.emailId, this.enteredOtp?.value)
      .subscribe((response: any) => {
        if (response.data?.status) {
          this.masterDataService.masterData && this.masterDataService.masterData.user && this.masterDataService.masterData.user.cinfo &&
          (this.masterDataService.masterData.user.cinfo.iev = EmailVerification.VERIFIED);
          this.dataService.runChangeDetForUserInfo.next(true);
          this.closeModal(true);
          this.toastService.presentToast(
            UPDATE_USER_DETAILS_SUCCESS_ALERT.MESSAGE
          );
          this.userDetailsModalSourceType === UserDetailsModalSourceType.ShoppingCreditsPage && this.updateProfilePopup();
        } else {
          this.otpError.isError = true;
          this.otpError.message = response.data.error_msg;
        }
      }, (error) => {
        this.otpError.isError = true;
        this.otpError.message = error.error.message;
      });
    this.subscriptions.push(verifyEmailOTPSubscription);
  }

  generateEmailOTP() {
    const generateLoginOTPSubscription = this.settingService.generateEmailOTP(this.userDetails.emailId).subscribe((response: any) => {
      if (response?.data?.status) {
        this.showOtpScreen = true;
      } else {
        this.showOtpScreen = false;
      }
    }, (error) => {
      this.showOtpScreen = false;
    });
    this.subscriptions.push(generateLoginOTPSubscription);
  }

  onOtpChange(value: { value: string, validOtp: boolean }) {
    this.otpError.isError = false;
    this.otpError.message = '';
    this.enteredOtp = value;    
  }

  mbOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
