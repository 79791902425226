//#region Imports

//#region Angular Imports
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
import { ProductListType } from '@Globals/constants/listing/enums';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mb-similar-products',
  templateUrl: './similar-products.component.html',
  styleUrls: ['./similar-products.component.scss'],
})
export class SimilarProductsComponent implements OnInit {
  @Input() metaData: any;
  @Output() requestProduct = new EventEmitter();
  recommendationProductListing = ProductListType.Recommendation_Product_Listing;
  constructor(
    public dataService: DataService
  ) { }

  ngOnInit() { }

  triggerRequestProduct(product, isSourceSeeSimilar) {
    this.requestProduct.emit({ product, isSourceSeeSimilar });
  }
}
