
import { Injectable, NgZone } from '@angular/core';
import { from } from 'rxjs';
import { delay } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class TaskRunner {

    private _s = from([0]);

    constructor(
        private _zone: NgZone
    ) { }

    defer(cb: Function) {
        this._zone.runOutsideAngular(() => {
            this._s.pipe(
                delay(0)
            ).subscribe(_ => {
                cb();
            });
        });
    }
}

