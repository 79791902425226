export class ProductWeight {
    __typename = 'ProductWeight';
    text: string; // was product.product_weight, product.wgt, product.weight in same order of preference
    value?: number; // was product.numericWeight
    unit?: string; // was unitOfMeasurement

    setWeightFromApi(productApi) {
        this.text = productApi.product_weight || productApi.wgt || productApi.weight;
        this.value = productApi.numericWeight || null;
        this.unit = productApi.unitOfMeasurement || null;

        return this;
    }
}
