import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './containers/header/header.component';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { InviteComponent } from './containers/invite/invite.component';
import { MomentModule } from 'ngx-moment';
import { InfoPopUpComponent } from './components/info-pop-up/info-pop-up.component';
import { AppUpdateComponent } from './components/app-update/app-update.component';
import { BannerComponent } from './containers/banner/banner.component';
import { ImgOnDemandComponent } from './components/img-on-demand/img-on-demand.component';
import { AutoPayComponent } from './containers/auto-pay/auto-pay.component';
import { OfferComponent } from './components/offer/offer.component';
import { JourneyComponent } from './containers/journey/journey.component';
import { BottomSheetHeaderComponent } from './containers/bottom-sheet-header/bottom-sheet-header.component';
import { IonBottomSheetModule } from 'ion-bottom-sheet';
import { JioMartHeaderComponent } from './containers/jio-mart-header/jio-mart-header.component';
import { SwiperModule } from 'swiper/angular';
import { ProductListingService } from '../listing/providers/product-listing/product-listing.service';
import { FaqModalComponent } from './components/faq-modal/faq-modal.component';

@NgModule({
  declarations: [
    HeaderComponent,
    InviteComponent,
    FilterPipe,
    InfoPopUpComponent,
    AppUpdateComponent,
    BannerComponent,
    ImgOnDemandComponent,
    AutoPayComponent,
    OfferComponent,
    JourneyComponent,
    BottomSheetHeaderComponent,
    JioMartHeaderComponent,
    FaqModalComponent
  ],
  entryComponents: [
    InviteComponent,
    InfoPopUpComponent,
    AppUpdateComponent,
    JourneyComponent,
    FaqModalComponent,
  ],
  imports: [CommonModule, FormsModule, IonicModule, MomentModule, IonBottomSheetModule, SwiperModule,
  ],
  exports: [
    HeaderComponent,
    InviteComponent,
    FilterPipe,
    InfoPopUpComponent,
    BannerComponent,
    ImgOnDemandComponent,
    AutoPayComponent,
    OfferComponent,
    BottomSheetHeaderComponent,
    JioMartHeaderComponent,
    FaqModalComponent
  ],
  providers: [
    ProductListingService
  ]
})
export class SharedModule { }
