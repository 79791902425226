import { Recurrence } from './recurrence';
import { PauseSchedule } from './pause-schedule';

export class ProductSubscriptionOrder {
    id: number;
    productId: number; // was product_id
    startDate?: string; // was start_date
    recurrence?: Recurrence;
    pauseSchedule?: PauseSchedule;

    setProductSubscriptionOrderFromApi(subscriptionProductOrderApi) {
        this.id = subscriptionProductOrderApi.id;
        this.productId = subscriptionProductOrderApi.product_id;
        this.startDate = subscriptionProductOrderApi.start_date;

        const recurrence = new Recurrence();
        this.recurrence = recurrence.setProductRecurrence(subscriptionProductOrderApi);

        const pauseSchedule = new PauseSchedule();
        this.pauseSchedule = pauseSchedule.setProductPauseSchedule(subscriptionProductOrderApi);
        return this;
    }
}
