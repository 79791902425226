<ion-item *ngIf="metaData" color="light" class="ion-no-padding item-out-of-stock-inner animation animationInRight">
  <div class="theme-gray">
    <mb-pl-recommendation (touchstart)="dataService.toggleSliderSwipes(metaData.sliderFromPage, true)" 
    (touchend)="dataService.toggleSliderSwipes(metaData.sliderFromPage, false)"
    *ngIf="metaData.products?.data?.length > 0; else showRequestDiv"
    [eventSource]="metaData.eventSource" [recommendationType]="metaData.recommendationComponentType"
    [recommendation]="metaData.products">
  </mb-pl-recommendation>
    <ng-template #showRequestDiv>
      <div *ngIf="metaData.isRequestDivAllowed" class="req-original-item-wrap ion-padding">
        <div class="req-original-card">
          <div class="img-holder">
            <img alt="request for original item" width="24" [src]="'assets/img/icon-original-item-2.png'" />
          </div>
          <div class="description ion-text-center">
            <p>
              This item is so unique! <br />
              We don’t see anything like it!
            </p>
          </div>
          <ion-button mode="md" fill="outline" size="large" color="primary" class="mw-default ion-margin-top"
            (click)="triggerRequestProduct(metaData.parentProduct, true)">
            Request for the Original Item
          </ion-button>
        </div>
      </div>
    </ng-template>
  </div>
</ion-item>