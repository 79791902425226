<div class="best-price-wrap layout-spacer" *ngIf="data?.productList?.length && showDealsOfTheList" >
    <ion-list class="thicker" lines="none">
      <span [ngStyle]="{ 'animation-duration' :  (data?.productList?.length*6) + 's'}">
        <ng-conatiner *ngFor="let product of data?.productList">
          <ion-item  no-line>
            <ion-label>{{product?.nm}} <span class="f-w-s-b icon-inr">{{product?.sp}}</span>
              <span class="dis f-w-m" *ngIf="product?.dodPrice else defaultPrice"> -{{product?.dodPrice | number:'1.1-2'}}%</span>
              <ng-template #defaultPrice>
              <span class="dis f-w-m" *ngIf="product.mrp - product.sp > 0"> -{{((product?.mrp - product?.sp)/product?.mrp)*100 | number:'1.1-2'}}%</span>
              </ng-template>
            </ion-label>
          </ion-item>
        </ng-conatiner>
      </span>
      <span [ngStyle]="{ 'animation-duration' :  (data?.productList?.length*6) + 's'}">
        <ng-conatiner *ngFor="let product of data?.productList">
          <ion-item  no-line>
            <ion-label>{{product?.nm}} <span class="f-w-s-b icon-inr">{{product?.sp}}</span>
              <span class="dis f-w-m" *ngIf="product?.dodPrice else defaultProductPrice"> -{{product?.dodPrice  | number:'1.1-2'}}%</span>
              <ng-template #defaultProductPrice>
              <span class="dis f-w-m" *ngIf="product.mrp - product.sp > 0"> -{{((product?.mrp - product?.sp)/product?.mrp)*100 | number:'1.1-2'}}%</span>
              </ng-template>            
            </ion-label>
          </ion-item>
        </ng-conatiner>
      </span>
    </ion-list>
    <ion-item lines="none" class="best-header">
      <ion-label>
        <h2 class="f-w-b">{{ data.name }}</h2>
        <p> {{ data.description }}</p>
      </ion-label>
      <ion-label slot="end">
      <ion-img alt="Background image" src="{{backgroundUrl}}"></ion-img>
      </ion-label>
    </ion-item>
<div class="rel">
<wgt-deals-of-the-day *ngIf="data?.productList" [dealsOfTheDayProductList]="data?.productList" [eventSource]="eventData?.Source" [eventData]="eventData"></wgt-deals-of-the-day>
<ion-button (click)="navigateToCollectionList()"  fill="clear" size="small" class="ion-no-padding no-ripple-effect view-all">
   View All Products
   <ion-icon class="icons-property icon-arrow-right icon-primary" role="img"></ion-icon>
 </ion-button>
</div>
</div>

