//#region Imports

//#region Angular Imports
import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { DateFormatPipe } from "ngx-moment";
//#endregion Library Imports

//#region Module Imports
import { ReactRendererRoutingModule } from "./react-renderer-routing.module";
import { ShoppingCreditsModule } from "@Pages/payment/shopping-credits/shopping-credits.module";
import { LowBalanceModule } from "@Shared/payment/low-balance/low-balance.module";
import { ProductVariantListModule } from '@Shared/product-variant-list/product-variant-list.module';
//#endregion Module Imports

//#region Component Imports
import { ReactRendererComponent } from "./react-renderer.component";
//#endregion Component Imports

//#region Providers Imports
import { EventManagerService } from "./providers/event-manager.service";
import { FooterStripModule } from "@Shared/footer-strip/footer-strip.module";
import { AccountHistoryService } from "@Pages/account-history/providers/account-history.service";
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  declarations: [ReactRendererComponent],
  imports: [CommonModule, ReactRendererRoutingModule, ShoppingCreditsModule, LowBalanceModule, ProductVariantListModule,FooterStripModule],
  exports: [ReactRendererComponent, ShoppingCreditsModule, LowBalanceModule, ProductVariantListModule],
  entryComponents: [ReactRendererComponent],
  providers: [
    DecimalPipe,
    EventManagerService,
    DateFormatPipe,
    AccountHistoryService
  ],
})
export class ReactRendererModule {}