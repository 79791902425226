//#region Imports

//#region Angular Imports
import { Component, Input, ChangeDetectorRef } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { EventsService } from '@Globals/providers/events.service';
import { MasterDataService } from 'src/app/shared/providers/master-data/master-data.service';
import { ModalService } from '@Globals/providers/modal.service';
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES } from '@Globals/constants/listing/constants';
import { OFFER_SOURCE, PAYMENT_VALUES } from '@Globals/constants/payment/constants';
import { Utilities } from '@Globals/classes/utilities';
//#endregion Other Imports

//#endregion Imports


@Component({
  selector: 'mbs-payment-offer-listing',
  templateUrl: './payment-offer-listing.component.html',
  styleUrls: ['./payment-offer-listing.component.scss'],
})
export class PaymentOfferListingComponent extends DefaultComponent {
  
  @Input() source = OFFER_SOURCE.PAYMENT;
  @Input() showLabel = true;
  @Input() addEventListener = false;
  @Input() offerList =  this.masterDataService.paymentOffers;
  @Input() commonOffers : Array<{}> = [];
  @Input() isSort:boolean =true;
  combinedOffers: Array<{}> = [];
  subscription:Subscription;
  constructor(public masterDataService: MasterDataService,
    private eventsService: EventsService,
    private dataService: DataService,
    public modalService: ModalService,
    private _cd: ChangeDetectorRef) {
      super();
     }

  openDescription() {
    this.eventsService.trackEvents(EVENT_NAMES.VIEW_ALL_OFFER, { source: this.source });
    this.modalService.openOfferDescriptionComponent(this.combinedOffers);
  }
 
  mbOnInit() {
    this.mergeOffers();
    if (this.addEventListener) {
        this.dataService.addListener();
    } else{
      const el = document.querySelectorAll('.option');
      el.forEach(els => {
        els.classList.add('scroll-x');
      });
    }
    this.subscription = this.dataService.runChangeDetForPaymentData.subscribe(() => {
      this.mergeOffers();
      this._cd.detectChanges();
    });
  }

  mergeOffers() {
    this.combinedOffers = [];
    this.commonOffers.length && this.createCombinedOffer(this.commonOffers);
    this.offerList.length && this.createCombinedOffer(this.offerList);
    if(this.isSort){
      Utilities.offerSort(this.combinedOffers);
    }
  }

  createCombinedOffer(offers) {
    offers.forEach((offer:any) => {
        (offer.visible || !("visible" in offer)) && this.combinedOffers.push(offer);
    });    
  }

  mbOnDestroy(): void {
    this.subscription?.unsubscribe(); 
  }
}
