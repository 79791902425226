//#region Imports

//#region Angular Imports
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultRoutingModule } from '@Lib/defaults/default-routing.module';
//#endregion Module Imports

//#region Component Imports
import { ShoppingCreditsPage } from '@Pages/payment/shopping-credits/shopping-credits.page';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

const routes: Routes = [
  {
    path: '',
    component: ShoppingCreditsPage
  },
  {
    path: '/:superChargeId',
    component: ShoppingCreditsPage
  },
  {
    path: '/:memberShipId/:openPaymentOption',
    component: ShoppingCreditsPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShoppingCreditsRoutingModule extends DefaultRoutingModule { }
