//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component, SimpleChanges } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component'; 
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, EVENT_SOURCES, DEFAULT_BANNER_MAX_LIMIT, STORAGE_KEYS } from '@Globals/constants/listing/constants';
import { WhatsappSharingComponentTypes, EventTrackingPlugins } from '@Globals/constants/listing/enums';

import { environment } from '@Env/environment';
import { InViewportMetadata } from 'ng-in-viewport';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-collection-banner-block',
  templateUrl: './banner.component.html',
  styleUrls: ['../base/base.component.scss', './banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent extends BaseCollectionComponent {
  bannerIndex = 0;
  bannerComponent = WhatsappSharingComponentTypes.Banner;
  homeBannersSource = EVENT_NAMES.HOME;
  bannerFilePath = environment.bannerPath;
  private swiperRef: any;

  bannerMaxLimit;
  swiperInit(swiper: any) {
    this.swiperRef = swiper;
  }

  setMaxLimitForBanners() {
    try {
        if(localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG_MAX_LIMIT) && localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG_MAX_LIMIT) != 'null' && localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG_MAX_LIMIT) != 'undefined' && Object.keys(JSON.parse(localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG_MAX_LIMIT))).length) { 
          this.bannerMaxLimit = JSON.parse(localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG_MAX_LIMIT)).banners || DEFAULT_BANNER_MAX_LIMIT;
        } else {
          this.bannerMaxLimit = DEFAULT_BANNER_MAX_LIMIT;
        }
    } catch(e) {
        this.bannerMaxLimit = DEFAULT_BANNER_MAX_LIMIT;
    }
   }

  mbOnInit(): void {
    this.setMaxLimitForBanners();
    this.dataService.knockKnock.subscribe(success => {
      if (success) {
        this.swiperRef?.autoplay?.start();
      }
    });
    super.mbOnInit();
  }

  mbOnChanges(changes: SimpleChanges): void {
    this.data?.banners && this.data?.banners.length > 0 && this.trackBannerImpression();
  }

  slideChanged($event) { 
    this.bannerIndex = $event[0].activeIndex;
    this.trackBannerImpression();
  }

  trackBannerImpression() {
    this.data?.banners && (this.data?.banners.length > 0) && this.eventsService.trackEvents(EVENT_NAMES.BANNER_IMPRESSION, {
      banner_id: this.data?.banners[this.bannerIndex] && this.data?.banners[this.bannerIndex].id,
      banner_index: this.bannerIndex + 1,
      source: this.props?.source
    }, false, EventTrackingPlugins.Firebase);
  }
  
  navigate(event, activeIndex){
    event.preventDefault && event.preventDefault();
    let banner = this.data?.banners[activeIndex];
    let eventName: string;
    let eventArgs: any;
    if (banner['collection_id']) {
      this.dataService.collectionsConfigData = {
        leafRequest: [],
        listImageUrl: null,
        collection: null
      };
      const currentLeafIdAndListId = {
        leafId: null,
        listId: null
      };
      this.data?.metaData.cells.forEach(metaData => {
        if (banner['list_position'] === metaData.identifier) {
          currentLeafIdAndListId.leafId = metaData.collectionLeafId;
          currentLeafIdAndListId.listId = metaData.image.leafBanner.listId;
        }
        this.dataService.collectionsConfigData.leafRequest.push({
          leafId: metaData.collectionLeafId,
          listId: metaData.image.leafBanner.listId
        });
        this.dataService.collectionsConfigData.collection = this.data;
      });
      eventName = EVENT_NAMES.COLLECTION_VIEW;
      eventArgs = {
        name: banner.name,
        collection_title: banner['collection_title'],
        collection_id: banner['collection_id'],
      };
    } else {
      eventName = EVENT_NAMES.BANNER_VIEWED;
      eventArgs = {
        banner_id: banner.id,
        banner_name: banner.name,
        banner_image: banner.img,
        banner_url: banner.url,
        banner_index: this.bannerIndex + 1,
        banner_source: this.props?.source
      };
    }
    this.eventsService.trackEvents(eventName, eventArgs);
    let url = banner.url;
    if (/list/i.test(url)) {
      url = `${url}/${banner.id}` + '/' + (this.bannerIndex + 1);
    }
    this.deepLinkService.navToDeepLinkUrl(url, EVENT_SOURCES.BANNERS, banner.name);
  }

  onIntersection($event) {
    const { [InViewportMetadata]: { entry }, target } = $event;
    const ratio = entry.intersectionRatio;

    this.zone.runOutsideAngular(() => {
      if(ratio >= 0.65) {
        this.swiperRef?.autoplay?.start();
      } else {
        this.swiperRef?.autoplay?.stop();
      }
    });
  }
}