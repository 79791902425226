import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Utilities } from '../../classes/utility/utilities';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {

    constructor (private firebase: FirebaseX, private ngZone: NgZone) {
    }

    logMessage(message1: any, message2?: any) {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
               if (!environment.production) {
                    console.log(message1, message2);
               }
                Utilities.isApp() && this.firebase.logMessage(JSON.stringify(message1) + (message2 ? JSON.stringify(message2) : ''));
            });
        });
    }
}
