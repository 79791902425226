//#region Imports

//#region Angular Imports
import { Component,OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { HTTPResponse } from '@ionic-native/http';
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { AlertService } from 'src/app/shared/providers/alert/alert.service';
import { MasterDataService } from 'src/app/shared/providers/master-data/master-data.service';
import { HttpService } from 'src/app/shared/providers/http/http.service'; 
//#endregion Providers Imports

//#region Other Imports
import { GENERATE_CONTENT_FOR_ALERT_LAYER, PROMO_CODE_REMOVE } from '@Globals/constants/listing/constants'; 
import { PAYMENT_APIS } from '@Globals/constants/payment/apis'; 
import { DefaultComponent } from '@Lib/defaults/default.component';
import { TncsComponent } from '@Pages/home/components/tncs/tncs.component';
import { BottomSheetService } from '@Globals/providers/bottom-sheet.service';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-promo-code-offer',
  templateUrl: './promo-code-offer.component.html',
  styleUrls: ['./promo-code-offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoCodeOfferComponent extends DefaultComponent{
  @Output() onApplyInviteCode = new EventEmitter();
  subscription:Subscription;
    constructor(
    public masterDataService: MasterDataService,
    public alertService: AlertService,
    private httpService: HttpService,
    public dataService: DataService,
    private _cd: ChangeDetectorRef,
    private bottomSheetService: BottomSheetService
  ) {
    super();
  }

  mbOnInit(): void {
    !this.masterDataService.masterData?.user?.paid && this.getInviteCodes();
    this.subscription = this.dataService.runChangeDetForPromo.subscribe(() => {
        this.markCheck();
    });
  }

  getInviteCodes() {
    const dataToSend = { hideLoader: true };
    this.httpService.get(PAYMENT_APIS.INVITE_CODES, dataToSend).subscribe((response: HTTPResponse) => {
      if (response.data && response.data.status) {
        this.dataService.inviteCodes = response.data.data || [];
        this.markCheck();
      }
    })
  }

  openOfferDetail(){
    this.bottomSheetService.openBottomSheet(TncsComponent,[0,0.5,0.9],0.5);
  }
  
  deleteInviteCode() {
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(
        PROMO_CODE_REMOVE.META_DATA
      ),
      [
        PROMO_CODE_REMOVE.BUTTONS.OKAY,
        PROMO_CODE_REMOVE.BUTTONS.CLOSE
      ],
      PROMO_CODE_REMOVE.CSS_CLASS
    );
    PROMO_CODE_REMOVE.BUTTONS.OKAY.handler = ()=> {
      this.httpService.post(PAYMENT_APIS.DELETE_INVITE_CODE, {welcome_code: this.masterDataService.masterData.welcome_code.detail.code})
      .subscribe((response: HTTPResponse) => {
        if (response.data.status) {
          this.masterDataService.masterData.welcome_code.detail = null;
          this.markCheck();
        }
      });
    }
  }

  markCheck() {
    this._cd.markForCheck();
  }

  getOffer() { 
    this.onApplyInviteCode.emit();
  }

  applyInviteCode(){
    this._cd.markForCheck();
  }

  mbOnDestroy(): void {
    this.subscription.unsubscribe(); 
  }
}
