import SwiperCore from "swiper";
import { getDocument } from 'ssr-window';

import onTouchStart from 'node_modules/swiper/core/events/onTouchStart.js';
import onTouchMove from 'node_modules/swiper/core/events/onTouchMove.js';
import onTouchEnd from 'node_modules/swiper/core/events/onTouchEnd.js';
import onResize from 'node_modules/swiper/core/events/onResize.js';
import onClick from 'node_modules/swiper/core/events/onClick.js';
import onScroll from 'node_modules/swiper/core/events/onScroll.js';

const events = (swiper, method) => {
  const document = getDocument();
  const {
    params,
    touchEvents,
    el,
    wrapperEl,
    device,
    support
  } = swiper;
  const capture = !!params.nested;
  const domMethod = method === 'on' ? 'addEventListener' : 'removeEventListener';
  const swiperMethod = method; // Touch Events

  if (!support.touch) {
    el[domMethod](touchEvents.start, swiper.onTouchStart, false);
    document[domMethod](touchEvents.move, swiper.onTouchMove, capture);
    document[domMethod](touchEvents.end, swiper.onTouchEnd, false);
  } else {
    const passiveListener = touchEvents.start === 'touchstart' && support.passiveListener && params.passiveListeners ? {
      passive: true,
      capture: false
    } : false;
    el[domMethod](touchEvents.start, swiper.onTouchStart, passiveListener);
    el[domMethod](touchEvents.move, swiper.onTouchMove, support.passiveListener ? {
      passive: false,
      capture
    } : capture);
    el[domMethod](touchEvents.end, swiper.onTouchEnd, passiveListener);

    if (touchEvents.cancel) {
      el[domMethod](touchEvents.cancel, swiper.onTouchEnd, passiveListener);
    }
  } // Prevent Links Clicks


  if (params.preventClicks || params.preventClicksPropagation) {
    el[domMethod]('click', swiper.onClick, true);
  }

  if (params.cssMode) {
    //wrapperEl[domMethod]('scroll', swiper.onScroll);
    let isScrolling;
    // @Changed: Debounced the scrolling function to call it with a delay, otherwise it causes continous flicker on the screen
    wrapperEl[domMethod]('scroll', swiper.onScroll);
  } // Resize handler


  if (params.updateOnWindowResize) {
    swiper[swiperMethod](device.ios || device.android ? 'resize orientationchange observerUpdate' : 'resize observerUpdate', onResize, true);
  } else {
    swiper[swiperMethod]('observerUpdate', onResize, true);
  }
};

SwiperCore.prototype.attachEventsOld = SwiperCore.prototype.attachEvents;
SwiperCore.prototype.attachEvents = function() {
    const swiper = this;
  const document = getDocument();
  const {
    params,
    support
  } = swiper;
  swiper.onTouchStart = onTouchStart.bind(swiper);
  swiper.onTouchMove = onTouchMove.bind(swiper);
  swiper.onTouchEnd = onTouchEnd.bind(swiper);

  if (params.cssMode) {
    let throttled = debouncedThrottle(onScroll, 1000, 200);
    swiper.onScroll = throttled.bind(swiper);
  }

  swiper.onClick = onClick.bind(swiper);

  /* if (support.touch && !dummyEventAttached) {
    document.addEventListener('touchstart', dummyEventListener);
    dummyEventAttached = true;
  } */

  events(swiper, 'on');
};

function debounce(method, delayMs) {
	delayMs = delayMs || 50;
	var timer = null;
	return function() {
		var context = this, args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function() {
			method.apply(context, args);
		}, delayMs);
	};
}

function debouncedThrottle(fn, threshold, lastThreshhold) {
	threshold = threshold || 250;
	var last, deferTimer;
  
  var db = debounce(fn, lastThreshhold);
	return function() {
		var now = +new Date, args = arguments;
		if(!last || (last && now < last + threshold)) {
			clearTimeout(deferTimer);
      db.apply(this, args);
			deferTimer = setTimeout(function() {
				last = now;
				fn.apply(this, args);
			}, threshold);
		} else {
			last = now;
			fn.apply(this, args);
		}
	}
}

export default SwiperCore;