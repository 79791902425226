//#region Imports

//#region Angular Imports
import { ChangeDetectorRef, Component, NgZone, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { InfoPopupComponent } from '@Shared/info-popup/info-popup.component';
//#endregion Component Imports

//#region Providers Imports
import { AuthService } from '@Globals/providers/auth.service';
import { BottomSheetService } from '@Globals/providers/bottom-sheet.service';
import { EventsService } from '@Globals/providers/events.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { LayoutService, SelectTabOption } from '@Layouts/one/providers/layout.service';
import { StorageService } from '@Globals/providers/storage.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, LOGIN_SIGNUP_TAB_POPUP, EVENT_SOURCES } from '@Globals/constants/listing/constants';
import { CORE_ROUTES } from '@Globals/constants/listing/routes';
import { Utilities } from '@Globals/classes/utilities';
//#endregion Other Imports
//#endregion Imports
@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss']
})
export class BottomNavComponent {
  selectTabOption = SelectTabOption;
  currentTab = SelectTabOption.Home;
  showBottomNav = false;
  isTabClicked = false;
  @Output() bottomNavStatusChanged = new EventEmitter();
  constructor(
    public navCtrl: NavController,
    public masterDataService: MasterDataService,
    public layoutService: LayoutService,
    private authService: AuthService,
    public eventsService: EventsService,
    private storageService: StorageService,
    private bottomSheetService: BottomSheetService,
    private cd: ChangeDetectorRef,
    public router: Router,
    public zone: NgZone
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if(event['url']){
        if(this.isTabClicked) {
          return;
        }
        this.showBottomNav = true;
        if(event['url'] === `/${CORE_ROUTES.HOME_PAGE}`) {
          this.currentTab = SelectTabOption.Home;
        } else if(event['url'] === `/${CORE_ROUTES.CATEGORY}`) {
          this.currentTab = SelectTabOption.Category;
        } else if(event['url'] === `/${CORE_ROUTES.CART_PAGE}`) {
          this.currentTab = SelectTabOption.Basket;
        } else if(event['url'] === `/${CORE_ROUTES.SHOPPING_CREDIT}`) {
          this.currentTab = SelectTabOption.Wallet;
        } else if(event['url'] === `/${CORE_ROUTES.MORE_PAGE}`) {
          this.currentTab = SelectTabOption.More;
        } else {
          this.showBottomNav= false;
        }
      }
      this.bottomNavStatusChanged.emit(!!this.showBottomNav ? 1 : 0); 
    });
  }

  changeTab(selectedTab){
    let eventName: string;
    this.isTabClicked = true;
    this.masterDataService.selectedTabSub.next(selectedTab);
    if (selectedTab === SelectTabOption.Home) {
      this.navigateForward('/'+CORE_ROUTES.HOME_PAGE, selectedTab);
      eventName = EVENT_NAMES.HOME_TAB;
    } else if (selectedTab === SelectTabOption.Category) {
      this.navigateForward('/'+CORE_ROUTES.CATEGORY, selectedTab);
      eventName = EVENT_NAMES.CATEGORIES_TAB;
    } else if (selectedTab === SelectTabOption.Basket) {
      this.checkAuthStateAndNavigate('/' + CORE_ROUTES.CART_PAGE, selectedTab);
      eventName = EVENT_NAMES.BASKET_TAB;
    } else if (selectedTab === SelectTabOption.Wallet) {
      this.checkAuthStateAndNavigate('/' + CORE_ROUTES.SHOPPING_CREDIT, selectedTab); 
      eventName = EVENT_NAMES.SHOPPING_CREDITS;
    } else if (selectedTab === SelectTabOption.More) {
      this.checkAuthStateAndNavigate('/' + CORE_ROUTES.MORE_PAGE, selectedTab);
      eventName = EVENT_NAMES.HAMBURGER_OPEN;
    } 
    if([EVENT_NAMES.HOME_TAB, EVENT_NAMES.CATEGORIES_TAB, EVENT_NAMES.BASKET_TAB].includes(eventName))
      this.masterDataService.masterData?.user?.id && this.eventsService.trackEvents(eventName, { Source: EVENT_SOURCES.BOTTOM_NAV, 'User Id': this.masterDataService.masterData?.user?.id });
    else
      this.eventsService.trackEvents(eventName, { Source: EVENT_SOURCES.BOTTOM_NAV });
  }

  checkAuthStateAndNavigate(url: string, selectedTab) {
    this.storageService.checkIfAppSecurityCompromised();
    if(this.authService.isLoggedIn()) {
      this.navigateForward(url, selectedTab);
    } else {
      this.isTabClicked = false;
      this.openAlertPopup();
    }
  }

  navigateForward(url: string, selectedTab: SelectTabOption) {
    this.navCtrl.navigateForward(url, {animated : false}).then(() => {
      setTimeout(() => {
        this.currentTab = selectedTab;
        this.isTabClicked = false;
      }, 100);
    });
  }

  private openAlertPopup(){
    if(!Utilities.isApp() && this.masterDataService.navigateFromDeeplink && Utilities.isMobileBrowser()) {
      this.masterDataService.getRedirectionToPlayStoreOrAppStore();   
    } else {
      this.bottomSheetService.openLoginBottomSheet(InfoPopupComponent, [0, 0.45, 0.50, 1], 0.50, LOGIN_SIGNUP_TAB_POPUP);
    }
    return;
  }
}
