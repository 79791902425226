import { Utilities } from '@Globals/classes/utilities';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Observable } from 'rxjs';
import { IOS } from '@App/shared/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class InAppBrowserService {
  constructor(private iab: InAppBrowser, private platform: Platform,) { }
  public browser;

  create(url: string, hidden = false, showToolbar = false, target = '_blank') {
    let options = {};
    if (hidden) {
      options = { location: 'no', toolbar: 'no', hidden: 'yes' };
    } else {
      options = { location: 'no', toolbar: showToolbar ? 'yes' : 'no' };
    }
    this.browser = this.iab.create(url, target, options);
  }
  close() {
    if (this.browser) {
      this.browser.close();
    }
  }
  show() {
    if (this.browser) {
      this.browser.show();
    }
  }
  openPaymentUrl(url: string) {
    this.browser = this.iab.create(url);
  }
  openJioPayPaymentUrl(url: string) {
    this.browser = this.iab.create(url, Utilities.isApp() ? '_blank' : '_self', { location: this.platform.is(IOS)? 'no' : 'yes', hideurlbar : 'yes', zoom: 'no'});
  }
  sendMessage(payload) {
    this.browser.executeScript({code: payload});
  }
  eventSubscription(event: string): Observable<object> {
    return this.browser.on(event);
  }
}
