//#region Imports

//#region Angular Imports
import { Injectable } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DefaultService } from '@Lib/defaults/default.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Injectable({
  providedIn: 'root'
})
export class BaseLayoutService extends DefaultService {
  constructor() {
      super();
  }
}
