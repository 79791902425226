//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { ProductListType } from '@Globals/constants/listing/enums';
import { EVENT_SOURCES } from '@Globals/constants/listing/constants';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-product-launch-list-block',
  templateUrl: './product-launch-list.component.html',
  styleUrls: ['./product-launch-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductLaunchListComponent extends BaseCollectionComponent {
  horizontalProductListingWithHeader = ProductListType.Horizontal_Listing_With_Headers;
  eventData = {source: EVENT_SOURCES.COLLECTION, collection_id:''};
  mbOnInit(): void {
    this.data && this.getCollectionListProducts();
    if(this.props) {
      if(this.props?.data && "one_pager_id" in this.props.data) {
        this.eventData = { ...this.props.data, 
          collection_id: this.data.id, 
          collection_title: this.data.name || this.data.displayName, 
          sortOrder: this.data.sortOrder,
          displayPosition: this.data.displayPosition,
          source: this.props?.eventSource 
        };
      } else {
        this.eventData = { source: this.props?.eventSource, collection_id: this.data.id };
      }
    }
  }
}
