<ion-content>
  <form class="form">
    <div class="f-body padding-horizontal-medium">
      <div class="successfully-submitted">
      <span class="successfully-img"></span>
      </div>
      <div class="heading-title">
        <h1>Address Detail submitted successfully!</h1>
        <p padding-top-extra-small>
          We’ll get back to you as soon as we hit <br> your area
        </p>
      </div>
    </div>
    <ion-button padding-horizontal-medium fill="solid" size="large" 
    shape="round" (click)="goToHome()" expand="full" class="t-t-n">Continue to Home</ion-button>
  </form>
</ion-content>