//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { ExtraWidthComponent } from './extra-width.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import annotations from '@Features/product-list/product-list.annotations';
//#endregion Other Imports

//#endregion Imports

@NgModule({
  declarations: [ExtraWidthComponent],
  imports: [...annotations.imports],
  exports: [...annotations.exports, ExtraWidthComponent],
  providers: [...annotations.providers],
})
export class ExtraWidthModule { }