<ion-header class="layout-header-one" [ngClass]="{'is-user-logout': !authService.isLoggedIn()}">
  <ion-toolbar class="for-jio-app pt-0" color="primary" *ngIf="dataService.isJioUserObs | async">
    <ion-buttons class="ion-buttons-arrow-back" slot="start">
      <ion-button (click)="dataService.closeMBApp()" >
        <ion-icon class="icon-arrow-back icons-property icon-md icon-white" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  
  <ion-toolbar color="primary" [ngClass]="{'pt-0': dataService.isJioUserObs | async}">
    <div 
    class="search f-w-m text-size-md ion-align-items-center d-flex" 
    (click)="navigateToSearch()"
    >
      <ion-icon class="icon-search icon-base icons-property" color="dark"></ion-icon>
      <span class="ion-padding-start">Search from 5000+ products</span>
    </div>
    <ion-buttons slot="end">
      <ion-button id="cal-icon" class="btn-icon" *ngIf="authService.isLoggedIn()"
        (click)="callMethod($event)">
        <ion-icon slot="icon-only" class="icon-calendar icons-property" color="white"></ion-icon>
        <span *ngIf="dataService.subscribeAndSaveNudge" class="notification-badge-cal"></span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div *ngIf="masterDataService.hero.banner?.data" 
    class="festi-hader-scroll-bg"  
    [ngClass]="{'show-bg':showFestiveHeader}"
    [style.background]="masterDataService.hero.banner.data.bgSearchColor ? masterDataService.hero.banner.data.bgSearchColor : ''">
    <ng-container [ngSwitch]="masterDataService.hero.banner.data.bgSearchType">
      <ng-container *ngSwitchCase="heroMediaType.IMAGE">
        <app-img-on-demand [actualImgSrc]="bannerFilePath + masterDataService.hero.banner.data.bgSearch"
          alt_image="img_header_{{masterDataService.hero.banner.data.bgSearch}}"
          [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER" [useImageOnDemand]="false">
        </app-img-on-demand>
      </ng-container>
      <ng-container *ngSwitchCase="heroMediaType.LOTTIE">
        <ng-lottie 
          (animationCreated)="animationCreated($event)"
          [options]="{path: bannerFilePath + masterDataService.hero.banner.data.bgSearch, loop: masterDataService.hero.banner.data.bgSearchLoop, autoplay: masterDataService.hero.banner.data.bgSearchAutoPlay}" 
          width="360" height="114" 
          class="lottie-animation">
        </ng-lottie>
      </ng-container>
    </ng-container>
  </div>
</ion-header> 