//#region Imports

//#region Angular Imports
import { Injectable } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { LoggingService } from '@Globals/providers/logging.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    isCameraAvailable = false;
    constructor(
        private diagnostic: Diagnostic,
        private loggingService: LoggingService) { }
    cameraPermission(callbackFunction?) {
        this.diagnostic.isCameraAuthorized(false).then((success) => {
            if (!success) {
                this.diagnostic.requestCameraAuthorization().then(s => {
                    this.loggingService.logMessage('Success for requestCameraAuthorization: ', s);
                    this.isCameraAvailable = true;
                }).catch((err) => {
                    this.loggingService.logMessage('Error in requestCameraAuthorization: ', err);
                });
            } else {
                callbackFunction();
                this.isCameraAvailable = true;
                this.loggingService.logMessage('Success for isCameraAuthorized: ', success);
            }
        }).catch((err) => {
            this.loggingService.logMessage('Error in isCameraAuthorized: ', err);
        });
    }
}
