<ion-header class="ion-main-header sticky-header">
  <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium padding-right-medium padding-horizontal-0"> 
    <mbs-bottom-sheet-header  (closeBottomSheet)="closeTnc()"
    [backType]="backAction"
     class="ion-promo-header has-cross-icon">TnCs</mbs-bottom-sheet-header>
    </ion-toolbar>
  </ion-header>

<ion-content class="ctn-gt-space-p ion-padding-bottom">
  <div class="tnc-wrap" [innerHTML]="masterDataService.masterData?.welcome_code?.detail?.info"></div>
</ion-content>

<ion-footer class="ctn-gt-space-p">
  <ion-button type="submit" size="large" shape="round" expand="block" 
  class="t-t-n margin-bottom-extra-big-large" (click)="closeTnc()">Ok</ion-button>
</ion-footer>
