//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
//#endregion Providers Imports

//#region Other Imports
import { APPSFLYER, EVENT_NAMES, WHATSAPP, ANDROID, IOS, SOCIAL_SHARING_CHANNELS } from '@Globals/constants/listing/constants';
import { CollectionType, WhatsappSharingComponentTypes } from '@Globals/constants/listing/enums';
import { LISTING_ROUTES } from '@Globals/constants/listing/routes';

import { Utilities } from '@Globals/classes/utilities';

import { environment } from '@Env/environment';
import { HttpClient } from '@angular/common/http';
import { SOCIAL_SHARING_DESCRIPTION, SOCIAL_SHARING_MESSAGE } from '@App/listing/constants/listing-constants';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-social-sharing-button',
  templateUrl: './social-sharing-button.component.html',
  styleUrls: ['./social-sharing-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialSharingButtonComponent extends DefaultComponent {
  @Input() componentType: WhatsappSharingComponentTypes;
  @Input() metaData: any;
  SOCIAL_SHARING_CHANNELS = SOCIAL_SHARING_CHANNELS;
  utility = Utilities;
  constructor(
    private dataService: DataService,
    private socialSharing: SocialSharing,
    private eventsService: EventsService,
    private HttpClient: HttpClient,
  ) {
    super();
  }

  mbOnInit() {
  }

  generateLinkAndShare() {
    let redirectUrl: string;
    const options = {
      message: '',
      subject: null,
      link: '',
      image: null
    };
    switch (this.componentType) {
      case WhatsappSharingComponentTypes.PDP:
        redirectUrl = '/product/' + (this.metaData.product_id || this.metaData.id);
        options.message = this.metaData.product_name || this.metaData.nm || this.metaData.name;
        if (this.metaData.img?.indexOf('.png') === -1) {
          const imageNameArr = this.metaData.img?.split('.');
          options.image = environment.productPath + imageNameArr[0] + '_fallback.' + imageNameArr[1];
        }
        break;
      case WhatsappSharingComponentTypes.Collection:
        const column = this.metaData.table.rows[0].columns[0];
        if (column.url) {
          redirectUrl = column.url;
        } else if (column.type === CollectionType.Video) {
          redirectUrl = LISTING_ROUTES.COLLECTION_VIDEO + '/' + this.metaData.metaData.cells[0].image.leafBanner.listId + '/' + this.metaData.metaData.cells[0].collectionLeafId;
        } else {
          redirectUrl = LISTING_ROUTES.COLLECTION + '/' + this.metaData.id + '/' + this.metaData.metaData.cells[0].collectionLeafId;
        }
        (column.images.leafBanner.image?.indexOf('.png') === -1) && (options.image = environment.bannerPath + column.images.leafBanner.image);
        options.message = this.dataService.firebaseMasterData.whatsAppSharingCollectionMetadata[this.metaData.id].shareMessage;
        break;
      case WhatsappSharingComponentTypes.Banner:
        redirectUrl = this.metaData.url;
        (this.metaData.img?.indexOf('.png') === -1) && (options.image = environment.bannerPath + this.metaData.img);
        options.message = this.metaData.shareText;
        break;
      case WhatsappSharingComponentTypes.Collection_PLP:
        redirectUrl = LISTING_ROUTES.COLLECTIONPLP + this.metaData.collectionId + '/' + this.metaData.id;
        options.message = this.dataService.firebaseMasterData.shareableCollectionData[this.metaData.id];
        break;
      case WhatsappSharingComponentTypes.Collection_Video:
        redirectUrl = LISTING_ROUTES.COLLECTION_VIDEO + this.metaData.listId + '/' + this.metaData.id + '/' + this.metaData.collectionId;
        options.message = this.dataService.firebaseMasterData.shareableCollectionData[this.metaData.id];
        break;
      case WhatsappSharingComponentTypes.Collection_Listing:
        redirectUrl = LISTING_ROUTES.COLLECTION_LISTING + '/' + this.metaData.id
        break;
      case WhatsappSharingComponentTypes.Banner_PLP:
        redirectUrl = LISTING_ROUTES.BANNER_COLLECTION_PLP + '' + this.metaData.listId + '/' + this.metaData.bannerId;
        if (this.metaData.hasOwnProperty('bannerIndex')) {
          redirectUrl += '/' + this.metaData.bannerIndex;
        }
        break;
    }
    const eventData: any = {
      type: WhatsappSharingComponentTypes[this.componentType],
      redirectUrl
    };
    this.SOCIAL_SHARING_CHANNELS.whatsapp.isAvailable && (eventData.channel = WHATSAPP);
    this.eventsService.trackEvents(EVENT_NAMES.SHARING_START, eventData);

    const share_message = SOCIAL_SHARING_MESSAGE;
    options.message = share_message;


    const dataToSend = {
      dynamicLinkInfo: {
        domainUriPrefix: environment.firebaseDeeplinkConfiguration.domainUriPrefix,
        link: `${environment.firebaseDeeplinkConfiguration.link}${redirectUrl}`,
        androidInfo: {
          androidPackageName: "com.milkbasket.app"
        },
        iosInfo: {
          iosBundleId: "com.milkbasket.app"
        },
        socialMetaTagInfo: {
          socialTitle: this.metaData.name,
          socialImageLink: environment.firebaseDeeplinkConfiguration.socialImageLink,
          socialDescription: SOCIAL_SHARING_DESCRIPTION
        }
      }
    }
    this.HttpClient.post(environment.firebaseDeeplinkConfiguration.createLinkApi, dataToSend)
      .subscribe((resp: any) => {
        options.link = resp.shortLink;
        if ((this.componentType === WhatsappSharingComponentTypes.Banner_PLP) || (this.componentType === WhatsappSharingComponentTypes.Collection_PLP) || (this.componentType === WhatsappSharingComponentTypes.Collection_Video) || (this.componentType === WhatsappSharingComponentTypes.Collection_Listing)) {
          this.shareWithOptions({
            message: options.message,
            url: options.link
          });
        } else {
          this.SOCIAL_SHARING_CHANNELS.whatsapp.isAvailable ? this.shareViaWhatsApp(options) :
            this.shareWithOptions({
              message: options.message,
              url: options.link
            });
        }
      });

  }

  shareViaWhatsApp(options) {
    if (this.dataService.currentPlatform === IOS) {
      this.socialSharing.shareViaWhatsApp(options.message, null, options.link).then(() => {
        this.trackShareComplete(WHATSAPP);
      }).catch();
    } else {
      this.socialSharing.shareVia(SOCIAL_SHARING_CHANNELS.whatsapp[ANDROID], options.message, options.subject, options.image, options.link).then(() => {
        this.trackShareComplete(WHATSAPP);
      }).catch();
    }
  }

  shareWithOptions(options) {
    this.socialSharing.shareWithOptions(options).then((channelInfo) => {
      if (channelInfo && channelInfo.app) {
        // extract channel name under group 1 of applied regex if available else will pass channelInfo.app as it is in event
        const appInfoArray = channelInfo.app.match(/{(.*)\//);
        const channelName = (appInfoArray && appInfoArray[1]) || channelInfo.app;
        this.trackShareComplete(channelName);
      }
    }).catch();
  }

  trackShareComplete(channelName) {
    this.eventsService.trackEvents(EVENT_NAMES.SHARING_COMPLETE, {
      channel: channelName
    });
  }

}
