import { USER_DETAIL_DEFAULT_VALUES } from '../../constants/constants';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { MediaType } from '../../constants/enums';
declare var window:any;
import * as moment from 'moment';

type Idebounce = (cb: (...args: Array<{}>) => void, wait?: number, immediate?: boolean) => (...args: Array<{}>) => void;

export class Utilities {
  constructor() {
  }

  static isApp(): boolean {
    return window.cordova.platformId !== 'browser';
  }

  static addMinutes(date?, minutes?) {
    if(!minutes) {
      minutes = 0;
    }
    if(!date) {
      date = new Date();
    }
    return moment().add(minutes,'minutes');
  }

  static isAndroid(): boolean {
    return navigator.userAgent.toLowerCase().includes("android");
  }

  static isMobileBrowser(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  static getElementsByTagName(tagName: string): any {
    const tagValue = document.getElementsByTagName(tagName);
    return tagValue[tagValue.length - 1];
  }

  static isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  static createRandom4DigitNumber() {
    return Math.floor(Math.random() * 10000);
  }

  static replaceKeysWithValues(message: string, contentKeysToBeReplaced: any = {}) {
    for (const key in contentKeysToBeReplaced) {
      if (contentKeysToBeReplaced.hasOwnProperty(key)) {
        message = message.replace(
          new RegExp(`{${key}}`, 'ig'),
          contentKeysToBeReplaced[key]
        );
      }
    }
    return message;
  }

  // using regex grouping which starts from 1 to n irrespective of any regex conditions here 1st and 3rd group belongs to firstname and 2nd group belongs to lastname.
  static extractFirstNameAndLastNameFromFullName(fullName) {
    const fullNameMatcher = fullName ? fullName.match(/(\S+)\s+(.*)|(.*)/) : [];
    const data = { firstName: '', lastName: '' };
    data.firstName = fullNameMatcher[1] || fullNameMatcher[3] || USER_DETAIL_DEFAULT_VALUES.FIRSTNAME;
    data.lastName = fullNameMatcher[2] || (data.firstName !== USER_DETAIL_DEFAULT_VALUES.FIRSTNAME ? '' : USER_DETAIL_DEFAULT_VALUES.LASTNAME);
    return data;
  }

  // parse json string if exist and valid else return null
  static parseJSONString(jsonString, isDoubleParseRequired = false) {
    try {
      return JSON.parse(isDoubleParseRequired ? JSON.parse(jsonString) : jsonString);
    } catch (e) {
      return null;
    }
  }

  static getCurrentHoursAndMinutes() {
    return parseInt(new DatePipe('en-IN').transform(new Date(), 'HHmm'), 10);
  }

  static returnImageWebPSrc(imageName, width, height) {
    const cloudFrontUrl = environment.cloudFront.Url;
    const imageRequest = JSON.stringify({
      bucket: environment.cloudFront.bucket,
      key: `products/${imageName}`,
      edits: {
        resize: {
          width,
          height,
          fit: 'fill'
        }
      }
    });
    return cloudFrontUrl + '/' + btoa(imageRequest);
  }

  static retryAPICall(APICallToBeExecuted, retryTime = 1, callback = (arg) => { }) {
    APICallToBeExecuted.subscribe(arg => {
      callback(arg);
    }, (error: any) => {
      if (retryTime <= 3) {
        setTimeout(() => {
          this.retryAPICall(APICallToBeExecuted, retryTime, callback);
        }, (5000 * retryTime));
        ++retryTime;
      }
    });
  }
  // Method to group By any key in an array object.
  static groupByKey(data, key) {
    if (Array.isArray(data)) {
      return data.reduce((accumulator, item) => {
        (accumulator[item[key]] = accumulator[item[key]] || []).push(item);
        return accumulator;
      }, {});
    } else {
      return {};
    }
  }

  static deepCloningOfAnArray(array) {
    return JSON.parse(JSON.stringify(array));
  }

  static isEmptyString(str) {
    if(/^ *$/.test(str)) {
      return true;
    }
    return false;
  }

  static deg2rad(deg) {
    return deg * (Math.PI/180)
  }

  static calculateDistanceBetweenLatLng(lat1, lon1, lat2, lon2) {
    let R = 6371; // Radius of the earth in km
    let dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    let dLon = this.deg2rad(lon2-lon1);
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    let d = R * c * 1000; // Distance in meter
    return d;
  }

  static removeEmojis (string) {
    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, '');
  }
  static  equalDates(date1, date2) {
    const inp1 = new Date(date1);
    const inp2 = new Date(date2);
    return inp1.getFullYear() === inp2.getFullYear()
      && inp1.getDate() === inp2.getDate()
      && inp1.getMonth() === inp2.getMonth();
  }

  static validateDates = (date1, date2) => {
    if(!date1) {
      return true;
    }
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    if(moment(d1).format('YYYY-MM-DD') < moment(d2).format('YYYY-MM-DD') ) {
      return true;
    } else if(moment(d1).format('YYYY-MM-DD') === moment(d2).format('YYYY-MM-DD')){
      if(moment(d1).format('HH:mm') <= moment(d2).format('HH:mm')) {
        return true;
      }
    } 
    return false;
  };

  static debounce: Idebounce = (callback, wait, immediate) => {
    let timeout: unknown;
    return (...args) => {
      const later = () => {
        timeout = undefined;
        if (!immediate) callback(...args);
      };

      const callNow = immediate && !timeout;

      clearTimeout(timeout as ReturnType<typeof setTimeout>);

      timeout = setTimeout(later, wait);
      if (callNow) callback(...args);
    };
  };

  static offerSort(offers){
      offers.sort(function(a, b){
        const date1:any = new Date(a.createdOn);
        const date2:any = new Date(b.createdOn);
        if( a.sortOrder - b.sortOrder === 0){
          return date2 - date1;
        }else{
          return a.sortOrder - b.sortOrder;
        }
      });
  }

  static isPreviousDate(date) {
    const inputDate = new Date(date);
    const today = new Date();
    return inputDate <= today;
  }

  static getMediaType(url: string) {
    let mediaType;
    switch (url?.split('.').pop()?.toLowerCase()) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        mediaType = MediaType.IMAGE;
        break;
      case 'mp4':
        mediaType = MediaType.VIDEO;
        break;
      case 'json':
        mediaType = MediaType.LOTTIE;
        break;
    }
    return mediaType;
  }
}
