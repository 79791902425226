//#region Imports

//#region Angular Imports
import { Component, ChangeDetectionStrategy } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-book-store-collection-block',
  templateUrl: './book-store.component.html',
  styleUrls: ['./book-store.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookStoreComponent extends BaseCollectionComponent {

}
