//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { SafeHtmlModule } from '@Globals/pipes/safe-html/safe-html.module';
//#endregion Module Imports

//#region Component Imports
import { SingleBrandComponent } from './single-brand.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import annotations from '@Features/product-list/product-list.annotations';
//#endregion Other Imports

//#endregion Imports

@NgModule({
  declarations: [SingleBrandComponent],
  imports: [...annotations.imports, SafeHtmlModule],
  exports: [...annotations.exports, SingleBrandComponent],
  providers: [...annotations.providers],
})
export class SingleBrandModule { }