//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { LottieModule } from 'ngx-lottie';
import { InViewportModule } from 'ng-in-viewport';
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
import { ImgOnDemandModule } from '@Shared/img-on-demand/img-on-demand.module';
//#endregion Module Imports

//#region Component Imports
import { CategoryCollectionComponent } from '@Widgets/blocks/collection/category-collection/category-collection.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LottieModule,
    ImgOnDemandModule,
    InViewportModule
  ],
  exports: [CategoryCollectionComponent],
  declarations: [CategoryCollectionComponent]
})
export class CategoryCollectionModule extends DefaultModule { }
