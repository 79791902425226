//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
//#endregion Module Imports

//#region Component Imports
import { RateAppComponent } from '@Pages/home/components/rate-app/rate-app.component';
//#endregion Component Imports

//#region Providers Imports
import { RateAppService } from '@Globals/providers/rate-app.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  providers:[RateAppService],
  exports: [RateAppComponent],
  declarations: [RateAppComponent]
})
export class RateAppModule extends DefaultModule { }
 