import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import {
  AlertButton,
  AlertInput
} from '@ionic/core/dist/types/components/alert/alert-interface';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(public alertController: AlertController) { }

  async presentAlert(
    message: string,
    buttons: Array<string>,
    cssClass?: string | Array<string>
  ) {
    const alert = await this.alertController.create({
      message,
      buttons,
      cssClass
    });
    await alert.present().then(data => {
      this.addedClass();
    });
    await alert.onDidDismiss().then(data => {
      this.removeClass();
    });
  }

  async presentAlertConfirm(
    message: string,
    buttons: Array<AlertButton>,
    cssClass?: string | Array<string>,
    backdropDismiss = true,
    inputs?: Array<object>,
  ) {
    const alert = await this.alertController.create({
      message,
      inputs,
      buttons,
      cssClass,
      backdropDismiss
    });
    await alert.present().then(data => {
      this.addedClass();
    });
    await alert.onDidDismiss().then(data => {
      this.removeClass();
    });
  }

  async presentAlertPrompt(
    header: string,
    inputs: Array<AlertInput>,
    buttons: Array<AlertButton>,
    cssClass?: string | Array<string>
  ) {
    const alert = await this.alertController.create({
      header,
      inputs,
      buttons,
      cssClass
    });
    await alert.present().then(data => {
      this.addedClass();
    });
    await alert.onDidDismiss().then(data => {
      this.removeClass();
    });
  }

  async presentDismissAlert(
    message: string,
    buttons: Array<AlertButton>,
    cssClass?: string | Array<string>,
    backdropDismiss = true,
    inputs?: Array<object>,
  ) {
    const alert = await this.alertController.create({
      message,
      inputs,
      buttons,
      cssClass,
      backdropDismiss
    });
    await alert.present().then(data => {
      this.addedClass();
    });
    setTimeout(() => {
      alert.dismiss();
    }, 2000);
    await alert.onDidDismiss().then(data => {
      this.removeClass();
    });
  }

  addedClass() {
    document
      .getElementsByTagName('ion-app')
      .item(0)
      .classList.add('disable-scroll');
  }
  removeClass() {
    if (
      document
        .getElementsByTagName('ion-app')
        .item(0)
        .classList.contains('disable-scroll')
    ) {
      document
        .getElementsByTagName('ion-app')
        .item(0)
        .classList.remove('disable-scroll');
    }
  }


  dismissTopAlert(successCallBack?) {
    this.alertController
      .getTop()
      .then(topModal => {
        successCallBack && successCallBack(topModal);
      })
      .catch(() => { });
  }
}
