import { Injectable, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InviteComponent } from '@Features/payment/invite/invite.component';
import { DataService } from '@Globals/providers/data.service';
import { Subject } from 'rxjs';
import { CITY_MODAL_CSS_CLASS } from 'src/app/core/constants/core-constants';
import { InfoPopupComponent } from '@Shared/info-popup/info-popup.component';
import { MasterDataService } from '../master-data/master-data.service';
import { PaymentOfferDetailsComponent } from '@Shared/payment/payment-offer-details/payment-offer-details.component';
import { EVENT_NAMES, MODAL_IDS } from '@Globals/constants/payment/constants';
import { TermsConditionPopupComponent } from '@Shared/payment/terms-condition-popup/terms-condition-popup.component';
import { OfferDescriptionComponent } from '@Shared/payment/offer-description/offer-description.component';
import { EventsService } from '@Globals/providers/events.service';
import { GeofenceAlertComponent } from '@Pages/registration/geolocation/components/geofence-alert/geofence-alert.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService implements OnInit {
  public cityDataUpdate = new Subject();
  errorMessage = new Subject();
  constructor (
    protected modalCtrl: ModalController,
    protected dataService: DataService,
    protected masterDataService: MasterDataService,
    private eventsService: EventsService
    ) { }

  ngOnInit() {
    document
      .getElementsByTagName('ion-app')
      .item(0)
      .classList.add('disable-scroll');
  }
  
  async infoPopUp(source: string) {
    const modal = await this.modalCtrl.create({
      component: InfoPopupComponent,
      backdropDismiss: false,
      cssClass: CITY_MODAL_CSS_CLASS,
      componentProps: {
        source
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
    }
  }


  async openInviteModal(source) {
    if (this.masterDataService.masterData && !this.dataService.doesUserBelongToBulkBuyHub) {
      this.dataService.showInviteModalOnHome = false;
      const modal = await this.modalCtrl.create({
        component: InviteComponent,
        cssClass: 'invite-modal',
        componentProps: {
          source
        }
      });
      modal.present();
    }
  }

  dismissTopModal(successCallBack?) {
    this.modalCtrl
      .getTop()
      .then(topModal => {
        successCallBack && successCallBack(topModal, this.modalCtrl);
      })
      .catch(() => { });
  }
  

  async openPaymentOfferDetailModal(data) {
    const modal = await this.modalCtrl.create({
      component: PaymentOfferDetailsComponent,
      componentProps: data
    });
    return await modal.present();
  }

  async geofenceAlert(){
    const modal = await this.modalCtrl.create({
      component: GeofenceAlertComponent,
      cssClass: 'geofence-modal',
    });
    return await modal.present();
  }

  async openTermConditionPopup(position, offerDetail: any, source:string) {
    this.eventsService.trackEvents(EVENT_NAMES.OFFER_DETAILS, { position: position, source: source , paymentMethod: offerDetail.name});
    const offerDesc = offerDetail.offerDescription || offerDetail.offer_description;
    const modal = await this.modalCtrl.create({
      component: TermsConditionPopupComponent,
      componentProps: { description: offerDesc },
      breakpoints: [ 0, 0.5, 0.6, 1 ],
      cssClass: 'modal-bottom-sheet',
      initialBreakpoint: 0.6,
      handle: false,
    });
    return await modal.present();
  }

  async openOfferDescriptionComponent(offerList) {
    const modal = await this.modalCtrl.create({
      component: OfferDescriptionComponent,
      breakpoints: [ 0, 0.5, 0.8, 1 ],
      cssClass: 'modal-bottom-sheet',
      initialBreakpoint: 0.8,
      handle: false,
      id: MODAL_IDS.OFFER_DESCRIPTION,
      componentProps: {
        offerList: offerList
      }
    });
    return await modal.present();
  }

  async openMembershipTermConditionPopup(content: string) {
    const modal = await this.modalCtrl.create({
      component: TermsConditionPopupComponent,
      componentProps: { description: content },
      breakpoints: [ 0, 0.5, 0.6, 1 ],
      cssClass: 'modal-bottom-sheet',
      initialBreakpoint: 0.6,
      handle: false,
    });
    return await modal.present();
  }

  async openModal(component) {
    const modal = await this.modalCtrl.create({
        component: component,
        cssClass: ''
    });
    await modal.present();
    modal.onDidDismiss().then(res => {
    });

  }

  async openGenericModal(source, componentClass, cssClass) {
    if (this.masterDataService.masterData && !this.dataService.doesUserBelongToBulkBuyHub) {
      this.dataService.showInviteModalOnHome = false;
      const modal = await this.modalCtrl.create({
        component: componentClass,
        cssClass: cssClass,
        componentProps: {
          source
        }
      });
      modal.present();
    }
  }

  async openGenericInviteModal(source, componentClass) {
    if (this.masterDataService.masterData && !this.dataService.doesUserBelongToBulkBuyHub) {
      this.dataService.showInviteModalOnHome = false;
      const modal = await this.modalCtrl.create({
        component: componentClass,
        cssClass: 'invite-modal',
        componentProps: {
          source
        }
      });
      modal.present();
    }
  }

  async openGenericPauseModal(product, componentClass, redirectAfterPause = false, recurringMapper = null) {
    let pauseModalCss = 'modal-custom pause-calendar';
    if(recurringMapper.recurringCampaignProducts?.recurringCampaignMapper?.products?.[product.product_id]){
      pauseModalCss += ' pausing-subscription-strip';
    }
    const modal = await this.modalCtrl.create({
      component: componentClass,
      cssClass: pauseModalCss,
      componentProps: {
        product,
        redirectAfterPause,
        recurringMapper
      }
    });
    await modal.present();
    modal.onDidDismiss().then(res => {
      if (res.data) {
        recurringMapper.recurringCampaignValuesSet(true);
      }
    });
  }

  async openGenericUserFeedbackModal(pageContent, componentClass, successCallBack, recurringMapper) {
    const modal = await this.modalCtrl.create({
      component: componentClass,
      cssClass: '',
      componentProps: { pageContent, recurringMapper }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      successCallBack(data);
    }
  }

  async openGenericRecycleBagsModal(componentClass) {
    const modal = await this.modalCtrl.create({
      component: componentClass,
      cssClass: 'recycle-bags-modal'
    });
    await modal.present();
    modal.onDidDismiss().then(res => {
      this.dataService.promiseResolve(res.data);
    });
  }
}
