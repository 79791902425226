<ion-header class="ion-header-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button (click)="closeModal()">
        <ion-icon class="icon-close icons-property icon-xmd" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="divider-content" margin-top-small>
    <small class="ion-padding-start ion-text-uppercase" padding-right-small><strong>SET Quantity</strong></small>
  </div>
  <div class="app-counter-holder ion-padding" margin-top-extra-small>
    <mb-product-counter [counter]="counterQuantity" (counterChange)="counterChange($event)">
    </mb-product-counter>
  </div>
  <div [hidden]="selectedDate" class="divider-content ion-margin-top">
    <small class="ion-padding-start ion-text-uppercase" padding-right-small><strong>SET DATE</strong></small>
  </div>
  <div [hidden]="selectedDate" margin-top-large class="dropdown-list" [ngClass]="{ open: !showList }">
    <ion-label class="ion-padding-horizontal ion-align-items-center"
      (click)="showList = !showList; addAndRemoveCustomClass()">
      {{ selectedStartDate | amDateFormat: 'dddd, Do MMMM YYYY' }}</ion-label>
    <ul class="ion-no-padding" margin-top-medium>
      <ng-container *ngIf="componentType === preOrderScheduleType; else scheduleDateSelector;">
        <li *ngFor="let dateObj of startDateDropDown"
        [ngClass]="{'text-disabled' : dateObj.isLimitExceeded}"
          (click)="dateObj.isLimitExceeded ? false : dateSelected(dateObj.date);">
          {{ dateObj.date | amDateFormat: 'dddd, Do MMMM YYYY' }}
        </li>
      </ng-container>
      <ng-template #scheduleDateSelector>
        <li *ngFor="let date of startDateDropDown"
          (click)="dateSelected(date);">
          {{ date | amDateFormat: 'dddd, Do MMMM YYYY' }}
        </li>
      </ng-template>
    </ul>
  </div>
</ion-content>

<ion-footer mode="ios" class="ion-padding">
  <ion-button type="button" [disabled]="counterQuantity < 1" size="large" expand="block" class="mw-large"
    (click)="scheduleOrder()">{{ preOrderScheduleType === componentType ? 'Confirm My Order' : 'Schedule Order' }}
  </ion-button>
</ion-footer>