//#region Imports

//#region Angular Imports
import { Component, OnInit,Input } from '@angular/core'; 
//#endregion Angular Imports

//#region Ionic Imports
import { HTTPResponse } from '@ionic-native/http';
import { ModalController, Platform } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports 
import { DataService } from '@Globals/providers/data.service';
import { AlertService } from '@Globals/providers/alert.service';
import { EventsService } from '@Globals/providers/events.service';
import { RateAppService } from '@Globals/providers/rate-app.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
import { ALERT_BUTTON_TEXT, ANDROID, EVENT_NAMES, RATING_CONTENT } from '@Globals/constants/listing/constants';
import { FeedbackData } from '@Globals/interfaces/listing/interfaces';

import { Utilities } from '@Globals/classes/utilities'; 
import { CustomerRating } from '@Pages/home/interface/interfaces';
//#endregion Other Imports

//#endregion Imports
declare var window: any;

@Component({
  selector: 'app-rate',
  templateUrl: './rate-app.component.html',
  styleUrls: ['./rate-app.component.scss']
})
export class RateAppComponent implements OnInit {
  @Input()customerRating: CustomerRating;
  feedbackData: FeedbackData = {
    rating: -1,
    selectedFeedbackOptionIds: [],
    selectedFeedbackOptions: []
  };
  saveButtonDisplay: boolean = true;
  appRateModal = document.getElementsByTagName('app-rate');
  starRatingArray = new Array(5);
  rateOnStoreContent = this.platform.is(ANDROID)
    ? RATING_CONTENT.RATE_US_ON_PLAYSTORE
    : RATING_CONTENT.RATE_US_ON_APPSTORE;
  saveUserRating = (onDidDismiss = true, dismissModal = false) => {
    if (onDidDismiss) {
      if (
        this.feedbackData.rating >=
        this.customerRating.ratingOnPlaystore
      ) {
        this.eventsService.trackEvents(EVENT_NAMES.RATE_US, {
          'May Be Later': true
        });
        return;
      } else if (
        this.feedbackData.rating === -1 ||
        this.feedbackData.selectedFeedbackOptionIds.length === 0
      ) {
        this.eventsService.trackEvents(EVENT_NAMES.RATE_US, {
          Close: true
        });
        return;
      } else {
        const eventArgs = {
          Value: this.feedbackData.selectedFeedbackOptions,
          IsValue:
            this.feedbackData.selectedFeedbackOptionIds &&
            this.feedbackData.selectedFeedbackOptionIds.length > 0,
          Close: true
        };
        this.eventsService.trackEvents(EVENT_NAMES.RATE_US, eventArgs);
      }
    }
    const dataToBeSent = {
      customerId: this.masterDataService.masterData?.user?.id,
      customerRatingId: this.feedbackData.saveCustomerRatingId || '',
      rating: this.feedbackData.rating || '',
      feedbackId: this.feedbackData.selectedFeedbackOptionIds || [],
      comment: this.feedbackData.comment || '',
      hideLoader: true
    };
    if (this.feedbackData.saveCustomerRatingId) {
      this.rateAppService
        .updateUserRating(dataToBeSent)
        .subscribe((response: HTTPResponse) => {
          this.userRatingResponseCallback(response);
        });
    } else {
      this.rateAppService
        .saveUserRating(dataToBeSent)
        .subscribe((response: HTTPResponse) => {
          this.userRatingResponseCallback(response);
        });
    }
    if (dismissModal) {
      const eventArgs = {
        Value: this.feedbackData.selectedFeedbackOptions,
        IsValue:
          this.feedbackData.selectedFeedbackOptionIds &&
          this.feedbackData.selectedFeedbackOptionIds.length > 0,
        Submit: true
      };
      this.eventsService.trackEvents(EVENT_NAMES.RATE_US, eventArgs);
      this.dismissRateModal();
    }
  };

  userRatingResponseCallback = (response: HTTPResponse) => {
    if (response.data.status) {
      this.feedbackData.saveCustomerRatingId = response.data.data.id;
    } else {
      this.alertService.presentAlert(
        response.data.error_msg,
        [ALERT_BUTTON_TEXT],
        ''
      );
    }
  };

  dismissRateModal = () => {
    this.modalCtrl.dismiss();
  };

  constructor(
    public masterDataService: MasterDataService,
    private rateAppService: RateAppService,
    private alertService: AlertService,
    private modalCtrl: ModalController,
    private eventsService: EventsService,
    public dataService: DataService,
    private platform: Platform,
  ) { }

  ngOnInit() {
    this.saveButtonDisplay = this.dataService.firebaseMasterData.showRatingThankYouText;
    this.eventsService.trackEvents(EVENT_NAMES.RATE_US, {});
    this.customerRating.feedback &&
    this.customerRating.feedback.forEach(
        (option: any) => {
          option = {
            ...{ selected: false },
            ...option
          };
        }
      );
    this.dataService.saveUserRating = this.saveUserRating;
  }

  openStoreForRating() {
    this.eventsService.trackEvents(EVENT_NAMES.RATE_US, {
      'Playstore rating': true
    });
    if (Utilities.isApp()) {
      window.cordova.plugins.AppReview.requestReview().then().catch();
    }
    this.dismissRateModal();
  }

  userRating(starIndex) {
    this.feedbackData.rating = starIndex + 1;
    this.eventsService.trackEvents(EVENT_NAMES.RATE_US, {
      'Star Ratings': this.feedbackData.rating
    });
    setTimeout(() => {
      if (
        this.feedbackData.rating >=
        this.customerRating.ratingOnPlaystore
      ) {
        if (!this.dataService.firebaseMasterData.showRatingThankYouText) {
          this.saveButtonDisplay = false;
          this.openStoreForRating();
        }
        if (
          this.appRateModal[0] && this.appRateModal[0].classList.contains('has-below-five-star-rating')
        ) {
          this.appRateModal[0] && this.appRateModal[0].classList.remove('has-below-five-star-rating');
        }
        this.appRateModal[0] && this.appRateModal[0].classList.add('has-five-star-rating');
      } else {
        this.saveButtonDisplay = true;
        if (this.appRateModal[0] && this.appRateModal[0].classList.contains('has-five-star-rating')) {
          this.appRateModal[0] && this.appRateModal[0].classList.remove('has-five-star-rating');
        }
        this.appRateModal[0] && this.appRateModal[0].classList.add('has-below-five-star-rating');
      }
    });
    this.saveUserRating(false);
  }

  userFeedback(feedbackOption) {
    feedbackOption.selected = !feedbackOption.selected;
    if (feedbackOption.selected) {
      this.feedbackData.selectedFeedbackOptionIds.push(feedbackOption.id);
      this.feedbackData.selectedFeedbackOptions.push(feedbackOption.value);
    } else {
      const feedbackOptionIndex = this.feedbackData.selectedFeedbackOptionIds.indexOf(
        feedbackOption.id
      );
      if (feedbackOptionIndex > -1) {
        this.feedbackData.selectedFeedbackOptionIds.splice(
          feedbackOptionIndex,
          1
        );
        this.feedbackData.selectedFeedbackOptions.splice(
          feedbackOptionIndex,
          1
        );
      }
    }
  }
}
