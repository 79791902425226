//#region Imports

//#region Angular Imports
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import {
  HostBinding,
  SecurityContext,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Directive({
  selector: '[appTextHighlight]',
})
export class TextHighlightDirective implements OnInit {
  @Input() term: string;
  @Input() data: string;
  @HostBinding("innerHtml")
  content: string;
  constructor(
    private el: ElementRef,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (this.el && this.el.nativeElement) {
      if (this.term === "") {
        this.content = this.data;
      } else {
        let regex = new RegExp(
          this.term, "gi");
        let newText = this.data.replace(regex, (match: string) => {
          return `<mark class="highlight-color">${match}</mark>`;
        });
        const sanitzed = this.sanitizer.sanitize(
          SecurityContext.HTML,
          newText
        );
        this.content = sanitzed;
      }
    }
  }
}
