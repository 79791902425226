<ion-header class="ion-main-header sticky-header">
    <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium pl-0 padding-right-medium">
        <div class="d-flex padding-left-medium">
            <ion-buttons class="ion-buttons-arrow-back" id="custom-main-heading" slot="start">
                <ion-button (click)="closeModal()">
                    <ion-icon class="icon-arrow-back icons-property icon-md" color="dark" slot="icon-only"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title class="ion-no-padding ">
                Netbanking
            </ion-title>
        </div>
        <p class="ion-text-wrap ion-margin-top text-size-lg f-w-n color-dark-300 padding-left-medium">The Best and secure payment method</p>
    </ion-toolbar>
</ion-header>
<div class="payment-list padding-bottom-medium" *ngIf="masterDataService.paymentOptionSection.netBanking.offers.length || masterDataService.paymentOptionSection.netBanking.commonOffers.length">
    <mbs-payment-offer-listing [showLabel]="false" [offerList]="masterDataService.paymentOptionSection.netBanking.offers" [commonOffers] = "masterDataService.paymentOptionSection.netBanking.commonOffers"></mbs-payment-offer-listing>
</div>
<ion-content class="payment-option payment-option">
    <ion-toolbar class="search-toolbar f-group margin-top-0">
        <div class="search-container">
            <ion-searchbar [(ngModel)]="searchText" search-icon="search-btn" clear-icon="close" class="ion-no-padding" mode="ios" title="search" placeholder="Search your Banks" type="text">
                <ion-button class="no-ripple-effect ion-text-uppercase ion-no-padding search-btn" fill="clear" size="small" color="primary">
                    <ion-icon class="icon-search icon-base icons-property" color="dark"></ion-icon>
                </ion-button>
            </ion-searchbar>
        </div>
    </ion-toolbar>
    <ion-list class="padding-top-medium padding-horizontal-medium">
        <div class="ion-no-padding payment-option-list" *ngIf="!searchText">
            <ion-list-header class="padding-top-small">
                <ion-label class="sub-heading f-w-b">Frequently Used Banks</ion-label>
            </ion-list-header>

            <ion-item color="none" lines="none" class="br-bottom padding-vertical-extra-medium" *ngFor="let frequentBank of frequentNetBankingBanks;" (click)="submit(frequentBank.paymentMethod)">
                <ion-avatar slot="start" class="pm-w">
                    <ion-img [alt]="frequentBank.bankName" [src]="frequentBank.icon"></ion-img>
                </ion-avatar>
                <ion-label>
                    <p class="p-title f-w-m">{{frequentBank.bankName}}</p>
                </ion-label>
                <ion-button fill="clear tc" slot="end" class="ion-no-padding">
                    <ion-icon slot="icon-only" class="icon-arrow-right icons-property"></ion-icon>
                </ion-button>
            </ion-item>
        </div>
        <div class="ion-no-padding payment-option-list ">
            <ion-list-header>
                <ion-label class="sub-heading f-w-b">{{searchText ? 'Search Result':'Select Bank'}}</ion-label>
            </ion-list-header>

            <ion-item color="none" lines="none" class="br-bottom padding-vertical-extra-medium" (click)="submit(bank.paymentMethod)" *ngFor="let bank of netBankingBanks | filter: [{ key: 'bankName', value: searchText, checkForContainedIn: true}]">
                <ion-avatar slot="start" class="pm-w">
                    <ion-img [alt]="bank.bankName" [src]="bank.icon"></ion-img>
                </ion-avatar>
                <ion-label>
                    <p class="p-title f-w-m">{{bank.bankName}}</p>
                </ion-label>
                <ion-button fill="clear tc" slot="end" class="ion-no-padding">
                    <ion-icon slot="icon-only" class="icon-arrow-right icons-property"></ion-icon>
                </ion-button>
            </ion-item>
        </div>
    </ion-list>
</ion-content>