<ion-row class="inner-row">
  <div class="headline ion-text-uppercase ion-text-center ion-padding has-remove-bundle-button">
    <strong>Bundle Offer</strong>
    <p *ngIf="product.prop?.bundle_heading">
      <small>~ {{product.prop.bundle_heading}} ~</small>
    </p>
    <ion-buttons *ngIf="componentType === ProductListType.Cancelled_Products_On_Cart"
      (click)="triggerremoveCancelProduct(product.product_id); $event.stopPropagation()" class="remove-bundle">
      <ion-button color="light">
        <ion-icon color="dark" slot="icon-only" class="icon-close icons-property icon-base"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>
  <ion-grid class="ion-no-padding" [ngClass]="{ 'has-three-columns': product.bun_products?.length === 3 }">
    <ion-row>
      <ion-col *ngFor="let bundleProduct of product.bun_products">
        <ion-card class="ion-card-image ion-no-margin">
          <span class="offer-strip-success" *ngIf="bundleProduct?.free">Free</span>
          <div class="img-holder ion-no-padding">
            <app-img-on-demand [componentType]="PLPTypeImageOnDemand" [actualImgSrc]="
              productPath +
              (bundleProduct.image || bundleProduct.img)
" [defaultImgSrc]="'assets/img/default-product.svg'" [productId]="bundleProduct.product_id || bundleProduct.id"
              alt_image="img_bundleProduct_{{bundleProduct.image || bundleProduct.img}}"
              [imageName]="(bundleProduct.image || bundleProduct.img)"
              [fileStorageDirectoryName]="dataService.fileStorageDirectories.PRODUCT">
            </app-img-on-demand>
          </div>
          <ion-card-header class="has-ellipsis-ml-mh ion-no-padding">
            <ion-card-title text-ellipsis-ml>
              {{ bundleProduct.product_name || bundleProduct.nm || bundleProduct.name }}
            </ion-card-title>
          </ion-card-header>
          <ion-card-content class="ion-no-padding">
            <div class="padding-top-small">
              <!-- <p class="ion-text-muted"> -->
                <div (click)="openProductsVariantModal(product, productIndex); $event.stopPropagation();" class="variants-wrap mb-0 mt-sm" [ngClass]="{'no-variants': !(product?._newVariantProducts?.length)}">
                  <div><span class="text-holder"><span *ngIf="bundleProduct.qty > 1">{{ bundleProduct.qty }}
                    X</span>{{ bundleProduct?.wgt }}</span></div>
                  <ion-icon class="icons-property icon-down-arrow"></ion-icon>
                </div>
              <p>₹{{ product.prop?.show_sp ? bundleProduct?.sp : bundleProduct?.mrp }}
              </p>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="action ion-padding">

    <p [hidden]="(componentType === ProductListType.Cancelled_Products_On_Cart)"
      class="label-delivery-time ion-text-left"
      *ngIf="componentType !== ProductListType.Your_Cart && product.delivery_types_slot && product.delivery_types_slot[0] === deliveryType.frozen && dataService.isFrozenCheckEnabled;">
      {{dataService.firebaseMasterData.deliverySlotInfo}}</p>
    <ion-row class="ion-justify-content-between ion-align-items-center">
      <div class="left-section">
        <p *ngIf="!product.oos && !product.forcedOos; else OutOfStock">
          <strong class="f-w-b selling-price">₹{{ product.sp || product.price }}</strong>
          <ng-container *ngIf="
              (product.saving_per_unit && product.saving_per_unit !== 0) ||
              (product.hasOwnProperty('sp') &&
              product.mrp - product.sp > 0) ||
              (product.hasOwnProperty('price') &&
              product.mrp - product.price > 0)
            ">
            <span class="ion-text-muted actual-price">
              ₹{{ product.mrp_per_unit || product.mrp }}
            </span>
            <span class="ion-text-success discount-price">₹{{
                                                      product.saving_per_unit ||
                                                      product.mrp - product.sp ||
                                                      product.mrp - product.price | number: '1.2-2'
                                                      }} off</span>
          </ng-container>
          <span class="highlight d-block margin-top-extra-small"
            *ngIf="product.ct && product.ct !== '0' && currentHoursAndMinutes > product.ct">+1
            Day Delivery</span>
        </p>
        <p *ngIf="componentType === ProductListType.Cancelled_Products_On_Cart" class="bundle-unavailable">Currently
          unavailable</p>
      </div>
      <ng-template #OutOfStock>
        <p [ngClass]="product.UIMessage?'refillType':'qty'">
          {{product.UIMessage || 'Out OF Stock'}}</p>
      </ng-template>
      <mb-product-counter [isPlusOneDayDelivery]="product.delivery_types_slot && product.delivery_types_slot[0] === deliveryType.frozen &&
            product.ct && product.ct !== '0' && currentHoursAndMinutes > product.ct"
                                          *ngIf="!(componentType === ProductListType.Cancelled_Products_On_Cart) && !product.oos && !product.forcedOos; else oos"
                                          [productId]="product.product_id || product.id" [counter]="
            (basketIndex && basketIndex > 0) ? product.quantity : authService.userData?.tomorrowBasket?.[product.product_id || product.id]

            " (counterChange)="
            triggerCounterChange($event, product, productIndex)" (click)="$event.stopPropagation()">
                                      </mb-product-counter>
                                   
      <ng-template #oos>
        <ion-button *ngIf="
                (componentType === ProductListType.Product_Listing ||
                componentType === ProductListType.Cancelled_Products_On_Cart ||
                  componentType === ProductListType.Search_Page ||
                  componentType === ProductListType.Collection) &&
                  masterDataService.staticMasterData?.isOOS &&
                  dataService.showSeeSimilar;
                else requestButton
              " size="small" (click)="
              triggershowOrHideRecommendedProducts(
                  product,
                  (product.id || product.product_id) ===
                    recommendedProductList.product.id
                    ? seeSimilarActionType.hide
                    : seeSimilarActionType.show
                );$event.stopPropagation()
              " fill="outline" color="primary" class="mw-small btn-see-hide-similar">{{
                                              (product.id || product.product_id) ===
                                              recommendedProductList.product.id
                                                  ? 'Hide'
                                                  : 'See'
                                              }} Similar
        </ion-button>
        <ng-template #requestButton>
          <ion-button size="small"  (click)="triggerRequestProduct(product)"fill="outline" color="primary"
            class="mw-small request-button">
            {{ dataService.firebaseMasterData.refillTypesMetaData[ product.refillType ]?.CTAText || 'Request'}}
          </ion-button>
        </ng-template>
      </ng-template>
    </ion-row>

  </div>

</ion-row>