import { productToProductApi } from "./product";

export const basketToBasketApi = (basket) => {
    try { 
      let basketApi: Record<string, unknown> = {};
      basketApi.is_opted = basket.isOpted;
      basketApi.basket_id = basket.id,
     // basketApi.extra_fee = '',
      basketApi.date  = basket.date,
      basketApi.is_covid_fee = basket.isCovidFee,
      basketApi.is_otdf = basket.isOtdf,
      basketApi.mb_pass_saving = basket.mbPassSaving,
      basketApi.product_img_url = '',
      basketApi.products = [],
      basketApi.quantity = basket.quantity;
      basketApi.saving = basket.saving;
      basketApi.show_ssph = basket.showSsph
      basketApi.taxes = basket.taxes
      basketApi.total = basket.total
      basketApi.bill_details = null;

      if(basket?.products?.length) {
        const productApi = []
        basket.products.forEach((product) => {
            const updatedProduct = productToProductApi(product);
            if(updatedProduct) {
                productApi.push(updatedProduct);
            }
        });
        basketApi.products = productApi;
      }
     
      if(basket.billDetails) {
        basketApi.bill_details = {};
        basketApi.bill_details['total']  = basket.billDetails.total || 0;
        basketApi.bill_details['sub_total'] = basket.billDetails.subTotalAmount || 0
        basketApi.bill_details['sub_savings'] = basket.billDetails.subSavingsAmount || 0
        basketApi.bill_details['gst_services'] = basket.billDetails.gstAmount || 0
        basketApi.bill_details['applied_cashback'] = basket.billDetails.cashbackUsedAmount || 0
      }
      return basketApi;
    } catch(e) {
      return null
    }
  }