import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private showLoader: boolean = false;
    public showLoaderBehaviourSubject : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.showLoader);
    
    showLoading() {
        this.showLoader = true;
        this.showLoaderBehaviourSubject.next(this.showLoader);
    }

    hideLoading() {
        this.showLoader = false;
        this.showLoaderBehaviourSubject.next(this.showLoader);
    }
}
