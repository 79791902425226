export class ProductCutOff {
    time: string;
    text: string;

    setCutOffFromApi(productApi) {
        this.time = productApi.ct && productApi.ct !== '0' ? productApi.ct : '';
        this.text = productApi.ct_text || '';
        return this;
    }
}
