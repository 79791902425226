//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core'; 
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { PromoCodeInputComponent } from '@Shared/payment/promo-code-input/promo-code-input.component';
//#endregion Component Imports

//#region Providers Imports
import { AlertService } from '@Globals/providers/alert.service';
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, GENERATE_CONTENT_FOR_ALERT_LAYER } from '@Globals/constants/listing/constants';
import { PROMO_CODE_APPLY,SELECTED_OFFER } from '@Globals/constants/payment/constants';
import { BackType } from '@Globals/constants/listing/enums'; 
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-promocode-listing',
  templateUrl: './promocode-listing.component.html',
  styleUrls: ['./promocode-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromocodeListingComponent {
  @ViewChild(PromoCodeInputComponent) promoInput:PromoCodeInputComponent;
  isError: boolean = false;
  errorMessage: string;
  isOpenOfferInfo: boolean = false;
  selectedOfferDetail;
  selectedOffer = SELECTED_OFFER;
  backAction = BackType.Close;
  inviteCode:string;
  isModal:boolean =true;
  constructor(
    private alertService: AlertService,
    public dataService: DataService,
    private modalCtrl: ModalController,
    private _cd: ChangeDetectorRef
  ) { }

  openInviteCodeSuccessAlert(message: any) {
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(
        message
      ),
      [
        PROMO_CODE_APPLY.BUTTONS.OKAY
      ],
      PROMO_CODE_APPLY.CSS_CLASS
    );
  }

  applyInviteCode(inviteCode: string) {
    this.promoInput.applyInviteCode(inviteCode);
  }

  errorInviteCode(isError,errorMessage){
    this.isError =isError;
    this.errorMessage = errorMessage;
    this._cd.markForCheck();
  }

  openOfferDetail(offerInfo) {
    this.isOpenOfferInfo = true;
    this.selectedOfferDetail = offerInfo;
  }

  closeOfferDetail() {
    this.isOpenOfferInfo = false;
  }

  replaceDate(date) {
    return date.replace(/-/g, "/");
  }
  copyCode() {
    this.dataService.copyCode(this.selectedOfferDetail.name, EVENT_NAMES.PROMO_CODE_COPY);
  }

  onModalChange() {
    this.isError = false;
  }

  closePromoCodeBottomSheet() {
    this.modalCtrl.dismiss();
  }

}
