
<ion-list class="ion-no-padding horizontal-collection">
<ion-list-header class="ion-list-header" *ngIf="data?.name">
    <ion-row class="ion-justify-content-between full-width ion-align-items-center">
        <ion-label class="padding-right-small">
            {{ data.name }}
        </ion-label>
        <ion-button size="small" fill="clear" class="ion-no-padding no-ripple-effect has-mw" (click)="viewAllCollection()" color="light">View All 
            <ion-icon class="icons-property icon-arrow-right icon-white"></ion-icon>
        </ion-button>
    </ion-row>
</ion-list-header>
 <ion-item class="ion-no-padding" lines="none" color="none">
    <div class="scroll" scroll-x="true" *ngFor="let row of data?.table?.rows">
        <div class="ion-item-inner">
            <ion-card class="ion-card-custom fixed-size" (click)="navigateToCollectionList(column)" *ngFor="let column of row.columns">
                    <div class="ion-no-padding img-holder" *ngIf="column.images.leafBanner.image">
                        <app-img-on-demand
                        [actualImgSrc]="bannerFilePath + column.images.leafBanner.image"
                        [defaultImgSrc]="'assets/img/default-banner.png'" [showDefaultImage]="showDefaultImage"
                        alt_image="img_banner_{{column.images.leafBanner.image}}"
                        [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER"
                        [useImageOnDemand]="false" [imageName]="column.images.leafBanner.image">
                      </app-img-on-demand> 
                    </div>
            </ion-card>
        </div>
    </div>
 </ion-item>
</ion-list>
