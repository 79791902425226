//#region Imports

//#region Angular Imports
import { Component, Input, OnInit } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { HTTPResponse } from '@ionic-native/http';
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { timer } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AlertService } from '@Globals/providers/alert.service';
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { ModalService } from '@Globals/providers/modal.service';
import { ToastService } from '@Globals/providers/toast.service';

import { JuspayService } from '@App/shared/providers/juspay/juspay.service';
//#endregion Providers Imports

//#region Other Imports
import {  
    INVALID_FOR_AUTO_DEBIT, EVENT_NAMES,
    GENERATE_CONTENT_FOR_ALERT_LAYER
 } from '@Globals/constants/listing/constants';
import {
  MODAL_IDS,PG_WISE_JUSPAY_PAY_LOAD,
  EDENRED_BIN,FOOD_WALLET,
  CARD_ERROR_MESSAGES,
  SAVED_CARD_DEFAULT_CVV,
  CARD_TYPE,CARD_BRANDS,
  SAVED_CARD_DELETE_CONFIRMATION,
  SODEXO_BIN,CVV_ALERT
 } from '@Globals/constants/payment/constants';
import { AutoDebitStatus } from '@Globals/constants/listing/enums';
import { CardBrandType } from '@Globals/constants/payment/enums';
import { CardInfo } from '@Globals/interfaces/listing/interfaces';

import { Utilities } from '@Globals/classes/utilities';
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Other Imports

//#endregion Imports


@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: [ './card-details.component.scss' ],
})
export class CardDetailsComponent extends DefaultComponent {
  @Input() shoppingCreditAmount: string;
  @Input() pg: any;
  autoDebitStatus = AutoDebitStatus;
  cardValidations = CARD_TYPE.DEFAULT;
  cardType = CARD_TYPE;
  cards = [];
  cardInfo;
  PG_WISE_JUSPAY_PAY_LOAD = PG_WISE_JUSPAY_PAY_LOAD;
  whichCardSelected = '$$$$'; // stands for new card
  amount = {
    percentage: FOOD_WALLET.processingFeePercentage.percentage,
    percentageAmount: 0,
    totalAmountDeducted: 0,
    amount: 0
  };
  error = {
    showError: false,
    errorMessage: null
  };
  cardDetails = {
    cardNumber: null,
    cardName: null,
    valid: null,
    cvv: null,
    saveToLocker: false,
    autoDebitOneRupeesDeduct: true
  };
  cardBrands = CARD_BRANDS;
  constructor (
    private juspayService: JuspayService,
    private modalCtrl: ModalController,
    private toastService: ToastService,
    private alertService: AlertService,
    public dataService: DataService,
    private eventsService: EventsService,
    public masterDataService: MasterDataService,
    public modalService: ModalService
  ) { 
    super();
  }

  mbOnInit() {
    this.amount.amount = parseInt(this.shoppingCreditAmount, 10);
    this.amount.percentageAmount = this.amount.amount * FOOD_WALLET.processingFeePercentage.calculate;
    this.amount.totalAmountDeducted = this.amount.amount + this.amount.percentageAmount;
    this.shoppingCreditAmount = this.amount.totalAmountDeducted.toString();
    this.getUserCard();
  }
  closeModal(pg = null) {
    this.modalCtrl.dismiss(pg, '', MODAL_IDS.PAYMENT_TYPE);
  }
  changeSelectedCard(whichCardSelected) {
    if (whichCardSelected.includes('$$$$')) {
      this.whichCardSelected = whichCardSelected;
      this.cardDetails = {
        cardNumber: null,
        cardName: null,
        valid: null,
        cvv: null,
        saveToLocker: false,
        autoDebitOneRupeesDeduct: true
      };
      if (this.whichCardSelected === '$$$$') {
        this.cardValidations = CARD_TYPE.DEFAULT;
      } else {
        const selectedCard = this.cards[ parseInt(this.whichCardSelected.split('_')[ 1 ], 10) ];
        if (selectedCard.cardBrand in CARD_TYPE) {
          this.cardValidations = CARD_TYPE[ selectedCard.cardBrand ];
        }
      }
    }
  }

  submit() {
    if (this.error.showError) {
      return;
    }
    if (this.pg.value === PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName) {
      if (this.whichCardSelected === '$$$$') {
        if (this.cardInfo?.mandate_support) {
          this.initiatePayment(this.pg.value);
        } else {
          this.toastService.presentToast(INVALID_FOR_AUTO_DEBIT, 'not-valid-card', null, 4000);
        }
      } else {
        const selectedCard = this.cards[ parseInt(this.whichCardSelected.split('_')[ 1 ], 10) ];
        this.juspayService.cardInfo(selectedCard.cardIsin).subscribe((success: CardInfo) => {
          if (success.data.mandate_support) {
            this.initiatePayment(this.pg.value);
          } else {
            this.toastService.presentToast(INVALID_FOR_AUTO_DEBIT, 'not-valid-card', null, 4000);
          }
        });
      }
    } else {
      this.initiatePayment(this.pg.value);
    }
  }

  initiatePayment(pg) {
    if (this.whichCardSelected === '$$$$') {
      this.eventsService.trackEvents(EVENT_NAMES.SAVE_TO_LOCKER, {
        saveToLocker: this.cardDetails.saveToLocker
      });
      if (pg === PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName) {
        if (this.cardInfo?.type === CardBrandType.DEBIT_CARD) {
          pg = PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName;
        } else if (this.cardInfo?.type === CardBrandType.CREDIT_CARD) {
          pg = PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName;
        }
      }
      const fillData = PG_WISE_JUSPAY_PAY_LOAD[ pg ].payload;
      fillData.cardNumber = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.cardNumber = this.cardDetails.cardNumber;
      fillData.cardExpMonth = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.cardExpMonth = this.cardDetails.valid.split('/')[ 0 ];
      fillData.cardExpYear = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.cardExpYear = this.cardDetails.valid.split('/')[ 1 ];
      fillData.cardSecurityCode = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.cardSecurityCode = this.cardDetails.cvv;
      fillData.nameOnCard = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.nameOnCard = this.cardDetails.cardName;
      fillData.saveToLocker = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.saveToLocker = this.cardDetails.saveToLocker;
      fillData.shouldCreateMandate = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.shouldCreateMandate = this.pg.value === PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName;
      if (pg === PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName || pg === PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName) {
        fillData.paymentMethod = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.paymentMethod = this.cardInfo?.brand;
      }
      PG_WISE_JUSPAY_PAY_LOAD[ pg ].payload = fillData;
    } else {
      const selectedCard = this.cards[ parseInt(this.whichCardSelected.split('_')[ 1 ], 10) ];
      if (pg === PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName) {
        if (selectedCard.cardType === CardBrandType.DEBIT_CARD) {
          pg = PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName;
        } else if (selectedCard.cardType === CardBrandType.CREDIT_CARD) {
          pg = PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName;
        }
      }
      const fillData = PG_WISE_JUSPAY_PAY_LOAD[ `saved_${pg}` ].payload;
      fillData.cardSecurityCode = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.cardSecurityCode = SAVED_CARD_DEFAULT_CVV;
      fillData.cardToken = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.cardToken = selectedCard.cardToken;
      // fillData.cardNumber = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.cardNumber = selectedCard.cardNumber;
      // fillData.cardExpMonth = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.cardExpMonth = selectedCard.cardExpMonth;
      // fillData.cardExpYear = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.cardExpYear = selectedCard.cardExpYear;
      // fillData.nameOnCard = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.nameOnCard = selectedCard.nameOnCard;
      if (pg === PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName || pg === PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName || pg === PG_WISE_JUSPAY_PAY_LOAD.EDENRED.pgName) {
        fillData.paymentMethod = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.paymentMethod = selectedCard.cardBrand;
        fillData.cardSecurityCode = PG_WISE_JUSPAY_PAY_LOAD[ pg ].webPayload.cardSecurityCode = this.cardDetails.cvv;
      }
      fillData.shouldCreateMandate = this.pg.value === PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName;
      PG_WISE_JUSPAY_PAY_LOAD[ `saved_${pg}` ].payload = fillData;
      pg = `saved_${pg}`;
    }
    this.closeModal(pg);
  }
  restrictToDigits = evt => {
    if (evt.target.value.length >= 6) {
      this.getCardInfo(evt.target.value.substring(0, 6));
    }
    evt.target.value = evt.target.value.replace(
      /[^0-9]/g,
      ''
    );
  };
  appendHyphen = evt => {
    if (evt.target.value && evt.target.value.length === 2 && evt.detail.inputType !== 'deleteContentBackward') {
      timer().subscribe(() => {
        evt.target.value = evt.target.value + '/';
      });
    }
  };
  cardSelectionChange() {
    if (this.whichCardSelected === '$$$$') {
      this.whichCardSelected = '$$$$$$$$';
    } else {
      this.whichCardSelected = '$$$$'
    }
  }

  deleteSavedCard(event, card: any, index: number) {
    if (this.dataService.autoDebitUserData && this.dataService.autoDebitUserData.cardData && card.cardFingerprint === this.dataService.autoDebitUserData.cardData.cardFingerprint &&
      this.dataService.autoDebitUserData.status === this.autoDebitStatus.Activated) {
      event.target.value = '';
      this.toastService.presentToast('This card is linked with auto-pay. Please disable auto-pay to remove this card.');
      return;
    }
    if (event.target.value) {
      SAVED_CARD_DELETE_CONFIRMATION.BUTTONS.CONFIRM.handler = () => {
        this.juspayService.deleteSavedCard(card.cardToken, { cardRef: card.cardReference, cardFingerprint: card.cardFingerprint }).subscribe(() => {
          this.cards.splice(index, 1);
          event.target.value = '';
        });
      };
      SAVED_CARD_DELETE_CONFIRMATION.BUTTONS.CANCEL.handler = () => {
        event.target.value = '';
      };
      const cardNumber = card.cardNumber.substr(14);
      SAVED_CARD_DELETE_CONFIRMATION.META_DATA.MESSAGE = `Are you sure you want to delete card ending ${cardNumber}?`;

      this.alertService.presentAlertConfirm(
        GENERATE_CONTENT_FOR_ALERT_LAYER(SAVED_CARD_DELETE_CONFIRMATION.META_DATA),
        [
          SAVED_CARD_DELETE_CONFIRMATION.BUTTONS.CONFIRM,
          SAVED_CARD_DELETE_CONFIRMATION.BUTTONS.CANCEL
        ],
        SAVED_CARD_DELETE_CONFIRMATION.CSS_CLASS
      );
    }
  }
  getCardInfo(cardNumber: number) {
    this.juspayService.cardInfo(cardNumber).subscribe((success: CardInfo) => {
      if(success.data) {
        this.cardInfo = success.data;
        switch (this.pg.value) {
          case PG_WISE_JUSPAY_PAY_LOAD.EDENRED.pgName:
            this.updateCardErrorStatus(success, CARD_ERROR_MESSAGES.EDENRED_INVALID_CARD, false, true);
            break;
          case PG_WISE_JUSPAY_PAY_LOAD.SODEXO.pgName:
            this.updateCardErrorStatus(success, CARD_ERROR_MESSAGES.SODEXO_INVALID_CARD, false, true);
            break;
          case PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName:
            if (success.data.brand in CARD_TYPE) {
              this.cardValidations = CARD_TYPE[ success.data.brand ];
            }
            this.updateCardErrorStatus(success, CARD_ERROR_MESSAGES.CREDIT_INVALID_CARD, true);
            break;
          case PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName:
            this.updateCardErrorStatus(success, CARD_ERROR_MESSAGES.DEBIT_INVALID_CARD, true);
            break;
          case PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName:
            if (this.cardInfo?.type === CardBrandType.CREDIT_CARD) {
              if (success.data.brand in CARD_TYPE) {
                this.cardValidations = CARD_TYPE[ success.data.brand ];
              }
            }
            if (this.cardInfo?.type !== CardBrandType.CREDIT_CARD) {
              this.error.showError = true;
              this.error.errorMessage = CARD_ERROR_MESSAGES.AUTO_DEBIT_INVALID_CARD;
            } else {
             this.addErrorTimer();
            }
            break;
        }
      }
    }, (() => {
      this.error.showError = true;
      this.error.errorMessage = CARD_ERROR_MESSAGES.INVALID_CARD;
      this.addErrorTimer();
    }));
  }


  addErrorTimer() {
    timer().subscribe(() => {
      this.error.showError = false;
      this.error.errorMessage = null;
    });
  }

  updateCardErrorStatus(success, message: string, checkTypeOtherWiseBrand: boolean, isBinMatching = false) {
    if ((checkTypeOtherWiseBrand ? success.data.type !== CardBrandType[ this.pg.value ] : success.data.brand !== CardBrandType[ this.pg.value ])
      && (isBinMatching ? success.data.id !== SODEXO_BIN : true)) {
      this.error.showError = true;
      this.error.errorMessage = message;
    } else {
      this.addErrorTimer();
    }
  }

  getUserCard() {
    this.juspayService.getSavedCard().subscribe((success: HTTPResponse) => {
      if (success && success.data.status) {
        success.data.data.forEach(element => {
          switch (this.pg.value) {
            case PG_WISE_JUSPAY_PAY_LOAD.EDENRED.pgName:
              if (element.cardBrand === CardBrandType.EDENRED && element.cardIsin === EDENRED_BIN) {
                this.cards.push(element);
              }
              break;
            case PG_WISE_JUSPAY_PAY_LOAD.SODEXO.pgName:
              if (element.cardBrand === CardBrandType[ this.pg.value ]) {
                this.cards.push(element);
              }
              break;
            case PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName:
              if (element.cardType === CardBrandType[ this.pg.value ]) {
                this.cards.push(element);
              }
              break;
            case PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName:
              if (element.cardType === CardBrandType[ this.pg.value ]
                && element.cardBrand !== PG_WISE_JUSPAY_PAY_LOAD.SODEXO.pgName
                && element.cardIsin !== EDENRED_BIN) {
                this.cards.push(element);
              }
              break;
            case PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName:
              if ((element.cardType === CardBrandType.CREDIT_CARD)
                && element.cardIsin !== EDENRED_BIN && element.cardBrand !== CardBrandType.SODEXO) {
                this.cards.push(element);
              }
          }
          if (this.cards.length) {
            this.whichCardSelected = `$$$$_0`;
          }
        });
      }
    });
  }
  showCvvInfo() {
   this.alertService.presentAlertConfirm(
    GENERATE_CONTENT_FOR_ALERT_LAYER(
      CVV_ALERT.META_DATA
    ),
    [CVV_ALERT.BUTTONS.CLOSE],
    CVV_ALERT.CSS_CLASS
  );
  }
  ionViewWillLeave() {
    Utilities.getElementsByTagName('body').classList.remove(
      'has-card-delete-popover'
    );
  }
}
