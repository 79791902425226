//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
import { BottomNavModule } from '@MbUi/bottom-navs/one/bottom-nav.module';
import { HeaderModule } from '@MbUi/headers/one/header.module';
//#endregion Module Imports

//#region Component Imports
import { LayoutComponent } from '@Layouts/one/layout.component';
//#endregion Component Imports

//#region Providers Imports
import { LayoutService } from '@Layouts/one/providers/layout.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BottomNavModule,
    HeaderModule
  ],
  declarations: [LayoutComponent],
  entryComponents: [LayoutComponent],
  exports: [LayoutComponent],
  providers: [LayoutService]
})
export class LayoutModule extends DefaultModule { }
