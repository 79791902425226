//#region Imports

//#region Angular Imports
import { Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { timer, Observable } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import {MODAL_IDS,WALLET_ELIGIBILITY_STATUS} from '@Globals/constants/payment/constants';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-wallet-eligibility',
  templateUrl: './wallet-eligibility.component.html',
  styleUrls: ['./wallet-eligibility.component.scss'],
})
export class WalletEligibilityComponent {
  @Input() errorMessage: Observable<any>;
  error: string;

  constructor(private modalCtrl: ModalController) { }

  ionViewWillEnter() {
    this.errorMessage.subscribe((success: any) => {
      if (success.status === WALLET_ELIGIBILITY_STATUS.SUCCESS) {
        this.modalCtrl
          .getTop()
          .then(topModal => {
            topModal.dismiss();
          })
          .catch(() => { });
        this.modalCtrl.dismiss(true, '', MODAL_IDS.PAYMENT_TYPE);
      } else {
        this.error = success.gateway_error_message;
        timer(4000).subscribe(() => {
          this.modalCtrl.dismiss(false, '', MODAL_IDS.PAYMENT_TYPE);
        });
      }
    });
  }
}
