//#region Imports

//#region Angular Imports
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from "@ionic/angular";
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from "@Lib/defaults/default.module";
import { ImgOnDemandModule } from "@Shared/img-on-demand/img-on-demand.module";
//#endregion Module Imports

//#region Component Imports
import { VideoCardComponent } from "@Widgets/blocks/collection/video-card/video-card.component";
//#endregion Component Imports

//#region Providers Imports
import { CollectionService } from '@Globals/providers/collection.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ImgOnDemandModule],
  exports: [VideoCardComponent],
  declarations: [VideoCardComponent],
  providers: [CollectionService]
})
export class VideoCardModule extends DefaultModule {}
