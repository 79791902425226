//#region Imports

//#region Angular Imports
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import * as moment from 'moment';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { RecurringService } from '@Globals/providers/recurring.service';
//#endregion Providers Imports

//#region Other Imports
import { Utilities } from '@Globals/classes/utilities';
import { CalendarPlanType, recurringCampaignCashbackType, SubscribeAndSaveSlab } from '@Globals/constants/listing/enums';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mb-rom-rc-strip',
  templateUrl: './rom-rc-strip.component.html',
  styleUrls: ['./rom-rc-strip.component.scss'],
})
export class RestOfTheMonthRcStripComponent implements OnInit {
  @Input() recurringProductDetails: object;
  cashbackType = recurringCampaignCashbackType;
  calendarPlanType = CalendarPlanType;
  @Input() restOfTheMonthPercentProductCount: boolean = true;
  @Input() productList: Array<object>;
  @Input() restOfTheMonthBasketDate: string;
  percentValue: number|string;
  flatValue:string;
  constructor(public dataService: DataService, public router: Router, public recurringService: RecurringService, public masterDataService: MasterDataService) {
  }

  ngOnInit() {
    this.setPercentValue();
  }

  setPercentValue() { 
    this.recurringProductDetails['add_rec'] =  true;
    this.flatValue = this.recurringProductDetails['cb_val'];
    if(this.restOfTheMonthPercentProductCount){
      if(!this.recurringService.subscribedRecurringProduct(this.recurringProductDetails['product_id'])){  
        this.recurringProductDetails['add_rec'] =  false;
      } 
    }
    if (this.recurringService.recurringCampaignProducts?.recurringCampaignMapper?.products?.[this.recurringProductDetails['product_id']] && this.recurringService.recurringCampaignProducts?.recurringCampaignMapper?.products?.[this.recurringProductDetails['product_id']]['cb_type'] === this.cashbackType.PERCENT_TYPE){
      this.percentValue = this.recurringService.restOfTheMonthSlabValueSet(this.productList, SubscribeAndSaveSlab.CURRENT_SLAB, this.restOfTheMonthPercentProductCount,this.restOfTheMonthBasketDate);
    }
    this.masterDataService.recurringOrder && this.masterDataService.recurringOrder.length && this.masterDataService.recurringOrder.forEach((item:any)=>{
      if (item.product_id === this.recurringProductDetails['product_id'] && item.pausePeriod ) {
         let startDate = new Date(item.pausePeriod.startDate);
         let endDate = new Date(item.pausePeriod.endDate);
         let productDate = new Date();
         if(this.restOfTheMonthPercentProductCount){
          productDate.setDate((new Date()).getDate() + 1);
         }

         if(moment(productDate).isBetween(startDate,endDate) || Utilities.equalDates(productDate,startDate) ||  Utilities.equalDates(productDate,endDate)){
          this.percentValue='Paused';
          this.flatValue ='Paused';
        }
      }
    });
  }


}
