//#region Imports

//#region Angular Imports
import { ChangeDetectorRef, Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { Subject, Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from 'src/app/shared/providers/master-data/master-data.service';
import { EventsService } from 'src/app/shared/providers/events/events.service';
import { DataService } from '@Globals/providers/data.service';
import { Utilities } from 'src/app/shared/classes/utility/utilities';
import { ShoppingCreditsServices } from 'src/app/payment/providers/shopping-credits/shopping-credits.service';
import { PaymentModalService } from '@Globals/providers/payment-modal/payment-modal-service';
//#endregion Providers Imports

//#region Other Imports
import { PaymentOfferType } from '@Globals/constants/listing/enums';
import { LOW_BALANCE_ERROR_MESSAGES, EVENT_NAMES } from '@Globals/constants/listing/constants';
import { ADDITIONAL_CHARGES_TYPE, EVENT_SOURCE, OFFER_SOURCE, PAYMENT_GATEWAY } from '@Globals/constants/payment/constants';
import {MODAL_IDS,PG_WISE_JUSPAY_PAY_LOAD,PAYMENT_VALUES} from '@Globals/constants/payment/constants';
import { MEMBERSHIP_STATUS } from '@Pages/membership/membership-constants/membership-constants';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mb-low-balance',
  templateUrl: './low-balance.component.html',
  styleUrls: [ './low-balance.component.scss']
})
export class LowBalanceComponent extends DefaultComponent {
  @Input() payment;
  @Input() errorMsg: string;
  source = OFFER_SOURCE.LOW_BAL;
  showDefaultPayment = true;
  getStatusOfAutoDebit = new Subject();
  paymentOfferType = PaymentOfferType;
  pgWiseJuspayPayload = PG_WISE_JUSPAY_PAY_LOAD;
  membershipTextVisiblity=false;
  paymentOption = {
    value: '',
    amount: null,
    juspay: false,
    linked: false,
    token: '',
    walletId: ''
  };
  membershipOffer;
  lastPaymentAmount: any;
  showError = {
    min_amount_error: false,
    max_amount_error: false
  };
  subscription:Subscription;
  LOW_BALANCE_ERROR_MESSAGES = LOW_BALANCE_ERROR_MESSAGES;
  eventSourceOutSCPopup = EVENT_SOURCE.OUT_OF_SHOPPING_CREDIT_POPUP;
  appliedAdditionalChanges: any;
  additionalChargesType = ADDITIONAL_CHARGES_TYPE;
  updateLastPaymentAmount = evt => {
    this.lastPaymentAmount = this.paymentOption.amount = evt.target.value = evt.target.value.replace(
      /[^0-9]/g,
      ''
    );
    if(this.payment?.viaMembership){
      this.memberShipVisibility(); 
    }

    this.resetOfferSelection();
  };
  memberShipVisibility(){
    if(this.lastPaymentAmount > Number(this.shoppingCreditsServices.topUpRange?.min_amount)  && this.lastPaymentAmount < this.payment.amount){
      this.membershipTextVisiblity = true;
    } else{
      this.membershipTextVisiblity = false;
    }
  }
  executeTransaction = (pgValue?) => {
    pgValue && (this.paymentOption.value = pgValue);
    const dataToSend = {
      amount: this.paymentOption.amount,
      pg: this.paymentOption.value
    };
    const eventArgs = {
      'User Id': this.masterDataService.masterData?.user?.id,
      Amount: this.paymentOption.amount,
      'Payment Option': this.paymentOption.value,
      'PaymentGateway': PAYMENT_GATEWAY.JUSPAY,
      Source: 'Payment_popup',
      isMethod:
        this.payment &&
        this.payment.option &&
        this.payment.option !== this.paymentOption.value,
      isAmount:
        this.payment &&
        this.payment.amount &&
        this.payment.amount !== this.paymentOption.amount,
      default: this.showDefaultPayment,
      type:
        this.payment && this.payment.type && this.payment.type === 'retry'
          ? 'retry'
          : this.showDefaultPayment
            ? 'default'
            : 'edit'
    };
    if(this.paymentOption.value === PAYMENT_VALUES.CRED ) {
       const offerText = this.shoppingCreditsServices.getCredOffer();
       offerText && (eventArgs['cred_offer'] = offerText.offer);
    }
    this.eventsService.trackEvents(EVENT_NAMES.PAYMENT_START, eventArgs);
    this.closeModal(dataToSend);
  };
  membershipStatus =MEMBERSHIP_STATUS;

  constructor (
    private modalCtrl: ModalController,
    public masterDataService: MasterDataService,
    private eventsService: EventsService,
    public dataService: DataService,
    public shoppingCreditsServices: ShoppingCreditsServices,
    public paymentModalBaseService: PaymentModalService,
    private _cd: ChangeDetectorRef
  ) {
    super();
    this.shoppingCreditsServices.checkJiopaySegment();
  }

  mbOnInit(): void {
    if(this.payment.additionalCharges?.length){
      this.appliedAdditionalChanges = this.shoppingCreditsServices.getPrioritizeAC(this.payment.additionalCharges);
    }
    !this.masterDataService.isJioPayEnabled && this.dataService.addListener();
    this.shoppingCreditsServices.walletRefreshRequired && this.shoppingCreditsServices.refreshWallets();
    this.masterDataService.paymentOffers.length && (document.getElementsByClassName('low-balance-bottom-sheet')[0].classList.add('has-payment-offer-widget'));
    const payment = this.masterDataService.paymentOptions?.find((option) => {
      return option.value === this.payment.option;
    });
    this.payment.option = payment && payment.value ? payment.value : null;
    if(this.payment?.fromAccountDeletionFlow) {
      this.lastPaymentAmount = this.payment.balance;
    }
    if (this.payment && this.payment.option && this.payment.amount && (this.payment.option !== PG_WISE_JUSPAY_PAY_LOAD.SODEXO.pgName)) {
      if (this.payment.option.includes('saved_')) {
        this.payment.option = this.payment.option.split('saved_')[ 1 ];
      }
      this.paymentOption.juspay = true;
      this.paymentOption.value = this.payment.option;
      this.paymentOption.amount = this.lastPaymentAmount = parseInt(this.payment.amount, 10);
      this.showDefaultPayment = true;
      const index = this.masterDataService.paymentOptions.findIndex(option => option.value === payment.value);
      this.masterDataService.paymentOptions.splice(index, 1);
      this.masterDataService.paymentOptions.splice(0, 0, payment);
    } else if(!this.masterDataService.isJioPayEnabled) {
      this.paymentOption.amount = this.lastPaymentAmount;
      this.paymentOption.value = this.masterDataService.paymentOptions[ 0 ].value;
      this.paymentOption.juspay = this.masterDataService.paymentOptions[ 0 ].juspay;
      this.paymentOption.token = this.masterDataService.paymentOptions[ 0 ].token;
      this.paymentOption.walletId = this.masterDataService.paymentOptions[ 0 ].walletId;
      this.paymentOption.linked = this.masterDataService.paymentOptions[ 0 ].linked;
      this.showDefaultPayment = false;
      this.addFullHeightToModel();
    } else {
      this.paymentOption.amount = this.payment && this.payment.amount ? parseInt(this.payment.amount, 10) : this.lastPaymentAmount;
      this.showDefaultPayment = false;
    }
    this.dataService.paymentOfferThroughLowBalancePopup.subscribe((data) => {
      if(this.payment?.fromAccountDeletionFlow) {
        this.lastPaymentAmount = this.payment.balance;
        this.paymentOption.amount = this.payment.balance;
      } else {
        this.lastPaymentAmount = data.amount;
        this.paymentOption.amount = data.amount;
      }
    });
    if (this.masterDataService.masterData?.wallet_increase_offers && this.masterDataService.masterData.wallet_increase_offers.length) {
      const activateOfferIndex = Math.round(this.masterDataService.masterData?.wallet_increase_offers.length / 2);
      this.membershipOffer = this.masterDataService.masterData?.wallet_increase_offers[ activateOfferIndex - 1 ];
      this.payment.amount = this.membershipOffer.amount;
      if(this.payment?.fromAccountDeletionFlow) {
        this.lastPaymentAmount = this.payment.balance;
        this.paymentOption.amount = this.payment.balance;
      } else {
        this.lastPaymentAmount = this.membershipOffer.amount;
        this.paymentOption.amount = this.membershipOffer.amount;
      }
    }
    document.getElementsByTagName('ion-app').item(0).classList.add('disable-scroll');
    this.addKeyboardListener();
    this.getStatusOfAutoDebit.next(true);
    if(Utilities.isApp() && !this.masterDataService.isJioPayEnabled){
      this.shoppingCreditsServices.checkTwidEligibilityExpiration(this.lastPaymentAmount);
      this.subscription = this.dataService.runChangeDetForPaymentData.subscribe(() => {
        this._cd.detectChanges();
      });
    }

    const viewBasketButton = document.getElementById('reorder-basket');
    if(viewBasketButton){
      viewBasketButton.classList.add("hide-view-basket-cta");
    }
  }

  addKeyboardListener() {
    window.addEventListener('keyboardWillShow', () => {
      document.getElementsByTagName('body')[0].classList.add('is-keyboard-open');
    });
    window.addEventListener('keyboardWillHide', () => {
      document.getElementsByTagName('body')[0].classList.remove('is-keyboard-open');
    });
  }

  addFullHeightToModel() {
    if (this.dataService.firebaseMasterData.paymentOffers && !this.showDefaultPayment) {
      Utilities.getElementsByTagName('ion-modal').classList.add('offer-added', 'full-height');
    }
    document.getElementsByTagName('ion-modal')[0].classList.add('custom-modal-height');
    setTimeout(() => {
      this.resetOfferSelection();
    }, 1000);
  }

  resetOfferSelection() {
    this.dataService.paymentOfferAmountChanged.next(this.lastPaymentAmount);
    if(this.payment?.viaMembership){
      this.memberShipVisibility(); 
    } 
  }
  ionViewWillEnter() {
    this.getStatusOfAutoDebit.next(true);
    if(!this.shoppingCreditsServices.topUpRange){
      this.shoppingCreditsServices.getTopUp().then((data)=>{ 
        if(data){
          this.paymentOption.amount = this.lastPaymentAmount = data;
          this.setMembershipAmount();
        }});
    }else{
      this.paymentOption.amount = this.lastPaymentAmount = parseInt(this.shoppingCreditsServices.topUpRange.amount,10);
    }
    this.setMembershipAmount(); 
  }

  setMembershipAmount(){
    if(this.payment?.viaMembership){
      this.lastPaymentAmount = this.payment.amount;
      this.paymentOption.amount = this.lastPaymentAmount;
    }
  }

  ionViewWillLeave() {
    if (document.getElementsByTagName('ion-app').item(0).classList.contains('disable-scroll')) {
      document.getElementsByTagName('ion-app').item(0).classList.remove('disable-scroll');
    }
  }

  closeModal(data?) {
    this.modalCtrl.dismiss(data, '', MODAL_IDS.LOW_BALANCE_POPUP);
  }

  submit() {
    if (
      this.paymentOption.amount <
      (this.payment?.fromAccountDeletionFlow ? this.payment.balance : parseInt(this.shoppingCreditsServices.topUpRange?.min_amount, 10))
    ) {
      this.showError.min_amount_error = true;
      this.resetError();
      return;
    } else if (
      this.paymentOption.amount >
      parseInt(this.shoppingCreditsServices.topUpRange?.max_amount, 10)
    ) {
      this.showError.max_amount_error = true;
      this.resetError();
      return;
    } else {
      if(this.masterDataService.isJioPayEnabled) {
        this.executeTransaction(PAYMENT_GATEWAY.JIOPAY);
      } else {
        this.masterDataService.paymentOptions?.forEach((option) => {
          if (this.paymentOption.value === option.value) {
            this.paymentOption.token = option.token;
            this.paymentOption.walletId = option.walletId;
            this.paymentOption.linked = option.linked;
          }
        });
        this.paymentModalBaseService.initiatePayment(this.paymentOption, this.executeTransaction, this.paymentOption.amount);
      }
    }
  }

  resetError() {
    setTimeout(() => {
      this.showError = { min_amount_error: false, max_amount_error: false };
    }, 3000);
  }

  mbOnDestroy(): void {
    this.subscription?.unsubscribe(); 
    const viewBasketButton = document.getElementById('reorder-basket');
    if(viewBasketButton){
      viewBasketButton.classList.remove("hide-view-basket-cta");
    }
  }
}
