<ion-header class="ion-header-modal ion-header-modal-modal">
  <ion-toolbar>
    <ion-buttons class="ion-align-self-start" slot="end">
      <ion-button (click)="closeModal(false)">
        <ion-icon class="icon-close icons-property icon-xmd" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <h2>{{ masterDataService.masterData.welcome_code?.title || dataService.firebaseMasterData?.welcome_code?.title}}</h2>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-padding-vertical">
    <h4>How it works?</h4>
    <ion-row>
      <ion-col size="12">
        <ion-item class="ion-no-padding" lines="none" color="none">
          <ion-img alt="Top Up" src="../../../../assets/img/top-up-icon.svg"></ion-img>
          <ion-label class="ion-text-wrap">
            <h4 class="f-w-s-b text-size-small">Top Up!</h4>
            <p>With a topped up wallet, you run your house non-stop without making payments on every order. </p>
          </ion-label>
        </ion-item>
      </ion-col>

      <ion-col size="12">
        <ion-item class="ion-no-padding" lines="none" color="none">
          <ion-img alt="No Checkout" src="../../../../assets/img/no-checkout-icon.svg"></ion-img>
          <ion-label class="ion-text-wrap">
            <h4 class="f-w-s-b text-size-small">‘No Checkout’ Orders!</h4>
            <p>Add or remove items from the basket up till midnight. At midnight, all items in your basket are auto-checked out.</p>
          </ion-label>
        </ion-item>
      </ion-col>

      <ion-col size="12">
        <ion-item class="ion-no-padding" lines="none" color="none">
          <ion-img alt="Daily 7 AM Delivery!" src="../../../../assets/img/daily-icon.svg"></ion-img>
          <ion-label class="ion-text-wrap">
          <h4 class="f-w-s-b text-size-small">Daily 7 AM Delivery!</h4>
           <p>Your auto-checked out items are then delivered at your doorstep by 7 AM next morning. That's a Milkbasket assurance 👍</p>
          </ion-label>
        </ion-item>
      </ion-col>

     
    </ion-row>
  </ion-grid>

  <div class="ctn-gt-space-p">
    <ion-text>
      <!-- <p class="text-size-xsm margin-top-small margin-bottom-medium color-dark-300" [innerHTML]="masterDataService.masterData.welcome_code?.description || dataService.firebaseMasterData?.welcome_code?.description"></p> -->
      <p class="text-size-ext-sm margin-top-small color-dark-300">Note: Your money is 100% safe & secure with us!</p>
    </ion-text>
  </div>
  <mbs-promo-code-offer (onApplyInviteCode)="onOpenBottomSheet($event)"></mbs-promo-code-offer>
</ion-content>
<ion-footer class="ctn-gt-space-p">
  <ion-button (click)="closeModal(true)" shape="round" size="large" expand="block">Add Money Now</ion-button>
</ion-footer>