import { Injectable } from '@angular/core';
import { STORAGE_KEYS, GENERATE_CONTENT_FOR_ALERT_LAYER, LOGOUT_MODE } from '../../constants/constants';
import { LOGIN_SIGNUP_CONFIRMATION } from 'src/app/listing/constants/listing-constants';
import { CORE_ROUTES } from 'src/app/core/constants/coreRoutes';
import { ModalController, NavController } from '@ionic/angular';
import { AlertService } from '../alert/alert.service';
import { BehaviorSubject, noop, Subject } from 'rxjs';
import { AuthUserData, User } from '../../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authKey: string;
  refreshKey: string;
  userData: AuthUserData = {
  };
  inviteCode: string;
  promoCode: string;
  jioMartData = {
    isJioMartUser : false,
    isSelfHelpRedirect: false,
    isCalledFromHomeBanner: 'false'
    };
  source:string;
  isLogInSubject : Subject<string> = new Subject<string>();
  jioPreviousLogInNumber:string;
  userObj: User = {};
  userId:string;
  token:string;
  refreshTime:string;
  isLogout: BehaviorSubject<LOGOUT_MODE> = new BehaviorSubject<LOGOUT_MODE>(LOGOUT_MODE.NO_LOGOUT);
  addressDataForAutocomplete: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isAuthenticated : Subject<boolean> = new Subject<boolean>();
  constructor(
    private modalCtrl: ModalController,
    private alertService: AlertService,
    private navCtrl: NavController,
  ) {
  }

  // TODO:Add logic for following values and add more values if required( which will be used as any kind of authorization, operation wise)

  isLoggedIn() {
    this.authKey = localStorage.getItem(
      STORAGE_KEYS.AUTH_KEY
    );
    return !!this.authKey;
  }

  isUserPaid() { }

  isSocietyServiceable() { }

  getAuthKey() {
    this.authKey = localStorage.getItem(STORAGE_KEYS.AUTH_KEY);
    return this.authKey;
  }

  getRefreshKey() {
    this.refreshKey = localStorage.getItem(STORAGE_KEYS.REFRESH_KEY);
    return this.refreshKey ? this.refreshKey : null;
  }

  getRefreshTime() {
    this.refreshTime = localStorage.getItem(STORAGE_KEYS.REFRESH_TIME);
    return this.refreshTime ? this.refreshTime : new Date();
  }

  setAuthKey(authToken) {
    if (authToken) {
      this.authKey = authToken;
      localStorage.setItem(STORAGE_KEYS.AUTH_KEY, authToken);
    }
  }

  setRefreshKey(refreshToken, refreshTime) {
    if (refreshToken) {
      this.refreshKey = refreshToken;
      this.refreshTime = refreshTime;
      localStorage.setItem(STORAGE_KEYS.REFRESH_KEY, refreshToken);
      localStorage.setItem(STORAGE_KEYS.REFRESH_TIME, refreshTime);
    }
  }

  setAuthSubscriber() {
    this.isLogInSubject.next(this.authKey);
  }


  logout() {
    if(window.navigator.onLine) {
    this.authKey = null;
    this.refreshKey = null;
    this.refreshTime = null;
    this.isLogInSubject.next(this.authKey);
    localStorage.removeItem(STORAGE_KEYS.AUTH_KEY);
    localStorage.removeItem(STORAGE_KEYS.RECURRING_CAMPAIGN);
    localStorage.removeItem(STORAGE_KEYS.USER_DATA);
    localStorage.removeItem(STORAGE_KEYS.JIO_NUMBER);
    localStorage.removeItem(STORAGE_KEYS.ADDRESS_STATUS);
    localStorage.removeItem(STORAGE_KEYS.FIRST_ATB_SUCESS);
    localStorage.removeItem(STORAGE_KEYS.LAST_NOCHECKOUT_VISIT_DATE);
    localStorage.removeItem(STORAGE_KEYS.SAVINGS_DATA);
    localStorage.removeItem(STORAGE_KEYS.SAVINGS_LAST_UPDATED_ON);
    localStorage.removeItem(STORAGE_KEYS.FIREBASE_PUSH_TOKEN);
    localStorage.removeItem(STORAGE_KEYS.REFRESH_KEY);
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TIME);
    this.isAuthenticated.next(false);
    }
  }

  openLoginPrompt(source?) {
    LOGIN_SIGNUP_CONFIRMATION.BUTTONS.OKAY.handler = () => {
      this.modalCtrl.getTop().then(topModal => {
        if (topModal) {
          this.modalCtrl.dismiss();
        }
      });
      const navigateTo = source
        ? CORE_ROUTES.BOARDING + '/' + source
        : CORE_ROUTES.BOARDING;
      this.navCtrl.navigateRoot(navigateTo);
    };
    LOGIN_SIGNUP_CONFIRMATION.BUTTONS.CLOSE.handler = noop();
    const pastCutOffTimeContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
      LOGIN_SIGNUP_CONFIRMATION.META_DATA
    );
    this.alertService.presentAlertConfirm(
      pastCutOffTimeContent,
      [
        LOGIN_SIGNUP_CONFIRMATION.BUTTONS.OKAY,
        LOGIN_SIGNUP_CONFIRMATION.BUTTONS.CLOSE
      ],
      LOGIN_SIGNUP_CONFIRMATION.CSS_CLASS
    );
  }

  // Promocode handling using localstorage
  setPromoCodeToLocalstorage(promocode:string) {
    localStorage.setItem(STORAGE_KEYS.PROMO_CODE,promocode);
  }

  // Promocode handling using localstorage
  getPromoCodeFromLocalstorage() {
    return localStorage.getItem(STORAGE_KEYS.PROMO_CODE);
  }

  // Promocode handling using localstorage
  removePromoCodeFromLocalstorage() {
    localStorage.removeItem(STORAGE_KEYS.PROMO_CODE);
  }
}
