import { SocietyType } from '../enums/address-status';

export class UserAddress {
    __typename = 'UserAddress';
    line1: string;
    line2: string;
    fullAddress: string;
    cityId: number;
    hubId: number;
    status: number;

    setAddressFromApi(userApi) {
        try {
            if (userApi?.societyType === SocietyType.Locality) {
                const addressFloor = userApi?.addressFloor
                    ? `- ${userApi?.addressFloor as string}`
                    : '';
                const buildingName = userApi?.buildingName
                    ? `, ${userApi?.buildingName as string}`
                    : '';
                const landmark = userApi?.landmark ? `, ${userApi?.landmark as string}` : '';
                this.line1 = `${
                    userApi?.addressUnit.slice(0, 10) as string
                } ${addressFloor} ${buildingName}`;
                this.line2 = `${userApi?.society as string}, ${userApi?.streetName as string}`;
                this.fullAddress = `${
                    userApi?.addressUnit.slice(0, 10) as string
                } ${addressFloor}${buildingName}, ${userApi?.streetName as string}${landmark}, ${
                    userApi?.society as string
                }`;
            } else {
                this.line1 = userApi?.address;
                this.line2 = userApi?.society;
                this.fullAddress = `${this.line1}, ${this.line2}`;
            }

            this.status = userApi?.is_valid_address;
            this.hubId = userApi.hubId;
            this.cityId = userApi.city_id;
            return this;
        } catch {
            return null;
        }
    }
}
