//#region Imports

//#region Angular Imports
import { Injectable, NgZone } from '@angular/core';
import { take } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
//#endregion Angular Imports

//#region Ionic Imports

//#endregion Ionic Imports

//#region Library Imports

//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
// import { InfoPopupComponent } from '@Featu/info-popup/info-popup.component';
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { HttpService } from '@Globals/providers/http.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports
import { CORE_ROUTES, LISTING_ROUTES } from '@Globals/constants/listing/routes';
import { Observable } from 'rxjs';
import { PROFILE_APIS } from '@Globals/constants/profile/apis';
import { RecurringService } from './recurring.service';
import { DATA_UI_EVENT_SOURCE_TYPES } from '@App/registration/constants/registration-constants';

//#endregion Imports

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(private dataService: DataService,
    private zone: NgZone,
    private navCtrl: NavController,
    private httpService: HttpService,
    private masterDataService: MasterDataService,
    private recurringService: RecurringService
    ) { }


  sessionLogout() {
    if(window.navigator.onLine) {
      this.dataService.resetOffersData();
        // clear slab list for removing previously hold value
        this.recurringService.slablist = [];

        this.dataService.clearFirebaseConfigData();
        this.dataService.inviteCodes = [];
        this.masterDataService.paymentOptions = null;
        this.masterDataService.paymentOffers = [];
        this.masterDataService.localAddressStatus = null;
        this.masterDataService.resetPaymentOptionSection();
        this.masterDataService.resetCacheData();
        this.masterDataService.isShownJioStrip = false;
        this.masterDataService.saving = null;
        this.masterDataService.categoryType = null;
        this.masterDataService.jioStripData = { mbpassBasketValue:0,currentCashback:0,chashBackCount:false};
        this.masterDataService.masterDataUpdateObs.next(false);
        this.masterDataService.masterDataUpdateObs
          .pipe(take(2))
          .subscribe(dataLoaded => {
            this.zone.run(async () => {
              if (dataLoaded) {
                this.dataService.mbPassData = {};
                this.masterDataService.resetMasterData();
                if(!(window.location.href.indexOf(CORE_ROUTES.HOME_PAGE) > -1)) {
                this.navCtrl.navigateRoot(
                  '/' + LISTING_ROUTES.HOME
                );}
              }
            });
          });
        this.masterDataService.getMasterData(true, true, false, true, false, DATA_UI_EVENT_SOURCE_TYPES.SESSION_LOGOUT);
    }
  }

  logout(): Observable<object> {
    return this.httpService.post(PROFILE_APIS.LOGOUT, {
      udid: this.dataService.uuid,
      addWhiteLoader: 'white-custom-loader'
    });
  }
}
