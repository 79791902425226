import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DefaultComponent } from '@Lib/defaults/default.component';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-geofence-alert',
  templateUrl: './geofence-alert.component.html',
  styleUrls: ['./geofence-alert.component.scss'],
})
export class GeofenceAlertComponent extends DefaultComponent {
  
  public mapLottieConfig: AnimationOptions = {
    path: 'assets/lottie/map.json',
    autoplay: true,
    loop: true
  };
  public importLottieConfig: AnimationOptions = {
    path: 'assets/lottie/important.json',
    autoplay: true,
    loop: true
  };
  constructor(private modalCtrl: ModalController) { 
    super();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
