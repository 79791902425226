<ng-container [ngSwitch]="componentType">
  <ng-container *ngSwitchCase="counterType?.Normal">
    <div [ngClass]="{'disable-btn': masterDataService.masterData?.user?.wf || !masterDataService.isValidAddress || disableCounterForOfferProducts }">
      <ion-button mode="md" fill="outline" size="small" color="primary" class="mw-small add-button"
        *ngIf="!showZero && counterDataToShow == 0" (click)="increment()" [disabled]="checkForSegmentOffer || masterDataService.masterData?.user?.wf|| !masterDataService.isValidAddress || disableCounterForOfferProducts" title="add">
        <ion-icon class="icon-add icons-property ds-icon"></ion-icon>{{ isPlusOneDayDelivery ? '1 Day' : 'Add' }}
      </ion-button>

      <div *ngIf="showZero || counterDataToShow != 0" class="ion-align-items-center"
        [ngClass]="{ 'vertical-counter': verticalOrientation }">
        <small class="ion-text-uppercase" *ngIf="label">{{ label }}</small>
        <div class="counter-btn secondary ion-align-items-center">
          <ion-button size="small" class="ion-no-padding" fill="outline" color="primary" [disabled]="masterDataService.masterData?.user?.wf|| !masterDataService.isValidAddress"
            [ngClass]="{ disabled: !counterDataToShow }" (click)="decrement()" title="decrement">
            <ion-icon class="icon-subtract icons-property ds-icon"></ion-icon>
          </ion-button>
          <span class="count ion-text-center">{{ counterDataToShow }}</span>
          <ion-button size="small" class="ion-no-padding disable-light" fill="outline" color="primary" [disabled]="masterDataService.masterData?.user?.wf|| !masterDataService.isValidAddress ||  disableCounterForOfferProducts" 
            [ngClass]="{ disabled: maxValue && counterDataToShow == maxValue }" (click)="increment()" title="increment">
            <ion-icon class="icon-add icons-property ds-icon"></ion-icon>
          </ion-button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="counterType.TopNotch">
    <div *ngIf="showZero || counterDataToShow != 0" class="counter-btn secondary has-shadow ion-align-items-center" [ngClass]="{'disable-btn': masterDataService.masterData?.user?.wf|| !masterDataService.isValidAddress}">
      <ion-button size="small" class="ion-no-padding no-ripple-effect" fill="clear" color="primary"[disabled]="masterDataService.masterData?.user?.wf|| !masterDataService.isValidAddress"
        [ngClass]="{ disabled: !counterDataToShow }" (click)="decrement()" title="decrement">
        <ion-icon class="icon-subtract icons-property ds-icon"></ion-icon>
      </ion-button>
      <span class="count ion-text-center">{{ counterDataToShow }}</span>
      <ion-button size="small" class="ion-no-padding no-ripple-effect disable-light" fill="clear" color="primary" [disabled]="masterDataService.masterData?.user?.wf|| !masterDataService.isValidAddress" 
        [ngClass]="{ disabled:  (maxValue && counterDataToShow == maxValue)|| masterDataService.masterData?.user?.wf|| !masterDataService.isValidAddress }" (click)="increment()" title="increment">
        <ion-icon class="icon-add icons-property ds-icon"></ion-icon>
      </ion-button>
    </div>
  </ng-container>
</ng-container>
