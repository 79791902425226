//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-three-column-in-single-row-block',
  templateUrl: './three-column-in-single-row.component.html',
  styleUrls: ['./three-column-in-single-row.component.scss']
})
export class ThreeColumnInSingleRowComponent extends BaseCollectionComponent {
  collectionTableData = [];

  mbOnInit(): void {
    this.data?.table?.rows?.forEach(element => {
      this.collectionTableData = this.collectionTableData.concat(element.columns);
    });
    super.mbOnInit();
  }

}
 