import { REACT_WIDGET_MAP } from '@Globals/constants/react/widget-map.constants';

export const REACT_ROUTE_PACKAGE_MAP: {[key in REACT_WIDGET_MAP]: () => Promise<any>} = {
    [REACT_WIDGET_MAP.ACCOUNT_HISTORY]: () => import(/* webpackChunkName: "@mb-pages/account-history" */ '@mb-pages/account-history'),
    [REACT_WIDGET_MAP.TRANSACTION_HISTORY]: () => import(/* webpackChunkName: "@mb-pages/transaction-history" */ '@mb-pages/transaction-history'),
    [REACT_WIDGET_MAP.VALUE_PASS]: () => import(/* webpackChunkName: "@mb-pages/value-pass" */ '@mb-pages/value-pass'),
    [REACT_WIDGET_MAP.VALUE_PASS_HISTORY]: () => import(/* webpackChunkName: "@mb-pages/value-pass" */ '@mb-pages/value-pass'),
    [REACT_WIDGET_MAP.LOGIN]: () => import(/* webpackChunkName: "@mb-pages/login" */ '@mb-pages/login'),
    [REACT_WIDGET_MAP.USER_MENU]: () => import(/* webpackChunkName: "@mb-pages/user-menu" */ '@mb-pages/user-menu'),
    [REACT_WIDGET_MAP.GO_GREEN]: () => import(/* webpackChunkName: "@mb-pages/go-green" */ '@mb-pages/go-green'),
    [REACT_WIDGET_MAP.RECYCLE_BAG]: () => import(/* webpackChunkName: "@mb-pages/recycle-delivery-bags" */ '@mb-pages/recycle-delivery-bags'),
    [REACT_WIDGET_MAP.RECYCLE_CARTON]: () => import(/* webpackChunkName: "@mb-pages/recycle-cartons" */ '@mb-pages/recycle-cartons'),
    [REACT_WIDGET_MAP.RECYCLE_CARTON_KNOW_MORE]: () => import(/* webpackChunkName: "@mb-pages/recycle-cartons" */ '@mb-pages/recycle-cartons'),
   [REACT_WIDGET_MAP.PRODUCT_CATEGORY]: () => import(/* webpackChunkName: "@mb-pages/product-category" */ '@mb-pages/product-category'),
   [REACT_WIDGET_MAP.REFERRAL]: () => import(/* webpackChunkName: "@mb-pages/referral" */ '@mb-pages/referral'),
   [REACT_WIDGET_MAP.REFERRAL_HOW_IT_WORKS]: () => import(/* webpackChunkName: "@mb-pages/referral" */ '@mb-pages/referral'),
   [REACT_WIDGET_MAP.REFERRAL_HISTORY]: () => import(/* webpackChunkName: "@mb-pages/referral" */ '@mb-pages/referral'),
   [REACT_WIDGET_MAP.SHOPPING_CREDIT]: () => import(/* webpackChunkName: "@mb-pages/wallet" */ '@mb-pages/wallet'),
    [REACT_WIDGET_MAP.HOME]: () => import(/* webpackChunkName: "@mb-pages/home" */ '@mb-pages/home'),
    [REACT_WIDGET_MAP.PROFILE]: () => import(/* webpackChunkName: "@mb-pages/profile" */ '@mb-pages/profile'),
    [REACT_WIDGET_MAP.ACCOUNT_CLOSURE]: () => import(/* webpackChunkName: "@mb-pages/account-closure" */ '@mb-pages/account-closure'),
    [REACT_WIDGET_MAP.ACCOUNT_DELETED]: () => import(/* webpackChunkName: "@mb-pages/account-deleted" */ '@mb-pages/account-deleted'),
    [REACT_WIDGET_MAP.CALENDAR_JOURNEY]: () => import(/* webpackChunkName: "@mb-pages/calendar-journey" */ '@mb-pages/calendar-journey'),
    [REACT_WIDGET_MAP.FRAUD_USER]: () => import(/* webpackChunkName: "@mb-pages/fraud-user" */ '@mb-pages/fraud-user'),
    [REACT_WIDGET_MAP.EXTERNAL]: () => import(/* webpackChunkName: "@mb-pages/external" */ '@mb-pages/external'),
    [REACT_WIDGET_MAP.FAQ]: () => import(/* webpackChunkName: "@mb-pages/faq" */ '@mb-pages/faq'),
    [REACT_WIDGET_MAP.CATEGORY_PRODUCT_LIST]: () => import(/* webpackChunkName: "@mb-pages/category-product-list" */ '@mb-pages/category-product-list'),
    [REACT_WIDGET_MAP.SAVING]: () =>import( /* webpackChunkName: "@mb-pages/user-savings" */ "@mb-pages/user-savings"),
    [REACT_WIDGET_MAP.SIGNUP_JOURNEY]: () => import(/* webpackChunkName: "@mb-pages/signup-journey" */ '@mb-pages/signup-journey'),
    [REACT_WIDGET_MAP.PRODUCT_DETAIL]: () => import(/* webpackChunkName: "@mb-pages/product-detail" */ '@mb-pages/product-detail'),
    [REACT_WIDGET_MAP.COLLECTION_DASHBOARD]: () => import(/* webpackChunkName: "@mb-pages/collection-dashboard" */ '@mb-pages/collection-dashboard'),
    [REACT_WIDGET_MAP.MEMBERSHIP]: () => import(/* webpackChunkName: "@mb-pages/user-membership" */ '@mb-pages/user-membership'),
    [REACT_WIDGET_MAP.BANNER_PRODUCT_LIST]: () => import(/* webpackChunkName: "@mb-pages/banner-product-list" */ '@mb-pages/banner-product-list'),
    [REACT_WIDGET_MAP.PRODUCT_LAUNCH_LIST]: () => import(/* webpackChunkName: "@mb-pages/product-launch-list" */ '@mb-pages/product-launch-list'),
    [REACT_WIDGET_MAP.SPONSORED_PRODUCT_LIST]: () => import(/* webpackChunkName: "@mb-pages/sponsored-product-list" */ '@mb-pages/sponsored-product-list'),
    [REACT_WIDGET_MAP.CART]: () => import(/* webpackChunkName: "@mb-pages/cart" */ '@mb-pages/cart'),
    [REACT_WIDGET_MAP.HOME_CART]: () => import(/* webpackChunkName: "@mb-pages/cart" */ '@mb-pages/cart'),
    [REACT_WIDGET_MAP.SUBSCRIPTION_ORDERS]: () => import(/* webpackChunkName: "@mb-pages/subscription-orders" */ '@mb-pages/subscription-orders'),
    [REACT_WIDGET_MAP.SUBSCRIBE_TO_SAVE]: () => import(/* webpackChunkName: "@mb-pages/subscribe-to-save" */ '@mb-pages/subscribe-to-save'),
    [REACT_WIDGET_MAP.SUBSCRIBE_TO_SAVE_EDUCATION]: () => import(/* webpackChunkName: "@mb-pages/subscribe-to-save-education" */ '@mb-pages/subscribe-to-save-education'),
    [REACT_WIDGET_MAP.SUBSCRIPTION_CASHBACK]: () => import(/* webpackChunkName: "@mb-pages/subscription-cashback" */ '@mb-pages/subscription-cashback'),
    [REACT_WIDGET_MAP.SUBSCRIPTION_SEARCH]: () => import(/* webpackChunkName: "@mb-pages/subscription-search" */ '@mb-pages/subscription-search'),
    [REACT_WIDGET_MAP.BILL_BUSTER]: () =>import( /* webpackChunkName: "@mb-pages/bill-buster" */ "@mb-pages/bill-buster"),
    [REACT_WIDGET_MAP.SET_SUBSCRIPTION_PLAN]: () => import(/* webpackChunkName: "@mb-pages/set-subscription-plan" */ '@mb-pages/set-subscription-plan'),
    [REACT_WIDGET_MAP.PAYMENT_METHOD]: () => import(/* webpackChunkName: "@mb-pages/payment-methods" */ '@mb-pages/payment-methods'),
    [REACT_WIDGET_MAP.ORDER_DETAILS]: () =>import( /* webpackChunkName: "@mb-pages/order-detail" */ "@mb-pages/order-detail" ),
    [REACT_WIDGET_MAP.SEARCH]: () =>import( /* webpackChunkName: "@mb-pages/search" */ "@mb-pages/search"),
    [REACT_WIDGET_MAP.SET_USER_HOLIDAYS]: () =>import( /* webpackChunkName: "@mb-pages/user-holidays" */ "@mb-pages/user-holidays"),
    [REACT_WIDGET_MAP.USER_HOLIDAYS_LIST]: () =>import( /* webpackChunkName: "@mb-pages/user-holidays" */ "@mb-pages/user-holidays"),
    [REACT_WIDGET_MAP.GEOLOCATION]: () => import(/* webpackChunkName: "@mb-pages/geolocation" */ '@mb-pages/geolocation'),
    [REACT_WIDGET_MAP.COLLECTION_SINGLE_CELL]: () =>import( /* webpackChunkName: "@mb-pages/collection-product-list" */ "@mb-pages/collection-product-list"),
    [REACT_WIDGET_MAP.CONTACT_US]: () => import(/* webpackChunkName: "@mb-pages/contact-us" */ '@mb-pages/contact-us'),
};
