<img class="default-image" *ngIf="!isActualImgLoaded && showDefaultImage" [src]="defaultImgSrc" />
<ion-skeleton-text *ngIf="!isActualImgLoaded && !showDefaultImage && showDefaultShimmer" class="default-image-shimmer" animated></ion-skeleton-text>
<ion-img *ngIf="useImageOnDemand; else downloadAllImage" [src]="imageSrc" (ionImgDidLoad)="onLoad($event)" 
    (ionError)="onError($event)">
</ion-img>
<ng-template #downloadAllImage>
    <ng-container *ngIf="useGumletSrc; else defaultImg">
           <img (error)="onError($event)" (load)="onLoad($event)" class="img-load" data-src="{{imageSrc}}" id="imageLoad" />
    </ng-container>
    <ng-template #defaultImg>
           <img (error)="onError($event)" (load)="onLoad($event)" class="img-load" [src]="imageSrc" id="imageLoad"  />
    </ng-template>
</ng-template>
