//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { environment } from '@Env/environment';
import { LeafMediaType } from '@Globals/constants/listing/enums';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-horizontal-widget-block',
  templateUrl: './horizontal-widget.component.html',
  styleUrls: ['./horizontal-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalWidgetComponent extends BaseCollectionComponent {
  collectionTableData = [];
  leafMediaType = LeafMediaType;
  collectionFilePath = environment.collectionPath;

  mbOnInit(): void {
    this.data?.table?.rows?.forEach(element => {
      this.collectionTableData = this.collectionTableData.concat(element.columns);
    });
    super.mbOnInit();
  }

}
