//#region Imports

//#region Angular Imports
import { Injectable } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController, Platform } from '@ionic/angular'; 
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { ToastService } from '@Globals/providers/toast.service';
import { ModalService } from '@Globals/providers/modal.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, EVENT_SOURCES } from '@Globals/constants/listing/constants';
import { LISTING_ROUTES } from '@Globals/constants/listing/routes';
import { CITY_MODAL_CSS_CLASS, EXIT_PAGE_ROUTES_MAPPER } from '@App/core/constants/core-constants';
import { Router } from '@angular/router';

//#endregion Other Imports

//#endregion Imports

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {
  counterForAppExit = 0;
  reactBackButtonCb;
  
  constructor ( 
    public masterDataService: MasterDataService,
    public dataService: DataService,
    public eventsService: EventsService,
    private navCtrl: NavController,
    private platform: Platform,
    private modalService: ModalService,
    private router: Router,
    private toastService: ToastService,
  ) { }

  navigateTo(
    trackNavigation?,
    category?,
    subCategory?,
    categoryIndex?,
    subCategoryIndex?
    ) {
        let navigationURL = trackNavigation;
        let routeArgs = {};
        switch (trackNavigation) {
            case LISTING_ROUTES.CATEGORY:
            this.eventsService.trackCategoryView(category, EVENT_SOURCES.HOME, this.masterDataService.masterData?.user?.hubId);
            this.masterDataService.selectedCategory = category;
            this.masterDataService.selectedSubcategoryIndex = 0;
            navigationURL = LISTING_ROUTES.BASE + LISTING_ROUTES.CATEGORY + category.id;
            break;
            case LISTING_ROUTES.SUBCATEGORY:
            let source = EVENT_NAMES.HOME;
            const eventArgument: any = {};
            if (this.dataService.mbPassData.applyCategoryAndSubcategory && this.dataService.mbPassData.applyCategoryAndSubcategory[ subCategory.id ]) {
                source = EVENT_SOURCES.MB_PASS;
                eventArgument[ EVENT_SOURCES.MB_PASS ] = this.dataService.mbPassData.purchased;
            }
            eventArgument['hub_id'] = this.masterDataService.masterData?.user?.hubId;
            this.eventsService.trackSubCategoryView(subCategory, source, eventArgument, category);
            if (category.id) {
                this.masterDataService.selectedCategory = category.name ? category : this.masterDataService.categories?.find((cat) => {
                return cat.id === subCategory.categoryId;
                });
                this.masterDataService.selectedSubcategoryIndex = category.name ? subCategoryIndex : subCategory.subCategoryIndex;
            } else {
                this.masterDataService.getSelectedIndexes(subCategory.id);
            }
            navigationURL = LISTING_ROUTES.BASE + LISTING_ROUTES.SUBCATEGORY + subCategory.id;
            break;
            case LISTING_ROUTES.YOUR_CART:
            routeArgs = { animated: false };
            const eventArgs = { source: EVENT_SOURCES.HOME };
            this.eventsService.trackEvents(EVENT_SOURCES.CART_VIEWED, eventArgs);
            navigationURL = LISTING_ROUTES.BASE + LISTING_ROUTES.YOUR_CART;
            break;
        }
        this.navCtrl.navigateForward(navigationURL, routeArgs);
    }

    offerBasedNavigation(offerDetail, source) { 
      const eventArgs  = {
        offer_id: offerDetail.id,
        type: 'sample_offer',
        Source: source
      };
      this.eventsService.trackEvents(EVENT_NAMES.SAMPLE_OFFER_CLICKED, eventArgs);
      let eventSource = EVENT_SOURCES.SAMPLE_OFFER_HOME;
      switch (source) {
        case EVENT_SOURCES.HOME:
          eventSource = EVENT_SOURCES.SAMPLE_OFFER_HOME;
          break;
        case EVENT_SOURCES.CATEGORY:
          eventSource = EVENT_SOURCES.SAMPLE_OFFER_CATEGORY;
          break;
        case EVENT_SOURCES.YOUR_CART:
          eventSource = EVENT_SOURCES.SAMPLE_OFFER_CART;
          break;
      }
      switch (offerDetail.type) {
        case 1:
          this.masterDataService.selectedCategory = this.masterDataService.getCategoryDetails(offerDetail.offerDetails.typeIds[ 0 ]);
          this.masterDataService.selectedSubcategoryIndex = 0;
          this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.CATEGORY +
            this.masterDataService.selectedCategory.id + '/' + eventSource, { animated: false });
          break;
        case 2:
          this.masterDataService.getSelectedIndexes(offerDetail.offerDetails.typeIds[ 0 ]);
          let selectedCategory;
          for (const category of this.masterDataService.categories) {
            const subCategoryDetail = category.subcategories.find(subCat => {
              return +subCat.id === +offerDetail.offerDetails.typeIds[ 0 ];
            });
            if(subCategoryDetail) {
              selectedCategory = category;
              break;
            }
          }
          if(selectedCategory) {
          this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.SUBCATEGORY +
            offerDetail.offerDetails.typeIds[ 0 ] + '/' + selectedCategory?.id +'/' + eventSource, { animated: false });
          }
          break;
        case 3:
        case 5:
          this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.OFFER_COLLECTION_PLP + offerDetail.offerDetails.listId + '/' + offerDetail.id + '/' + eventSource, { animated: false });
          break;
      }
    }

    setupDeviceBackForReact(cb) {
      this.reactBackButtonCb = cb;
    }

    backButtonSubscriber() {
      this.platform.backButton.subscribeWithPriority(10,() => {
        if( this.reactBackButtonCb) {       
          if(this.reactBackButtonCb() === true) {
            return;
          }
        } 
        if(this.dataService.backButtonOfferHandling){
          return
        }
        this.modalService.dismissTopModal((topModal,modalCtrl) => {
          if(!this.masterDataService.masterData?.user?.cinfo?.acceptTnc){
            return false;
          }
          if (topModal) {
            if (topModal.className.match(CITY_MODAL_CSS_CLASS)) {
              this.router.navigateByUrl(this.router.url, {
                replaceUrl: true
              });
            }
            else {
              modalCtrl.dismiss();
            }
          } else if (
            this.router.url.match(EXIT_PAGE_ROUTES_MAPPER.HOME) || 
            this.router.url.match(EXIT_PAGE_ROUTES_MAPPER.BOARDING) || 
            this.router.url.match(EXIT_PAGE_ROUTES_MAPPER.SIGNUP_JOURNEY)
          ) {
            const appName = 'app';
            if (this.counterForAppExit === 1) {
              navigator[ appName ].exitApp();
            } else {
              this.counterForAppExit++;
              this.toastService.presentToast('Press again to exit');
              setTimeout(() => {
                this.counterForAppExit = 0;
              }, 2100);
            }
          }
           else {
            if(!this.dataService.backButtonOfferHandling){
              this.navCtrl.pop();
            }    
          }
       });
      });
    }

}
