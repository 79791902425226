//#region Imports

//#region Angular Imports
import { Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
import { HTTPResponse } from '@ionic-native/http';
//#endregion Ionic Imports

//#region Library Imports
import { timer } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { JuspayService } from '@App/shared/providers/juspay/juspay.service';
//#endregion Providers Imports

//#region Other Imports
import { INVALID_UPI } from '@Globals/constants/listing/constants';
import { MODAL_IDS,PG_WISE_JUSPAY_PAY_LOAD} from '@Globals/constants/payment/constants';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-upi-verification',
  templateUrl: './upi-verification.component.html',
  styleUrls: ['./upi-verification.component.scss'],
})
export class UpiVerificationComponent {
  @Input() pg: any;
  searchText = '';
  upi: string;
  error = {
    showError: false,
    errorMessage: INVALID_UPI
  };

  constructor(
    private modalCtrl: ModalController,
    private juspayService: JuspayService
  ) { }

  closeModal() {
    this.modalCtrl.dismiss(null, '', MODAL_IDS.PAYMENT_TYPE);
  }

  verifyAndPayUpi() {
    this.juspayService.upiVerify(this.upi).subscribe((success: HTTPResponse) => {
      if (success.data.data && success.data.data.success) {
        const fillData = PG_WISE_JUSPAY_PAY_LOAD[this.pg.value].payload;
        fillData.custVpa = this.upi;
        PG_WISE_JUSPAY_PAY_LOAD[this.pg.value].payload = fillData;
        PG_WISE_JUSPAY_PAY_LOAD[this.pg.value].webPayload.upi_vpa = this.upi;
        this.modalCtrl.dismiss(this.pg.value, '', MODAL_IDS.PAYMENT_TYPE);
      } else {
        this.error.showError = true;
        timer(3000).subscribe(() => {
          this.error.showError = false;
        });
      }
    });
  }
}
