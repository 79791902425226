//#region Imports

//#region Angular Imports
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { ImgOnDemandModule } from '@Shared/img-on-demand/img-on-demand.module';
import { ProductCounterModule } from '@Shared/product-listing/product-counter/product-counter.module';
import { LowBalanceModule } from '@Shared/payment/low-balance/low-balance.module';
import { ScheduleProductModule } from '@Shared/product-listing/schedule-product/schedule-product.module';
import { InviteModule } from '@Features/payment/invite/invite.module';
import { JourneyModule } from '@Shared/journey/journey.module';
import { GreenBagRemovalModule } from '@Shared/green-bag-removal/green-bag-removal.module';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { BottomSheetService } from '@Globals/providers/bottom-sheet.service';
import { ProductListManagerService } from '@Features/product-list/providers/product-list-manager.service';
import { CartService } from '@Features/product-list/providers/cart.service';
import { MembershipService } from '@Pages/membership/providers/membership.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

const annotations = {
    imports: [
        CommonModule, FormsModule, IonicModule, 
        ScrollingModule, ImgOnDemandModule, ProductCounterModule, 
        LowBalanceModule, ScheduleProductModule, InviteModule, JourneyModule,
        GreenBagRemovalModule
    ],
    exports: [],
    providers: [ProductListManagerService, CartService, BottomSheetService,MembershipService]
};
export default annotations;