<ion-list lines="none" [ngStyle]="{ 'min-height': minHeight }">
    <ion-row class="ion-item-row" [ngClass]="product._classes" [style.background]="product._hgColor"
        *ngFor="let product of productsAsPerDisplayLimit; trackBy: getProductId; let productIndex = index">
        <ng-container *ngIf="product._visible">
            <ng-container *ngFor="let collection of collections" >
                <div class="full-width" [ngSwitch]="collection.displayPosition">
                    <div *ngSwitchCase="productIndex.toString()">
                        <wgt-collection inViewport
                        [inViewportOptions]="{ threshold: [0, 0.65], partial: true }"
                        (inViewportAction)="onIntersectionCollection($event, collection)" [eventSource]="eventData?.SourceKeyword || eventSourceProductListing" [data]="collection"></wgt-collection>
                    </div>
                    <div *ngSwitchCase="collectionPositionsBeforeOOS">
                        <wgt-collection inViewport
                        [inViewportOptions]="{ threshold: [0, 0.65], partial: true }"
                        (inViewportAction)="onIntersectionCollection($event, collection)" *ngIf="productIndex === OosProductStartingIndex" [eventSource]="eventData?.SourceKeyword || eventSourceProductListing" [data]="collection">
                        </wgt-collection>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngFor="let sponseredProduct of sponseredProductList">
                <mb-pl-single-brand 
                    *ngIf="sponseredProduct.displayPosition === productIndex"
                    [brandData]="sponseredProduct"
                    [showNew]="false"
                    eventSource="Single brand">
                </mb-pl-single-brand>
            </ng-container>
            <ng-container *ngIf="product._bundleView; else bundleProduct;">
                <ion-item class="item-out-of-stock-outer" [ngClass]="product._itemClasses" inViewport
                    [inViewportOptions]="{ threshold: [0, 0.65], partial: true }"
                    (inViewportAction)="onIntersectionProduct($event, product)">
                    <div class="list-item-inner">
                        <div (click)="productGoToCalendar(product, productIndex)" class="repeat-button-wrap full-width ion-text-right">
                            <ion-button *ngIf="indexToShowRecurringCoachOn === productIndex" size="small" fill="outline"
                                color="primary" class="repeat-button no-ripple-effect">
                                Put it on Subscription?
                            </ion-button>
                            <div *ngIf="product._id && (product._id === productIdToShowCoachmark)" class="coach-mark-filled">
                                {{plmService.recRecommendationObject[productIdToShowCoachmark].recTextPLP}}
                            </div>
                        </div>

                        <div *ngIf="!product.preorderedDate; else preOrdered" class="pre-order-header" (click)="openProductsDetailModal(product, productIndex)">
                            <ion-text padding-vertical-extra-small class="pre-order-status ion-text-uppercase" [hidden]="!product.isStartDateEndDateSame">
                                <small><b>Delivery on {{ product.startDate | amDateFormat: 'Do MMM\'YY' }}</b></small>
                            </ion-text>
                            <div class="pre-order-discription description">
                                <h6 class="ion-text-uppercase f-w-b" [hidden]="!product.source && !product.location">
                                    <small [hidden]="!product.source">
                                        <ion-icon color="dark" class="icon-route icon-sm icons-property" ></ion-icon>
                                        {{ product._source }}
                                    </small>
                                    <small [hidden]="!product.location">
                                        <ion-icon color="dark" class="icon-location icon-sm icons-property" ></ion-icon>
                                        {{ product.location }}
                                    </small>
                                </h6>
                                <h1>{{ product._nm }}</h1>
                            </div>
                        </div>

                        <ng-template #preOrdered>
                            <div (click)="openProductsDetailModal(product, productIndex)" class="pre-delivery-status ion-padding">
                                <ion-thumbnail slot="start" class="ion-margin-end"
                                    *ngIf="isProductImageRequired && componentType !== ProductListType.Product_Details">
                                    <app-img-on-demand [componentType]="PLPTypeImageOnDemand"
                                        [actualImgSrc]="product._imgSrc" 
                                        [defaultImgSrc]="'assets/img/default-product.svg'" 
                                        alt_image="img_product_{{product._imgSrc}}"
                                        [imageName]="product._img"
                                        [productId]="product._id"
                                        [fileStorageDirectoryName]="plmService.fileStorageDirectories.PRODUCT">
                                    </app-img-on-demand>
                                </ion-thumbnail>
                                <div class="pre-del-header ion-text-uppercase">
                                    <small>Your Pre-Order for</small>
                                    <h3 class="ion-text-capitalize">{{ product._name }}</h3>
                                    <small>{{ product._wgt}}</small>
                                    <small margin-top-small class="col-dark">Delivering on {{ product.preorderedDate | amDateFormat: 'Do MMM\'YY' }}</small>
                                </div>
                            </div>
                        </ng-template>

                        <div class="farm-location description">
                            <h6 class="ion-text-uppercase f-w-b farm-cont vod-wrap" [hidden]="product._flHide">
                                <ng-container *ngIf="product?.offerImg" >
                                <img alt={{product.title}} class="start-icon" [src]="product._offerImage" />
                                <small>{{ product.title }}</small>
                                <img alt={{product.title}} class="end-icon" [src]="product._offerImage" />
                                </ng-container>
                            </h6>
                            <h6 class="ion-text-uppercase f-w-b farm-cont" [hidden]="product._fsHide">
                                <small margin-bottom-smallest [hidden]="!product.source">
                                    <ion-icon color="dark" class="icon-route icon-sm icons-property"></ion-icon>
                                    {{product._source}}
                                </small>
                                <small [hidden]="!product.location">
                                    <ion-icon color="dark" class="icon-location icon-sm icons-property"></ion-icon>
                                    {{ product.location }}
                                </small>
                            </h6>
                        </div>

                        <div *ngIf="!product.preorderedDate" class="list-item-content">
                            <ion-thumbnail slot="start" class="ion-margin-end has-new-launch-sticker"
                                (click)="openProductsDetailModalForNonCancelled(product, productIndex)" 
                                *ngIf="product._showThumbnail">
                                <ng-container *ngIf="product._offerUndisclosed; else imgOnDemand">
                                    <div class="gift-icon-wrap">
                                        <img alt="offer-gifts" class="has-gift-icon" src="assets/img/offer-gits.svg" />
                                    </div>
                                </ng-container>
                                <ng-template #imgOnDemand>
                                    <app-img-on-demand 
                                        [componentType]="PLPTypeImageOnDemand"
                                        [actualImgSrc]="product._imgSrc"
                                        alt_image="img_product_{{product._imgSrc}}"
                                        [defaultImgSrc]="'assets/img/default-product.svg'"
                                        [showDefaultImage]="product._showDefaultImg"
                                        [showDefaultShimmer]="product._showShimmer"
                                        [imageName]="product._img"
                                        [productId]="product._id"
                                        [fileStorageDirectoryName]="plmService.fileStorageDirectories.PRODUCT">
                                    </app-img-on-demand>
                                </ng-template>
                                <div *ngIf="product.sticker; else stickerNew" [innerHTML]="(dataService.firebaseMasterData?.stickers?.[product.sticker]?.['small'] || '') | safeHtml">
                                </div>
                                <ng-template #stickerNew>
                                    <div *ngIf="product._isNew" [innerHTML]="(dataService.firebaseMasterData?.stickers?.['new']?.['small'] || '') | safeHtml">
                                    </div>
                                </ng-template>
                            </ion-thumbnail>
                            <div class="list-item-middle-sec">
                                <ion-label [ngClass]="{'overflow-unset':componentType === ProductListType.Product_Details}" class="ion-no-margin description" padding-right-small (click)="openProductsDetailModalForNonCancelled(product, productIndex)">
                                   <div #getHeight>
                                    <h2 text-ellipsis-ml>{{product._offerUndisclosed ? 'Surprise gift' : product._nm}}</h2>
                                    <div (click)="componentType !== ProductListType.Product_Variant_List && product?._newVariantProducts?.length && (openProductsVariantModal(product, productIndex) && $event.stopPropagation())" class="variants-wrap" [ngClass]="{'no-variants': !(product?._newVariantProducts?.length)}">
                                        <div><span class="text-holder">{{product._wgt}}</span></div>
                                        <ion-icon *ngIf="product?._newVariantProducts?.length" class="icons-property icon-down-arrow"></ion-icon>
                                    </div>
                                    <p [hidden]="product._oos || !product.offerText" class="discount-offer ion-align-items-center">
                                        <ion-icon class="margin-right-smallest" name="star"></ion-icon>
                                        <small class="ion-text-uppercase"><strong>{{product._offerText}}</strong></small>
                                    </p>
                                    </div>
                                    <p *ngIf="product._show; else OutOfStock">
                                        <ng-container *ngIf="!product._hasVariants;else priceForVariant">
                                            <span class="text-dark mr-0 mrp" *ngIf="product._showMrp">M.R.P: </span>
                                            <span class="sale-price icon-inr">{{product._sp}}</span>
                                            <ng-container *ngIf="product._svp">
                                                <span class="text-muted mr-0 mrp" *ngIf="componentType === ProductListType.Product_Details">M.R.P:</span> 
                                                <span class="actual-price icon-inr">{{product._mrp}}</span>
                                                <span *ngIf="!product.offerText" class="discount icon-inr">{{product._off | number: '1.2-2'}} OFF</span>
                                            </ng-container>
                                            <span class="text-muted" *ngIf="componentType === ProductListType.Product_Details">(Inclusive of all taxes)</span>
                                            <p class="icon-inr tex-size-sm color-dark-300 p-mrp" *ngIf="product?.pricePerUnit">{{product.pricePerUnit }}</p>
                                        </ng-container>
                                        <ng-template #priceForVariant>
                                            <span class="text-dark mr-0 mrp" *ngIf="isPDP &&  
                                                +(product.variants[product.selectedVariantIndex ]?.sp || product.variants[product.selectedVariantIndex ]?.price)  ===  
                                                +(product.variants[ product.selectedVariantIndex]?.mrp_per_unit || product.variants[product.selectedVariantIndex ]?.mrp)">M.R.P: </span>
                                            <span class="sale-price icon-inr ">{{ product.variants[product.selectedVariantIndex]?.price_per_unit || 
                                                (product.variants[ product.selectedVariantIndex ]?.hasOwnProperty('sp') ? product.variants[ product.selectedVariantIndex ]?.sp : 
                                                product.variants[ product.selectedVariantIndex ]?.price) }}</span>
                                            <ng-container *ngIf="(product.variants[product.selectedVariantIndex]?.saving_per_unit && product.variants[product.selectedVariantIndex]?.saving_per_unit !== 0) ||
                                                    (product.variants[product.selectedVariantIndex]?.hasOwnProperty('sp') && product.variants[product.selectedVariantIndex]?.mrp - product.variants[product.selectedVariantIndex]?.sp > 0) ||
                                                    (product.variants[product.selectedVariantIndex]?.hasOwnProperty('price') && product.variants[product.selectedVariantIndex]?.mrp - product.variants[product.selectedVariantIndex]?.price > 0)">
                                                <span class="text-muted mr-0 mrp" *ngIf="isPDP">M.R.P:</span>
                                                <span class="actual-price icon-inr">{{ product.variants[ product.selectedVariantIndex ]?.mrp_per_unit || product.variants[ product.selectedVariantIndex ]?.mrp }}</span>
                                                <span *ngIf="!product.offerText"
                                                    class="discount icon-inr">{{ (product.variants[ product.selectedVariantIndex ]?.saving_per_unit ||
                                                    product.variants[ product.selectedVariantIndex ]?.mrp - product.variants[ product.selectedVariantIndex ]?.sp ||
                                                    product.variants[ product.selectedVariantIndex ]?.mrp - product.variants[ product.selectedVariantIndex ]?.price) | number: '1.2-2' }} OFF</span>
                                            </ng-container>
                                            <span class="text-muted" *ngIf="isPDP">(Inclusive of all taxes)</span>
                                            <p class="icon-inr tex-size-sm color-dark-300 p-mrp" *ngIf="product.variants[ product.selectedVariantIndex ]?.pricePerUnit">{{product.variants[ product.selectedVariantIndex ].pricePerUnit }}</p>
                                        </ng-template>
                                    </p>
                                    
                                    <p style="width:100px" *ngIf="componentType === ProductListType.Product_Details && (eventData?.collectionDisplayType === allCollectionDisplayType.DealsOfTheDayCollection)">
                                        <ng-container *ngIf="((product.mrp - product.sp) > 0) || product?.dodPrice">
                                            <span class="bp-tag"><span>Best Price:</span> *{{ product?.dodPrice ? product?.dodPrice : ((product?.mrp - product?.sp)/product?.mrp)*100 | number:'1.1-2'}}% {{product?.dodPrice ? 'lesser than avg online price' : (product.mrp - product.sp) > 0 ? 'discount on this product' : ''}}</span>
                                        </ng-container>
                                    </p>
    
                                    <p *ngIf="!product._oos && (componentType !== ProductListType.Cancelled_Products_On_Cart)" class="subscription">
                                        <ng-container *ngIf="product._rsss">
                                            <ng-container *ngIf="!restOfTheMonth;else restMonthView">
                                                <mb-rc-subsave-strip [componentType]="componentType" [product]="product" [recurringProductDetails]="plmService.recurringService.recurringCampaignProducts?.recurringCampaignMapper?.products?.[product.product_id]" [stripType]="productListing"></mb-rc-subsave-strip>
                                            </ng-container>
                                            <ng-template #restMonthView>
                                                <mb-rom-rc-strip [restOfTheMonthPercentProductCount]="recCampaignSubsribeToSave" [restOfTheMonthBasketDate]="basketDate"
                                                    [stripType]="productListing" [productList]="productsAsPerDisplayLimit" [recurringProductDetails]="plmService.recurringService.recurringCampaignProducts?.recurringCampaignMapper?.products?.[product.product_id]">
                                                </mb-rom-rc-strip>
                                            </ng-template>
                                        </ng-container>
                                    </p>

                                    <div *ngIf="product._offerAvailable" class="offer-available">
                                        <img alt="Offers Available" src="assets/img/current-offer.png" />
                                        <p>Offers Available</p>
                                    </div>

                                    <div *ngIf="product._hasVariants" (click)="$event.stopPropagation()" class="chips-holder chips-holder-type-2 buttons-group">
                                        <div class="varient-btn" *ngFor="let variant of product.variants; let variantIndex=index;">
                                            <ion-button (click)="trackVariantSwitch(product, variantIndex);"
                                                class="ion-text-uppercase" color="primary" size="small" fill="outline"
                                                [ngClass]="{ selected: product.variants[product.selectedVariantIndex].id === variant.id }"
                                                [disabled]="variant.oos || variant.forcedOos">
                                                <ion-text color="dark">
                                                    {{ variant.product_weight || variant.wgt || variant.weight }}
                                                </ion-text>
                                            </ion-button>
                                            <div *ngIf="authService.userData?.tomorrowBasket?.[variant.id]" class="added-btn ion-text-uppercase ion-text-center">Added</div>
                                        </div>
                                    </div>

                                    <ng-template #OutOfStock>
                                        <p *ngIf="(componentType !== ProductListType.Cancelled_Products_On_Cart); else unavailableView"
                                            [ngClass]="product.UIMessage ? 'refillType' : 'qty'">
                                            {{product.UIMessage || 'Out OF Stock'}}</p>
                                        <ng-template #unavailableView>
                                            <p class="qty item-unavailable f-w-b ion-text-uppercase">Currently Unavailable</p>
                                        </ng-template>
                                    </ng-template>
                                </ion-label>

                                <ion-button [disabled]="product._oos" (click)="openScheduleModal(product, productIndex)" margin-top fill="outline" color="primary" size="small" class="btn-pre">
                                    pre-order
                                </ion-button>
                            </div>

                            <div *ngIf="componentType === ProductListType.Account_History_Details" class="ion-no-margin list-btn" slot="end">
                                <div class="item-status-img">
                                    <img alt="refund cancel" *ngIf="showCancelIcon; else showRefundIcon" height="35" src="assets/img/img-cancel.png" />
                                    <ng-template #showRefundIcon>
                                        <img alt="product refund" *ngIf="product.is_refund" height="43" src="assets/img/img-refunded.png" />
                                    </ng-template>
                                </div>
                                <div class="item-unit">
                                    <h4 *ngIf="!!(product.quantity)" class="ion-text-uppercase">
                                        {{ product.quantity }} {{product.quantity === 1 ? 'unit': 'units'}}
                                    </h4>
                                </div>
                            </div>

                            <div [ngStyle]="{ height:componentType === ProductListType.Product_Details ? (getHeight.offsetHeight+2)+'px' : ''}" slot="end" *ngIf="componentType !== ProductListType.Account_History_Details" class=" ion-no-margin list-btn" 
                                [ngClass]="{'btn-dark': product._btnDark, 'has-sample-product': product.is_sample_offer, 'ion-align-self-start':componentType === ProductListType.Product_Details}">
                                <ion-badge *ngIf="product.is_sample_offer" class="ion-text-uppercase" color="success">Free</ion-badge>
                                <ion-button [ngClass]="{ active: product._isActive }" *ngIf="product._showCalendar" size="small" fill="clear" [disabled]="!masterDataService.isValidAddress"
                                    color="primary" (click)="productGoToCalendar(product, productIndex)" class="ion-no-padding recurring-btn">
                                    <div class="subscription-wrap" [ngClass]="{'disable-btn': masterDataService.masterData?.user?.wf|| !masterDataService.isValidAddress, 'percentage-sticker-wrap': product._rsss}">
                                        <ion-icon class="icon-calendar ds-icon"></ion-icon>
                                        <span *ngIf="product._rsss" class="percentage-sticker"></span>    
                                    </div>
                                </ion-button>
                                <div padding-horizontal-small padding-bottom-extra-small class="remove-item"
                                    (click)="removeCancelProduct(product.product_id); $event.stopPropagation()"
                                    *ngIf="(componentType === ProductListType.Cancelled_Products_On_Cart)">
                                    <ion-icon slot="icon-only" class="icon-close" color="dark"></ion-icon>
                                </div>

                                <div class="item-counter-btn">
                                    <ion-button size="small" [disabled]="masterDataService.masterData?.user?.wf|| !masterDataService.isValidAddress" *ngIf="showRecurringButton; else otherListItem" (click)="navigateToRecurring(product, productIndex)" 
                                        fill="outline" color="primary" class="mw-small">{{recurringButtonText}}</ion-button>
                                    <ng-template #otherListItem>
                                        <ng-container *ngIf="showAddToBasketButton">
                                            <mb-product-counter [isPlusOneDayDelivery]="product._isPlusOneDayDelivery" *ngIf="product._showCounter; else oos"
                                                [productId]="product._idVar"
                                                [disableCounterForOfferProducts]="offerProductDisable"
                                                [counter]="(componentType === ProductListType.Your_Cart) || (basketIndex && basketIndex > 0) ? (!product._hasVariants ? product.quantity : product.variants[product.selectedVariantIndex].quantity) : (authService.userData?.tomorrowBasket?.[!product._hasVariants ? product._id : product.variants[product.selectedVariantIndex]?.id])" 
                                                (counterChange)="counterChange($event, product, productIndex)">
                                            </mb-product-counter>
                                        </ng-container>

                                        <ng-template #oos>
                                            <ion-button *ngIf="showSimilarButton; else requestButton" size="small" (click)="productShowHideRecommended(product, productIndex)" fill="outline" color="primary" class="mw-small btn-see-hide-similar">
                                                {{ product._id === recommendedProductList.product.id ? 'Hide' : 'See' }} Similar
                                            </ion-button>
                                            <ng-template #requestButton>
                                                <ion-button size="small" [disabled]="masterDataService.masterData?.user?.wf|| !masterDataService.isValidAddress" (click)="requestProduct(product)" fill="outline" color="primary" class="mw-small request-button">
                                                    {{ plmService.refillTypesMetaData[product.refillType]?.CTAText || 'Request' }}
                                                </ion-button>
                                            </ng-template>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>

                </ion-item>
                <mb-similar-products *ngIf="product._id === recommendedProductList?.product?.product_id && (recommendedProductList.data.length > 0 || isRequestDivAllowed)" 
                    [metaData]="{
                        'sliderFromPage': sliderFromPage,
                        'products': recommendedProductList,
                        'eventSource': eventSource,
                        'recommendationComponentType': recommendationComponentType,
                        'isRequestDivAllowed': isRequestDivAllowed,
                        'parentProduct': product
                    }" (requestProduct)="requestProduct($event.product, $event.isSourceSeeSimilar)">
                </mb-similar-products>
            </ng-container>

            <ng-template #bundleProduct>
                <ion-item color="light" class="ion-card-image-type-3" (click)="openProductsDetailModal(product, productIndex)">
                    <mb-bundle-products [product]="product" [eventData]="eventData" [eventSource]="eventSource"
                        [basketIndex]="basketIndex"
                        [productIndex]="productIndex" [componentType]="componentType"
                        [recommendedProductList]="recommendedProductList"
                        (counterChangeEvent)="counterChangeEvent($event, product, productIndex)"
                        (showOrHideRecommendedProducts)="productShowHideRecommended($event.product, $event.showHideRelated)"
                        (requestProduct)="requestProduct($event.product)"
                        (removeCancelProduct)="removeCancelProduct($event.productId)"></mb-bundle-products>
                </ion-item>

                <mb-similar-products *ngIf="product._id === recommendedProductList.product.id && (recommendedProductList.data.length > 0 || isRequestDivAllowed)" 
                    [metaData]="{
                        'sliderFromPage': sliderFromPage,
                        'products': recommendedProductList,
                        'eventSource': eventSource,
                        'recommendationComponentType': recommendationComponentType,
                        'isRequestDivAllowed': isRequestDivAllowed,
                        'parentProduct': product
                    }" (requestProduct)="requestProduct($event.product, $event.isSourceSeeSimilar)">
                </mb-similar-products>
            </ng-template>
        </ng-container>
    </ion-row>
</ion-list>

<mbs-loader applyClass="has-min-height" class="w-100" [hideLoader]="isPageLoaded" *ngIf="useClientPagination && products?.length > 4 && showLoader"></mbs-loader>
<ion-infinite-scroll *ngIf="useClientPagination && products?.length !== 0" threshold="100px" (ionInfinite)="loadData($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
</ion-infinite-scroll>