import { Injectable } from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import { ANDROID, APP_PERMISSION, CACHED_IMAGE_MAP, FILE_STORAGE_DIRECTORIES, GENERATE_CONTENT_FOR_ALERT_LAYER, IOS } from '../../constants/constants';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { STORAGE_PERMISSION_CONFIRMATION, STORAGE_PERMISSION_DENIED_ALWAYS } from '../../../listing/constants/listing-constants';
import { AlertService } from '../alert/alert.service';
import { BehaviorSubject, noop, Subject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { Utilities } from '../../classes/utility/utilities';
import { DataService } from '../data/data.service';
import { environment } from '@Env/environment';
declare var IRoot: any;
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  directoriesCreated = {};
  imageStorage = {};
  deviceOSVersion;
  isStorageAvailable = false;
  appSecurityCompromisedSub: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor (private file: File, private diagnostic: Diagnostic,
    private alertService: AlertService, private platform: Platform,
    private dataService: DataService,
    private domSanitizer: DomSanitizer, private fileTransfer: FileTransfer) {
  }

  setCachedImageMapInLocalStorage() {
    localStorage.setItem(CACHED_IMAGE_MAP, JSON.stringify(this.imageStorage));
  }

  getCachedImageMapFromLocalStorage() {
    const imageData = Utilities.parseJSONString(localStorage.getItem(CACHED_IMAGE_MAP));
    if (imageData) {
      this.imageStorage = imageData;
    }
  }

  setDirectoryExistence(directoryName) {
    this.directoriesCreated[ directoryName ] = true;
    localStorage.setItem(directoryName, 'true');
  }

  createDirectory(directoryName: string) {
    this.file.createDir(this.file.dataDirectory, directoryName, false).then(() => {
      this.setDirectoryExistence(directoryName);
    }).catch(() => {
      this.directoriesCreated[ directoryName ] = false;
    });
  }

  checkDirectoryIfExistElseCreate(directoryName: string) {
    this.file.checkDir(this.file.dataDirectory, directoryName).then(() => {
      this.setDirectoryExistence(directoryName);
    }).catch(() => {
      this.createDirectory(directoryName);
    });
  }

  checkStorageDirectoriesIfExistElseCreate() {
    this.getCachedImageMapFromLocalStorage();
    for (const directory in FILE_STORAGE_DIRECTORIES) {
      if (FILE_STORAGE_DIRECTORIES.hasOwnProperty(directory)) {
        if (localStorage.getItem(FILE_STORAGE_DIRECTORIES[ directory ])) {
          this.directoriesCreated[ FILE_STORAGE_DIRECTORIES[ directory ] ] = true;
        } else {
          this.checkDirectoryIfExistElseCreate(FILE_STORAGE_DIRECTORIES[ directory ]);
        }
      }
    }
  }

  checkIfAppSecurityCompromised () {
    if(Utilities.isApp() && environment.production) {
      this.detectFrida();
      this.checkRootDetectionWithIRoot();
    }
  }
  
  detectFrida() {
    const indicators = ["frida", "Frida", "FRIDA"];
    for (const indicator of indicators) {
      if (window[indicator] !== undefined) {
        this.appSecurityCompromisedSub.next(true);
      }
    }
  }

  checkRootDetectionWithIRoot () {
    setTimeout(() => {
      if (typeof (IRoot) !== 'undefined' && IRoot) {
        IRoot.isRooted((data) => {
          if (data && data == 1) {
            this.appSecurityCompromisedSub.next(true);
          }
        });
      }
    }, 500);
  }

  getDeviceOSVersion() {
    window.cordova.plugins.AppReview.getDeviceOSVersion().then((deviceInfo) => {
      this.deviceOSVersion = deviceInfo;
      this.deviceOSVersion.versionCode = parseInt(this.deviceOSVersion.version);
      if(this.deviceOSVersion.versionCode >= 13) {
        this.requestNotificationPermission();
      }
      }, (e) => {
    });
  }

  checkFileStoragePermission(isDownloadStatement?) {
    if (this.platform.is(ANDROID)) {
      if(this.deviceOSVersion.versionCode >= 13) {
        this.diagnostic.getPermissionsAuthorizationStatus([APP_PERMISSION.READ_MEDIA_IMAGES, APP_PERMISSION.READ_MEDIA_VIDEO]).then((statuses) => {
            let permissionNotGranted = [];
            Object.entries(statuses).forEach(([key, value], index) => {
              if(value !== this.diagnostic.permissionStatus.GRANTED) {
                permissionNotGranted.push(key);
              }
            });
            if(permissionNotGranted.length) {
                this.diagnostic.requestRuntimePermissions(permissionNotGranted).then((statusesX) => {
                  let requestPermissionNotGranted = [];
                  Object.entries(statusesX).forEach(([key, value], index) => {
                    if(value !== this.diagnostic.permissionStatus.GRANTED) {
                      requestPermissionNotGranted.push(key);
                    }
                  });
                  if(!requestPermissionNotGranted.length) {
                    this.isStorageAvailable = true;
                    this.checkStorageDirectoriesIfExistElseCreate();
                  } else {
                    if (isDownloadStatement) {
                      this.onExternalStorageDeniedAlways();
                    }
                  }
                }, (e) => {
              });
            } else {
              this.isStorageAvailable = true;
              this.checkStorageDirectoriesIfExistElseCreate();
            }
          }, (e) => {
        });
      } else {
        this.diagnostic.getExternalStorageAuthorizationStatus().then((permission) => {
          if (permission !== this.diagnostic.permissionStatus.GRANTED) {
            this.diagnostic.requestExternalStorageAuthorization().then((per) => {
              if ((per === this.diagnostic.permissionStatus.GRANTED) || (per === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE)) {
                this.isStorageAvailable = true;
                this.checkStorageDirectoriesIfExistElseCreate();
              } else if ((per === this.diagnostic.permissionStatus.DENIED) || (per === this.diagnostic.permissionStatus.DENIED_ONCE)) {
                this.confirmExternalStorageRejection();
              } else if (per === this.diagnostic.permissionStatus.DENIED_ALWAYS) {
                if (isDownloadStatement) {
                  this.onExternalStorageDeniedAlways();
                }
              }
            }, () => {
            });
          } else {
            this.isStorageAvailable = true;
            this.checkStorageDirectoriesIfExistElseCreate();
          }
        }, () => {});
      }
    } else {
      this.isStorageAvailable = true;
      this.checkStorageDirectoriesIfExistElseCreate();
    }
  }

  requestNotificationPermission(callback?) {
    this.diagnostic.getPermissionAuthorizationStatus(APP_PERMISSION.POST_NOTIFICATIONS).then((status) => {
        if(status.value !== this.diagnostic.permissionStatus.GRANTED) {
          this.diagnostic.requestRuntimePermission(APP_PERMISSION.POST_NOTIFICATIONS).then((statusesX) => { 
              if(statusesX.value === this.diagnostic.permissionStatus.GRANTED) {
                callback && callback(true);
              }
            }, (e) => {
          });
        }
        callback && callback(true);
      }, (e) => {
    });
  }

  onExternalStorageDeniedAlways() {
    STORAGE_PERMISSION_DENIED_ALWAYS.BUTTONS.CONFIRM.handler = noop();
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(STORAGE_PERMISSION_DENIED_ALWAYS.META_DATA),
      [
        STORAGE_PERMISSION_DENIED_ALWAYS.BUTTONS.CONFIRM
      ],
      STORAGE_PERMISSION_DENIED_ALWAYS.CSS_CLASS,
      STORAGE_PERMISSION_DENIED_ALWAYS.BACKDROP_DISMISS
    );
  }

  confirmExternalStorageRejection() {
    STORAGE_PERMISSION_CONFIRMATION.BUTTONS.CONFIRM.handler = () => {
      this.checkFileStoragePermission();
    };
    STORAGE_PERMISSION_CONFIRMATION.BUTTONS.CANCEL.handler = noop();
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(STORAGE_PERMISSION_CONFIRMATION.META_DATA),
      [
        STORAGE_PERMISSION_CONFIRMATION.BUTTONS.CANCEL,
        STORAGE_PERMISSION_CONFIRMATION.BUTTONS.CONFIRM
      ],
      STORAGE_PERMISSION_CONFIRMATION.CSS_CLASS,
      STORAGE_PERMISSION_CONFIRMATION.BACKDROP_DISMISS
    );
  }

  downloadImage(scope, wantToConvert = true) {
    const fileTransfer: FileTransferObject = this.fileTransfer.create();
    fileTransfer.download(scope.actualImgSrc, `${this.file.dataDirectory}/${scope.fileStorageDirectoryName}/` + scope.imageName)
      .then(_ => {
        this.convertFileSrcUsingIonicWebView(scope, wantToConvert);
      }).catch(_ => {
        if (wantToConvert) {
          scope.imageSrc = scope.actualImgSrc;
          scope.changeDetectorRef && scope.changeDetectorRef.detectChanges();
        }
      });
  }

  convertFileSrcUsingIonicWebView(scope, wantToConvert = true) {
    let src = (window as any).Ionic.WebView.convertFileSrc(`${this.file.dataDirectory}${scope.fileStorageDirectoryName}/${scope.imageName}`);
    this.imageStorage[ scope.imageName ] = src;
    if (!scope.useImageOnDemand && this.platform.is(IOS)) {
      src = this.domSanitizer.bypassSecurityTrustResourceUrl(src);
    }
    if (wantToConvert) {
      scope.imageSrc = src;
      scope.changeDetectorRef && scope.changeDetectorRef.detectChanges();
    }
  }

  async checkAndRemoveRedundantDirectoryFromCache(directoryName) {
    this.file.checkDir(this.file.dataDirectory, directoryName).then(() => {
      this.file.removeDir(this.file.dataDirectory, directoryName).then((success) => {
      }).catch((error) => {
      });
    }).catch((error) => {
    });
  }

  getFreeSpace() {
    return this.file.getFreeDiskSpace();
  }

  async clearDeviceStorage() {
    for (const directory in FILE_STORAGE_DIRECTORIES) {
      await this.file.listDir(this.file.dataDirectory, FILE_STORAGE_DIRECTORIES[ directory ]).then((result) => {
        for (let file of result) {
          if (file.isFile === true) {
            this.file.removeFile(this.file.dataDirectory + '/' + FILE_STORAGE_DIRECTORIES[ directory ] + '/', file.name).then(res => {
            })
          }
        }
      });
    }
    localStorage.removeItem(CACHED_IMAGE_MAP);
    this.imageStorage = {};
  }
}
