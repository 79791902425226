//#region Imports

//#region Angular Imports
import { Component, OnInit } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-upcoming-delivery-info',
  templateUrl: './upcoming-delivery-info.component.html',
  styleUrls: ['./upcoming-delivery-info.component.scss'],
})
export class UpcomingDeliveryInfoComponent implements OnInit {

  constructor(
    public masterDataService: MasterDataService,
    private modalCtrl: ModalController,
    public dataService: DataService
    ) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }

}
