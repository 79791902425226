import { Injectable, NgZone } from '@angular/core';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { Utilities } from '../../classes/utility/utilities';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { CleverTap } from '@ionic-native/clevertap/ngx';
import { EVENT_NAMES } from 'src/app/listing/constants/listing-constants';
import { EventTrackingPlugins } from '../../constants/enums';
import { noop } from 'rxjs';
import firebase from 'firebase/app';
import { LoggingService } from '../logging/logging.service';
import { environment } from '@Env/environment';
import { AuthService } from '@Globals/providers/auth.service';
import { EVENT_SOURCES } from '@App/core/constants/core-constants';
import { getAnalytics, logEvent } from 'firebase/analytics';
import moment from 'moment';
declare var clevertap: any;
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  uuid: string;
  // replaceAllWhiteSpaceAndHyphenWithUnderscoreIfAny with Underscore for ANALYTICS & FIRE-BASE, it will remove all the spaces and `-` from every where in input string
  replaceAllWhiteSpaceAndHyphenWithUnderscoreIfAny = inputString => {
    return typeof inputString === 'string'
      ? inputString.replace(/[\s?-]+/g, '_')
      : inputString;
  };
  // updateEventDataForSecondaryEvents will remove all the spaces from every where in object for ANALYTICS & FIRE-BASE and will return a new object
  updateEventDataForFireBaseAndAnalytics = (inputData: object) => {
    const outputData = {};
    for (const key in inputData) {
      if (inputData.hasOwnProperty(key)) {
        outputData[
          this.replaceAllWhiteSpaceAndHyphenWithUnderscoreIfAny(key)
        ] = this.replaceAllWhiteSpaceAndHyphenWithUnderscoreIfAny(
          inputData[key]
        );
      }
    }
    return outputData;
  };

  constructor(
    private appsflyer: Appsflyer,
    private firebaseX: FirebaseX,
    private cleverTap: CleverTap,
    private loggingService: LoggingService,
    private authService: AuthService,
    private ngZone: NgZone
  ) {
  }

  trackEvents(
    eventName: string,
    eventData: any,
    isDeviceIdRequired?: boolean,
    whichEventPlugin = EventTrackingPlugins.All
  ) {
    try {
      eventData.timestamp = moment().format('DDMMYYYYHHmmss');
      eventData.appLaunchId = window.appLaunchId;
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          if (!eventName) {
            return;
          }
          const eventNameWithUnderscore = this.replaceAllWhiteSpaceAndHyphenWithUnderscoreIfAny(
            eventName
          );
          const eventDataWithUnderscore = this.updateEventDataForFireBaseAndAnalytics(
            eventData
          );

          this.loggingService.logMessage('Event : '+ eventName, eventData );
          eventData.codePushVersion = environment.codePushVersion;
          if (Utilities.isApp()) {
            if (isDeviceIdRequired) {
              // @ts-ignore
              eventData.deviceId = this.uuid;
            }
            // console.log('Event', eventName, eventData);
            eventData.whichPlatform = window.cordova.platformId;
            switch (whichEventPlugin) {
              case EventTrackingPlugins.All:
                this.appsflyer.logEvent(eventName, eventData);
                this.firebaseX.logEvent(
                  eventNameWithUnderscore,
                  eventDataWithUnderscore
                ).catch(noop);
                this.cleverTap.recordEventWithNameAndProps(eventName, eventData).catch(noop);
                break;
              case EventTrackingPlugins.AppsFlyer:
                this.appsflyer.logEvent(eventName, eventData);
                break;
              case EventTrackingPlugins.Firebase:
                this.firebaseX.logEvent(
                  eventNameWithUnderscore,
                  eventDataWithUnderscore
                ).catch(noop);
                break;
              case EventTrackingPlugins.CleverTap:
                this.cleverTap.recordEventWithNameAndProps(eventName, eventData).catch(noop);
                break;
            }
          } else {
            // console.log('Event',eventName, eventData );
            this.authService.jioMartData?.isJioMartUser ? (eventData.whichPlatform = EVENT_SOURCES.JIO) : (eventData.whichPlatform = EVENT_SOURCES.WEB);
            const analytics = getAnalytics();
            const eventNameWithUnderscore = this.replaceAllWhiteSpaceAndHyphenWithUnderscoreIfAny(
              eventName
            );
            const eventDataWithUnderscore = this.updateEventDataForFireBaseAndAnalytics(
              eventData
            );
            switch (whichEventPlugin) {
              case EventTrackingPlugins.All:
                clevertap && clevertap.event.push(eventName, eventData);
                analytics && logEvent(analytics,eventNameWithUnderscore, eventDataWithUnderscore);
                
              
                break;
              case EventTrackingPlugins.Firebase:
                analytics && logEvent(analytics,eventNameWithUnderscore, eventDataWithUnderscore);
                break;
              case EventTrackingPlugins.CleverTap:
                clevertap && clevertap.event.push(eventName, eventData);
                break;
            }
          }
        });
      });
    } catch (e) {
    }
  }

  trackCategoryView(category, source, hubId = null) {
    const eventArgs = {
      'Category id': category?.id,
      'Category name': category?.name,
      Source: source,
      hub_id: hubId
    };
    this.trackEvents(EVENT_NAMES.CATEGORY_VIEWED, eventArgs);
  }

  trackSubCategoryView(subCategory, eventSource, eventArgument = {}, category?) {
    let eventArgs = {
      'Subcategory id': subCategory?.id,
      'Subcategory name': subCategory?.name,
      'Category id': category && category.id,
      Source: eventSource
    };
    eventArgs = Object.assign({}, eventArgs, eventArgument);
    this.trackEvents(EVENT_NAMES.SUBCATEGORY_VIEWED, eventArgs);
  }

  trackPLPScrolled(subCategory, eventSource, didReachedBottom, pagination) {
    const eventArgs = {
      'Subcategory id': subCategory.id,
      'Subcategory name': subCategory.name,
      didReachedBottom,
      pagination,
      Source: eventSource
    };
    this.trackEvents(EVENT_NAMES.PLP_SCROLLED, eventArgs, false, EventTrackingPlugins.Firebase);
  }


}
