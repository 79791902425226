//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { LottieModule } from 'ngx-lottie';
//#endregion Library Imports

//#region Module Imports
import { IonBottomSheetModule } from 'ion-bottom-sheet';
import { DefaultModule } from '@Lib/defaults/default.module';
import { GeolocationRoutingModule } from '@Pages/registration/geolocation/geolocation-routing.module';
import { HeaderModule } from '@MbUi/headers/two/header.module';
import { SelectCityModule } from '@Features/registration/select-city/select-city.module';
import { BottomSheetHeaderModule } from '@Shared/bottom-sheet-header/bottom-sheet-header.module';
import { SearchModule } from '@Features/search/search/search.module';
import { JioMartHeaderModule } from '@Shared/jio-mart-header/jio-mart-header.module';
//#endregion Module Imports

//#region Component Imports
import { GeolocationPage } from '@Pages/registration/geolocation/geolocation.page';
import { BottomSheetComponent } from '@Pages/registration/geolocation/components/bottom-sheet/bottom-sheet.component';
import { ConfirmSocietyPage } from '@Pages/registration/geolocation/components/confirm-society/confirm-society.page';
import { AddAddressComponent } from '@Pages/registration/geolocation/components/add-address/add-address.component';
import { AddressAddSuccessComponent } from '@Pages/registration/geolocation/components/address-add-success/address-add-success.component';
import { SelectDeliveryLocationComponent } from '@Pages/registration/geolocation/components/select-delivery-location/select-delivery-location.component';
import { SelectTowerComponent } from '@Pages/registration/geolocation/components/select-tower/select-tower.component';
//#endregion Component Imports

//#region Providers Imports
import { TextHighlightDirective } from '@Pages/registration/geolocation/directives/text-highlight.directive';
import { GeolocationService } from '../../../globals/providers/geolocation.service';
import { GeofenceAlertComponent } from './components/geofence-alert/geofence-alert.component';

//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    GeolocationRoutingModule,
    HeaderModule,
    FormsModule,
    SelectCityModule,
    BottomSheetHeaderModule,
    SearchModule,
    IonBottomSheetModule,
    JioMartHeaderModule,
    LottieModule
  ],
  exports: [GeolocationPage],
  declarations: [
    GeolocationPage,
    BottomSheetComponent,
    AddAddressComponent,
    AddressAddSuccessComponent,
    SelectDeliveryLocationComponent,
    SelectTowerComponent,
    ConfirmSocietyPage,
    TextHighlightDirective,
    GeofenceAlertComponent
  ],
  providers: [GeolocationService]
})
export class GeolocationModule extends DefaultModule { }