//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Input, Output } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { HTTPResponse } from '@ionic-native/http';
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { ShoppingCreditsServices } from 'src/app/payment/providers/shopping-credits/shopping-credits.service';
import { EventsService } from '@Globals/providers/events.service';
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { AlertService } from '@Globals/providers/alert.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, GENERATE_CONTENT_FOR_ALERT_LAYER } from '@Globals/constants/listing/constants';
import { PROMO_CODE_APPLY } from '@Globals/constants/payment/constants';
import { Utilities } from '@Globals/classes/utilities';
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-promo-code-input',
  templateUrl: './promo-code-input.component.html',
  styleUrls: ['./promo-code-input.component.scss'],
})
export class PromoCodeInputComponent extends DefaultComponent {
  @Output() errorData = new EventEmitter();
  @Input() isError: boolean = false;
  @Input() errorMessage: string;
  @Input() isModal: boolean;
  inviteCode: string;
  referStatus:number=null;
  constructor(
    private shoppingCreditsServices: ShoppingCreditsServices,
    private eventsService: EventsService,
    public dataService: DataService,
    public masterDataService: MasterDataService,
    private alertService: AlertService,
    private modalCtrl: ModalController,
  ) {
    super();
  }

  mbOnInit(): void {}
  onModalChange() {
    this.isError = false;
  }

  errorRequest(){
    this.errorData.emit({ isError: this.isError, errorMessage: this.errorMessage });
  }

  applyInviteCode(code) {
    this.eventsService.trackEvents(EVENT_NAMES.PROMO_CODE_APPLY, {
      welcome_code: code
    });
    let payload: any = {
      referCode: code,
    };
    this.shoppingCreditsServices.getReferralStatus(payload).subscribe((referResponse:HTTPResponse) => {
      if(referResponse?.data?.status){
        this.referStatus = referResponse?.data?.referStatus;
        this.eventsService.trackEvents(EVENT_NAMES.REFERRER_STATUS, {status:referResponse?.data?.referStatus});
        this.masterDataService.masterData.user && (this.masterDataService.masterData.user.referStatus = referResponse?.data?.referStatus);
        let promoPayload = {
          welcome_code: code,
        }
        if(typeof this.referStatus === 'number'){
          promoPayload['referralStatus'] = this.referStatus;
        }
        this.shoppingCreditsServices.applyInviteCode(promoPayload).subscribe((response: HTTPResponse) => {
          if (response?.data?.status) {
            if(this.masterDataService?.masterData && ('balance' in this.masterDataService.masterData)){
            this.masterDataService.masterData.balance = response?.data?.balance;
            }
            this.masterDataService.masterData.walletInfo = response?.data?.walletInfo;

            !this.masterDataService.masterData.welcome_code && (this.masterDataService.masterData['welcome_code'] = { detail: {} });
            this.masterDataService.masterData.welcome_code.detail = response.data.data.detail;
            this.dataService.runChangeDetForPromo.next(true);
            const message = JSON.parse(JSON.stringify(PROMO_CODE_APPLY));
            message.META_DATA.MESSAGE = Utilities.replaceKeysWithValues(PROMO_CODE_APPLY.META_DATA.MESSAGE, { promoCode: response.data.data.detail.code, shortDescription: response.data.data.detail.shortDescription === null ? 'Promo code applied successfully' : response.data.data.detail.shortDescription });
            this.openInviteCodeSuccessAlert(message.META_DATA);
    
            this.eventsService.trackEvents(EVENT_NAMES.PROMO_CODE_SUCCESS, {
              welcome_code: code
            });
            this.isError = false;
            if (this.isModal){
              this.modalCtrl.dismiss();
            }
          } else {
            this.isError = true;
            this.errorMessage = response.data.error_msg;
            this.errorRequest();
          }
        });
      }else{
        this.isError = true;
        this.errorMessage = referResponse?.data?.error_msg;
        this.errorRequest();
      }
    })
  }

  openInviteCodeSuccessAlert(message: object) {
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(
        message
      ),
      [
        PROMO_CODE_APPLY.BUTTONS.OKAY
      ],
      PROMO_CODE_APPLY.CSS_CLASS
    );
  }

}
