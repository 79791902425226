
const thousandSeparator = (amount: number, roundTo = 2) => {
    // using regex for thousand separator bcoz it is faster than toLocaleString() && Intl.numberformat();
    return amount.toFixed(roundTo).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};


const Utilities = {
    number: {
        thousandSeparator
    }
};
export {Utilities};