import { ImageTypes } from '../../enums/product.enums';
import { PATH_CONSTANTS } from '../../constants/product.contants';

export class ProductImageAssets {
    url: string;
    type: number;

    setImageAssetsFromApi(productApi) {
        this.url = productApi.image || productApi.img || '';
        if (!this.url) {
            return null;
        }
        this.type = ImageTypes.None;
        if (this.url) {
            this.url = `${PATH_CONSTANTS.PRODUCT_IMAGE}${this.url}`;
            this.type = ImageTypes.Image;
        }
        return this;
    }

    setTagAssetsFromApi(productApi) {
        this.url = productApi.offerImg || '';
        if (!this.url) {
            return null;
        }
        this.type = ImageTypes.None;
        if (this.url) {
            this.url = `${PATH_CONSTANTS.PRODUCT_IMAGE}${this.url}`;
            this.type = ImageTypes.Image;
        }
        return this;
    }
}
