import { Injectable } from '@angular/core';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Utilities } from 'src/app/shared/classes/utility/utilities';
import { LoaderService } from 'src/app/shared/providers/loader/loader.service';
import { LoggingService } from 'src/app/shared/providers/logging/logging.service';
import { SignupService } from './signup/signup.service';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AddressSelectionType, LocationComponentType } from '../constants/registration-enums';
import { REGISTRATION_ROUTES } from '../constants/registration-routes';
import { NavController, Platform } from '@ionic/angular';
import { EventsService } from 'src/app/shared/providers/events/events.service';
import { EVENT_NAMES } from 'src/app/core/constants/core-constants';
import { DataService } from 'src/app/shared/providers/data/data.service';
import { EVENT_SOURCES } from '../constants/registration-constants';
import { AuthService } from 'src/app/shared/providers/auth/auth.service';
import { StorageService } from 'src/app/shared/providers/storage/storage.service';
import { MasterDataService } from 'src/app/shared/providers/master-data/master-data.service';
import { STORAGE_KEYS } from 'src/app/shared/constants/constants';
import { APIs } from 'src/app/shared/constants/APIs';
import { HttpService } from 'src/app/shared/providers/http/http.service';
import { environment } from 'src/environments/environment';
declare var navigator: Navigator;
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  componentType: number;
  showLoader: boolean;

  constructor(private signupService: SignupService,
    private geolocation: Geolocation,
    private diagnostic: Diagnostic,
    private loggingService: LoggingService,
    private locationAccuracy: LocationAccuracy,
    private loaderService: LoaderService,
    private dataService: DataService,
    private navCtrl: NavController,
    private authService: AuthService,
    private eventsService: EventsService,
    private httpService: HttpService) { }

  enableLocation(component, showLoader = true) {
    this.showLoader = showLoader;
    this.componentType = component
    if (Utilities.isApp()) {
      this.diagnostic.isLocationEnabled().then(
        enabled => {
          if (enabled) {
            this.getCurrentLocation();
          } else {
            this.presentLocationOnPrompt();
          }
        }, (err) => {
          this.loggingService.logMessage('Error in getLocationMode', err);
          this.updateLocationInfo();
        }
      );
    } else {
      showLoader && this.loaderService.showLoading();
      navigator.geolocation.getCurrentPosition((position) => {
        showLoader && this.loaderService.hideLoading();
        this.signupService.latLong = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
        this.updateLocationInfo(true);
        this.eventsService.trackEvents(EVENT_NAMES.LOCATION_ALLOWED, { latitude: position.coords.latitude, longitude: position.coords.longitude });
      }, () => {
        showLoader && this.loaderService.hideLoading();
        this.eventsService.trackEvents(EVENT_NAMES.LOCATION_DENIED, {});
        this.updateLocationInfo();
      });
    }
  }

  updateLocationInfo(updateValue = false) {
    if (!updateValue) {
      this.signupService.latLong = null;
      this.setCoordinates();
    }
    if (this.componentType === LocationComponentType.LocationAccess) {
      this.dataService.draggenedCoordinate = null;
      this.authService.source = EVENT_SOURCES.LOCATION_ACCESS;
      this.navCtrl.navigateForward(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.GEOLOCATION + '/' + AddressSelectionType.SelectSociety);
    } else if (this.componentType === LocationComponentType.DeliveryLocationPage && updateValue) {
      this.signupService.updateCoordinates.next(updateValue);
    }
  }

  presentLocationOnPrompt() {
        this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
          () => {
            this.getCurrentLocation();
          },
          error => {
            this.updateLocationInfo();
          }
        );
  }

  getCurrentLocation() {
    this.showLoader && this.loaderService.showLoading();
    this.geolocation
      .getCurrentPosition()
      .then(resp => {
        this.signupService.latLong = {
          latitude: resp.coords.latitude,
          longitude: resp.coords.longitude
        };
        this.showLoader && this.loaderService.hideLoading();
        this.updateLocationInfo(true);
        this.eventsService.trackEvents(EVENT_NAMES.LOCATION_ALLOWED, { latitude: resp.coords.latitude, longitude: resp.coords.longitude });
      })
      .catch((event) => {
        this.eventsService.trackEvents(EVENT_NAMES.LOCATION_DENIED, {});
        this.showLoader && this.loaderService.hideLoading();
        this.diagnostic.getLocationAuthorizationStatus().then(res => {
          if (res === this.diagnostic.permissionStatus.NOT_REQUESTED && this.dataService.denyCount === 0) {
            this.dataService.denyCount = 1;
            localStorage.setItem(STORAGE_KEYS.LOCATION_DENY_DONT_AGAIN_COUNT, '1');
            this.updateLocationInfo();
          } else if (res === this.diagnostic.permissionStatus.NOT_REQUESTED && this.dataService.denyCount >= 1) {
            this.diagnostic.switchToSettings().then(res => {
            }).catch(() => {
            });
          } else if(res === this.diagnostic.permissionStatus.DENIED_ALWAYS && this.dataService.denyCount === 0) {
            this.dataService.denyCount = 1;
            localStorage.setItem(STORAGE_KEYS.LOCATION_DENY_DONT_AGAIN_COUNT, '1');
            this.updateLocationInfo();
          } 
          else if(res === this.diagnostic.permissionStatus.DENIED_ALWAYS && this.dataService.denyCount >= 1) {
            this.diagnostic.switchToSettings().then(res => {}).catch(() => {});
          } else {
            this.updateLocationInfo();
          }
        }).catch((err) => {
          this.updateLocationInfo();
        });
      });
  }

  setCoordinates() {
    (Object.keys(this.dataService.ipCoordinates).length === 0) && this.getIP().subscribe((res: any) => {
      if (res?.status && res?.data?.ip) {
        this.getLatlngFromIp(res.data.ip).subscribe((response: any) => {
          if (response) {
            if (window.latLng && window.latLng.latitude && window.latLng.longitude) {
              this.signupService.latLong = {
                latitude: window.latLng.latitude,
                longitude: window.latLng.longitude
              };
              this.signupService.updateCoordinates.next(true);
            } else {
              if(response.data?.latitude && response.data?.longitude) {
              this.dataService.ipCoordinates.latitude = response.data.latitude;
              this.dataService.ipCoordinates.longitude = response.data.longitude;
              }
              this.signupService.updateCoordinates.next();
            }
          }
        });
      }
    });
  }

  getIP() {
    return this.httpService.get(APIs.USER_IP, { hideLoader: true }, undefined, true);
  }

  getLatlngFromIp(ip) {
    return this.httpService.get(`${APIs.LAT_LNG_FROM_IP}apiKey=${environment.apiKeyForIPGeo}&ip=${ip}`,
      { hideLoader: true },
      undefined,
      true);
  }

}
