//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
import { PaymentOfferListingModule } from '@Shared/payment/payment-offer-listing/payment-offer-listing.module';
import { SharedModule } from '@Shared/shared-filter/shared-filter.module';
//#endregion Module Imports

//#region Component Imports
import { NetBankingComponent } from '@Shared/payment/net-banking/net-banking.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PaymentOfferListingModule,
    SharedModule
  ],
  exports: [NetBankingComponent],
  declarations: [NetBankingComponent]
})
export class NetBankingModule extends DefaultModule { }