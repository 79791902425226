//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component } from '@angular/core'; 
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component'; 
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports
@Component({
  selector: 'wgt-image-collection-block',
  templateUrl: './image-collection.component.html',
  styleUrls: ['../base/base.component.scss', './image-collection.component.scss']
})
export class ImageCollectionComponent extends BaseCollectionComponent {

  // mbOnInit(): void {
  //   this.platform.resize.subscribe(() => {
  //     this._cd.detectChanges();
  //   });
  // }
}
