//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { InViewportMetadata } from 'ng-in-viewport';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES } from '@Globals/constants/listing/constants';
import { OnlySchedulingOrNot } from '@Globals/constants/listing/enums';
import { Utilities } from '@Globals/classes/utilities';
import { environment } from '@Env/environment';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-auto-swipe-block',
  templateUrl: './auto-swipe.component.html',
  styleUrls: ['./auto-swipe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoSwipeComponent extends BaseCollectionComponent {
  eventSource = EVENT_NAMES.HOME;
  coloumIteration = 0;
  exploreCollectionData;
  collectionTableData: any = [];
  onlySchedulingOrNot = OnlySchedulingOrNot;
  backgroundUrl: string;
  swiperRef:any;
  @ViewChild('swiper', { static: false }) swiper: SwiperComponent;
  autuPlayPros = {
    disableOnInteraction: false,
    pauseOnMouseEnter:false
  }
 
  mbOnInit(): void {
    this.collectionTableData = Utilities.deepCloningOfAnArray(this.data); 

    this.collectionTableData.table.rows = [{ columns: [] }];
    this.coloumIteration = 0;
    this.data.table.rows.forEach((row, rowIndex) => {
      row.columns.forEach((column, columnIndex) => {
        this.coloumIteration++;
        if (rowIndex === 0 && columnIndex === 0) {
          this.exploreCollectionData = column;
          this.backgroundUrl = `${environment.bannerPath}${this.exploreCollectionData.images.leafBanner.image}`;
        }  else {
          this.collectionTableData.table.rows[0].columns.push(column);
        }
      });
    });
    this._cd.markForCheck();
  } 

  swiperInit(swiper: any) {
    this.swiperRef = swiper;
  }

  onIntersection($event) {
    const { [InViewportMetadata]: { entry }, target } = $event;
    const ratio = entry.intersectionRatio;

    this.zone.runOutsideAngular(() => {
      if(ratio >= 0.65) {
        this.swiperRef?.autoplay?.start();
      } else {
        this.swiperRef?.autoplay?.stop();
      }
    });
  }
}
