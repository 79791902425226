import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { Utilities } from '../utility/utilities';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { CleverTap } from '@ionic-native/clevertap/ngx';
import { Platform } from '@ionic/angular';
import { EVENT_NAMES, IOS, SocietyName } from '../../constants/constants';
import { User } from '../../interfaces/interfaces';
import { EventsService } from '../../providers/events/events.service';
import { EventTrackingPlugins } from '../../constants/enums';
import { environment } from '../../../../environments/environment';
import { noop } from 'rxjs';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { getAnalytics, setUserProperties, setUserId } from 'firebase/analytics';
declare var clevertap: any;
export class RegisterPlugins {
  constructor (
    private platform: Platform,
    private eventService: EventsService,
    private appsflyer: Appsflyer,
    private firebase: FirebaseX,
    private cleverTap: CleverTap,
    private networkInterface: NetworkInterface,
  ) { }

  doRegisterPlugins(masterData, registerCleverTap) {
    if (Utilities.isApp()) {
      if (masterData.user && masterData.user.id) {
        this.registerAppsflyer(masterData.user.id);
        this.registerFirebase(masterData.user);
        // if (masterData.isClevertap) {
          this.createProfileData(masterData, registerCleverTap, true);
        // }
      }
    } else if (!Utilities.isApp() && masterData.user && masterData.user.id 
    // && masterData.isClevertap
    ) {
      const fbase = getAnalytics();
      if(fbase) 
      {
        setUserId(fbase, masterData.user.id.toString());
        setUserProperties(fbase,  masterData.user );
      }
      this.createProfileData(masterData, registerCleverTap, false);
    }
  }

  private async createProfileData(masterData, registerCleverTap, isApp) {
    const profile = masterData.user;
    const userData: any = {
      Identity: profile.id, // User's identity
      Name: profile.name,
      'First Name': profile.firstName,
      'Last Name': profile.lastName,
      Phone: profile.country_code + profile.phone,
      Address: profile.address,
      Society: profile.society,
      'Society Type': SocietyName[profile.societyType],
      City: profile.city,
      Paid: profile.paid,
      'Creation Date': profile.created,
      'Referral Code': profile.referredByCode,
      'Invite Code': profile.inviteCode,
      isSocietyReferralActive: profile.societyReferralActive,
      societyEntryType: profile.society_entry_type,
      codePushVersion: environment.codePushVersion,
      'Hub': profile.hubName,
      isMBeyond: profile.is_mbeyond,
      balance: masterData?.balance,
      segment: masterData.segmentOffer ? masterData.segmentOffer?.userSegments.toString() : [],
      isDuplicate: profile.is_duplicate,
      isValidAddress: profile.is_valid_address
    };
    if ((profile.phone + '@milkbasket.com') !== profile.email) {
      userData['Email'] = profile.email;
    }
    if (masterData?.current_membership) {
      userData['Membership Type'] = masterData?.current_membership?.status.toString();
      userData['Membership End Date'] = masterData?.current_membership?.endDate;
      userData['Membership Days Left'] = masterData?.current_membership?.daysLeft;
    }
    if (profile.first_payment_date) {
      userData['First Payment Date'] = profile.first_payment_date;
    }
    if (profile.first_order_date) {
      userData['First Order Date'] = profile.first_order_date;
    }
    let mbPassData: any = {};
    if (masterData?.customer_mb_pass && masterData.customer_mb_pass.status) {
      mbPassData = masterData.customer_mb_pass;
    } else if (masterData?.mbpass_customer_segments && masterData?.mbpass_customer_segments?.length) {
      mbPassData = masterData.mbpass_customer_segments[0];
    }
    if (mbPassData.passId) {
      userData['MB Pass Active'] = !!mbPassData.startDate;
      userData['MB Pass max cashback'] = mbPassData.maxCashback;
      userData['MB Pass Cashback earned'] = mbPassData.totalSavings || 0;
      userData['MB Pass id'] = mbPassData.passId;
      userData['MB Pass Validity'] = mbPassData.validity;
      userData['MB Pass Start Date'] = mbPassData.startDate || '';
    }
    if (isApp) {
      this.registerCleverTap(userData, registerCleverTap)
    } else {
      this.registerCleverTapForWeb(userData, registerCleverTap);
    }
  }

  private registerWifiSSID() {
    this.networkInterface.getWiFiIPAddress()
      .then(address => {
        this.eventService.trackEvents(EVENT_NAMES.WIFI_IP_ADDRESS, {
          value: address.ip,
        });
      })
      .catch(error => console.error(`Unable to get IP: ${error}`));

    this.networkInterface.getCarrierIPAddress()
      .then(address => {
        this.eventService.trackEvents(EVENT_NAMES.CARRIER_IP_ADDRESS, {
          value: address.ip,
        })
      })
      .catch(error => console.error(`Unable to get IP ${error}`));

    this.networkInterface.getHttpProxyInformation(environment.apiUrl)
      .then(proxy => {
        this.eventService.trackEvents(EVENT_NAMES.HTTP_PROXY_INFORMATION, {
          type: proxy.type,
          host: proxy.host,
          port: proxy.port
        })
      })
      .catch(error => console.error(`Unable to get proxy info ${error}`));
  }

  private registerAppsflyer(userId) {
    this.appsflyer.setAppUserId(userId.toString());
  }

  private registerFirebase(userData: User) {
    const userId = userData.id.toString();
    this.firebase.setUserId(userId).catch(noop);
    this.firebase.setCrashlyticsUserId(userId).catch(noop);
    let key: string;
    let value: string;
    for ([ key, value ] of Object.entries(userData)) {
      this.firebase.setUserProperty(key, value).catch(noop);
    }
  }

  private registerCleverTap(userData, registerCleverTap) {
    if (this.platform.is(IOS)) {
      this.firebase.getAPNSToken().then((APNSToken) => {
        if (APNSToken) {
          this.cleverTap.setPushToken(APNSToken).catch(noop);
          this.eventService.trackEvents(EVENT_NAMES.APNS_TOKEN, { token: APNSToken }, false, EventTrackingPlugins.CleverTap);
        }
      }, () => { });
    }
    this.cleverTap.enablePersonalization().catch(noop);
    this.cleverTap.registerPush().catch(noop);
    if (registerCleverTap) {
      this.cleverTap.onUserLogin(userData).catch(noop);
    }
  }

  private registerCleverTapForWeb(userData, registerCleverTap) {
    if (registerCleverTap) {
      clevertap.onUserLogin.push({ "Site": userData });
    }
  }

}
