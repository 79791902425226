import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AlertController, AnimationController, ModalController, NavController, Platform } from '@ionic/angular';
import { HttpService } from './shared/providers/http/http.service';
import { environment } from '../environments/environment';
import { StorageService } from './shared/providers/storage/storage.service';
import { ANDROID, FIREBASE_TRACES, GENERATE_CONTENT_FOR_ALERT_LAYER, INTERNET_ERROR, STORAGE_KEYS, TRUE, FALSE, MODALS, APP_VERSION, IOS,JIO_EVENTS, LOGOUT_MODE } from './shared/constants/constants';
import { AuthService } from './shared/providers/auth/auth.service';
import { MasterDataService } from './shared/providers/master-data/master-data.service';
import { InitializePlugins } from './core/classes/initialize-plugins/initialize-plugins';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LISTING_ROUTES } from './listing/constants/listing-routes';
import { PAYMENT_ROUTES } from '@Globals/constants/payment/routes';
import { EventsService } from './shared/providers/events/events.service';
import { APPSFLYER, CITY_MODAL_CSS_CLASS, EVENT_NAMES, EVENT_SOURCES, EXIT_PAGE_ROUTES_MAPPER, FIREBASE_REMOTE_CONFIG_CALL, LIVE_NOW, UPCOMING_DELIVERY_INFO } from './core/constants/core-constants';
import { CORE_ROUTES } from './core/constants/coreRoutes';
import { Utilities } from './shared/classes/utility/utilities';
import { FirebaseService } from '@Globals/providers/firebase.service';
import { NavigationEnd, NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { DataService } from './shared/providers/data/data.service';
import { ModalService } from './shared/providers/modal/modal.service';
import { CleverTap } from '@ionic-native/clevertap/ngx';
import { DeepLinkService } from './shared/providers/deeplink/deep-link.service';
import { Network } from '@ionic-native/network/ngx';
import { AlertService } from './shared/providers/alert/alert.service';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { take } from 'rxjs/operators';
import { noop, Subscription } from 'rxjs';
import { ToastService } from './shared/providers/toast/toast.service';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links/ngx';
import { SuperChargeType } from '@Globals/constants/payment/enums';
import { LoggingService } from './shared/providers/logging/logging.service';
import { Device } from '@ionic-native/device/ngx';
import { LoaderService } from './shared/providers/loader/loader.service';
import { DATA_UI_EVENT_SOURCE_TYPES, DATA_UI_TIMER, SIGNUP_ERRORS } from './registration/constants/registration-constants';

import { ModalService as NewModalService } from "@Globals/providers/modal/modal.service";
import { NavigatorService } from "@Globals/providers/navigator.service";
import { ShoppingCreditsServices } from "@Globals/providers/shopping-credits.service";

import { Location } from "@angular/common";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { SplashPage } from "@Pages/splash/splash.page";
import { WebAppflyerService } from "@Globals/providers/web-appflyer-service";
import { LogoutService } from "@Globals/providers/logout.service";
import { LogoutPlugins } from "@Globals/classes/logout-plugins";
import { EventTrackingPlugins } from "@Globals/constants/listing/enums";
import { ApolloClientService, clearApolloCache } from "@Widgets/react-renderer/providers/apollo-cache.service";
import {
  ADDITEM_ALERT,
  ADDRESS_UNDER_VERIFICATION,
  ADDRESS_VERIFIED_SUCCESSFULLY,
  FREE_MEMBERSHIP_ACTIVATED,
  PRODUCT_DETAILS,
  TARGET_MEMBERSHIP_COMPLETED,
  TARGET_MEMBERSHIP_EXTENDED,
  TERMS_USES,
  UNDER_REJECTED_ADDRESS,
} from "./listing/constants/listing-constants";
import {
  EVENT_NAMES as EVENT_NAME_LIST,
  EVENT_SOURCES as EVENT_SOURCE_LIST,
} from "@Globals/constants/listing/constants";
import { WelcomeMessageComponent } from "@Pages/home/components/welcome-message/welcome-message.component";
// import { MembershipService } from '@Globals/providers/membership.service';
import { SelectCityComponent } from "@Features/registration/select-city/select-city.component";
import { ProductListManagerService } from "@Features/product-list/providers/product-list-manager.service";
import { RateAppComponent } from "@Pages/home/components/rate-app/rate-app.component";
import { PROFILE_ROUTES } from "@Globals/constants/profile/routes";
import { CustomerRating } from "@Pages/home/interface/interfaces";
import { ReferralSuccessComponent } from "@Pages/home/components/referral-success/referral-success.component";
import moment from "moment";
import { HomeService } from "@Pages/home/providers/home.service";
import { AddressStatus } from "@Globals/constants/registration/constants";
import { TargetMembership } from "./shared/interfaces/interfaces";
import { MembershipTermsAndConditionComponent } from "@Pages/membership/components/membership-terms-and-condition/membership-terms-and-condition.component";
import { UpcomingDeliveryInfoComponent } from "@Shared/upcoming-delivery-info/upcoming-delivery-info.component";
import { RecurringService } from "@Globals/providers/recurring.service";
import { MEMBERSHIP_CACHE_TYPES } from "@Globals/constants/react/widget-map.constants";
import { HTTPResponse } from '@ionic-native/http';

declare var MobileAccessibility: any;
declare var window: any;
declare var MobileAccessibility: any;
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  liveNow = LIVE_NOW;
  superChargeType = SuperChargeType;
  isPluginsInitialized = false;
  isAppSecurityCompromised: boolean = false;
  intervalId: any;
  loading: boolean = false;
  splashModal: HTMLIonModalElement;
  subscription: Subscription = new Subscription();
  utility = Utilities;
  lastLogoutValue: LOGOUT_MODE;
  isCodePushRunning = false;
  customerRating: CustomerRating;
  bottomNavVisible = false;
  targetMembership: TargetMembership;
  lastPauseAt;
  constructor(
    private platform: Platform,
    private animationCtrl: AnimationController,
    protected modalCtrl: ModalController,
    private statusBar: StatusBar,
    public authService: AuthService,
    private httpService: HttpService,
    public loaderService: LoaderService,
    private storageService: StorageService,
    private navCtrl: NavController,
    public masterDataService: MasterDataService,
    private firebaseDynamicLinks: FirebaseDynamicLinks,
    private appsflyer: Appsflyer,
    private eventsService: EventsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private firebaseService: FirebaseService,
    public dataService: DataService,
    private cleverTap: CleverTap,
    public modalService: ModalService,
    public newModalService: NewModalService,
    public navigatorService: NavigatorService,
    private deepLinkService: DeepLinkService,
    private network: Network,
    private alertService: AlertService,
    private alertCtrl: AlertController,
    private deploy: Deploy,
    private toastService: ToastService,
    private firebase: FirebaseX,
    private loggingService: LoggingService,
    private device: Device,
    private lottieSplashScreen: SplashScreen,
    private zone: NgZone,
    private webAppflyerService: WebAppflyerService,
    private location: Location,
    private logoutService: LogoutService,
    private shoppingCreditsServices: ShoppingCreditsServices,
    private recurringService: RecurringService,
    //private membershipService: MembershipService,
    private plmService: ProductListManagerService,
    private homeService: HomeService,
    private apolloService: ApolloClientService
  ) {
    this.initializeEnvVariables();
    this.initializeApp();
    !Utilities.isApp() && this.setupJioSession();
    this.apolloService.configureApolloClient();
  }

  initializeEnvVariables() {
    let processKey = 'process';
    let envKey = 'env';
    if (!window[processKey]) {
      window[processKey] = window[processKey] || {};
      window[processKey][envKey] = window[processKey][envKey] || {};
    }
    let reactVariables = environment.reactProcessVars;
    for (let i in reactVariables) {
      if (!window[processKey][envKey][i]) {
        window[processKey][envKey][i] = reactVariables[i];
      }
    }
  }

  setupJioSession() {
    const params = window.location.href?.split("?");
    const urlParams = new URLSearchParams(params.slice(1).join());
    this.authService.jioMartData.isJioMartUser = Boolean(
      urlParams.get("jioMart")
    );
    this.authService.jioMartData.isSelfHelpRedirect = Boolean(
      urlParams.get("selfHelp")
    );
    this.authService.jioMartData.isCalledFromHomeBanner =
      urlParams.get("linksrc");
    if (
      this.authService.jioMartData.isJioMartUser &&
      (this.platform.is(ANDROID) || this.platform.is(IOS))
    ) {
      document
        .getElementsByTagName("body")[0]
        .classList.add("jio-mart-statusbar-height");
      this.dataService.app_sendToApp({
        type: JIO_EVENTS.GET_JM_AUTH_TOKEN,
      });
      this.dataService.app_sendToApp({
        type: JIO_EVENTS.PRESENT_HEADER,
        value: 2,
      });
      this.dataService.app_sendToApp({
        type: JIO_EVENTS.GETLATLNG,
      });
      localStorage.setItem(
        STORAGE_KEYS.ISJIOUSER,
        JSON.stringify(this.authService.jioMartData.isJioMartUser)
      );
      this.dataService._isJioUserSubject.next(true);
    }
  }

  ngOnInit(): void {

    this.subscription.add(
      this.loaderService.showLoaderBehaviourSubject.subscribe(
        (res: boolean) => {
          this.loading = res;
        }
      )
    );
    this.subscription.add(
      this.storageService.appSecurityCompromisedSub.subscribe(
        (appSecurityCompromised: boolean) => {
          if (appSecurityCompromised) {
            try {
              this.isAppSecurityCompromised = appSecurityCompromised;
              navigator["app"] &&
                "exitApp" in navigator["app"] &&
                navigator["app"].exitApp();
            } catch (error) {}
          }
        }
      )
    );
    this.subscription.add(
      this.authService.isLogout.subscribe((res: LOGOUT_MODE) => {
        if (this.authService.isLoggedIn() && window.navigator.onLine) {
          if (
            res &&
            (res === LOGOUT_MODE.FORCE_LOGOUT ||
              !(
                this.lastLogoutValue === LOGOUT_MODE.DEFAULT_LOGOUT ||
                this.lastLogoutValue === LOGOUT_MODE.DATA_UI_LOGOUT ||
                this.lastLogoutValue === LOGOUT_MODE.REFRESH_TOKEN_API_LOGOUT
              )) &&
            res != LOGOUT_MODE.NO_LOGOUT
          ) {
            this.eventsService.trackEvents(
              EVENT_NAMES.APP_TRYING_TO_LOGOUT,
              {
                authToken: this.authService.getAuthKey(),
                refreshToken: this.authService.getRefreshKey(),
                mode: res,
                lastLogoutValue: this.lastLogoutValue,
                auth_expire_time: this.authService.getRefreshTime(),
              },
              false,
              EventTrackingPlugins.Firebase
            );
            new LogoutPlugins(this.appsflyer).doLogoutPlugins(
              this.masterDataService.masterData?.user
            );
            this.authService.logout();
            this.logoutService.sessionLogout();
            try {
              clearApolloCache();
              this.apolloService.configureApolloClient();
            } catch (e) {}
          }
          this.lastLogoutValue = res;
        }
      })
    );
    /* this.subscription.add(
      this.dataService.addressUpdateAlert.subscribe((response) => {
        response && this.presentEmptyCartAlert();
      })
    ); */

    /* this.subscription.add(
      this.dataService.addressUnderVerificationAlert.subscribe((response) => {
        if (response) {
           this.showUnderVerifyAddress();
          this.dataService.addressUnderVerificationAlert.next(false);
        }
      })
    ); */
    this.dataService.targetMembership.subscribe((targetMembershipData) => {
      this.targetMembership = targetMembershipData;  
    });

    /* this.subscription.add(
      this.dataService.showUpcomingSocietyModal.subscribe((response) => {
        if(response) {
          this.showUpcomingDeliveryInfoPopup();
        }
      })
    ) */
  }

  showUnderVerifyAddress() {
    this.masterDataService.masterData.user &&
      (this.masterDataService.masterData.user.is_valid_address =
        AddressStatus.UnderVerification);
    localStorage.setItem(
      STORAGE_KEYS.ADDRESS_STATUS,
      JSON.stringify(AddressStatus.UnderVerification)
    );
    this.masterDataService.localAddressStatus = AddressStatus.UnderVerification;
    ADDRESS_UNDER_VERIFICATION.BUTTONS.CLOSE.handler = noop();
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(ADDRESS_UNDER_VERIFICATION.META_DATA),
      [
        ADDRESS_UNDER_VERIFICATION.BUTTONS.CLOSE,
        ADDRESS_UNDER_VERIFICATION.BUTTONS.CONFIRM,
      ],
      ADDRESS_UNDER_VERIFICATION.CSS_CLASS
    );
  }

  presentEmptyCartAlert() {
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(ADDITEM_ALERT.META_DATA),
      [ADDITEM_ALERT.BUTTONS.CLOSE],
      ADDITEM_ALERT.CSS_CLASS
    );
  }

  RegisterAppsFlyerAttribution = () => {
    this.appsflyer
      .registerOnAppOpenAttribution()
      .then((response) => {
        const deepLinkData = Utilities.parseJSONString(response);
        if (deepLinkData.type === APPSFLYER.ON_APP_OPEN_ATTRIBUTION) {
          deepLinkData.data.deeplink =
            deepLinkData.data.deep_link_value ||
            deepLinkData.data.af_dp ||
            deepLinkData.data.af_deeplink ||
            deepLinkData.data.link ||
            null;
          this.deepLinkHandler(deepLinkData.data);
        }
      })
      .catch();
  };

  SyncWithCodePush = () => {
    if (environment.codepushEnabled) {
      setTimeout(async () => {
        if (this.isCodePushRunning) {
          return true;
        }
        let currentVersion;
        this.isCodePushRunning = true;
        try {
          currentVersion = await this.deploy.getCurrentVersion();
          this.eventsService.trackEvents(
            EVENT_NAMES.CODE_PUSH_STARTED,
            {
              version: currentVersion,
              envVersion: environment.codePushVersion,
            },
            true,
            EventTrackingPlugins.Firebase
          );
          const resp: any = await this.deploy
            .sync({ updateMethod: "background" }, (percentDone) => {})
            .then((value) => {
              this.eventsService.trackEvents(
                EVENT_NAMES.CODE_PUSH_DOWNLOADED,
                {
                  version: currentVersion,
                  envVersion: environment.codePushVersion,
                },
                true,
                EventTrackingPlugins.Firebase
              );
              this.loggingService.logMessage(value);
              this.isCodePushRunning = false;
            })
            .catch((reason) => {
              this.loggingService.logMessage(reason);
              this.eventsService.trackEvents(
                EVENT_NAMES.CODE_PUSH_ERROR,
                {
                  version: currentVersion,
                  envVersion: environment.codePushVersion,
                  reason: reason,
                },
                true,
                EventTrackingPlugins.Firebase
              );
              this.isCodePushRunning = false;
            });
        } catch (err) {
          this.isCodePushRunning = false;
          this.eventsService.trackEvents(
            EVENT_NAMES.CODE_PUSH_FAILED,
            {
              version: currentVersion,
              envVersion: environment.codePushVersion,
            },
            true,
            EventTrackingPlugins.Firebase
          );
          this.loggingService.logMessage(err);
          // We should show an error here thru toasts.
          // We encountered an error.
        }
      }, 3000);
    }
  };

  // Home or Boarding depending on user login state
  showInitialScreen = (isLottieSplashEnabled = false) => {
    let navigationUrl = CORE_ROUTES.SIGNUP_JOURNEY;
    this.authService.authKey = localStorage.getItem(STORAGE_KEYS.AUTH_KEY);
    if (
      this.authService.authKey &&
      !this.dataService._isJioUserSubject.getValue()
    ) {
      navigationUrl = "/" + LISTING_ROUTES.HOME;
      if (
        window.location.href.indexOf(CORE_ROUTES.PAYMENT) > -1 &&
        window.location.href.indexOf(PAYMENT_ROUTES.COMPLETE) > -1
      ) {
        navigationUrl = PAYMENT_ROUTES.BASE + PAYMENT_ROUTES.COMPLETE;
      }
      this.firebase.startTrace(FIREBASE_TRACES.HOME_PAGE_LAUNCH);
    } else if (
      this.authService.authKey &&
      this.dataService._isJioUserSubject.getValue()
    ) {
      if (
        window.location.href.indexOf(CORE_ROUTES.PAYMENT) > -1 &&
        window.location.href.indexOf(PAYMENT_ROUTES.COMPLETE) > -1
      ) {
        navigationUrl = "/" + PAYMENT_ROUTES.COMPLETE;
      } else if (this.authService.jioMartData.isSelfHelpRedirect) {
        navigationUrl =
          "/" +
          LISTING_ROUTES.HOME +
          "/" +
          MODALS.HELP +
          "/" +
          `${Utilities.createRandom4DigitNumber()}`;
      }
    }
    if (window.location.href.indexOf(LISTING_ROUTES.PRODUCT_DETAIL) > -1) {
      this.masterDataService.navigateFromDeeplink = true;
      this.webAppflyerService.createOnelinkFromUrl(window.location.href);
      navigationUrl = window.location.pathname;
    }

    this.navCtrl.navigateForward(navigationUrl).then(() => {
      setTimeout(() => {
        Utilities.isApp() && this.lottieSplashScreen.hide();
        this.splashModal?.dismiss();
      }, isLottieSplashEnabled ? 1300 : 2000);
      setTimeout(function() {
       navigator.splashscreen.hide()
        }, 3000);
    });
    this.masterDataService.getMasterData(!this.authService.authKey, false, true, true, false, DATA_UI_EVENT_SOURCE_TYPES.APP_INITIALIZATION );
  };

  openCalendar = () => {
    this.navCtrl.navigateForward(
      LISTING_ROUTES.BASE + LISTING_ROUTES.RECURRING_ORDERS
    );
  };

  disabledMenuOrNot() {
    return window.location.href.indexOf(CORE_ROUTES.BOARDING) > -1;
  }

  onMenuOpen() {
    this.eventsService.trackEvents(EVENT_NAMES.HAMBURGER_OPEN, {
      Source: EVENT_SOURCES.MENU,
    });
  }
  checkForInternetDisconnect() {
    this.subscription.add(
      this.network.onDisconnect().subscribe(() => {
        const internetErrorContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
          INTERNET_ERROR.META_DATA
        );
        this.alertService.presentAlertConfirm(
          internetErrorContent,
          [],
          INTERNET_ERROR.CSS_CLASS,
          INTERNET_ERROR.BACKDROP_DISMISS
        );
        this.checkForInternetConnection();
      })
    );
  }

  checkForInternetConnection() {
    this.subscription.add(
      this.network.onConnect().subscribe(async () => {
        await this.callRefreshToken();
        this.alertCtrl.getTop().then((topAlert) => {
          if (topAlert) {
            this.alertCtrl.dismiss();
            if (!this.masterDataService.masterData) {
              this.masterDataSetting();
            } else {
              this.router
                .navigateByUrl("/", { skipLocationChange: true })
                .then(() => this.router.navigate([this.router.url]));
            }
          }
        });
        this.checkForInternetDisconnect();
      })
    );
  }

  fixReloadPath() {
    if (
      window.location.href.indexOf(LISTING_ROUTES.BASE) > -1 &&
      window.location.href.indexOf("/" + LISTING_ROUTES.HOME) === -1
    ) {
      this.location.go("/" + LISTING_ROUTES.HOME);
    }
  }

  async openSplashModal() {
    const enterAnimation = (baseEl: HTMLElement) => {
      const root = baseEl.shadowRoot;

      const backdropAnimation = this.animationCtrl
        .create()
        .addElement(root.querySelector("ion-backdrop")!)
        .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

      const wrapperAnimation = this.animationCtrl
        .create()
        .addElement(root.querySelector(".modal-wrapper")!)
        .keyframes([
          { offset: 0, opacity: "0" },
          { offset: 1, opacity: "0.99" },
        ]);

      return this.animationCtrl
        .create()
        .addElement(baseEl)
        .easing("ease-out")
        .duration(500)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    };

    const leaveAnimation = (baseEl: HTMLElement) => {
      return enterAnimation(baseEl).direction("reverse");
    };

    if (this.platform.is(ANDROID)) {
      this.splashModal = await this.modalCtrl.create({ component: SplashPage });
      setTimeout(() => {
        this.lottieSplashScreen.hide();
        this.splashModal.present().then(() => {
          setTimeout(() => {
            this.showInitialScreen();
          }, 2000);
        });
      }, 1400);
    } else {
      this.splashModal = await this.modalCtrl.create({
        component: SplashPage,
        leaveAnimation,
      });
      Utilities.isApp() && this.lottieSplashScreen.hide();
      this.splashModal.present().then(() => {
        if (Utilities.isApp()) {
          setTimeout(() => {
            this.showInitialScreen(true);
          }, 2000);
        } else {
          this.showInitialScreen(true);
        }
      });
    }
  }

  initializeApp() {
    Utilities.isApp() && this.fixReloadPath();
    this.platform.ready().then(async () => {
      this.storageService.checkIfAppSecurityCompromised();
      Utilities.isApp() &&
        this.platform.is(ANDROID) &&
        this.storageService.getDeviceOSVersion();
      this.httpService.setDataSerializer("json");
      if (MobileAccessibility && Utilities.isApp()) {
        MobileAccessibility.usePreferredTextZoom(false);
      }
      this.statusBar.overlaysWebView(true);
      this.statusBar.styleDefault();
      this.httpService.setRequestTimeout(50);
      this.dataService.uuid = this.device.uuid;
      this.eventsService.uuid = this.device.uuid;
      this.dataService.oneTimeTimerForSlidingActiveSegmentIntoView = 1000;
      this.dataService.isUserWalletFreeze = true;
      this.authService.getAuthKey();
      this.storageService.getCachedImageMapFromLocalStorage();
      this.httpService.setupSSL();
      this.eventsService.trackEvents(EVENT_NAMES.APP_OPENED, {});
      const firebaseData = localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG);
      firebaseData && (this.dataService.firebaseMasterData = JSON.parse(firebaseData));
      if(this.authService.isLoggedIn()){
        setTimeout(function() {
          navigator?.splashscreen?.hide()
          const appToken = localStorage.getItem(STORAGE_KEYS.FIREBASE_PUSH_TOKEN);
          if(!!appToken && !this.firebaseService.tokenStored) {
            this.firebaseService.storeNotificationToken(appToken);
          } else if(!this.firebaseService.tokenStored) {
            this.firebaseService.fetchFirebaseToken();
          }
        }, 2000);
      }
      if (Utilities.isApp()) {
        this.storageService.checkStorageDirectoriesIfExistElseCreate();
        if (
          this.platform.is(IOS) &&
          localStorage.getItem(APP_VERSION) !== environment.codePushVersion
        ) {
          this.storageService.imageStorage = {};
          this.storageService.setCachedImageMapInLocalStorage();
          localStorage.setItem(APP_VERSION, environment.codePushVersion);
        }
        new InitializePlugins(
          this.appsflyer,
          this.platform,
          this.cleverTap,
          this.firebase,
          this.deepLinkService,
          this.eventsService
        ).doInitializePlugins();
        await this.callRefreshToken();
        this.dataService.firebaseMasterData?.festiveSeason?.splash?.visibility
          ? this.openSplashModal()
          : this.showInitialScreen();
      } else {
        await this.callRefreshToken();
        this.showInitialScreen();
      }
      this.masterDataService.removeDataFromLocalStorage(
        STORAGE_KEYS.TWID_ELIGIBILITY_CACHE_EXPIRATION
      );
      this.dataService.showFrozenProductA2BAlert = !localStorage.getItem(
        STORAGE_KEYS.SHOW_FROZEN_PRODUCT_A2B_ALERT
      );
      this.dataService.dontShowSubsciptionAgain =
        localStorage.getItem(STORAGE_KEYS.DONT_SHOW_SUBSCRIPTION_AGAIN) ===
        TRUE;
      this.dataService.showRecurringCoachMark =
        localStorage.getItem(STORAGE_KEYS.SHOW_RECURRING_COACHMARK) !== FALSE;
      this.dataService.recRecommendationFromStorage = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.RECURRING_RECOMMENDATIONS)
      );
      this.dataService.frequentSearches = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.FREQUENT_SEARCHES)
      );
      this.dataService.likedCollectionsList = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.LIKED_COLLECTIONS)
      );
      this.authService.inviteCode = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.REFERRER_CODE)
      );
      this.dataService.calendarCoachmarkObject = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.CALENDAR_COACHMARK_OBJECT)
      );
      this.authService.jioMartData.isJioMartUser =
        localStorage.getItem(STORAGE_KEYS.ISJIOUSER) === TRUE;
      this.authService.jioMartData.isJioMartUser &&
        this.dataService._isJioUserSubject.next(true);
      this.dataService.denyCount = +localStorage.getItem(
        STORAGE_KEYS.LOCATION_DENY_DONT_AGAIN_COUNT
      );
      this.dataService.subscribeAndSaveNudge =
        localStorage.getItem(STORAGE_KEYS.SUBSCRIBE_AND_SAVE_NUDGE) !== FALSE;
      if (localStorage.getItem(STORAGE_KEYS.FIRST_ATB_SUCESS)) {
        this.masterDataService.firstAtbSucces = JSON.parse(
          localStorage.getItem(STORAGE_KEYS.FIRST_ATB_SUCESS)
        );
      }
      if (localStorage.getItem(STORAGE_KEYS.LAST_NOCHECKOUT_VISIT_DATE)) {
        this.masterDataService.lastCheckoutVisitDate = localStorage.getItem(
          STORAGE_KEYS.LAST_NOCHECKOUT_VISIT_DATE
        );
      }
      if (
        localStorage.getItem(STORAGE_KEYS.CASHBACK_SAVINGS) &&
        localStorage.getItem(STORAGE_KEYS.CASHBACK_SAVINGS) !== "null" &&
        localStorage.getItem(STORAGE_KEYS.CASHBACK_SAVINGS) !== "undefined"
      ) {
        this.masterDataService.cashbackSavings = JSON.parse(
          localStorage.getItem(STORAGE_KEYS.CASHBACK_SAVINGS)
        );
      }
      this.authService.jioPreviousLogInNumber = localStorage.getItem(
        STORAGE_KEYS.JIO_NUMBER
      );
      if (localStorage.getItem(STORAGE_KEYS.USER_DATA)) {
        this.authService.userObj = JSON.parse(
          localStorage.getItem(STORAGE_KEYS.USER_DATA)
        );
      }
      const cityInfo = localStorage.getItem(STORAGE_KEYS.CITY);
      cityInfo && (this.authService.userData.cityObj = JSON.parse(cityInfo));
      this.masterDataSetting();
      this.navigatorService.backButtonSubscriber();
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (event.url.includes("/home")) {
            this.executeHomeRouteDeeplinks(event.url);
          }
          if (event.restoredState) {
            this.modalService.dismissTopModal(
              (topModal, modalCtrlReference: ModalController) => {
                if (topModal) {
                  modalCtrlReference.dismiss();
                  this.router.navigateByUrl(this.router.url, {
                    replaceUrl: true,
                  });
                }
              }
            );
          }
        }
        if (event instanceof NavigationEnd) {
          this.dataService.isShadowRequired = false;
        }
      });
      this.checkForInternetDisconnect();
      this.addDataUIInterval();
      if (Utilities.isApp()) {
        this.askTrackingPermission();
        this.SyncWithCodePush();
        this.appClosedDynamicLink();
        this.RegisterAppsFlyerAttribution();
        this.firebaseService.fetchRemoteConfig(
          this.authService.isLoggedIn()
            ? FIREBASE_REMOTE_CONFIG_CALL.All
            : FIREBASE_REMOTE_CONFIG_CALL.MAP_KEY_RIL
        );
      } else {
        this.firebaseService.fetchConfigVersionForWeb(
          this.authService.isLoggedIn()
            ? FIREBASE_REMOTE_CONFIG_CALL.All
            : FIREBASE_REMOTE_CONFIG_CALL.MAP_KEY_RIL
        );
      }
      this.zone.runOutsideAngular(() => {
        this.toastService.showInvisibleToast();
      });
    });
    this.platform.resume.subscribe(async () => {
      this.httpService.setDataSerializer("json");
      await this.callRefreshToken();
      let currentTime = new Date().getTime();
      if(this.lastPauseAt && (Math.abs(moment(currentTime).diff(moment(this.lastPauseAt), 'minutes'))  > 10) && this.authService.isLoggedIn()) {
        // update dashboard
        this.apolloService.evictHomeDashboard();
      }
      this.lastPauseAt = 0;
      if (Utilities.isApp()) {
        this.appClosedDynamicLink();
        this.RegisterAppsFlyerAttribution();
        this.SyncWithCodePush();
        this.firebaseService.fetchRemoteConfig(
          this.authService.isLoggedIn()
            ? FIREBASE_REMOTE_CONFIG_CALL.All
            : FIREBASE_REMOTE_CONFIG_CALL.MAP_KEY_RIL
        );
        this.authService.authKey &&
          this.masterDataService.executeParallelCalls();
      } else {
        this.firebaseService.fetchConfigVersionForWeb(
          this.authService.isLoggedIn()
            ? FIREBASE_REMOTE_CONFIG_CALL.All
            : FIREBASE_REMOTE_CONFIG_CALL.MAP_KEY_RIL
        );
      }
      if(this.masterDataService.baskets?.length && this.masterDataService.baskets[0].date && (Utilities.isPreviousDate(this.masterDataService.baskets[0].date))) {        
        this.masterDataService.getMasterData(true, false, true, false, false, DATA_UI_EVENT_SOURCE_TYPES.APP_RESUME);
      }
      this.addDataUIInterval();
      this.eventsService.trackEvents(EVENT_NAMES.APP_OPENED, {});
    });
    this.platform.pause.subscribe(() => {
      this.lastPauseAt = new Date().getTime();
      if (Utilities.isApp()) {
        this.storageService.setCachedImageMapInLocalStorage();
        this.masterDataService.trackProductImpression();
      }
      clearInterval(this.intervalId);
    });
  }

  executeHomeRouteDeeplinks(url) {
    const idList = url.split("/");
    let modalName = "";
    let id;
    if (idList && idList.length && idList.length > 3) {
      modalName = idList[2];
      id = idList[3];
    }
    if (modalName && id) {
      switch (modalName) {
        case MODALS.PDP:
          setTimeout(() => {
            this.plmService.productDetailsModal(id, 0, PRODUCT_DETAILS);
          }, 500);
          break;
        case MODALS.SELECT_CITY:
          if (this.authService.userData && !this.authService.userData.cityObj) {
            this.newModalService.openModalWithCallback(
              {
                component: SelectCityComponent,
                backdropDismiss: false,
                cssClass: CITY_MODAL_CSS_CLASS,
                componentProps: { serviceable: true },
              },
              (data) => {
                this.checkForWelcomeMessagePopup();
              }
            );
          }
          break;
        case MODALS.WALLET_INCREASE:
          if (
            this.masterDataService.masterData?.wallet_increase_offers &&
            this.masterDataService.masterData.wallet_increase_offers.length
          ) {
            //  this.membershipService.openWalletIncreaseModal(EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION, WalletIncreaseComponent);
          }
          break;
        case MODALS.MEMBERSHIP_DETAILS:
          this.masterDataService.masterData?.current_membership &&
            this.dataService.showMembershipInfo(
              this.masterDataService.masterData?.current_membership,
              EVENT_NAME_LIST.HOME
            );
          break;
        case MODALS.INBOX:
          this.dataService.openUserInbox();
          break;
        case MODALS.HELP:
          document
            .getElementsByTagName("body")[0]
            .classList.remove("home-page");
          this.openSelfHelp();
          break;
      }
    }
  }

  checkForWelcomeMessagePopup() {
    let value = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.APP_START_MESSAGE)
    );
    const prevAppStartMessage = value;
    const lastShownTime = new Date(
      prevAppStartMessage && prevAppStartMessage.lastDisplay
    );
    const currentDate = new Date();
    let showPopup = false;
    if (
      lastShownTime.getMonth() === currentDate.getMonth() &&
      lastShownTime.getDate() < currentDate.getDate()
    ) {
      showPopup = true;
    } else if (lastShownTime.getMonth() < currentDate.getMonth()) {
      showPopup = true;
    } else if (lastShownTime.getFullYear() < currentDate.getFullYear()) {
      showPopup = true;
    } else if (
      prevAppStartMessage &&
      this.masterDataService.masterData &&
      this.masterDataService.masterData.appStartMessage &&
      prevAppStartMessage.id !==
        this.masterDataService.masterData.appStartMessage.id
    ) {
      showPopup = true;
    }
    if (
      showPopup &&
      this.masterDataService.masterData &&
      this.masterDataService.masterData.appStartMessage
    ) {
      this.masterDataService.masterData.appStartMessage.lastDisplay =
        currentDate;
      localStorage.setItem(
        STORAGE_KEYS.APP_START_MESSAGE,
        JSON.stringify(this.masterDataService.masterData.appStartMessage)
      );
      this.openWelcomeMessageModal();
    }
  }

  async openWelcomeMessageModal() {
    const modal = await this.modalCtrl.create({
      component: WelcomeMessageComponent,
      cssClass: "welcome-modal",
    });
    await modal.present();
  }

  async openReferralSuccessModal() {
    const modal = await this.modalCtrl.create({
      component: ReferralSuccessComponent,
    });
    await modal.present();
  }

  async showRateAppPopup(customerRating: CustomerRating) {
    const modal = await this.modalCtrl.create({
      component: RateAppComponent,
      cssClass: "modal-custom modal-rate-app",
      componentProps: { customerRating },
    });
    await modal.present();

    modal.onDidDismiss().then(this.dataService.saveUserRating);
  }

  async showUpcomingDeliveryInfoPopup() {
    const modal = await this.modalCtrl.create({
      component: UpcomingDeliveryInfoComponent,
      cssClass: UPCOMING_DELIVERY_INFO,
    });
    await modal.present();

    modal.onDidDismiss().then(this.dataService.saveUserRating);
  }

  getUserRating() {
    if (
      localStorage.getItem(STORAGE_KEYS.RATING_POPUP_VISIBILITY_DATE) &&
      moment(
        localStorage.getItem(STORAGE_KEYS.RATING_POPUP_VISIBILITY_DATE)
      ).isSame(moment(new Date()).format("MM-DD-YYYY"))
    ) {
      return;
    }
    this.homeService.customerRating().subscribe((response: HTTPResponse) => {
      if (response?.status) {
        this.customerRating = response?.data?.data;
        if (this.customerRating && this.customerRating.showRatingPopup) {
          this.customerRating.showRatingPopup = false;
          this.showRatingPopup();
        }
      }
    });
  }

  freeMembershipActivatedModal() {
    if (
      this.masterDataService.masterData?.current_membership.mid ==
        this.dataService.firebaseMasterData?.userMembership?.freeMembership &&
      this.masterDataService.masterData?.user?.first_order_date &&
      !localStorage.getItem(STORAGE_KEYS.FREE_MEMBERSHIP_POPUP)
    ) {
      let BUY_MEMBERSHIP_COPY = JSON.parse(
        JSON.stringify(FREE_MEMBERSHIP_ACTIVATED)
      );

      BUY_MEMBERSHIP_COPY.BUTTONS.CONFIRM.handler = () => {
        localStorage.setItem(STORAGE_KEYS.FREE_MEMBERSHIP_POPUP, "1");
      };

      BUY_MEMBERSHIP_COPY.META_DATA.MESSAGE =
        BUY_MEMBERSHIP_COPY.META_DATA.MESSAGE.replace(
          "{MEMBERSHIP_DAYS}",
          this.masterDataService.masterData?.current_membership.validity
            ? this.masterDataService.masterData?.current_membership.validity - 1
            : 30
        );
      BUY_MEMBERSHIP_COPY.META_DATA.MESSAGE =
        BUY_MEMBERSHIP_COPY.META_DATA.MESSAGE.replace(
          "{deliver_charge}",
          this.masterDataService.masterData?.extra_fee?.sp
        );
      this.alertService.presentAlertConfirm(
        GENERATE_CONTENT_FOR_ALERT_LAYER(BUY_MEMBERSHIP_COPY.META_DATA),
        [BUY_MEMBERSHIP_COPY.BUTTONS.CONFIRM],
        BUY_MEMBERSHIP_COPY.CSS_CLASS
      );
    }
  }

  showRatingPopup() {
    localStorage.setItem(
      STORAGE_KEYS.RATING_POPUP_VISIBILITY_DATE,
      moment().format("MM-DD-YYYY")
    );
    if (this.dataService.firebaseMasterData.showInternalAppRatingPopUp) {
      this.showRateAppPopup(this.customerRating);
    } else {
      window.cordova.plugins.AppReview.requestReview()
        .then((res) =>
          this.eventsService.trackEvents(
            EVENT_NAME_LIST.NATIVE_APP_RATING_POPUP_CLICKED,
            { ratingResponse: res }
          )
        )
        .catch((error) =>
          this.eventsService.trackEvents(
            EVENT_NAME_LIST.NATIVE_APP_RATING_POPUP_ERROR,
            { ratingResponseError: error }
          )
        );
    }
  }

  openSelfHelp() {
    this.eventsService.trackEvents(EVENT_NAME_LIST.SELF_HELP_OPEN, {
      Source: EVENT_SOURCE_LIST.HOME,
    });
    this.navCtrl.navigateForward(PROFILE_ROUTES.BASE + PROFILE_ROUTES.CONTACT);
  }

  addDataUIInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(async () => {
      await this.callRefreshToken();
      this.masterDataService.getMasterData(true, false, true, false, true, DATA_UI_EVENT_SOURCE_TYPES.DATA_UI_INTERVAL);
    }, DATA_UI_TIMER);
  }

  askTrackingPermission() {
    if (this.platform.is(IOS) && window.cordova) {
      window.cordova.exec(win, fail, "idfa", "requestPermission", []);
    }

    function win(res) {
      this.loggingService.logMessage("success", JSON.stringify(res));
    }

    function fail(res) {
      console.error(`Fail ${JSON.stringify(res)}`);
    }
  }

  masterDataSetting() {
    this.masterDataService.masterDataUpdateObs.subscribe((dataLoaded) => {
      this.getFirebaseNavigation();
      if (dataLoaded) {
        if (this.masterDataService.masterData) {
          if (this.masterDataService.masterData?.user) {
            this.getUserRating();
            this.shoppingCreditsServices.checkJiopaySegment();
            /* this.freeMembershipActivatedModal();
            this.getUserTargetMembership();
            if (!this.masterDataService.masterData?.user?.cinfo?.acceptTnc) {
             this.membershipTerm();
            } */
            if(Utilities.isApp()) {
              this.shoppingCreditsServices.preFetchHyperSdk();
            }
            /* if (
              this.masterDataService.masterData?.user?.wf &&
              this.dataService.isUserWalletFreeze
            ) {
              this.showTermUses();
              this.dataService.isUserWalletFreeze = false;
            }
            this.checkAddressPopup();
            */
            if(this.authService.isLoggedIn()){
                const appToken = localStorage.getItem(STORAGE_KEYS.FIREBASE_PUSH_TOKEN);
                if(!!appToken && !this.firebaseService.tokenStored) {
                  this.firebaseService.storeNotificationToken(appToken);
                } else if(!this.firebaseService.tokenStored) {
                  this.firebaseService.fetchFirebaseToken();
                }
            }
          }
          if (
            this.masterDataService.masterData.welcome_code &&
            this.masterDataService.masterData.welcome_code.title &&
            !this.masterDataService.masterData?.user?.throttle_status &&
            !this.masterDataService.masterData?.user?.paid &&
            this.masterDataService.masterData?.user?.society_id
          ) {
            if (
              this.dataService.societyErrorCode ===
              SIGNUP_ERRORS.UPCOMING_DELIVERY
            ) {
              // this.modalService.openModal({
              //   component: UpcomingDeliveryInfoComponent,
              //   cssClass: UPCOMING_DELIVERY_INFO,
              // });
              // this.dataService.societyErrorCode = "";
            } else if (
              this.dataService.showReferralSuccessModal &&
              this.masterDataService.masterData.welcome_code.detail &&
              this.masterDataService.masterData.welcome_code.detail.cashback
            ) {
              this.openReferralSuccessModal();
              this.dataService.showReferralSuccessModal = false;
            }
          }
        }

        if (
          this.dataService._isJioUserSubject.getValue() &&
          !this.authService.jioPreviousLogInNumber &&
          this.masterDataService.masterData &&
          this.masterDataService.masterData?.user
        ) {
          this.authService.jioPreviousLogInNumber =
            this.masterDataService.masterData.user?.phone;
          this.authService.userObj = this.masterDataService.masterData?.user;
          localStorage.setItem(
            STORAGE_KEYS.JIO_NUMBER,
            this.masterDataService.masterData.user.phone
          );
          localStorage.setItem(
            STORAGE_KEYS.USER_DATA,
            JSON.stringify(this.masterDataService.masterData.user)
          );
        }

      }
    });
    this.masterDataService.subscribeToSaveTrigger.subscribe(res => {
      if (res) {
        this.recurringService.intialRecurringCampaignObjectSet();
      }
    });
    if(this.platform.is(ANDROID)) {
      this.subscription.add(this.masterDataService.checkUPIAppAvailability.subscribe(optionsDataLoaded => {
        if (optionsDataLoaded) {
          this.shoppingCreditsServices.checkEligibleApps(); 
        }
      }))
    }
    if(!(localStorage.getItem(STORAGE_KEYS.FIRST_ATB_SUCESS))){
      this.masterDataService.firstAtbSucces = null;
    }
    if(!(localStorage.getItem(STORAGE_KEYS.LAST_NOCHECKOUT_VISIT_DATE))){
      this.masterDataService.lastCheckoutVisitDate = null;
    }
  }

  checkAddressPopup() {
    this.masterDataService.localAddressStatus =
      localStorage.getItem(STORAGE_KEYS.ADDRESS_STATUS) &&
      JSON.parse(localStorage.getItem(STORAGE_KEYS.ADDRESS_STATUS));
    if (this.masterDataService.localAddressStatus) {
      if (
        this.masterDataService.masterData?.user?.is_valid_address ===
          AddressStatus.Valid &&
        this.masterDataService.localAddressStatus ===
          AddressStatus.UnderVerification
      ) {
        this.showAddressVerified();
      }
      if (
        this.masterDataService.masterData?.user?.is_valid_address ===
          AddressStatus.Rejected &&
        this.masterDataService.localAddressStatus ===
          AddressStatus.UnderVerification
      ) {
        this.showAddressRejected();
      }
    }
    this.checkValidAddress();
  }

  showAddressVerified() {
    localStorage.setItem(
      STORAGE_KEYS.ADDRESS_STATUS,
      JSON.stringify(AddressStatus.Valid)
    );
    ADDRESS_VERIFIED_SUCCESSFULLY.BUTTONS.CLOSE.handler = noop();

    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(ADDRESS_VERIFIED_SUCCESSFULLY.META_DATA),
      [
        ADDRESS_VERIFIED_SUCCESSFULLY.BUTTONS.CLOSE,
        ADDRESS_VERIFIED_SUCCESSFULLY.BUTTONS.CONFIRM,
      ],
      ADDRESS_VERIFIED_SUCCESSFULLY.CSS_CLASS
    );
  }

  showAddressRejected() {
    localStorage.setItem(
      STORAGE_KEYS.ADDRESS_STATUS,
      JSON.stringify(AddressStatus.Rejected)
    );
    UNDER_REJECTED_ADDRESS.BUTTONS.CLOSE.handler = noop();
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(UNDER_REJECTED_ADDRESS.META_DATA),
      [
        UNDER_REJECTED_ADDRESS.BUTTONS.CLOSE,
        UNDER_REJECTED_ADDRESS.BUTTONS.CONFIRM,
      ],
      UNDER_REJECTED_ADDRESS.CSS_CLASS
    );
  }

  checkValidAddress() {
    this.masterDataService.localAddressStatus =
      localStorage.getItem(STORAGE_KEYS.ADDRESS_STATUS) &&
      JSON.parse(localStorage.getItem(STORAGE_KEYS.ADDRESS_STATUS));
    if (
      this.masterDataService.masterData?.user?.is_valid_address ===
        AddressStatus.UnderVerification ||
      this.masterDataService.masterData?.user?.is_valid_address ===
        AddressStatus.Rejected
    ) {
      this.masterDataService.isValidAddress = false;
    } else {
      this.masterDataService.isValidAddress = true;
    }
  }

  showTermUses() {
    TERMS_USES.BUTTONS.OKAY.handler = () => {
      window.open(this.dataService.firebaseMasterData.milkbasketSupportEmail);
    };
    TERMS_USES.BUTTONS.CLOSE.handler = () => {
      this.dataService.externalUrl.parameters = true;
      this.navCtrl.navigateBack(PROFILE_ROUTES.BASE + PROFILE_ROUTES.CONTACT);
    };

    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(TERMS_USES.META_DATA),
      [
        TERMS_USES.BUTTONS.OKAY,
        TERMS_USES.BUTTONS.CLOSE,
        TERMS_USES.BUTTONS.CONFIRM,
      ],
      TERMS_USES.CSS_CLASS
    );
  }

  async membershipTerm() {
    const modal = await this.modalCtrl.create({
      component: MembershipTermsAndConditionComponent,
      backdropDismiss: false,
      cssClass: "modal-bottom-sheet term-membership-modal",
    });
    await modal.present();
  }

  getUserTargetMembership() {
    this.subscription.add(
      this.homeService
        .getTargetMembership()
        .subscribe((success: HTTPResponse) => {
          if (success?.data?.status) {
            this.targetMembership = success.data.data;
            this.targetMembership.orderAmount = this.targetMembership.orderAmount >= this.targetMembership.targetAmount ? this.targetMembership.targetAmount : (Math.round((this.targetMembership.orderAmount) * 100) / 100);
            this.targetMembership.orderCount =
              this.targetMembership.orderCount >=
              this.targetMembership.targetOrder
                ? this.targetMembership.targetOrder
                : this.targetMembership.orderCount;
            const targetMembershipId = localStorage.getItem(
              STORAGE_KEYS.TARGET_MEMBERSHIP
            );
            if (
              targetMembershipId &&
              targetMembershipId !==
                `${this.targetMembership.offerMembershipId}`
            ) {
              localStorage.removeItem(STORAGE_KEYS.TARGET_MEMBERSHIP);
              localStorage.removeItem(
                STORAGE_KEYS.TARGET_MEMBERSHIP_COMPLETED_POPUP
              );
              localStorage.removeItem(
                STORAGE_KEYS.TARGET_MEMBERSHIP_EXTENDED_POPUP
              );
              localStorage.removeItem(
                STORAGE_KEYS.TARGET_MEMBERSHIP_EXPIRED_SHOWN
              );
              localStorage.removeItem(
                STORAGE_KEYS.TARGET_MEMBERSHIP_COMPLETED_LOTTIE
              );
            }
            if (
              this.targetMembership.isExpired &&
              localStorage.getItem(
                STORAGE_KEYS.TARGET_MEMBERSHIP_EXPIRED_SHOWN
              ) === "1"
            ) {
              this.dataService.targetMembership.next(null);
            } else {
              this.targetMembership.amountDiff =
                Math.round(
                  (this.targetMembership.targetAmount -
                    this.targetMembership.orderAmount) *
                    100
                ) / 100;
              this.dataService.targetMembership.next(this.targetMembership);
              if (
                this.targetMembership.isTargetCompleted &&
                !this.targetMembership.isMembershipExtended
              ) {
                this.targetMembershipCompletedModal();
              } else if (this.targetMembership.isMembershipExtended) {
                this.targetMembershipExtendedModal(this.targetMembership);
                this.dataService.targetMembership.next(null);
              }
            }
          } else {
            this.dataService.targetMembership.next(null);
          }
        })
    );
  }

  targetMembershipCompletedModal() {
    if (!localStorage.getItem(STORAGE_KEYS.TARGET_MEMBERSHIP_COMPLETED_POPUP)) {
      let TARGET_MEMBERSHIP_COPY = JSON.parse(
        JSON.stringify(TARGET_MEMBERSHIP_COMPLETED)
      );

      TARGET_MEMBERSHIP_COPY.BUTTONS.CONFIRM.handler = () => {
        localStorage.setItem(
          STORAGE_KEYS.TARGET_MEMBERSHIP_COMPLETED_POPUP,
          "1"
        );
        localStorage.setItem(
          STORAGE_KEYS.TARGET_MEMBERSHIP,
          `${this.targetMembership.offerMembershipId}`
        );
      };

      this.alertService.presentAlertConfirm(
        GENERATE_CONTENT_FOR_ALERT_LAYER(TARGET_MEMBERSHIP_COPY.META_DATA),
        [TARGET_MEMBERSHIP_COPY.BUTTONS.CONFIRM],
        TARGET_MEMBERSHIP_COPY.CSS_CLASS
      );
    }
  }

  targetMembershipExtendedModal(targetMembershipValue) {
    if (!localStorage.getItem(STORAGE_KEYS.TARGET_MEMBERSHIP_EXTENDED_POPUP)) {
      let TARGET_MEMBERSHIP_COPY = JSON.parse(
        JSON.stringify(TARGET_MEMBERSHIP_EXTENDED)
      );
      let targetMembershipData = JSON.parse(
        JSON.stringify(targetMembershipValue)
      );

      TARGET_MEMBERSHIP_COPY.BUTTONS.CONFIRM.handler = () => {
        localStorage.setItem(
          STORAGE_KEYS.TARGET_MEMBERSHIP_COMPLETED_POPUP,
          "1"
        );
        localStorage.setItem(
          STORAGE_KEYS.TARGET_MEMBERSHIP_EXTENDED_POPUP,
          "1"
        );
        localStorage.setItem(
          STORAGE_KEYS.TARGET_MEMBERSHIP,
          `${targetMembershipData.offerMembershipId}`
        );
      };

      TARGET_MEMBERSHIP_COPY.META_DATA.MESSAGE =
        TARGET_MEMBERSHIP_COPY.META_DATA.MESSAGE.replaceAll(
          "{numExtensionDays}",
          targetMembershipData.numExtensionDays
        );
      this.alertService.presentAlertConfirm(
        GENERATE_CONTENT_FOR_ALERT_LAYER(TARGET_MEMBERSHIP_COPY.META_DATA),
        [TARGET_MEMBERSHIP_COPY.BUTTONS.CONFIRM],
        TARGET_MEMBERSHIP_COPY.CSS_CLASS
      );
    }
  }

  getFirebaseNavigation() {
    this.firebaseDynamicLinks.onDynamicLink().subscribe(
      (res: any) => {
        if (res) {
          this.deepLinkHandler(res);
        }
      },
      (err: any) => {
        // error block here
      }
    );
  }

  appClosedDynamicLink() {
    this.firebaseDynamicLinks.getDynamicLink().then(
      (res: any) => {
        if (res) {
          this.deepLinkHandler(res);
        }
      },
      (err: any) => {
        // error block here
      }
    );
  }

  deepLinkHandler(data) {
    if (
      this.dataService.firebaseMasterData?.festiveSeason?.splash?.visibility &&
      Utilities.isApp()
    ) {
      setTimeout(
        () => {
          this.deepLinkService.deepLinkHandler(data);
        },
        this.platform.is(ANDROID) ? 3500 : 2100
      );
    } else {
      this.deepLinkService.deepLinkHandler(data);
    }
  }

  getApp() {
    return this;
  }

  async callRefreshToken() {
    if (
      this.httpService.checkWhenToRefreshToken() &&
      this.authService.isLoggedIn() &&
      window.navigator.onLine
    ) {
      return await this.httpService.updateToken();
    } else {
      return Promise.resolve(true);
    }
  }

  bottomNavStatusChanged(event) {
    this.bottomNavVisible = !!event;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
