//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Input, Output } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { SheetState } from 'ion-bottom-sheet';
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbpc-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
})
export class BottomSheetComponent {

  @Input() sheetState = SheetState.Docked;
  @Input() dockedHeight;
  @Input() minHeight;
  @Input() disableDrag: boolean = false;
  @Output() sheetStateChange = new EventEmitter();
  topDistance = 44;
  enableScrollContentOnlyOnTop = 'true';
  subscription: Subscription;
  useSmoothScrolling = true;
  constructor(
    public dataService: DataService) { }

  sheetChange(event) {
    this.sheetState = event;
    this.sheetStateChange.emit(event);
  }

}
