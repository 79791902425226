export class PauseSchedule {
    startDate: string;
    endDate: string;

    setProductPauseSchedule(subscriptionProductOrderApi) {
        if (!subscriptionProductOrderApi.pausePeriod) return null;

        if (
            subscriptionProductOrderApi.pausePeriod.startDate &&
            subscriptionProductOrderApi.pausePeriod.endDate
        ) {
            this.startDate = subscriptionProductOrderApi.pausePeriod.startDate;
            this.endDate = subscriptionProductOrderApi.pausePeriod.endDate;
            return this;
        }
        return null;
    }
}
