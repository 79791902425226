export class ProductBundle {
    __typename = 'ProductBundle';
    active: boolean;

    setProductBundleFromProductApi(productApi) {
        if (productApi.bun_products && productApi.bun_products?.length) {
            this.active = true;
            return this;
        }
        return null;
    }
}
