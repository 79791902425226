<div class="top-fixed">
  <div class="fixed-bottom-sheet">
    <mbs-bottom-sheet-header [component]="viewTypes.SelectTower">
      <ng-container *ngIf="authService.userData?.societyObj?.societyType === societyType.Locality ; else towerSelection">
        Add Address
      </ng-container>
      <ng-template #towerSelection>
        <div class="tower-head d-flex ion-space-between ion-align-items-center">
          <div class="sub-heading">
            <h1 text-ellipsis-ml>{{authService.userData.societyObj.name}}</h1>
            <p text-ellipsis-ml>{{authService.userData.societyObj.location}}, {{ authService?.userData?.cityObj?.name }}
            </p>
          </div>
          <ion-button (click)="editSociety()" class="no-ripple-effect ion-no-padding" fill="clear">
            <ion-icon class="edit-icon" slot="icon-only"></ion-icon>
          </ion-button>
        </div>
      </ng-template>
    </mbs-bottom-sheet-header>
  </div>
</div>
<!-- <mbf-search class="delivery-search" ></mbf-search> -->
<ion-content class="ctn-gt-space-p">
  <ng-container *ngIf="authService.userData?.societyObj?.societyType === societyType.Locality ;else tower">
    <form class="form pb-0" #addLocalityAddressForm="ngForm">
      <!--New design starts here-->
      <div class="f-body form-content">
        <ion-row>
          <ion-col size="6">
            <div class="f-group margin-bottom-large">
              <ion-label class="f-w-b">Flat No. *</ion-label>
              <ion-item class="ion-no-padding" lines="none">
                <ion-input type="text" id="unit" name="unit" #unit (ngModelChange)="removeSpace(Input.LOCALITY_HOUSE, unit.value, $event, 15)" [(ngModel)]="userSignupDetails.houseNo" ngControl="unit" maxlength="15" (ionFocus)="onFocus()" required placeholder="eg. 86"></ion-input>
              </ion-item>
            </div>
          </ion-col>
          <ion-col size="6">
            <div class="f-group margin-bottom-large">
              <ion-label class="f-w-b">Floor No. *</ion-label>
              <ion-item class="ion-no-padding has-down-arrow" lines="none" [ngClass]="{ 'item-has-focus' : showFloorList}">
                <ion-input (ngModelChange)="closeDropDown()" ngControl="address_floor" name="address_floor" #address_floor (ionFocus)="onFocus()" type="text" [(ngModel)]="userSignupDetails.localAddressDetails.address_floor" maxlength="15" (keyup)="removeSpace(Input.FLOOR, address_floor.value, $event, 15)" required placeholder="eg. 1st"></ion-input>
                <span class="down-arrow" (click)="(showFloorList = !showFloorList)"></span>
              </ion-item>
              <ul class="dropdown-list select-tower" [ngClass]="{ open: !showFloorList }" *ngIf="showFloorList">
                <ng-container *ngFor="let floor of floorList">
                  <li (click)="setFloor(floor)">{{floor.name}}</li>
                </ng-container>
              </ul>
            </div>
          </ion-col>
        </ion-row>
        <div class="f-group margin-bottom-large">
          <ion-label class="f-w-b">Apartment / Building Name/ Plot No. </ion-label>
          <ion-item class="ion-no-padding" lines="none">
            <ion-input type="text" ngControl="buildingName" name="buildingName" #buildingName  maxlength="50" [(ngModel)]="userSignupDetails.localAddressDetails.buildingName" placeholder="eg. Golden leaf" (ngModelChange)="removeSpace(Input.BUILDING_NAME, buildingName.value, $event, 50)"></ion-input>
          </ion-item>
        </div>
        <div class="f-group margin-bottom-large">
          <ion-label class="f-w-b">Street Name / Locality / Sector *</ion-label>
          <ion-item class="ion-no-padding" lines="none">
            <ion-input type="text" ngControl="streetName" name="streetName" #streetName  maxlength="50" required [(ngModel)]="userSignupDetails.localAddressDetails.streetName" (ngModelChange)="removeSpace(Input.STREET, streetName.value, $event, 50)" placeholder="eg. sector-42"></ion-input>
          </ion-item>
        </div>
        <div class="f-group margin-bottom-large">
          <ion-label class="f-w-b">Landmark (optional)</ion-label>
          <ion-item class="ion-no-padding" lines="none">
            <ion-input (ionFocus)="setFocus()" (ionBlur)="removeFocus()" ngControl="landmark" name="landmark" #landmark  maxlength="50" type="text" [(ngModel)]="userSignupDetails.localAddressDetails.landmark" (ngModelChange)="removeSpace(Input.LANDMARK, landmark.value, $event, 50)" placeholder="eg. near mother dairy"></ion-input>
          </ion-item>
        </div>
        <ion-button class="t-t-n margin-top-large margin-bottom-extra-big-large" fill="solid" size="large" shape="round"
          expand="block" [disabled]="!addLocalityAddressForm.valid" (click)="submitLocalAddress()">Add & Continue</ion-button>
          <div *ngIf="hasFocus" style="height:200px"></div>
      </div>
      <!--New design ends here-->
    </form>
  </ng-container>
  <ng-template #tower>
    <form class="form pb-0" #signupDetailsForm="ngForm">
      <div class="f-body">
        <div class="details-section f-group">
          <div class="form-content margin-bottom-extra-big-large margin-top-0">
            <ion-label class="f-w-b">{{authService.userData?.societyObj?.societyType === societyType.RowHousing ?'Block / Lane*':'Tower / Block*'}}</ion-label>
            <ng-container *ngIf="isEnableTowerRequest;else towerEnter">
              <ion-item class="ion-no-padding tower-no ion-invalid" lines="none" (click)="towerSelections()">
                <input class="towerInput" ngControl="towerName" #towerName name="towerName" required disabled="true"
                  (keyup)="searchTower(towerName.value)" [(ngModel)]="userSignupDetails.towerDetails.name" type="text"
                  placeholder="eg. Tower 1, Block A" />
                <span class="down-arrow right-position"></span>
              </ion-item>
            </ng-container>
            <ng-template #towerEnter>
              <ion-item class="ion-no-padding tower-no ion-invalid" lines="none"
              [ngClass]="{ 'item-has-focus' : showTowerList}">
              <input class="towerInput" ngControl="towerName" #towerName (focus)="showTowerList = true; onFocus()" (click)="$event.stopPropagation()" name="towerName" required
                (keyup)="searchTower(towerName.value)" [(ngModel)]="userSignupDetails.towerDetails.name" type="text"
                placeholder="eg. Tower 1, Block A" />
              <span class="down-arrow" (click)="$event.stopPropagation();(showTowerList = !showTowerList)"></span>
            </ion-item>
            <ul class="dropdown-list" [ngClass]="{ open: !showTowerList }" *ngIf="showTowerList">
              <ng-container *ngFor="let tower of filteredTowerList">
                <li (click)="setTower(tower)">{{tower.name}}</li>
              </ng-container>
            </ul>
            <ion-text class="help-text">
              <span *ngIf="isManualInput" class="info f-w-m">Not Listed : Continue with
                {{userSignupDetails.towerDetails.name}}</span>
              <span color="danger" class="f-w-m">Please enter correct Flat No.</span>
            </ion-text>
            </ng-template>
          </div>
          <div class="form-content margin-bottom-extra-big-large">
            <ion-label position="floating" class="f-w-b"> Flat / House Number.*</ion-label>
            <ion-item class="ion-no-padding" lines="none">
              <ion-input (ionFocus)="onFocus()" type="text" #houseNo (ngModelChange)="removeSpace( Input.HOUSE_NO, houseNo.value, $event, 10 )"
                name="houseNo" maxlength="10" ngControl="houseNo" required [(ngModel)]="userSignupDetails.houseNo"
                placeholder="eg. 41">
              </ion-input>
            </ion-item>
          </div>
        </div>
        <ion-button *ngIf="isEdit; else newUser" (click)="triggerUpdateAddress()" margin-top-extra-big-large
          margin-bottom-extra-big-large fill="solid" size="large" [disabled]="!signupDetailsForm.valid" shape="round"
          expand="block">Save & Continue</ion-button>
        <ng-template #newUser>
          <ion-button (click)="submitInfo()" class="t-t-n" margin-top-extra-big-large margin-bottom-extra-big-large
            fill="solid" size="large" [disabled]="!signupDetailsForm.valid" shape="round" expand="block">Continue to
            Home</ion-button>
        </ng-template>
      </div>

    </form>
  </ng-template>
</ion-content>