<ion-list lines="none" class="ion-no-padding ion-list-image-card ion-list-grid layout-spacer">
  <ion-list-header class="ion-list-header" *ngIf="data?.name">
    <ion-row class="ion-justify-content-between full-width ion-align-items-center">
      <div>
        <span class="padding-right-small">{{ data.name }}</span>
        <p *ngIf="data?.description">{{ data.description }}</p>
      </div> 
    </ion-row>
  </ion-list-header>
  <div class="swiper-wrap" *ngIf="data?.banners?.length" 
  inViewport
  [inViewportOptions]="{ threshold: [0, 0.65], partial: true }"
  (inViewportAction)="onIntersection($event)">
    <swiper 
      [pagination]="props?.source !== homeBannersSource && (data.banners.length > 1)"
      [autoplay]="true"
      [spaceBetween]="12"
      [cssMode]="true"
      [centeredSlides]="true"
      (swiper)="swiperInit($event)"   
      (slideChange)="slideChanged($event)"
      class="collection-swiper">
      <ng-template *ngFor="let banner of data.banners | slice:0:bannerMaxLimit; let bannerIndex = index;" swiperSlide>
        <mbs-social-sharing-button *ngIf="banner.isShare" [metaData]="banner" [componentType]="bannerComponent"></mbs-social-sharing-button>
        <p *ngIf="banner.contentOnBanner" (click)="navigate($event, bannerIndex)" class="social-proof" [innerHTML]="banner.contentOnBanner"></p>
        <app-img-on-demand (click)="navigate($event, bannerIndex)" [actualImgSrc]="bannerFilePath + banner?.img"
          [defaultImgSrc]="'assets/img/default-banner.png'"
          [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER" [imageName]="banner?.img"
          alt_image="img_banner_{{banner?.img}}"
          [useImageOnDemand]="false">
        </app-img-on-demand>
      </ng-template>
    </swiper>
  </div>
</ion-list>
