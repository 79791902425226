<ion-list lines="none" class="ion-no-padding ion-list-image-card ion-list-grid gvc layout-spacer">
<ion-list-header class="ion-list-header" *ngIf="data?.name">
  <ion-row class="ion-justify-content-between full-width ion-align-items-center">
    <div>
      <span class="padding-right-small">{{ data.name }}</span>
      <p *ngIf="data?.description">{{ data.description }}</p>
    </div> 
  </ion-row>
</ion-list-header>
<ion-item class="ion-no-padding" mode="md">
  <ion-grid class="collection-grid" 
  [ngClass]="{
    'auto-size-img': data?.customizeCollection == 1,
    'ignore-padding': data?.customizeCollection == 2,
    'auto-size-img-n-ignore-padding': data?.customizeCollection == 3
  }" 
  #grid>
    <div class="d-flex full-width ion-row" *ngFor="let row of data?.table?.rows">
      <!-- We are calculating the height and width of the col as based on the width of the #grid(ViewChild)-->
      <ion-col *ngFor="let column of row.columns" [ngStyle]="{
height:
  column.height === column.width
    ? ((grid.el.offsetWidth - 24) * column.height) / 100 + 'px'
    : ((grid.el.offsetWidth - 24) * column.height) / 100 + 'px',
'max-width':
((grid.el.offsetWidth - 24) * column.width) /  100 + 'px',
'flex-basis':
((grid.el.offsetWidth - 24) * column.width) /  100 + 'px'
}" (click)="navigateToCollectionList(column)">
        <ion-card class="ion-no-margin ion-card-image">
          <p *ngIf="column.cashBack?.text" class="offer-strip">
            {{ column.cashBack.text }}
          </p>
          <mbs-social-sharing-button *ngIf="dataService.firebaseMasterData.whatsAppSharingCollectionMetadata[data.id] &&
        dataService.firebaseMasterData.whatsAppSharingCollectionMetadata[data.id].isWShareEnabled" [componentType]="props?.collectionComponent" [metaData]="data"></mbs-social-sharing-button>
          <div class="ion-no-padding img-holder" *ngIf="column.images?.leafBanner?.image">
            <app-img-on-demand [actualImgSrc]="bannerFilePath + column.images.leafBanner.image" [defaultImgSrc]="'assets/img/default-banner.png'"
              alt_image="img_banner_{{column.images.leafBanner.image}}"
              [showDefaultImage]="showDefaultImage" [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER" [imageName]="column.images.leafBanner.image">
            </app-img-on-demand>
            <img *ngIf="column?.images?.overlapImage" class="overlay-image" [src]="bannerFilePath + column.images.overlapImage" alt="img_banner_{{column.images.overlapImage}}" />
          </div>
          <ion-card-header class="ion-no-padding" *ngIf="column.title?.text">
            <ion-card-title [ngStyle]="{'color':column.title?.color}">{{ column.title.text }}
            </ion-card-title>
          </ion-card-header>
          <!-- <ng-template #showSeeAll>
  <ion-button (click)="navigateToCollection()"
    class="ion-text-uppercase ion-no-padding button-see-all no-ripple-effect" type="button" mode="md"
    fill="clear" color="light" size="small">{{ column?.title.text }}</ion-button>
</ng-template> -->
        </ion-card>
      </ion-col>
    </div>
  </ion-grid>
</ion-item>
</ion-list>