
  <ng-container *ngIf="!isOpenOfferInfo">
    <ion-header class="sticky-header">
      <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium">
        <mbs-bottom-sheet-header 
        class="ion-promo-header" 
        (closeBottomSheet)="closePromoCodeBottomSheet()"
        [backType]="backAction"
        >Promo Code</mbs-bottom-sheet-header>
        <mbs-promo-code-input [isModal]="isModal" (errorData)="errorInviteCode($event.isError,$event.errorMessage)"></mbs-promo-code-input>
        <div class="promo-apply-code">
          <ion-text class="padding-top-medium padding-bottom-extra-medium">Available Promo Codes ({{ dataService.inviteCodes && dataService.inviteCodes.length > 0 ? dataService.inviteCodes.length : 0 }})</ion-text>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ctn-gt-space-p ion-content-scroll">
    <div class="promo-apply-code">
      <div class="apply-coupon promo-ticket">
        <ion-row 
        class="d-flex ion-align-items-center circle"
        *ngFor="let promoCode of dataService.inviteCodes"> 
        <ion-col size="12" class="padding-bottom-base ion-no-padding">
           <div class="coupon-code d-flex ion-justify-content-between">
            <span text-ellipsis-ml class="ion-text-uppercase f-w-m d-flex ion-align-items-center">{{ promoCode.name }}</span>

          </div>
        </ion-col>
         <ion-row class="ion-no-margin ion-no-padding">
          <ion-col size="9" class="horizontal-divider ion-no-padding promo-listing">
              <p *ngIf="promoCode?.short_description"><small>{{ promoCode.short_description }}</small></p>
              
          </ion-col>
          <ion-col size="3" class="d-flex ion-justify-content-end ion-no-padding promo-listing">
            <ion-button class="apply-btn ion-no-padding"  fill="clear" (click)="applyInviteCode(promoCode.name)">Apply</ion-button>
          </ion-col>
         </ion-row>
         <ion-row>
          <ion-col class="margin-top-extra-small ion-no-padding promo-listing">
              <ion-button class="text-size-sm f-w-s-b promo-know-more" (click)="openOfferDetail(promoCode)">
                Know More
                <ion-icon slot="end" color="primary" class="icon-arrow-right icons-property"></ion-icon>
              </ion-button>
          </ion-col>
         </ion-row>
        </ion-row>
      </div>
    </div>
  </ion-content>
  </ng-container>

  <ng-container *ngIf="isOpenOfferInfo">
    <ion-header class="sticky-header ">
      <ion-toolbar class="modal-bottom-sheet unset-height modal-handle padding-top-medium">
      </ion-toolbar>
      </ion-header>
    <ion-content class="ctn-gt-space-p">
        <ion-button (click)="closeOfferDetail()" class="no-ripple-effect close" fill="clear">
          <ion-icon slot="icon-only" class="icon-close icons-property icon-md" color="dark"></ion-icon>
        </ion-button>
    <mbs-promo-code-input [hidden]="true" [isModal]="isModal" (errorData)="errorInviteCode($event.isError,$event.errorMessage)"></mbs-promo-code-input>
    <div class="promo-detail ion-text-center">
      <img alt="select offer" [src]="selectedOffer.imgUrl" />
      <h2>{{ selectedOfferDetail.short_description }}</h2>
      <div class="coupon-code ion-justify-content-center ion-align-items-center">
       <div class="d-flex ion-align-items-center">
        <p class="f-w-b ion-text-uppercase">{{ selectedOfferDetail.name }}</p>
        <ion-buttons>
          <ion-button (click)="copyCode()" >
            <ion-icon class="icon-copy icon-md icons-property" color="primary" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
       </div>
      </div>
      <p class="valid-date f-w-n">
        Valid till 
        <span class="f-w-b">{{ replaceDate(selectedOfferDetail.validity_date) | date:'d LLL yyyy'}}</span>
      </p>
    </div>
    <div class="promo-list">
      <ion-text *ngIf="isError" class="help-text margin-bottom-0"> 
        <span class="error">{{ errorMessage }}</span>
      </ion-text>
      <h4 class="f-w-b">{{ selectedOffer.termsAndCondition }}</h4>
      <ul>
        <li *ngIf="selectedOfferDetail?.description"><small [innerHTML]="selectedOfferDetail?.description"></small></li>
      </ul>
      <div class="bottom-btn ion-text-center margin-bottom-medium">
        <ion-button margin-top-medium margin-bottom-large fill="solid" 
        size="large" shape="round" expand="full"
        (click)="applyInviteCode(selectedOfferDetail.name)"
        >Apply
        </ion-button>
      </div>
    </div>
  </ion-content>
  </ng-container>

