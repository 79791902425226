//#region Imports

//#region Angular Imports
import { Component, Input, OnInit } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import * as moment from 'moment';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { ScheduleType } from '@Globals/constants/listing/enums';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mb-schedule-product',
  templateUrl: './schedule-product.component.html',
  styleUrls: ['./schedule-product.component.scss']
})
export class ScheduleProductComponent implements OnInit {
  @Input() productDetails;
  @Input() selectedDate;
  @Input() componentType;
  constructor(private modalCtrl: ModalController) { }
  body = document.getElementsByTagName('body')[0];
  preOrderScheduleType = ScheduleType.Pre_Order;
  startDateDropDown;
  selectedStartDate;
  showList = true;
  count = 0;
  counterQuantity = 1;
  ngOnInit() {
    if (this.componentType === this.preOrderScheduleType) {
      let dateIndex: number;
      this.startDateDropDown = [];
      for (dateIndex = 0; dateIndex <= moment(this.productDetails.endDate).diff(moment(this.productDetails.startDate), 'days'); dateIndex++) {
        const tempDate = moment(this.productDetails.startDate).add(dateIndex, 'days');
        this.startDateDropDown.push(
          { date: tempDate,isLimitExceeded: ((this.productDetails.limitExceededDates.indexOf(tempDate.format('YYYY-MM-DD')) > -1))}
        );
      }
      this.selectedStartDate = (this.startDateDropDown.find((dateObj) => {
        return !dateObj.isLimitExceeded;
      })).date;
    } else {
      this.startDateDropDown = Array(7)
        .fill(1)
        .map((val, index) => moment().add(val + index + 1, 'days'));
        this.selectedStartDate = this.startDateDropDown[0];
    }
  }
  closeModal(dataToSend = null) {
    this.body.classList.remove('modal-full-height');
    this.modalCtrl.dismiss(dataToSend);
  }
  counterChange(event) {
    event.counterSubscriber.next(true);
    this.counterQuantity = event.count;
  }
  scheduleOrder() {
    this.closeModal({
      date: moment(this.selectedDate || this.selectedStartDate).format('YYYY-MM-DD'),
      product_id: this.productDetails.product_id,
      quantity: this.counterQuantity
    });
  }
  addAndRemoveCustomClass() {
    if (!this.showList) {
      this.body.classList.add('modal-full-height');
    } else {
      this.body.classList.remove('modal-full-height');
    }
  }
  dateSelected(selectedDate){
    this.selectedStartDate = selectedDate;
    this.showList = !this.showList;
    this.addAndRemoveCustomClass()
  }
}
