//#region Imports

//#region Angular Imports
import { Component, OnInit, Input, OnDestroy } from '@angular/core'; 
//#endregion Angular Imports

//#region Ionic Imports
import { NavController, ModalController } from '@ionic/angular';
import { HTTPResponse } from '@ionic-native/http'; 
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { JuspayService } from '@App/shared/providers/juspay/juspay.service';
import { ModalService } from '@Globals/providers/modal.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_SOURCES } from '@Globals/constants/listing/constants';
import { AutoDebitStatus, SEGMENT_TYPES } from '@Globals/constants/listing/enums';
import { PAYMENT_ROUTES } from '@Globals/constants/payment/routes'; 
import { AutoDebitBanner } from '@Globals/interfaces/listing/interfaces';

import { Utilities } from '@Globals/classes/utilities';
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbf-auto-pay',
  templateUrl: './auto-pay.component.html',
  styleUrls: ['./auto-pay.component.scss'],
})
export class AutoPayComponent extends DefaultComponent {
  autoDebitStatus = AutoDebitStatus;
  @Input() getStatusOfAutoDebit: any;
  @Input() lowBalancePopUp = false;
  @Input() creditExhausted = false;
  autoDebitBanner : Array<AutoDebitBanner> = [];
  subscription : Subscription = new Subscription();
  data = {};
  constructor(private navCtrl: NavController, public dataService: DataService, private juspayService: JuspayService, private modalService: ModalService, private masterDataService: MasterDataService) {
    super();
  }
  mbOnInit() {
    const userIdEndsWith = this.masterDataService.masterData?.user?.id % 10;
    switch (userIdEndsWith) {
      case 1:
      case 2:
        this.autoDebitBanner = this.dataService.firebaseMasterData.auto_debit_banners.worry_free;
        break;
      case 3:
      case 4:
        this.autoDebitBanner = this.dataService.firebaseMasterData.auto_debit_banners.with_or_without;
        break;
      case 5:
      case 6:
        this.autoDebitBanner = this.dataService.firebaseMasterData.auto_debit_banners.social_proof;
        break;
      default:
        this.autoDebitBanner = this.dataService.firebaseMasterData.auto_debit_banners.social_proof.concat(this.dataService.firebaseMasterData.auto_debit_banners.with_or_without);
        break;
    }
    if (this.autoDebitBanner[0].contentOnBanner) {
      this.autoDebitBanner[0].contentOnBanner = Utilities.replaceKeysWithValues(this.autoDebitBanner[0].contentOnBanner,{ autoDebitEnabledCustomers: this.masterDataService.masterData?.autoDebitEnabledCustomers});
    }
    this.data = {
      banners : this.autoDebitBanner || [],
      source : 'auto-pay'
    };
    this.getStatusOfAutoDebit && this.getStatusOfAutoDebit.subscribe(() => {
      if (this.masterDataService.masterData?.user?.paid) {
        let custSegmentsSub = this.masterDataService.getCustomerSegmentsGroupBy();
        if(custSegmentsSub) {
          this.subscription.add(
            custSegmentsSub.subscribe((segments:any[])=>{
               if(segments && Object.keys(segments).length) {
                 if(segments[SEGMENT_TYPES.AUTO_DEBIT_SEGMENT]) {
                   this.getAutoDebit(); 
                 }
               }
             })
          );
        }
      } else {
        this.dataService.autoDebitUserData = {};
      }
    });
  }

  getAutoDebit() {
    this.juspayService.getAutoDebit().subscribe((success: HTTPResponse) => {
      if (success.data.status && success.data.data) {
        this.dataService.autoDebitUserData = success.data.data;
        if (this.lowBalancePopUp) {
          if (this.creditExhausted) {
            Utilities.getElementsByTagName('body').classList.add(
              'auto-debit-credit-exhausted'
            );
          }
          if (
            success.data.data.status === this.autoDebitStatus.New
            || success.data.data.status === this.autoDebitStatus.InActive
          ) {
            Utilities.getElementsByTagName('body').classList.add(
              'has-auto-debit'
            );
          }
        }

      }
    });
  }

  navigate() {
    if (this.dataService.autoDebitUserData.status !== this.autoDebitStatus.ActivationPending) {
      if (this.lowBalancePopUp) {
        this.modalService.dismissTopModal((topModal, modalCtrlReference: ModalController) => {
          if (topModal) {
            modalCtrlReference.dismiss();
            this.navigateToAutoDebit();
          }
        });
      } else {
        this.navigateToAutoDebit();
      }

    }
  }
  navigateToAutoDebit() {
    let url = PAYMENT_ROUTES.BASE +
      PAYMENT_ROUTES.AUTO_DEBIT;
    if (this.lowBalancePopUp) {
      url = `${url}/${EVENT_SOURCES.ATB_ERROR}`;
    }
    this.navCtrl.navigateForward(
      url
    );
  }
  mbOnDestroy() {
    Utilities.getElementsByTagName('body').classList.remove(
      'has-auto-debit'
    );
    Utilities.getElementsByTagName('body').classList.remove(
      'auto-debit-credit-exhausted'
    );
  }
}
