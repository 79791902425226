import { ProductAssets } from './assets/product-assets';

export class ProductTag {
    __typename = 'ProductTag';
    title: string;
    color: string;
    assets?: ProductAssets; // was offerImage

    setTagFromApi(productApi) {
        if (!productApi.title) {
            return null;
        }
        this.title = productApi.title;
        this.color =
            !productApi.oos && !productApi.forcedOos && productApi.highlightColor
                ? `#${productApi.highlightColor as string}`
                : '';

        const assets = new ProductAssets();
        this.assets = assets.setTagAssetsFromApi(productApi);

        return this;
    }
}
