//#region Imports

//#region Angular Imports
import { Injectable } from "@angular/core";
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { Observable } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { HttpService } from '@Globals/providers/http.service';
import { AuthService } from '@Globals/providers/auth.service';
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';

//#endregion Providers Imports

//#region Other Imports
import { LISTING_APIS } from '@Globals/constants/listing/apis';
import { CollectionDisplayType } from "@Globals/constants/listing/enums";
//#endregion Other Imports

//#endregion Imports

@Injectable()
export class CollectionService {
  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private masterDataService: MasterDataService,
    public dataService: DataService
  ) { }
  getListProduct(id, bannerId): Observable<object> {
    bannerId = bannerId ? bannerId : '';
    return this.httpService.get(`${LISTING_APIS.COLLECTION_PLP}/${id}/${LISTING_APIS.COLLECTION_PLP_PRODUCTS}?city_id=${(this.masterDataService.masterData && this.masterDataService.masterData.user &&
      this.masterDataService.masterData.user.city_id) ||
      (this.authService.userData && this.authService.userData?.cityObj?.id) ||
      1}&bannerId=${bannerId}`);
  }
  getAllCollectionPlp(dataToSend): Observable<object> {
    return this.httpService.post(LISTING_APIS.GET_ALL_COLLECTION_PLP, dataToSend);
  }

  getLinkedCollections(collectionId): Observable<object> {
    return this.httpService.get(`${LISTING_APIS.COLLECTION_RELATED}${collectionId}`, { hideLoader: true }
    );
  }

  getAllBrandProducts(dataToSend): Observable<object> {
    return this.httpService.post(LISTING_APIS.BRAND_ALL_PRODUCT, dataToSend);
  }

  getAllLaunchedProducts(): Observable<object> {

    return this.httpService.get(`${LISTING_APIS.ALL_PRODUCT_LAUNCH}?cityId=${
      (this.authService.userData && this.authService.userData.cityObj && this.authService.userData.cityObj.id) ||
      1}&hubId=${this.masterDataService.masterData && this.masterDataService.userHub}`, { hideLoader: true })
  }

  getLocationTypeCollection(locationType, segmentIds, hideLoader = true): Observable<object> {
      const dataToSend = {
        segmentsIds: segmentIds,
        locationType
      };
      hideLoader && (dataToSend['hideLoader'] = true);
      return this.httpService.post(LISTING_APIS.COLLECTION_LOCATION_TYPE, dataToSend);
  }

  getCollection(): Observable<object> {
    return this.httpService.get(`${LISTING_APIS.COLLECTION_LISTING}?city_id=${(this.masterDataService.masterData && this.masterDataService.masterData.user &&
      this.masterDataService.masterData?.user?.city_id) ||
      (this.authService.userData && this.authService.userData.cityObj && this.authService.userData.cityObj.id) ||
      1}`, { hideLoader: true });
  }

  getCollections(dataToSend): Observable<object> {
    dataToSend.hideLoader = true;
    return this.httpService.post(LISTING_APIS.COLLECTION_LOCATION_TYPE, dataToSend);
  }

  getCollectionById(collectionId: string): Observable<object> {
    return this.httpService.get(`${LISTING_APIS.COLLECTION_BY_ID}/${collectionId}`, { hideLoader: true });
  }

  likeCollection(leafId, count): Observable<object> {
    return this.httpService.post(`${LISTING_APIS.LIKE_COLLECTION}/${leafId}/${count}`, {hideLoader: true});
  }

  getBrandLaunchData(): Observable<object> {
    return this.httpService.get(`${LISTING_APIS.BRAND_LAUNCH}?hubId=${this.masterDataService.userHub}&cityId=${(this.authService.userData.cityObj ? this.authService.userData.cityObj.id : '1')}`,
      {
        hideLoader: true
      });
  }

  getSponsoredListData(listId): Observable<object> {
    return this.httpService.get(`${LISTING_APIS.SPONSORED_ALL_PRODUCTS}?hubId=${this.masterDataService.userHub}&cityId=${(this.authService.userData.cityObj ? this.authService.userData.cityObj.id : '1')}&listId=${listId}`);
  }

  getProductLaunchData(): Observable<object> {
    return this.httpService.get(`${LISTING_APIS.PRODUCT_LAUNCH}?hubId=${this.masterDataService.userHub}&cityId=${(this.authService.userData.cityObj ? this.authService.userData.cityObj.id : '1')}`,
      {
        hideLoader: true
      });
  }

  ifCollectionIsLiked (leafId) {
    if (this.dataService.likedCollectionsList !== null && this.dataService.likedCollectionsList.length > 0) {
      const leafIdFound = this.dataService.likedCollectionsList.find(id => +id === +leafId);
      if (leafIdFound) {
        return true;
      } else {
        return false;
      }
    }
  }

  getOnePagerById(onePagerId: number): Observable<object> {
    return this.httpService.get(`${LISTING_APIS.ONE_PAGER_BY_ID}/${onePagerId}`, { hideLoader: true });
  }

  recommendationCollection(collection){
    return(collection.table.displayType === CollectionDisplayType.RecommendationWidgetProductListing && (collection.metaData.cells[0].image.leafBanner.listId === 0 || collection.metaData.cells[0].image.leafBanner.listId === 'null' || !collection.metaData.cells[0].image.leafBanner.listId));
  }

  dealsOfTheDayProductList(dataToSend){   
    dataToSend['hideLoader'] = true;
    return  this.httpService.post(LISTING_APIS.DEALS_OF_THE_DAY, dataToSend);
  }
  expireCollection(dataToSend,id){   
    dataToSend['hideLoader'] = true;
    return  this.httpService.put(`${LISTING_APIS.COLLECTION_EXPIRE}/${id}/oos`, dataToSend);
  }
  expireBanner(dataToSend = {},id){   
    dataToSend['hideLoader'] = true;
    return  this.httpService.put(`${LISTING_APIS.BANNER_EXPIRE}/${id}/oos`, dataToSend);
  }
}
