<ion-list lines="none" class="ion-no-padding ion-list-image-card ion-list-grid gvc-t2 layout-spacer " *ngIf="data?.productList?.length" [ngClass]="{'has-collection-bg-image': data.table.displayType === collectionDisplayType.RecommendationWidgetProductListingBg}">
  <ion-list-header class="ion-list-header" *ngIf="data?.name">
    <ion-row class="ion-justify-content-between full-width ion-align-items-center">
      <div>
        <span class="padding-right-small">{{ data.name }}</span>
        <p *ngIf="data?.description">
          {{ data.description }}
        </p>
      </div>
      <ion-button *ngIf="showViewAll" size="small" fill="clear" class="ion-no-padding no-ripple-effect view-all"
          (click)="navigateToCollectionList()" 
          color="dark">View All</ion-button>
    </ion-row>
  </ion-list-header>
  <div class="collection-bg-img" [ngStyle]="data.table.displayType === collectionDisplayType.RecommendationWidgetProductListingBg && { 'background-image': 'url(' + backgroundUrl + ') ' }">
    <mb-pl-recommendation class="default-height"  *ngIf="data?.productList" (viewAll)="navigateToCollectionList()" [displayType]="data.table.displayType" [recommendation]="{data:data?.productList}" [recommendationType]="recommendationType"  [eventSource]="eventData?.Source" [eventData]="eventData"></mb-pl-recommendation>    
  </div>
</ion-list> 
