//#region Imports

//#region Angular Imports
import { Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AlertService } from '@Globals/providers/alert.service';
import { EventsService } from '@Globals/providers/events.service';
import { SignupService } from '@Globals/providers/signup.service';
import { DeepLinkService } from '@Globals/providers/deep-link.service';
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
import { ALERT_BUTTON_TEXT, PAGE_TAGS } from '@Globals/constants/listing/constants';
import { LISTING_ROUTES } from 'src/app/listing/constants/listing-routes';
import { CORE_ROUTES } from '@App/core/constants/coreRoutes';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbpc-address-add-success',
  templateUrl: './address-add-success.component.html',
  styleUrls: ['./address-add-success.component.scss'],
})
export class AddressAddSuccessComponent {

  constructor(private navCtrl: NavController,
    private alertService: AlertService,
    private signupService: SignupService,
    private eventsService: EventsService,
    private deeplinkService: DeepLinkService,
    private dataService: DataService
  ) {
    this.eventsService.trackEvents(PAGE_TAGS.ADD_ADDRESS_SUCCESS, {});
  }

  goToHome() {
    if (this.signupService.societyErrorMessage) {
      this.alertService.presentAlert(this.signupService.societyErrorMessage, [
        ALERT_BUTTON_TEXT
      ]);
      if(this.deeplinkService.navigateProductIdDetails && this.deeplinkService.navigateProductIdDetails.id) {
        this.dataService.selectedPDPProduct.next({
          productId :this.deeplinkService.navigateProductIdDetails.id ,
          productIndex: 0,
          source: undefined,
          eventData: undefined,
          itemType: undefined,
          product:undefined,
          appliedFilter:undefined,
          basketIndex: undefined
         });
        this.navCtrl.navigateRoot(
          '/' + CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.PRODUCT_DETAIL + `/:${this.deeplinkService.navigateProductIdDetails.id}`
        );
      }
      else {
        this.navCtrl.navigateRoot(
          '/' + LISTING_ROUTES.HOME
        );
      }
    } else {
      this.navCtrl.navigateForward(
        LISTING_ROUTES.BASE +
        LISTING_ROUTES.SUCCESS + '/1'
      );
    }
  }
}
