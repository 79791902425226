import { DeliveryTypes } from '../enums/product.enums';

export class ProductDelivery {
    __typename = 'ProductDelivery';
    type?: number; // was deliveryTypeId
    slot?: string[]; // was delivery_types_slot
    plusOne?: boolean;

    setDeliveryFromApi(productApi) {
        if (!productApi.deliveryTypeId && !productApi.delivery_types_slot) {
            return null;
        }
        this.type = productApi.deliveryTypeId || null;
        this.slot = productApi.delivery_types_slot || null;

        this.plusOne = !!(
            productApi.schedule &&
            ((productApi.delivery_types_slot &&
                productApi.delivery_types_slot[0] === DeliveryTypes.frozen) ||
                (productApi.ct && productApi.ct !== '0'))
        );
        return this;
    }
}
