function checker(x) {
    if (x > 3 && x < 21) return 'th';
    switch (x % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}

const MonthShortNames: Record<string, string> = {
    January: 'Jan',
    February: 'Feb',
    March: 'Mar',
    April: 'Apr',
    May: 'May',
    June: 'Jun',
    July: 'Jul',
    August: 'Aug',
    September: 'Sep',
    October: 'Oct',
    November: 'Nov',
    December: 'Dec',
};


const formatter = new Intl.DateTimeFormat('en-GB', {
    dateStyle: "long",
} as Intl.DateTimeFormatOptions);

/**
 *
 * @param inputDate  2022-01-08
 * @returns Jan 8, 2022
 */
const formatDateMDY = inputDate => {
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};

/**
 *
 * @param inputDate  785442600000 timestamp is in milliseconds
 * @returns 22/11/1194
 */
const formatDateDDmmYYYY = timestamp => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const formatDateDoMY = date => {
    const formatted = formatter.formatToParts(new Date(date));
    const day = formatted[0].value;
    const wr = checker(day);
    return `${day}${wr}${formatted[1].value}${formatted[2].value}${formatted[3].value}${formatted[4].value}`;
};

const formatDateDoM = date => {
    const formatted = formatter.formatToParts(new Date(date));
    const day = formatted[0].value;
    const wr = checker(day);
    return `${day}${wr}${formatted[1].value}${formatted[2].value}`;
};

const formatDateDmY = date => {
    const formatted = formatter.formatToParts(new Date(date));
    const day = formatted[0].value;
    return `${day}${formatted[1].value}${MonthShortNames[formatted[2].value]}${formatted[3].value}${
        formatted[4].value
    }`;
};

const dateDiffInDays = (fromDate, toDate) => {
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);
    const dateDiff = date1.getTime() - date2.getTime();
    const diffDays = Math.ceil(Math.abs(dateDiff) / (1000 * 60 * 60 * 24));
    return diffDays;
};

const equalDates = (date1, date2) => {
    const inp1 = new Date(date1);
    const inp2 = new Date(date2);
    return (
        inp1.getFullYear() === inp2.getFullYear() &&
        inp1.getDate() === inp2.getDate() &&
        inp1.getMonth() === inp2.getMonth()
    );
};

const isBefore = (date1, date2) => {
    const date1Obj = new Date(date1.toString().substring(0, 15));
    const date2Obj = new Date(date2.toString().substring(0, 15));

    if (date1Obj < date2Obj) return true;
    return false;
};

const isOnOrBefore = (date1, date2) => {
    const date1Obj = new Date(date1.toString().substring(0, 15));
    const date2Obj = new Date(date2.toString().substring(0, 15));

    if (date1Obj <= date2Obj) return true;
    return false;
};

const isAfter = (date1, date2) => {
    const date1Obj = new Date(date1.toString().substring(0, 15));
    const date2Obj = new Date(date2.toString().substring(0, 15));

    if (date1Obj > date2Obj) return true;
    return false;
};

const isOnOrAfter = (date1, date2) => {
    const date1Obj = new Date(date1.toString().substring(0, 15));
    const date2Obj = new Date(date2.toString().substring(0, 15));

    if (date1Obj <= date2Obj) return true;
    return false;
};

export {
    formatDateMDY,
    formatDateDoMY,
    formatDateDoM,
    formatDateDmY,
    formatDateDDmmYYYY,
    dateDiffInDays,
    equalDates,
    isBefore,
    isOnOrBefore,
    isAfter,
    isOnOrAfter,
    MonthShortNames,
};
