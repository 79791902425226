<form #promoCodeForm="ngForm" class="form pd-0" (ngSubmit)="applyInviteCode(inviteCode)">
  <div class="f-body f-group" [ngClass]="{'has-error': isError}">
    <div class=" d-flex ion-justify-content-between"  >
        <div class="promo-input">
          <ion-item 
          class="ion-no-padding" 
          lines="none" color="none"
          [ngClass]="{'ion-invalid': isError}">
            <ion-input type="text" class="ion-no-padding" 
            placeholder="Enter Promo Code"
            [(ngModel)]="inviteCode" name="inviteCode"
            (ngModelChange)="onModalChange()" 
            required></ion-input>
          </ion-item>
        </div>
        <ion-button fill="solid" size="large" shape="round" 
        expand="block" type="submit"
        [disabled]="!inviteCode||!masterDataService.isValidAddress" >Apply</ion-button>
      </div>
      <ion-text *ngIf="isError" class="help-text margin-bottom-0"> 
        <span color="danger">{{ errorMessage }}</span>
      </ion-text>
  </div>
</form>