   <div class="bpb-wrapper">
      <div class="swiper-wrap">
      <swiper 
      [pagination]="true"
      [centeredSlides]="false"
      [slidesPerView]="cardWidth"
      [spaceBetween]="16"
      [autoplay]="false"
      [cssMode]="false">
      <ng-container *ngFor="let product of dealsOfTheDayProductList; let productIndex = index">
         <ng-template  swiperSlide>
            <ion-card class="ion-card-custom bpb-slider pdt-card to-flex">
               <div class="d-flex" (click)="handleItemClick(product, productIndex)">
                  <ion-card-header>
                     <ion-img [alt]="product?.nm" src="../../../../../../../assets/img/best_price_tag.svg"></ion-img>
                     <ion-card-title text-ellipsis-ml>{{ product?.nm }} </ion-card-title>
                  </ion-card-header>
                  <div>
                     <app-img-on-demand
                     [componentType]="PLPTypeImageOnDemand" 
                     [actualImgSrc]="plmService.productImageUrl + product?.img" 
                     alt_image="img_product_{{product?.img}}"
                     [defaultImgSrc]="'assets/img/default-product.svg'" 
                     [imageName]="product?.img"
                     [productId]="product?.product_id || product?.id" 
                     [fileStorageDirectoryName]="plmService.fileStorageDirectories.PRODUCT">
                  </app-img-on-demand>
                </div>
               </div>
               <ion-card-content (click)="handleItemClick(product, productIndex)">
                  <div class="description pl-card">
                     <div (click)="openProductsVariantModal(product, productIndex); $event.stopPropagation()" class="variants-wrap" [ngClass]="{'no-variants': !(product?._newVariantProducts?.length)}">
                        <div><span class="text-holder">{{product?.wgt}}</span></div>
                        <ion-icon *ngIf="product?._newVariantProducts?.length" class="icons-property icon-down-arrow"></ion-icon>
                    </div>
                     <p class="d-flex">
                        <span class="sale-price icon-inr">{{ product?.sp }}</span>
                        <ng-container *ngIf="product.mrp - product.sp > 0">
                        <span class="actual-price icon-inr ion-text-nowrap">{{ product?.mrp }}</span>
                        <span _ngcontent-hwo-c258="" class="discount icon-inr">{{ product?.mrp - product?.sp | number: '1.1-2' }} OFF</span>
                      </ng-container>
                     </p>
                     <p class="dis-summary" *ngIf="(product.mrp - product.sp > 0) || product?.dodPrice">*{{ product?.dodPrice ? product?.dodPrice: ((product?.mrp - product?.sp)/product?.mrp)*100 | number:'1.1-2'}}% {{product?.dodPrice ? 'lesser than avg online price' : (product.mrp - product.sp) > 0 ? 'discount on this product' : ''}}</p>
                  </div>
               </ion-card-content>
               <div class="action has-counter-btn">
                <ion-button *ngIf="product.oos || product.forcedOos || (product.ct && product.ct !== '0' && currentHoursAndMinutes > product.ct && !product.schedule); else showAdd" 
                  mode="md" fill="outline" size="small" color="primary" class="full-width" (click)="triggerRequestProduct(product)">
                  Request
                </ion-button>
                <ng-template #showAdd>
                  <mb-product-counter [isPlusOneDayDelivery]="product.delivery_types_slot && product.delivery_types_slot[0] ===
              deliveryType.frozen && product.ct && product.ct !== '0' && currentHoursAndMinutes > product.ct" 
                      [productId]="product.product_id || product.id"
                      [counter]="authService.userData?.tomorrowBasket?.[product.product_id || product.id]" 
                      (counterChange)="triggerCounterChange($event, product, productIndex)">
                  </mb-product-counter>
                </ng-template>
              </div>
            </ion-card>
         </ng-template>
      </ng-container> 
      </swiper>
   </div>
</div>
