//#region Imports

//#region Angular Imports
import { Component, OnInit } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController, NavController } from '@ionic/angular'; 
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES } from '@Globals/constants/listing/constants';
import { CORE_ROUTES } from '@Globals/constants/listing/routes';
//#endregion Other Imports

//#endregion Imports

@Component({
    selector: 'app-referral-success',
    templateUrl: './referral-success.component.html',
    styleUrls: [ './referral-success.component.scss' ],
})
export class ReferralSuccessComponent implements OnInit {

    constructor(private dataService: DataService, private navCtrl: NavController, private eventService: EventsService,
                private modalCtrl: ModalController, public masterDataService: MasterDataService) {
    }

    ngOnInit() {
        this.eventService.trackEvents(EVENT_NAMES.REFERRAL_ADD_MONEY_POP_UP, {});
    }

    closeModal(navigateToPayment) {
        this.modalCtrl.dismiss();
        if (navigateToPayment) {
            this.eventService.trackEvents(EVENT_NAMES.SHOPPING_CREDIT_REFERRAL, { source: EVENT_NAMES.REFERRAL_ADD_MONEY_POP_UP });
            this.navCtrl.navigateForward(CORE_ROUTES.SHOPPING_CREDIT);
        }
    }
}
