<ion-grid *ngIf="collections" class="padding-horizontal-0 pt-0 pb-0">
  <ion-row class="seven-grid">
    <ion-col size="5" (click)="triggerCounterChange(collections?.table?.rows[0].columns[0])">
      <app-img-on-demand class="imgs" [actualImgSrc]="
            bannerFilePath +
            collections?.table?.rows[0].columns[0].images.leafBanner.image
          " [defaultImgSrc]="'assets/img/default-banner.png'" [showDefaultImage]="showDefaultImage"
        [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER" [useImageOnDemand]="false"
        alt_image="img_banner_{{collections?.table?.rows[0].columns[0].images.leafBanner.image}}"
        [imageName]="collections?.table?.rows[0].columns[0].images.leafBanner.image">
      </app-img-on-demand>
    </ion-col>
    <ion-col size="7" class="ion-no-padding">
      <ion-row class="small-grid">
        <ion-col size="6" class="no-padding" *ngFor="let column of collections?.table?.rows[0].columns | slice:1"
          (click)="triggerCounterChange(column)">
          <app-img-on-demand class="imgs" [actualImgSrc]="
                bannerFilePath +
                column.images.leafBanner.image
              " [defaultImgSrc]="'assets/img/default-banner.png'" [showDefaultImage]="showDefaultImage"
            [fileStorageDirectoryName]="
                dataService.fileStorageDirectories.BANNER
              " [useImageOnDemand]="false" [imageName]="column.images.leafBanner.image"
              alt_image="img_banner_{{column.images.leafBanner.image}}"
              >
          </app-img-on-demand>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>