<div class="ion-no-padding ion-list-image-card ion-list-grid gvc-t2 layout-spacer" lines="none">
  <div class="ion-list-wrap" *ngIf="metaData" (click)="hideCounterButton()" [ngClass]="{ 
      'has-bg-theme' :metaData.brands,
      'multiple-brand-low-value-products': (metaData.isSponsored && (metaData.typeId === 2 || metaData.typeId === 4)) || metaData.isRecommendation,
      'sponsored-single-brand': metaData.isSponsored && metaData.typeId === 1
    }" >
    <div class="bg-theme"></div>
    <!-- <div *ngIf="showNew" class="sticker new ion-text-uppercase">
      New
    </div> -->
    <ion-list class="ion-no-padding single-brand" [ngClass]="{ 'margin-0': metaData.isRecommendation}" lines="none">
      <ion-list-header class="view-title ion-list-header" *ngIf="!metaData.isSponsored && !metaData.isRecommendation">
        <div class="full-width">
          <ion-avatar *ngIf="metaData.brands && metaData.brands[0]?.logo">
            <app-img-on-demand 
              [actualImgSrc]="bannerFilePath + metaData.brands[0]?.logo" 
              [defaultImgSrc]="'assets/img/default-banner.png'"
              [fileStorageDirectoryName]="plmService.fileStorageDirectories.BANNER" 
              alt_image="img_singleBrand_{{metaData.brands[0]?.logo}}"
              [useImageOnDemand]="false" 
              [imageName]="metaData.brands[0]?.logo">
            </app-img-on-demand>
          </ion-avatar>
          <ion-row class="ion-justify-content-between full-width ion-align-items-center">
          <div>
            <span class="d-flex ion-align-items-center">{{ metaData.head }} <div *ngIf="showNew" class="new-sticker">New</div></span>
            <p class="margin-top-extra-small has-max-width">{{ metaData.subHead }}</p>
        </div>
        <ion-button class="ion-no-padding no-ripple-effect view-all" fill="clear" color="dark" size="small"
              *ngIf="showViewAll && (metaData.products?.length >= 7 || (metaData.brands && metaData.brands[0]?.products?.length >= 7))" (click)="navigateToListing();">View all
            </ion-button>
      </ion-row>
    </div>
      </ion-list-header>

      <ion-row class="" *ngIf="metaData.isSponsored && metaData.typeId === 1">
        <app-img-on-demand 
          [actualImgSrc]="bannerFilePath + metaData?.bannerImg" 
          [defaultImgSrc]="'assets/img/default-banner.png'" 
          alt_image="img_singleBrand_{{metaData?.bannerImg}}"
          [showDefaultImage]="false"
          [fileStorageDirectoryName]="plmService.fileStorageDirectories.BANNER" 
          [useImageOnDemand]="false" 
          [imageName]="metaData?.bannerImg">
        </app-img-on-demand>
        <div class="sticker"> AD </div>
      </ion-row>
      <ion-item class="ion-no-padding" color="none">
        <div class="scroll" scroll-x="true">
          <div *ngIf="metaData.isSponsored && metaData.typeId === 1" class="ion-first-card ion-card-custom fixed-size" (click)="navigateToListing();">
            <ion-thumbnail>
              <app-img-on-demand 
                [actualImgSrc]="bannerFilePath + metaData?.logo" 
                [defaultImgSrc]="'assets/img/default-banner.png'" 
                alt_image="img_singleBrand_{{metaData?.logo}}"
                [showDefaultImage]="false"
                [fileStorageDirectoryName]="plmService.fileStorageDirectories.BANNER" 
                [useImageOnDemand]="false" 
                [imageName]="metaData?.logo">
              </app-img-on-demand>
            </ion-thumbnail>
            <ion-text>
              <p>{{ metaData?.head }}</p>
            </ion-text>
          </div>
          <div class="card-flex-view card-wrap">
          <ion-card class="ion-card-custom fixed-size pdt-card to-flex " 
            *ngFor="let product of (metaData.products || (metaData.brands && metaData.brands[0]?.products));let productIndex=index;" 
            (click)="handleProductClick(product, productIndex)" 
            [ngClass]="{ 'disabled' : showCounterForProductId === (product.product_id || product.id)  && authService.userData?.tomorrowBasket?.[product.product_id || product.id] }">
            <div *ngIf="metaData.isSponsored && metaData.typeId === 2" class="sticker new ion-text-uppercase">
              AD
            </div>
            <div class="img-holder">
              <div class="img-holder-inner">
                <app-img-on-demand 
                  [componentType]="PLPTypeImageOnDemand" 
                  [actualImgSrc]="plmService.productImageUrl + product.img" 
                  alt_image="img_product_{{product.img}}"
                  [defaultImgSrc]="'assets/img/default-product.svg'" 
                  [productId]="product.product_id || product.id" 
                  [imageName]="product.img" 
                  [fileStorageDirectoryName]="plmService.fileStorageDirectories.PRODUCT">
                </app-img-on-demand>
                <div *ngIf="product.sticker; else stickerNew" [innerHTML]="(dataService.firebaseMasterData?.stickers?.[product.sticker]?.['small'] || '') | safeHtml">
                </div>
                <ng-template #stickerNew>
                  <div *ngIf="product.isNew" [innerHTML]="(dataService.firebaseMasterData?.stickers?.['new']?.['small'] || '') | safeHtml"></div>
                </ng-template>
              </div>
            </div>
            <ion-card-header>
              <ion-card-title text-ellipsis-ml>{{ product.name || product.nm }}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <div class="description pl-card">
                <div class="variants-wrap" (click)="openProductsVariantModal(product, productIndex); $event.stopPropagation();" [ngClass]="{'no-variants': !(product?._newVariantProducts?.length)}">
                  <div><span class="text-holder">{{ product.wgt }}</span></div>
                  <ion-icon *ngIf="product?._newVariantProducts?.length" class="icons-property icon-down-arrow"></ion-icon>
                </div>
              </div>
              <div class="price-details">
                <p class="d-flex">
                  <span class="sale-price icon-inr">{{ product?.sp }}</span>
                  <span class="actual-price icon-inr">{{ product?.mrp }}</span>
                </p>
                <ng-container *ngIf="product.mrp - product.sp > 0">
                  <span class="discount icon-inr">{{product?.mrp - product?.sp | number: '1.2-2' }} OFF</span>
                </ng-container>
              </div>
            </ion-card-content>
            <div class="action has-counter-btn">
              <mb-product-counter  
                [isPlusOneDayDelivery]="product.delivery_types_slot && product.delivery_types_slot[0] === deliveryTypeFrozen &&
                  product.ct && product.ct !== '0' && currentHoursAndMinutes > product.ct" 
                [productId]="product.product_id || product.id" 
                [counter]="authService.userData?.tomorrowBasket[product.product_id || product.id]"
                (counterChange)="triggerCounterChange($event, product, productIndex)" 
                (click)="$event.stopPropagation()">
              </mb-product-counter>
            </div>
          </ion-card>
          <ion-card *ngIf="showViewAll && (metaData.products?.length >=7 || (metaData.brands && metaData.brands[0]?.products?.length >= 7))" 
            class="ion-card-custom fixed-size pdt-card to-flex" (click)="navigateToListing();">
            <div class="view-all">
              <ion-button class="no-ripple-effect ion-text-uppercase ion-no-padding height-auto f-w-n" fill="clear" color="dark" size="small">
                View all 
                <ion-icon class="icon-arrow-right" color="primary"></ion-icon>
              </ion-button>
            </div>
          </ion-card>
          </div>
        </div>
      </ion-item>
    </ion-list>
  </div>
</div>
