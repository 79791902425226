//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { SignupService } from '@Globals/providers/signup.service';
import { EventsService } from '@Globals/providers/events.service';
//#endregion Providers Imports

//#region Other Imports
import { PAGE_TAGS } from '@Globals/constants/listing/constants';
import { INPUT } from '@Globals/constants/registration/constants';
import { SheetState } from '@Globals/constants/listing/enums';
import { AddressSelectionType } from '@Globals/constants/registration/enums';
import { AddressData } from '@Globals/interfaces/registration/interfaces';

import { Utilities } from '@Globals/classes/utilities';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbpc-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
})
export class AddAddressComponent extends DefaultComponent {

  @Input() cityData;
  @Output() addAddress = new EventEmitter();
  @Output() openCity = new EventEmitter();
  addressData: AddressData = {};
  dockedHeight: number = 500;
  minHeight: number = this.dockedHeight;
  citysheetState = SheetState.Bottom;
  viewTypes = AddressSelectionType;
  @ViewChild('address_form', { static: true }) public addressForm: NgForm;
  public Input = INPUT;
  constructor(
    public signupService: SignupService,
    private eventsService: EventsService) {
    super();
  }

  mbOnInit() {
    document.getElementsByTagName('body')[0].classList.add('address-height');
    this.eventsService.trackEvents(PAGE_TAGS.ADD_ADDRESS, {});
  }

  submit() {
    this.addAddress.emit(this.addressData);
  }

  removeSpace(inputType: string, searchText) {
    if (inputType === this.Input.APARTMENT) {
      if (Utilities.isEmptyString(searchText)) {
        searchText = '';
        this.addressData.society_name = '';
        this.addressForm && this.addressForm.controls['society_name'].setValue('');
      }
    } else if (inputType === this.Input.FLAT) {
      if (Utilities.isEmptyString(searchText)) {
        searchText = '';
        this.addressData.unit = '';
        this.addressForm && this.addressForm.controls['unit'].setValue('');
      }
    } else {
      if (Utilities.isEmptyString(searchText)) {
        searchText = '';
        this.addressData.tower = '';
        this.addressForm && this.addressForm.controls['tower'].setValue('');
      }
    }
  }

  triggerCityChange() {
    this.openCity.emit();
  }

  mbOnDestroy() {
    document.getElementsByTagName('body')[0].classList.remove('address-height');
  }
}
