//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { EVENT_SOURCES } from '@Globals/constants/listing/constants';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-horizontal-collection',
  templateUrl: './horizontal-collection.component.html',
  styleUrls: ['./horizontal-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalCollectionComponent extends BaseCollectionComponent {
  @Input() collections: any;
  @Output() navigate = new EventEmitter();
  @Input() showDefaultImage = true;
  collectionListingEventSource = EVENT_SOURCES.COLLECTION_LISTING;
  collectionTableData = [];

  triggerCounterChange(column) {
    this.navigate.emit({ column });
  }

}
