//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
//#endregion Module Imports

//#region Component Imports
import { JioMartHeaderComponent } from '@Shared/jio-mart-header/jio-mart-header.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [JioMartHeaderComponent],
  declarations: [JioMartHeaderComponent]
})
export class JioMartHeaderModule extends DefaultModule { }