<div class="main-banner layout-spacer" *ngIf="data?.banners?.length">
<div class="swiper-wrap" [ngClass]="{'singleSlide' : data.banners.length === 1}"
  inViewport
  [inViewportOptions]="{ threshold: [0, 0.65], partial: true }"
  (inViewportAction)="onIntersection($event)">
  <swiper 
    [pagination]="data.source !== homeBannersSource && (data.banners.length > 1)"
    [centeredSlides]="false"
    [slidesPerView]="1.14"
    [spaceBetween]="12"
    [autoplay]="true"
    [cssMode]="false"
    (swiper)="swiperInit($event)"
    (slideChange)="slideChanged($event)">
    <ng-template *ngFor="let banner of data.banners | slice:0:bannerMaxLimit; let bannerIndex = index;" swiperSlide>
      <mbs-social-sharing-button *ngIf="banner.isShare" [metaData]="banner" [componentType]="bannerComponent"></mbs-social-sharing-button>
      <p *ngIf="banner.contentOnBanner" (click)="navigate($event, bannerIndex)" class="social-proof" [innerHTML]="banner.contentOnBanner"></p>
      <app-img-on-demand [ngClass]="{'full-height-img-ios': bannerIndex === data.banners.length -1}" (click)="navigate($event, bannerIndex)"
        [actualImgSrc]="bannerFilePath + banner?.img"
        [defaultImgSrc]="'assets/img/default-banner.png'"
        [useGumletSrc]="true"
        alt_image="img_banner_{{banner?.img}}"
        [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER" [imageName]="banner?.img"
        [useImageOnDemand]="false">
      </app-img-on-demand>
    </ng-template>
  </swiper>
</div> 
</div>