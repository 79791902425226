
//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from '@Globals/providers/master-data.service';
import { DataService } from '@Globals/providers/data.service';
import { AuthService } from '@Globals/providers/auth.service';
import { EventsService } from '@Globals/providers/events.service';
import { ProductListManagerService } from '@Features/product-list/providers/product-list-manager.service';
//#endregion Providers Imports

//#region Other Imports
import { Utilities } from '@Globals/classes/utilities';

import { ProductListType, SeeSimilar, DeliveryType, ImageOnDemandType } from '@Globals/constants/listing/enums';

import { environment } from '@Env/environment';
import { EVENT_NAMES } from '@Globals/constants/listing/constants';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mb-bundle-products',
  templateUrl: './bundle-products.component.html',
  styleUrls: ['./bundle-products.component.scss'],
})
export class BundleProductsComponent extends DefaultComponent {
  @Input() product: any;
  @Input() componentType: any;
  @Input() basketIndex?: number;
  @Input() productIndex: number;
  @Input() recommendedProductList: any;
  @Output() counterChangeEvent = new EventEmitter();
  @Output() requestProduct = new EventEmitter();
  @Output() removeCancelProduct = new EventEmitter();
  @Output() showOrHideRecommendedProducts = new EventEmitter();
  @Input() eventData: any;
  @Input() eventSource?: string;

  ProductListType = ProductListType;
  PLPTypeImageOnDemand = ImageOnDemandType.PLP;
  deliveryType = DeliveryType;
  seeSimilarActionType = SeeSimilar; 
  currentHoursAndMinutes = Utilities.getCurrentHoursAndMinutes();
  productPath = environment.productPath;

  constructor(
    public masterDataService: MasterDataService,
    public dataService: DataService,
    public authService: AuthService,
    private eventsService: EventsService,
    private plmService: ProductListManagerService
    ) { 
    super();
  }

  mbOnInit() {}

  triggerCounterChange(event, product, productIndex) {
    this.counterChangeEvent.emit({event, product, productIndex});
  }
  triggerRequestProduct(product) {
    this.requestProduct.emit({ product});
  }

  triggerremoveCancelProduct(productId) {
    this.removeCancelProduct.emit({productId});
  }

  triggershowOrHideRecommendedProducts(product, showHideRelated) {
    this.showOrHideRecommendedProducts.emit({product, showHideRelated});
  }

  async openProductsVariantModal(product, selectedProductIndex) {
    this.dataService.productVariantInfo.isVariantAddedToCart = false;
    this.dataService.productVariantInfo.latestVariantIndexIntoCart = null;
    const variantProduct = product?._newVariantProducts;

    if (variantProduct && variantProduct.length) {
      const modal = await this.plmService.openProductVariantModel(variantProduct, product, ProductListType.Product_Variant_List, this.eventSource, this.eventData);
      modal.present();
      let eventAgrs = { source: this.eventSource, product_id: product.id, variant_id: product.variantId };
      this.eventsService.trackEvents(EVENT_NAMES.PRODUCT_VARIANT_CLICKED, { ...eventAgrs });

      modal.onDidDismiss().then(res => {
        if (this.dataService.productVariantInfo.isVariantAddedToCart) {
          this.product.bun_products[selectedProductIndex] = variantProduct[this.dataService.productVariantInfo.latestVariantIndexIntoCart];
          this.product.bun_products[selectedProductIndex]._newVariantProducts = variantProduct;
        }
      });
    }
  }

}
