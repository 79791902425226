<ion-header class="ion-main-header sticky-header">
  <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium padding-right-medium">
    <div class="d-flex">
    <ion-buttons class="ion-buttons-arrow-back" id="custom-main-heading" slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon class="icon-arrow-back icons-property icon-md" color="dark" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-no-padding ">
      Link {{pg.name}}
    </ion-title>
  </div>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding ctn-gt-space-p">
     <div class="f-group padding-top-base">
      <ion-text class="margin-bottom-extra-large">
        <h2 class="padding-bottom-extra-small"></h2>
        <!-- <ion-label class="f-w-b" *ngIf="walletState === walletLinking.OTP">Enter OTP received on <span>{{mobileNumber}}</span></ion-label> -->
      </ion-text>
      <div class="form-content ion-item-wrap">
          <ion-label class="f-w-b">Enter Your Mobile No.</ion-label>
        <ion-item class="ion-no-padding">
          <ion-input placeholder="Mobile Number" type="tel"  minlength="10"
          maxlength="10" required (ionInput)="restrictToDigits($event)" [(ngModel)]="mobileNumber" class="ion-no-padding"></ion-input>
        </ion-item>  
        <ion-text class="danger-wrong" *ngIf="mobileNumber.length < 10">
          <small>Mobile Number is invalid</small>
        </ion-text>
      </div>
      <ng-container *ngIf="walletState === walletLinking.OTP">
        <div class="form-content ion-item-wrap margin-top-extra-big-large">
          <ion-label class="f-w-b">Enter OTP</ion-label>
          <ion-item class="ion-no-padding">
            <ion-input [(ngModel)]="dataToSend.otp"type="tel" maxlength="8" placeholder="Enter OTP" ></ion-input>
          </ion-item>
          <ion-text class="danger-wrong" *ngIf="error">
            <small>Wrong OTP, Please enter correct OTP</small>
          </ion-text>
          <ion-text class="margin-top-small">
            <p class="ion-resends text-size-small margin-top-extra-small">An OTP was sent to your phone </p>
          </ion-text>
        </div>
        <div class="resend  d-flex ion-justify-content-center ion-align-items-center">
          <p class="help-text">A new OTP has been sent</p>
         <div class="spinner">
          <ion-button  [disabled]="!enableResend" (click)="generateOtp()" color="primary"  fill="clear" size="small" class="resend-now no-ripple-effect no-ripple-effect ">Resend Now</ion-button>
            <mbs-count-down-timer *ngIf="!enableResend" (updateTimer) = "timerValue($event)"></mbs-count-down-timer>
         </div>
        </div>
     </ng-container>
      <ion-button *ngIf="walletState === walletLinking.OTP; else enterOtpSectionProceed" expand="block" (click)="verifyOtp()" [disabled]="dataToSend.otp && dataToSend.otp.length < 4 " size="large" class="margin-top-large">Proceed</ion-button>
      <ng-template #enterOtpSectionProceed>
        <ion-button expand="block" (click)="generateOtp()" [disabled]="mobileNumber && mobileNumber.length < 10" size="large" class="margin-top-large">Continue</ion-button>
      </ng-template>
     </div>
</ion-content>
