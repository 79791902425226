<ion-header class="ion-padding-horizontal">
  <ion-toolbar>
    <h2>Rate Your Experience</h2>

    <div class="rating-star">
      <ion-icon *ngFor="let star of starRatingArray; let starIndex = index;" name="star" (click)="userRating(starIndex)"
        [ngClass]="{filled : feedbackData.rating > starIndex}"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-horizontal ion-padding-top app-rating">
  <ng-container *ngIf="dataService.firebaseMasterData.showRatingThankYouText">
    <!--Five star rating block-->
    <div *ngIf="feedbackData?.rating >= customerRating?.ratingOnPlaystore"
      class="five-star-rating">
      <p>Thanks a ton!</p>
    </div>
  </ng-container>
  <!--Below five star rating block-->
  <div
    *ngIf="feedbackData?.rating < customerRating?.ratingOnPlaystore && feedbackData?.rating !== -1"
    class="below-five-star-rating">
    <p class="divider">Sorry you didn’t have the best experience.. <br /> Where can we improve?</p>
    <div class="buttons-group-light">
      <ion-button type="button" *ngFor="let feedbackOption of customerRating.feedback"
        (click)="userFeedback(feedbackOption)" [ngClass]="{active: feedbackOption.selected}">{{ feedbackOption.value }}
      </ion-button>
    </div>
  </div>
</ion-content>
<ion-footer *ngIf="feedbackData?.rating !== -1" mode="ios"
  class="ion-padding-horizontal ion-padding-bottom ion-text-center">
  <ion-button *ngIf="saveButtonDisplay" class="btn-rate-on-play-store" type="button" size="large" shape="round"
    color="primary" expand="block"
    (click)="feedbackData?.rating >= customerRating?.ratingOnPlaystore ? openStoreForRating() : saveUserRating(false, true)">
    {{ feedbackData?.rating >= customerRating?.ratingOnPlaystore ? rateOnStoreContent :
    'Submit' }}</ion-button>
  <ion-button *ngIf="feedbackData?.rating >= customerRating?.ratingOnPlaystore"
    (click)="dismissRateModal();" class="no-ripple-effect btn-rate-later" type="button" color="primary" fill="clear"
    size="large" shape="round" class="ion-text-uppercase">Maybe Later</ion-button>
</ion-footer>