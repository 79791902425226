import { CORE_ROUTES } from '../../core/constants/coreRoutes';

export const LISTING_ROUTES = {
  BASE: `/${CORE_ROUTES.LISTING}/`,
  HOME: 'home',
  CALENDAR_JOURNEY: 'calendar-journey',
  RECURRING_ORDERS: 'recurring-orders',
  RECURRING_SEARCH: 'recurring-search',
  ACCOUNT_HISTORY: 'account-history',
  ACCOUNT_HISTORY_DETAILS: 'account-history-details',
  SEARCH: 'search',
  SUBCATEGORY: 'subcategory/',
  CATEGORY: 'category/',
  YOUR_CART: 'your-cart',
  SSPH_CHARGES: 'ssph-charges',
  PRODUCT_DETAIL: 'product-detail',
  LOW_BALANCE: 'low-balance',
  SCHEDULE_PRODUCT: 'schedule-product',
  CONTACT: 'contact',
  EXTERNAL: 'external',
  OFFERS: 'offers',
  COLLECTION: 'collection',
  COLLECTIONPLP: 'collection-plp/',
  RECOMMENDATION: '',
  MEMBERSHIP_DETAILS: 'membership-details',
  MEMBERSHIP_DETAILS_POPUP: 'grey-page',
  SIMILAR_PRODUCTS_LIST: 'similar-products/',
  COLLECTION_VIDEO: 'collection-video/',
  BANNER_COLLECTION_PLP: 'banner-collection-plp/',
  BRAND_PLP: 'brand-plp/',
  PRODUCT_LAUNCH_PLP: 'product-launch-plp/',
  COLLECTION_LISTING: 'collection-listing',
  MB_PASS: 'mb-pass',
  M_BEYOND: 'mbeyond',
  SET_CALENDAR: 'set-calendar',
  SPONSORED_LISTING: 'sponsored-listing/',
  M_BEYOND_ALL_COLLECTION: 'm-beyond-all-collection',
  OFFER_COLLECTION_PLP: 'offer-collection-plp/',
  FAQ: 'faq',
  SUCCESS: 'register-success',
  SUBSCRIBE_TO_SAVE: 'subscribe-to-save',
  RC_SAVING: 'rc-saving',
  RC_DETAILS: 'rc-details',
  RECYCLE_BAGS: 'recycle-bags',
  RECYCLE_BAG: 'recycle-bag',
  RECYCLE_TETRA_BAG: 'recycle-tetra-bag',
  CALENDAR_MODAL: 'calendar-modal',
  NPS_SURVEY:'nps-survey',
  MEMBERSHIP: 'membership',
  SET_SUBSCRIPTION_PLAN: 'set-subscription-plan',
  COLLECTION_LISTING_NAV_SINGLE_URL: 'view/single-cell/',
};
