export enum ProductListType {
  Account_History_Details,
  Product_Listing,
  Search_Page,
  Your_Cart,
  Recurring_Search_Listing,
  Recurring_Order_Listing,
  Collection_List,
  Offers,
  Product_Details,
  Account_History,
  Mb_Plus_Pass,
  Your_Frequent_Orders,
  Collection,
  Similar_Products_List,
  Impulse_Buy_Listing_On_Cart,
  Variant,
  Cancelled_Products_On_Cart,
  Horizontal_Listing_With_Headers,
  Two_Row_Horizontal_Listing,
  Horizontal_Listing_Extra_Width,
  High_Value_Horizontal_Listing,
  Recommendation_Product_Listing,
  Product_Variant_List,
  Discount_Type_Product_List
}

export enum ProductSource {
  Unknown,
  Farm_Route
}

export enum ScheduleType {
  PDP,
  Pre_Order
}

export enum RecommendationType {
  Recommendation,
  See_Similar,
  Related,
  PDP_See_Similar,
  Recurring_Suggestions,
  Home_Recommendation
}
export enum AddVoucherEventArgType {
  Clicked,
  Closed,
  ViewTnC,
  BackTnC
}

export enum AddVoucherType {
  Membership,
  Product
}

export enum SeeSimilar {
  show = 'show_Similar',
  hide = 'hide_Similar'
}

export enum SeeRelated {
  show = 'show_Related',
  hide = 'hide_Related'
}

export enum DeliveryType {
  frozen = '7:00-8:00',
  normal = 'normal',
}

export enum PaymentOfferType {
  LowBalancePopUp,
  ShoppingCredit,
  HomeStrip
}
export enum CollectionPositionsInSubcategoryPLP {
  BeforeOutOfStock = '-2',
  EndOfThePage = '-1'
}

export enum RefillTypes {
  NextDay = 3,
  WillReturnInXDays = 4,
  Discontinued = 5,
  LeadTimeNotKnown = 6
}

export enum WhatsappSharingComponentTypes {
  PDP,
  Collection,
  Banner,
  PLP,
  Collection_PLP,
  Collection_Video,
  Collection_Listing,
  Banner_PLP
}

export enum CollectionType {
  Image,
  Video
}

export enum StripType {
  Home,
  PLP
}

export enum RecurringViewType {
  SelectPlanType,
  AddEditPlan,
  ViewPlan,
  SelectStartDate
}

export enum RecurringEnabled {
  Yes = 1,
  No
}

export enum CounterType {
  Normal,
  TopNotch
}

export enum CollectionDisplayType {
  Normal = 1,
  HorizontalWidget = 2,
  BookStoreWidget = 3,
  RecommendationWidgetProductListing = 4,
  ProductLaunchTypeListing = 5,
  TwoRowProductListing = 6,
  HorizontalProductListingExtraWidth = 7,
  RecurringSuggestions = 8,
  mBeyond = 9,
  ExploreCollection = 11,
  VideoCollection = 12,
  ThreeColumnInSingleRow = 13,
  AutoSwipeCollection = 14,
  LottieCollection = 15,
  VideoCardCollection = 16,
  DealsOfTheDayCollection = 17,
  HeroCollection = 18,
  CategoryCollection = 100,
  RecommendationWidgetProductListingBg = 19,
}

export enum CalendarPlanType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ALTERNATE = 'ALTERNATE',
  ONCE = 'ONCE',
}

export enum ProductCardViewType {
  LISTING = 'LISTING',
  VIEW_PLAN = 'VIEW_PLAN',
  CREATE_PLAN = 'CREATE_PLAN',
  EXPLORE = 'EXPLORE',
}

export enum CollectionType {
  IMAGE = 'c_image',
  PAYMENT_OFFER = 'c_payment_offer',
  BANNER = 'c_banner',
  HORIZONTAL_WIDGET = 'c_horizontal_widget',
  THREE_COLUMN_IN_SINGLE_ROW = 'c_thee_column_in_single_row',
  BOOK_STORE = 'c_bookstore_widget',
  VIDEO = 'c_video',
  LOTTIE = 'c_lottie',
  RECOMMENDATION = 'c_recommendation_widget',
  PREVIOUSLY_ORDERED = 'c_previously_ordered',
  PRODUCT_LAUNCH_LISTING = 'c_product_launch_listing',
  TWO_ROW = 'c_two_row',
  HORIZONTAL_PRODUCT_EXTRA_WIDTH = 'c_horizontal_products_extra_width',
  MBEYOND = 'c_mBeyond',
  EXPLORE = 'c_explore',
  AUTO_SWIPE = 'c_auto_swipe',
  RECURRIN = 'c_recurring',
  BRAND_LAUNCH = 'c_brand_launch',
  PRODUCT_LAUNCH = 'c_product_launch',
  VIDEO_CARD = 'c_video_card',
  DEALS_OF_THE_DAY = 'c_deals_of_the_day',
  CATEGORY_COLLECTION = 'c_category_collection',
}

export enum CustomizeCollection {
  previouslyOrdered = 7
}

export enum OfferType {
  category = 1,
  subCategory = 2,
  brandOrManufacturer = 3,
  product = 6
}

export enum RewardType {
  Sample = 1,
  MbPass = 2,
  Cashback = 3,
  DiscountedProduct=4

}

export enum FaqType {
  MbPass = 1,
  Mbeyond = 2,
  ShoppingCredits = 3,
  JioMahaCashback = 4
}

export enum OnlySchedulingOrNot {
  BothRecurringAndA2B,
  RecurringOnly,
  ProductListing
}

export enum recurringCampaignCashbackType {
  PERCENT_TYPE = '%cashback',
  FLAT_TYPE = 'flat cashback',
}

export enum RecurringCampaignStripType {
  PRODUCT_LISTING_STRIP = 'productListingStrip',
  CALENDER_BOTTOM_STRIP = 'calenderBottomStrip',
  RECURRING_LIST_CARD_TOP_STRIP = 'recurringListCardTopStrip'
}
export enum RecycleBags {
  RequestAlreadyRaised,
  AddToBasket,
  RequestRaised,
  tetraPackRequestRaised
}

export enum SubscribeAndSaveSlab {
  CURRENT_SLAB,
  PREVIOUS_SLAB,
}

export enum RecycleRequestType {
  DELIVERY_BAG = 'DELIVERY_BAG',
  TETRA_BAG='TETRA_PAK'
}

export enum OfferDiscountedProduct {
  DiscountedProduct = 5,
}