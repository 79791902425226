import { MbAppBootstrap } from "@mb-lib/app-bootstrap";

export const reactRendererWrapper = (
  Component,
  componentProps,
  eventManager,
  apolloClient,
  authService,
  isHome
) => {
  const navigationManagerConfig = {
    navigateToPreviousUrl:
      eventManager.navigateToPreviousUrl.bind(eventManager),
    navigateByUrl: eventManager.navigateByUrl.bind(eventManager),
  };
  const eventTrackerConfig = {
    trackEvents: eventManager.trackEvents.bind(eventManager),
  };
  const apolloConfig = { client: apolloClient };
  const authConfig = {
    isAuthenticated: !!authService.isLoggedIn(),
    setAuthKey: eventManager.setAuthKey.bind(eventManager),
    setRefreshKey: eventManager.setRefreshKey.bind(eventManager),
    setupAuthKey: eventManager.setupAuthKey.bind(eventManager),
  };
  const cordovaBridgeLegacyConfig = {
    generateAppHash: eventManager.generateAppHash.bind(eventManager),
    startWatchingOtp: eventManager.startWatchingOtp.bind(eventManager),
    fetchDeviceDetails: eventManager.fetchDeviceDetails.bind(eventManager),
    sendReferralReminder: eventManager.sendReferralReminder.bind(eventManager),
    createAndShareReferralLink:
      eventManager.createAndShareReferralLink.bind(eventManager),
    copyToClipboard: eventManager.copyToClipboard.bind(eventManager),
    addKeyboardShowListener:
      eventManager.addKeyboardShowListener.bind(eventManager),
    addKeyboardHideListener:
      eventManager.addKeyboardHideListener.bind(eventManager),
    onDeviceBackButtonPress:
      eventManager.onDeviceBackButtonPress.bind(eventManager),
    getPicture: eventManager.getPicture.bind(eventManager),
    generateLinkAndShare: eventManager.generateLinkAndShare.bind(eventManager),
    openSharingOptions: eventManager.openSharingOptions.bind(eventManager),
    downloadUserStatement:eventManager.downloadUserStatement.bind(eventManager), 
    preFetchHyperSdk:  eventManager.preFetchHyperSdk.bind(eventManager),
    initiateHyperSdk:  eventManager.initiateHyperSdk.bind(eventManager),
    processHyperSdk:  eventManager.processHyperSdk.bind(eventManager),
    processHyperSdkHandler:  eventManager.processHyperSdkHandler.bind(eventManager),
    exitAppHandler: eventManager.exitAppHandler.bind(eventManager),
    getGoogleLocation: eventManager.getGoogleLocation.bind(eventManager),
    checkCameraPermissionAndTakePhotos: eventManager.checkCameraPermissionAndTakePhotos.bind(eventManager),
    checkForDownloadPermission: eventManager.checkForDownloadPermission.bind(eventManager),
    requestForDirectoriesPermission: eventManager.requestForDirectoriesPermission.bind(eventManager),
    downloadAccountStatement: eventManager.downloadAccountStatement.bind(eventManager),
    downloadStatement: eventManager.downloadStatement.bind(eventManager),
  };

  const appBridgeConfig = {
    getAppVersion: eventManager.getAppVersion.bind(eventManager),
    getAppBinaryVersion: eventManager.getAppBinaryVersion.bind(eventManager),
    getAppPlatform: eventManager.getAppPlatform.bind(eventManager),
  };

  const appBridgeLegacyConfig = {
    getBasket: eventManager.getBasket.bind(eventManager),
    setUserPhoneNumber: eventManager.setUserPhoneNumber.bind(eventManager),
    openPaymentOptionsModal:
      eventManager.openPaymentOptionsModal.bind(eventManager),
    getSocialSharingChannels:
      eventManager.getSocialSharingChannels.bind(eventManager),
    navigateDeepLink: eventManager.navigateDeepLink.bind(eventManager), 
    handleCollectionSelection:
      eventManager.handleCollectionSelection.bind(eventManager),
    setVideoCollectionData:
      eventManager.setVideoCollectionData.bind(eventManager),
    onBrandSelection: eventManager.onBrandSelection.bind(eventManager),
    setMBPassData: eventManager.setMBPassData.bind(eventManager),
    accountDeletedPostProcess: eventManager.accountDeletedPostProcess.bind(eventManager),
    navigateToSelfHelp: eventManager.navigateToSelfHelp.bind(eventManager),
    setDataForExternalUrl: eventManager.setDataForExternalUrl.bind(eventManager),
    setBasket: eventManager.setBasket.bind(eventManager),
    logout: eventManager.logout.bind(eventManager), 
    refetchBasket: eventManager.refetchBasket.bind(eventManager),
    checkIfAppInstalled:eventManager.checkIfAppInstalled.bind(eventManager),
    onDismissLowBalanceModal:eventManager.onDismissLowBalanceModal.bind(eventManager),
    onRegisterMasterDataUpdation: eventManager.onRegisterMasterDataUpdation.bind(eventManager),
    adressChangeMasterDataUpdation: eventManager.adressChangeMasterDataUpdation.bind(eventManager),
    navigateFromSelfHelp: eventManager.navigateFromSelfHelp.bind(eventManager),
  };
  
  const modalControllerConfig = {
    onDeviceBackButtonPress:
      eventManager.onDeviceBackButtonPress.bind(eventManager),
  };

  const remoteConfig = {
    getConfig: eventManager.getConfig.bind(eventManager),
  };

  const cacheManagerConfig = {};

  const appOptions = {
    isRoot: isHome
  }

  return (props) => (
    <MbAppBootstrap
      navigationManagerConfig={navigationManagerConfig}
      eventTrackerConfig={eventTrackerConfig}
      apolloConfig={apolloConfig}
      appOptions={appOptions}
      authConfig={authConfig}
      cordovaBridgeLegacyConfig={cordovaBridgeLegacyConfig}
      appBridgeConfig={appBridgeConfig}
      appBridgeLegacyConfig={appBridgeLegacyConfig}
      remoteConfig={remoteConfig}
      dataHubConfig={{}}
      asyncDataHubConfig={{}}
      modalControllerConfig={modalControllerConfig}
      cacheManagerConfig={cacheManagerConfig}
      paymentConfig={{}}
    >
      <Component {...componentProps.data} {...props} />
    </MbAppBootstrap>
  );
};
