const AppConfigs = {
    WIDGETS: {
        COLLECTION: {
            BANNER_LIMIT: 20,
            MAX_COLLECTIONS_PER_SLOT: 1,
            MAX_SLOT_POSITION_INDEX: 19,
            LAST_POSITION_OFFSET: 999,
            POSITION_MULTIPLIER_OFFSET: 10,
            UNPARSED_POSITION_POLYFILL: 900,
        },
    },
    TYPE_CONFIGS: {
        PRODUCT: {
            REFILL_TYPES_META_DATA: {
                type3: {
                    buttonLabel: 'Notify me',
                    text: 'Back in stock tomorrow',
                },
                type4: {
                    buttonLabel: 'Notify me',
                    text: 'Back in stock by {date}',
                    replacer: '{date}',
                },
                type5: {
                    buttonLabel: 'Notify me',
                    text: 'Currently out of production',
                },
                type6: {
                    buttonLabel: 'Notify me',
                    text: 'Currently unavailable',
                },
            },
        },
    },
};
export { AppConfigs };
