import { ProductImageAssets } from './product-image-assets';

export class ProductAssets {
    __typename = 'ProductAssets';
    image?: ProductImageAssets;
    setAssetsFromApi(productApi) {
        const image = new ProductImageAssets();
        this.image = image.setImageAssetsFromApi(productApi);
        return this;
    }

    setTagAssetsFromApi(productApi) {
        const image = new ProductImageAssets();
        this.image = image.setTagAssetsFromApi(productApi);
        return this;
    }
}
