<ion-footer class="cashback-footer strip-swiper" *ngIf="masterDataService.isShownJioStrip || masterDataService.baskets[0]?.is_otdf">
  <swiper #swiper
  [loop]="false"
  [autoplay]="false"
      [pagination]="masterDataService.baskets[0]?.is_otdf && masterDataService.isShownJioStrip && showOTDFStrip ?{ type:'fraction'}:false" 
      (swiper)="swiperInit($event)"   
 >
  <ng-container *ngFor="let item of slidesIndex">  
    <ng-template *ngIf="item == 1 && masterDataService.baskets[0]?.is_otdf && showOTDFStrip && masterDataService.masterData?.extra_fee?.mov" swiperSlide>
      <ion-item lines="none" class="w-100 has-pager" [ngClass]="{'has-pager-base' : !masterDataService.baskets[0]?.is_otdf || (masterDataService.baskets[0]?.is_otdf && !showOTDFStrip) || (!masterDataService.baskets[0]?.is_otdf && masterDataService.isShownJioStrip) || (masterDataService.baskets[0]?.is_otdf && !masterDataService.isShownJioStrip)}">
      
        <ion-thumbnail slot="start" class="icon-holder">
         <img alt="free delivery" src="../../../../assets/icon/free-del.svg" />
        </ion-thumbnail>
        <ng-conatiner *ngIf="(masterDataService.masterData?.extra_fee?.mov - (!!(masterDataService.baskets[0]?.bill_details?.sub_total - masterDataService.baskets[0]?.bill_details?.sub_savings)?masterDataService.baskets[0]?.bill_details?.sub_total - masterDataService.baskets[0]?.bill_details?.sub_savings:0)   ) > 0;else otdfFreeDelivery">
         <ion-label class="ion-text-wrap">Add items worth <strong>₹{{ ((masterDataService.masterData?.extra_fee?.mov ) - 
           ( !!(masterDataService.baskets[0]?.bill_details?.sub_total - masterDataService.baskets[0]?.bill_details?.sub_savings) ? masterDataService.baskets[0]?.bill_details?.sub_total - masterDataService.baskets[0]?.bill_details?.sub_savings:0)).toFixed(2) }}</strong> {{masterDataService.baskets[0]?.bill_details?.sub_total - masterDataService.baskets[0]?.bill_details?.sub_savings>0?'more':'' }} to get <strong> free delivery</strong>
        </ion-label>
      </ng-conatiner>
      <ng-template  #otdfFreeDelivery>
        <ion-label class="ion-text-wrap">Enjoy <strong>free delivery</strong> on this order</ion-label>
        </ng-template>
      </ion-item>
    </ng-template>     
        <ng-template *ngIf="item == 2 && masterDataService.isShownJioStrip"  swiperSlide>
          <ng-container
    *ngIf="masterDataService.masterData?.customer_mb_pass?.mbv === 0 && masterDataService.masterData?.customer_mb_pass?.offerType === offerType.PERCENTAGE">
    <ng-container *ngIf="(masterDataService.jioStripData.currentCashback < masterDataService.masterData?.customer_mb_pass?.maxCashback);else achieved">
      <!-- basket value comparison  zero-->
      <ng-container *ngIf="masterDataService.jioStripData.mbpassBasketValue === 0">
        <ion-item class="cashback-info" [ngClass]="{'has-pager': masterDataService.jioStripData.chashBackCount,'has-pager-base' : !masterDataService.baskets[0]?.is_otdf || (masterDataService.baskets[0]?.is_otdf && !showOTDFStrip) || (!masterDataService.baskets[0]?.is_otdf && masterDataService.isShownJioStrip) || (masterDataService.baskets[0]?.is_otdf && !masterDataService.isShownJioStrip)}" lines="none">
          <ion-thumbnail class="icon-mb-pass-wrap" slot="start">
            <ion-icon class="icons-property icon-white icon-mb-pass"></ion-icon>
          </ion-thumbnail>
          <ion-label  class="ion-no-margin ion-text-wrap">
            <ion-text *ngIf="masterDataService.categoryType === mbpassType.PLATFORM;else category" class="text-size-sm d-block">
              {{MBPASS_STRIP_CONTENT.ADD_ANY_ITEM}} {{MBPASS_STRIP_CONTENT.TO}} <strong>{{MBPASS_STRIP_CONTENT.TO_EARN}} {{masterDataService.masterData?.customer_mb_pass?.offerValue}}% {{MBPASS_STRIP_CONTENT.CASHBACK}}</strong>
            </ion-text>
            <ng-template #category>
              <ion-text [ngClass]="{'text-animation': masterDataService.jioStripData.chashBackCount}" class="text-size-sm d-block">
                <span id="cashback-count">{{MBPASS_STRIP_CONTENT.ADD_ANY_ITEM}} {{MBPASS_STRIP_CONTENT.FROM}} {{masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.length > 1 ? 'select' + (masterDataService.masterData?.customer_mb_pass?.offerCategoryType === offerCategoryType.CATEGORY ?' categories':' sub-categories') :masterDataService.mbpassCategoryNames}} {{MBPASS_STRIP_CONTENT.TO}} <strong>{{MBPASS_STRIP_CONTENT.TO_EARN}} {{masterDataService.masterData?.customer_mb_pass?.offerValue}}% {{MBPASS_STRIP_CONTENT.CASHBACK}}</strong>.</span>
                <!-- Duplicate-text span use for animation -->
                <!-- <span class="duplicate-text">{{MBPASS_STRIP_CONTENT.ADD_ANY_ITEM}} {{MBPASS_STRIP_CONTENT.FROM}} {{masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.length > 1 ? 'select' + (masterDataService.masterData?.customer_mb_pass?.offerCategoryType === offerCategoryType.CATEGORY ?' categories':' sub-categories') :masterDataService.mbpassCategoryNames}} {{MBPASS_STRIP_CONTENT.TO_EARN}} {{masterDataService.masterData?.customer_mb_pass?.offerValue}}% {{MBPASS_STRIP_CONTENT.CASHBACK}}.</span> -->

              </ion-text>
            </ng-template>
          </ion-label>
        </ion-item>
      </ng-container>
      <!-- bsketvalue greater than zero plus achive less cashback than maxCashback show range -->
      <ng-container
        *ngIf="masterDataService.jioStripData.mbpassBasketValue > 0 && (masterDataService.jioStripData.currentCashback < masterDataService.masterData?.customer_mb_pass?.maxCashback)">
        <ion-item class="cashback-info redeem-partial" lines="none" color="none">
          <ion-thumbnail class="icon-mb-pass-wrap" slot="start">
            <ion-icon class="icons-property icon-white icon-mb-pass"></ion-icon>
          </ion-thumbnail>
          <ion-label class="ion-no-margin ion-text-wrap">
            {{MBPASS_STRIP_CONTENT.YOU_HAVE_EARNED}} <strong>{{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.jioStripData.currentCashback | number : '1.2-2'}}</strong> {{MBPASS_STRIP_CONTENT.CASHBACK}} <strong>({{MBPASS_STRIP_CONTENT.MAX}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.maxCashback}})</strong>
            </ion-label>
          <ion-range
            class="ion-no-padding" [max]="100" style="z-index: -1"
            [value]="((masterDataService.jioStripData.currentCashback/masterDataService.masterData?.customer_mb_pass?.maxCashback)*100)">
          </ion-range>
        </ion-item>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="masterDataService.masterData?.customer_mb_pass?.mbv > 0">
    <ng-container *ngIf="masterDataService.masterData?.customer_mb_pass?.offerType === offerType.PERCENTAGE">
     <ng-container *ngIf="(masterDataService.jioStripData.currentCashback < masterDataService.masterData?.customer_mb_pass?.maxCashback);else achieved">
        <!-- condition for basket value zero -->
        <ng-container *ngIf="masterDataService.jioStripData.mbpassBasketValue === 0">
          <ion-item class="cashback-info" [ngClass]="{'has-pager': masterDataService.jioStripData.chashBackCount,'has-pager-base' : !masterDataService.baskets[0]?.is_otdf || (masterDataService.baskets[0]?.is_otdf && !showOTDFStrip) || (!masterDataService.baskets[0]?.is_otdf && masterDataService.isShownJioStrip) || (masterDataService.baskets[0]?.is_otdf && !masterDataService.isShownJioStrip)}" lines="none">
            <ion-thumbnail class="icon-mb-pass-wrap" slot="start">
              <ion-icon class="icons-property icon-white icon-mb-pass"></ion-icon>
            </ion-thumbnail>
            <ion-label class="ion-no-margin ion-text-wrap">
             <ion-text *ngIf="masterDataService.categoryType === mbpassType.PLATFORM;else categoryPercentageZero" class="text-size-sm d-block">
                {{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} <strong>{{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv}}</strong> {{MBPASS_STRIP_CONTENT.TO}} <strong>{{MBPASS_STRIP_CONTENT.TO_EARN}} {{masterDataService.masterData?.customer_mb_pass?.offerValue}}% {{MBPASS_STRIP_CONTENT.CASHBACK}}</strong>.
              </ion-text>
              <ng-template #categoryPercentageZero>
                <ion-text [ngClass]="{'text-animation': masterDataService.jioStripData.chashBackCount}" class="text-size-sm d-block">
                <span id="cashback-count">{{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv}} {{MBPASS_STRIP_CONTENT.FROM}} {{masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.length > 1 ? 'select' + (masterDataService.masterData?.customer_mb_pass?.offerCategoryType === offerCategoryType.CATEGORY ?' categories':' sub-categories') :masterDataService.mbpassCategoryNames}} {{MBPASS_STRIP_CONTENT.TO}} <strong>{{MBPASS_STRIP_CONTENT.TO_EARN}} {{masterDataService.masterData?.customer_mb_pass?.offerValue}}% {{MBPASS_STRIP_CONTENT.CASHBACK}}</strong>.</span>
                <!-- Duplicate-text span use for animation -->
                <!-- <span class="duplicate-text">{{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv}} {{MBPASS_STRIP_CONTENT.FROM}} {{masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.length > 1 ? 'select' + (masterDataService.masterData?.customer_mb_pass?.offerCategoryType === offerCategoryType.CATEGORY ?' categories':' sub-categories') :masterDataService.mbpassCategoryNames}} {{MBPASS_STRIP_CONTENT.TO_EARN}} {{masterDataService.masterData?.customer_mb_pass?.offerValue}}% {{MBPASS_STRIP_CONTENT.CASHBACK}}.</span> -->
                </ion-text>
              </ng-template>
            </ion-label>
          </ion-item>
        </ng-container>
        <!-- condition basket greater than zero or equal to minimu basket value -->
        <ng-container *ngIf="(masterDataService.jioStripData.mbpassBasketValue > 0) && (masterDataService.jioStripData.mbpassBasketValue < masterDataService.masterData?.customer_mb_pass?.mbv) ">
          <ion-item class="cashback-info" [ngClass]="{'has-pager': masterDataService.jioStripData.chashBackCount || masterDataService.categoryType === mbpassType.PLATFORM,'has-pager-base' : !masterDataService.baskets[0]?.is_otdf || (masterDataService.baskets[0]?.is_otdf && !showOTDFStrip) || (!masterDataService.baskets[0]?.is_otdf && masterDataService.isShownJioStrip) || (masterDataService.baskets[0]?.is_otdf && !masterDataService.isShownJioStrip)}" lines="none">
            <ion-thumbnail class="icon-mb-pass-wrap" slot="start">
              <ion-icon class="icons-property icon-white icon-mb-pass"></ion-icon>
            </ion-thumbnail>
            <ion-label class="ion-no-margin ion-text-wrap">
              <ion-text *ngIf="masterDataService.categoryType === mbpassType.PLATFORM;else categoryPercentageOne" class="text-size-sm d-block">
                {{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} <strong>{{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv - masterDataService.jioStripData.mbpassBasketValue}}</strong> {{MBPASS_STRIP_CONTENT.MORE}} {{MBPASS_STRIP_CONTENT.TO}} <strong>{{MBPASS_STRIP_CONTENT.TO_EARN}} {{masterDataService.masterData?.customer_mb_pass?.offerValue}}% {{MBPASS_STRIP_CONTENT.CASHBACK}}</strong>.
              </ion-text>
              <ng-template #categoryPercentageOne>
                <ion-text [ngClass]="{'text-animation': masterDataService.jioStripData.chashBackCount}" class="text-size-sm d-block">
                  <span id="cashback-count">{{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv -masterDataService.jioStripData.mbpassBasketValue}} {{MBPASS_STRIP_CONTENT.MORE}} {{MBPASS_STRIP_CONTENT.FROM}} {{masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.length > 1 ? 'select' + (masterDataService.masterData?.customer_mb_pass?.offerCategoryType === offerCategoryType.CATEGORY ?' categories':' sub-categories') :masterDataService.mbpassCategoryNames}} {{MBPASS_STRIP_CONTENT.TO}} <strong>{{MBPASS_STRIP_CONTENT.TO_EARN}} {{masterDataService.masterData?.customer_mb_pass?.offerValue}}% {{MBPASS_STRIP_CONTENT.CASHBACK}}</strong>.</span>
                  <!-- Duplicate-text span use for animation -->
                <!-- <span class="duplicate-text">{{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv -masterDataService.jioStripData.mbpassBasketValue}} {{MBPASS_STRIP_CONTENT.MORE}} {{MBPASS_STRIP_CONTENT.FROM}} {{masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.length > 1 ? 'select' + (masterDataService.masterData?.customer_mb_pass?.offerCategoryType === offerCategoryType.CATEGORY ?' categories':' sub-categories') :masterDataService.mbpassCategoryNames}} {{MBPASS_STRIP_CONTENT.TO_EARN}} {{masterDataService.masterData?.customer_mb_pass?.offerValue}}% {{MBPASS_STRIP_CONTENT.CASHBACK}}.</span> -->
                </ion-text>
              </ng-template>
            </ion-label>
          </ion-item>
        </ng-container>
        <!-- condition basket greater than or equal to mov plus max cashack is 200 but according to basket is calculated 150-->
        <ng-container *ngIf="((masterDataService.jioStripData.currentCashback < masterDataService.masterData?.customer_mb_pass?.maxCashback) && masterDataService.jioStripData.mbpassBasketValue >= masterDataService.masterData?.customer_mb_pass?.mbv)">
        <ion-item class="cashback-info redeem-partial" lines="none" color="none">
            <ion-icon class="icon-trophy-secondary" slot="start"></ion-icon>
            <ion-label class="ion-no-margin ion-text-wrap">
                {{MBPASS_STRIP_CONTENT.YOU_HAVE_EARNED}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.jioStripData.currentCashback | number : '1.2-2'}} {{MBPASS_STRIP_CONTENT.CASHBACK}} <strong>({{MBPASS_STRIP_CONTENT.MAX}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.maxCashback}})</strong>
            </ion-label>
            <ion-range
              class="ion-no-padding" [max]="100" style="z-index: -1"
              [value]="((masterDataService.jioStripData.currentCashback/masterDataService.masterData?.customer_mb_pass?.maxCashback)*100)">
            </ion-range>
          </ion-item>
          
        </ng-container>
     </ng-container>
    </ng-container>
    <ng-container *ngIf="masterDataService.masterData?.customer_mb_pass?.offerType === offerType.FLAT">
      <ng-container *ngIf="masterDataService.jioStripData.mbpassBasketValue < masterDataService.masterData?.customer_mb_pass?.mbv;else achieved">
        <!-- condition for basket zero -->
        <ng-container *ngIf="masterDataService.jioStripData.mbpassBasketValue === 0">
         <ion-item class="cashback-info" [ngClass]="{'has-pager': masterDataService.jioStripData.chashBackCount,'has-pager-base' : !masterDataService.baskets[0]?.is_otdf || (masterDataService.baskets[0]?.is_otdf && !showOTDFStrip) || (!masterDataService.baskets[0]?.is_otdf && masterDataService.isShownJioStrip) || (masterDataService.baskets[0]?.is_otdf && !masterDataService.isShownJioStrip)}" lines="none">
         <ion-thumbnail class="icon-mb-pass-wrap" slot="start">
            <ion-icon class="icons-property icon-white icon-mb-pass"></ion-icon>
          </ion-thumbnail>
           <ion-label class="ion-no-margin ion-text-wrap">
             <ion-text *ngIf="masterDataService.categoryType === mbpassType.PLATFORM;else categoryFlatZero" class="text-size-sm d-block">
               {{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} <strong>{{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv}}</strong> {{MBPASS_STRIP_CONTENT.TO}} <strong>{{MBPASS_STRIP_CONTENT.TO_EARN}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.offerValue}} {{MBPASS_STRIP_CONTENT.CASHBACK}}</strong>.
             </ion-text>
             <ng-template #categoryFlatZero>
              <ion-text [ngClass]="{'text-animation': masterDataService.jioStripData.chashBackCount}" class="text-size-sm d-block">
                <span id="cashback-count">{{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} <strong>{{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv}}</strong> {{MBPASS_STRIP_CONTENT.FROM}} {{masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.length > 1 ? 'select' + (masterDataService.masterData?.customer_mb_pass?.offerCategoryType === offerCategoryType.CATEGORY ?' categories':' sub-categories') :masterDataService.mbpassCategoryNames}} {{MBPASS_STRIP_CONTENT.TO}} <strong>{{MBPASS_STRIP_CONTENT.TO_EARN}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.offerValue}} {{MBPASS_STRIP_CONTENT.CASHBACK}}</strong>.</span>
                <!-- Duplicate-text span use for animation -->
                <!-- <span class="duplicate-text">{{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv}} {{MBPASS_STRIP_CONTENT.FROM}} {{masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.length > 1 ? 'select' + (masterDataService.masterData?.customer_mb_pass?.offerCategoryType === offerCategoryType.CATEGORY ?' categories':' sub-categories') :masterDataService.mbpassCategoryNames}} {{MBPASS_STRIP_CONTENT.TO_EARN}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.offerValue}} {{MBPASS_STRIP_CONTENT.CASHBACK}}.</span> -->
              </ion-text>
             </ng-template>
           </ion-label>
         </ion-item>
        </ng-container>
        <!-- basket value less than mov otherwise else -->
        <ng-container *ngIf="(masterDataService.jioStripData.mbpassBasketValue > 0 && masterDataService.jioStripData.mbpassBasketValue < masterDataService.masterData?.customer_mb_pass?.mbv) ">
         <ion-item class="cashback-info" [ngClass]="{'has-pager': masterDataService.jioStripData.chashBackCount || masterDataService.categoryType === mbpassType.PLATFORM,'has-pager-base' : !masterDataService.baskets[0]?.is_otdf || (masterDataService.baskets[0]?.is_otdf && !showOTDFStrip) || (!masterDataService.baskets[0]?.is_otdf && masterDataService.isShownJioStrip) || (masterDataService.baskets[0]?.is_otdf && !masterDataService.isShownJioStrip)}" lines="none">
         <ion-thumbnail class="icon-mb-pass-wrap" slot="start">
            <ion-icon class="icons-property icon-white icon-mb-pass"></ion-icon>
          </ion-thumbnail>
           <ion-label class="ion-no-margin ion-text-wrap">
             <ion-text *ngIf="masterDataService.categoryType === mbpassType.PLATFORM;else categoryFlatOne" class="text-size-sm d-block">
              {{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} <strong>{{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv - masterDataService.jioStripData.mbpassBasketValue}}</strong> {{MBPASS_STRIP_CONTENT.MORE}} {{MBPASS_STRIP_CONTENT.TO}} <strong>{{MBPASS_STRIP_CONTENT.TO_EARN}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.offerValue}} {{MBPASS_STRIP_CONTENT.CASHBACK}}</strong>.
             </ion-text>
             <ng-template #categoryFlatOne>
              <ion-text [ngClass]="{'text-animation': masterDataService.jioStripData.chashBackCount}" class="text-size-sm d-block">
               <span id="cashback-count">{{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv -masterDataService.jioStripData.mbpassBasketValue}} {{MBPASS_STRIP_CONTENT.MORE}} {{MBPASS_STRIP_CONTENT.FROM}} {{masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.length > 1 ? 'select' + (masterDataService.masterData?.customer_mb_pass?.offerCategoryType === offerCategoryType.CATEGORY ?' categories':' sub-categories') :masterDataService.mbpassCategoryNames}} {{MBPASS_STRIP_CONTENT.TO}} <strong>{{MBPASS_STRIP_CONTENT.TO_EARN}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.offerValue}} {{MBPASS_STRIP_CONTENT.CASHBACK}}</strong>.</span>
               <!-- Duplicate-text span use for animation -->
                <!-- <span class="duplicate-text">{{MBPASS_STRIP_CONTENT.ADD_ITEMS_OF}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.mbv -masterDataService.jioStripData.mbpassBasketValue}} {{MBPASS_STRIP_CONTENT.MORE}} {{MBPASS_STRIP_CONTENT.FROM}} {{masterDataService.masterData?.customer_mb_pass?.offerCategoryValue.length > 1 ? 'select' + (masterDataService.masterData?.customer_mb_pass?.offerCategoryType === offerCategoryType.CATEGORY ?' categories':' sub-categories') :masterDataService.mbpassCategoryNames}} {{MBPASS_STRIP_CONTENT.TO_EARN}} {{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.offerValue}} {{MBPASS_STRIP_CONTENT.CASHBACK}}.</span> -->
               </ion-text>
             </ng-template>
           </ion-label>
         </ion-item>
       </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #achieved>
    <!-- when get cshback is greter than maxcashback or flat -->
      <ion-item class="cashback-info redeem" lines="none" color="none">
        <ion-icon class="icon-trophy-secondary" slot="start"></ion-icon>
        <ion-label class="ion-no-margin ion-text-wrap" [ngClass]="{'pr-0' : !masterDataService.baskets[0]?.is_otdf || (masterDataService.baskets[0]?.is_otdf && !showOTDFStrip)}">
            {{MBPASS_STRIP_CONTENT.CONGRATS_YOU_HAVE_EARNED}} <strong>{{MBPASS_STRIP_CONTENT.RS}}{{masterDataService.masterData?.customer_mb_pass?.offerType === offerType.FLAT ?
            masterDataService.masterData?.customer_mb_pass?.offerValue:
            masterDataService.masterData?.customer_mb_pass?.maxCashback}}</strong> {{MBPASS_STRIP_CONTENT.CASHBACK}} {{MBPASS_STRIP_CONTENT.FOR}} {{MBPASS_STRIP_CONTENT.TODAY}}!
        </ion-label>
      </ion-item>
  </ng-template>
        </ng-template>
      
      </ng-container>

      </swiper>
</ion-footer>