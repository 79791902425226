const thousandSeparator = (amount: number, roundTo = 2) => {
    // using regex for thousand separator bcoz it is faster than toLocaleString() && Intl.numberformat();
    return amount.toFixed(roundTo).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const leftPad = (number: number, digits: number) => {
    return +Array(Math.max(digits - String(number).length + 1, 0)).join('0') + number;
};

const generateRandomNumber = (power = 2): number => {
    return Math.floor(Math.random() * 10 ** power);
};

export { thousandSeparator, leftPad, generateRandomNumber };
