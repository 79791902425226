import { UserDetail } from './user-detail';

export class UserDetails {
    __typename = 'UserDetails';
    user: UserDetail;

    setUserDetail(userApi) {
        try {
            const user = new UserDetail();
            this.user = user.setUserDetailFromApi(userApi);
            return this;
        } catch {
            return null;
        }
    }
}
