<ion-header class="ion-main-header sticky-header">
    <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium pl-0">
        <div class="d-flex">
        <ion-buttons class="ion-buttons-arrow-back" id="custom-arrow-back" slot="start">
            <ion-button (click)="closeModal()">
                <ion-icon class="icon-arrow-back icons-property icon-md" color="dark" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-no-padding ion-text-capitalize">
            {{PG_WISE_JUSPAY_PAY_LOAD[pg.value].name }}
        </ion-title>
        </div>
    </ion-toolbar>
</ion-header>
<div class="payment-list padding-top-base" *ngIf="masterDataService.paymentOptionSection?.[pg.value]?.offers?.length || masterDataService.paymentOptionSection?.[pg.value]?.commonOffers?.length">
    <mbs-payment-offer-listing [showLabel]="false" [offerList]="masterDataService.paymentOptionSection?.[pg.value]?.offers" [commonOffers] = "masterDataService.paymentOptionSection?.[pg.value]?.commonOffers"></mbs-payment-offer-listing>
</div>
<ion-content class="ion-no-padding card-details theme-light">
    <form class="form" #cardDetailsForm="ngForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="submit()">
        <div class="card-holder padding-horizontal-medium margin-top-base">
            <ion-card class="ion-no-margin" *ngIf="pg.value != PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName">
                <ion-card-content>
                    <ion-row class="ion-justify-content-between">
                        <span>Amount</span>
                        <strong class="f-w-b">₹ {{amount.amount | number: '1.0'}}</strong>
                    </ion-row>
                    <ion-row class="ion-justify-content-between" *ngIf="pg.value !== PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName && pg.value !==
          PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName">
                        <span>{{amount.percentage}}% Processing Fees </span>
                        <strong><span class="text-line-through padding-right-extra-small f-w-n ion-text-muted">₹
              {{amount.amount * (amount.percentage/100) | number: '1.0'}}</span> ₹
            {{amount.percentageAmount}}</strong>
                    </ion-row>
                </ion-card-content>
                <ion-card-header *ngIf="pg.value !== PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName && pg.value !==
          PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName">
                    <ion-row class="ion-justify-content-between">
                        <strong class="f-w-s-b">Amount to be deducted</strong>
                        <strong class="f-w-s-b">₹ {{amount.totalAmountDeducted}}</strong>
                    </ion-row>
                </ion-card-header>
            </ion-card>
        </div>
        <div class="card-detail-listing margin-top-base">
            <!-- <ion-radio-group class="payment-option" [value]="whichCardSelected" (ionChange)="changeSelectedCard($event)"> -->
            <div class="ion-no-padding payment-option-list active-child" *ngFor="let card of cards;let i = index">
                <!-- item-checked : add this class in case of active -->
                <ion-item color="none" lines="none" [ngClass]="{'item-checked' : whichCardSelected === '$$$$_'+i}" class="active-border padding-left-medium br-bottom overflow-none padding-vertical-extra-medium" (click)="whichCardSelected !== '$$$$_'+i && changeSelectedCard('$$$$_'+i)">
                    <div class="has-full-width">
                        <div class="payment-details">
                            <div class="d-flex ion-align-items-center">
                                <div slot="start" class="d-flex ion-align-items-center">
                                    <!-- <ion-radio class="ion-align-self-start active-payments" [value]="'$$$$_'+i"></ion-radio> -->
                                    <img alt="payment card type" [src]="cardType[card.cardBrand]?.icon" onError="this.src='../../../../assets/img/credit-card.svg'" class="pm-w" />
                                    <ion-label class="ion-text-wrap ion-no-margin">
                                        <p class="p-title f-w-m">
                                            <span class="f-m icon ">{{card.cardNumber}}</span>
                                        </p>
                                        <p class="name">{{card.cardIssuer === 'SODEXO' ? 'Pluxee' : card.cardIssuer}}</p>
                                    </ion-label>
                                </div>
                                <div slot="end" class="context-menu" *ngIf="pg.value !== PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName ">
                                    <div class="logout-cont margin-right-base">
                                        <ion-item class="ion-no-padding has-delete-icon" lines="none" color="none">
                                            <ion-icon class="ion-no-margin icon-trigger icons-property icon-md" slot="end" mode="md"></ion-icon>
                                            <ion-select slot="end" class="ion-no-padding ion-no-margin" interface="popover" (ionChange)="deleteSavedCard($event, card, i)">
                                                <ion-icon class="icon-trash icons-property"></ion-icon>
                                                <ion-select-option value="Remove" class="ion-no-padding padding-left-medium">Remove
                                                </ion-select-option>
                                            </ion-select>
                                        </ion-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ion-item-wrap has-width f-group" *ngIf="whichCardSelected === '$$$$_'+i && (pg.value === PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName || pg.value ===
            PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName || pg.value === PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName ||
            PG_WISE_JUSPAY_PAY_LOAD.EDENRED.pgName)">
                            <div>
                                <ion-list-header class="margin-top-base ion-no-padding ion-cvv">
                                    <ion-label class="sub-heading f-w-b padding-horizontal-0">CVV Code</ion-label>
                                </ion-list-header>
                                <ion-row class="d-flex ion-align-items-center">
                                    <ion-item class="ion-no-padding">
                                        <ion-input type="password" ngControl="cvv" name="cvv" [maxlength]="cardType[card.cardBrand].cvvLength" [pattern]="cardType[card.cardBrand].cvvPattern" [(ngModel)]="cardDetails.cvv" class="ion-no-padding" placeholder="Enter CVV" required>
                                        </ion-input>
                                    </ion-item>
                                    <ion-buttons>
                                        <ion-button class="help-button" (click)="showCvvInfo()">
                                            <ion-icon slot="icon-only" class="icon-help icon-md icons-property" color="primary"></ion-icon>
                                        </ion-button>
                                    </ion-buttons>
                                </ion-row>
                                <ion-text color="danger" class="saved-card-cvv" *ngIf="cardDetailsForm.controls['cvv'] &&
                (cardDetailsForm.controls['cvv'].dirty &&
                  cardDetailsForm.controls['cvv'].touched)">
                                    <small margin-top-smallest *ngIf="cardDetailsForm.controls['cvv'].errors?.required &&
                    !cardDetailsForm.controls['cvv'].errors?.pattern">
                  CVV is required
                </small>
                                    <small margin-top-smallest *ngIf="cardDetailsForm.controls['cvv'].errors?.pattern &&
                    !cardDetailsForm.controls['cvv'].errors?.required">
                  CVV is not valid
                </small>
                                </ion-text>
                            </div>
                            <ion-button type="submit" [disabled]="(card.cardBrand === cardBrands.AMEX ? cardDetails?.cvv?.length < 4 : cardDetails?.cvv?.length < 3)"
                            size="large" shape="round" mode="md" expand="block" class="mw-large ion-margin-bottom margin-top-medium t-t-n padding-right-medium">
                                Pay ₹ {{amount.totalAmountDeducted}}</ion-button>
                        </div>
                    </div>
                </ion-item>
            </div>
            <!-- </ion-radio-group> -->
            <!-- item-checked : add this class in case of active -->
            <div value="open" [ngClass]="{'item-checked' : whichCardSelected === '$$$$'}">
                <ion-item color="none" class="padding-left-medium overflow-none padding-vertical-extra-medium payment-option-list" lines="none" (click)="cardSelectionChange()">
                    <div class="payment-details padding-right-extra-medium">
                        <div class="d-flex ion-align-items-center">
                            <div slot="start" class="d-flex ion-align-items-center">
                                <img alt="Debit card" src="../../../../assets/img/debit-card.svg" class="pm-w" />
                                <ion-label class="ion-text-wrap ion-no-margin">
                                    <p class="p-title f-w-m">
                                        <span class="f-m icon ">Add {{PG_WISE_JUSPAY_PAY_LOAD[pg.value].name }}</span>
                                    </p>
                                    <p class="name">Add Another {{PG_WISE_JUSPAY_PAY_LOAD[pg.value].name }}</p>
                                </ion-label>
                                <ion-buttons>
                                    <ion-button fill="clear">
                                        <ion-icon class="icon-down-arrow icons-property">
                                        </ion-icon>
                                    </ion-button>
                                </ion-buttons>
                            </div>
                        </div>
                    </div>

                </ion-item>
                <ion-list class="f-group collapse padding-horizontal-medium">
                    <!-- <ion-label class="ion-text-wrap ion-no-margin d-inline-block margin-bottom-medium">
              We accept Credit and Debit Cards from Visa, Mastercards, American Express.
            </ion-label> -->
                    <div>
                        <div class="ion-item-wrap form-content margin-bottom-extra-big-large">
                            <ion-label position="floating" class="f-w-b">Name on card </ion-label>

                            <ion-item class="ion-no-padding" lines="none" color="none">
                                <ion-input type="text" ngControl="cardName" name="cardName" [(ngModel)]="cardDetails.cardName" class="ion-no-padding" placeholder="Name on card">
                                </ion-input>
                            </ion-item>
                        </div>
                        <div class="ion-item-wrap form-content margin-bottom-extra-big-large">
                            <ion-label position="floating" class="f-w-b">Card Number</ion-label>
                            <ion-item class="ion-no-padding" lines="none" color="none">
                                <ion-input ngControl="cardNumber" [pattern]="cardValidations.cardPattern" name="cardNumber" [(ngModel)]="cardDetails.cardNumber" type="tel" class="ion-no-padding" (ionInput)="restrictToDigits($event)" placeholder="Enter Card Number" required>
                                </ion-input>
                            </ion-item>
                            <ion-text color="danger" *ngIf="!error.showError && cardDetailsForm.controls['cardNumber'] &&
                  (cardDetailsForm.controls['cardNumber'].dirty &&
                    cardDetailsForm.controls['cardNumber'].touched)">
                                <small margin-top-smallest class="f-m" *ngIf="cardDetailsForm.controls['cardNumber'].errors?.required &&
                  !cardDetailsForm.controls['cardNumber'].errors?.pattern">
                CARD NUMBER is required
              </small>
                                <small margin-top-smallest class="f-m" *ngIf="cardDetailsForm.controls['cardNumber'].errors?.pattern &&
                  !cardDetailsForm.controls['cardNumber'].errors?.required">
                CARD NUMBER is not valid
              </small>
                            </ion-text>
                            <ion-text color="danger" *ngIf="error.showError">
                                <small margin-top-smallest class="f-m">{{error.errorMessage}}</small>
                            </ion-text>
                        </div>
                        <div class="ion-item-wrap form-content margin-bottom-extra-big-large">
                            <ion-label position="floating" class="f-w-b">Expiry Date</ion-label>
                            <ion-item class="ion-no-padding" color="none">
                                <ion-input type="tel" maxlength="5" (ionInput)="appendHyphen($event)" ngControl="valid" name="valid" pattern="^(0[1-9]|1[0-2])\/[2-5][0-9]" [(ngModel)]="cardDetails.valid" class="ion-no-padding" placeholder="(MM/YY)" required>
                                </ion-input>
                            </ion-item>
                            <ion-text color="danger" *ngIf="cardDetailsForm.controls['valid'] &&
                  (cardDetailsForm.controls['valid'].dirty &&
                    cardDetailsForm.controls['valid'].touched)">
                                <small margin-top-smallest class="f-m" *ngIf="cardDetailsForm.controls['valid'].errors?.required && !cardDetailsForm.controls['valid'].errors?.pattern">
                VALIDITY is required
              </small>
                                <small margin-top-smallest class="f-m" *ngIf="cardDetailsForm.controls['valid'].errors?.pattern && !cardDetailsForm.controls['valid'].errors?.required">
                VALIDITY is not valid
              </small>
                            </ion-text>
                        </div>
                        <div class="ion-item-wrap form-content margin-bottom-extra-big-large input-cvv">
                            <ion-label position="floating" class="f-w-b">CVV Code</ion-label>
                            <ion-item class="ion-no-padding has-icon" lines="none" color="none">
                                <ion-input type="password" [maxlength]="cardValidations.cvvLength" [pattern]="cardValidations.cvvPattern" ngControl="cvv" name="cvv" [(ngModel)]="cardDetails.cvv" class="ion-no-padding" placeholder="Enter CVV" required>
                                </ion-input>
                            </ion-item>
                            <ion-buttons slot="end" (click)="showCvvInfo()" class="dark-default">
                                <ion-button>
                                    <ion-icon slot="icon-only" class="icon-help icon-md icons-property" color="primary"></ion-icon>
                                </ion-button>
                            </ion-buttons>
                            <ion-text color="danger" *ngIf="cardDetailsForm.controls['cvv'] &&
                  (cardDetailsForm.controls['cvv'].dirty &&
                    cardDetailsForm.controls['cvv'].touched)">
                                <small margin-top-smallest class="f-m" *ngIf="cardDetailsForm.controls['cvv'].errors?.required &&
                    !cardDetailsForm.controls['cvv'].errors?.pattern">
                CVV is required
              </small>
                                <small margin-top-smallest class="f-m" *ngIf="cardDetailsForm.controls['cvv'].errors?.pattern &&
                    !cardDetailsForm.controls['cvv'].errors?.required">
                CVV is not valid
              </small>
                            </ion-text>
                        </div>
                    </div>
                    <div class="checkbox-holder">
                        <ion-item lines="none" class="ion-no-padding no-ripple-effect">
                            <ion-checkbox name="saveToLocker" [(ngModel)]="cardDetails.saveToLocker" ngControl="saveToLocker" slot="start" checked>
                            </ion-checkbox>
                            <ion-label class="ion-text-wrap">Save this detail for further transaction</ion-label>
                        </ion-item>
                        <ion-item lines="none" class="ion-no-padding no-ripple-effect" *ngIf="pg.value === PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName">
                            <ion-checkbox name="autoDebitOneRupeesDeduct" [(ngModel)]="cardDetails.autoDebitOneRupeesDeduct" ngControl="autoDebitOneRupeesDeduct" slot="start" checked>
                            </ion-checkbox>
                            <ion-label class="ion-text-wrap">₹1 will be deducted from your card for verification. It will be credited to your wallet once your card is verified</ion-label>
                        </ion-item>
                    </div>

                    <ion-button type="submit" [disabled]="error.showError || !(cardDetailsForm.valid && (!(pg.value === PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName) || (cardDetails.saveToLocker && cardDetails.autoDebitOneRupeesDeduct)))" size="large" shape="round" mode="md"
                        expand="block" class="mw-large ion-margin-bottom margin-top-medium t-t-n ">
                        Add & Pay ₹ {{amount.totalAmountDeducted}}</ion-button>
                </ion-list>
            </div>
        </div>
        <!-- <ion-button type="submit" size="large" shape="round" fill="outline" mode="md" expand="block"
    class="mw-large ion-margin-bottom margin-top-medium t-t-n margin-horizontal-medium"> Add New Card</ion-button> -->
    </form>
</ion-content>