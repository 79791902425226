<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon class="icon-close icons-property icon-xmd" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div class="form">
    <div class="f-body">
      <ion-thumbnail slot="start" class="ion-margin-bottom">
        <ion-img [alt]="offer?.title.text || pg?.offer"  *ngIf="offer" [src]="bannerFilePath + offer.images.leafBanner.image"></ion-img>
        <ion-img [alt]="offer?.title.text || pg?.offer"  *ngIf="pg" [src]="pgImagePath + dataService.firebaseMasterData.pgImagesData[pg.value]"></ion-img>
      </ion-thumbnail>
      <h2>{{offer?.title.text || pg?.offer}}</h2>
      <div class="pdp-listing-details ion-margin-top ion-padding-vertical">
        <span *ngIf="offer" [innerHTML]="offer?.description"></span>
        <ng-container  *ngIf="pg">
        <p><b>Offer Details</b></p>
        <ul>
          <li *ngFor="let pgOffer of pg.offer_details">{{pgOffer}}</li>
        </ul>
        </ng-container>
      </div>
    </div>
    <div class="f-footer">
      <ion-button margin-top-large size="default" (click)="navigateToShoppingCredit()" expand="block" class="mw-large">
       Add Money</ion-button>
    </div>
  </div>
</ion-content>
