//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
import { PromoCodeOfferModule } from '@Shared/payment/promo-code-offer/promo-code-offer.module';
//#endregion Module Imports

//#region Component Imports
import { InviteComponent } from '@Features/payment/invite/invite.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PromoCodeOfferModule
  ],
  exports: [InviteComponent],
  declarations: [InviteComponent]
})
export class InviteModule extends DefaultModule { }