//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { HTTPResponse } from '@ionic-native/http';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES } from '@Globals/constants/listing/constants';
import { OnlySchedulingOrNot, ProductListType } from '@Globals/constants/listing/enums';
import { LISTING_ROUTES } from '@Globals/constants/listing/routes';

import { Utilities } from '@Globals/classes/utilities';

import { environment } from '@Env/environment';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-explore-collection-block',
  templateUrl: './explore-collection.component.html',
  styleUrls: ['./explore-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExploreCollectionComponent extends BaseCollectionComponent {
  eventSource = EVENT_NAMES.HOME;
  coloumIteration = 0;
  horizontalProductListingWithHeader =
    ProductListType.Horizontal_Listing_With_Headers;
  exploreCollectionData;
  collectionTableData: any = [];
  productList;
  onlySchedulingOrNot = OnlySchedulingOrNot;
  backgroundUrl: string;
  eventData: any;
  
  mbOnInit(): void {
    this.collectionTableData = Utilities.deepCloningOfAnArray(this.data);
    this.collectionTableData.table.rows = [{ columns: [] }];
    this.coloumIteration = 0;
    this.data.table.rows.forEach((row, rowIndex) => {
      row.columns.forEach((column, columnIndex) => {
        this.coloumIteration++;
        if (rowIndex === 0 && columnIndex === 0) {
          this.exploreCollectionData = column;
          this.backgroundUrl = `${environment.bannerPath}${this.exploreCollectionData.images.leafBanner.image}`;
        } else if (
          column.onlySchedulingOrNot === this.onlySchedulingOrNot.ProductListing
        ) {
          this.getCollectionsProductList(column);
        } else {
          this.collectionTableData.table.rows[0].columns.push(column);
        }
      });
    });
    if(this.props) {
      this.eventData = { Source: this.props?.eventSource, collection_id: this.data.id };
    }
    this._cd.markForCheck();
    super.mbOnInit();
  }

  getCollectionsProductList(column) {
    let leafRequest = []
    this.data?.metaData?.cells?.forEach(metaData => {
      leafRequest.push({
        leafId: metaData.collectionLeafId,
        listId: metaData.image.leafBanner.listId
      });
    });

    const metaData = this.data.metaData.cells.filter(
      (cell) => cell.identifier === column.identifier
    );
    const dataToSend: any = {
      collectionId: this.data.id,
      leafRequest: [
        {
          leafId: metaData[0].collectionLeafId,
          listId: metaData[0].image.leafBanner.listId,
        },
      ],
      hideLoader: true,
    };
    this.collectionService
      .getAllCollectionPlp(dataToSend)
      .subscribe((response: HTTPResponse) => {
        if (
          response.data.status &&
          response.data.data &&
          response.data.data[dataToSend.leafRequest[0].leafId]
        ) {
          this.productList = {
            products: response.data.data[dataToSend.leafRequest[0].leafId].data.slice(0, 10),
            head: column.title.text,
            collectionData: this.data,
            leafRequest: leafRequest,
            collection: LISTING_ROUTES.BASE + LISTING_ROUTES.COLLECTIONPLP + dataToSend.collectionId + '/' + dataToSend.leafRequest[0].leafId
          };
          this._cd.markForCheck();
        }
      });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
