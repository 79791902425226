//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { MomentModule } from 'ngx-moment';
import { InViewportModule } from 'ng-in-viewport';
//#endregion Library Imports

//#region Module Imports
import { RcSubscribeSavingStripModule } from '@Shared/product-listing/rc-subsave-strip/rc-subsave-strip.module';
import { RestOfTheMonthRcStripModule } from '@Shared/product-listing/rom-rc-strip/rom-rc-strip.module';
import { SimilarProductsModule } from '@Shared/product-listing/similar-products/similar-products.module';
import { BundleProductsModule } from '@Shared/product-listing/bundle-products/bundle-products.module';
import { AllCollectionModule } from '@Widgets/blocks/collection/all/all.module';
import { SafeHtmlModule } from '@Globals/pipes/safe-html/safe-html.module';
//#endregion Module Imports

//#region Component Imports
import { ListingComponent } from '@Features/product-list/components/vertical/listing/listing.component';
import { VariantsComponent } from '@Features/product-list/components/vertical/listing/components/variants/variants.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import annotations from '@Features/product-list/product-list.annotations';
import { SingleBrandModule } from '../../horizontal/single-brand/single-brand.module';
//#endregion Other Imports

//#endregion Imports

@NgModule({
  declarations: [ListingComponent, VariantsComponent],
  imports: [...annotations.imports, MomentModule, InViewportModule, RcSubscribeSavingStripModule, RestOfTheMonthRcStripModule, SimilarProductsModule, BundleProductsModule, AllCollectionModule,
  SafeHtmlModule, SingleBrandModule],
  exports: [...annotations.exports, ListingComponent, VariantsComponent],
  providers: [...annotations.providers],
})
export class ListingModule { }
