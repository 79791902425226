//#region Imports
//#region Angular Imports
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Other Imports
import { APIs } from '@App/shared/constants/APIs';
//#endregion Other Imports

//#region Providers Imports 
import { HttpService } from '@App/shared/providers/http/http.service'; 
import { MasterDataService } from '@Globals/providers/master-data.service';
import { HTTPResponse } from '@ionic-native/http';
//#endregion Providers Imports


//#endregion Imports

@Component({
  selector: 'app-membership-terms-and-condition',
  templateUrl: './membership-terms-and-condition.component.html',
  styleUrls: ['./membership-terms-and-condition.component.scss'],
})
export class MembershipTermsAndConditionComponent {
  enableSubmitButton:boolean=false;
  url:any;
  constructor(private modalCtrl: ModalController,public sanitizer: DomSanitizer,  private httpService: HttpService,  private masterDataService: MasterDataService) {
    this.url = this.getSafeUrl('https://www.milkbasket.com/terms-and-conditions.html')
  }
  


  closeModal(){
    this.httpService
     .put(APIs.USER_TNC, {acceptTnc:1,tncVersion:this.masterDataService.staticMasterData.tncVersion})
     .subscribe((response: HTTPResponse) => {
       if(response && response.status){
        this.masterDataService.masterData?.user?.cinfo && (this.masterDataService.masterData.user.cinfo.acceptTnc = 1);
        this.modalCtrl.dismiss();
       }
     })
   }
  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


}
