//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CollectionDisplayType } from '@App/listing/constants/listing-enums';
import { environment } from '@Env/environment';
import { HTTPResponse } from '@ionic-native/http';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports

//#endregion Other Imports

//#endregion Imports
@Component({
  selector: 'wgt-deals-of-the-day-list-block',
  templateUrl: './deals-of-day-products-list.component.html',
  styleUrls: ['./deals-of-day-products-list.component.scss'],
})
export class DealsOfTheDayProductsListComponent extends BaseCollectionComponent {
  backgroundUrl:string;
  eventData;
  showDealsOfTheList:boolean =false;
  mbOnInit(): void {
    this.data && this.getCollectionListProducts();
    if(this.data) {
      if(this.data.table?.rows?.length){
        this.data.table.rows.forEach((row, rowIndex) => {
          if(row.columns?.length){
            row.columns.forEach((column, columnIndex) => {
              if (rowIndex === 0 && columnIndex === 0) {
                this.backgroundUrl = `${environment.bannerPath}${column.images.leafBanner.image}`;
              }
            })
          }
        })
      }
      setTimeout(()=>{
        this.dealsOfTheProductMarketValue();
      },1000)
    }

    if(this.props) {
      this.eventData = { Source: this.props?.eventSource, collection_id: this.data.id,collectionDisplayType:CollectionDisplayType.DealsOfTheDayCollection };
    }
    super.mbOnInit();
  }

  dealsOfTheProductMarketValue(){
    this.collectionService.dealsOfTheDayProductList({collectionId:this.data?.id}).subscribe((response: HTTPResponse) => {
      if(response.status){
        if(response.data?.length) {
        const dealsOfTheDayProduct = {};
        response.data.map(product=>dealsOfTheDayProduct[product?.productId]=product);          
          this.data?.productList &&  this.data?.productList.map((product,index)=>{
              if(dealsOfTheDayProduct[product.product_id]){
                this.data.productList[index]['dodPrice']=dealsOfTheDayProduct[product.product_id]['value'];
              }
            }) 
          }
            this.showDealsOfTheList = true;      
      }
    })

  }
}
