//#region Imports

//#region Angular Imports
import { Injectable } from "@angular/core";
//#endregion Angular Imports

//#region Ionic Imports

//#endregion Ionic Imports

//#region Library Imports
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { HttpService } from '@Globals/providers/http.service';
//#endregion Providers Imports

//#region Other Imports
import { LISTING_APIS } from '@Globals/constants/listing/apis';
import { environment } from "@Env/environment";
//#endregion Other Imports

//#endregion Imports

@Injectable()
export class AccountHistoryService {
  constructor(private httpService: HttpService,private http: HttpClient) {}

  getAccountHistory(): Observable<object> {
    return this.httpService.get(LISTING_APIS.ACCOUNT_HISTORY, {});
  }

  getOrderDetails(orderId): Observable<object> {
    if (orderId) {
      return this.httpService.post(LISTING_APIS.ORDER_DETAILS, {
        order_id: orderId
      });
    } else {
      return this.httpService.get(LISTING_APIS.TODAYS_NEW);
    }
  }
  getOrderInvoiceNumber(orderId){
    return this.httpService.get(`${LISTING_APIS.GENERATE_INVOICE_NUMBER}/${orderId}`);
  }

  downloadPdf(orderId){
    return this.httpService.downloadPdf(`${environment.apiUrl}${LISTING_APIS.GENERATE_INVOICE_NUMBER}/${orderId}/download`);

  }

  generateUserInvoice(orderId,dataToBeSent) {
    return this.httpService.put(`${LISTING_APIS.GENERATE_INVOICE_NUMBER}/${orderId}/generate`, dataToBeSent);
  }


}
