export const NUMBER_OF_ITEMS_TO_DISPLAY_INC = 5;
export {
    A2B_API_ERRORS,
    BUNDLE_VIEW,
    COUNTER_DATA_FOR_IMPULSE_PRODUCT,
    EVENT_NAMES,
    EVENT_SOURCES,
    EVENT_ARGS,
    HOLIDAY_CUT_OFF_TIME_CONFIRMATION,
    PAST_CUT_OFF_TIME_CONFIRMATION,
    TIME_RECHECK_GET_CONFIRMATION,
    PRE_ORDER_POST_ORDER_END_DATE_ERROR,
    NON_MBEYOND_USER_REDIRECTION,
    HOLIDAY_BASKET_TOAST_MESSAGE,
    REC_COACHMARK_REPEAT_FREQUENCY,
    SUBSCRIPTION_BASKET_REMOVAL_CONFIRMATION,
    PRODUCT_DETAILS,
    LOGIN_SIGNUP_CONFIRMATION,
    MAX_QUANTITY_REACHED_ALERT,
    NUMBER_OF_ITEMS_TO_DISPLAY,
    ITEM_TYPE_FOR_BUNDLE_PRODUCT,
    FROZEN_PRODUCT_DELIVERY_INFO,
    VENDOR_OFF,
    EDIT_RECURRING_ALERT,
    DELETE_RECURRING_ALERT,
    RECURRING_SUCCESS_ALERT,
    SCHEDULE_SUCCESS_ALERT,
    LOW_BALANCE_ERROR_MESSAGES,
    COLLECTION_KEYS,
    YFO_CATEGORY_ID,
    REC_RECOMMENDATION_TEXT,
    CAMPAIGN_CASHBACK_TYPE,
    WHATSAPP,
    RATING_CONTENT,
    COLLECTION_LOCATION_TYPE,
    ADDITEM_ALERT,
    BUY_MEMBERSHIP_CONFIRMATION,
    SEARCH_EMPTY_SCREEN_CONTENT,
    SEARCH_PAGE_EMPTY_SCREEN_CONTENT,
    SEARCH_PLACEHOLDER,
    ORDER_EMPTY_STATE,
    GST_NOTE,
    PRICE_FLUCTUATION_INFO,
    CALENDAR_FREQUENCIES,
    RECURRING_DELETE_CONFIRMATION,
    FREQUENCY_CONSTANTS,
    SUCCESS_MESSAGE_LIST_RECURRING,
    PAUSE_ALERT,
    RECURRING_CAMPAIGN_BOTTOM_TEXT,
    CALENDAR_HEADING,
    DAY29_ALERT,
    DAY31_ALERT,
    PAUSE_CALENDAR_ALERT,
    START_DATE,
    END_DATE,
    RECURRING_TEXT,
    CALENDAR_JOURNEY_BASE_URL,
    DAYS_LIST,
    DEFAULT_BANNER_MAX_LIMIT,
    MBEYOND,
    VIDEO_TYPE,
    RECYCLE_BAG_MATERIAL_TAGS,
    RECYCLE_BAGS_INSTRUCTION,
    TETRA_PACKS_BAGS_INSTRUCTION,
    MB_PASS,
    NPS_SUCCESS,
    TERMS_AND_CONDITIONS,
    GENERATE_LIST_FROM_ARRAY,
    SCROLL_UP_DURATION,
} from '@App/listing/constants/listing-constants';
export {
    OFFER_VIEW_TYPE,
    ALERT_BUTTON_TEXT,
    OFFER_REMOVED,
    CASHBACK_REMOVED,
    OFFER_PASS_REMOVED,
    STORAGE_KEYS,
    GENERATE_CONTENT_FOR_ALERT_LAYER,
    MONTH,
    WEEK,
    FEEDBACK_DETAILS_SUCCESS_ALERT,
    CACHED_IMAGE_URL,
    IMAGE_TYPE_METADATA,
    ANDROID,
    IOS,
    WEB,
    SOCIAL_SHARING_CHANNELS,
    EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION,
    COLLECTION_BASE_URL,
    PROMO_CODE_REMOVE,
    MODALS,
    INVALID_FOR_AUTO_DEBIT,
    NET_BANKING_BANKS,
    FREQUENT_NET_BANKING_BANKS,
    INVALID_UPI,
    SIGNUP_ADD_ADDRESS_PAGEVIEW,
    REMOVE_CALENDAR_FEEDBACK,
    LISTING_SUBCATEGORY_ROUTE,
    ANF_DETAIL_TYPE,
    USER_DETAIL_DEFAULT_VALUES,
    FIREBASE,
    FIREBASE_KEYS,
    PAYMENT_OFFER,
} from '@App/shared/constants/constants';

export {
    MODAL_IDS, OFFER_SOURCE, PG_WISE_JUSPAY_PAY_LOAD,
    EVENT_SOURCE,
    PROMO_CODE_APPLY,
    CASH_COLLECTION_REMOVAL_INFO,
    EVENT_ARGUMENTS,
    SHOPPING_CREDIT,
    SHOPPING_CREDIT_ERROR_MESSAGES,
    EDENRED_BIN, FOOD_WALLET,
    CARD_ERROR_MESSAGES,
    SAVED_CARD_DEFAULT_CVV,
    CARD_TYPE, CARD_BRANDS,
    SAVED_CARD_DELETE_CONFIRMATION,
    SODEXO_BIN, CVV_ALERT,
    WALLET_ELIGIBILITY_STATUS,
    SELECTED_OFFER,
    BUY_MB_PASS,
    TERMS_CONDITIONS,
    SUCCESS_PURCHASED_MB_PASS,
    MB_PASS_TYPE_VALVE,
} from '@Globals/constants/payment/constants';

export {
    CAL_MODAL_CSS_CLASS,
    LOGIN_SIGNUP_A2B_POPUP,
    APPSFLYER,
    CITY_MODAL_CSS_CLASS,
    UPCOMING_DELIVERY_INFO,
    LOGIN_SIGNUP_TAB_POPUP,
    PAGE_TAGS,
    ACCOUNT_DELETE_CONFIRMATION_POPUP,
    NEGATIVE_BALANCE_POPUP,
    MENU_TITLES,
} from 'src/app/core/constants/core-constants';

export const NPS_RATING = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const LOCAL_IMG_PATH = "assets/img/";
export const CALENDAR_INFO = {
    TOMORROW_STOCK_UNAVAILABLE: {
        header: 'Item Out Of Stock For Tomorrow’s Order!',
        msg: 'Please select a further delivery date for this product'
    },
    ITEM_SOLD_OUT: {
        header: 'Uh-oh! Item Is Sold Out For The Selected Date.',
        msg: 'Please pick a different start date!'
    }
}

export const MAX_LIMIT_REACHED = {
    headerText: `Limit Reached!`,
    msg: `Maximum quantity has been added.`
}