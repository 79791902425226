//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';

import { SevenGridCollectionModule } from '@Widgets/blocks/collection/explore/components/seven-grid-collection/seven-grid-collection.module';
import { HorizontalCollectionModule } from '@Widgets/blocks/collection/explore/components/horizontal-collection/horizontal-collection.module';

import { SingleBrandModule } from '@Features/product-list/components/horizontal/single-brand/single-brand.module';
//#endregion Module Imports

//#region Component Imports

import { ExploreCollectionComponent } from '@Widgets/blocks/collection/explore/explore-collection.component';
//#endregion Component Imports

//#region Providers Imports
import { CollectionService } from '@Globals/providers/collection.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SingleBrandModule,
    HorizontalCollectionModule,
    SevenGridCollectionModule,
    IonicModule
  ],
  providers: [
    CollectionService
  ],
  exports: [ExploreCollectionComponent],
  declarations: [ExploreCollectionComponent]
})
export class ExploreCollectionModule extends DefaultModule { }