 //#region Imports

//#region Angular Imports
import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { PROFILE_ROUTES } from '@Globals/constants/profile/routes';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

declare var window: any;
@Component({
  selector: 'app-account-freeze',
  templateUrl: './account-freeze.component.html',
  styleUrls: ['./account-freeze.component.scss'],
})
export class AccountFreezeComponent extends DefaultComponent {

  constructor(
    private dataService:DataService,
    private navCtrl: NavController,
    public masterDataService:MasterDataService ) {
    super();
  }
    
  @Input() tomorrowBasketExists: boolean=false;
  @Input() cartPageView:boolean=false;

  chatWithAgent(){
    this.dataService.externalUrl.parameters =true;
    this.navCtrl.navigateBack(PROFILE_ROUTES.BASE + PROFILE_ROUTES.CONTACT);
  }

  sendEmail(){
    window.open(this.dataService.firebaseMasterData.milkbasketSupportEmail);
  }

}
