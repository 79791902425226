//#region Imports

//#region Angular Imports
import { Component, ElementRef, ViewChild, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { COLLECTION_BASE_URL } from '@Globals/constants/listing/constants';
import { InViewportMetadata } from 'ng-in-viewport';
import { environment } from '@Env/environment';
declare var Hls: any;
//#endregion Other Imports
//#endregion Imports

@Component({
  selector: 'wgt-video-collection-block',
  templateUrl: './video-collection.component.html',
  styleUrls: ['./video-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class VideoCollectionComponent extends BaseCollectionComponent {
  @ViewChild('featureVideoPlayer', {static: false}) featureVideoPlayer: ElementRef;
  videosForCollectionConfigData: any;
  muted = true;

  mbOnInit(): void {
    if(this.data) {
      let collectionVideoUrl = `${COLLECTION_BASE_URL}${this.data.id}.mp4`;
      let colectionImageUrl = `${COLLECTION_BASE_URL}${this.data.id}.png`;
      let colectionVideoType = 'video/mp4';
      if(this.data.playbackURL) {
        collectionVideoUrl = this.data.playbackURL;
        colectionImageUrl = environment.collectionPath + this.data.image;
        colectionVideoType = this.data.playbackURL.split(".")[this.data.playbackURL.split(".").length -1] === 'm3u8' ? 'application/x-mpegURL': 'video/mp4';
      } else if(this.data.video) {
        collectionVideoUrl = environment.collectionVideoPath + this.data.video;
        colectionImageUrl = environment.collectionPath + this.data.image;
        colectionVideoType = 'video/mp4';
      }
      this.videosForCollectionConfigData = {
        full_video_url: collectionVideoUrl,
        thumbnail: colectionImageUrl,
        type: colectionVideoType
      };
    }
    super.mbOnInit();
  }
 
  mbAfterViewInit(): void {
    // To get durationOfVideo after checking that video's metadata is loaded.
    if (this.featureVideoPlayer && this.featureVideoPlayer.nativeElement) {
      this.featureVideoPlayer.nativeElement.addEventListener('loadedmetadata', () => {
        this.featureVideoPlayer.nativeElement.muted = true;
      });
    }
    if(this.data.playbackURL) {
      this.readyVideojsPlayer();
    }
  }

  readyVideojsPlayer() {
   if(Hls.isSupported()) {
       var hls = new Hls();
       hls.loadSource(this.videosForCollectionConfigData.full_video_url);
       hls.attachMedia(this.featureVideoPlayer.nativeElement);
    }
  }
  muteOrUnmuteVideo(): void {
    this.muted = !this.muted;
    this.featureVideoPlayer && (this.featureVideoPlayer.nativeElement.muted = this.muted);
  }

  onIntersection($event) {
    const { [InViewportMetadata]: { entry }, target } = $event;
    const ratio = entry.intersectionRatio;
    const vid = target;
  
    ratio >= 0.65 ? vid.play() : vid.pause();
  }

}
