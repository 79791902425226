//#region Imports

//#region Angular Imports
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { CustomizeCollection } from '@App/listing/constants/listing-enums';
import { COLLECTION_KEYS } from '@Globals/constants/listing/constants';
import { CollectionDisplayType, CollectionType } from '@Globals/constants/listing/enums';
//#endregion Other Imports

//#endregion Imports

export class CollectionUtilities {
  constructor() {
  }

  static createRandom7DigitNumber() {
    return Math.floor(Math.random() * 10000000);
  }
  
  static getCollectionBlockTypeValue(collection) {
    let collectionType = ''; 
    if(collection.table?.templateType === COLLECTION_KEYS.OFFER && 
      collection.table?.displayType !== CollectionDisplayType.mBeyond) {
      collectionType = CollectionType.PAYMENT_OFFER;
    } else if (collection?.table?.rows && collection.table.isCollectionScrollable) {
      collectionType = CollectionType.BANNER;
    } else if (collection?.table?.rows) { 
      switch (collection.table.displayType) {
        case CollectionDisplayType.HorizontalWidget: 
        case CollectionDisplayType.HeroCollection: 
          collectionType = CollectionType.HORIZONTAL_WIDGET
          break;
        case CollectionDisplayType.ThreeColumnInSingleRow: 
          collectionType = CollectionType.THREE_COLUMN_IN_SINGLE_ROW;
          break;
        case CollectionDisplayType.BookStoreWidget: 
          collectionType = CollectionType.BOOK_STORE;
          break;
        case CollectionDisplayType.VideoCollection: 
          collectionType = CollectionType.VIDEO;
          break;
        case CollectionDisplayType.LottieCollection: 
          collectionType = CollectionType.LOTTIE;
          break;
        case CollectionDisplayType.RecommendationWidgetProductListing: 
          if(collection.customizeCollection === CustomizeCollection.previouslyOrdered) {
            collectionType = CollectionType.PREVIOUSLY_ORDERED;
          } else {
            collectionType = CollectionType.RECOMMENDATION;
          }
          break;
        case CollectionDisplayType.RecommendationWidgetProductListingBg:
          collectionType = CollectionType.RECOMMENDATION;
          break;
        case CollectionDisplayType.ProductLaunchTypeListing: 
          collectionType = CollectionType.PRODUCT_LAUNCH_LISTING;
          break;
        case CollectionDisplayType.TwoRowProductListing:
          collectionType = CollectionType.TWO_ROW;
          break;
        case CollectionDisplayType.HorizontalProductListingExtraWidth: 
          collectionType = CollectionType.HORIZONTAL_PRODUCT_EXTRA_WIDTH;
          break;
        case CollectionDisplayType.mBeyond: 
          collectionType = CollectionType.MBEYOND;
          break;
        case CollectionDisplayType.ExploreCollection: 
          collectionType = CollectionType.EXPLORE;
          break;
        case CollectionDisplayType.AutoSwipeCollection: 
          collectionType = CollectionType.AUTO_SWIPE;
          break;
        case CollectionDisplayType.RecurringSuggestions: 
          collectionType = CollectionType.RECURRIN;
          break;
        case CollectionDisplayType.VideoCardCollection: 
          collectionType = CollectionType.VIDEO_CARD;
          break;
        case CollectionDisplayType.DealsOfTheDayCollection: 
          collectionType = CollectionType.DEALS_OF_THE_DAY;
        break;
        case CollectionDisplayType.CategoryCollection: 
          collectionType = CollectionType.CATEGORY_COLLECTION;
        break;
        case CollectionDisplayType.Normal: 
        collectionType = CollectionType.IMAGE;
        break;
      }
    }
    return collectionType;
  }

  static getPanelJson(id, bt){
    return {
      "id": id,
      "h": 200,
      "w": 100,
      "m": "10px 5px 10px 5px",
      "p": "10px 5px 10px 5px",
      "r": 0,
      "c":1,
      "bc": "#fff",
      "bi": "/banners/fruits.jpg",
      "b": {
          "id": 1234,
          "n": "collection_one",
          "t": "collection",
          "s": "collection one title",
          "st": 1,
          "c": "WGB0000001",
          "bt": bt,
          "h": 200,
          "w": 100,
          "pl": "/listing/plp/230",
          "sl": "/listing/plp/230/345",
          "bc": "#fff",
          "bi": "/banners/fruits.jpg",
          "m": "10px 5px 10px 5px",
          "p": "10px, 5px 10px 5px"
      }
    };
  }

}
