import { RecRecommendationText } from "../interfaces/interfaces";

export const STORAGE_KEYS = {
  AUTH_KEY: 'authKey',
  CITY: 'city',
  APP_START_MESSAGE: 'appStartMessage',
  REFERRER_CODE: 'referrerCode',
  SHOW_FROZEN_PRODUCT_A2B_ALERT: 'showFrozenProductA2BAlert',
  DONT_SHOW_SUBSCRIPTION_AGAIN: 'dontShowSubsciptionAgain',
  DONT_SHOW_EXHAUSTED_AGAIN: 'dontShowExhaustedAgain',
  SHOW_RECURRING_COACHMARK: 'showRecurringCoachMark',
  RECURRING_RECOMMENDATIONS: 'recRecommendations',
  LIKED_COLLECTIONS: 'likedCollections',
  CALENDAR_COACHMARK_OBJECT: 'calendarCoachmarkObject',
  FREQUENT_SEARCHES: 'frequentSearches',
  CURRENT_PACKAGE: 'currentPackage',
  TRANSCATION_ID: 'txnId',
  TRANSACTION_DATA: 'txnData',
  PAYMENT_STATUS: 'status',
  ISJIOUSER: 'isJioUser',
  LOCATION_DENY_DONT_AGAIN_COUNT: 'denyCount',
  HOME_COLLECTION: 'homeCollection',
  BANNERS: 'banners',
  STATIC_MASTER_DATA: 'staticMasterData',
  STATIC_MASTER_DATA_VERSION: 'staticMasterDataVersion',
  CATEGORIES: 'categories',
  CATEGORIES_COLLECTION: 'categoriesCollection',
  HERO_BANNER: 'heroBanner',
  HERO_COLLECTION: 'heroCollection',
  PREVIOUSLY_ORDERED_COLLECTION: 'previouslyOrderedCollection',
  MASTER_DATA_BANNER_CACHE_EXPIRATION: 'masterDataBannerCacheExpiration',
  MASTER_DATA_CATEGORY_CACHE_EXPIRATION: 'masterDataCategoryCacheExpiration',
  TWID_ELIGIBILITY_CACHE_EXPIRATION: 'twidEligibilityCacheExpiration',
  HOME_COLLECTION_CACHE_EXPIRATION: 'homeCollectionCacheExpiration',
  RECURRING_CAMPAIGN: 'recurringCampaign',
  RATING_POPUP_VISIBILITY_DATE: 'ratingPopupVisibilityDate',
  CASHBACK_SAVING_VISIBILITY_DATE: 'cashbackSavingVisibilityDate',
  CASHBACK_SAVINGS: 'cashbackSavings',
  HOME_SCHEMA: 'homeSchema',
  FIREBASE_CONFIG: 'firebaseConfig',
  FIREBASE_PUSH_TOKEN: 'firebasePushToken',
  FIREBASE_CONFIG_MAX_LIMIT: 'maxLimits',
  SUBSCRIBE_AND_SAVE_NUDGE: 'subscribeAndSaveNudge',
  RC_MODIFIED_DATE: 'recOfferModifiedDate',
  JIO_NUMBER: 'jioLoginNumber',
  USER_DATA: 'userData',
  RC_EFFECTIVE_DATE: 'rcEffectiveDate',
  RECYCLE_BAG_DATE: 'recycleBagSetDate',
  RC_NEWLY_ADDED_PRODUCTS: 'rcNewlyAddedProducs',
  RC_CUSTOMER_OFFER_DATE:'customer_offer_date',
  PROMO_CODE: 'promo_code',
  DATA_UI_RC_MODIFIED_DATE:'dataUiRcmodifiedDate',
  ADDRESS_STATUS:'addressStatus',
  FIRST_ATB_SUCESS: 'isFirstAtb',
  LAST_NOCHECKOUT_VISIT_DATE:'lastBasketVisitDate',
  SAVINGS_DATA:'savingsData',
  SAVINGS_LAST_UPDATED_ON:'savingsLastUpdatedOn',
  REFRESH_KEY: 'refreshKey',
  REFRESH_TIME: 'refreshTime',
  FREE_MEMBERSHIP_POPUP: 'freeMembershipPopup',
  TARGET_MEMBERSHIP: 'targetMembership',
  TARGET_MEMBERSHIP_COMPLETED_LOTTIE: 'targetMembershipCompletedLottie',
  TARGET_MEMBERSHIP_COMPLETED_POPUP: 'targetMembershipCompletedPopup',
  TARGET_MEMBERSHIP_EXTENDED_POPUP: 'targetMembershipExtendedPopup',
  TARGET_MEMBERSHIP_EXPIRED_SHOWN: 'targetMembershipExpired',
  POPULAR_SUGGESION_VERSION: 'popularSuggestionVersion',
  POPULAR_SUGGESION_LIST: 'popularSuggestionList',
};

export const FIREBASE = {
  TIME: 0,
  ANDROID: 'AND',
  IOS: 'IOS',
  CONFIG_IS_ALREADY_ACTIVATED: 'config is already activated'
};

export const FIREBASE_TRACES = {
  DATA_UI: 'data_ui',
  HOME_PAGE_LAUNCH: 'home_page_launch'
};

export const MONTH = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec' ];

export const WEEK = [
  'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'
];

export const SocietyName = {
  1 : 'HighRise',
  2 : 'RowHousing',
  3 : 'Locality'
};

export const FIREBASE_KEYS = {
  MB_PLUS_PASS: 'mb_plus_pass',
  YFO_CONFIG_DATA: 'yfo_config_data',
  IS_SEARCH_BAR: 'isSearchBar',
  COLLECTIONS: 'collections',
  SELF_HELP_VERSION: 'selfhelp_version',
  MEMBERSHIP: 'membership',
  CART_PROMOTION_DATA: 'cartPromotionData',
  HOME_STRIP_PROMOTION: 'homeStripPromotion',
  MEMBERSHIP_CONFIRMATION_DATA: 'membershipConfirmationData',
  EXTRA_FEE_LABEL: 'extraFeeLabel',
  TAXES_LABEL: 'taxesLabel',
  EXTRA_FEE_DETAILS: 'extraFeeDetails',
  FROZEN_CART_LABEL: 'frozenCartLabel',
  FROZEN_PRODUCT_A2B_ALERT_DATA: 'frozenProductA2BData',
  DELIVERY_SLOT_INFO: 'deliverySlotInfo',
  DELIVERY_TIMING: 'deliveryTiming',
  REMOVE_FROZEN_CHECK_FOR_RESPECTIVE_CITIES: 'removeFrozenCheckForRespectiveCities',
  FROZEN_PRODUCT_A2B_META_DATA: 'frozenProductA2BMetaData',
  MEMBERSHIP_INFO_METADATA: 'membershipInfoMetaData',
  SETTINGS_MEMBERSHIP_CONTENT: 'settingsMembershipContent',
  BILL_DETAIL_LABELS: 'billDetailLabels',
  FARM_ROUTE_INFO: 'farmRouteInfo',
  SHOW_SUBCATEGORIES_ON_PDP: 'showSubCategoriesOnPDP',
  PAYMENT_OFFER_STRIP: 'paymentOfferStrip',
  SUPER_CHARGE_CONFIG_DATA: 'superChargeData',
  PAYMENT_OFFERS: 'paymentOffers',
  CATEGORY_WIDGET: 'categoryWidget',
  INFO_POP_UP: 'infoPopUp',
  CASH_COLLECTION_REMOVAL_INFO_DATA: 'cashCollectionRemovalInfoData',
  ORDER_LIMIT_REACHED_ALERT_DATA: 'orderLimitReachedAlertData',
  CUT_OFF_TIMES_BY_HUB_ID: 'cutOffTimesByHubId',
  BULK_BUY_DATA: 'bulkBuyData',
  SSPH_CONFIG: 'ssph_config',
  CANCELLED_PRODUCTS_CONFIG: 'cancelledProductsConfig',
  PG_IMAGES_DATA: 'pgImagesData',
  NEW_PAYMENT_GATEWAYS: 'newPaymentGateways',
  CATEGORY_SUBCATEGORY_CLOSED_INFO_TEXT: 'categorySubcategoryClosedInfoText',
  REFILL_TYPES_META_DATA: 'refillTypesMetaData',
  WHATSAPP_SHARING_COLLECTION_METADATA: 'whatsAppSharingCollectionMetadata',
  COLLECTION_LIST_TITLE: 'collectionListTitle',
  OFFER_VIEW_TYPES_METADATA: 'offerViewTypesMetaData',
  PDP_INFO: 'pdpInfo',
  ACCOUNT_CLOSURE_REASON: 'accountClosureLeaveReason',
  SEE_SIMILAR_HUBIDS: 'seeSimilarHubIds',
  SHOW_LIVE_NOW_ON: 'showLiveNowOn',
  MBEYOND: 'mbeyond',
  COLLECTION_LISTING_META_DATA: 'collectionListingMetaData',
  HOLIDAY_CONFIG_DATA: 'holidayConfigData',
  SHAREABLE_COLLECTION_DATA: 'shareableCollectionData',
  MEMBERSHIP_REVISED_CONSTRUCT: 'membershipRevisedConstruct',
  AUTO_DEBIT_BANNERS: 'auto_debit_banners',
  FAQ: 'faq',
  ADD_MONEY_BOTTOM_NOTE: 'addMoney_bottomNote',
  CLEAR_STORAGE: 'clearStorage',
  JIO_CASHBACK_CONSTRUCT: 'jioCashBackConstruct',
  SHOW_INTERNAL_APP_RATING_POPUP: 'showInternalAppRatingPopUp',
  FIREBASE_METADATA: 'firebaseMasterData',
  SHOW_RATING_THANK_YOU_TEXT: 'showRatingThankYouText',
  FIREBASE_CONFIG_VERSION: 'configVersion',
  REFRESH_API_CONFIG: 'refreshApiConfig',
  MAP_KEY_RIL: 'mapLoadUrl'
};

export const TRUE = 'true';
export const FALSE = 'false';

export const MB_AUTH_KEY = 'mb-auth-key';
export const MB_REFRESH_KEY = 'refresh-key';
export const KEY = 'key';

export const ALERT_BUTTON_TEXT = 'OK';
export const LISTING_SUBCATEGORY_ROUTE = '/listing/subcategory/';

export const EVENT_NAMES = {
  SIGN_UP_CITY: 'Sign up - City',
  INVITE_CODE_POPUP_VIEWED: 'Invite Code - Popup Viewed',
  INVITE_CODE_DETAILS: 'Invite Code - Details',
  SUCCESSFUL: 'Successful',
  UNSUCCESSFUL: 'Unsuccessful',
  POST_REGISTRATION_CAPTURE: 'Post registration capture',
  UPDATE_PROFILE_PAGE: 'update_profile_page',
  UPDATED_PROFILE_PAGE: 'updated_profile_page',
  APNS_TOKEN: 'APNSToken',
  SETTING: 'setting',
  SUPERCHARGE_DISCOVERY_PAGE: 'SuperCharge Discovery page',
  SUPERCHARGE_EXPIRED_PAGE: 'SuperCharge Expired page',
  FREE_SUBSCRIPTION: 'Free Subscription',
  PAYMENT_OFFERS: 'Payment Offers',
  INFO_POP_UP_VIEW: 'Info Pop Up View',
  INFO_POP_CLICKED: 'Info Pop Up Clicked',
  CAMPAIGN_CLICKED: 'Campaign Clicked',
  SAVE_TO_LOCKER: 'Save To Locker',
  OFFER_CLICKED: 'Offer Clicked',
  MEMBERSHIP_CHECKBOX_VALUE: 'Membership Checkbox Value',
  USER_BALANCE: 'User Balance',
  ADD_MONEY_CLICK: 'Add money clicked',
  JIO_CASHBACK: 'Jio Cashback',
  WIFI_IP_ADDRESS: 'Wifi  IP Address',
  CARRIER_IP_ADDRESS: 'Carrier  IP Address',
  HTTP_PROXY_INFORMATION: 'Http  Proxy Information',
  UPDATE_ADDRESS: 'Click on update address',
  PERSONALIZED_DETAILS: 'Personalized details',
  GENDER_DETAILS: 'Gender Details',
  FOOD_PREFERNECE_DETAILS: 'Food Prefernece Details',
  MARITAL_STATUS_DETAILS: 'Marital Status Details',
  FAMILY_MEMBER_COUNT: 'Family Member count',
  ANNIVERSARY_DETAILS: 'Anniversary Details',
  BIRTHDAY_DETAILS: 'Birthday Details',
  CHARACTERISTICS_DETAILS: 'Chracteristics Details',
  EDIT_PROFILE_DETAILS: 'Edit Profile details',
  SMS_TOGGLE: 'SMS toggle',
  EMAIL_TOGGLE: 'Email toggle',
  FEEDBACK_SUBMIT: 'Feedback submit',
  PRODUCT_IMPRESSION: 'product_impression',
  AUTH_EXPIRED: "access token expired",
  REFRESH_API_FAILURE:  "refresh token api failure",
  REFRESH_DELAYED_RESPONSE: "logout due to refresh delayed response",
  SSL_PIN_FAILED: 'Ssl pin failed',
  DATA_UI_FAILED: 'Data ui failed',
  DATA_UI_CALLED: 'Data ui calling started',
  DATA_UI_LOADED: "Data ui loaded"
};

export const EVENT_SOURCES = {
  FREE_SUBSCRIPTION: 'FreeSubscription',
  SHOPPING_CREDITS: 'Shopping Credits',
  HOME: 'home',
  BOTTOM_NAV: 'Bottom Nav',
  COLLECTION: 'collection',
  JIO_CASHBACK_PROGRESS_BAR: 'Jio Cashback Progress Bar'
};

export const SETTINGS_MEMBERSHIP_CONTENT = {
  heading: '30 day charge',
  subHeading: 'We levy a small amount of ₹{price}(+GST) for 30 days of uninterrupted service.',
  checkLabel: 'Ok, I got it. Do not ask again'
};

export const SIGNUP_SOCIETY_PAGEVIEW = 'signup-society pageView';
export const IOS = 'ios';
export const ANDROID = 'android';
export const WEB = 'web';
export const APP_VERSION = 'appVersion';
export const CACHED_IMAGE_MAP = 'cachedImageMap';
export const CACHED_IMAGE_URL = 'http://localhost';


export const APP_PERMISSION = {
  READ_MEDIA_IMAGES: "READ_MEDIA_IMAGES",
  READ_MEDIA_VIDEO: "READ_MEDIA_VIDEO",
  POST_NOTIFICATIONS: "POST_NOTIFICATIONS"
};

export const MODALS = {
  PDP: 'ProductDetailComponent',
  SELECT_CITY: 'SelectCityComponent',
  WALLET_INCREASE: 'WalletIncrease',
  MEMBERSHIP_DETAILS: 'MembershipDetails',
  INBOX: 'Inbox',
  HELP: 'Selfhelp'
};

export const LOGIN_PROMPT_ERROR_MESSAGE = 'login_required';
export const APP_UPDATE_REQUIRED = 'app_update_required';
export const EVENT_SOURCE_FOR_DEEP_LINK_NAVIGATION = 'Campaign';

export const REFERRED_BY_ALERT = {
  BUTTONS: [ 'OK' ],
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: false,
    MESSAGE:
      '{name} ({society}) has invited you to join the Milkbasket family.<br/>Welcome To MilkBasket.'
  },
  CSS_CLASS: 'alert-custom'
};

export const INTERNET_ERROR = {
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: false,
    HEADER_TEXT: 'Oops! Something gone wrong..',
    MESSAGE: 'Please check your internet settings and try again.'
  },
  CSS_CLASS: 'alert-custom',
  BACKDROP_DISMISS: false
};

export const SERVER_DOWN_ERROR = {
  BUTTONS: {
    OKAY: { text: 'Ok', handler: null }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: false,
    HEADER_TEXT: 'Oops! Something gone wrong..',
    MESSAGE:
      'We’re working on it. In the meantime please check your internet settings and try again.'
  },
  CSS_CLASS: 'alert-custom'
};

export const GENERATE_CONTENT_FOR_ALERT_LAYER = (
  metaData: {
    IS_CLOSE_ICON_REQUIRED?: boolean;
    HEADER_TEXT?: string;
    MESSAGE?: string;
    NOTE?: string;
  },
  contentKeysToBeReplaced: any = {}
) => {
  let content = '';
  if (metaData.IS_CLOSE_ICON_REQUIRED) {
    content += '<div class="has-close-button"></div>';
  }
  if (metaData.HEADER_TEXT) {
    content += `<div class="header">${metaData.HEADER_TEXT}</div>`;
  }
  if (metaData.MESSAGE) {
    content += `<div class="message">${metaData.MESSAGE}</div>`;
  }
  if (metaData.NOTE) {
    content += `<div class="note">${metaData.NOTE}</div>`;
  }
  for (const key in contentKeysToBeReplaced) {
    if (contentKeysToBeReplaced.hasOwnProperty(key)) {
      content = content.replace(
        new RegExp(`{${key}}`, 'ig'),
        contentKeysToBeReplaced[ key ]
      );
    }
  }
  return content;
};

export const USER_DETAIL_DEFAULT_VALUES = {
  FIRSTNAME: 'milkbasket',
  LASTNAME: 'user'
};

export const UPDATE_USER_DETAILS_SUCCESS_ALERT = {
  MESSAGE: 'Your details have been verified!',
};

export const UPDATE_USER_DETAILS_SUCCESS = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    },
    CONFIRM: {
      text: 'Awesome',
      handler: null,
      cssClass: 'alert-button-primary-outline'
  }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    MESSAGE: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center">
      <img src="assets/img/successPopup.svg" alt="Payment success">
      <h2 class="ion-text-center">Thank You</h2>
      <p class="ion-text-center fw-subHeading pb-8">This will help us personalise the app and give you better recommendations</p>
    </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group custom-position food-wallet'
};

export const YFO_TRENDING_CONFIG_DEFAULT_VALUES = {
  yfo_category_id: 36,
  title: 'Best Sellers',
  heading1: 'Umm...looks like you didn\'t need much from here so far.',
  heading2: 'Why don\'t you check out our bestsellers?'
};

// Define the directory names to store images in device
export const FILE_STORAGE_DIRECTORIES = {
  BANNER: 'banner',
  PRODUCT: 'product',
  CATEGORY: 'category',
  MILK_LABEL_VIDEO: 'milklabelvideo',
  POPULAR_SUGGESTION: 'popular_suggestion'
};

export const SUPER_CHARGE_CONFIG_DATA = {
  schemes: {
    1: {
      totalAmount: '15000',
      cashBack: '1000',
      monthlyAmount: '1400',
      percentage: '15%',
      maturityMonth: '10th month',
      startMonth: '1st February',
      lastMonth: 'November'
    },
    2: {
      totalAmount: '25000',
      cashBack: '2000',
      monthlyAmount: '2500',
      percentage: '19%',
      maturityMonth: '10th month',
      startMonth: '1st February',
      lastMonth: 'November'
    },
    3: {
      totalAmount: '40000',
      cashBack: '4000',
      monthlyAmount: '4000',
      percentage: '23%',
      maturityMonth: '10th month',
      startMonth: '1st February',
      lastMonth: 'November'
    }
  },
  // tslint:disable-next-line: max-line-length
  howItsWork: '<ul class="list-custom ion-padding-horizontal"> <li>A SuperChargeCashback of ₹{monthlyAmount} would be credited to your Mb wallet every month starting {startMonth} for next {maturityMonth}.</li><li>In the {maturityMonth} you get an extra ₹{cashBack} SuperChargeBenefit</li><li>Only 1 SuperCharge allowed per account</li></ul>',
  successfullyRecharged: {
    title: 'Your MB Wallet will be credited with ₹{cashBack} for the next {maturityMonth} starting {startMonth}.',
    subTitle: 'Additionally, in the  {maturityMonth}, you will get SuperChargeBenefit of ₹{cashBack}!'
  },
  cashCollectionRecharged: {
    title: 'Our executive will reach you in your selected time slot for cash collection.',
    subTitle: 'After cash collection, you would be successfully SuperCharged with ₹{totalAmount}'
  },
  yourPlan: {
    title: '₹{cashBack} SuperCharge benefit at the end of {maturityMonth}',
    subTitle: 'Get SuperChargeBenefit of ₹{cashBack} in {lastMonth} - a return of {percentage}!'
  },
  whatsGreat: [ {
    icon: 'assets/img/icon-sc-1@2x.png',
    title: 'Unparalleled convenience',
    subTitle: 'The SuperCharge Cashback comes into your MB wallet every month without any expiry.'
  }, {
    icon: 'assets/img/icon-sc-2@2x.png',
    title: 'Can be used for anything',
    subTitle: 'from milk to flour to potatoes'
  }, {
    icon: 'assets/img/icon-sc-3@2x.png',
    title: 'Can be cancelled anytime',
    subTitle: '& get back balance SuperCharge'
  } ],
  paymentPending: {
    title: 'We haven’t received your payment yet. But don’t worry, we’re looking into it. Give us 20 mins to check & update the status.',
    subTitle: 'If we receive your payment, we will SuperCharge your account.',
    description: 'If we don’t receive your payment & your money was debited, it will be refunded to your account within next 2-3 working days.'
  },
  expiry: {
    title: 'Oops!<br /> You missed the best deal of 2020 this time!',
    subTitle: 'Don’t worry! <br> We’ll notify you if we ever go live with another such amazing deal!'
  },
  journey: {
    title: 'SuperChargeMoney credited to your MB Wallet',
    cashBackTitle: 'SuperChargeBenefit will be credited to your MB Wallet along with'
  },
  subTitle: 'Bet it can’t get better than this.',
  title: 'The best deal of 2020 is here!',
  highlight: 'LIMITED TIME OFFER STARTING 12 JAN',
  successfullyRechargedFooter: 'Stay tuned here for further updates',
  cashCollectionTitle: 'You have successfully placed your request for cash collection.',
  successfullyRechargedTitle: 'You have successfully SuperCharged with',
  url: 'http://file.milkbasket.com/static/supercharge/index-supercharge.html',
  urlTitle: 'Term & condition'
};
// tslint:disable-next-line: max-line-length
export const FARM_ROUTE_INFO = '<div class="ion-row"><div class="pdp-img"><img alt="Shop Farm Route Vegetables" src="http://file.milkbasket.com/images/farm.png"><div class="farm-btn ion-text-center"><div class ="ion-button">Shop Farm Route Vegetables</div></div></div></div><div class="pdp-content ion-padding-horizontal"><div class="padding-vertical-large"><h1>What is Farm Route</h1><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</p></div><div class="ion-no-padding margin-bottom-large img-sec"><div class="pdp-img-holder"><img alt="Form 1" src="http://file.milkbasket.com/images/farm-1.png" /></div><div class="pdp-img-holder"><img alt="Farm 2" src="http://file.milkbasket.com/images/farm-2.png" /></div></div></div><div class="pdp-content bg-light ion-padding-horizontal"><div class="padding-vertical-large"><h1>What is Changing</h1><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p><p> Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</p></div></div><div class="pdp-content ion-padding-horizontal margin-bottom-extra-large" ><div class="padding-vertical-large"><h1>Freshest Produce for you</h1><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s</p></div><div class="pdp-img-holder farm-img ion-no-margin"><img alt="farm-3" src="http://file.milkbasket.com/images/farm-3.png" /></div></div>';

export const FROZEN_PRODUCT_A2B_META_DATA = {
  ctaLabel: 'Okay',
  header: 'High quality = Tasty meal!',
  // tslint:disable-next-line: max-line-length
  description: 'We at milkbasket are very particular about quality. We achieve high standards by ensuring product you’ve added to the basket travels to your doorstep in temperature-controlled boxes. Your order will be hand-delivered between 7 am - 8 am.',
  detailHeader: 'Details as below :',
  timeInfo: 'In-Hand <br />delivery',
  time: '7-8 AM',
  price: '9 ONLY',
  dateInfo: 'Tomorrow',
  priceInfo: 'IN - HAND DELIVERY CHARGE',
  note: ' Any other product from this range will be delivered free of cost.',
  date: ''
};

export const FROZEN_PRODUCT_A2B_DATA = {
  // tslint:disable-next-line: max-line-length
  message: '<div class="img-holder"><img alt="delivery-fee" src="assets/img/img-delivery-fee.png" /></div><h1 class="has-max-width">{header}</h1><p>{description}</p><p class="details"><strong>{detailHeader}</strong></p><ul class="list"><li class="list-item"><strong>{time}</strong><p>{timeInfo}</p></li><li class="list-item"><strong>{date}</strong><p>{dateInfo}</p></li><li class="list-item"><strong><span class="icon-rupee icons-property"></span>{price}</strong><p>{priceInfo}<span class="star">*</span></p></li></ul><div class="free-cost-strip"><span class="star">*</span>{note}</div>',
  ctaLabel: 'Okay'
};

export const HOME_STRIP_CONTENTS = {
  2: 'Enjoy benefits for next {daysLeft} days',
  4: 'Enjoy <strong>FREE</strong> benefits for next {daysLeft} days'
};

export const HOME_OFFER_STRIP_CONTENTS = {
  hasSubscription: '<strong>{daysLeft} days left - EXTEND FOR FREE</strong>',
  hasNotSubscription: 'Get Upto 30 Days of Free Subscription '
};

export const DELIVERY_TIMING = {
  frozen: '7-8 am',
  normal: '',
  noCheckoutReq: { isImgAllowed: true, text: '' },
  saveEnv: { isImgAllowed: false, text: 'Please place your MB bags at your doorsteps for<br/>collection and reuse' }
};

export const TAXES_LABEL = 'Taxes';

export const EXTRA_FEE_LABEL = 'PKG & HANDLING FEE';

export const DELIVERY_SLOT_INFO = '7-8 am Delivery';
export const MILKBASKET_SUPPORT_EMAIL = 'mailto:hello@milkbasket.com';

export const PAYMENT_OFFER = {
  name: '',
  amount: 0,
  table: {
    rows: [ { columns: [] } ]
  },
  bobValue: '',
  successMessage: '',
  position: 4
};

export const SOCIAL_SHARING_CHANNELS = {
  whatsapp: { isAvailable: false },
  messenger: { isAvailable: false }
};
SOCIAL_SHARING_CHANNELS.whatsapp[ ANDROID ] = 'com.whatsapp';
SOCIAL_SHARING_CHANNELS.whatsapp[ IOS ] = 'whatsapp';
SOCIAL_SHARING_CHANNELS.messenger[ ANDROID ] = 'com.facebook.orca';
SOCIAL_SHARING_CHANNELS.messenger[ IOS ] = 'fb-messenger';

export const HTTP_ERROR = 'Http Error';

export const FREQUENT_NET_BANKING_BANKS = [
  {
    bankName: 'Axis Bank',
    paymentMethod: 'NB_AXIS',
    icon: '../../../../assets/img/axis-bank.png'
  },
  {
    bankName: 'HDFC Bank Ltd.',
    paymentMethod: 'NB_HDFC',
    icon: '../../../../assets/img/hdfc.png'
  },
  {
    bankName: 'ICICI Bank',
    paymentMethod: 'NB_ICICI',
    icon: '../../../../assets/img/icici-bank.png'
  },
  {
    bankName: 'State bank of india',
    paymentMethod: 'NB_SBI',
    icon: '../../../../assets/img/sbi-bank.png'
  }
];

export const NET_BANKING_BANKS = [
  {
    bankName: 'Axis Bank',
    paymentMethod: 'NB_AXIS',
    icon: '../../../../assets/img/axis-bank.png'
  }, {
    bankName: 'Bank of India',
    paymentMethod: 'NB_BOI',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Bank of Maharashtra',
    paymentMethod: 'NB_BOM',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Central Bank Of India',
    paymentMethod: 'NB_CBI',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Corporation Bank',
    paymentMethod: 'NB_CORP',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Development Credit Bank',
    paymentMethod: 'NB_DCB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Federal Bank',
    paymentMethod: 'NB_FED',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'HDFC Bank',
    paymentMethod: 'NB_HDFC',
    icon: '../../../../assets/img/hdfc.png'
  }, {
    bankName: 'ICICI',
    paymentMethod: 'NB_ICICI',
    icon: '../../../../assets/img/icici-bank.png'
  }, {
    bankName: 'Industrial Development Bank of India',
    paymentMethod: 'NB_IDBI',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Indian Bank',
    paymentMethod: 'NB_INDB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'IndusInd Bank',
    paymentMethod: 'NB_INDUS',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Indian Overseas Bank',
    paymentMethod: 'NB_IOB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Jammu and Kashmir Bank',
    paymentMethod: 'NB_JNK',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Karnataka Bank',
    paymentMethod: 'NB_KARN',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Karur Vysya',
    paymentMethod: 'NB_KVB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'State Bank of india',
    paymentMethod: 'NB_SBI',
    icon: '../../../../assets/img/sbi-bank.png'
  }, {
    bankName: 'State Bank of Mysore',
    paymentMethod: 'NB_SBM',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'NB_SBH',
    paymentMethod: 'State Bank of Hyderabad',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'South Indian Bank',
    paymentMethod: 'NB_SOIB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Union Bank of India',
    paymentMethod: 'NB_UBI',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'United Bank Of India',
    paymentMethod: 'NB_UNIB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Vijaya Bank',
    paymentMethod: 'NB_VJYB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'NB_VJYB',
    paymentMethod: 'NB_YESB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'CityUnion',
    paymentMethod: 'NB_CUB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Canara Bank',
    paymentMethod: 'NB_CANR',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'State Bank of Patiala',
    paymentMethod: 'NB_SBP',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Citi Bank',
    paymentMethod: 'NB_CITI',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Kotak Bank',
    paymentMethod: 'NB_KOTAK',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Andhra Bank',
    paymentMethod: 'NB_ANDHRA',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Punjab National Bank CORPORATE',
    paymentMethod: 'NB_PNBCORP',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Punjab National Bank',
    paymentMethod: 'NB_PNB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Bank of Baroda',
    paymentMethod: 'NB_BOB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Standard Chartered Bank',
    paymentMethod: 'NB_SCB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Oriental Bank Of Commerce',
    paymentMethod: 'NB_OBC',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Syndicate Bank',
    paymentMethod: 'NB_SYNB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Bank of Baroda Corporate',
    paymentMethod: 'NB_BOBCORP',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Allahabad Bank',
    paymentMethod: 'NB_ALLB',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'DENA Bank',
    paymentMethod: 'NB_DENA',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Bharat Bank',
    paymentMethod: 'NB_BHARAT',
    icon: '../../../../assets/img/other-banks.svg'
  },
  {
    bankName: 'IDFC Bank',
    paymentMethod: 'NB_IDFC',
    icon: '../../../../assets/img/other-banks.svg'
  }, {
    bankName: 'Union Bank Corporate Banking',
    paymentMethod: 'NB_UBICORP',
    icon: '../../../../assets/img/other-banks.svg'
  },
  {
    bankName: 'RBL Bank',
    paymentMethod: 'NB_RBL',
    icon: '../../../../assets/img/other-banks.svg'
  }
];

export const GET_ALL_BASKETS_PERIOD = {
  MONTH: 'Month',
  WEEK: 'week'
};
export const INVALID_UPI = 'Invalid UPI Handle';
// PLP, PDP, PFV(Product Image full view)
export const IMAGE_TYPE_METADATA = {
  PLP: { prefix: '100_', width: 100, height: 100, postfix: '.webp' },
  PDP: { prefix: '300_', width: 300, height: 300, postfix: '.webp' },
  PFV: { prefix: '900_', width: 900, height: 900, postfix: '.webp' }
};

export const PAYMENT_ELIGIBILITY_MESSAGES = {
  LAZYPAY: 'Connecting with lazypay',
  CARDS: 'Checking your card Eligibility'
};

export const INVALID_FOR_AUTO_DEBIT = 'Your card is not eligible for AutoPay';

export const DISOUNTED_PRODUCT_OFFER_APPLIED_INFO = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    },
    OKAY: {
      text: 'View discounted products',
      role: 'okay',
      handler: null,
      cssClass: 'button-lg alert-button'
    }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    MESSAGE:
      `<div class="img-holder margin-top-extra-large">
        {img}  
      </div>
      <div class="text-holder margin-top-large has-max-width">
       <h1 class="f-w-n margin-bottom-small">{heading}</h1>
       <p>{subheading}</p>
       </div>`
  },
  CSS_CLASS: 'alert-custom auto-add-sample',
  BACK_DROP_DISMISS: false
};

export const OFFER_APPLIED_INFO = {
  BUTTONS: {
    CLOSE: {
      text: '',
      role: 'cancel',
      handler: null,
      cssClass: 'alert-button-close icon-close icons-property icon-base icon-dark'
    }
  },
  META_DATA: {
    IS_CLOSE_ICON_REQUIRED: true,
    MESSAGE:
      `<div class="img-holder margin-top-extra-large">
        {img}  
      </div>
      <div class="text-holder margin-top-large has-max-width">
       <h1 class="f-w-n margin-bottom-small">{heading}</h1>
       <p>{subheading}</p>
       </div>`
  },
  CSS_CLASS: 'alert-custom auto-add-sample',
  BACK_DROP_DISMISS: true
};

export const OFFER_VIEW_TYPES = {
  DISCLOSED: '1',
  UNDISCLOSED: '2'
};

export const OFFER_VIEW_TYPE = {
  DISCLOSED: 'disclosed',
  UNDISCLOSED: 'undisclosed'
}

export const OFFER_VIEW_TYPES_METADATA = {
  [ OFFER_VIEW_TYPE.DISCLOSED ]: {
    heading: 'Yay! You got {product_name} for free.',
    keyToBeReplaced: 'product_name',
    subheading: ''
  },
  [ OFFER_VIEW_TYPE.UNDISCLOSED ]: {
    img: '<ion-icon class="icon-offer-gift icons-property"></ion-icon>',
    heading: 'Yay! You got a surprise gift.',
    subheading: '',
  }
};
export const CASHBACK_METADATA = {
  img: '<ion-icon class="icon-offer-gift icons-property"></ion-icon>',
  heading: 'Yay! You got a cashback of ₹ {amount}.',
  subheading: 'The cashback will be added to your wallet at 12 in the midnight',
  keyToBeReplaced: 'amount'
};

export const DISCOUNT_PRODUCT_METADATA = {
  img: '<ion-icon class="icon-offer-gift icons-property"></ion-icon>',
  heading: 'Yay! You got an offer',
  subheading: '',
};

export const PERCENT_CASHBACK_METADATA = {
  img: '<ion-icon class="icon-offer-gift icons-property"></ion-icon>',
  heading: 'Yay! You got {percent}% cashback on all orders for next {numOfDays} days',
  subheading: 'Maximum cashback allowed is {maxCashback}. Cashback will be processed everyday at 12 in the midnight, starting tomorrow.',
  keyToBeReplaced: [ 'percent', 'numOfDays', 'maxCashback' ]
};
export const MB_PASS_METADATA = {
  img: '<ion-icon class="icon-offer-gift icons-property"></ion-icon>',
  heading: 'Yay! You got a free MB pass.',
  subheading: ''
};
export const OFFER_REMOVED = {
  message:
    `<strong>Your free gift has been removed from your cart.</strong><br>Add more products to get the gift.`,
  class: 'tost-custom-secondary',
  position: 'bottom'
};
export const CASHBACK_REMOVED = {
  message:
    `<strong>Cashback removed.</strong><br>You are no longer eligible for this offer.`,
  class: 'tost-custom-secondary',
  position: 'bottom'
};
export const DISCOUNTED_PROUDUCT_OFFER_REMOVED = {
  message:
    `<strong>Discounted product Offer removed.</strong><br>You are no longer eligible for this offer.`,
  class: 'tost-custom-secondary',
  position: 'bottom'
};
export const OFFER_PASS_REMOVED = {
  message:
    `<strong>MB Pass removed.</strong><br>You are no longer eligible for this offer.`,
  class: 'tost-custom-secondary',
  position: 'bottom'
};
export const ACCOUNT_CLOSURE_REASON = [
  { value: 'I already have another account', isChecked: false },
  { value: 'I am moving out to a different location', isChecked: false },
  { value: 'I am not okay with subscription charge', isChecked: false },
  { value: 'I did not receive offer/cashback', isChecked: false },
  { value: 'Others', isChecked: false } ];

export const MEMBERSHIP_REVISED_CONSTRUCT = {
  text: 'Revised construct starting 1st Nov 2020',
  isShow: true,
  redirectionUrl: 'https://s3.ap-south-1.amazonaws.com/adhoc.milkbasket.com/Membership/newMembership.html',
  redirectionUrlHeader: 'Revised construct starting 1st Nov 2020',
  backgroundColor: 'https://s3.ap-south-1.amazonaws.com/adhoc.milkbasket.com/Membership/pink.png'
};

export const REMOVE_CALENDAR_FEEDBACK = {
  reasons: [
    { value: 'Quality issue', isChecked: false },
    { value: 'Changing product', isChecked: false },
    { value: 'Moving out of society', isChecked: false },
    { value: 'Issue with customer care', isChecked: false },
    { value: 'I did not receive offer/cashback', isChecked: false },
    { value: 'Others', isChecked: false } ],
  heading: 'Can you share your reasons for removing this item from subscription?',
  subheading: 'This feedback will help better our service.'
};

export const PROMO_CODE_REMOVE = {
  BUTTONS: {
    OKAY: {
      text: 'Remove',
      handler: null,
      cssClass: 'f-w-m'
    },
    CLOSE: {
      text: 'Back',
      handler: null,
      cssClass: 'clear-btn f-w-m margin-top-medium margin-left-0'
    }
  },
  META_DATA: {
    MESSAGE: `<div class="alert-details d-flex ion-justify-content-center ion-align-items-center ion-text-center">
    <img alt="Promo Code Removing" src="../../../../assets/icon/icon-warning.svg" />
    <p>Promo Code Removing</p> 
    <h2>Are you sure you want to Remove Promo Code?</h2>
  </div>`
  },
  CSS_CLASS: 'alert-custom alert-custom-default show-alert-button-group'
};
export const SIGNUP_ADD_ADDRESS_PAGEVIEW = 'signup-add-address pageView';
export const COLLECTION_BASE_URL = 'https://s3.ap-south-1.amazonaws.com/adhoc.milkbasket.com/collection/';

export const FAQs = {
  "1": {
    "title": "Mb Pass",
    "qnas": [
      {
        "Q": "What is Value Pass?",
        "A": "Value Pass is a special value program, for select customers every month, offering benefits like cashbacks to subscribed users."
      },
      {
        "Q": "How do I get access to / buy Value Pass?",
        "A": "Value Pass is accessible to select customers only, every month. You can access it from 'Homescreen' or 'Your Cart' page or 'Side Menu' and choose to purchase from there"
      },
      {
        "Q": "When will I receive the Cashback benefit? How will I get to know about it?",
        "A": "You will receive your cumulative / entire cashback post 2 days of the validity period of the Pass. It will be credited to your MB account and you will receive an intimation about the same via SMS / App notification."
      },
      {
        "Q": "Is there a limit on the Cashback benefit on my Value Pass?",
        "A": "Yes, the same is mentioned alongwith your Pass description / T&Cs in the form of 'Maximum Cashback' that can be availed as part of the program."
      },
      {
        "Q": "Can I cancel my Value Pass?",
        "A": "No, once purchased, Value Pass cannot be cancelled. Only a select set of customers get access to the program every month"
      },
      {
        "Q": "How do I get to know the number of days left in my Value Pass?",
        "A": "You can access it from 'Value Pass' section from the 'Side Menu'. All relevant details like number of days left, total / latest Cashback benefit earned etc. are available under that section."
      },
      {
        "Q": "How much Cashback have I earned on my last order?",
        "A": "You can access it from 'Value Pass' section from the 'Side Menu'. All relevant details like total / latest Cashback benefit earned, number of days left etc. are available under that section."
      },
      {
        "Q": "How much Cashback have I earned till date from Value Pass?’",
        "A": "You can access it from 'Value Pass' section from the 'Side Menu'. All relevant details like total / latest Cashback benefit earned, number of days left etc. are available under that section."
      },
      {
        "Q": "Am I eligible for other discounts on products even after purchasing the Value Pass?",
        "A": "Yes, Value Pass is a special value program, over and above any existing offers / discounts."
      },
      {
        "Q": "My order was partially/fully refunded. Will I get a cashback?",
        "A": "You will receive cashback for the order after deducting for any refund amount, in case of a partially refunded order. In case of a fully refunded order, no cashback will be issued."
      },
      {
        "Q": "Will a physical pass be delivered?",
        "A": "This is a virtual pass, available only on the Milkbasket app, and no physical pass will be delivered."
      },
      {
        "Q": "Can I transfer the Value Pass benefits to another account?",
        "A": "Value Pass is a special value program, for select customers only. The Pass or its benefits can not be transferred to any other account."
      }
    ]
  },
  "2": {
    "title": "MBeyond",
    "qnas": [
      {
        "Q": "How it works?",
        "A": "Access to mBeyond is simply based on your grocery spend on Milkbasket for the month of January.              The minimum qualifying spend is Rs. 5000."
      },
      {
        "Q": "What are the benefits?",
        "A": " 1. The access to benefits are customised to each household and can differ for different        households based on your spending habits @Milkbasket. The higher your spend is,the more we can give back.<br /> 2. Here you will find details on your curated selection of benefits for this month.For few benefits, you don't have to do anything, while for others your or your family member's active participation would be required. As always, we have tried to bring in the best to you and would always welcome suggestions to make it even better. So, for both bricks and bouquets, please let us know at mBeyond@milkbasket.com"
      }
    ]
  },
  "4": {
    "title": "TnCs",
    "qnas": [
      {
        "Q": "<div class='title-holder'>Jio Mart Cash Back Program Terms and Conditions (T&C)</div>",
        "A": `<div class="main tnc"><ol>
        <li>Program: Reliance Retail Limited (“Reliance”) offers this program, starting 18th September 2021 (“Jio Mart Cash Back Program” or “Program”). Any person, who (i) is an individual resident of India as defined under the Income Tax Act, 1961), (ii) is of eighteen (18) years of age or above and has the capacity to enter into a contract and (iii) meets such eligibility conditions as may be stipulated by Reliance from time to time; can become a member (“Jio Mart Cash Back Member”) of this Program upon voluntarily providing his/her mobile number at the time of shopping for the products and services sold or offered by Reliance. As part of the Jio Mart Cash Back Program, Jio Mart Cash Back Member can avail a wide range of offers through points earned (“Jio Mart Cash Back Points”) from any of our selected stores or websites or on mobile applications (collectively referred to as “Reliance Platforms”) operated and maintained by Reliance.</li>
        <li>Enrolment: Participation in the Jio Mart Cash Back Program is purely voluntary and subject to compliance with terms and conditions hereinafter contained (“Jio Mart Cash Back Terms and Conditions”, or “T&C”). Reliance may, at its sole discretion, reject the enrolment in the Jio Mart Cash Back Program for reasons it deems appropriate.</li>
        <li>Amendments: Reliance reserves the right to extend, cancel, discontinue, prematurely withdraw, change, alter or modify this Jio Mart Cash Back Program/T&C and/or any part thereof including the eligibility criteria and other pertinent terms and conditions at their sole discretion at any time during its validity as may be required including in view of business exigencies and/or changes by a regulatory authority and/or statutory changes and/or any reasons beyond their control and the same shall be binding on the Jio Mart Cash Back Member.</li>
        <li>Acceptance: A person’s application for enrolment in the Jio Mart Cash Back Program by providing his/her mobile number at the time of billing on any of the Reliance Platforms shall be deemed to be explicit acceptance of the T&C. Jio Mart Cash Back Member hereby acknowledges that he/she has understood and unconditionally accepts the T&C. Any Jio Mart Cash Back Member providing incorrect information or concealing/withholding any information from Reliance with intent to participate in this Jio Mart Cash Back Program will automatically be disqualified from participating in it. Reliance’s determination of such disqualification, at any point of time, shall be final and binding.</li>
        <li>Privacy: All information collected in the course of applying for and participating in the Jio Mart Cash Back Program shall be in accordance with Reliance’s privacy policy available at https://www.relianceretail.com/privacy-policy.html (“Privacy Policy”). The Privacy Policy is an integral part of the T&C and acceptance of the latter shall be construed as explicit consent and acceptance of the Privacy Policy.</li>
    </ol></div>`
      }
    ]
  }
}

export const AUTO_DEBIT_BANNERS = {
  "worry_free": [
    {
      "img": "worry-free_1611213548.jpeg"
    }
  ],
  "with_or_without": [
    {
      "img": "with-or-without_1611213510.jpeg"
    }
  ],
  "social_proof": [
    {
      "img": "social-proof_1611213478.jpeg",
      "contentOnBanner": "More Than <br> <strong class='f-w-h'>{autoDebitEnabledCustomers} Milkbasket users <br> now use AutoPay <br></strong> for top ups."
    }
  ]
}

export const BULK_BUY_DATA = { "freeHeading": "Delivery fee : <span class='text-line-through'>₹79 (+ GST)</span> FREE", "feeAppliedHeading": "Delivery Fee : <span>₹79 (+ GST)</span>", "subHeading": "Make a minimum order of ₹1200 to waive off delivery fee." }

export const INFO_POP_UP = { "isThrottle": true, "location": { "home": true, "addToBasket": true, "requestCashCollection": true, "shoppingCredit": true }, "info": "<ion-content class='ion-padding at-modal-content' style='--bg-theme: #0e3e91; --var-text-color: #fff; --ion-color-danger: #e06c91'><img alt='safety' class='icon-safety' width='24' height='28' src='../../../../assets/icon/noun-safety@2x.png' /> <p><strong>Dear Consumer,</strong></p> <p>Thanks for your interest in joining the Milkbasket community. <br> We are afraid we are running full throttle right now and are unable to onboard new customers. Since we have your details, we shall inform you as and when we open up more delivery slots in your area.<br> Thanks so much again and we really hope to start serving you soon.<br> <h2>We are here to help you<br /> stay safe @ your home! </h2> <p>Sincerely yours,<br /> Team Mb</p><br /> <ion-button id='close-button' class='ion-margin-bottom f-w-n' type='button' fill='outline' color='light' expand='block' size='default'> Okay, got it!</ion-button> </ion-content>" };

export const ANF_DETAIL_TYPE = {
  ADDRESS: 'address',
  GPS: 'gps',
  PIN: 'pin'
}


export const JIO_MAHACASHBACK = {
  BOTTOM_STRIP_HEADING: 'Add Items worth ₹{threshold} to earn Jio cashback',
  DAY_MAX_CASHBACK: `Congrats! You've max Jio Cashback for today!`,
  THRESHOLD_VALUE: 200,
}

export const JIO_EVENTS = {
  CLOSE : 'close',
  GET_JM_AUTH_TOKEN : 'getJMAuthenticatonToken',
  GETLATLNG: 'adparams',
  PRESENT_HEADER: 'presentHeader'
}

export const JIO_CASHBACK_CONSTRUCT = {
  HEADER: 'Jio Cashback',
  VISIBILITY: false,
  SHOW_FOOTER_STRIP:true,
  REDEMPTION_PERCENTAGE: 0.2,
  THRESHOLD_VALUE: 200,
  SECONDARY_THRESHOLD: 40,
  INITIAL_THRESHOLD: 50,
  MODIFIED_THRESHOLD_VALUE: 0,
  THRESHOLD_COMPLETE_MESSAGE: {
    FIRST_SECTION: `Congrats! You've earned`,
    SECOND_SECTION: `cashback for today!`
  },
  THRESHOLD_NOT_STARTED_YET: {
    FIRST_SECTION: `Add items worth`,
    SECOND_SECTION: `to earn`,
    THIRD_SECTION: `cashback`
  },
  THRESHOLD_STARTED: {
    FIRST_SECTION: 'You have earned',
    SECOND_SECTION: 'cashback'
  },
  MOV_ZERO_CONTENT:{
    FIRST_SECTION: 'Add any item to earn',
    SECOND_SECTION: 'cashback'
  },
  PERCENTAGE: 0.1,
  REDEEM: `<div class="main">
    <h4>How to redeem cashback?</h4>
    <ul>
        <li>You can start redeeming your cashback points from 2nd October 2021</li>
        <li>1 Jio Mart Cashback Point can be redeemed for every Re 1 spent, for a maximum of 20% of total basket value.</li>
        <li>The minimum basket value of ₹ 200 is required to redeem cashback points</li>
        <li>There is no upper limit to the redeemable value </li>
        <li>Cashback points can only be utilized within 90 days from the earning date</li>
        <li>If you have Jio Cashback Points in your wallet and your basket value is more than or equal to ₹ 200, the cashback will be automatically redeemed from your wallet.</li>
        <li>You can know the amount redeemed through the payment history tab after the successful delivery of your order.</li>
    </ul>
  </div>`,
  OFFER: `<div class="main">
    <h4>Jio Cashback is here!</h4>
    <div class="strip">
        Shop at Milkbasket & Get 10% cashback up to <br>
        ₹ 200 everyday!
    </div>
    <p>More info below:</p>
    <ul>
        <li>You can earn Jio cashback points on a minimum basket value of Rs 200/-, starting 18th September 2021 </li>
        <li>The basket value is calculated after you’ve redeemed your Jio cashback points (if any) </li>
        <li>Cashback can be earned on all channels of Reliance Retail using Point of Sale (POS) issued as below: </li>
        <p>- Reliance Smart, Fresh, Smart Point, JioMart</p>
        <p>- Reliance Digital, My Jio Stores, Jio Points</p>
        <p>- Reliance Trends, Project Eve, Ajio</p>
        <p>- Partner Grocery Stores (Kirana) representing Reliance</p>
        <p>- Partner merchants representing Reliance</p>
        <p>- Zivame, Netmeds, Urban Ladder (Reliance affiliates)</p>
        <li>Check <a class="tdn color-primary" >TnCs</a> for more information</li>
        <li>The percentage of Cashback points awarded will be based on the platform you’ve ordered from</li>
        <li>Jio Cashback members can know the current balance of Jio Cashback Points by visiting the JioMart website and through authenticated login credentials</li>
        <li>The cashback amount will be applicable only on the payment made and no loyalty points/ Coupons/ Discounts will be considered as the payment total for earning cash backs. – For Example, if you make a purchase for Rs 1000 and utilise Rs 100 cashback, you will earn cashback points on Rs 900 only.</li>
    </ul>
  </div>`,
  TABS: {
    OFFER: 'Offer',
    REDEEM_PROCESS: 'Redeem Process'
  }
};

export const FEEDBACK_DETAILS_SUCCESS_ALERT = {
  MESSAGE: "Feedback send successfully" 
}

export enum LOGOUT_MODE {
  NO_LOGOUT = "NO_LOGOUT",
  FORCE_LOGOUT = "FORCE_LOGOUT",
  DEFAULT_LOGOUT = "DEFAULT_LOGOUT",
  DATA_UI_LOGOUT = "DATA_UI_LOGOUT",
  REFRESH_TOKEN_API_LOGOUT = "REFRESH_TOKEN_API_LOGOUT",
};

export const FIREBASE_REFRESH_API_CONFIG = { 
  "timeout": 6000,
  "retryLimit": 1,
  "timeBeforeTokenCall": 11 
}

export const MEMBERSHIP = {
  STATUS: {
    0: 'Inactive',
    1: 'Active',
    2: 'Active'
  }
}

export const PAYMENT_CACHE = {
  ALL : "ALL",
  WITHOUT_INVITE: "WITHOUT_INVITE"
}