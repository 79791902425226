<ion-header class="ion-main-header margin-bottom-base">
  <ion-toolbar class="margin-top-0 bottom-sheet-header">
    <ion-buttons *ngIf="showButtons" slot="start" class="ion-buttons-arrow-back">
      <ion-button (click)="navigateBack()">
        <ion-icon  class="icon-arrow-back icons-property icon-md"  slot="icon-only"></ion-icon>       
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-no-padding" [ngClass] = "{'pb-5' : !showButtons}">
      <ng-content></ng-content>
    </ion-title>
    </ion-toolbar>
</ion-header>