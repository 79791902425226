//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';

import { ImgOnDemandModule } from '@Main/modules/shared/img-on-demand/img-on-demand.module';

import { TwoRowModule } from '@Features/product-list/components/horizontal/two-row/two-row.module';
//#endregion Module Imports

//#region Component Imports
import { TwoRowProductsListComponent } from '@Widgets/blocks/collection/two-row-products-list/two-row-products-list.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ImgOnDemandModule,
    TwoRowModule
  ],
  exports: [TwoRowProductsListComponent],
  declarations: [TwoRowProductsListComponent]
})
export class TwoRowProductsListModule extends DefaultModule { }