import { Observable } from 'rxjs';
import { REGISTRATION_APIS } from '../../constants/registration-APIs';
import { HttpService } from '../../../shared/providers/http/http.service';
import { EVENT_NAMES, LoginObject } from '../../constants/registration-constants';
import { EventsService } from '../../../shared/providers/events/events.service';
import { Injectable } from "@angular/core";
import { environment } from '../../../../environments/environment';
import { DataService } from '@Globals/providers/data.service';
import { CORE_ROUTES } from '@App/core/constants/coreRoutes';
import { REGISTRATION_ROUTES } from '@App/registration/constants/registration-routes';
import { NavController } from '@ionic/angular';

@Injectable()
export class LoginService {
  static fraudInfo = {
    error: "",
    error_data: {}
  };
  constructor(private httpService: HttpService, 
    private navCtrl: NavController,
    private eventsService: EventsService,private dataService: DataService,) {}

  // TODO : Update following calls to get required data

  logIn(mobileNumber, password): Observable<object> {
    return this.httpService.get(
      REGISTRATION_APIS.LOG_IN,
      {},
      this.httpService.getBasicAuthHeader(mobileNumber, password)
    );
  }

  generateLoginOTP(mobileNumber, retry: boolean, appHash?: string): Observable<object> {
    let dataToBeSent;
    if (retry) {
      dataToBeSent = {
        retry: true,
        retryType: 'voice',
        mobile: mobileNumber,
        udid: this.dataService.uuid,
        loaderMessage: 'Initializing call..'
      };
    } else {
        dataToBeSent = { mobile: mobileNumber,
          udid: this.dataService.uuid, appHash, };
    }
    return this.httpService.post(
      REGISTRATION_APIS.GENERATE_LOGIN_OTP,
      dataToBeSent
    );
  }

  loginWithOTP(mobileNumber, enteredOtp): Observable<object> {
    this.eventsService.trackEvents(EVENT_NAMES.DOING_LOGIN_WITH_OTP, {mobileNumber, enteredOtp});
    const object: LoginObject = {
      mobile: mobileNumber,
      otp: enteredOtp,
      mb_version: { app_store: environment.appStoreVersion, code_push: environment.codePushVersion },
      source: this.dataService.currentPlatform
    }
    return this.httpService.post(REGISTRATION_APIS.LOGIN_WITH_OTP, object);
  }

  loginWithTrueCaller(): Observable<object> {
    return this.httpService.post('LOGIN_TRUE_CALLER', {});
  }

  showFraudAlert(response) {
    LoginService.fraudInfo.error = response.data.error;
    LoginService.fraudInfo.error_data = response.data.error_data;
    const errorData = [];
    if (response.data.error_data && typeof response.data.error_data === 'object' && Object.keys(response.data.error_data).length) {
      Object.keys(response.data.error_data).forEach((key: string) => {
        errorData.push({ phone: key, address: response.data.error_data[key] });
      });
    }
    this.dataService.paramData = {
      ...this.dataService.paramData,
      errorType: response.data.error,
      errorData: [...errorData],
    };
    setTimeout(() => {
        this.navCtrl.navigateForward(
            '/' + CORE_ROUTES.REGISTRATION + '/' + REGISTRATION_ROUTES.FRAUD_ALERT
          );
    },0);
}
}
