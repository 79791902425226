//#region Imports

//#region Angular Imports
import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { Router } from '@angular/router'; 
//#endregion Angular Imports

//#region Ionic Imports
import { NavController, Platform } from '@ionic/angular';
import { HTTPResponse } from '@ionic-native/http/ngx';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseComponent } from '@Widgets/blocks/base/base.component';
//#endregion Component Imports

//#region Providers Imports
import { CollectionService } from '@Globals/providers/collection.service';
import { AuthService } from '@Globals/providers/auth.service';
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { ModalService } from '@Globals/providers/modal.service';
import { VideoService } from '@App/listing/providers/video.service';
import { DeepLinkService } from '@App/shared/providers/deeplink/deep-link.service';
import { ProductListManagerService } from '@Features/product-list/providers/product-list-manager.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, EVENT_SOURCES, PRODUCT_DETAILS } from '@Globals/constants/listing/constants';
import { CollectionType } from '@Globals/constants/listing/enums';
import { LISTING_ROUTES } from '@Globals/constants/listing/routes'; 
import { CollectionDisplayType } from "@Globals/constants/listing/enums";
import { environment } from '@Env/environment';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-base-collection-block',
  template: '<p>Base Works</p>',
  providers: [ProductListManagerService],
  styleUrls: ['./base.component.scss'],
})
export class BaseCollectionComponent extends BaseComponent {
    bannerFilePath = environment.bannerPath;
 
  constructor(
    public dataService: DataService,
    public navCtrl: NavController,
    public modalService: ModalService,
    public eventsService: EventsService,
    public videoService: VideoService,
    public authService: AuthService,
    public masterDataService: MasterDataService,
    public deepLinkService: DeepLinkService,
    public plmService: ProductListManagerService,
    public platform:Platform,
    public _cd: ChangeDetectorRef,
    public router: Router,
    public collectionService: CollectionService,
    public zone: NgZone
  ) {
    super();
  }

  getCollectionListProducts() {
    const dataToSend: any = {
        collectionId: this.data.id,
        leafRequest: [{
            leafId: this.data.metaData.cells[0].collectionLeafId,
            listId: this.data.metaData.cells[0].image.leafBanner.listId
        }],
        hideLoader: true
    };
    if(this.collectionService.recommendationCollection(this.data)) {
        dataToSend['displayType'] = this.data.table.displayType;
    }
    this.collectionService.getAllCollectionPlp(dataToSend).subscribe((response: HTTPResponse) => {
        if (response.data.status &&
            response.data.data &&
            response.data.data[dataToSend.leafRequest[0].leafId]) {
            let data = response.data.data[dataToSend.leafRequest[0].leafId].data;
            if(!data?.length){
                const dataToSend =
                {
                  cell: this.data.metaData.cells[0].identifier,
                  leafId: this.data.metaData.cells[0].collectionLeafId,
                  listId: this.data.metaData.cells[0].image.leafBanner.listId
              }
              if(this.data?.table?.rows[0]?.columns[0]?.['images']?.leafBanner?.bannerType !== '10'){
                this.collectionService.expireCollection(dataToSend,this.data.id);
              }            }
            
            if(data){
                data = data?.map((product: any)=> {
                    if(product?.variantProducts && product?.variantProducts?.length){
                    product = this.plmService.getVariantDefaultProduct(product);
                    }
                return product;
                });
            }
            this.data['productList'] = data && data.length >15 ? data.splice(0,15): data;
            this.data['showCollection']=  data.length ? true:false;
            if(this.data?.table.displayType === CollectionDisplayType.ProductLaunchTypeListing){
                this.data['collectionData']= this.data;
                this.data['leafRequest']= dataToSend.leafRequest;
                this.data['collection']= LISTING_ROUTES.BASE + LISTING_ROUTES.COLLECTIONPLP + dataToSend.collectionId + '/' + dataToSend.leafRequest[0].leafId;
            }
            this._cd.markForCheck();
        }
    });
  }

  navigateToCollectionList(column?) {
    if(this.collectionService.recommendationCollection(this.data)) {
        column = this.data.table.rows[0]['columns'][0];
    }
    let selectedMetaData;
    this.dataService.collectionsConfigData = {
        leafRequest: [],
        listImageUrl: null,
        collection: null
    };
    const currentLeafIdAndListId = {
        leafId: null,
        listId: null
    };
    
    this.data?.metaData.cells.forEach(metaData => {
        if (column?.identifier === metaData.identifier) {
            selectedMetaData = metaData;
            currentLeafIdAndListId.leafId = metaData.collectionLeafId;
            currentLeafIdAndListId.listId = metaData.image.leafBanner.listId;
        } else if (!column) {
            selectedMetaData = metaData;
        }
        this.dataService.collectionsConfigData.leafRequest.push({
            leafId: metaData.collectionLeafId,
            listId: metaData.image.leafBanner.listId
        });
        this.dataService.collectionsConfigData.collection = this.data;
    });
    let eventArgs = {
        name: column?.title && column.title.text,
        collection_title: this.data?.name || '',
        collection_id: this.data?.id || '',
        leaf_id: currentLeafIdAndListId.leafId,
        source: this.props?.eventSource
    };
    if(this.props?.data) {
        eventArgs = { 
            ...eventArgs, 
            ...{ 
                one_pager_id: this.props?.data.one_pager_id, 
                one_pager_name: this.props?.data.one_pager_name,
                sortOrder: this.data.sortOrder, 
                displayPosition : this.data.displayPosition
            }
        }; 
        this.dataService.onePagerCollectionRedirect[this.data.id] = eventArgs;
    }
    if (column?.url && /collection-listing/i.test(column?.url)) {
        eventArgs['type'] = EVENT_NAMES.ONE_PAGER;
        delete eventArgs.leaf_id;
    }
    this.eventsService.trackEvents(EVENT_NAMES.COLLECTION_VIEW, eventArgs);

    // selectedMetaData.url is using for showing banner in collection.
    if (column && /product/i.test(column.url)) {
        this.plmService.productDetailsModal(
            column.url.split('/product/')[1],
            0,
            PRODUCT_DETAILS
        );
    } else if (column?.url) {
        this.deepLinkService.navToDeepLinkUrl(column.url, EVENT_SOURCES.COLLECTION);
    } else if (column?.type === CollectionType.Video || column?.type === CollectionType.VIDEO_CARD) {
        this.videoService.selectedVideoCollectionData.next(this.data);
        this.navCtrl.navigateForward(
            LISTING_ROUTES.BASE +
            LISTING_ROUTES.COLLECTION_VIDEO +
            currentLeafIdAndListId.listId + '/' +
            currentLeafIdAndListId.leafId + '/' +
            this.data?.id + '/' +
            EVENT_SOURCES.COLLECTION, { animated: false }
        );
    } else {
        this.navCtrl.navigateForward(
            LISTING_ROUTES.BASE +
            LISTING_ROUTES.COLLECTIONPLP +
            this.data?.id + '/' +
            selectedMetaData.collectionLeafId + '/' +
            EVENT_SOURCES.COLLECTION, { animated: false }
        );
    }
  }

  viewAllCollection(collection) {
      this.dataService.mBeyondAllCollection = collection;
      this.navCtrl.navigateForward(
          LISTING_ROUTES.BASE +
          LISTING_ROUTES.M_BEYOND_ALL_COLLECTION, { animated: false }
        );
  }
}
