export {
    UserAccountStatus,
    InvitePageSections,
    RefereeReasonTypes,
    RefereeStatusTypes,
    ACCOUNT_DEACTIVATION_STATUS,
    AccountClosure,
} from '@App/profile/constants/profile-enums';

export enum ReferStatus {
    ELIGIBLE = 1,
    NON_ELIGIBLE = 2
}

export enum EmailVerification {
    NOT_VERIFIED = 0,
    VERIFIED = 1
}