//#region Imports

//#region Angular Imports
import { Component, ChangeDetectionStrategy } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular'; 
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
import { BackType } from '@Globals/constants/listing/enums';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbpc-tncs',
  templateUrl: './tncs.component.html',
  styleUrls: ['./tncs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TncsComponent extends DefaultComponent {
  backAction = BackType.Close;
  constructor(public masterDataService: MasterDataService,
    private modalCtrl: ModalController) {
      super();
    }

  closeTnc() {
   this.modalCtrl.dismiss();
  }
}
