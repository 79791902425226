//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { CollectionDisplayType, RecommendationType } from '@Globals/constants/listing/enums';
import { environment } from '@Env/environment';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-recommendation-products-list-block',
  templateUrl: './recommendation-products-list.component.html',
  styleUrls: ['./recommendation-products-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendationProductsListComponent extends BaseCollectionComponent {
  recommendationType: RecommendationType;
  collectionDisplayType = CollectionDisplayType;
  showViewAll = true;
  eventData: any;
  backgroundUrl: string;
  mbOnInit(): void {
    this.data && this.getCollectionListProducts();
    if(this.props) {
      this.showViewAll = this.props.showViewAll === null ||  this.props.showViewAll === undefined ? true: this.props.showViewAll;
      this.recommendationType = this.props.recommendationType;
      this.eventData = { Source: this.props?.eventSource, collection_id: this.data.id };
      this.data.table.rows.forEach((row, rowIndex) => {
        row.columns.forEach((column, columnIndex) => {
          if (rowIndex === 0 && columnIndex === 0) {
            this.backgroundUrl = `${environment.bannerPath}${column.images.leafBanner.image}`;            
          }
        });
      });
    }
    super.mbOnInit();
  }
}
