import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Utilities } from '../../../shared/classes/utility/utilities';
import { CleverTap } from '@ionic-native/clevertap/ngx';
import { APPSFLYER, CLEVERTAP_CHANNELS } from '../../constants/core-constants';
import { DeepLinkService } from '../../../shared/providers/deeplink/deep-link.service';
import { IOS } from '../../../shared/constants/constants';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { noop } from 'rxjs';
import { EVENT_NAMES } from '../../constants/core-constants';
import { EventsService } from '@Globals/providers/events.service';

export class InitializePlugins {
    constructor (
        private appsflyer: Appsflyer,
        private platform: Platform,
        private cleverTap: CleverTap,
        private firebase: FirebaseX,
        private deepLinkService: DeepLinkService,
        private eventsService: EventsService
    ) {
    }

    async doInitializePlugins() {
        this.initializeAppsflyer();
        this.initializeCleverTap();
        this.initializeFireBase();
        this.initializeDeepLinkListener();
    }

    private initializeAppsflyer() {
        const options = {
            devKey: environment.APPSFLYER.DEV_KEY,
            isDebug: environment.APPSFLYER.IS_DEBUG,
            onInstallConversionDataListener:
                environment.APPSFLYER.ON_INSTALL_CONVERSION_DATA_LISTENER,
            appId: this.platform.is(IOS) ? environment.APPSFLYER.APP_ID : ''
        };
        this.appsflyer.initSdk(options).then(result => {
            const conversionData = Utilities.parseJSONString(result);
            if (conversionData && conversionData.data.is_first_launch === true && conversionData.data.af_status === APPSFLYER.NON_ORGANIC) {
                conversionData.data.deeplink = conversionData.data.af_dp || conversionData.data.af_deeplink || null;
                this.deepLinkService.deepLinkHandler(conversionData.data);
            }
        }).catch();
        this.appsflyer.setAppInviteOneLinkID(environment.APPSFLYER.APP_INVITE_ONE_LINK_ID);
    }

    private initializeCleverTap() {
        this.cleverTap.setDebugLevel(environment.CLEVERTAP.DEBUG_LEVEL).catch(noop);
        this.cleverTap.notifyDeviceReady().catch(noop);
        CLEVERTAP_CHANNELS.forEach((channel) => {
            this.cleverTap.createNotificationChannelWithSound(
                channel.ID,
                channel.NAME,
                channel.DESCRIPTION,
                channel.IMPORTANCE,
                channel.SHOW_BADGE,
                channel.SOUND
            ).catch(noop);
        });
        this.cleverTap.initializeInbox().catch(noop);
    }

    private initializeFireBase() {
        this.firebase.setAnalyticsCollectionEnabled(true).catch(noop);
        this.firebase.setCrashlyticsCollectionEnabled(true).catch(noop);
        this.firebase.setPerformanceCollectionEnabled(true).catch(noop);
    }

    private initializeDeepLinkListener() {
        document.addEventListener(
            'onDeepLink',
            (event) => {
                if (event) {
                    this.deepLinkService.deepLinkHandler(event);
                    this.eventsService.trackEvents(EVENT_NAMES.CAMPAIGN_APP_OPENED, { 'redirect url': event['deeplink'] });
                }
            },
            false
        );
    }
}
