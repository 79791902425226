<ion-header class="ion-main-header sticky-header">
  <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium padding-right-medium pl-0">
    <ion-buttons class="ion-buttons-arrow-back" id="custom-arrow-back" slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon class="icon-arrow-back icons-property icon-md" color="dark" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-no-padding ">
      Offer
    </ion-title>
    <p>Best Curated Offers for you to Max saving</p>
  </ion-toolbar>
</ion-header>
<ion-content class="payment-list padding-top-base">
  <ion-list class="ion-no-padding" lines="none">
    <ion-list-header class="sub-heading f-w-b ion-no-paading padding-horizontal-medium">Offers ({{offerList.length}})</ion-list-header>
    <ion-item class="ion-no-padding offer-list" mode="md">
      <div class="scroll vertical-offer-list" scroll-x="true">
        <ion-card class="ion-card-image v-list ion-no-margin" *ngFor="let paymentOffer of offerList">
          <ion-item color="none" lines="none">
            <ion-icon class="big-active-offer icon-common-medium" slot="start"></ion-icon>
            <ion-label class="f-w-m">{{paymentOffer.offer}} </ion-label>
          </ion-item>
          <div class="cashback-listing" [innerHTML]="paymentOffer.offerDescription || paymentOffer.offer_description"></div>
        </ion-card>
      </div>
    </ion-item>
  </ion-list>
</ion-content>
