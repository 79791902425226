import { Injectable, NgZone } from '@angular/core';
import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { from, noop, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { ANDROID, APP_UPDATE_REQUIRED, EVENT_NAMES, FIREBASE_REFRESH_API_CONFIG, GENERATE_CONTENT_FOR_ALERT_LAYER, HTTP_ERROR, IOS, KEY, LOGOUT_MODE, MB_AUTH_KEY, MB_REFRESH_KEY, SERVER_DOWN_ERROR, WEB } from '../../constants/constants';
import { Utilities } from '../../classes/utility/utilities';
import { AlertService } from '../alert/alert.service';
import { ACCEPT_ENCODING_HEADER, APIs } from '../../constants/APIs';
import { ModalController, Platform } from '@ionic/angular';
import { EventsService } from '../events/events.service';
import { AppUpdateComponent } from '../../components/app-update/app-update.component';
import { LoggingService } from '../logging/logging.service';
import { LoaderService } from '../loader/loader.service';
import { HttpClient } from '@angular/common/http';
import { EventTrackingPlugins } from '@Globals/constants/listing/enums';
import { Device } from '@ionic-native/device/ngx';

interface RequestOptions {
  method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  data?: {
    [ index: string ]: any;
  };
  params?: {
    [ index: string ]: string | number;
  };
  serializer?: 'json' | 'urlencoded' | 'utf8';
  timeout?: number;
  headers?: {
    [ index: string ]: string;
  };
}
interface KeyValues {
  [ index: string ]: any;
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  pendingRequest = {
    method: '',
    url: ''
  };
  retryConfig = FIREBASE_REFRESH_API_CONFIG;
  alreadyCalled: boolean = false;
  handleError = async (error, url) => {
    if(url.includes(APIs.DATA_UI)) {
      let errorObj;
      try {
        errorObj = error ? JSON.stringify(error) : ''; 
      } catch(e) {
        if(e.message) {
          errorObj = 'unparsable error:' + e.message;
        } else {
          errorObj = 'unparsable error: without message';
        }
      }
      this.eventService.trackEvents(EVENT_NAMES.DATA_UI_FAILED, { authToken: this.authService.getAuthKey() ? this.authService.getAuthKey() : '', refreshToken: this.authService.getRefreshKey() ? this.authService.getRefreshKey() : '', auth_expire_time: this.authService.getRefreshTime() ? this.authService.getRefreshTime() : '', status: error && error.status ? error.status : '', errorObj: errorObj, message: "Data ui failed"}, false, EventTrackingPlugins.All);
    }
    if(url.includes(APIs.REFRESH_TOKEN)) {
      this.eventService.trackEvents(EVENT_NAMES.REFRESH_API_FAILURE, { authToken: this.authService.getAuthKey(), refreshToken: this.authService.getRefreshKey(), auth_expire_time: this.authService.getRefreshTime(), status: error.status, errorObj: error}, false, EventTrackingPlugins.Firebase);
      if(error && error.status != 401) {
        this.resetTokenVariable();
        this.authService.isLogout.next(LOGOUT_MODE.REFRESH_TOKEN_API_LOGOUT);
      }
    }
    if(error && error.status === 401) {
      this.resetTokenVariable();
      this.authService.isLogout.next(url.includes(APIs.REFRESH_TOKEN) ? LOGOUT_MODE.REFRESH_TOKEN_API_LOGOUT : LOGOUT_MODE.DEFAULT_LOGOUT);
    } 
    this.loadingService.hideLoading();
    this.pendingRequest.url = null;
    this.pendingRequest.method = null;
    this.loggingService.logMessage(HTTP_ERROR, error);
    let errorMessage = '';
    if (error && error.error) {
      const errorObj = Utilities.parseJSONString(error.error);
      if (errorObj) {
        if (errorObj.error === APP_UPDATE_REQUIRED) {
          this.openAppUpdateModal();
        }
      } else {
        // error status -3 stands for Host could not be resolved and -4 stands for Request timed out
        if (!url.endsWith(APIs.BASKETS) && (error.status >= 500 || error.status === -3 || error.status === -4)) {
          SERVER_DOWN_ERROR.BUTTONS.OKAY.handler = () => noop();
          const internetErrorContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
            SERVER_DOWN_ERROR.META_DATA
          );
          this.alertService.presentAlertConfirm(
            internetErrorContent,
            [ SERVER_DOWN_ERROR.BUTTONS.OKAY ],
            SERVER_DOWN_ERROR.CSS_CLASS
          );
        }
        this.eventService.trackEvents(HTTP_ERROR, { status: error.status, error: error.error, url });
      }
    }
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  };

  dismissLoaderAfterGivenTimer = (timer) => {
    setTimeout(
      () => {
        this.zone.run(() => {
        });
      }, timer);
  };

  constructor (
    private http: HTTP,
    private authService: AuthService,
    private alertService: AlertService,
    private zone: NgZone,
    private loadingService: LoaderService,
    private eventService: EventsService,
    private modalCtrl: ModalController,
    private loggingService: LoggingService,
    private httpc: HttpClient,
    private platform: Platform,
    private device: Device,
  ) { 
  }

  setupSSL() {
    this.http.setServerTrustMode("pinned") //<=== Add this function 
    .then(() => {
    })
    .catch((error) => {
      let errorStr = 'Error not parseable';
      try {
        errorStr = JSON.stringify(error);
      } catch (e) {}
        this.eventService.trackEvents(EVENT_NAMES.SSL_PIN_FAILED, {
          time : new Date(),
          uuid : this.device.uuid,
          os_version: this.device.version,
          device_brand_name:this.device.manufacturer,
          device_model_name:this.device.model,
          error: errorStr,
          is_internet_available: window.navigator.onLine
        });
    });
  }

  sendRequest(
    url: string,
    options: RequestOptions,
    isPayment = false,
    isOther = false
  ): Observable<object> {
      if(url && url.includes('//')){
        url = url.replace('//','/');
      }
      let showLoader:boolean = true;
      if (options && options.params) {
        options.data = options.data || {};
        if ('hideLoader' in options.params) {
          options.data.hideLoader = true;
          showLoader = false;
          delete options.params.hideLoader;
          
        }
        if ('addWhiteLoader' in options.params) {
          options.data.addWhiteLoader = options.params.addWhiteLoader;
          showLoader = true;
          delete options.params.addWhiteLoader;
        }
      }
      if(options?.data && ('hideLoader' in options.data)) {
          showLoader = false;
          delete options.data.hideLoader;
      }
      if(this.pendingRequest.method === options.method && this.pendingRequest.url === url && options?.data?.avoidDuplicateCalls) { 
        showLoader = false;
        return of();
      }
      options?.data?.avoidDuplicateCalls && (delete options.data.avoidDuplicateCalls);
      this.pendingRequest.url = url;
      this.pendingRequest.method = options.method;  
      if (!options.headers) {
        options.headers = {};
      }
      options.headers[ ACCEPT_ENCODING_HEADER.key ] = ACCEPT_ENCODING_HEADER.value;
      if (this.authService.authKey) {
        if (isPayment) {
          options.headers[ KEY ] = this.authService.getAuthKey();
        } else {
          options.headers[ MB_AUTH_KEY ] = this.authService.getAuthKey();
        }
      }
      if(url.includes(APIs.REFRESH_TOKEN)) {
        this.alreadyCalled = true;
        if(this.authService.getRefreshKey()) {
          options.headers[ MB_REFRESH_KEY ] = this.authService.getRefreshKey();
        }
      }
      this.loggingService.logMessage(`API Request for ${url}: `, options);
      if (isPayment) {
        url = `${environment.paymentUrl}${url}`;
      } else if (isOther) {
        url = url;
        delete options.headers[MB_AUTH_KEY];
      } else {
        url = `${environment.apiUrl}${url}`;
      }
    return from(this.callHttpUrls(url, options, this.retryConfig.retryLimit, showLoader)).pipe(
      timeout(50000),
      tap((response: HTTPResponse) => {
        this.loadingService.hideLoading();
        response.data = Utilities.parseJSONString(response.data);
        this.loggingService.logMessage('API Response: ', response);
      }),
      catchError((error: HTTPResponse) => this.handleError(error, url)),
      finalize(() => {
        this.resetTokenVariable();
        this.loadingService.hideLoading();
        this.pendingRequest.url = null;
        this.pendingRequest.method = null;
      }));
  }

  wait(ms:number) { 
    return new Promise((resolve)=>{
      this.zone.runOutsideAngular(() => {
        const intervalInstance = setInterval(()=>{
          if(!this.alreadyCalled) {
            resolve({intervalInstance : intervalInstance, status: true});
          }
        },300);
        setTimeout(() => {
          resolve({intervalInstance : intervalInstance, status: false});
        }, ms);
      })
    });
  }


  callHttpUrls(url, options, retries, showLoader) {
    if(showLoader){
      this.loadingService.showLoading();
    }
    this.setDataSerializer('json');
    return new Promise((resolve, reject)=>{
      this.http.sendRequest(url, options).then((response)=>{
        this.loadingService.hideLoading();
        this.loggingService.logMessage(`API Response for ${url}: `, options);
        resolve(response);
      })
      .catch(async (error)=> {
        this.loadingService.hideLoading();
        this.loggingService.logMessage(`API Response for ${url}: `, options);
        if(retries === this.retryConfig.retryLimit && !this.alreadyCalled && error?.status === 401 && !url.includes(APIs.REFRESH_TOKEN)) {
          await this.updateToken();
        }
        if(retries != 0 && !url.includes(APIs.REFRESH_TOKEN) && error?.status === 401) {
          this.eventService.trackEvents(EVENT_NAMES.AUTH_EXPIRED, { auth_token: this.authService.getAuthKey(), auth_expire_time: this.authService.getRefreshTime(), url: url}, false, EventTrackingPlugins.Firebase);
          const refreshResolve:any = await this.wait(this.retryConfig.timeout);
          if (refreshResolve && refreshResolve.status && this.authService.getAuthKey()) {
            if (options.headers[ KEY ]) {
              options.headers[ KEY ] = this.authService.getAuthKey();
            } else {
              options.headers[ MB_AUTH_KEY ] = this.authService.getAuthKey();
            }
            this.callHttpUrls(url, options, retries-1, showLoader).then(resolve).catch(reject);
          } else {
            if (refreshResolve && refreshResolve.status === false) {
              this.eventService.trackEvents(EVENT_NAMES.REFRESH_DELAYED_RESPONSE, { authToken: this.authService.authKey, refreshToken: this.authService.refreshKey}, false, EventTrackingPlugins.Firebase);
            }
            reject(error);
          }
          if( refreshResolve && refreshResolve.intervalInstance ) {
            clearInterval(refreshResolve.intervalInstance);
          }
        } else {
          reject(error);
        }
      });
    })
  }

  checkWhenToRefreshToken() {
    if(Utilities.validateDates(this.authService.getRefreshTime(), new Date()) && !this.alreadyCalled) {
      return true;
    }
    return false;
  }

  getNewToken(): Observable<object> {
    const body = {
      mb_version: { app_store: environment.appStoreVersion, code_push: environment.codePushVersion },
      source: Utilities.isApp() ? (this.platform.is(ANDROID) ? ANDROID : IOS) : WEB,
      hideLoader: true
    }
    return this.post(APIs.REFRESH_TOKEN, body)
    .pipe(map((response: HTTPResponse) => {
        // store token, refresh token, expiry time
        this.resetTokenVariable();
        if(response?.data?.status) {
          this.authService.setAuthKey(response?.data?.data?.auth_key);
          this.authService.setRefreshKey(response?.data?.data?.refresh_key, Utilities.addMinutes(new Date(), response?.data?.data?.authExpiry - this.retryConfig.timeBeforeTokenCall));
          const iframe = document.querySelector('iframe');
          if(iframe){
            iframe.contentWindow.postMessage({
              eventName:'selfHelpAuthUpdate',
              auth_key:response?.data?.data?.auth_key
            }, '*');
          }
          return response;
        }
    }));
  }

  resetTokenVariable() {
    setTimeout((_)=>{
      this.alreadyCalled = false;
    },100);
  }

  updateToken() {
    return new Promise((resolve)=>{
      this.getNewToken().subscribe((response: HTTPResponse) => {  
        resolve(true);
      });
    })
  }

  get(
    url: string,
    parameters?: KeyValues,
    headers?: KeyValues,
    isOther?
  ): Observable<object> {
    return from(
      this.sendRequest(url, { method: 'get', params: parameters, headers }, false , isOther)
    );
  }

  post(
    url: string,
    body: KeyValues,
    headers?: KeyValues,
    isPayment?,
  ): Observable<object> {
    return from(
        this.sendRequest(url, { method: 'post', data: body, headers }, isPayment));
  }

  put(url: string, body: KeyValues, headers?: KeyValues): Observable<object> {
    return from(this.sendRequest(url, { method: 'put', data: body, headers }));
  }

  patch(url: string, body: KeyValues, headers?: KeyValues): Observable<object> {
    return from(
      this.sendRequest(url, { method: 'patch', data: body, headers })
    );
  }

  delete(
    url: string,
    parameters: KeyValues,
    headers?: KeyValues
  ): Observable<object> {
    return from(
      this.sendRequest(url, { method: 'delete', params: parameters, headers })
    );
  }

  getBasicAuthHeader(username: string, password: string) {
    return this.http.getBasicAuthHeader(username, password);
  }

  setDataSerializer(serializer) {
    this.http.setDataSerializer(serializer);
  }

  setRequestTimeout(timeout: number) {
    this.http.setRequestTimeout(timeout);
  }

  async openAppUpdateModal() {
    const modal = await this.modalCtrl.create({
      component: AppUpdateComponent,
      cssClass: 'app-update-modal',
      backdropDismiss: false
    });
    await modal.present();
  }

  downloadPdf(url){ 
    return this.httpc.get(url, { 'responseType': 'blob', observe: "response","headers":{'mb-auth-key':this.authService.authKey} });

  }
}
