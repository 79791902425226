<div class="top-fixed">
  <div class="fixed-bottom-sheet">
    <mbs-bottom-sheet-header [component]="viewTypes.ConfirmSociety">
      Select Delivery Location
    </mbs-bottom-sheet-header>
    </div>
  </div>
  <ion-content class="ctn-gt-space-p">
      <ng-container *ngIf="!dataService.isUnserviceableFlow">
        <div *ngIf="authService.userData?.societyObj?.societyType !== societyType.Locality; else locality" class="confirm-society">
          <p text-ellipsis-ml>Near By Societies/Apartments ({{signupService.nearBySocietiesTotal}})</p>
        </div>
        <ng-template #locality>
          <p class="location-strip text-dark">Please mark the location pin accurately. This will help us deliver your order at the right door every morning.</p>
        </ng-template>
      </ng-container>
      <ion-list class="society-block">
        <ion-item class="ion-no-padding confirm-list ion-margin-top" margin-bottom-medium lines="none">
          <ion-icon slot="start" class="ion-no-margin icon-location-active icons-property" ></ion-icon>
          <ion-label class="location-text ion-no-margin" >
            <h3 class="text-ellipsis-tl">{{authService.userData.societyObj.name}}</h3>
            <p text-ellipsis-ml>{{authService.userData.societyObj.location}} {{authService.userData.societyObj.location.length ? ',' : ''}} {{ authService?.userData?.cityObj?.name }}</p>
          </ion-label>
          <ion-button *ngIf="authService.userData?.societyObj?.societyType !== societyType.Locality" (click)="goBack()" class=" t-t-n no-ripple-effect" fill="clear" size="default">
              Change
          </ion-button>
       
        </ion-item>
      </ion-list>
      <ion-button (click)="confirmSelection()" fill="solid" size="large" shape="round" expand="full" class="ion-text-capitalize">{{ dataService.isUnserviceableFlow ? "Continue to Add Details" : "Confirm location & proceed" }}</ion-button>
  </ion-content> 