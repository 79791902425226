
//#region Angular Imports
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
//#region Imports
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultWidgetModule } from '@Lib/defaults/default-widget.module';
//#endregion Module Imports

//#region Component Imports
import { DealsOfTheDayComponent } from '@Features/product-list/components/horizontal/deals-of-the-day/deals-of-the-day.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import annotations from '@Features/product-list/product-list.annotations';
//#endregion Other Imports

//#endregion Imports

@NgModule({
  declarations: [DealsOfTheDayComponent],
  imports: [...annotations.imports,SwiperModule],
  exports: [...annotations.exports, DealsOfTheDayComponent],
  providers: [...annotations.providers],
})
export class DealsOfTheDayModule extends DefaultWidgetModule { }
