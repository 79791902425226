<ion-button *ngIf="utility.isApp()" color="light" class="whats-app-share has-opacity {{((componentType === 4) || ( componentType === 5) || ( componentType === 6 ) || ( componentType === 7 ) ) ? '' : 'absolute'}}" (click)="$event.stopPropagation();generateLinkAndShare()">
  <ng-container *ngIf="((componentType === 4) || ( componentType === 5) || ( componentType === 6) || ( componentType === 7 )); else shareForOtherPages">
    <span slot="icon-only" class="icon-share icons-property icon-base"></span>
  </ng-container>
  <ng-template #shareForOtherPages>
    <ng-container *ngIf="SOCIAL_SHARING_CHANNELS.whatsapp.isAvailable;else shareWithOptions;">
      <span class="whats-app-icon"></span>Share</ng-container>
    <ng-template #shareWithOptions>
      <span class="icon-share icons-property" color="dark"></span>
    </ng-template>
  </ng-template>
</ion-button>