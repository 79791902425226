
<ion-header class="sticky-header">
  <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium padding-horizontal-0">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
          <ion-icon class="icon-close alert-button-close icon-md icons-property" color="dark" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-padding-horizontal">
      <h2>Wallet breakup</h2>
    </ion-title>
  </ion-toolbar>
</ion-header>


<div class="breakup-wrap content-wrap">
  
    <ion-item class="wb-balance-info f-m" lines="none">
      <ion-label class="ion-no-margin">Available balance <span class="f-w-s-b" [ngClass]="{ 'txt-red': masterDataService.masterData?.balance <=0 }"> {{ masterDataService.masterData?.balance | currency: 'INR'}} </span></ion-label>
    </ion-item>
    <div class="item-holder">
      <ion-item class="wallet-strip ion-no-padding ctn-gt-space-m" lines="full">
        <ion-img src="assets/img/wallet-icon.svg" slot="start"></ion-img>
        <ion-label>
          <h6 class="f-m">Wallet</h6>
          <p>Applicable on all items & services</p>
        </ion-label>
        <ion-label slot="end" class="f-w-s-b text-size-md" [ngClass]="{ 'txt-red': masterDataService.masterData?.walletInfo?.mainCredits <=0 }"> {{ masterDataService.masterData?.walletInfo?.mainCredits | currency: 'INR' }}</ion-label>
      </ion-item>
      
      <ion-item class="wallet-strip ion-no-padding ctn-gt-space-m"  lines="full">
        <ion-img src="assets/img/food-wallet-icon.svg" slot="start"></ion-img>
        <ion-label>
          <h6 class="f-m">Food Wallet
              <div class="scale-tabable-area" (click)="shoppingCreditsServices.openFW_Guidelines(eventSource)">
                <ion-icon class="icons-property icon-info icon-dark"></ion-icon>
              </div>
            </h6>
          <p>Applicable on edible items only</p>
        </ion-label>
        <ion-label slot="end" class="f-w-s-b text-size-md" [ngClass]="{ 'txt-red': masterDataService.masterData?.walletInfo?.foodCredits <=0 }">{{ masterDataService.masterData?.walletInfo?.foodCredits | currency: 'INR' }}</ion-label>
      </ion-item>

      <ion-item class="wallet-strip ion-no-padding ctn-gt-space-m"  lines="full">
        <ion-img src="assets/img/wallet-cashback.svg" slot="start"></ion-img>
        <ion-label>
          <h6 class="f-m">Cashback Wallet
            <div class="scale-tabable-area" (click)="shoppingCreditsServices.openCashbackWalletGuidelines(eventSource)">
              <ion-icon class="icons-property icon-info icon-dark"></ion-icon>
            </div>
          </h6>
          <p class="text-ellipsis-two-line">{{dataService.firebaseMasterData?.MbUniversalWidgets?.widgets?.MbCashbackWalletGuideline?.CASHBACK_GUIDELINES_SUBHEADING}}</p>
        </ion-label>
        <ion-label slot="end" class="f-w-s-b text-size-md" [ngClass]="{ 'txt-red': masterDataService.masterData?.walletInfo?.cashbackCredits <=0 }">{{ masterDataService.masterData?.walletInfo?.cashbackCredits | currency: 'INR' }}</ion-label>
      </ion-item>
    </div>
  
  
  </div>

