import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { noop } from 'rxjs';

interface ToastOptions {
  msg: string;
  headerText?: string;
  className?: string;
  isShowCloseButton?: boolean;
  toastPosition?: "bottom" | "top" | "middle";
  durationTime?: 2000 | number;
  buttons?: [];
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public toast;

  constructor(private toastController: ToastController) {
  }

  async presentToast(msg, Class?, toastPosition?, durationTime = 2000) {
    if (!toastPosition) {
      toastPosition = 'bottom';
    }
    this.toast = await this.toastController.create({
      message: msg,
      duration: durationTime,
      cssClass: Class,
      position: toastPosition
    });
    this.toast.present();
  }

  async presentCustomToast(toastOptions: ToastOptions){
    this.removeTopToast();
    
    let buttons = []
    if(toastOptions?.isShowCloseButton){
      buttons.push({
        icon: 'assets/icon/close-icon-secondary.svg',
        role: 'cancel',
        cssClass: 'close-icon-btn',
      })
    }
    this.toast = await this.toastController.create({
      header: toastOptions?.headerText,
      message: toastOptions?.msg,
      cssClass: toastOptions?.className,
      position: toastOptions?.toastPosition || 'bottom',
      duration: toastOptions?.durationTime,
      buttons: buttons
    });
    this.toast.present();
  }

  async removeTopToast() {
    await this.toastController.dismiss().catch(noop);
  }

  showInvisibleToast() {
    // HACK: ion-toast is lazy loaded, due to which addtobasket takes time
    // Can be removed once #17450 is resolved: https://github.com/ionic-team/ionic/issues/17450
    this.toastController.create({ animated: false }).then(t => { t.present(); t.dismiss(); });
  }
}
