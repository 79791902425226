<div class="empty-basket padding-horizontal-medium  padding-top-extra-medium">
    <div class="top-btn-bar">
        <ion-button fill="solid" class="no-ripple-effect" size="small"></ion-button>
    </div>
    <h3 class="text-size-xs-lg f-w-b ion-margin-bottom color-dark-300 d-flex ion-align-items-center">
        <ion-icon class="icon-close icons-property icon-base recycle-delivery-bags cross-icon margin-right-base" (click)="removeBasket(false)" color="dark"></ion-icon>
        Recycle Request Cancellation
    </h3>
    <p class="text-size-lg f-w-n color-dark-300  ion-text-start">We cannot process your request for <br> recycling packing material, without a scheduled order. </p>
    <p class="text-size-lg f-w-n color-dark-300 ion-text-start">Do you still wish to continue with item <br> removal?</p>
    <div class="recycle-request-btn d-flex margin-top-medium ion-align-items-center ion-justify-content-between margin-bottom-extra-big-large">
        <ion-button (click)="removeBasket(true)" class="margin-right-small" expand="full" mode="md" fill="clear" color="primary" size="large">Remove
        </ion-button>
        <ion-button type="submit" fill="solid" (click)="removeBasket(false)" size="large" expand="full" mode="md" class="margin-left-small" shape="round">
            Cancel</ion-button>
    </div>
</div>