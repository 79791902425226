import { ProductOffer } from './product-offer';
import { ProductOfferType } from '../enums/product-offer.enums';

export class ProductOffers {
    __typename = 'ProductOffers';
    viewType: number;
    list: ProductOffer[];

    setProductOffersFromApi(productApi) {
        if (!productApi.offers) {
            return null;
        }
        this.viewType = ProductOfferType.None;
        if (productApi.offer_view_type === 'disclosed') {
            this.viewType = ProductOfferType.Disclosed;
        } else {
            this.viewType = ProductOfferType.Undisclosed;
        }

        const list = [];
        /* productApi.offers.forEach(offer => {
            const offerObj = new ProductOffer();
            const offerParsed = offerObj.setProductOfferFromApi(productApi, offer);
            if (offerParsed) {
                list.push(offerParsed);
            }
        }); */
        this.list = list;

        return this;
    }
}
