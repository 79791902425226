//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
import { BannerModule } from '@Widgets/blocks/banner/banner.module';
//#endregion Module Imports

//#region Component Imports
import { AutoPayComponent } from '@Features/payment/auto-pay/auto-pay.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BannerModule
  ],
  exports: [AutoPayComponent],
  declarations: [AutoPayComponent],
})
export class AutoPayModule extends DefaultModule { }