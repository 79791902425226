//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';

//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
import { CounterType } from '@Globals/constants/listing/enums';
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Other Imports

//#endregion Imports

/**
 * Examples
 * Recurring vertical with zero and label
 * <app-counter [verticalOrientation]="true" label="Sun" [(counter)]="count" [showZero]="true"></app-counter>
 * PLP with add
 * <app-counter [(counter)]="count"></app-counter>
 * Plp with request
 *  <app-counter (requestProduct)="productService.requestProduct(product)"></app-counter>
 */
@Component({
  selector: 'mb-product-counter',
  templateUrl: './product-counter.component.html',
  styleUrls: ['./product-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCounterComponent extends DefaultComponent {
  @Input() isPlusOneDayDelivery = false;
  @Input() verticalOrientation = false;
  @Input() label = null;
  @Input() counter = 0;
  @Input() productId = 0;
  @Input() maxValue: number;
  @Input() showZero = false;
  @Input() minCountOne;
  @Input() componentType = CounterType.Normal;
  @Output() counterChange = new EventEmitter();
  counterType = CounterType;
  public counterDataToShow: number;
  public checkForSegmentOffer = false;
  subscription:Subscription;
  @Input() disableCounterForOfferProducts = false;

  constructor(
    public masterDataService: MasterDataService,
    public dataService: DataService, 
    private _cd: ChangeDetectorRef) { 
      super();
  }

  mgOnInit() {
    if (!this.counter) {
      this.counter = 0;
      this.counterDataToShow = 0;
    } else {
      this.counterDataToShow = this.counter;
    }
    this.subscription = this.dataService.runChangeDetForA2B.subscribe(res => {
      this._cd.markForCheck();
    });
  }

  mbOnChanges() {
    if (this.counter !== undefined) {
      this.counterDataToShow = this.counter;
    } else {
      this.counterDataToShow = this.counter = 0;
    }
  }

  increment() {
    const subject = new Subject();
    subject.pipe(take(1)).subscribe((success: boolean) => {
      success && this.counterDataToShow++;
    });
    (!this.maxValue || this.counter < this.maxValue) &&
      this.counterChange.emit({
        count: 1 + this.counterDataToShow,
        counterSubscriber: subject,
        isAdded: true
      });
  }

  decrement() {
    if(!(this.counterDataToShow === 1 && this.minCountOne)) {
      const subject = new Subject();
      subject.pipe(take(1)).subscribe((success: boolean) => {
        success && this.counterDataToShow--;
      });
      this.counterDataToShow &&
        this.counterChange.emit({
          count: this.counterDataToShow - 1,
          counterSubscriber: subject
        });
    }}

  mbOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
