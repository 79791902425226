import { ProductSubCategoryInfo } from './product-subcategory-info';

export class ProductCategoryInfo {
    __typename = 'ProductCategoryInfo';
    id?: number; // was cid
    name?: string;
    subCategory?: ProductSubCategoryInfo; // was scid
    setCategoryFromApi(productApi) {
        if (!productApi.cid) {
            return null;
        }
        this.id = productApi.cid;
        this.name = productApi.category || '';

        const subCategory = new ProductSubCategoryInfo();
        this.subCategory = subCategory.setSubCategoryFromApi(productApi);
        return this;
    }
}
