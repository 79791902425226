<ng-container *ngIf="faqData">
  <ion-header *ngIf="isModal; else default" class="ion-main-header sticky-header">
    <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium pl-0">
        <ion-buttons class="ion-buttons-arrow-back" id="custom-arrow-back" slot="start">
            <ion-button (click)="closeModal()">
                <ion-icon class="icon-arrow-back icons-property icon-md" color="dark" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-no-padding title-caps">
          {{faqData.title}}
        </ion-title>
    </ion-toolbar>
</ion-header>
<ng-template #default>
  <app-header class="title-caps" [isDrawerNeeded]="false" [isShadowAllowed]="true">{{faqData.title}}</app-header>
</ng-template>
  <ion-content class="faq-content ion-padding ctn-gt-space-p" >
    <div class="holder" *ngFor="let faq of faqData.qnas">
      <h4 class="f-w-n" [innerHTML]= "faq.Q"></h4>
      <p class="f-w-b" [innerHTML] ="faq.A">
      </p>
    </div>
  </ion-content>
  </ng-container>
