<ion-toolbar class="search-bar f-group" >
  <ion-item class="ion-no-padding search-container" lines="none" color="none" required *ngIf="!isGoogleAddressAutoComplete">
    <ion-button class="no-ripple-effect ion-text-uppercase ion-no-padding search-btn" fill="clear" size="small"
      color="primary">
      <ion-icon class="icon-search icon-base icons-property" color="dark"></ion-icon>
    </ion-button>
    <ion-input (keyup)="search($event)" type="search" (ionFocus)="setFocus()" [(ngModel)]="searchData.text" #searchInput
      [ngModelOptions]="{standalone: true}" [placeholder]="searchData?.placeholder" animated="on"></ion-input>
    <ion-button *ngIf="searchData.text.length >= 3" class="no-ripple-effect ion-text-uppercase ion-no-padding search-cross-btn" fill="clear" size="small"
      color="primary" (click)="clearText()">
      <ion-icon class="icon-close icon-base icons-property" color="dark"></ion-icon>
    </ion-button>
  </ion-item>

  <ion-item class="ion-no-padding search-container" lines="none" color="none" required *ngIf="!!isGoogleAddressAutoComplete">
    <ion-button class="no-ripple-effect ion-text-uppercase ion-no-padding search-btn" fill="clear" size="small"
      color="primary">
      <ion-icon class="icon-search icon-base icons-property" color="dark"></ion-icon>
    </ion-button>
    <ion-input [(ngModel)]="searchData.text" (ionInput)="UpdateSearchResults()" (ionClear)="ClearAutocomplete()" #searchInput type="search" (ionFocus)="setFocus()" 
      [placeholder]="searchData?.placeholder" animated="on"></ion-input>
    <ion-button *ngIf="searchData && searchData.text.length > 2" class="no-ripple-effect ion-text-uppercase ion-no-padding search-cross-btn" fill="clear" size="small"
      color="primary" (click)="ClearAutocomplete()">
      <ion-icon class="icon-close icon-base icons-property" color="dark"></ion-icon>
    </ion-button>
  </ion-item>
</ion-toolbar>