<ion-header class="ion-main-header sticky-header low-blance" [ngClass]="{'has-negative-account': payment?.fromAccountDeletionFlow}">
    <ion-toolbar class="modal-bottom-sheet pl-0 modal-handle padding-top-medium toolbar-spacing">
        <div class="d-flex ion-align-items-center">
              <ion-title class="ion-no-padding text-size-lg f-w-b">
                    {{ payment?.fromAccountDeletionFlow ? 'Add Money' : payment && payment.type && payment.type === 'retry' ? 'Payment Failed' : 'Insufficient Wallet Balance' }}
                </ion-title>
            <div>
                <ion-button (click)="closeModal()" color="dark" fill="clear" class="ion-buttons-arrow-back btn-size" id="custom-main-heading" slot="end">
                    <ion-icon  color="dark" class="icon-close icons-property icon-md no-ripple-effect" slot="icon-only"></ion-icon>
                    </ion-button>
            </div>
        </div>
    <!-- This will  be required in future -->
     <!-- <p class="ion-text-wrap ion-margin-top text-size-lg f-w-n color-dark-300" [innerHtml]="errorMsg" *ngIf="errorMsg"></p> -->
    </ion-toolbar>
</ion-header>
<ion-content [ngClass]="{'has-negative-account': payment?.fromAccountDeletionFlow}">

   <ion-item lines="none" class="ion-no-padding ctn-gt-space-m balance-strip-info">
    <img class="brand logo margin-right-extra-medium" slot="start" src="../../../../../assets/img/mb-logo.svg" />
    <ion-label class="logo-content-wrapper">
        <p class="text-size-md color-dark-300 f-w-b d-flex ion-align-items-center">Your Balance {{ (masterDataService.masterData?.balance === 0 ? '0' : masterDataService.masterData?.balance) | currency: 'INR' : 'symbol': '1.2-2' }} 
            <span class="scale-tabable-area" (click)="shoppingCreditsServices.openWalletBreakupPrompt(eventSourceOutSCPopup)"><ion-icon class="icons-property icon-info icon-dark icon-xsm"></ion-icon></span>
        </p>
        <small class="text-size-sm f-w-n d-block" *ngIf="shoppingCreditsServices.topUpRange?.max_amount">You can add upto ₹ {{ shoppingCreditsServices.topUpRange.max_amount }}</small>
    </ion-label> 
   </ion-item>

    <ion-accordion-group [animated]="false" class="ctn-gt-space-m wallet-balance-wrapper">
        <ion-accordion value="first">
          <ion-item slot="header">
            <img class="margin-right-small" src="../../../../../assets/icon/icon-info-secondary.svg" alt="icon-info-secondary">
            <ion-label>Wallet balance is lower than the  {{!payment?.additionalCharges?.length?'item':'order'}} value</ion-label>
          </ion-item>
         
          <div slot="content" class="ion-no-padding">
            <ng-container *ngIf="appliedAdditionalChanges;else defaultAppliedAdditionContent" >
                <ng-container *ngIf="appliedAdditionalChanges.type === additionalChargesType.OTDF">
                    <p class="lh-base text-size-ext-sm f-w-m">(Order Value = Item price + {{appliedAdditionalChanges?.amount}} Delivery Fee )</p>
                    <strong class="note-spaccer">Note: </strong>
                    <ul *ngIf="dataService.isMembershipApplied; else nonMemberGudelines">
                        <li class="text-size-ext-sm f-w-m margin-top-smallest membership-renewal">Delivery fee cannot be charged via food wallet.</li>
                        <li class="text-size-ext-sm f-w-m margin-top-smallest membership-renewal" *ngFor="let item of dataService.firebaseMasterData?.MbUniversalWidgets?.widgets?.MbCashbackWalletGuideline?.MEMBERS?.slice(1,3)">{{item}}</li>
                    </ul>
                    <ng-template #nonMemberGudelines> 
                        <ul>
                            <li class="text-size-ext-sm f-w-m margin-top-smallest membership-renewal">Delivery fee cannot be charged via food wallet.</li>
                            <li class="text-size-ext-sm f-w-m margin-top-smallest membership-renewal" *ngFor="let item of dataService.firebaseMasterData?.MbUniversalWidgets?.widgets?.MbCashbackWalletGuideline?.NON_MEMBERS?.slice(1,3)">{{item}}</li>
                        </ul>
                    </ng-template>
                </ng-container>
                
                <ng-container *ngIf="appliedAdditionalChanges.type === additionalChargesType.MEMBERSHIP">
                    <p  class="lh-base text-size-ext-sm f-w-m">(Order Value = Item price + ₹{{appliedAdditionalChanges?.amount}} Membership Fees)</p>
                    <strong class="note-spaccer">Note: </strong>
                    <ul>
                        <li class="text-size-ext-sm f-w-m margin-top-smallest membership-renewal">Membership renewal is due with this order.</li>
                        <li class="text-size-ext-sm f-w-m margin-top-smallest membership-renewal">Membership cannot be renewed via food wallet.</li>
                        <li class="text-size-ext-sm f-w-m margin-top-smallest membership-renewal">Membership fee will be fully paid using the cashback wallet balance (if available).</li>
                    </ul>
                </ng-container>
                
               <ng-container *ngIf="appliedAdditionalChanges.type === additionalChargesType.INSUFF_NF_CREDITS">
                    <strong class="note-spaccer">Note: </strong>
                    <ul>
                        <li class="lh-base text-size-ext-sm f-w-m margin-top-smallest membership-renewal">Food wallet balance is not applicable on this particular item.</li>
                    </ul>
               </ng-container>
            </ng-container>
            <ng-template #defaultAppliedAdditionContent>
                <ng-container>
                    <strong class="note-spaccer">Note: </strong>
                    <ul *ngIf="dataService.isMembershipApplied; else nonMemberGudelines">
                        <li class="text-size-ext-sm f-w-m margin-top-smallest membership-renewal" *ngFor="let item of dataService.firebaseMasterData?.MbUniversalWidgets?.widgets?.MbCashbackWalletGuideline?.MEMBERS?.slice(1,3)">{{item}}</li>
                    </ul>
                    <ng-template #nonMemberGudelines> 
                        <ul>
                            <li class="text-size-ext-sm f-w-m margin-top-smallest membership-renewal" *ngFor="let item of dataService.firebaseMasterData?.MbUniversalWidgets?.widgets?.MbCashbackWalletGuideline?.NON_MEMBERS?.slice(1,3)">{{item}}</li>
                        </ul>
                    </ng-template>
                </ng-container>
            </ng-template>
          </div>
        </ion-accordion>
      </ion-accordion-group>

    <form class="form" #lowBalanceForm="ngForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="submit()">
        <div class="f-body shopping-cont padding-top-medium">
            <ion-row class="add-topup ctn-gt-space-p">
                <div class="f-group">
                    <ion-label position="stacked">
                        <strong class="f-w-b text-size-small d-block lh-base margin-bottom-small">{{payment?.viaMembership ? payment.amount === lastPaymentAmount ?'Minimum Recharge Amount':'Recharge Amount':'Enter Amount' }}</strong>
                    </ion-label>
                    <div class="shoping-topup-amount">
                        <ion-item class="ion-no-padding">
                            <ion-input class="inr fixed" type="tel" [placeholder]="'eg. ' + shoppingCreditsServices.topUpRange?.min_amount" [ngModel]="lastPaymentAmount | number: '1.0'" name="lastPaymentAmount" ngControl="lastPaymentAmount" (ionInput)="updateLastPaymentAmount($event)" [maxlength]="shoppingCreditsServices.topUpRange?.maxLength" required>
                            </ion-input>
                        </ion-item>
                    </div>
                    <p class="min-amount" *ngIf="membershipTextVisiblity">
                       <ng-container *ngIf="payment?.membsershipStatus === membershipStatus.PAID_MEMBERSHIP;else expiredMembershipText" > Please add a minimum of <span><span class="inr">{{payment.amount}}</span></span> to purchase the plan or continue to shop till your current membership expires. </ng-container>
                       <ng-template #expiredMembershipText>
                        Please add a minimum of ₹{{payment.amount}} to purchase the  plan or continue only with the wallet recharge.
                       </ng-template>
                    </p>
                    <ion-text color="danger">
                        <p ion-text color="danger" *ngIf="showError.min_amount_error">
                            {{ LOW_BALANCE_ERROR_MESSAGES.SHOPPING_CREDIT_AMOUNT_MIN }} {{ payment?.fromAccountDeletionFlow ? this.payment.balance : shoppingCreditsServices.topUpRange.min_amount }}
                        </p>
                        <p ion-text color="danger" *ngIf="showError.max_amount_error">
                            {{ LOW_BALANCE_ERROR_MESSAGES.SHOPPING_CREDIT_AMOUNT_MAX }} {{ shoppingCreditsServices.topUpRange.max_amount }}
                        </p>
                    </ion-text>
                </div>
            </ion-row>
            <ion-row class="ctn-gt-space-p padding-top-medium credit-btn">
                <ion-button class="ion-no-padding" *ngFor="let amount of shoppingCreditsServices.topUpRange?.amounts" [ngClass]="{ active: amount.value == lastPaymentAmount }" (click)="paymentOption.amount = lastPaymentAmount = amount.value; resetOfferSelection()">
                    <span class="inr"></span>{{ amount.value }}
                </ion-button>
            </ion-row>

            <div class="payment-list ctn-gt-space-p margin-top-large row-medium" *ngIf="masterDataService.paymentOffers.length">
                <mbs-payment-offer-listing [isSort]="false" [addEventListener] = "true" [source]="source"></mbs-payment-offer-listing>
            </div>

            <div class="payment-method {masterDataService.isJioPayEnabled ? margin-top-large : margin-vertical-large}" >
                <ion-list class="ion-no-padding" lines="none" *ngIf="!masterDataService.isJioPayEnabled">
                    <ion-list-header class="sub-heading f-w-b">Choose Payment Method</ion-list-header>
                    <ion-item class="ion-no-padding" mode="md">
                        <div class="h-scroll scroll-x option" >
                            <ng-container *ngFor="let option of masterDataService.paymentOptions">
                                <ion-card [ngClass]="{
                  active: paymentOption && paymentOption.value === option.value
              }" class="ion-text-capitalize f-w-n" (click)="paymentOption.value = option.value; paymentOption.juspay = option.juspay;paymentOption.token = option.token;paymentOption.walletId = option.walletId;paymentOption.linked = option.linked" *ngIf="(option.isAllowed && option.visible && !payment?.additionalCharges?.length && !payment?.hideSodexo) || (option.isAllowed && option.visible 
              && ((payment?.additionalCharges?.length || payment?.viaMembership || payment.hideSodexo)&& option.value !== pgWiseJuspayPayload.SODEXO.pgName))"
                                    class="ion-card-image ion-no-margin ion-text-center">
                                   <div class="active-outline">
                                    <img alt="payment option" [src]="pgWiseJuspayPayload[option.value]?.icon" />
                                   </div>
                                    <ion-card-title>
                                        {{ option.name }}
                                    </ion-card-title>
                                </ion-card>
                            </ng-container>
                        </div>
                    </ion-item>
                </ion-list>
            </div>
        </div>
        <ion-button type="submit" size="large" shape="round" [disabled]="!lowBalanceForm.valid" expand="block" class="mw-large p-button ion-padding-horizontal">{{ payment?.fromAccountDeletionFlow ? 'Proceed to Add Money' : payment.type && payment.type === 'retry' ? 'Retry' : 'Recharge Now' }}
        </ion-button>
        <div class="app-payment-offer-wrap" *ngIf="!showDefaultPayment && dataService.firebaseMasterData.paymentOffers">
            <mbs-payment-offer [componentType]="paymentOfferType.LowBalancePopUp" [paymentOffers]="dataService.firebaseMasterData.paymentOffers">
            </mbs-payment-offer>
        </div>
    </form>

    <mbf-auto-pay [lowBalancePopUp]="true" [getStatusOfAutoDebit]="getStatusOfAutoDebit" [creditExhausted]="payment.credit_exhausted">
    </mbf-auto-pay>

</ion-content>