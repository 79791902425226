//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-coachmark-popover',
  templateUrl: './coachmark-popover.component.html',
  styleUrls: ['./coachmark-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoachmarkPopoverComponent {

  constructor() { }

}
