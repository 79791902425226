//#region Imports

//#region Angular Imports
import { Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { MODAL_IDS } from '@Globals/constants/listing/constants';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-faq-modal',
  templateUrl: './faq-modal.component.html',
  styleUrls: ['./faq-modal.component.scss'],
})
export class FaqModalComponent extends DefaultComponent {

  @Input() faqData;
  @Input() isModal = false;
  constructor(private modalCtrl: ModalController) {
    super();
  }

  mbOnInit() { }

  closeModal() {
    this.modalCtrl.dismiss('', '', MODAL_IDS.FAQ_DATA);
  }
}
