export class ProductRecurrence {
    __typename = 'ProductRecurrence';
    type: string; // was recurring
    text: string; // was recText
    listingText?: string; // was recTextPLP

    setRecurrenceFromApi(productApi) {
        this.type = productApi.recurring || '';
        this.text = productApi.recText || '';
        this.listingText = productApi.recTextPLP || '';

        return this;
    }
}
