//#region Imports

//#region Angular Imports
import { Component, Input, ChangeDetectorRef } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthService } from '@Globals/providers/auth.service';
import { ProductListManagerService } from '@Features/product-list/providers/product-list-manager.service';
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
//#endregion Providers Imports

//#region Other Imports
import { CounterType, DeliveryType, ProductListType, ImageOnDemandType } from '@Globals/constants/listing/enums';
import { LISTING_ROUTES } from '@Globals/constants/listing/routes';
import { SimilarProducts } from '@Globals/interfaces/listing/interfaces';

import { Utilities } from '@Globals/classes/utilities';

import { environment } from '@Env/environment';
import { EVENT_NAMES, EVENT_SOURCES } from '@Globals/constants/listing/constants';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mb-pl-single-brand',
  templateUrl: './single-brand.component.html',
  styleUrls: [ './single-brand.component.scss'],
  providers: [ ProductListManagerService ]
})
export class SingleBrandComponent {
  @Input('brandData') metaData: any;
  @Input() eventData: any;
  @Input() showViewAll = true;
  @Input() showNew = true;
  @Input() eventSource?: string;
  @Input() itemType: string = '';

  topNotchCounterType = CounterType.TopNotch;
  showCounterForProductId = -1;
  PLPTypeImageOnDemand = ImageOnDemandType.PLP;
  deliveryTypeFrozen = DeliveryType.frozen;
  products: Array<any>;
  componentType = ProductListType.Horizontal_Listing_With_Headers;
  appliedFilter;
  currentHoursAndMinutes = Utilities.getCurrentHoursAndMinutes();
  bannerFilePath = environment.bannerPath;
  subscription:Subscription;

  recommendedProductList: SimilarProducts = {
    product: {
        id: 0
    },
    title: 'Suggestions for you'
  };

  constructor(
    public authService: AuthService,
    public plmService: ProductListManagerService,
    private navCtrl: NavController,
    public dataService: DataService,
    private cdRef: ChangeDetectorRef,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.metaData && (this.products = ((this.metaData.brands && this.metaData.brands[0].products) || this.metaData.products));
    this.plmService.setupTomorrowBasket();
    this.subscription = this.plmService.getRunChangeDetForA2B().subscribe(res => {
      this.cdRef.markForCheck();
    });
  }

  hideCounterButton() {
    this.showCounterForProductId = -1;
  }

  triggerCounterChange(counterDataForImpluseListing, product, productIndex) {
    this.plmService.counterChange(this, counterDataForImpluseListing, product, productIndex);
  }

  navigateToListing() {
    this.plmService.setBranchLaunchData(this.metaData);
    if (this.metaData.brands && this.metaData.brands[0].logo) {
      this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.BRAND_PLP + 0 + '/' + EVENT_SOURCES.HOME);
    } else if (this.metaData.isSponsored) {
      this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.SPONSORED_LISTING + this.metaData.listId);
    } else if (this.metaData.collection) {
      this.dataService.collectionsConfigData.leafRequest = this.metaData.leafRequest;
      this.dataService.collectionsConfigData.collection = this.metaData.collectionData;
      this.eventData && 'one_pager_id' in this.eventData && (this.dataService.onePagerCollectionRedirect[this.metaData.collectionData.id] = this.eventData);
      this.navCtrl.navigateForward(this.metaData.collection, { animated: false });
    } else {
      this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.PRODUCT_LAUNCH_PLP + 0 + '/' + EVENT_SOURCES.HOME);
    }
  }

  async openProductsVariantModal(product, selectedProductIndex) {
    this.dataService.productVariantInfo.isVariantAddedToCart = false;
    this.dataService.productVariantInfo.latestVariantIndexIntoCart = null;
    const variantProduct = product?._newVariantProducts;
    
    if (variantProduct && variantProduct.length) {
      const modal = await this.plmService.openProductVariantModel(variantProduct, product, ProductListType.Product_Variant_List, this.eventSource, this.eventData);
      await modal.present();
      let eventAgrs = { source: this.eventSource, product_id: product.id, variant_id: product.variantId };
      this.eventsService.trackEvents(EVENT_NAMES.PRODUCT_VARIANT_CLICKED, { ...eventAgrs });

      modal.onDidDismiss().then(res => {
        if (this.dataService.productVariantInfo.isVariantAddedToCart) {
          if(this.metaData.products){
            this.metaData.products[selectedProductIndex] = variantProduct[this.dataService.productVariantInfo.latestVariantIndexIntoCart];
            this.metaData.products[selectedProductIndex]._newVariantProducts = variantProduct;
          } else if (this.metaData.brands && this.metaData.brands[0]?.products) {
            this.metaData.brands[0].products[selectedProductIndex] = variantProduct[this.dataService.productVariantInfo.latestVariantIndexIntoCart];
            this.metaData.brands[0].products[selectedProductIndex]._newVariantProducts = variantProduct;
          }
          this.cdRef.detectChanges();
        }
      });
    }
  }

  handleProductClick(product, productIndex) {
    return (this.showCounterForProductId === (product.product_id || product.id)  && this.authService.userData.tomorrowBasket[product.product_id || product.id]) ? false : this.plmService.productDetailsModal(
      product.product_id || product.id,
      productIndex,
      this.componentType,
      this.eventSource,
      this.eventData,
      this.itemType,
      product,
      this.appliedFilter,
      0
    );
  }

  successHandlerForRecommendedProducts = (productList, product) => {
    this.recommendedProductList.data = productList;
    this.recommendedProductList.product = product;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
