import { Utilities } from '../../../../utilities';
import { BillDetails } from './bill-details';
import { BasketProduct } from './basket-product';

export class Basket {
    __typename = 'Basket';
    id: number;
    date: string;
    quantity: number;
    isOpted: boolean;
    isCovidFee: boolean;
    isOtdf: boolean;
    showSsph: boolean;
    mbPassSaving: string;
    saving: string;
    taxes: string;
    total: string;
    billDetails: BillDetails;
    products: BasketProduct[];

    setBasketFromApi(basketApi) {
        this.id = basketApi.basket_id;
        this.date = basketApi.date;
        this.quantity = basketApi.quantity;
        this.isOpted = basketApi.is_opted;
        this.isCovidFee = basketApi.is_covid_fee;
        this.isOtdf = basketApi.is_otdf;
        this.showSsph = basketApi.show_ssph;
        this.mbPassSaving = Utilities.number.thousandSeparator(+basketApi.mb_pass_saving);
        this.saving = Utilities.number.thousandSeparator(+basketApi.saving);
        this.taxes = Utilities.number.thousandSeparator(+basketApi.taxes);
        this.total = Utilities.number.thousandSeparator(+basketApi.total);

        const billDetails = new BillDetails();
        this.billDetails = billDetails.setBillDetailsFromApi(basketApi);

        const basketProducts = [];
        const productsApi = basketApi?.products || [];
        productsApi?.forEach(productApi => {
            try {
                const product = new BasketProduct();
                product.setBasketProductFromProductApi(productApi);

                if(product.order && product.order.quantity > 0) {
                    basketProducts.push(product);
                }
            } catch (e) {
                return true;
            }
            return true;
        });
        this.products = basketProducts;
        return this;
    }
}
