//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { LottieModule } from 'ngx-lottie';
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';

import { ImgOnDemandModule } from '@Main/modules/shared/img-on-demand/img-on-demand.module';
//#endregion Module Imports

//#region Component Imports
import { HorizontalWidgetComponent } from '@Widgets/blocks/collection/horizontal-widget/horizontal-widget.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ImgOnDemandModule,
    LottieModule
  ],
  exports: [HorizontalWidgetComponent],
  declarations: [HorizontalWidgetComponent]
})
export class HorizontalWidgetModule extends DefaultModule { }