//#region Imports

//#region Angular Imports
import { ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//#endregion Angular Imports

//#region Ionic Imports
import { HTTPResponse } from '@ionic-native/http';
import { NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultPage } from '@Lib/defaults/default.page';
import { BottomSheetComponent } from '@Pages/registration/geolocation/components/bottom-sheet/bottom-sheet.component';
import { SelectDeliveryLocationComponent } from '@Pages/registration/geolocation/components/select-delivery-location/select-delivery-location.component';
//#endregion Component Imports

//#region Providers Imports
import { AlertService } from '@Globals/providers/alert.service';
import { AuthService } from '@Globals/providers/auth.service';
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
import { FirebaseService } from '@Globals/providers/firebase.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { LocationService } from '@Globals/providers/location.service';
import { SignupService } from '@Globals/providers/signup.service';
import { DeepLinkService } from '@Globals/providers/deep-link.service';
//#endregion Providers Imports

//#region Other Imports
import { SheetState } from '@Globals/constants/listing/enums';
import {
  ADDRESS_VALIDATION_TYPE,
  EMPTYCART_ALERT,
  MY_LOCATION,
  PROMOCODE_TYPES,
  SIGNUP_ERRORS,
} from '@Globals/constants/registration/constants';
import {
  ALERT_BUTTON_TEXT,
  ANF_DETAIL_TYPE,
  GENERATE_CONTENT_FOR_ALERT_LAYER,
  STORAGE_KEYS,
  USER_DETAIL_DEFAULT_VALUES,
  PAGE_TAGS,
} from '@Globals/constants/listing/constants';
import { EVENT_NAMES, EVENT_SOURCES } from '@Globals/constants/registration/constants';
import {
  AddressSelectionType,
  LocationComponentType,
  SocietyType
} from '@Globals/constants/registration/enums';
import { CORE_ROUTES, LISTING_ROUTES } from '@Globals/constants/listing/routes';
import { REGISTRATION_ROUTES } from '@Globals/constants/registration/routes';
import {
  AddressData,
  AddressUpdate,
  CityInfo,
  LocalityAddress,
  SignupComplete,
  SocietyPayload,
  SocietySelection,
  UserSignupDetails
} from '@Globals/interfaces/registration/interfaces';

import { Utilities } from '@Globals/classes/utilities';

import { environment } from '@Env/environment';
import { GeolocationService } from '../../../globals/providers/geolocation.service';
import { ModalService } from '@Globals/providers/modal.service';
//#endregion Other Imports

//#endregion Imports
declare var google: any;
declare var window:any;

@Component({
  selector: 'mbp-geolocation',
  templateUrl: './geolocation.page.html',
  styleUrls: ['./geolocation.page.scss'],
})
export class GeolocationPage extends DefaultPage {

  addressSelectionType = AddressSelectionType;
  viewType: number;
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  map: any;
  markers = [];
  dockedHeight: number;
  sheetState = SheetState.Docked;
  societiesList = [];
  promoCodeType: string;
  validPromoCode: boolean;
  promo_code!: string;
  showCity: boolean = false;
  location: string = MY_LOCATION;
  locationConstant = MY_LOCATION;
  userSignupDetails: UserSignupDetails = {
    towerDetails: {},
    localAddressDetails: {}
  };
  isAddNewAddress: boolean = false;
  addLocalityDetails: LocalityAddress = {};
  cityInfo: any = {};
  subscriptions: Subscription[] = [];
  utility = Utilities;
  societyType = SocietyType;
  @ViewChild(SelectDeliveryLocationComponent, { static: false }) societyListComponent: SelectDeliveryLocationComponent;
  @ViewChild(BottomSheetComponent, { static: false }) bottomSheetComponent: BottomSheetComponent;
  referStatus:number = null;
  constructor(
    private route: ActivatedRoute,
    private navCtrl: NavController,
    public signupService: SignupService,
    public authService: AuthService,
    private eventsService: EventsService,
    public dataService: DataService,
    private masterDataService: MasterDataService,
    private firebaseService: FirebaseService,
    private alertService: AlertService,
    private locationService: LocationService,
    public geolocationService:GeolocationService,
    private deeplinkService: DeepLinkService,
    public modalService: ModalService,
    private zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ionViewWillEnter() {
    this.viewType = +this.route.snapshot.params.addressSelectionType;
    if(this.viewType === AddressSelectionType.SelectTower){
      this.bottomSheetComponent.sheetState = SheetState.Bottom;
    }
    this.geolocationService.userSignupDetails ={};
    this.geolocationService.isAddNewAddress = false;
    this.geolocationService.validPromoCode = null;
    this.geolocationService.promo_code = null;
    this.geolocationService.promoCodeType = null;
    this.geolocationService.referStatus = null;
    this.geolocationService.cityInfo = {};
    if (this.viewType === AddressSelectionType.SelectSociety) {
      this.eventsService.trackEvents(PAGE_TAGS.SEARCH_SOCIETY, { src: this.authService.source });
      if (!this.signupService.latLong && (Utilities.isApp() || (!Utilities.isApp() && this.authService.isLoggedIn()))) {
        this.locationService.enableLocation(LocationComponentType.DeliveryLocationPage, false);
      }
    }
    this.initializeMap();
  }

  closeCitySelection(cityData: CityInfo) {
    this.showCity = false;
    if (cityData) {
      this.geolocationService.cityInfo = {
        city_id: cityData.id,
        name: cityData.name
      }
    }
  }

  sheetStates(event) {
    if (event) {
      this.bottomSheetComponent.sheetState = SheetState.Top;
    }
  }

  sheetBackToNormalStates(event) {
    if (event) {
      this.bottomSheetComponent.sheetState = SheetState.Docked;
      this.zone.run((_)=>{
        this.setUpBottomSheet(true);
        this.cdr.detectChanges();
      })
    }
  }

  setUpBottomSheet(isNoNserviceFlow:boolean = false) {
    this.viewType = +this.route.snapshot.params.addressSelectionType;
    if((isNoNserviceFlow || this.dataService.isUnserviceableFlow ) && this.viewType === AddressSelectionType.ConfirmSociety) {
      document.getElementsByTagName('body')[0].classList.add('select-no-serviceFlow-confirm');
      document.getElementsByTagName('ion-bottom-sheet')[0].classList.add('change_height-confirm');
      document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow');
      document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height');
      document.getElementsByTagName('body')[0].classList.remove('select-delivery-location');
      document.getElementsByTagName('body')[0].classList.remove('confirm-society');
      document.getElementsByTagName('body')[0].classList.remove('select-tower');
      document.getElementsByTagName('body')[0].classList.remove('add-address');
      document.getElementsByTagName('body')[0].classList.remove('address-success');
      return 300;
    }
    if(isNoNserviceFlow || this.dataService.isUnserviceableFlow) {
      document.getElementsByTagName('body')[0].classList.add('select-no-serviceFlow');
      document.getElementsByTagName('ion-bottom-sheet')[0].classList.add('change_height');
      document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow-confirm');
      document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height-confirm');
      document.getElementsByTagName('body')[0].classList.remove('select-delivery-location');
      document.getElementsByTagName('body')[0].classList.remove('confirm-society');
      document.getElementsByTagName('body')[0].classList.remove('select-tower');
      document.getElementsByTagName('body')[0].classList.remove('add-address');
      document.getElementsByTagName('body')[0].classList.remove('address-success');
      return 250;
    }
    switch (this.viewType) {
      case AddressSelectionType.SelectSociety:
        document.getElementsByTagName('body')[0].classList.add('select-delivery-location');
        document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow');
        document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow-confirm');
        document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height-confirm');
        document.getElementsByTagName('body')[0].classList.remove('confirm-society');
        document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height');
        document.getElementsByTagName('body')[0].classList.remove('select-tower');
        document.getElementsByTagName('body')[0].classList.remove('add-address');
        document.getElementsByTagName('body')[0].classList.remove('address-success');
        return 400;
      case AddressSelectionType.ConfirmSociety:
        document.getElementsByTagName('body')[0].classList.add('confirm-society');
        document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow');
        document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow-confirm');
        document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height-confirm');
        document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height');
        document.getElementsByTagName('body')[0].classList.remove('select-delivery-location');
        document.getElementsByTagName('body')[0].classList.remove('select-tower');
        document.getElementsByTagName('body')[0].classList.remove('add-address');
        document.getElementsByTagName('body')[0].classList.remove('address-success');
        return 330;
      case AddressSelectionType.SelectTower:
        document.getElementsByTagName('body')[0].classList.add('select-tower');
        document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow');
        document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height');
        document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow-confirm');
        document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height-confirm');
        document.getElementsByTagName('body')[0].classList.remove('confirm-society');
        document.getElementsByTagName('body')[0].classList.remove('select-delivery-location');
        document.getElementsByTagName('body')[0].classList.remove('add-address');
        document.getElementsByTagName('body')[0].classList.remove('address-success');
        return (this.authService.userData?.societyObj?.societyType === this.societyType.Locality ? 550 : 480);
      case AddressSelectionType.AddAddress:
        document.getElementsByTagName('body')[0].classList.add('add-address');
        document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow');
        document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height');
        document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow-confirm');
        document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height-confirm');
        document.getElementsByTagName('body')[0].classList.remove('confirm-society');
        document.getElementsByTagName('body')[0].classList.remove('select-tower');
        document.getElementsByTagName('body')[0].classList.remove('select-delivery-location');
        document.getElementsByTagName('body')[0].classList.remove('address-success');
        return 550;
      case AddressSelectionType.AddressAddSuccess:
        document.getElementsByTagName('body')[0].classList.add('address-success');
        document.getElementsByTagName('body')[0].classList.remove('confirm-society');
        document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow');
        document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow-confirm');
        document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height-confirm');
        document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height');
        document.getElementsByTagName('body')[0].classList.remove('select-tower');
        document.getElementsByTagName('body')[0].classList.remove('add-address');
        document.getElementsByTagName('body')[0].classList.remove('select-delivery-location');
        return 480;
    }
  }

  setDisable() {
    this.viewType = +this.route.snapshot.params.addressSelectionType;
    if (this.viewType === AddressSelectionType.ConfirmSociety) {
      return true;
    }
    return false;
  }

  initializeMap() {
    let coordinates: any = {};
    if (this.dataService.draggenedCoordinate) {
      coordinates.lat = this.dataService.draggenedCoordinate.latitude;
      coordinates.lng = this.dataService.draggenedCoordinate.longitude;
    } else if (this.signupService.latLong) {
      coordinates.lat = this.signupService.latLong.latitude;
      coordinates.lng = this.signupService.latLong.longitude;
    } else {
      coordinates.lat = 28.4595;
      coordinates.lng = 77.0266;
    }
    let mapOptions = {
      center: coordinates,
      zoom: 18,
      mapTypeId: google && google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      disableDefaultUI: true,
    };
    google && (this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions));
    this.addMapListener();
    if (this.viewType === AddressSelectionType.SelectSociety) {
      const updateCoordinatesSubscription = this.signupService.updateCoordinates.subscribe(res => {
        if (res) {
          this.recenterWithoutSocietyUpdate();
          this.setLocationMarker();
          this.getNearSocieties({ latitude: this.signupService.latLong.latitude, longitude: this.signupService.latLong.longitude });
        } else {
          Object.keys(this.dataService.ipCoordinates).length &&
            this.getNearSocieties({ latitude: this.dataService.ipCoordinates.latitude, longitude: this.dataService.ipCoordinates.longitude });
        }
      });
      this.subscriptions.push(updateCoordinatesSubscription);
      if (this.signupService.latLong || this.dataService.draggenedCoordinate) {
        this.updateNearBySocieties();
        this.signupService.latLong && this.setLocationMarker();
      } else if (Object.keys(this.dataService.ipCoordinates).length) {
        this.getNearSocieties({ latitude: this.dataService.ipCoordinates.latitude, longitude: this.dataService.ipCoordinates.longitude, hideLoader: true })
      }
    }
    if (this.viewType === AddressSelectionType.ConfirmSociety || this.viewType === AddressSelectionType.SelectTower) {
      if (Object.keys(this.authService.userData.societyObj).length) {
        this.location = this.authService.userData.societyObj.name;
        if (this.signupService.anfDetails.z) {
          this.setCenter(this.signupService.anfDetails.z.lat, this.signupService.anfDetails.z.lng);
        } else if (this.signupService.latLong) {
          const radialDistance = Utilities.calculateDistanceBetweenLatLng(
            this.signupService.latLong.latitude, this.signupService.latLong.longitude, this.authService.userData.societyObj.latitude, this.authService.userData.societyObj.longitude
          );
          if (this.masterDataService.masterData && radialDistance <= this.masterDataService.staticMasterData?.customerSocietyPinDistance) {
            this.setCenter(this.signupService.latLong.latitude, this.signupService.latLong.longitude);
          } else {
            this.setCenter(this.signupService.anfDetails.y.lat, this.signupService.anfDetails.y.lng);
          }
        } else {
          this.setCenter(this.signupService.anfDetails.y.lat, this.signupService.anfDetails.y.lng);
        }
      }
    }
    if(this.viewType === AddressSelectionType.ConfirmSociety && this.authService.userData?.societyObj?.societyType === this.societyType.Locality && this.signupService.isShowPinAlert){
      this.signupService.isShowPinAlert = false;
      setTimeout(()=>{
        this.modalService.geofenceAlert();
      },600);
    }
  }

  showCityModal() {
    this.showCity = true;
  }

  getNearSocieties(dataToSend: SocietyPayload) {
    this.authService.isLoggedIn() && (dataToSend = { active: 1, ...dataToSend });
    this.societyListComponent && this.societyListComponent.searchData.text.length > 0 && (dataToSend = { search_text: this.societyListComponent.searchData.text, ...dataToSend });
    dataToSend.all_type = true;
    const getNearBySocietiesSubscription = this.signupService
      .getNearBySocieties(dataToSend)
      .subscribe((success: any) => {
        this.societiesList = (success.data && success.data.data) || [];
        this.signupService.nearBySocietySubject.next(this.societiesList);
        this.signupService.nearBySocietiesTotal = this.societiesList.length;
        if (this.societiesList.length === 0) {
          this.authService.userData.cityObj = {
            name: null,
            id: null
          }
          this.eventsService.trackEvents(
            EVENT_NAMES.SIGNUP_CITY_NOT_SERVICEABLE,
            {
              Source: EVENT_SOURCES.SIGNUP,
              gps: (!!(dataToSend.latitude && dataToSend.longitude)).toString()
            },
            true
          );

        }
      });
    this.subscriptions.push(getNearBySocietiesSubscription);
  }

  enableLocation() {
    this.locationService.enableLocation(LocationComponentType.DeliveryLocationPage, false);
  }

  setLocationMarker() {
    this.addMarker();
    this.signupService.anfDetails.x = {
      lat: this.signupService.latLong.latitude,
      lng: this.signupService.latLong.longitude,
      type: ANF_DETAIL_TYPE.GPS
    };
    this.eventsService.trackEvents(EVENT_NAMES.MARKER_SET, {
      lat: this.signupService.latLong.latitude,
      lng: this.signupService.latLong.longitude
    });

  }

  addMarker() {
    if(!google) {
      return;
    }
    var latlong = new google.maps.LatLng(this.signupService.latLong.latitude, this.signupService.latLong.longitude);
    var marker = new google.maps.Marker({
      draggable: false,
      icon: 'assets/img/current-location.png',
      map: this.map,
      animation: google?.maps.Animation.DROP,
      position: latlong,
    });
    this.markers[0] = marker;
  }

  addMapListener() {
    google && this.map && google.maps.event.addListener(this.map, 'dragend', () => {
        const position = this.map.getCenter();
        if (this.viewType === AddressSelectionType.ConfirmSociety || this.viewType === AddressSelectionType.SelectTower) {
          this.signupService.anfDetails.z = {
            lat: position.lat(),
            lng: position.lng(),
            type: ANF_DETAIL_TYPE.PIN
          };
          this.eventsService.trackEvents(EVENT_NAMES.MARKER_DRAGGED, {
            lat: this.signupService.anfDetails.y.lat,
            lng: this.signupService.anfDetails.y.lng,
            newLat: this.signupService.anfDetails.z.lat,
            newLng: this.signupService.anfDetails.z.lng
          });
        } else if (this.viewType === AddressSelectionType.SelectSociety) {
          this.dataService.draggenedCoordinate = {
            latitude: position.lat(),
            longitude: position.lng()
          };
        }
        if(this.dataService.isUnserviceableFlow && ( this.viewType === AddressSelectionType.ConfirmSociety || this.viewType === AddressSelectionType.SelectSociety)) {
          this.getAddressFromLatLng(position.lat(), position.lng());
        } else {
          this.updateNearBySocieties();
        }
    });
  }

  updateNearBySocieties() {
    if(this.map) {
      const position = this.map.getCenter();
      this.getNearSocieties({ latitude: position.lat(), longitude: position.lng(), hideLoader: true });
    } else {
      this.getNearSocieties({ latitude: undefined, longitude: undefined, hideLoader: true });
    }
  }

  selectSociety(societySelectedData: SocietySelection) {
    const societySelected = societySelectedData.society;
    this.authService.userData.societyObj = {
      name: societySelected.name,
      id: societySelected.id,
      location: societySelected.location,
      active: !!societySelected.active,
      hubId: societySelected.hub_id,
      latitude: societySelected.latitude,
      longitude: societySelected.longitude,
      societyType: societySelected.type,
      deliveryStartDate: societySelected.delivery_start_date
    };
    this.authService.userData.cityObj = {
      name: societySelected.city || societySelected.cityName,
      id: societySelected.city_id
    };
    this.signupService.anfDetails.y = {
      lat: societySelected.latitude,
      lng: societySelected.longitude,
      type: ANF_DETAIL_TYPE.ADDRESS
    };
    this.signupService.anfDetails.z = null;
    localStorage.setItem(STORAGE_KEYS.CITY, JSON.stringify(this.authService.userData.cityObj));
    const eventArgs = {
      'society Id': societySelected.id,
      'society Location': societySelected.location,
      'society Name': societySelected.name,
      'society active': !!societySelected.active,
      'city Name': this.authService.userData.cityObj.name,
      source: societySelectedData.src,
      isGPS: !!this.signupService.latLong
    };
    this.eventsService.trackEvents(
      EVENT_NAMES.SIGNUP_SOCIETY_SELECTED,
      eventArgs
    );
    this.signupService.towers = null;
    if(SocietyType.Locality === societySelected.type){
      this.signupService.isShowPinAlert = true;
    }
    this.navCtrl.navigateForward(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.GEOLOCATION + '/' + AddressSelectionType.ConfirmSociety);

  }

  confirmSociety() {
    if(this.dataService.isUnserviceableFlow) {
      this.navCtrl.navigateForward(
        '/' + CORE_ROUTES.REGISTRATION + '/' + REGISTRATION_ROUTES.ADD_ADDRESS
      );
    } else {
      this.eventsService.trackEvents(EVENT_NAMES.SOCIETY_CONFIRMED, this.authService.userData.societyObj);
      this.navCtrl.navigateForward(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.GEOLOCATION + '/' + AddressSelectionType.SelectTower);
    }
  }

  addAddress() {
    this.eventsService.trackEvents(EVENT_NAMES.ADD_ADDRESS, {});
    this.navCtrl.navigateForward(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.GEOLOCATION + '/' + AddressSelectionType.AddAddress);
  }

  recenterWithoutSocietyUpdate() {
    this.setCenter(this.signupService.latLong.latitude, this.signupService.latLong.longitude);
    if((this.viewType === AddressSelectionType.ConfirmSociety && this.authService.userData?.societyObj?.societyType === this.societyType.Locality) || this.signupService.anfDetails.z){
      this.signupService.anfDetails.z = {
        lat: this.signupService.latLong.latitude,
        lng: this.signupService.latLong.longitude,
        type: ANF_DETAIL_TYPE.PIN
      };
    }
    this.addMarker();
  }

  recenter() {
    this.recenterWithoutSocietyUpdate();
    if (this.viewType === AddressSelectionType.SelectSociety) {
      this.updateNearBySocieties();
    }
  }

  setCenter(lat: number, lng: number) {
    google && this.map && this.map.setCenter(new google.maps.LatLng(lat, lng));
  }

  addNewAddress(addressData: AddressData) {
    this.authService.userData.cityObj = {
      name: environment.defaultCity.name,
      id: environment.defaultCity.id
    };
    localStorage.setItem(STORAGE_KEYS.CITY, JSON.stringify(this.authService.userData.cityObj));
    this.signupService.anfDetails.y = {
      lat: 0,
      lng: 0,
      type: ANF_DETAIL_TYPE.ADDRESS
    };
    this.isAddNewAddress = true;
    this.geolocationService.isAddNewAddress =  true;
    this.geolocationService.registerUser({
        society_id: 0,
        society_name: addressData.society_name,
        tower: addressData.tower,
        tower_id: 0,
        unit: addressData.unit
    });
  }

  setTower(towerData: UserSignupDetails) {
    this.userSignupDetails = towerData;
    this.geolocationService.userSignupDetails = towerData;
    this.geolocationService.registerUser();
  }

  addLocalityAddress(localAddressDetails: UserSignupDetails) {
    this.userSignupDetails = localAddressDetails;
    this.geolocationService.userSignupDetails = localAddressDetails;
    this.geolocationService.registerUser(null,false);
  }

  changeAddress(towerData: UserSignupDetails) {
    this.geolocationService.userSignupDetails = towerData;
    this.geolocationService.changeAddress(towerData);
  }

  getAddressFromLatLng(lat:number, lng: number) {
    var latlng = new google.maps.LatLng(lat, lng);
    var geocoder = geocoder = new google.maps.Geocoder();
    let that = this;
    geocoder.geocode({ 'latLng': latlng }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            if (results[1]) {
                const locationAddress = results[1];
                const city = locationAddress?.address_components?.filter(comp => comp.types.includes("locality"))?.map(cityObj => cityObj.long_name)[0];
                const societySelected = {
                  city: city,
                  latitude: locationAddress.geometry.location.lat(),
                  longitude: locationAddress.geometry.location.lng(),
                  location: locationAddress.formatted_address,
                  name: locationAddress.formatted_address
                };
                that.zone.run((_)=>{
                  that.authService.userData.societyObj = {
                    name: societySelected.name,
                    id: null,
                    location: societySelected.location,
                    active: false,
                    hubId: null,
                    latitude: societySelected.latitude,
                    longitude: societySelected.longitude,
                    societyType: null,
                    deliveryStartDate: null
                  };
                  that.authService.userData.cityObj = {
                    name: societySelected.city,
                    id: null
                  };
                  that.location = societySelected.name;
                  that.signupService.anfDetails.y = {
                    lat: societySelected.latitude,
                    lng: societySelected.longitude,
                    type: ANF_DETAIL_TYPE.ADDRESS
                  };
                  that.signupService.anfDetails.z = null;
                });
                setTimeout(()=>{
                  that.navCtrl.navigateForward(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.GEOLOCATION + '/' + AddressSelectionType.ConfirmSociety);
                },10);

            }
        }
    });
  }

  mbOnDestroy() {
    document.getElementsByTagName('body')[0].classList.remove('address-success');
    document.getElementsByTagName('body')[0].classList.remove('confirm-society');
    document.getElementsByTagName('body')[0].classList.remove('select-tower');
    document.getElementsByTagName('body')[0].classList.remove('add-address');
    document.getElementsByTagName('body')[0].classList.remove('select-delivery-location');
    document.getElementsByTagName('body')[0].classList.remove('select-no-serviceFlow');
    document.getElementsByTagName('ion-bottom-sheet')[0].classList.remove('change_height');
    this.geolocationService.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
