<ng-container *ngIf="hideStripForMonthlyRecurringProduct">
  <div class="cashback-wrap" *ngIf="!!recurringProductDetails && componentType !== componentTypeView.Account_History_Details;else accountDetailPageView">
    <ng-container [ngSwitch]="stripType">
        <ng-container *ngSwitchCase="stripTypes.PRODUCT_LISTING_STRIP">
          <span class="cashback-detail fixed" *ngIf="!recurringProductDetails?.['productOverlaps'];else productOverlapsData">
            Subscribe to Save
            {{recurringProductDetails['cb_type']
            === cashbackType.FLAT_TYPE?'₹':''}}{{
            recurringProductDetails['cb_val']
            }}{{recurringProductDetails['cb_type']
            === cashbackType.FLAT_TYPE? '/unit':''}}{{recurringProductDetails['cb_type']
            === cashbackType.PERCENT_TYPE?'%':''}}
          </span>
          <ng-template #productOverlapsData>
            <span class="cashback-detail percentage" >
              Saving
              {{recurringProductDetails['cb_type']
              === cashbackType.FLAT_TYPE && recurringProductDetails['cb_val'] !== 'Paused'?'₹':''}}{{
              recurringProductDetails['cb_val']
              }}{{recurringProductDetails['cb_type']
              === cashbackType.FLAT_TYPE && recurringProductDetails['cb_val'] !== 'Paused'? '/unit':''}}{{recurringProductDetails['cb_type']
              === cashbackType.PERCENT_TYPE && recurringProductDetails['cb_val'] !== 'Paused'?'%':''}}{{onSubscriptionTextView || recurringProductDetails['cb_val'] == 'Paused' ?'':' on
              subscription'}}
            </span>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="stripTypes.CALENDER_BOTTOM_STRIP">
          <p class="earning-cashback one-step-away ion-text-center"
            *ngIf="!(dataService.calendarMetaData.plan === calendarPlanType.WEEKLY && dataService.quantitySum === 0 )">
            You’re one step away from saving
            {{recurringProductDetails['cb_type']
            === cashbackType.FLAT_TYPE?'₹':''}}{{
            recurringProductDetails['cb_type']
            === '%cashback'?recurringProductDetails['cb_val']:(recEdit?recurringProductDetails?.actual_price:recurringProductDetails['cb_val'])
            * (dataService.calendarMetaData.plan === calendarPlanType.WEEKLY ?
            dataService.quantitySum:dataService.calendarPlanObject.constantQuantity)}}{{recurringProductDetails['cb_type']
            === cashbackType.PERCENT_TYPE?'%':''}} {{dataService.calendarMetaData.plan ===
            calendarPlanType.WEEKLY || dataService.calendarMetaData.plan ===
            calendarPlanType.DAILY?dataService.calendarMetaData.plan.toLowerCase():'on every order'}}
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="stripTypes.RECURRING_LIST_CARD_TOP_STRIP">
          <ng-container *ngIf="!recurringProductDetails?.ef_till; else effectiveDateProduct" >
          <span class="cashback-detail margin-top-medium ch-cashback f-w-s-b ion-text-center margin-bottom-small" *ngIf="recurringProductDetails['cb_type']
            === cashbackType.PERCENT_TYPE">
            Saving on this item will change to
            {{recurringProductDetails?.currentSlab?.futureProduct?.['p_cb']
            }}% starting
            {{recurringProductDetails?.currentSlab?.futureProduct?.['ef_from']}}
          </span>
          <span class="cashback-detail margin-top-medium   ch-cashback f-w-s-b ion-text-center margin-bottom-small" *ngIf="recurringProductDetails['cb_type']
            === cashbackType.FLAT_TYPE">
            Saving on this item will change to
            {{recurringProductDetails['cb_val']
            | currency: 'INR'}}
            starting
            {{recurringProductDetails['ef_from']}}
          </span>
          </ng-container>
          <ng-template #effectiveDateProduct>
            <span  class="cashback-detail margin-top-medium ch-cashback f-w-s-b ion-text-center margin-bottom-small">
              This item will be removed from the Subscribe to Save range starting {{  momentRef(recurringProductDetails['ef_till']).add(1, 'days').format("YYYY-MM-DD")}}
            </span>
          </ng-template>
        </ng-container>
    </ng-container>
  </div>
  <ng-template #accountDetailPageView>
    <div class="cashback-wrap" *ngIf="showAccountStrip">
      <span class="cashback-detail percentage">
        Saving
        {{recurringService.recurringCampaignProducts.recurringCampaignMapper.products[product.product_id]['cb_type'] ===
        cashbackType.FLAT_TYPE ? '₹' + product?.cb_amount:product?.cb_per + '%'}} on subscription
      </span>
    </div>
  </ng-template>
  </ng-container>