//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
//#endregion Module Imports

//#region Component Imports
import { PaymentOfferListingComponent } from '@Shared/payment/payment-offer-listing/payment-offer-listing.component';
import { TermsConditionPopupModule } from '@Shared/payment/terms-condition-popup/terms-condition-popup.module';
import { OfferDescriptionModule } from '@Shared/payment/offer-description/offer-description.module';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TermsConditionPopupModule,
    OfferDescriptionModule,
    OfferDescriptionModule
  ],
  exports: [PaymentOfferListingComponent,TermsConditionPopupModule,OfferDescriptionModule],
  declarations: [PaymentOfferListingComponent]
})
export class PaymentOfferListingModule extends DefaultModule { }