export class ProductDiscount {
    __typename = 'ProductDiscount';
    amount: string;
    value: number;
    percent: number;
    perUnit: number;
    text: string;

    setDiscountFromApi(productApi) {
        const mrp = productApi.mrp_per_unit || productApi.mrp;
        const sp = productApi.price_per_unit || productApi.sp || productApi.price;

        this.value = this.roundOff(mrp - sp || 0);
        this.amount = `${this.roundOffDiscount(this.value)}`;

        this.perUnit = this.roundOff(productApi.saving_per_unit || 0);
        this.percent = this.roundOff(this.value ? (this.value / productApi.mrp) * 100 : 0 || 0);

        this.text = '';
        return this;
    }

    setDealDiscountFromApi(productApi) {
        const mrp = productApi.mrp_per_unit || productApi.mrp;
        const sp = productApi.price_per_unit || productApi.sp || productApi.price;
        const discount = mrp - sp;
        const discountPercent = discount > 0 ? (discount / mrp) * 100 : 0;

        this.percent = this.roundOff(productApi.dodPrice || discountPercent);
        this.value = this.roundOff((mrp * this.percent) / 100);
        this.amount = `${this.roundOffDiscount(this.value)}`;

        this.perUnit = null;
        this.text = '';
        if (productApi.dodPrice || discount > 0) {
            this.text = productApi.dodPrice
                ? `*${this.percent}% lesser than avg online price`
                : `*${this.percent}% discount on this product`;
        }
        return this;
    }

    roundOff(number) {
        return Number((+number || 0).toFixed(2));
    }

    roundOffDiscount(number) {
        return (+number).toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });
    }
}
