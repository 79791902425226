//#region Imports

//#region Angular Imports
import { ChangeDetectorRef, Component, ChangeDetectionStrategy, Input, EventEmitter, Output }  from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthService } from '@Globals/providers/auth.service';
import { ProductListManagerService } from '@Features/product-list/providers/product-list-manager.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_ARGS, EVENT_NAMES, EVENT_SOURCES, PRODUCT_DETAILS } from '@Globals/constants/listing/constants';
import { DeliveryType, RecommendationType, ProductListType, CollectionDisplayType } from '@Globals/constants/listing/enums';
import { LISTING_ROUTES } from '@Globals/constants/listing/routes';
import { RecommendationList, SimilarProducts } from '@Globals/interfaces/listing/interfaces';

import { Utilities } from '@Globals/classes/utilities';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mb-pl-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: [ './recommendation.component.scss' ],
  providers: [ ProductListManagerService ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendationComponent {
  @Input() recommendationCategory?: string;
  @Input() recommendation: RecommendationList;
  @Input() recommendationType?: RecommendationType;
  @Input() eventSource?: string;
  @Input() eventData: any;
  @Input() isCancelledProduct = false;
  @Input() displayType;
  @Output() viewAll = new EventEmitter();
  deliveryType = DeliveryType;
  recommendationTypes = RecommendationType;
  collectionDisplayType = CollectionDisplayType;
  componentType = ProductListType.Recommendation_Product_Listing;
  eventSourceHome = EVENT_SOURCES.HOME;
  currentHoursAndMinutes = Utilities.getCurrentHoursAndMinutes();
  horizontalProductListingWithHeader = ProductListType.Horizontal_Listing_With_Headers;
  hasNoCutoffTime = false;
  public productDetails = PRODUCT_DETAILS;
  itemType: string;
  retry = 1;
  subscription:Subscription;

  recommendedProductList: SimilarProducts = {
    product: {
        id: 0
    },
    title: 'Suggestions for you'
  };

  constructor (
    public authService: AuthService,
    protected plmService: ProductListManagerService,
    private navCtrl: NavController,
    private cdRef: ChangeDetectorRef,
    public masterDataService:MasterDataService,
    private dataService: DataService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    if (this.recommendation) {
      this.recommendation.data = this.recommendation.data?.map((product) => {
        if(product?.variantProducts && product?.variantProducts?.length){
          product = this.plmService.getVariantDefaultProduct(product);
        }
        if (this.plmService.getIsMBeyond() && product.mbeyondSp) {
          product.sp = product.mbeyondSp;
        }
        return product;
      });
      switch (this.recommendationType) {
        case RecommendationType.Related:
          this.itemType = EVENT_ARGS.RELATED;
          break;
        case RecommendationType.See_Similar:
          this.itemType = EVENT_ARGS.SIMILAR;
          break;
        default:
          this.itemType = this.recommendation.title && this.recommendation.title || '';
          break;
      }
      if (this.recommendationType === this.recommendationTypes.Recommendation) {
        const cutOffTimeProductIndex = this.recommendation.data.findIndex(product => {
          return product.ct !== '0';
        });
        this.hasNoCutoffTime = cutOffTimeProductIndex > 2 || cutOffTimeProductIndex === -1;
      }
    }
    this.plmService.setupTomorrowBasket();
    this.subscription = this.plmService.getRunChangeDetForA2B().subscribe(res => {
      this.cdRef.markForCheck();
    });
  }

  triggerCounterChange(event, product, productIndex) {
    this.plmService.counterChange(this, event, product, productIndex);
  }

  triggerRequestProduct(product, isSourceSeeSimilar = false) {
    return this.plmService.requestProduct(this, product, isSourceSeeSimilar);
  }

  showAll() {
    this.plmService.trackShowAll();
    this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.RECOMMENDATION + this.recommendationCategory);
  }

  removeCancelledProduct(productId) {
    Utilities.retryAPICall(this.plmService.removeCancelProductRequest(productId, true));
  }

  setToCalendar(product, productIndex) {
    this.plmService.setToCalendar(product, productIndex);
  }

  handleItemClick(product, productIndex) {
    return this.plmService.productDetailsModal(
      product.product_id || product.id,
      productIndex,
      this.productDetails,
      this.eventSource || this.eventSourceHome,
      this.eventData,
      this.itemType,
      product
    );
  }
  
  successHandlerForRecommendedProducts = (productList, product) => {
    this.recommendedProductList.data = productList;
    this.recommendedProductList.product = product;
  }

  async openProductsVariantModal(product, selectedProductIndex) {
    this.dataService.productVariantInfo.isVariantAddedToCart = false;
    this.dataService.productVariantInfo.latestVariantIndexIntoCart = null;
    const variantProduct = product?._newVariantProducts;
    
    if (variantProduct && variantProduct.length) {
      const modal = await this.plmService.openProductVariantModel(variantProduct, product, ProductListType.Product_Variant_List, this.eventSource, this.eventData);
      await modal.present();
      let eventAgrs = { source: this.eventSource, product_id: product.id, variant_id: product.variantId };
      this.eventsService.trackEvents(EVENT_NAMES.PRODUCT_VARIANT_CLICKED, { ...eventAgrs });

      modal.onDidDismiss().then(res => {
        if (this.dataService.productVariantInfo.isVariantAddedToCart) {
          this.recommendation.data[selectedProductIndex] = variantProduct[this.dataService.productVariantInfo.latestVariantIndexIntoCart];
          this.recommendation.data[selectedProductIndex]._newVariantProducts = variantProduct;
          this.cdRef.detectChanges();
        }
      });
    }
  }

  viewAllCollection() {
    this.viewAll.emit();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
