//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LISTING_ROUTES } from '@Globals/constants/listing/routes';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultRoutingModule } from '@Lib/defaults/default-routing.module';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthGuardService } from '@Globals/providers/auth-guard/auth-guard.service';
//#endregion Providers Imports

//#region Other Imports
import { CORE_ROUTES } from '@Globals/constants/listing/routes';
import { PROFILE_ROUTES } from '@Globals/constants/profile/routes';
import { REACT_WIDGET_MAP } from "@Globals/constants/react/widget-map.constants";
//#endregion Other Imports

//#endregion Imports

const routes: Routes = [
    {
        path: CORE_ROUTES.USER + '/' + PROFILE_ROUTES.SETTING,
        data: { componentName: REACT_WIDGET_MAP.PROFILE },
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.USER + '/' + PROFILE_ROUTES.ACCOUNT_CLOSURE + "/:accountClosureType",
        data: { componentName: REACT_WIDGET_MAP.ACCOUNT_CLOSURE },
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.USER + '/' + PROFILE_ROUTES.ACCOUNT_DELETED,
        data: { componentName: REACT_WIDGET_MAP.ACCOUNT_DELETED },
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.USER + '/' + PROFILE_ROUTES.CHANGE_PASSWORD,
        loadChildren: () => import('@Main/pages/profile/change-password/change-password.module').then(m => m.ChangePasswordModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.USER + '/' + PROFILE_ROUTES.CONTACT,
        data: { componentName: REACT_WIDGET_MAP.CONTACT_US },
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService],
    }, {
        path: CORE_ROUTES.USER + '/' + PROFILE_ROUTES.HOLIDAY,
        loadChildren: () => import('@Main/pages/profile/holiday/holiday.module').then(m => m.HolidayModule),
        canActivate: [AuthGuardService]
    },
    {
        path: CORE_ROUTES.USER + "/" + PROFILE_ROUTES.REFER,
        data: { componentName: REACT_WIDGET_MAP.REFERRAL },
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService],
    },
    {
        path: CORE_ROUTES.USER + "/" + PROFILE_ROUTES.REFER + "/2",
        data: { componentName: REACT_WIDGET_MAP.REFERRAL_HOW_IT_WORKS },
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService],
    },
    {
        path: CORE_ROUTES.USER + "/" + PROFILE_ROUTES.REFER + "/3",
        data: { componentName: REACT_WIDGET_MAP.REFERRAL_HISTORY },
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfileRoutingModule extends DefaultRoutingModule { }