import { environment } from "@Env/environment";

export enum DeliveryTypes {
  frozen = "7:00-8:00",
  normal = "normal",
}

export enum ProductTypes {
  Product = 1,
  Sample = 2,
}

export enum ProductBadgeTypes {
  None = 0,
  New = 1,
}

export enum ImageTypes {
  None = 0,
  Image = 1,
  /* Lottie = 2, // for future usecase */
}

export const ProductSourceType = { 0: "Unknown", 1: "Farm Route" };
export const ProductSourceTypeReverseMap = { Unknown: 0, "Farm Route": 1 };

export const productToProductApi = (product, processVariants = true) => {
  try {
    const productApi: Record<string, unknown> = {};
    productApi.product_id = productApi.id = product.id;
    productApi.product_name = productApi.nm = productApi.name = product.name;
    productApi.sample = product.type === ProductTypes.Sample ? true : false;
    productApi.isNew = product.badge === ProductBadgeTypes.New;
    productApi.sticker = product.stickerType || null;

    // ProductRecurrence
    productApi.recurring = product?.recurrence?.type;
    productApi.recText = product?.recurrence?.text;
    productApi.recTextPLP = product?.recurrence?.listingText;
    

    // category
    productApi.cid = product?.category?.id;
    productApi.category = product?.category?.name || '';
    productApi.scid = product?.category?.subCategory?.id;
    productApi.subcategory = product?.category?.subCategory?.name || '';
    productApi.subcategory_type = product?.category?.subCategory?.type || '';
    // ProductDelivery
    productApi.deliveryTypeId = product?.delivery?.type || null;
    productApi.delivery_types_slot = product?.delivery?.slot || null;

    // ProductAvailability
    productApi.oos = !!product?.availability?.oos;
    productApi.forcedOos = !!product?.availability?.forcedOos;
    productApi.maxQty = product?.availability?.maxQuantity || null;
    productApi.schedule = product?.availability?.schedule || false;

    // cutoff
    productApi.ct = product?.availability?.cutOff?.time || "";
    productApi.ct_text = product?.availability?.cutOff?.text || "";

    // cutoff
    productApi.refillType = product?.availability?.refill?.type || "";
    productApi.refillDate = product?.availability?.refill?.date || "";
    // productApi.baOos = product?.availability?.refill?.baOos || "";

    let imageUrl = product?.assets?.image?.url || "";
    if(imageUrl) {
      imageUrl = imageUrl.replace(environment.productPath, '');
    }
    productApi.image = productApi.img = imageUrl;

    // Tag
    productApi.title = product?.tag?.title || "";
    let offerImageUrl = product?.tag?.assets?.image?.url || "";
    if(offerImageUrl) {
      offerImageUrl = offerImageUrl.replace(environment.productPath, '');
    }
    productApi.offerImg = offerImageUrl;
    productApi.highlightColor = (product?.tag?.color || "").replace("#", "");

    // Source
    productApi.source = '';
    productApi.location = '';
    if (product.source) {
      productApi.source =
        product.source.source && ProductSourceTypeReverseMap?.[product.source.source]
          ? ProductSourceTypeReverseMap[product.source.source]
          : "";
  
      productApi.location = product.source.location || "";
    }

    // Weight
    productApi.product_weight =
      productApi.wgt =
      productApi.weight =
        product?.weight?.text || "";
    productApi.numericWeight = product.weight.value || null;
    productApi.unitOfMeasurement = product.weight.unit || null;

    // Price
    productApi.pricePerUnit = product?.price?.perUnit;
    productApi.mbeyondSp = product?.price?.mbeyond?.value || 0;

    productApi.mrp_per_unit = productApi.mrp = product?.price?.mrp?.value || 0;
    productApi.price_per_unit =
      productApi.sp =
      productApi.price =
        product?.price?.price?.value || 0;
    productApi.saving_per_unit = product?.price?.discount?.perUnit || 0;

    productApi.dodPrice = product?.price?.dealDiscount?.percent || 0;

    // bundle
    productApi['prop'] = {}
    productApi.prop['bundle_view'] = product?.bundle?.active ? 1 : 0

    // setting quantity
    productApi.quantity = product?.order?.quantity || 0;
    return productApi;
  } catch (e) {
    return null;
  }
};

export const productDossierToProductApi = (productDossier) => {
  try {
    let productApi: Record<string, unknown> = {};
    productApi = productToProductApi(productDossier.product);

    if(productDossier?.variant?.id && productDossier?.variant?.active) {
      productApi.variantId = productDossier.variant.id;
      productApi.isVariantActive = !!productDossier.variant.active;

      let variantProducts = [] as Array<Record<string, unknown>>;
      if (productDossier.variant.products) {
        productDossier.variant.products.forEach(variantProduct => {
              const variantProductApi = productToProductApi(variantProduct, false);
              if (variantProductApi) {
                variantProducts?.push(variantProductApi);
              }
          });
          productApi.variantProducts = variantProducts;
      }
    }
    return productApi;
  } catch(e) {
    return null
  }
}