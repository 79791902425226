//#region Imports

//#region Angular Imports
import { Component, ChangeDetectionStrategy } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { InViewportMetadata } from 'ng-in-viewport';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { environment } from '@Env/environment';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-category-collection-block',
  templateUrl: './category-collection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryCollectionComponent extends BaseCollectionComponent {
  animationItem: AnimationItem;
  lottieConfig: AnimationOptions = {
    path: 'assets/lottie/shimmer.json',
    autoplay: true,
    loop: true
  };
  isLottieCollection = false;

  mbOnInit(): void {
    if(this.data.video) {
      this.isLottieCollection = true;
      this.lottieConfig = {
        ...this.lottieConfig,
        ...{ path: environment.collectionPath + this.data.video}
      };
    }
    super.mbOnInit();
  }

  animationCreated(animationItem: AnimationItem) {
    this.animationItem = animationItem;
  }

  pause() {
    this.animationItem && this.zone.runOutsideAngular(() => {
      this.animationItem.pause();
    });
  }

  play() {
    this.animationItem && this.zone.runOutsideAngular(() => {
      this.animationItem.play();
    });
  }

  onIntersection($event) {
    const { [InViewportMetadata]: { entry }, target } = $event;
    const ratio = entry.intersectionRatio;
    ratio >= 0.65 ?  this.play() : this.pause();
  }
}
