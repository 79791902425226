<!--is-grid-view class will get added for grid view collection 3 collections in one row-->
<ion-list lines="none" class="ion-no-padding ion-list-image-card ion-list-grid gvc layout-spacer">
<ion-list-header class="ion-list-header" *ngIf="data?.name">
  <ion-row class="ion-justify-content-between full-width ion-align-items-center">
    <div>
      <span class="padding-right-small">{{ data.name }}</span>
      <p *ngIf="data?.description">{{ data.description }}</p>
    </div>
  </ion-row>
</ion-list-header>
<ion-item 
  class="ion-no-padding horizontal-widget" lines="none" 
  [ngClass]="{
  'small-size-img': data?.customizeCollection == 4,
  'medium-size-img': data?.customizeCollection == 5,
  'large-size-img': data?.customizeCollection == 6}"
  >
  <div class="scroll" scroll-x="true">

    <ion-card class="ion-card-custom fixed-size" (click)="navigateToCollectionList(row)"
      *ngFor="let row of collectionTableData">
      <div class="img-holder">
        <div class="img-holder-inner">
          <div [ngSwitch]="row.type">
            <ng-container *ngSwitchCase="leafMediaType.IMAGE">
              <app-img-on-demand [actualImgSrc]="bannerFilePath + row.images.leafBanner.image"
                [defaultImgSrc]="'assets/img/default-banner.png'" [showDefaultImage]="props?.showDefaultImage"
                alt_image="img_banner_{{row.images.leafBanner.image}}"
                [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER" [useImageOnDemand]="false"
                [imageName]="row.images.leafBanner.image">
              </app-img-on-demand>
            </ng-container>
            <ng-container *ngSwitchCase="leafMediaType.LOTTIE">
              <ng-lottie 
                [options]="{path: collectionFilePath + row.listPageBannerLottie, loop: true, autoplay: true}" class="lottie-animation">
              </ng-lottie>
            </ng-container>
          </div>
        </div>
      </div>

      <ion-card-header *ngIf="row.title?.text">
        <ion-card-title class="ion-text-center">
         {{ row.title.text }}
        </ion-card-title>
      </ion-card-header>
    </ion-card>

  </div>
</ion-item>
</ion-list>