export const COLORS = {
    SETTINGS: '#738d79',
    HOLIDAYS: '#b796a3',
    APP: '#FFFFFF'
};
export const CHANGE_PASSWORD_ERROR_MESSAGES = {
    PASSWORD_DONT_MATCH: 'Password doesn\'t match'
};

export const CHANGE_PASSWORD_SUCCESS_ALERT = {
    BUTTONS: ['OK'],
    MESSAGE: 'Password has been changed successfully!'
};

export const ACCOUNT_CLOSURE_DEACTIVATION = {
    BUTTONS: {
        CLOSE: {
            text: '',
            role: 'cancel',
            handler: null,
            cssClass: 'alert-button-close icon-close icon-base icons-property icon-dark'
        },
        CONFIRM: {
            text: 'Yes, Revoke my deactivation request',
            handler: null,
            cssClass: 'alert-button-primary-outline'
        }
    },
    META_DATA: {
        IS_CLOSE_ICON_REQUIRED: true,
        HEADER_TEXT: 'Hey! We’re glad you decided to stay back!',
        MESSAGE: 'With this, you will stop your account deactivation process and your account will be restored to its earlier state. Including your membership, wallet balance and all future orders.'
    },
    CSS_CLASS: 'alert-custom account-closure'
};

export const REFER_CODE_COPIED = 'Copied to Clipboard';

export const INVITE_CHOOSER_TITLE = 'Share Milkbasket with...';

export const SHARE_SUBJECT = 'Refer';

export const SELF_HELP_EVENT_NAMES = {
    CAMERA_NEEDED: 'cameraNeeded',
    DOWNLOAD_STATEMENT: 'downloadStatement',
    DOWNLOAD_STATEMENT_PERMISSION: 'downloadStatementPermissionCheck',
    REQUEST_PERMISSION: 'requestPermission',
    PERMISSION_AVAILABLE:'permissionAvailable',
    PERMISSION_UNAVAILABLE:'permissionUnavailable',
    START_TIMER:'startTimer',
    SUCCESS:'success',
    ERROR:'error',
    NAV_TO_LINK: 'navToLink',
    GO_BACK: 'goBack'
};

export const EVENT_NAMES = {
    REFERRAL_COPY: 'Referral-Copy',
    REFERRAL_INVITE_START: 'Referral Invite Start',
    REFERRAL_INVITE_COMPLETE: 'Referral Invite Complete',
    REFERRAL_HOW_IT_WORKS: 'Referral HowItWorks',
    REFERRAL_EARNINGS_VIEW: 'Referral Earnings View',
    REFERRAL_ADD_MONEY_POP_UP: 'Referral AddMoneyPopup',
    SHOPPING_CREDIT_REFERRAL: 'Shopping Credits',
    REFERRAL_SEND_REMINDER_START: 'Referral SendReminder Start',
    REFERRAL_SEND_REMINDER_COMPLETE: 'Referral SendReminder Complete',
    REFERRER_STATUS: 'Referrer_status',
    SHARING_COMPLETE: 'Sharing Complete',
};

export const MORE_ACTION_BUTTON_NAMES = {
    LOGOUT: 'logout',
    TNCS: 'tncs'
};

export const TNC_LINK = 'https://www.milkbasket.com/terms-and-conditions.html';

export const LOGOUT_CONFIRMATION = {
    BUTTONS: {
        CANCEL: { text: 'No', role: 'cancel', handler: null },
        CONFIRM: {
            text: 'Yes',
            handler: null,
            cssClass: 'alert-button-primary-outline'
        }
    },
    META_DATA: {
        HEADER_TEXT: 'It’s sad to see you leave..',
        MESSAGE: 'You sure you want to logout ?'
    },
    CSS_CLASS: 'alert-custom'
};

export const REFERRAL_STATUS_BASED_ON_FLAG = {
    0: 'Successfully topped up wallet',
    '-1': 'Yet to top-up wallet',
    1: 'Successfully topped up wallet',
    3: 'Not eligible for referral benefits'
};

export const FAILED_REFERRAL_STATUS_BASED_ON_REASON_FLAG = {
    1: 'Society not serviceable',
    2: 'Your society not serviceable',
    3: 'Duplicate address',
    4: 'Expired',
    9: 'Referral limit exceeded'
};

export const STATUS_KEY = {
   REJECTED: '2'
};

export const REFERRAL_REWARD_DETAIL = {
    BUTTONS: {
        CLOSE: {
            text: '',
            role: 'cancel',
            handler: null,
            cssClass: 'alert-button-close icon-close icon-base icons-property icon-dark'
        }
    },
    META_DATA: {
        IS_CLOSE_ICON_REQUIRED: true,
        MESSAGE: '',
        NOTE: 'Referral Reward'
    },
    CSS_CLASS: 'alert-custom no-button max-height-auto referral-reward'
};

export const GET_AND_ADD_REFERRAL_REWARD_TEXT = (referee) => {
    if (referee.amount_referred_by === referee.referral_reward) {
        referee.referral_reward_text = '₹' + referee.amount_referred_by + ' benefit to you & your friend';
    } else {
        referee.referral_reward_text = '₹' + referee.amount_referred_by + ' benefit to you & ₹' + referee.referral_reward + ' to your friend';
    }
    return referee.referral_reward_text;
};

export const REMINDER_MESSAGE = 'Hey, dost!\nLooks like you signed up on milkbasket but haven’t topped up yet. ' +
    'Just pinging you to remind you that you need to top up for both of us to get the referral benefits!' +
    '\nHope you do it before the referral expires!';

export const GALLERY_CAMERA_OPTIONS = {
    BUTTONS: {
        CLOSE: {
            text: '',
            role: 'cancel',
            handler: null,
            cssClass: 'alert-button-close icon-close icon-base icons-property icon-dark'
        },
        GALLERY: {
            text: 'Gallery',
            role: 'Okay',
            handler: null,
            cssClass: 'gallery'
        },
        CAMERA: {
            text: 'Camera',
            role: 'Okay',
            handler: null,
            cssClass: 'camera'
        }
    },
    META_DATA: {
        IS_CLOSE_ICON_REQUIRED: true,
        HEADER_TEXT: 'Choose photos from',
        MESSAGE: ''
    },
    CSS_CLASS: 'alert-custom choose-photo'
};

export const LIVE_NOW_OBJECT = {
    liveNowText: 'New',
    liveNowColor:"ffcd22"
};

export const GENDER = ['Male', 'Female', 'Others'];

export const FOOD_PREFERENCES = [
    'Egg', 'Vegeterian', 'Non-Vegeterian', 'Vegan'
];

export const MARITAL_STATUS = ['Married', 'Unmarried'];

export const NO_OF_FAMILY_MEMBER = [
    '1', '2', '3', '4', '5', '6', '>6'
];

export const USER_CHARACTERISTICS = [
    { key: 'tea_lover', value: 'Tea Lover' },
    { key: 'coffee_lover', value: 'Coffee Lover' },
    { key: 'fan_of_organic_foods', value: 'Fan of organic foods' },
    { key: 'health_freak', value: 'Health Freak' },
    { key: 'mid_night_snacking', value: 'Mid-night snacking' },
    { key: 'clean_freak', value: 'Clean freak' },
    { key: 'enjoy_cooking_meals', value: 'Enjoy cooking meals' },
    { key: 'sweet_tooth', value: 'Sweet tooth' },
    { key: 'crave_savory_items', value: 'Crave savory items' },
    { key: 'fan_of_baked_goods', value: 'Fan of baked goods' },
    { key: 'rely_on_ready_to_eat', value: 'Rely on ready to eat' },
];

export const  REFUND_BALANCE_TNC= `<ul class='ref-bal-tnc' style='letter-spacing: 0.3px;'>
<li dir='ltr' aria-level='1'><p dir='ltr' role='presentation'>Upon deactivating your account, the refundable amount will be credited to your source account within 9-12 business days.</p></li>
<li dir='ltr' aria-level='1'><p dir='ltr' role='presentation'>The refundable amount is subject to any deductions such as subscription fees, etc.<span style='letter-spacing: 0.3px; background-color: transparent;'>&nbsp;&nbsp;</span></p></li>
<li dir='ltr' aria-level='1'><p dir='ltr' role='presentation'>The refundable amount excludes cashbacks.<span style='letter-spacing: 0.3px; background-color: transparent;'>&nbsp;&nbsp;</span></p></li>
</ul>`;

export const NOTIFICATION = [
    { TYPE: 'SMS', MESSAGE: 'Get SMS notification for all The Promos, Offers and Deals' },
    { TYPE: 'EMAIL', MESSAGE: 'Get email notification for all The Promos, Offers and Deals' },
    // { TYPE: 'WHATSAPP', MESSAGE: 'Get whatsapp notification for all the Promos, Offers and Deals' },
];

export const SAVE_PROFILE_DETAILS_SUCCESS_ALERT = {
    MESSAGE: "Profile details saved successfully!"
};

