<span class="cashback-detail fixed" *ngIf="!recurringProductDetails?.['productOverlaps'];else productOverlapsData">
  Subscribe to Save
  {{recurringProductDetails['cb_type']
  ===
  cashbackType.FLAT_TYPE?'₹'+recurringProductDetails['cb_val']+'/unit':recurringProductDetails['cb_val']+'%'}}
</span> 
 <ng-template #productOverlapsData>
<span class="cashback-detail percentage" *ngIf="(recurringProductDetails['cb_type'] === cashbackType.FLAT_TYPE) || recurringProductDetails['add_rec']">
  Saving {{recurringProductDetails['cb_type']
  ===
  cashbackType.FLAT_TYPE && flatValue !=='Paused'?'₹':'' }}{{recurringProductDetails['cb_type']
  ===
  cashbackType.FLAT_TYPE?flatValue:percentValue}}{{recurringProductDetails['cb_type']
  ===
  cashbackType.FLAT_TYPE &&  flatValue !=='Paused'?'/unit':'' }}{{recurringProductDetails['cb_type'] === cashbackType.PERCENT_TYPE && percentValue !== 'Paused'?'%':''}}{{percentValue === 'Paused'?'':' on subscription'}}
</span>
</ng-template>