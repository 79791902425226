//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { InViewportModule } from 'ng-in-viewport';
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
import { ImgOnDemandModule } from '@Shared/img-on-demand/img-on-demand.module';
//#endregion Module Imports

//#region Component Imports
import { AutoSwipeComponent } from '@Widgets/blocks/collection/auto-swipe/auto-swipe.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SwiperModule,
    InViewportModule,
    ImgOnDemandModule
  ],
  exports: [AutoSwipeComponent],
  declarations: [AutoSwipeComponent]
})
export class AutoSwipeModule extends DefaultModule { }