<ion-header class="sticky-header">
  <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium padding-horizontal-0 mh-auto">
    <ion-buttons slot="end" class="ion-margin-end" *ngIf="data.IS_CLOSE_ICON_REQUIRED">
      <ion-button (click)="closeModal()">
        <ion-icon class="icon-close icons-property icon-xmd" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="info-popup ion-text-center ctn-gt-space-p padding-bottom-medium">
  <div class="img-holder">
    <img [alt]="data.HEADER_TEXT" [src]="data.IMAGE_URL" />
  </div>
  <ion-text color="dark">
    <h2 class="bt-title f-w-b text-dark">{{ data.HEADER_TEXT }}</h2>
  </ion-text>
  <ion-text color="dark">
    <p class="bt-des text-dark" [innerHTML]="data.MESSAGE"></p>
  </ion-text>
  <div class="bt-footer margin-bottom-medium">
    <ion-button shape="round" size="large" expand="full" (click)="loginSignUp()">{{data.BUTTON_TEXT}}
    </ion-button>
  </div>
</ion-content>