import { ModalController, NavController } from '@ionic/angular';
import { noop, Observable } from 'rxjs';
import { RecurringOrder } from 'src/app/shared/interfaces/interfaces';
import { HttpService } from 'src/app/shared/providers/http/http.service';
import { MasterDataService } from 'src/app/shared/providers/master-data/master-data.service';
import { LISTING_APIS } from '../../constants/listing-APIs';
import { EVENT_NAMES, EVENT_SOURCES } from '../../constants/listing-constants';
import { EventsService } from '../../../shared/providers/events/events.service';
import { ProductInSearch } from '../../interfaces/listing-interface';
import { AuthService } from 'src/app/shared/providers/auth/auth.service';
import { CORE_ROUTES } from 'src/app/core/constants/coreRoutes';
import { AlertService } from 'src/app/shared/providers/alert/alert.service';
import { ALERT_BUTTON_TEXT, GENERATE_CONTENT_FOR_ALERT_LAYER, STORAGE_KEYS } from 'src/app/shared/constants/constants';
import { DataService } from 'src/app/shared/providers/data/data.service';
import { HTTPResponse } from '@ionic-native/http/ngx';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/shared/providers/storage/storage.service';
import { JOURNEY_TYPE } from 'src/app/shared/constants/enums';
import { RecurringViewType } from '../../constants/listing-enums';
import { ModalService } from 'src/app/shared/providers/modal/modal.service';
import { CAL_MODAL_CSS_CLASS, LOGIN_SIGNUP_A2B_POPUP } from 'src/app/core/constants/core-constants';
import { Injectable } from "@angular/core";
import { LISTING_ROUTES } from '../../constants/listing-routes';
import { Router } from '@angular/router';
import { InfoPopupComponent } from '@Modules/shared/info-popup/info-popup.component';
import { BottomSheetService } from '@App/shared/providers/bottom-sheet.service';
import { Utilities } from '@Globals/classes/utilities';
import { ToastService } from '@Globals/providers/toast.service';
import { MAX_LIMIT_REACHED } from '@Globals/constants/listing/constants';


@Injectable()
export class ProductListingService {
  constructor(
    private httpService: HttpService,
    private modalCtrl: ModalController,
    public masterDataService: MasterDataService,
    private eventsService: EventsService,
    private authService: AuthService,
    private navCtrl: NavController,
    private alertService: AlertService,
    private dataService: DataService,
    private storageService: StorageService,
    private modalService: ModalService,
    private router: Router,
    private bottomSheetService: BottomSheetService,
    private toastService: ToastService,
  ) { }

  isSetRecurring(product: ProductInSearch | RecurringOrder) {
    return (
      this.masterDataService.recurringOrder &&
      this.masterDataService.recurringOrder.find(item => {
        return (
          item.product_id === product.product_id ||
          (!product.product_id && item.product_id === product.id)
        );
      })
    );
  }

  /* async openRecurringModal(
    product: ProductInSearch | RecurringOrder,
    index: number,
    source: string,
    navigateToRecurringOrders: boolean,
    itemType?: string,
    appliedFilter?: string
  ) {
    if (this.authService.isLoggedIn()) {
      const foundProduct = this.isSetRecurring(product);
      product = foundProduct || product;
      const isEdit = foundProduct && true; // check if already in recurring order
      // this.statusBar.overlaysWebView(false);
      // this.statusBar.backgroundColorByName('white');
      // this.statusBar.styleDefault();
      if (!isEdit) {
        const eventArgs = {
          product_id: product.id,
          product_name: (product as ProductInSearch).nm
        };
        this.eventsService.trackEvents(
          EVENT_NAMES.RECURRING_ICON_PLP_CLICK,
          eventArgs
        );
      }
      const recurringModal = await this.modalCtrl.create({
        component: SetRecurringComponent,
        componentProps: {
          isEdit,
          product,
          index,
          source,
          navigateToRecurringOrders,
          itemType,
          appliedFilter
        },
        keyboardClose: true,
        cssClass: 'full-screen-modal'
      });
      recurringModal.present();
    } else {
      LOGIN_SIGNUP_CONFIRMATION.BUTTONS.OKAY.handler = () => {
        this.modalCtrl.dismiss();
        this.navCtrl.navigateRoot(CORE_ROUTES.BOARDING);
      };
      LOGIN_SIGNUP_CONFIRMATION.BUTTONS.CLOSE.handler = noop();
      const pastCutOffTimeContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
        LOGIN_SIGNUP_CONFIRMATION.META_DATA
      );
      this.alertService.presentAlertConfirm(
        pastCutOffTimeContent,
        [
          LOGIN_SIGNUP_CONFIRMATION.BUTTONS.OKAY,
          LOGIN_SIGNUP_CONFIRMATION.BUTTONS.CLOSE
        ],
        LOGIN_SIGNUP_CONFIRMATION.CSS_CLASS
      );
    }
    this.dontShowRecurringCoachMark();
  } */
  getProductListing(dataToBeSent, isYFOCategory): Observable<object> {
    return this.httpService.post(isYFOCategory ? LISTING_APIS.PRODUCTS_SEARCH : LISTING_APIS.CATEGORY_PRODUCTS, dataToBeSent);
  }

  getVariantDetails(variantId: number): Observable<object> {
    return this.httpService.post(LISTING_APIS.PRODUCT_VARIANT_DETAILS, {
        variantId: variantId
    });
  }
  
  getTrendingProducts(subcategoryId): Observable<object> {
    return this.httpService.get(
      LISTING_APIS.TRENDING_PRODUCTS + subcategoryId,
      { hideLoader: true }
    );
  }
  getCollection(dataToSend): Observable<object> {
    dataToSend.hideLoader = true;
    return this.httpService.post(
      LISTING_APIS.COLLECTION_SUBCATEGORY, dataToSend
    );
  }

  getRelatedProducts(dataToBeSent): Observable<object> {
    dataToBeSent.hideLoader = true;
    return this.httpService.post(LISTING_APIS.RELATED_PRODUCTS, dataToBeSent);
  }
  addProduct(dataToBeSent): Observable<object> {
    return this.httpService.post(LISTING_APIS.PRODUCTS_SEARCH, dataToBeSent);
  }

  requestProduct(productId): Observable<object> {
    return this.httpService.post(LISTING_APIS.PRODUCT_REQUEST, {
      product_id: productId
    });
  }

  getOffers(dataToBeSent): Observable<object> {
    return this.httpService.post(LISTING_APIS.OFFERS, dataToBeSent);
  }

  getProductDetails(productId: number, city_id): Observable<object> {
    return this.httpService.post(LISTING_APIS.PRODUCT_DETAILS, {
      product_id: productId,
      version: environment.codePushVersion,
      city_id: city_id
    });
  }

  showMaxQuantityAlert() {
    this.toastService.presentCustomToast({
      headerText: MAX_LIMIT_REACHED.headerText,
      msg: MAX_LIMIT_REACHED.msg,
      className: "toast-secondary",
      durationTime: 3000,
      isShowCloseButton: true,
    });
  }

  showOrderLimitReachedAlert(errorData) {
    // errorData array will either have amount_{value} or quantity_{value}
    if (errorData && errorData.length) {
      const reasonKeyValue = errorData[ 0 ].replace(/\.\d+/, '').split('_');
      this.dataService.firebaseMasterData.orderLimitReachedAlertData.META_DATA.HEADER_TEXT = this.dataService.firebaseMasterData.orderLimitReachedAlertData.HEADER_TEXTS[ reasonKeyValue[ 0 ] ];
      this.dataService.firebaseMasterData.orderLimitReachedAlertData.META_DATA.MESSAGE = this.dataService.firebaseMasterData.orderLimitReachedAlertData.MESSAGES[ reasonKeyValue[ 0 ] ];
      const contentKeysToBeReplaced = {};
      contentKeysToBeReplaced[reasonKeyValue[0]] = reasonKeyValue[1];
      this.alertService.presentAlert(
        GENERATE_CONTENT_FOR_ALERT_LAYER(this.dataService.firebaseMasterData.orderLimitReachedAlertData.META_DATA, contentKeysToBeReplaced),
        this.dataService.firebaseMasterData.orderLimitReachedAlertData.BUTTONS,
        this.dataService.firebaseMasterData.orderLimitReachedAlertData.CSS_CLASS
      );
    }
  }

  getSimilarProducts(product, dataToBeSent, successCallback) {
    this.dataService.parentProductId = dataToBeSent.product_id;
    this.httpService.post(LISTING_APIS.SIMILAR_PRODUCTS, dataToBeSent)
      .subscribe((response: HTTPResponse) => {
        if (response.data.status) {
          successCallback(response.data.data, product);
        } else {
          this.alertService.presentAlert(response.data.error_msg, [
            ALERT_BUTTON_TEXT
          ]);
        }
      });
  }

  removeCancelProduct(productId, loaderHide = false): Observable<object> {
    const dataToSend: any = { product_id: productId };
    if (loaderHide) {
      dataToSend.hideLoader = true;
    }
    return this.httpService.post(LISTING_APIS.REMOVE_CANCEL_PRODUCTS, dataToSend);
  }

  async dontShowRecurringCoachMark() {
    if (this.dataService.showRecurringCoachMark) {
      this.dataService.showRecurringCoachMark = false;
      localStorage.setItem(STORAGE_KEYS.SHOW_RECURRING_COACHMARK, 'false');
    }
  }

  getProductSubcategories(productId): Observable<object> {
    return this.httpService.get(`${LISTING_APIS.PRODUCTS}/${productId}/${LISTING_APIS.SUBCATEGORIES}`, { hideLoader: true});
  }

  goToCalendar(product, productIndex, eventSource, itemType, appliedFilter) {
    if (this.authService.isLoggedIn()) {
      if(this.masterDataService.masterData?.user?.wf){
        return;
      }
      if (/subscribe-to-save/i.test(this.router.url)) {
        this.eventsService.trackEvents(
          EVENT_NAMES.RECURRING_CAMPAIGN_SUBSCRIBE_ICON_CLICK,
          {
            source: EVENT_SOURCES.SUBSCRIBE_TO_SAVE_PAGE,
            category: product.category,
            product_id: product.id
          }
        );
      }
      this.dataService.calendarMetaData.productInfo.product = product;
      this.dataService.calendarMetaData.productInfo.productIndex = productIndex;
      this.dataService.calendarMetaData.productInfo.eventSource = eventSource;
      this.dataService.calendarMetaData.productInfo.itemType = itemType;
      this.dataService.calendarMetaData.productInfo.appliedFilter = appliedFilter;
      this.dataService.calendarMetaData.flow_type = 'new';
      const ifCalendarJourneyVisited = this.dataService.ifCalendarJourneyVisited();
      if (ifCalendarJourneyVisited) {
        this.calendarRedirect();
      } else {
        this.dataService.updateJourneyTakenFlag();
        this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.CALENDAR_JOURNEY, { animated: false });
      }
    } else {
      if(!Utilities.isApp() && this.masterDataService.navigateFromDeeplink && Utilities.isMobileBrowser()) {
        this.masterDataService.getRedirectionToPlayStoreOrAppStore();   
      } else {
        this.bottomSheetService.openLoginBottomSheet(InfoPopupComponent, [0, 0.45, 0.50, 1], 0.50, LOGIN_SIGNUP_A2B_POPUP);
      }
    }
  }

  calendarRedirect = () => {
    const foundProduct: any = this.isSetRecurring(this.dataService.calendarMetaData.productInfo.product);
    if (foundProduct) {
      foundProduct.isEdit = true;
      this.dataService.calendarMetaData.flow_type = 'edit';
      this.dataService.calendarMetaData.productInfo.product = foundProduct;
      this.openCalendarModal(RecurringViewType.ViewPlan);
    } else {
      this.openCalendarModal(RecurringViewType.SelectPlanType);
    }
  }

  async openCalendarModal(viewType) {
    this.dataService.calendarMetaData.viewType = viewType;
    const productInfo = this.dataService.calendarMetaData.productInfo;
    const { eventSource = '', productIndex = 0 } = productInfo;
    const productId = productInfo.product.product_id || productInfo.product.id || productInfo.product.pid;
    return this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.SET_SUBSCRIPTION_PLAN + '/' + productId + '/' + productIndex+ '/' + eventSource);
   }

  async openCalendarModalNew(viewType, component, rootPage) {
    const modal = await this.modalCtrl.create({
      component: component,
      cssClass: CAL_MODAL_CSS_CLASS,
      componentProps: {
        viewType,
        rootPage
      }
    });
    await modal.present();
    modal.onDidDismiss().then(res => {
    });
  }

  async redirectToCalendar(viewType) {
    let navigationURL = CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.SET_CALENDAR + '/' + viewType;
    this.navCtrl.navigateForward(navigationURL);
  }

  basketStatus(dataTosend = null): Observable<object> {
    if (dataTosend) {
      return this.httpService.post(LISTING_APIS.RECYCLE_BAGS, dataTosend);
    }
    return this.httpService.get(LISTING_APIS.RECYCLE_BAGS); 
  }

  navigateTo(
    trackNavigation?,
    category?,
    subCategory?,
    categoryIndex?,
    subCategoryIndex?
  ) {
    let navigationURL = trackNavigation;
    let routeArgs = {};
    switch (trackNavigation) {
      case LISTING_ROUTES.CATEGORY:
        this.eventsService.trackCategoryView(category, EVENT_SOURCES.CATEGORY, this.masterDataService.masterData?.user?.hubId);
        this.masterDataService.selectedCategory = category;
        this.masterDataService.selectedSubcategoryIndex = 0;
        navigationURL = LISTING_ROUTES.BASE + LISTING_ROUTES.CATEGORY + category.id;
        break;
      case LISTING_ROUTES.SUBCATEGORY:
        let source = EVENT_NAMES.HOME;
        const eventArgument: any = {};
        if (this.dataService.mbPassData.applyCategoryAndSubcategory && this.dataService.mbPassData.applyCategoryAndSubcategory[ subCategory.id ]) {
          source = EVENT_SOURCES.MB_PASS;
          eventArgument[ EVENT_SOURCES.MB_PASS ] = this.dataService.mbPassData.purchased;
        }
        eventArgument['hub_id'] = this.masterDataService.masterData?.user?.hubId;
        this.eventsService.trackSubCategoryView(subCategory, source, eventArgument, category);
        if (category.id) {
          this.masterDataService.selectedCategory = category.name ? category : this.masterDataService.categories?.find((cat) => {
            return cat.id === subCategory.categoryId;
          });
          this.masterDataService.selectedSubcategoryIndex = category.name ? subCategoryIndex : subCategory.subCategoryIndex;
        } else {
          this.masterDataService.getSelectedIndexes(subCategory.id);
        }
        navigationURL = LISTING_ROUTES.BASE + LISTING_ROUTES.SUBCATEGORY + subCategory.id;
        break;
      case LISTING_ROUTES.YOUR_CART:
        routeArgs = { animated: false };
        const eventArgs = { source: EVENT_SOURCES.HOME };
        this.eventsService.trackEvents(EVENT_SOURCES.CART_VIEWED, eventArgs);
        navigationURL = LISTING_ROUTES.BASE + LISTING_ROUTES.YOUR_CART;
        break;
    }
    this.navCtrl.navigateForward(navigationURL, routeArgs);
  }


  showOfferProductListing(productId): Observable<object> {
    return this.httpService.get(`${LISTING_APIS.LIST_PRODUCT}/${productId}/products?isActive=false`, { hideLoader: true});
  }

}
