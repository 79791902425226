//#region Imports

//#region Angular Imports
import { Injectable } from "@angular/core";
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { Observable, noop } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { HttpService } from '@Globals/providers/http.service';
import { AlertService } from '@Globals/providers/alert.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
import { ACCOUNT_CLOSURE_DEACTIVATION } from '@Globals/constants/profile/constants';
import { GENERATE_CONTENT_FOR_ALERT_LAYER } from '@Globals/constants/listing/constants';
import { UserAccountStatus } from '@Globals/constants/profile/enums';
import { CORE_ROUTES } from '@Globals/constants/listing/routes';
import { PROFILE_APIS } from '@Globals/constants/profile/apis';
//#endregion Other Imports

//#endregion Imports
@Injectable({
  providedIn:'root'
})
export class SettingService {
  constructor(
    private httpService: HttpService,
    private alertService: AlertService,
    private masterDataService: MasterDataService,
    private dataService: DataService,
    private navCtrl: NavController) { }

  changePassword(password: string): Observable<object> {
    return this.httpService.post(PROFILE_APIS.CHANGE_PASSWORD, {
      new_password: password
    });
  }

  cancelDeactivationAccount(restartSubscription = false) {
    if (restartSubscription) {
      this.navCtrl.navigateForward(
       CORE_ROUTES.SHOPPING_CREDIT, { animated: false }
      );
    } else {
      ACCOUNT_CLOSURE_DEACTIVATION.BUTTONS.CLOSE.handler = noop();
      ACCOUNT_CLOSURE_DEACTIVATION.BUTTONS.CONFIRM.handler = () => {
        this.httpService.post(PROFILE_APIS.CANCEL_DEACTIVATE_ACCOUNT, {}).subscribe(() => {
          this.masterDataService.masterData.user && (this.masterDataService.masterData.user.closed = UserAccountStatus.Active);
        });
      };
      this.alertService.presentAlertConfirm(
        GENERATE_CONTENT_FOR_ALERT_LAYER(ACCOUNT_CLOSURE_DEACTIVATION.META_DATA),
        [
          ACCOUNT_CLOSURE_DEACTIVATION.BUTTONS.CLOSE,
          ACCOUNT_CLOSURE_DEACTIVATION.BUTTONS.CONFIRM
        ],
        ACCOUNT_CLOSURE_DEACTIVATION.CSS_CLASS
      );
    }
  }

  deactivationAccount(dataToSend): Observable<object> {
    return this.httpService.post(PROFILE_APIS.DEACTIVATE_ACCOUNT, dataToSend);
  }

  getBankDetailsOrNot(): Observable<object> {
    return this.httpService.get(PROFILE_APIS.GET_BANK_DETAILS_OR_NOT, {});
  }

  logout(uuid: string): Observable<object> {
    return this.httpService.post(PROFILE_APIS.LOGOUT, {
      udid: this.dataService.uuid,
      addWhiteLoader: 'white-custom-loader'
    });
  }

  generateEmailOTP(email): Observable<object> {
    let dataToBeSent = { email: email };
    return this.httpService.post(
      PROFILE_APIS.GENERATE_EMAIL_OTP,
      dataToBeSent
    );
  }

  verifyEmailOTP(emailId, enteredOtp): Observable<object> {
    return this.httpService.post(PROFILE_APIS.VERIFY_EMAIL_OTP, {
      email: emailId,
      otp: enteredOtp
    });
  }
}