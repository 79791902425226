import { EventsService } from '../../../shared/providers/events/events.service';
import { UserDetailsComponent } from '@Shared/user-details/user-details.component';
import { empty, noop, Observable } from 'rxjs';
import { HttpService } from '../../../shared/providers/http/http.service';
import { PAYMENT_APIS } from '@Globals/constants/payment/apis';
import { InitiateTransactionDataToSend } from '@Globals/interfaces/payment/interfaces';
import { MasterDataService } from 'src/app/shared/providers/master-data/master-data.service';
import { AlertService } from 'src/app/shared/providers/alert/alert.service';
import { ALERT_BUTTON_TEXT, ANDROID, GENERATE_CONTENT_FOR_ALERT_LAYER, IOS, PAYMENT_CACHE, STORAGE_KEYS, USER_DETAIL_DEFAULT_VALUES } from '../../../shared/constants/constants';
import { Injectable, NgZone } from '@angular/core';
import { ToastService } from 'src/app/shared/providers/toast/toast.service';
import {
  CANCELLED_PAYMENT,
  EVENT_NAMES,
  EVENT_SOURCE,
  EVENT_SOURCES,
  FAIL_PAYMENT,
  FOOD_WALLET,
  INITIATE_PAYMENT,
  PAYMENT_STATUS,
  PENDING_PAYMENT,
  PG_WISE_JUSPAY_PAY_LOAD,
  JUSPAY_ERROR_MESSAGES,
  INITIATE_SDK,
  PROCESS_SDK,
  PRE_FETCH_SDK,
  TRANSACTION_FAILED,
  PAYMENT_SUCCESS,
  CRED_ELIGIBILITY,
  TWID_ELIGIBILITY,
  PAYMENT_VALUES,
  APPS_ELIGIBILITY,
  WALLET_MESSAGE,
  ADDITIONAL_CHARGES_TYPE,
  EVENT_ARGUMENTS,
  PAYMENT_GATEWAY,
  JIOPAY_ERROR_MESSAGES,
  PG_WISE_JIOPAY_PAY_LOAD
} from '@Globals/constants/payment/constants';

import { ModalController, NavController, Platform } from '@ionic/angular';
import { EventTrackingPlugins, UserDetailsModalSourceType } from 'src/app/shared/constants/enums';
import { DataService } from 'src/app/shared/providers/data/data.service';
import { Utilities } from 'src/app/shared/classes/utility/utilities';
import { HTTPResponse } from '@ionic-native/http';
import { ModalService } from 'src/app/shared/providers/modal/modal.service';
import { environment } from 'src/environments/environment';
import { UserAccountStatus } from 'src/app/profile/constants/profile-enums';
import { InAppBrowserService } from 'src/app/shared/providers/in_app_browser/in_app_browser.service';
import { LISTING_APIS } from 'src/app/listing/constants/listing-APIs';
import { TWID_ELIGIBILITY_EXPIRATION_TIME } from '../../../registration/constants/registration-constants';
import { APIs } from 'src/app/shared/constants/APIs';
import { LoggingService } from 'src/app/shared/providers/logging/logging.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { JuspayService } from 'src/app/shared/providers/juspay/juspay.service';
import * as moment from 'moment';
import { CORE_ROUTES } from '@Globals/constants/listing/routes';
import { PROFILE_APIS } from '@Globals/constants/profile/apis'; 
import { TopUpRange } from '@Globals/interfaces/payment/interfaces';
import { FoodWalletGuidelinesComponent } from '@Shared/payment/food-wallet-guidelines/food-wallet-guidelines.component';
import { WalletBreakupComponent } from '@Shared/payment/wallet-breakup/wallet-breakup.component';
import { AuthService } from '@Globals/providers/auth.service';
import { CashbackWalletGuidelinesComponent } from '@Shared/payment/cashback-wallet-guidelines/cashback-wallet-guidelines.component';
declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class ShoppingCreditsServices {
  public txnId: number;
  public trackPayment;
  public dataToSendForTrackPayment;
  public juspayErrorMessage: string;
  public backButtonSubscription;
  walletRefreshRequired: string;
  hyperSDKRef;
  topUpRange:TopUpRange;
  constructor (
    private httpService: HttpService,
    private masterDataService: MasterDataService,
    private alertService: AlertService,
    private zone: NgZone,
    private toastService: ToastService,
    private platform: Platform,
    private modalCtrl: ModalController,
    private eventService: EventsService,
    private dataService: DataService,
    private modalService: ModalService,
    private inAppBrowserService: InAppBrowserService,
    private navCtrl: NavController,
    private loggingService: LoggingService,
    private statusBar: StatusBar,
    private juspayService: JuspayService,
    private authService: AuthService,
  ) {}

  toastDismiss() {
    if (this.toastService.toast) {
      this.toastService.toast.dismiss();
    }
  }

  getJuspayProfile(methodValue = null, shoppingCreditAmount = 1) {
    this.httpService.get(APIs.JUSPAY_PROFILE, { hideLoader: true }).subscribe((response: HTTPResponse) => {
        if (response.data.status) {
          if (methodValue) {
            this.checkTwidEligibility(response.data.data, shoppingCreditAmount);
          } else {
            this.masterDataService.paymentOptionSection?.cred?.options?.length && this.checkCredEligibility(response.data.data.juspayOptions?.clientAuthToken);
          }
        }
      });
  }

  checkTwidEligibilityExpiration(shoppingCreditAmount, hardRefreshEligibility = false) {
    if (hardRefreshEligibility || this.masterDataService.isCacheExpired(STORAGE_KEYS.TWID_ELIGIBILITY_CACHE_EXPIRATION, TWID_ELIGIBILITY_EXPIRATION_TIME)) {
      this.masterDataService.paymentOptionSection?.redeemRewards?.options?.length && this.getJuspayProfile(PAYMENT_VALUES.TWID, shoppingCreditAmount); 
    }
  }

  checkTwidEligibility(clientDeatils, shoppingCreditAmount) {
    const twidPayload = Object.assign({}, TWID_ELIGIBILITY);
    twidPayload.requestId = this.dataService.uuid || window.device.uuid;
    twidPayload.payload.clientAuthToken = clientDeatils?.juspayOptions?.clientAuthToken;
    twidPayload.payload.amount = `${shoppingCreditAmount}`;
    twidPayload.payload.data.wallets[0].mobile = this.masterDataService.masterData?.user?.phone;
    twidPayload.payload.data.wallets[0].customerId = clientDeatils?.id;

    window.cordova.plugins["HyperSDKPlugin"].process(JSON.stringify(twidPayload));
    this.masterDataService.updateCacheExpirationTime(STORAGE_KEYS.TWID_ELIGIBILITY_CACHE_EXPIRATION);
  }

  checkEligibleApps() {
    const appEligibilityPayload = Object.assign({}, APPS_ELIGIBILITY);
    appEligibilityPayload.requestId = this.dataService.uuid || window.device.uuid;
    
    window.cordova.plugins["HyperSDKPlugin"].process(JSON.stringify(appEligibilityPayload));
  }

  preFetchHyperSdk() {
    const preFetchSdk = Object.assign({}, PRE_FETCH_SDK);
    preFetchSdk.payload.clientId += this.dataService.currentPlatform;
    window.cordova.plugins[ 'HyperSDKPlugin' ].preFetch(JSON.stringify({ preFetchSdk }));
    this.initiateSdk();
  }

  initiateSdk() {
    if (!this.masterDataService.preFetchInitiatedOrNot) {
      const initiateSdk = Object.assign({}, INITIATE_SDK);
      initiateSdk.requestId = this.dataService.uuid || window.device.uuid;
      initiateSdk.payload.clientId += this.dataService.currentPlatform;
      initiateSdk.payload.customerId = (this.masterDataService.masterData.customerExtProfiles?.length && this.masterDataService.masterData.customerExtProfiles[ 0 ].extId) || `JUSPAY_${this.masterDataService.masterData?.user?.id}`;
      window.cordova.plugins[ 'HyperSDKPlugin' ].initiate(JSON.stringify(initiateSdk), this.initiateSuccessCallback);
    }
  }

  initiateSuccessCallback = successResponse => {
    this.masterDataService.preFetchInitiatedOrNot = true;
    this.loggingService.logMessage('this.hyperSDKRef.initiate', successResponse);
    const parsedSuccessResponse = Utilities.parseJSONString(successResponse);
    if (parsedSuccessResponse?.payload?.action &&this.dataService.successCbResult &&
      this.dataService.successCbResult[`${parsedSuccessResponse.payload.action}Pending`]
    ) {
      this.dataService.successCbResult[parsedSuccessResponse?.payload?.action](
        successResponse
      );
      this.dataService.successCbResult[`${parsedSuccessResponse.payload.action}Pending`] = false;
      return;
    }
    this.toastDismiss();
    if (successResponse) {
      this.masterDataService.successResponse = successResponse.toString();
      this.masterDataService.successResponse = Utilities.parseJSONString(this.masterDataService.successResponse);
      this.loggingService.logMessage('initiateSuccessCallback', this.masterDataService.successResponse);
      if(this.masterDataService.successResponse && this.masterDataService.successResponse.event) {
      if (this.masterDataService.successResponse.event === 'initiate_result' && this.masterDataService.successResponse.payload && this.masterDataService.successResponse.payload.status && 
      (this.masterDataService.paymentOptionSection.cred.options.length || this.masterDataService.paymentOptionSection.redeemRewards.options.length)) {
        this.platform.is(ANDROID) && this.checkEligibleApps();
        this.getJuspayProfile();
      } else if (this.masterDataService.successResponse.event !== 'initiate_result' && this.masterDataService.successResponse.payload && this.masterDataService.successResponse.payload.status) {
        this.verifyExternalPaymentModePayment();
      } else if (this.masterDataService.successResponse?.payload && this.masterDataService.successResponse.payload?.action === 'upiTxn') {
        this.masterDataService.checkAppFeasibleForPayment(this.masterDataService.successResponse.payload?.availableApps);
      } else if (this.masterDataService.successResponse.payload && this.masterDataService.successResponse.payload.action === 'eligibility') {
        if (this.masterDataService.successResponse.payload.apps?.length && this.masterDataService.successResponse.payload.apps[0]?.paymentMethodsEligibility?.length && this.masterDataService.successResponse.payload.apps[0].paymentMethodsEligibility[0]?.paymentMethod === "CRED" ) {
          const credResponse = this.masterDataService.successResponse.payload.apps[0].paymentMethodsEligibility[0];
          if (credResponse?.isEligible) {
            this.addCredPaymentOffers(credResponse);
          } else {
            const offerName = this.masterDataService.paymentOptionSection?.cred?.options?.[0]?.option?.name;
            this.masterDataService.paymentOptionSection.cred.options = [];
            this.filterPaymentMethod(PAYMENT_VALUES.CRED);
            this.filterPaymentOffer(offerName);
          }
        } else {
          let twidResponse;
          this.masterDataService.successResponse?.payload?.wallets?.length && (twidResponse = this.masterDataService.successResponse.payload.wallets[0]?.paymentMethodsEligibility.find((method) => method.paymentMethod === "TWID"));
          this.loggingService.logMessage("twidResponse", twidResponse);
          if(twidResponse && twidResponse.isEligible && twidResponse.balance > 0) {
            this.updateTwidPaymentData(true, true, `${twidResponse.balance} points`);
          } else {
            this.updateTwidPaymentData(false);
          }
          this.dataService.runChangeDetForPaymentData.next(true);
        }
      }}
    }
  };

  filterPaymentOffer(offer) {
    this.masterDataService.paymentOffers = this.masterDataService.paymentOffers.filter((offers:any) => {
      return (offer !== offers.name);
   });
 } 

  addCredPaymentOffers(credResponse) {
    const offer = { offer: credResponse.layout.subText, offerDescription: null , source: 'cred',sortOrder:10000000 ,createdOn: moment().format('YYYY-MM-DD HH:mm:ss')};
    this.masterDataService.paymentOptionSection.cred.offers.push(offer);
    this.masterDataService.paymentOffers.push(offer);
  }

  filterPaymentMethod(methodName) {
    if(this.masterDataService.paymentOptions) {
      this.masterDataService.paymentOptions = this.masterDataService.paymentOptions.filter((method) => {
        return (methodName !== method.value);
      });
    }
  }
  

  updateTwidPaymentData(showTwidData, checkOfferVisibility = true, points = '') {
    if(this.masterDataService.paymentOptionSection?.redeemRewards) {
      this.masterDataService.paymentOptionSection.redeemRewards.visible = showTwidData;
    }
    if(this.masterDataService.paymentOptionSection?.redeemRewards?.options?.length && 
      this.masterDataService.paymentOptionSection?.redeemRewards?.options?.[0]?.option?.value in PG_WISE_JUSPAY_PAY_LOAD) {
        PG_WISE_JUSPAY_PAY_LOAD[this.masterDataService.paymentOptionSection?.redeemRewards.options[0].option.value].desc = points;
      }

    //Update payment option visibility
    const index = this.masterDataService.paymentOptions?.findIndex((method) => { return PAYMENT_VALUES.TWID === method.value; });
    if(index > -1) {
      this.masterDataService.paymentOptions[index].visible = showTwidData;
    }

    //Update offer visibility
    checkOfferVisibility && this.masterDataService.paymentOffers?.forEach((offer:any) => {
      if(offer.type === PAYMENT_VALUES.TWID) {
        offer.visible = showTwidData;
      }
    });
  }
  
  verifyExternalPaymentModePayment() {
    this.transactionQuery().subscribe(
      (paymentStatus: HTTPResponse) => {
        const tempSuccessResponse = this.masterDataService.successResponse ? this.masterDataService.successResponse : paymentStatus;
        const errorMessage = this.masterDataService.successResponse?.errorMessage || paymentStatus?.data?.error;
        this.loggingService.logMessage('tempSuccessResponse', tempSuccessResponse);
        this.loggingService.logMessage('errorMessage', errorMessage);
        if(!Utilities.isApp() && !tempSuccessResponse.data.hasOwnProperty('responseMessage')) {
          tempSuccessResponse.data['responseMessage'] = tempSuccessResponse.data.errorMsg;
        }
        if (paymentStatus.data) {
          switch (Utilities.isApp() ? tempSuccessResponse.payload?.status : tempSuccessResponse.data.responseMessage) {
            case JUSPAY_ERROR_MESSAGES.pendingVBV.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.pendingVBV.message, PENDING_PAYMENT, JUSPAY_ERROR_MESSAGES.pendingVBV.code, PAYMENT_STATUS.FAILED);
              break;
            case JUSPAY_ERROR_MESSAGES.authorizationFailed.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.authorizationFailed.message, FAIL_PAYMENT, JUSPAY_ERROR_MESSAGES.authorizationFailed.code, PAYMENT_STATUS.FAILED);
              break;
            case JUSPAY_ERROR_MESSAGES.authenticationFAILED.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.authenticationFAILED.message, FAIL_PAYMENT, JUSPAY_ERROR_MESSAGES.authenticationFAILED.code, PAYMENT_STATUS.FAILED);
              break;
            case JUSPAY_ERROR_MESSAGES.juspayDeclined.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.juspayDeclined.message, FAIL_PAYMENT, JUSPAY_ERROR_MESSAGES.juspayDeclined.code, PAYMENT_STATUS.FAILED);
              break;
            case JUSPAY_ERROR_MESSAGES.authorizing.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.authorizing.message, PENDING_PAYMENT, JUSPAY_ERROR_MESSAGES.authorizing.code, PAYMENT_STATUS.PENDING);
              break;
            case JUSPAY_ERROR_MESSAGES.new.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.genericFailedCase.message, FAIL_PAYMENT, JUSPAY_ERROR_MESSAGES.new.code, PAYMENT_STATUS.FAILED);
              break;
            case JUSPAY_ERROR_MESSAGES.started.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.started.message, PENDING_PAYMENT, JUSPAY_ERROR_MESSAGES.started.code, PAYMENT_STATUS.PENDING);
              break;
            case JUSPAY_ERROR_MESSAGES.autoRefunded.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.voided.message, FAIL_PAYMENT, JUSPAY_ERROR_MESSAGES.voided.code, PAYMENT_STATUS.FAILED);
              break;
            case JUSPAY_ERROR_MESSAGES.captureInitiated.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.voided.message, PENDING_PAYMENT, JUSPAY_ERROR_MESSAGES.voided.code, PAYMENT_STATUS.PENDING);
              break;
            case JUSPAY_ERROR_MESSAGES.captureFAILED.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.voided.message, FAIL_PAYMENT, JUSPAY_ERROR_MESSAGES.voided.code, PAYMENT_STATUS.FAILED);
              break;
            case JUSPAY_ERROR_MESSAGES.voidInitiated.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.voidInitiated.message, PENDING_PAYMENT, JUSPAY_ERROR_MESSAGES.voidInitiated.code, PAYMENT_STATUS.PENDING);
              break;
            case JUSPAY_ERROR_MESSAGES.voided.code:
              this.applyMembership(this.masterDataService.dataToSendForTrackPayment.amount, this.masterDataService.txnId);
              break;
            case JUSPAY_ERROR_MESSAGES.voidFailed.code:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.voidFailed.message, PENDING_PAYMENT, JUSPAY_ERROR_MESSAGES.voidFailed.code, PAYMENT_STATUS.PENDING);
              break;
            case JUSPAY_ERROR_MESSAGES.charged.code:
            case JUSPAY_ERROR_MESSAGES.webhookUpdated.code:
              this.applyMembership(this.masterDataService.dataToSendForTrackPayment.amount, this.masterDataService.txnId);
              break;
            default:
              // tslint:disable-next-line: max-line-length
              this.showJuspayErrorMessageAndTriggerEvent(errorMessage || JUSPAY_ERROR_MESSAGES.genericFailedCase.message, FAIL_PAYMENT, JUSPAY_ERROR_MESSAGES.genericFailedCase.code, PAYMENT_STATUS.FAILED);
              break;
          }
        }
        this.statusBar.overlaysWebView(true);
        this.statusBar.styleDefault();
      },
      (error) => {
        if (this.toastService.toast) {
          this.toastService.toast.dismiss();
        }
        this.statusBar.overlaysWebView(true);
        this.statusBar.styleDefault();
        // tslint:disable-next-line: max-line-length
        this.showJuspayErrorMessageAndTriggerEvent(this.juspayErrorMessage || JUSPAY_ERROR_MESSAGES.genericFailedCase.message, CANCELLED_PAYMENT, JUSPAY_ERROR_MESSAGES.genericFailedCase.code, PAYMENT_STATUS.FAILED);
      }
    );
  }

  getTransactionHistory(): Observable<object> {
    return this.httpService.get(LISTING_APIS.ACCOUNT_HISTORY, {});
  }

  applyInviteCode(data: any): Observable<object> {
    return this.httpService.post(APIs.APPLY_WELCOME_CODE, data);
  }

  getPaymentBanner(segmentIds): Observable<object> {
      return this.httpService.post(PAYMENT_APIS.PAYMENT_BANNER, { segmentIds: segmentIds, hideLoader: true });
  }

  updateAutoDebit(dataToSend, id): Observable<object> {
    return this.httpService.put(`${PAYMENT_APIS.UPDATE_AUTO_DEBIT}${id}`, dataToSend);
  }

  getBalance(): Observable<object> {
    const dataToSend = { hideLoader: true };
    return this.httpService.get(PAYMENT_APIS.BALANCE, dataToSend);
  }

  getPrioritizeAC(additionalCharges){
    let shownAdditionalCharges =  additionalCharges.find(charges => charges?.type === ADDITIONAL_CHARGES_TYPE.OTDF || charges?.type === ADDITIONAL_CHARGES_TYPE.MEMBERSHIP);
    if(!shownAdditionalCharges){
      shownAdditionalCharges =  additionalCharges.find(charges => charges?.type === ADDITIONAL_CHARGES_TYPE.INSUFF_NF_CREDITS);
    }
    return shownAdditionalCharges;
  }

  applyMembership(amount, transactionId: number) {
    amount = parseInt(amount, 10);
    let allActiveOffer = this.masterDataService.masterData?.wallet_increase_offers;
    allActiveOffer = allActiveOffer?.sort((firstEl, secondEl) => firstEl.amount - secondEl.amount);
    let activeMembership;
    allActiveOffer && allActiveOffer.forEach((ele) => {
      if (ele.amount === amount) {
        activeMembership = ele;
      } else if (amount > ele.amount) {
        activeMembership = ele;
      }
    });
    this.masterDataService.masterData.user.closed = UserAccountStatus.Active;
    if (activeMembership) {
      this.httpService.post(PAYMENT_APIS.APPLY_MEMBERSHIP, { membership_id: activeMembership.membership_id, hideLoader: true, transaction_id: transactionId }).subscribe((success: any) => {
        if (success.data.status) {
          this.masterDataService.masterData?.memberships.push(success.data.data);
          if (this.masterDataService.masterData?.current_membership && !this.masterDataService.masterData?.current_membership?.isMember) {
            this.masterDataService.masterData.current_membership = success.data.data;
          }
          this.toastService.presentToast(Utilities.replaceKeysWithValues(this.dataService.firebaseMasterData.paymentOffers.successMessage, activeMembership));
          this.masterDataService.dataToSendForTrackPayment.source = EVENT_SOURCE.PAYMENT_OFFERS;
          if (Utilities.isApp()) {
            this.masterDataService.trackPayment && this.masterDataService.trackPayment(
              PAYMENT_STATUS.SUCCESS,
              this.masterDataService.txnId,
              this.masterDataService.dataToSendForTrackPayment
            );
          } else {
            this.getBalanceData();
          }
          this.masterDataService.masterData.wallet_increase_offers = [];
          this.dataService.firebaseMasterData.paymentOffers = null;
          this.masterDataService.homeStripGeneration();
        }
      });
    } else {
      if (Utilities.isApp()) {
        this.masterDataService.trackPayment && this.masterDataService.trackPayment(
          PAYMENT_STATUS.SUCCESS,
          this.masterDataService.txnId,
          this.masterDataService.dataToSendForTrackPayment
        );
      }
      this.getBalanceData();
    }
  }

  initiateTransaction(
    dataToSend: InitiateTransactionDataToSend
  ): Observable<object> {
    return this.httpService.post(
      PAYMENT_APIS.INITIATE_TRANSACTION,
      dataToSend,
      undefined,
      false
    );
  }

  getBalanceData(showAlertForBalanceUpdate = true) {
    this.getBalance().subscribe((success: any) => {
      if (success && success.data.status) {
        this.zone.run(() => {
          this.masterDataService.masterData.balance = success.data.data;
          this.masterDataService.masterData.walletInfo = success.data.walletInfo;
          // adding subscription to update balance in react pages
          if (showAlertForBalanceUpdate) {
            const message = JSON.parse(JSON.stringify(PAYMENT_SUCCESS));
            const walletMsg = (this.masterDataService.dataToSendForTrackPayment?.pg === PG_WISE_JUSPAY_PAY_LOAD.SODEXO.pgName || this.masterDataService.dataToSendForTrackPayment?.pg === PG_WISE_JUSPAY_PAY_LOAD.saved_SODEXO.pgName || this.masterDataService.dataToSendForTrackPayment?.pg === PG_WISE_JIOPAY_PAY_LOAD.SODEXO.pgName) ? WALLET_MESSAGE.FOOD_INFO : WALLET_MESSAGE.NON_FOOD_INFO;
            message.META_DATA.MESSAGE = walletMsg.replace('##balance##', this.masterDataService.dataToSendForTrackPayment?.amount);
            if (!Utilities.isApp() && this.toastService.toast) {
              this.toastService.toast.dismiss();
            }
            message.BUTTONS.CLOSE.handler = noop();
            this.alertService.presentAlertConfirm(
              GENERATE_CONTENT_FOR_ALERT_LAYER(
                message.META_DATA
              ),
              [
                message.BUTTONS.OKAY,
                message.BUTTONS.CLOSE
              ],
              message.CSS_CLASS
            );
            if (!Utilities.isApp()) {
              setTimeout(() => {
                this.navCtrl.navigateForward('/' + CORE_ROUTES.SHOPPING_CREDIT);
              }, 2000);
            }
            this.trackFirstPaymentAndAskForProfileDetailsIfRequired(
              UserDetailsModalSourceType.ShoppingCreditsPage
            );
          }
        });
      }
    });
  }

  getWalletEventSource(source){
    let eventSource;
    switch (source) {
      case EVENT_SOURCE.PAYMENT_PAGE:
        eventSource = EVENT_SOURCE.PAYMENT_PAGE;
        break;
      case EVENT_SOURCE.SHOPPING_CREDIT_PAGE:
        eventSource = EVENT_SOURCE.WB_SHOPPING_CREDIT_PAGE;
        break;
      case EVENT_SOURCE.OUT_OF_SHOPPING_CREDIT_POPUP:
        eventSource = EVENT_SOURCE.WB_OUT_OF_SHOPPING_CREDIT_POPUP;
        break;
      case EVENT_SOURCE.TRANSACTION_PAGE:
        eventSource = EVENT_SOURCE.WB_TRANSACTION_PAGE;
        break;
      case EVENT_SOURCE.CART_PAGE:
        eventSource = EVENT_SOURCE.CART_PAGE;
        break;
    }
    return eventSource;
  }

  async openFW_Guidelines(source) {
    const modal = await this.modalCtrl.create({
      component: FoodWalletGuidelinesComponent,
      cssClass: 'modal-bottom-sheet wallet-usage-wrap',
    });
    await modal.present();
    this.eventService.trackEvents(EVENT_NAMES.FOOD_WALLET_EDUCATION, {
      Source: this.getWalletEventSource(source),
      'User Id': this.masterDataService.masterData?.user?.id,
    });
  }

  async openCashbackWalletGuidelines(source) {
    const modal = await this.modalCtrl.create({
      component: CashbackWalletGuidelinesComponent,
      cssClass: 'modal-bottom-sheet cashback-wallet-modal-wrap',
    });
    await modal.present();
    this.eventService.trackEvents(EVENT_NAMES.CASHBACK_WALLET_EDUCATION, {
      Source: this.getWalletEventSource(source),
      'User Id': this.masterDataService.masterData?.user?.id,
    });
  }

  async openWalletBreakupPrompt(eventSource) {
    const modal = await this.modalCtrl.create({
      component: WalletBreakupComponent,
      cssClass: 'modal-bottom-sheet wallet-breakup-wrap auto-height',
      componentProps: { eventSource }
    });
    await modal.present();
    this.eventService.trackEvents(EVENT_NAMES.WALLET_BREAKUP, {
      Source: eventSource,
      'User Id': this.masterDataService.masterData?.user?.id,
    });
  }

  async openUserDetailsModal(userDetailsModalSourceType?){
    const modal = await this.modalCtrl.create({
      component: UserDetailsComponent,
      componentProps: { userDetailsModalSourceType },
      cssClass: 'auto-height modal-bottom-sheet',
    });
    await modal.present();
  }
  
  // This method is used to show user details prompt if user paid is 0 and it doesn't contains milkbasket in mail address.
  async trackFirstPaymentAndAskForProfileDetailsIfRequired(userDetailsModalSourceType) {
    if(this.masterDataService?.masterData?.user){
    if (this.masterDataService.masterData.user.paid === 0) {
      if (this.masterDataService.masterData.user.name && this.masterDataService.masterData.user.name.includes(
        USER_DETAIL_DEFAULT_VALUES.FIRSTNAME
      )) {
        this.openUserDetailsModal(userDetailsModalSourceType);
      }
      this.eventService.trackEvents(EVENT_NAMES.FIRST_PAYMENT, {...this.masterDataService.dataToSendForTrackPayment , af_revenue : this.masterDataService.dataToSendForTrackPayment.amount, af_currency: 'INR'}, false);
      this.masterDataService.masterData.user.paid = 1;
      this.masterDataService.masterData.welcome_code && (this.masterDataService.masterData.welcome_code.detail = null);
      this.masterDataService.refreshBalance.next(PAYMENT_CACHE.ALL);
      this.dataService.runChangeDetForPromo.next(true);
      this.dataService.updateCollection.next(true);
      this.getTopUp();
    } else {
      this.masterDataService.refreshBalance.next(PAYMENT_CACHE.WITHOUT_INVITE);
    }
  }
  }

  transactionQuery(): Observable<object> {
    const dataToSend:any = {
      txn_id: this.masterDataService.txnId,
      pg: this.masterDataService.dataToSendForTrackPayment?.pg,
      version: environment.codePushVersion
    };
    if(this.masterDataService.dataToSendForTrackPayment?.pg === PAYMENT_VALUES.CRED && this.masterDataService.paymentOptionSection.cred?.offers?.length){
      const offerText = this.getCredOffer();
      offerText && (dataToSend.external_offer = offerText.offer);
    }
    return this.httpService.post(
      PAYMENT_APIS.TRACTION_QUERY,
      dataToSend,
      undefined,
      false
    );
  }

  findingErrorMsg(errorResponse) {
    const error = JSON.parse(errorResponse.response);
    this.juspayErrorMessage = error.response && error.response.errorMessage && error.response.errorMessage;
  }


  // Common method to show error toast and trigger respective event with data.
  showJuspayErrorMessageAndTriggerEvent(paymentErrorSubMessage, paymentErrorMessageDiv, errorStatus, paymentStatus) {
    // Added a tempVariable to stop the original Reference change and let the scope of paymentErrorMessageDiv inside the function.
    this.loggingService.logMessage('showJuspayErrorMessageAndTriggerEvent', paymentErrorSubMessage);
    const tempPaymentErrorMessageDiv =JSON.parse(JSON.stringify(TRANSACTION_FAILED));
    this.masterDataService.dataToSendForTrackPayment.errorStatus = errorStatus;
    this.masterDataService.dataToSendForTrackPayment.errorMessage = this.juspayErrorMessage || '';
    tempPaymentErrorMessageDiv.META_DATA.MESSAGE = tempPaymentErrorMessageDiv.META_DATA.MESSAGE.replace('##ERROR_MESSAGE##', paymentErrorSubMessage);
    if (!Utilities.isApp() && this.toastService.toast) {
      this.toastService.toast.dismiss();
    }
    this.alertService.presentDismissAlert(
      GENERATE_CONTENT_FOR_ALERT_LAYER(
        tempPaymentErrorMessageDiv.META_DATA
      ),
      [
        tempPaymentErrorMessageDiv.BUTTONS.OKAY
      ],
      tempPaymentErrorMessageDiv.CSS_CLASS
    );
    if (Utilities.isApp()) {
      this.masterDataService.trackPayment(paymentStatus, this.txnId, this.masterDataService.dataToSendForTrackPayment);
    } else {
      setTimeout(() => {
        this.navCtrl.navigateForward('/' + CORE_ROUTES.SHOPPING_CREDIT);
      }, 2000);
    }
    this.eventService.trackEvents(paymentStatus, { ...{ txnId: this.masterDataService.txnId, errorMessage: paymentErrorSubMessage }, ...this.masterDataService.dataToSendForTrackPayment });
  }

  transactionProcess(dataToSend, trackPayment) {
    if (this.dataService.firebaseMasterData.infoPopUp && this.dataService.firebaseMasterData.infoPopUp.location.shoppingCredit) {
      if (this.dataService.firebaseMasterData.infoPopUp.isThrottle) {
        if (this.masterDataService.masterData?.user?.throttle_status) {
          this.modalService.infoPopUp(EVENT_SOURCES.SHOPPING_CREDIT);
          return empty();
        }
      } else {
        this.modalService.infoPopUp(EVENT_SOURCES.SHOPPING_CREDIT);
        return empty();
      }
    }
    this.dataToSendForTrackPayment = dataToSend;
    this.masterDataService.trackPayment = trackPayment;
    let pg = dataToSend.pg;
    if (pg.includes('saved_')) {
      pg = pg.split('saved_')[ 1 ];
    }
    const dataToSendToInitiate = {
      ...dataToSend,
      ...{ source: this.dataService.currentPlatform, pg, version: environment.codePushVersion, 'PaymentGateway': PAYMENT_GATEWAY.JUSPAY, hideLoader:true }
    };
    dataToSendToInitiate.udid = this.dataService.uuid;
    if (dataToSend.pg === PG_WISE_JUSPAY_PAY_LOAD.SODEXO.pgName || dataToSend.pg === PG_WISE_JUSPAY_PAY_LOAD.EDENRED.pgName
      || dataToSend.pg === PG_WISE_JUSPAY_PAY_LOAD.saved_SODEXO.pgName || dataToSend.pg === PG_WISE_JUSPAY_PAY_LOAD.saved_EDENRED.pgName) {
      dataToSend.amount = parseInt(dataToSend.amount, 10);
      dataToSend.amount = dataToSend.amount + (dataToSend.amount * FOOD_WALLET.processingFeePercentage.calculate);
    }
    if (this.platform.is(IOS) && dataToSend.pg === PG_WISE_JUSPAY_PAY_LOAD.google_pay.pgName) {
      dataToSendToInitiate.method = dataToSend.pg;
    }
    this.dataToSendForTrackPayment = dataToSendToInitiate;
    this.toastService.presentToast(
      INITIATE_PAYMENT.message,
      INITIATE_PAYMENT.class,
      INITIATE_PAYMENT.position,
      100000
    );
    this.statusBar.overlaysWebView(false);
    this.statusBar.backgroundColorByName('white');
    this.statusBar.styleDefault();
    this.initiateTransaction(dataToSendToInitiate).subscribe((response: any) => {
      this.dataToSendForTrackPayment.pg = dataToSend.pg;
      if (response.data && response.data.status) {
        const responseForJuspay = Utilities.parseJSONString(response.data.data);
        this.eventService.trackEvents(EVENT_NAMES.INITIATE_TRANSACTION_SUCCESS, responseForJuspay, false, EventTrackingPlugins.All);
        this.txnId = responseForJuspay.orderId;
        this.masterDataService.dataToSendForTrackPayment = Object.assign({}, this.dataToSendForTrackPayment);
        this.masterDataService.txnId = responseForJuspay.orderId;
        if (Utilities.isApp()) {
          this.processSdk(responseForJuspay)
        } else {
          const webPayLoad = PG_WISE_JUSPAY_PAY_LOAD[ dataToSend.pg ].webPayload;
          if (dataToSend.pg === PG_WISE_JUSPAY_PAY_LOAD.google_pay.pgName) {
            webPayLoad.mobile_number = this.masterDataService.masterData?.user?.phone?.toString();
          }
          webPayLoad.order_id = responseForJuspay.orderId;
          if (dataToSend.pg === PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName || dataToSend.pg === PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName || dataToSend.pg === PG_WISE_JUSPAY_PAY_LOAD.SODEXO.pgName) {
            this.paymentForCards(responseForJuspay);
          } else {
            this.paymentByUrl(webPayLoad);
          }
        }
      } else {
        this.eventService.trackEvents(EVENT_NAMES.INITIATE_TRANSACTION_FAILURE, response, false, EventTrackingPlugins.All);
        if (this.toastService.toast) {
          this.toastService.toast.dismiss();
        }
        this.alertService.presentAlert(response.data.error_msg, [
          ALERT_BUTTON_TEXT
        ]);
      } 
    }, (error) => {
      this.eventService.trackEvents(EVENT_NAMES.INITIATE_TRANSACTION_FAILURE, error, false, EventTrackingPlugins.All);
      if (this.toastService.toast) {
        this.toastService.toast.dismiss();
      }
    });
  }

  getCredOffer() {
      return this.masterDataService.paymentOptionSection.cred.offers.find(offer => offer['source'] === PAYMENT_VALUES.CRED);
  }

  checkCredEligibility(clientAuthToken) {
   const credPayload = Object.assign({}, CRED_ELIGIBILITY);
   credPayload.requestId =  this.dataService.uuid || window.device.uuid;
   credPayload.payload.clientAuthToken = clientAuthToken;
   credPayload.payload.amount = this.masterDataService.masterData?.topup?.min_amount || '250'
   credPayload.payload.data.apps[0].mobile = this.masterDataService.masterData?.user?.phone;
   window.cordova.plugins[ 'HyperSDKPlugin' ].process(JSON.stringify(credPayload));
}

  processSdk(responseForJuspay) {
    this.txnId = responseForJuspay.orderId;
    const processSdk = Object.assign({}, PROCESS_SDK);
    processSdk.requestId = this.dataService.uuid || window.device.uuid;
    processSdk.payload.customerId = responseForJuspay.customerId;
    processSdk.payload.clientId += this.dataService.currentPlatform;
    processSdk.payload.orderId = responseForJuspay.orderId;
    processSdk.payload.clientAuthToken = responseForJuspay.juspayOptions?.clientAuthToken;
    processSdk.payload = { ...processSdk.payload, ...PG_WISE_JUSPAY_PAY_LOAD[ this.masterDataService.dataToSendForTrackPayment.pg ].payload };
    if(this.dataToSendForTrackPayment.pg === PG_WISE_JUSPAY_PAY_LOAD.cred.pgName) {
      processSdk.payload['walletMobileNumber'] = this.masterDataService.masterData?.user?.phone;
    }
    this.loggingService.logMessage('processSdk', processSdk);
    window.cordova.plugins[ 'HyperSDKPlugin' ].process(JSON.stringify(processSdk));
    this.registerBackEvent();
  }

  registerBackEvent() {
    document.addEventListener('backbutton', () => {
      window.cordova.plugins[ 'HyperSDKPlugin' ].onBackPress((response) => {
        // if response true HyperSDK will handle else app can handle backpress
      });
    }, false);
  }

  processSuccessCallback = successResponse => {
    this.loggingService.logMessage('this.hyperSDKRef.process', successResponse);
    this.toastDismiss();
  };

  paymentByUrl(dataToSend) {
    this.httpService.post(PAYMENT_APIS.PAYMENT_BY_URL, dataToSend).subscribe((response: any) => {
      if (response.data && response.data.status) {
        localStorage.setItem(STORAGE_KEYS.TRANSCATION_ID, JSON.stringify(this.masterDataService.txnId));
        localStorage.setItem(STORAGE_KEYS.TRANSACTION_DATA, JSON.stringify(this.masterDataService.dataToSendForTrackPayment));
        this.inAppBrowserService.openPaymentUrl(response.data.data.url);
      }
    });
  }

  refreshWallets() {
    const paymentOption = this.masterDataService.paymentOptions.find((method) => method.walletId === this.walletRefreshRequired);
    this.juspayService.refreshWallet({ walletId: this.walletRefreshRequired }).subscribe((response: HTTPResponse) => {
        if (response && response.data.status && response.data.data.token) {
          paymentOption.token = response.data.data.token;
          paymentOption.balance = response.data.data.current_balance;
          this.walletRefreshRequired = null;
          paymentOption.value === PAYMENT_VALUES.TWID && this.getJuspayProfile(PAYMENT_VALUES.TWID);
        }
      });
  }

  paymentForCards(responseForJuspay) {
    localStorage.setItem(STORAGE_KEYS.TRANSCATION_ID, JSON.stringify(this.masterDataService.txnId));
    localStorage.setItem(STORAGE_KEYS.TRANSACTION_DATA, JSON.stringify(this.masterDataService.dataToSendForTrackPayment));
    this.inAppBrowserService.openPaymentUrl(responseForJuspay.paymentLinks.webLink);
  }

  executeUrlPayment(url, dataToSend?, trackPayment?) {
    if(dataToSend) {
      this.masterDataService.trackPayment = trackPayment;
      this.inAppBrowserService.openJioPayPaymentUrl(url);
    } else {
      this.inAppBrowserService.openPaymentUrl(url);
    }
    const loadCallback = event => {
      if (event.url.indexOf('milkbasket.com/store') > -1) {
        const urlSegments = event.url.split('/');
        const paymentResponse = urlSegments[ urlSegments.length - 1 ];
        const txnId = urlSegments[ urlSegments.length - 2 ];
        if (
          paymentResponse !== undefined &&
          paymentResponse !== '' &&
          txnId !== undefined &&
          txnId !== ''
        ) {
          // this.masterDataService.trackPayment(paymentResponse, txnId);
          if (this.toastService.toast) {
            this.toastService.toast.dismiss();
          }
        }
        this.inAppBrowserService.close();
      } else if (event.url.indexOf(environment.jiopayCallbackURL) > -1) {
        const queryString = event.url.split('?')[1];
        const params = new URLSearchParams(queryString);
        const success = params.get('success');
        const transactionId = params.get('transactionId');
        const pg = params.get('PG')?.replace('%20', '_');
        const dataToSendToInitiate = { ...dataToSend, ...{ pg : pg}};
        this.masterDataService.dataToSendForTrackPayment = Object.assign({}, dataToSendToInitiate);
        this.masterDataService.txnId = Number.parseInt(transactionId.replace('TR-',''));
        this.txnId = this.masterDataService.txnId;
        if (
          success !== undefined &&
          success === 'true' &&
          transactionId !== undefined &&
          transactionId !== ''
        ) {
          if (this.toastService.toast) {
            this.toastService.toast.dismiss();
          }
          this.applyMembership(this.masterDataService.dataToSendForTrackPayment.amount, this.masterDataService.txnId);
        } else {
          this.showJuspayErrorMessageAndTriggerEvent(JIOPAY_ERROR_MESSAGES.genericFailedCase.message, CANCELLED_PAYMENT, JIOPAY_ERROR_MESSAGES.genericFailedCase.code, PAYMENT_STATUS.FAILED);
        }
        this.inAppBrowserService.close();
      }
    };
    this.inAppBrowserService.eventSubscription('loadstart').subscribe(event => {
      loadCallback(event);
    });
    this.inAppBrowserService.eventSubscription('message').subscribe(event => {
      if (event && event['data'] && event['data']['eventtype'] && event['data']['eventtype'] == "webjs") {
        switch (event['data']['type']) {
          case "getPspAppsList":
            this.getPspAppsList();
            break;
          case "launchPspAppForUpiPayment":
            this.launchPspAppForUpiPayment(event['data']['params']);
            break;
        }
      }
    });
  }

  getPspAppsList() {
    const successCallback = (apps) => {
      this.inAppBrowserService.sendMessage("pspAppsResponseList("+JSON.stringify(apps)+")");
    }
    
    const failureCallback = (err) => {};

    window['UPI'].supportedApps(successCallback, failureCallback);
  }

  launchPspAppForUpiPayment(upivalue) {
    const appConfig = upivalue.split("|")[0]
    const upiconfig = upivalue.split("|")[1]
    const successCallback = (data) => {};
    
    const failureCallback = (err) => {
      this.showJuspayErrorMessageAndTriggerEvent(JIOPAY_ERROR_MESSAGES.genericFailedCase.message, CANCELLED_PAYMENT, JIOPAY_ERROR_MESSAGES.genericFailedCase.code, PAYMENT_STATUS.FAILED);
    }

    window['UPI'].acceptPayment(upiconfig, appConfig, successCallback, failureCallback);
  }

  checkJiopaySegment() { 
    this.masterDataService.isJioPayEnabled = false;
    if(this.dataService.firebaseMasterData?.jiopaySegment?.enabledForAll) {
      this.masterDataService.isJioPayEnabled = true;
    } else if(this.dataService.firebaseMasterData?.jiopaySegment?.segments?.length) {
      this.masterDataService.getSegmentsIds().then((segmentIds: any)=>{
        segmentIds.forEach(segment => {
          if(this.dataService.firebaseMasterData.jiopaySegment.segments.includes(segment)) {  
            this.masterDataService.isJioPayEnabled = true;
          }
        });
      });
    }
  }

  async openPaymentOptionsModalJio(amount,trackPayment) {
    const dataToSend = {
      amount: amount,
      pg: PAYMENT_GATEWAY.JIOPAY,
      udid: this.dataService.uuid,
      source: this.dataService.currentPlatform,
      'PaymentGateway': PAYMENT_GATEWAY.JIOPAY,
      version: environment.codePushVersion,
    };
    this.httpService.post(PAYMENT_APIS.INITIATE_TRANSACTION_JIO, dataToSend).subscribe((response: HTTPResponse) => {
      if(response?.data?.status && response.data.data) {
        this.eventService.trackEvents(EVENT_NAMES.INITIATE_TRANSACTION_SUCCESS, {}, false, EventTrackingPlugins.All);
        this.executeUrlPayment(response.data.data, dataToSend, trackPayment);
      } else {
        this.eventService.trackEvents(EVENT_NAMES.INITIATE_TRANSACTION_FAILURE, response, false, EventTrackingPlugins.All);
        if (this.toastService.toast) {
          this.toastService.toast.dismiss();
        }
        this.alertService.presentAlert(response.data.error_msg, [
          ALERT_BUTTON_TEXT
        ]);
      } 
    }, (error) => {
      this.eventService.trackEvents(EVENT_NAMES.INITIATE_TRANSACTION_FAILURE, error, false, EventTrackingPlugins.All);
    });
  }

  getTopUp(){
 
    return new Promise((resolve)=>{
      let topup_segmentsIds = null;
      this.masterDataService.getCustomerSegmentsGroupBy().subscribe((segments:any[])=>{      
        if(segments?.[7]?.length) {
          topup_segmentsIds = segments[7].map(segment => segment.segmentId);
        } else {
          topup_segmentsIds = [];
        }
        const topup_data = {
          credits: this.masterDataService.masterData?.balance ? this.masterDataService.masterData.balance : null,
          paid: this.masterDataService.masterData?.user?.paid,
          segmentIds: topup_segmentsIds,
          city_id: this.authService.userData.cityObj ? this.authService.userData.cityObj.id : '',
          version: environment.codePushVersion,
          source: this.dataService.currentPlatform
        };
        this.httpService.post(PAYMENT_APIS.TOP_UP,topup_data).subscribe((response:any)=>{
          if(response?.data?.data && response.data.status){
            this.topUpRange = response.data.data;
            this.topUpRange.maxLength = this.topUpRange.max_amount.length + 1;
            this.topUpRange.auto_debit.maxLength = Math.floor(Math.log(this.topUpRange.auto_debit.max_amount + 1) / Math.LN10 + 2);
            this.topUpRange.auto_debit.rechargeMaxLength = Math.floor(Math.log(this.topUpRange.auto_debit.max_recharge_amount + 1) / Math.LN10 + 2);
            resolve(parseInt(this.topUpRange.amount,10));
          }
        })
      });
  
  });
  }
  

  setUpPaymentDetails() {
    this.txnId = Utilities.parseJSONString(localStorage.getItem(STORAGE_KEYS.TRANSCATION_ID));
    this.dataToSendForTrackPayment = Utilities.parseJSONString(localStorage.getItem(STORAGE_KEYS.TRANSACTION_DATA));
    localStorage.removeItem(STORAGE_KEYS.TRANSCATION_ID);
    localStorage.removeItem(STORAGE_KEYS.TRANSACTION_DATA);
    localStorage.removeItem(STORAGE_KEYS.PAYMENT_STATUS);
    this.verifyExternalPaymentModePayment();
  }

  getReferralStatus(data){
    return this.httpService.post(PROFILE_APIS.REFERRAL_STATUS,data);
  }

}
