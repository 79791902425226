//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion Angular Imports
//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
import { DealsOfTheDayModule } from '@Features/product-list/components/horizontal/deals-of-the-day/deals-of-the-day.module';
//#endregion Module Imports

//#region Component Imports
import { DealsOfTheDayProductsListComponent } from '@Widgets/blocks/collection/deals-of-the-day/deals-of-day-products-list.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DealsOfTheDayModule
  ],
  exports: [DealsOfTheDayProductsListComponent],
  declarations: [DealsOfTheDayProductsListComponent]
})
export class DealsOfTheDayProductsListModule extends DefaultModule { }