//#region Imports

//#region Angular Imports
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from '@Widgets/blocks/collection/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { Collections } from '@Globals/interfaces/listing/interfaces';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-seven-grid-collection',
  templateUrl: './seven-grid-collection.component.html',
  styleUrls: ['./seven-grid-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SevenGridCollectionComponent extends BaseCollectionComponent {
  @Input() collections: Collections;
  @Output() navigate = new EventEmitter();
  @Input() showDefaultImage = true;

  triggerCounterChange(column) {
    this.navigate.emit({ column });
  }
}
