//#region Imports

//#region Angular Imports
import { Component, Input, OnChanges } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthService } from '@Globals/providers/auth.service';
import { ProductListManagerService } from '@Features/product-list/providers/product-list-manager.service';
//#endregion Providers Imports

//#region Other Imports
import { SimilarProducts } from '@Globals/interfaces/listing/interfaces';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mb-pl-two-row',
  templateUrl: './two-row.component.html',
  styleUrls: [ '../../common.scss', './two-row.component.scss' ],
  providers: [ ProductListManagerService ]
})
export class TwoRowComponent implements OnChanges {
  @Input() products: any;
  @Input() eventData?: any;
  productList1: Array<any>;
  productList2: Array<any>;

  basketIndex;

  recommendedProductList: SimilarProducts = {
    product: {
        id: 0
    },
    title: 'Suggestions for you'
  };

  constructor(
    private authService: AuthService,
    private plmService: ProductListManagerService
  ) { }

  ngOnInit() {
    this.products = this.plmService.processProductsData(this, this.products);
    this.plmService.setupTomorrowBasket();
    !this.basketIndex && (this.basketIndex = 0);
    !this.authService.isLoggedIn() && (this.authService.userData.tomorrowBasket = {});
  }

  ngOnChanges() {
    this.products = this.plmService.processProductsData(this, this.products);
    if (this.products && this.products.length) {
      const slicedProducts = this.products.length > 12 ? this.products.slice(0, 12) : this.products;
      const half_length = Math.ceil(slicedProducts.length / 2);
      this.productList1 = slicedProducts.splice(0, half_length);
      this.productList2 = slicedProducts;
    }
  }

  successHandlerForRecommendedProducts = (productList, product) => {
    this.recommendedProductList.data = productList;
    this.recommendedProductList.product = product;
  }
}
