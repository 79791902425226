export {
    SIGNUP_ERRORS,
    REGISTRATION_CONSTANTS,
    ADDRESS_VALIDATION_TYPE,
    EMPTYCART_ALERT,
    MY_LOCATION,
    PROMOCODE_TYPES,
    INPUT,
    INPUT_VALUE,
    SIGNUP_ERROR_MESSAGES,
} from '@App/registration/constants/registration-constants';

export const EVENT_NAMES = {
    ENABLE_LOCATION_CLICKED: 'Click on Enable location',
    SOCIETY_SEARCHED: 'Society Searched',
    LOCATION_ALLOWED: 'Click on location allow',
    LOCATION_DENIED: 'Click on location deny',
    SIGNUP_CITY_NOT_SERVICEABLE: 'Sign up - City Not Serviceable',
    MARKER_SET: 'Location marker - set',
    MARKER_DRAGGED: 'Location-marker - dragged',
    SOCIETY_CONFIRMED: 'Society-confirmed',
    SIGNUP_SOCIETY_SELECTED: 'Signup Society Selected',
    ADD_ADDRESS: 'Click on Add address',
    HUB_CHANGE_ALERT: 'Address update - Hub/city change alert presented',
    HUB_CHANGE_CONFIRM: 'Address update - Hub/city change confirmed',
    HUB_CHANGE_DENIED: 'Address update - Hub/city change denied',
    ADDRESS_UPDATED: 'Address update - complete',
    SIGNUP_CODE_APPLIED: 'Signup - Code applied',
    SIGNUP_COMPLETE: 'Signup Complete',
    TOWER_SEARCHED: 'Tower searched',
    CHANGE_SOCIETY: 'Click on change society',
    LOCATION_SCREEN_VISIT: 'Enable location screen visit',
    WANT_TO_EXPLORE: 'Want to explore Start',
    GENERATE_SIGN_UP_OTP: 'Generate Sign-up otp',
    GENERATE_LOGIN_OTP: 'Generate login otp',
    OTP_TRIGGER: 'OTP trigger',
    OTP_AUTO_READ: 'OTP auto read',
    OTP_VERIFIED: 'OTP verified',
    RESEND_OTP: 'Click on resend OTP',
    SIGNUP_SPLASHSCREEN_VIEWED: 'Sign up - Splash Screen Viewed',
    CLICK_ON_TNC: 'Click on Terms of Service',
    GET_STARTED: 'Click on get started',
    REFERRER_STATUS:'Referrer_status',
    REQUEST_FOR_ADDRESS_CLICK:'Request for address click',
    TOWER_AND_FLAT_PAGE_VISIT:'Tower & Flat page visit',
    SUBMIT_CLICK:'Submit click',
    CONTINUE_CLICK:'Continue click',
    RE_ENTER_DETAILS_CLICK:'Re-enter details click'
}

export const EVENT_SOURCES = {
    SIGNUP_SOCIETY_PAGE_VIEW: 'Signup - Society PageView',
    ADD_ADDRESS: 'Click on Add address',
    LOCATION_ACCESS: 'location-access-page',
    SIGNUP: 'signup',
    TOWER_SELECTION: 'Tower selection page',
    SOCIETY_CONFIRMATION: 'Society confirmation page',
    MOBILE_SCREEN: 'Mobile number page',
    GOOGLE_AUTOCOMPLETE: 'Google autocomplete'
};
export const SUSPENDED_USER = `generate_login_otp_error_account_suspended`;
export const USER_DOES_NOT_EXIST = "generate_login_otp_error";

export const AddressStatus = {
    Valid : 1,
    UnderVerification: 2,
    Rejected:3,
    IntentCaptured:4,
}
export const SIGNUP_INTRO_ITEMS = [
    {
        imgUrl: 'assets/img/signup-spalsh-img1@2x.png',
        heading: 'Order till midnight',
        subHeading: 'Get your essentials delivered <br>to you by 7 AM',
        visited: false
    },
    // {
    //     imgUrl: 'assets/img/signup-spalsh-img2@2x.png',
    //     heading: 'No minimum order',
    //     subHeading: 'Want a lemon? You got it!<br> One bar of soap? Sure',
    //     visited: false
    // },
    {
        imgUrl: 'assets/img/signup-spalsh-img3@2x.png',
        heading: 'Seamless Ordering!',
        subHeading: 'Simply add items throughout the day & receive it the next morning',
        visited: false
    }
];

export const BRAND_LOGO = 'assets/img/mb-logo.svg';
export const CAMPAIGN_SHOPPING_ADS_URL = "https://milkbasket.onelink.me/obgF/r2o8ogqd";
