  export const MEMBERSHIP_APIS = {
    GET_MEMBERSHIP_PLANS: 'user/membership',
    BUY_MEMBERSHIP: 'user/buy-membership',
    USER_NPS_RATING:'nps-survey',
    CUSTOMER_SAVING:'user/saving',
    UPDATE_MEMBERSHIP:'user/update-membership',
    BILL_DETAILS:'home/basket',
    CUSTOMER_MEMBERSHIP:'user/current-membership',
    UPDATE_AUTO_RENEW_MEMBERSHIP:'user/renew-membership',
    TARGET_MEMBERSHIP:'user/target-membership'
};

export enum MEMBERSHIP_STATUS {
  NOT_ADDED_TO_BASKET,
  MEMBERSHIP_ADDED_TO_BASKET,
  PAID_MEMBERSHIP
}


export const FREE_MEMBERSHIP_TEXT = {
 0: 'Free Trial Available',
  1:'Free Trial Added',
  2:'Free Trial Active'
}
