//#region Imports

//#region Angular Imports
import { Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseComponent } from '@Widgets/blocks/base/base.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { Utilities } from '@Globals/classes/utilities';
import { CollectionUtilities } from '@Widgets/blocks/collection/collection-utilities';

import { COLLECTION_KEYS } from '@Globals/constants/listing/constants';
import { CollectionDisplayType, RecommendationType } from '@Globals/constants/listing/enums';
import { HTTPResponse } from '@ionic-native/http';
import { CollectionService } from '@Globals/providers/collection.service';

//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-collection',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.scss'],
})
export class AllCollectionComponent extends BaseComponent {
  @Input() showDefaultImage;
  @Input() eventSource;
  type;
  productList;
  initiated = false;
  recurringSuggestions = RecommendationType.Recurring_Suggestions;

  constructor(private collectionService: CollectionService) {
    super();
  }

  mbOnInit() {
    this.initiateCollectionData();
  }

  mbOnChanges() {
    if(!this.initiated) {
      this.initiateCollectionData();
    }
  }

  initiateCollectionData(): void {
    if (this.data) {
      if (this.data.table && typeof (this.data.table) === 'string') {
          this.data.table = Utilities.parseJSONString('"' + this.data.table + '"', true);
      }
      if (this.data.table && this.data.table.templateType && this.data.table.templateType === COLLECTION_KEYS.OFFER) {
          //this.dataService.firebaseMasterData.paymentOffers = this.data;
      }
      if (this.data.table && this.data.table.isCollectionScrollable) {
          let bannerData = [];
          this.data.table.rows.forEach(row => {
              row.columns.forEach(column => {
                  let url = column.url;
                  if (!column.url) {
                      this.data.metaData.cells.forEach(metaData => {
                          if (column.identifier === metaData.identifier) {
                              url = `/collection/${this.data.id}/${metaData.collectionLeafId}`;
                          }
                      });
                  } 
                  bannerData.push({
                    name: column.title.text,
                    offer: column.cashBack.text,
                    img: column.images.leafBanner.image,
                    url,
                    collection_id: this.data.id,
                    collection_title: this.data.name,
                    list_position: column.identifier,
                    list_image_dimension: column.height + ' X ' + column.width
                });
              });
          });
          this.data['banners'] = bannerData;
      }

      let randomNum = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
      this.type = CollectionUtilities.getCollectionBlockTypeValue(this.data);

      if(!this.panelSchema) {
        this.panelSchema = CollectionUtilities.getPanelJson(randomNum, this.type);
      }

      if(!this.schema) {
        this.schema = this.panelSchema.b;
      }

      this.props = this.props || {};
      this.props.eventSource = this.eventSource;
      if(this.type == 'c_recurring') {
        this.props.recommendationType = RecommendationType.Recurring_Suggestions;
      }

      this.initiated = true;
    }

    (this.data &&
      this.data.table &&
      ([CollectionDisplayType.RecurringSuggestions].indexOf(this.data.table.displayType) > -1)) &&
      this.getCollectionListProducts();
  }

  getCollectionListProducts() {
    const dataToSend: any = {
        collectionId: this.data.id,
        leafRequest: [{
            leafId: this.data.metaData.cells[0].collectionLeafId,
            listId: this.data.metaData.cells[0].image.leafBanner.listId
        }],
        hideLoader: true
    };
    if(this.collectionService.recommendationCollection(this.data)) {
      dataToSend['displayType'] = this.data.table.displayType;
    }
    this.collectionService.getAllCollectionPlp(dataToSend).subscribe((response: HTTPResponse) => {
        if (response.data.status &&
            response.data.data &&
            response.data.data[dataToSend.leafRequest[0].leafId]) {
            this.productList = {
                data: response.data.data[dataToSend.leafRequest[0].leafId].data
            };
        }
    });
  }
}