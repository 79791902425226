//#region Imports

//#region Angular Imports
import { ChangeDetectorRef, Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthService } from '@Globals/providers/auth.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { ProductListManagerService } from '@Features/product-list/providers/product-list-manager.service';
//#endregion Providers Imports

//#region Other Imports
import { COUNTER_DATA_FOR_IMPULSE_PRODUCT, EVENT_NAMES } from '@Globals/constants/listing/constants';
import { CounterType, ProductListType, DeliveryType, ImageOnDemandType } from '@Globals/constants/listing/enums';
import { SimilarProducts } from '@Globals/interfaces/listing/interfaces';

import { Utilities } from '@Globals/classes/utilities';
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mb-pl-extra-width',
  templateUrl: './extra-width.component.html',
  styleUrls: [ '../../common.scss', './extra-width.component.scss' ],
  providers: [ ProductListManagerService ]
})
export class ExtraWidthComponent {
  @Input() products: any;
  @Input() wrapper: boolean = true;
  @Input() eventSource?: string;
  @Input() eventData?: any;
  topNotchCounterType = CounterType.TopNotch;
  showCounterForProductId = -1;
  PLPTypeImageOnDemand = ImageOnDemandType.PLP;
  deliveryTypeFrozen = DeliveryType.frozen;
  currentHoursAndMinutes = Utilities.getCurrentHoursAndMinutes();
  counterDataForQuantityZero = COUNTER_DATA_FOR_IMPULSE_PRODUCT;

  componentType = ProductListType.Horizontal_Listing_Extra_Width;
  subscription:Subscription;

  recommendedProductList: SimilarProducts = {
    product: {
        id: 0
    },
    title: 'Suggestions for you'
  };

  constructor(
    public authService: AuthService,
    public masterDataService: MasterDataService,
    public plmService: ProductListManagerService,
    private cdRef: ChangeDetectorRef,
    private dataService: DataService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.plmService.setupTomorrowBasket();
    this.subscription = this.plmService.getRunChangeDetForA2B().subscribe(res => {
      this.cdRef.markForCheck();
    });
  }

  ngOnChanges() { }

  showCounterButton(product, productIndex) {
    if (!this.authService.userData.tomorrowBasket[product.product_id || product.id]) {
      this.triggerCounterChange(this.counterDataForQuantityZero, product, productIndex);
    }
    this.showCounterForProductId = (product.product_id || product.id);
  }

  hideCounterButton() {
    this.showCounterForProductId = -1;
  }

  triggerCounterChange(counterDataForImpluseListing, product, productIndex) {
    this.plmService.counterChange(this, counterDataForImpluseListing, product, productIndex);
  }

  getProductId(index : number, product: any) {
    return product ? (product.product_id || product.id) : undefined;
  }

  handleProductClick(product, productIndex) {
    this.showCounterForProductId === (product.product_id || product.id)  && this.authService.userData.tomorrowBasket[product.product_id || product.id] ? false : this.plmService.productDetailsModal(
      product.product_id || product.id,
      productIndex,
      ProductListType.Horizontal_Listing_Extra_Width,
      this.eventSource,
      this.eventData,
      undefined,
      product,
      undefined,
      0
    )
  }

  successHandlerForRecommendedProducts = (productList, product) => {
    this.recommendedProductList.data = productList;
    this.recommendedProductList.product = product;
  }

  async openProductsVariantModal(product, selectedProductIndex) {
    this.dataService.productVariantInfo.isVariantAddedToCart = false;
    this.dataService.productVariantInfo.latestVariantIndexIntoCart = null;
    const variantProduct = product?._newVariantProducts;
    
    if (variantProduct && variantProduct.length) {
      const modal = await this.plmService.openProductVariantModel(variantProduct, product, ProductListType.Product_Variant_List, this.eventSource, this.eventData);
      await modal.present();
      let eventAgrs = { source: this.eventSource, product_id: product.id, variant_id: product.variantId };
      this.eventsService.trackEvents(EVENT_NAMES.PRODUCT_VARIANT_CLICKED, { ...eventAgrs });

      modal.onDidDismiss().then(res => {
        if (this.dataService.productVariantInfo.isVariantAddedToCart) {
          this.products[selectedProductIndex] = variantProduct[this.dataService.productVariantInfo.latestVariantIndexIntoCart];
          this.products[selectedProductIndex]._newVariantProducts = variantProduct;
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
