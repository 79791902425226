
//#region Imports

//#region Angular Imports
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { HttpService } from '@Globals/providers/http.service';
//#endregion Providers Imports

//#region Other Imports
import { MEMBERSHIP_STATUS,MEMBERSHIP_APIS } from '@Pages/membership/membership-constants/membership-constants';
import { environment } from '@Env/environment';
//#endregion Other Imports

//#endregion Imports

@Injectable({providedIn:'root'})
export class MembershipService {

  constructor(
    private httpService: HttpService,
  ) { }

  getMembershipPlans(): Observable<any> {
    return this.httpService.get(MEMBERSHIP_APIS.GET_MEMBERSHIP_PLANS);
  }
  buyMembershipPlan(payload: { membership_id: number },membershipStatus) : Observable<any> {
    if(membershipStatus === MEMBERSHIP_STATUS.MEMBERSHIP_ADDED_TO_BASKET || membershipStatus === MEMBERSHIP_STATUS.NOT_ADDED_TO_BASKET){
      return this.httpService.post(MEMBERSHIP_APIS.UPDATE_MEMBERSHIP, payload);
    }
    return this.httpService.post(MEMBERSHIP_APIS.BUY_MEMBERSHIP, payload);
  }
  getCustomerSaving() : Observable<any> {
    return this.httpService.get(MEMBERSHIP_APIS.CUSTOMER_SAVING);
  }
  getMembershipBillDetails() : Observable<any> {
    return this.httpService.post(MEMBERSHIP_APIS.BILL_DETAILS,{'version':environment.codePushVersion});
  }
  getCustomerMembership(): Observable<any> {
    return this.httpService.get(MEMBERSHIP_APIS.CUSTOMER_MEMBERSHIP);
  }
  updateAutoRenew(arm): Observable<any>{
     arm = parseInt(arm);
    return this.httpService.put(MEMBERSHIP_APIS.UPDATE_AUTO_RENEW_MEMBERSHIP, {
      arm
    });
  }
}
