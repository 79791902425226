//#region Imports

//#region Angular Imports
import { Component, NgZone } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { AnimationOptions } from 'ngx-lottie';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultPage } from '@Lib/defaults/default.page';
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbp-splash',
  templateUrl: './splash.page.html',
  styleUrls: ['./splash.page.scss'],
})
export class SplashPage extends DefaultPage {
  
  public lottieConfig: AnimationOptions = {
    path: 'assets/lottie/splash.json',
    autoplay: true,
    loop: false
  };
 
  constructor(
    public dataService: DataService,
    public zone: NgZone
  ) {
    super();
    if(this.dataService.firebaseMasterData?.festiveSeason?.splash?.visibility) {
      this.lottieConfig = {
        ...this.lottieConfig,
        ...{ path: this.dataService.firebaseMasterData?.festiveSeason?.splash?.splash_lottie}
      };
    }
  }

  mbOnInit(): void {
    document.getElementsByTagName('body')[0].classList.add('splash-screen');
  }

  mbOnDestroy(): void {
    document.getElementsByTagName('body')[0].classList.remove('splash-screen');
  }
}
