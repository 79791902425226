export const APIs = {
    DATA_UI: 'home/data_ui',
    HOME_DATA: 'home/data_ui/page',
    HOME_CATEGORIES: 'home/categories',
    HOME_BANNERS: 'home/banner',
    HOME_IMPULSE_PRODUCTS: 'home/impulse/product',
    HOME_MASTER_DATA: 'home/master-data',
    REGISTER_PUSH: 'user/register_push',
    APPLY_WELCOME_CODE: 'user/apply_welcome_code',
    LEGACY_TOKEN: 'user/legacy-login',
    SEND_USER_DETAILS: 'user/updateProfile',
    CATEGORIES: 'home/category',
    BASKETS: 'baskets',
    VERIFY_UPI: 'payment/verify-vpa',
    CARD_BINS: 'https://api.juspay.in/cardbins/',
    GET_CARDS: 'payment/cards',
    DELETE_CARDS: 'payment/delete-card',
    AUTO_DEBIT: 'payment/auto-debit',
    REC_RECOMMENDATIONS: 'products/rec-recommendation',
    PAYMENT_OPTIONS: 'payment/methods/options',
    CREATE_WALLET: 'payment/create-wallet?gateway=',
    LINK_WALLET: 'payment/link-wallet',
    ACTIVE_OFFERS: 'user/active-offers',
    USER_IP: 'https://api.ipify.org/?format=json',
    LAT_LNG_FROM_IP: 'https://api.ipgeolocation.io/ipgeo?',
    JIO_MAHACASHBACK: 'transactions/rr/mahacashbck/balance',
    CASHBACK_OFFERS: 'offers',
    REFRESH_WALLET: 'payment/refresh-wallet',
    SEND_FEEDBACK: 'user/feedback',
    GET_NOTIFICATION: 'user/update-communication',
    SAVE_PROFILE: 'user/profile',
    JUSPAY_PROFILE: 'payment/juspay-profile',
    BASKET_SUBCATEGORIES: 'home/basket/subcategories',
    CUSTOMER_RATING:'home/customer-rating',
    CASHBACK_SAVING:'home/total-saving',
    CAMPAIGN_STRIP:'home/campaign/strip',
    VALIDATE_TOKEN: 'user/token/validate',
    REFRESH_TOKEN: 'user/token/refresh',
    USER_TNC:'user/accept-tnc'
};

export const ACCEPT_ENCODING_HEADER = {
    key: 'Accept-Encoding',
    value: 'gzip'
};
