//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { CardDetailsModule } from '@Shared/payment/card-details/card-details.module';
import { WalletEligibilityModule } from '@Shared/payment/wallet-eligibility/wallet-eligibility.module';
import { NetBankingModule } from '@Shared/payment/net-banking/net-banking.module';
import { UpiVerificationModule } from '@Shared/payment/upi-verification/upi-verification.module';
import { WalletLinkingModule } from '@Shared/payment/wallet-linking/wallet-linking.module';
import { FaqModalModule } from '@Shared/faq-modal/faq-model.module';
import { FoodWalletGuidelinesModule } from '@Shared/payment/food-wallet-guidelines/food-wallet-guidelines.module';
import { CashbackWalletGuidelinesModule } from '@Shared/payment/cashback-wallet-guidelines/cashback-wallet-guidelines.module';
import { WalletBreakupModule } from '@Shared/payment/wallet-breakup/wallet-breakup.module';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

@NgModule({
  imports: [
    CardDetailsModule,
    WalletEligibilityModule,
    NetBankingModule,
    UpiVerificationModule,
    WalletLinkingModule,
    FaqModalModule,
    FoodWalletGuidelinesModule,
    CashbackWalletGuidelinesModule,
    WalletBreakupModule,
  ],
  exports: [
    CardDetailsModule,
    WalletEligibilityModule,
    NetBankingModule,
    UpiVerificationModule,
    WalletLinkingModule,
    FaqModalModule
  ],
  declarations: []
})
export class PaymentSharedModule{ }
