<ion-list lines="none" class="ion-no-padding ion-list-image-card ion-list-grid gvc layout-spacer">
<ion-list-header class="ion-list-header" *ngIf="data?.name">
  <ion-row class="ion-justify-content-between full-width ion-align-items-center">
    <div>
      <span class="padding-right-small">{{ data.name }}</span>
      <p *ngIf="data?.description">{{ data.description }}</p>
    </div>
  </ion-row>
</ion-list-header>
<ion-item class="ion-no-padding" mode="md">
  <ion-grid class="collection-grid" [ngClass]="{
    'auto-size-img': data?.customizeCollection == 1,
    'ignore-padding': data?.customizeCollection == 2,
    'auto-size-img-n-ignore-padding': data?.customizeCollection == 3
  }"  #grid>
    <ion-row *ngFor="let row of data?.table?.rows;let rowIndex=index;">
      <!-- We are calculating the height and width of the col as based on the width of the #grid(ViewChild)-->
      <ion-col *ngFor="let column of row.columns" [ngStyle]="{
'flex-basis':
  ((grid.el.offsetWidth - 24) * column.width) / 100 + 'px'
}">
        <ion-card class="ion-no-margin ion-card-image ion-full-card">
          <p *ngIf="column.cashBack?.text" class="offer-strip">
            {{ column.cashBack.text }}
          </p>
          <ion-button color="light" class="volume-control has-opacity" (click)="muteOrUnmuteVideo()">
            <ng-container>
              <span *ngIf="muted;else unMutedIcon" class="icon-mute"></span>
              <ng-template #unMutedIcon>
                <span class="icon-un-mute"></span>
              </ng-template>
            </ng-container>
          </ion-button>
          <ng-container *ngIf="videosForCollectionConfigData;else showImage">
            <video (click)="navigateToCollectionList(column)" #featureVideoPlayer
              class="video-area" [muted]="muted" webkit-playsinline playsinline autoplay loop preload="auto"
              [poster]="videosForCollectionConfigData.thumbnail"
              inViewport
              [inViewportOptions]="{ threshold: [0, 0.65], partial: true }"
              (inViewportAction)="onIntersection($event)">
              <source *ngIf="videosForCollectionConfigData.full_video_url"
                [src]="videosForCollectionConfigData.full_video_url" [type]="videosForCollectionConfigData.type">
            </video>
          </ng-container>
          <ng-template #showImage>
            <img alt="video collection thumbnail" *ngIf="videosForCollectionConfigData" [src]="videosForCollectionConfigData.thumbnail" />
          </ng-template>
          <ion-card-header class="ion-no-padding" *ngIf="column.title?.text">
            <ion-card-title [ngStyle]="{'color':column.title.color}">{{ column.title.text }}
            </ion-card-title>
          </ion-card-header>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>
</ion-list>