export enum DeliveryTypes {
    frozen = '7:00-8:00',
    normal = 'normal',
}

export enum ProductTypes {
    Product = 1,
    Sample = 2,
}

export enum ProductBadgeTypes {
    None = 0,
    New = 1,
}

export enum ImageTypes {
    None = 0,
    Image = 1,
    /* Lottie = 2, // for future usecase */
}

export const ProductSourceType = { 0: 'Unknown', 1: 'Farm Route' };

export enum ProductRefillTypes {
    NextDay = 3,
    WillReturnInXDays = 4,
    Discontinued = 5,
    LeadTimeNotKnown = 6,
}
