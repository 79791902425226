const MB_GQL_ENDPOINT = "https://uat-consumerbff.milkbasket.in/graphql";

export const environment = {
    production: false,
    environmentName: 'UAT',
    codepushEnabled:false,
    apiUrl: 'https://uat.milkbasket.com/milkbasket_uat_current/consumer/',
    apolloClientUrl: MB_GQL_ENDPOINT,
    codePushDeploymentKey: {
        ANDROID: 'Fw27TqPKqdFPZQbzN20cdczbtK7Br17sGxrkB',
        IOS: 'gTQQ8P-ZXm14Vkcc28CVIT_kG19rrJxMmgH1r'
    },
    userExperiorKey: '9b7f7c40-4081-48c0-9c9b-cf623daa8c32',
    imagePath: 'https://file.milkbasket.com/images/',
    bannerPath: "https://file-uat.milkbasket.com/banners/",
    subcatPath: "https://file-uat.milkbasket.com/subcategories/",
    catPath: "https://file-uat.milkbasket.com/categories/",
    collectionPath: "https://file-uat.milkbasket.com/collection/",
    productPath: "https://file-uat.milkbasket.com/products/",
    collectionVideoPath: "https://file-uat.milkbasket.com/collection/",
    popularSuggestion: "https://file-uat.milkbasket.com/popular_suggestions/",
    APPSFLYER: {
        DEV_KEY: 'cUysesBEmiyskjVx4Z2fnD',
        ON_INSTALL_CONVERSION_DATA_LISTENER: true,
        APP_ID: '1447080233',
        IS_DEBUG: true,
        APP_INVITE_ONE_LINK_ID: '6GeL'
    },
    BRANCHIO: {
        'branch-key': 'key_test_cjl9vbLJNwsBNCtsKp4xCfhhrybfvhcG',
        'uri-scheme': 'Milkbasket [Test]'
    },
    SELFHELP: {
        selfHelpUrl: 'https://selfhelp-uat.milkbasket.in/#/help?sid=',
        betaUrl: 'https://selfhelp-uat-beta.milkbasket.com/#/help?sid=',
        version: '4.0'
    },
    appUrl: 'uat.milkbasket.com/milkbasket_uat_current/store',
    paymentUrl: 'https://dev2.milkbasket.com/',
    CLEVERTAP: {
        DEBUG_LEVEL: 2
    },
    FIREBASE: {
        apiKey: 'AIzaSyCDHcyH-AmSZtc4E0-WqvD2iSzzvsimmIk',
        authDomain: 'milkbasket-dev.firebaseapp.com',
        databaseURL: 'https://milkbasket-dev.firebaseio.com',
        projectId: 'milkbasket-dev',
        storageBucket: 'milkbasket-dev.appspot.com',
        messagingSenderId: '1076793618671',
        appId: '1:1076793618671:web:70a1dd917ffd74edc7eb1a',
        measurementId: 'G-1ZSGHLT9NM'
    },
    CAMERACONFIG: {
        quality: 50,
        targetWidth: 600,
        targetHeight: 600
    },
    cloudFront: {
        Url: 'https://imageoptimizer.milkbasket.com',
        bucket: 'file-uat.milkbasket.com'
    },
    defaultCity: {
        id: 1,
        name: 'Gurgaon'
    },
    apiKeyForIPGeo: 'f2a7fa7ffad64114bad72d4e3a5be5db',
    gaTrackingId: '190199506',
    codePushVersion: '6.0.2.17',
    appStoreVersion: '6.0.2',
    REFER_IMAGE_URL:'https://file-uat.milkbasket.com/cityMeta/images/',
    juspayEndUrl: 'https://uat.milkbasket.com/milkbasket_uat_current/consumer/payment.*',
    jiopayCallbackURL: 'uat.milkbasket.com/milkbasket_uat_current/consumer/transaction_new/transaction_status',
    jioHomeUrl: 'https:\/\/test-jiomart.netmeds.com\/?source_attribution=JioMartApp-CPS&utm_source=JioMartApp-CPS&utm_medium=CPS&utm_campaign=JioMartApp',
    appStoreRedirection: 'http://itunes.apple.com/lb/app/milkbasket-hasslefree-grocery/id1088165798',
    playStoreRedirection: 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.milkbasket.app',
    mapLoadUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBR-FZrtxtZbTJl06uI0JyrSbV0tCgyigA&libraries=places',
    reactProcessVars: {
        MB_IS_PRODUCTION: false,
        MB_GQL_ENDPOINT: MB_GQL_ENDPOINT,
        MB_ICON_ASSETS_PATH: "https://file-uat.milkbasket.com/",
        MB_IMAGE_ASSETS_PATH: "https://file-uat.milkbasket.com/",
        MB_VIDEO_ASSETS_PATH: "https://file-uat.milkbasket.com/",
        MB_IMAGE_PATH: "https://file-uat.milkbasket.com/",
        MB_VIDEO_PATH: "https://file-uat.milkbasket.com/",
        MB_SELF_HELP_URL: "https://selfhelp-uat.milkbasket.in",
        MB_SELF_HELP_VERSION: "5.0.8",
    },

  firebaseDeeplinkConfiguration: {
    domainUriPrefix: "https://link.app.milkbasket.com",
    link: `https://app.milkbasket.com/`,
    socialImageLink: "https://adhoc.milkbasket.com/mb-logo.png",
    createLinkApi:
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAN8ntl0tveGM6cYSvFHctPw_i4Nia1XOw",
  },
};
