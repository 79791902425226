<app-layout [props]="layoutDefaultProps">
  <app-header class="page-header has-right-icon" [isDrawerNeeded]="false" [showBackButton]="false">
    <div class="page-header-wrap">
      Add Money
      <ion-buttons slot="end">
        <ion-button class="help-button" (click)="navigateToExternalUrl()">
          <ion-icon slot="icon-only" class="icon-help icon-md icons-property" color="dark"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </app-header>  
  <app-account-freeze class="wallet-freeze-wraper" *ngIf="masterDataService.masterData?.user?.wf"></app-account-freeze>
  <mbs-address-unverified class="has-tower-min-height-strip"></mbs-address-unverified>
  <ion-content #pageContent [ngClass]="{'disable-btn':masterDataService.masterData?.user?.wf}"  >
    <div class="f-v-h" [ngClass]="{ 'has-not-payment-offer': (superChargeId || !dataService.firebaseMasterData.paymentOffers?.name) }">
      <form 
      class="form" 
      #shoppingCreditForm="ngForm" 
      (keydown.enter)="$event.preventDefault()" 
      (ngSubmit)="submit()"
      [ngClass]="{'super-charge' : superChargeId}">
        <div 
        class="f-body ion-padding padding-top-0 ctn-gt-space-p white-bg">
          <ion-item 
          lines="none" 
          class="credit-cont ion-no-padding balance-info-strip">
            <img alt="brand logo" width="52" 
            class="mb-logo" 
            [src]="shoppingCreditsConstants.BALANCE_INFO.BRAND_LOGO_URL"
            slot="start" />
            <ion-label class="ion-text-wrap">
              <p class="f-w-m ion-text-capitalize d-flex ion-align-items-center">
                {{ shoppingCreditsConstants.BALANCE_INFO.BALANCE }}
                <strong class="f-w-s-b pl">{{ (masterDataService.masterData?.balance === 0 ? '0' : masterDataService.masterData?.balance) | currency:'INR' : 'symbol' : '1.2-2' }}</strong>
                <span class="scale-tabable-area" (click)="shoppingCreditsServices.openWalletBreakupPrompt(eventSourceSCPage)">
                  <ion-icon class="icons-property icon-info icon-dark"></ion-icon>
                </span>
              </p>
              <small>{{ shoppingCreditsConstants.BALANCE_INFO.MAX_RECHARGE_AMOUNT }}</small>
            </ion-label>
            <div (click)="navigateToTransactionHistory()" class="scale-tabable-area" slot="end">
              <ion-icon class="icon-arrow-right icons-property" color="primary"></ion-icon>
            </div>
          
          </ion-item>
          <div class="payment-list ion-margin-top row-medium" *ngIf="masterDataService.paymentOffers.length">
            <mbs-payment-offer-listing [isSort]="false" [showLabel]="false" [source]="source"></mbs-payment-offer-listing>
          </div>
          <ion-row class="add-topup margin-top-small" *ngIf="!superChargeId">
            <div class="f-group">
              <ion-label position="stacked" class="margin-bottom-smallest">
                <strong class="f-w-b text-size-small margin-bottom-extra-small d-block">{{ shoppingCreditsConstants.BALANCE_INFO.RECHARGE_AMOUNT_LABEL }}</strong>
              </ion-label>
              <ion-item lines="none" class="ion-no-padding" id="ds-border">
                <ion-input 
                  class="inr" 
                  [disabled]="masterDataService.masterData?.user?.wf"
                  [ngModel]="shoppingCreditAmount | number: '1.0'" 
                  type="tel"
                  name="shoppingCreditAmount" 
                  ngControl="shoppingCreditAmount"
                  (ionInput)="updateShoppingCreditAmount($event)"
                  [maxlength]="shoppingCreditsServices.topUpRange?.maxLength" 
                  [placeholder]="'eg. ' + (shoppingCreditsServices.topUpRange?.min_amount || 500)"  
                  required>
                </ion-input>
              </ion-item>
              <ion-text color="danger">
                <p ion-text color="danger" class="d-flex" *ngIf="showError.min_amount_error">
                  {{ SHOPPING_CREDIT_ERROR_MESSAGES.SHOPPING_CREDIT_AMOUNT_MIN
                  }} <span class="inr no-margin-0"> {{ shoppingCreditsServices.topUpRange.min_amount }}</span>
                </p>
                <p ion-text color="danger" class="d-flex" *ngIf="showError.max_amount_error">
                  {{ SHOPPING_CREDIT_ERROR_MESSAGES.SHOPPING_CREDIT_AMOUNT_MAX
                  }} <span class="inr no-margin-0"> {{ shoppingCreditsServices.topUpRange.max_amount }}</span>
                </p>
              </ion-text>
            </div>
          </ion-row>
          <ion-row class="margin-top-medium credit-btn" *ngIf="!superChargeId">
            <ion-button 
              class="ion-no-padding light-week disable-bg" 
              *ngFor="let amount of shoppingCreditsServices.topUpRange?.amounts"
              [ngClass]="{ active: amount.value == shoppingCreditAmount }" 
              (click)="setValue(amount.value)"
              [disabled]="masterDataService.masterData?.user?.wf"
              >
              <span class="inr"></span>{{ amount.value  | number: '1.0'}}
            </ion-button>
          </ion-row>
        </div>

        <div class="f-footer white-bg padding-top-small">

          <div class=" ctn-gt-space-p ion-text-center ion-justify-content-between buttons-wrap full-width">
            <ion-button 
            type="submit" 
            [disabled]="(!shoppingCreditForm.valid || !masterDataService.isValidAddress || masterDataService.masterData?.user?.wf)" 
            size="large" 
            shape="round"
            class="t-t-n  disable-bg light-week"
            >
              Proceed to Add Money
            </ion-button>
          </div>
        </div>

        <mbs-promo-code-offer (onApplyInviteCode)="openPromoCodeBottomSheet()"></mbs-promo-code-offer>
        <div class="auto-debit-wrap payment-banner-wrap">
          <mbf-auto-pay [getStatusOfAutoDebit]="getStatusOfAutoDebit"></mbf-auto-pay>
          <div class="banner-section banner-holder" *ngIf="paymentBanner.length">
            <wgt-banner-block [data]="data"></wgt-banner-block>
          </div>
        </div>
      </form>

      <div class="wrap-po" *ngIf="dataService.firebaseMasterData.paymentOffers">
        <mbs-payment-offer [paymentOffers]="dataService.firebaseMasterData.paymentOffers"></mbs-payment-offer>
      </div>

      <div class="add-money-note ctn-gt-space-p text-size-small ion-margin-top">
        <strong class="margin-bottom-small">{{shoppingCreditsConstants.NOTES_LABEL}}</strong>
        <div [innerHTML]="dataService.firebaseMasterData.addMoney_bottomNote"></div>
      </div>
      
    </div>
  </ion-content>
</app-layout>