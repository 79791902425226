//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
import { CoachmarkPopoverModule } from '@Shared/coachmark-popover/coachmark-popover.module';
import { SocialSharingButtonModule } from '@Shared/social-sharing-button/social-sharing-button.module';
import { JioMartHeaderModule } from '@Shared/jio-mart-header/jio-mart-header.module';
//#endregion Module Imports

//#region Component Imports
import { HeaderComponent } from '@MbUi/headers/two/header.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    CoachmarkPopoverModule,
    SocialSharingButtonModule,
    JioMartHeaderModule
  ],
  exports: [HeaderComponent],
  declarations: [HeaderComponent]
})
export class HeaderModule extends DefaultModule { }