export enum AddressStatus {
    Valid = 1,
    UnderVerification = 2,
    Rejected = 3,
    IntentCaptured = 4,
}

export enum SocietyType {
    HighRise = 1,
    RowHousing = 2,
    Locality = 3,
}
