//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultWidgetModule } from '@Lib/defaults/default-widget.module';
//#endregion Module Imports

//#region Component Imports
import { AllCollectionComponent } from '@Widgets/blocks/collection/all/all.component';
import { CollectionBannerModule } from '@Widgets/blocks/collection/banner/banner.module';
import { ExploreCollectionModule } from '@Widgets/blocks/collection/explore/explore-collection.module';
import { HorizontalProductsExtraWidthModule } from '@Widgets/blocks/collection/horizontal-products-extra-width/horizontal-products-extra-width.module';
import { HorizontalWidgetModule } from '@Widgets/blocks/collection/horizontal-widget/horizontal-widget.module';
import { ImageCollectionModule } from '@Widgets/blocks/collection/image/image-collection.module';
import { PaymentOfferModule } from '@Widgets/blocks/collection/payment-offer/payment-offer.module';
import { ProductLaunchListModule } from '@Widgets/blocks/collection/product-launch-list/product-launch-list.module';
import { RecommendationProductsListModule } from '@Widgets/blocks/collection/recommendation-products-list/recommendation-products-list.module';
import { ThreeColumnInSingleRowModule } from '@Widgets/blocks/collection/three-column-in-single-row/three-column-in-single-row.module';
import { TwoRowProductsListModule } from '@Widgets/blocks/collection/two-row-products-list/two-row-products-list.module';
import { VideoCollectionModule } from '@Widgets/blocks/collection/video/video-collection.module';
import { BookStoreModule } from '@Widgets/blocks/collection/book-store/book-store.module';
import { mBeyondCollectionModule } from '@Widgets/blocks/collection/mBeyond/mBeyond-collection.module';
import { AutoSwipeModule } from '@Widgets/blocks/collection/auto-swipe/auto-swipe.module';
import { LottieCollectionModule } from '@Widgets/blocks/collection/lottie/lottie-collection.module';
import { VideoCardModule } from '@Widgets/blocks/collection/video-card/video-card.module';
import { DealsOfTheDayProductsListModule } from '@Widgets/blocks/collection/deals-of-the-day/deals-of-day-products-list.module';
import { CategoryCollectionModule } from '@Widgets/blocks/collection/category-collection/category-collection.module';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  declarations: [AllCollectionComponent],
  imports: [
    CommonModule,
    IonicModule,
    CollectionBannerModule,
    BookStoreModule,
    ExploreCollectionModule,
    HorizontalProductsExtraWidthModule,
    HorizontalWidgetModule,
    ImageCollectionModule,
    PaymentOfferModule,
    ProductLaunchListModule,
    RecommendationProductsListModule,
    ThreeColumnInSingleRowModule,
    TwoRowProductsListModule,
    VideoCollectionModule,
    LottieCollectionModule,
    mBeyondCollectionModule,
    AutoSwipeModule,
    VideoCardModule,
    DealsOfTheDayProductsListModule,
    CategoryCollectionModule
  ],
  exports: [AllCollectionComponent]
})
export class AllCollectionModule extends DefaultWidgetModule { }
