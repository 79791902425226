<!--is-grid-view class will get added for grid view collection 3 collections in one row-->
<ion-list class="ion-no-padding ion-list-image-card ion-list-grid gvc layout-spacer">
 <ion-list-header class="ion-list-header" *ngIf="data?.name">
  <ion-row class="ion-justify-content-between full-width ion-align-items-center">
    <div>
      <span class="padding-right-small">{{ data.name }}</span>
      <p *ngIf="data?.description">{{ data.description }}</p>
    </div>
  </ion-row>
</ion-list-header> 
<ion-item  class="ion-no-padding horizontal-widget is-grid-view" lines="none">
    <div class="scroll" scroll-x="true">
    
    <ion-card class="ion-card-custom fixed-size" (click)="navigateToCollectionList(row)"
      *ngFor="let row of collectionTableData">
      <div class="img-holder">
        <div class="img-holder-inner">
          <app-img-on-demand [actualImgSrc]="bannerFilePath + row.images.leafBanner.image"
            [defaultImgSrc]="'assets/img/default-banner.png'" [showDefaultImage]="props?.showDefaultImage"
            alt_image="img_collection_{{row.images.leafBanner.image}}"
            [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER" [useImageOnDemand]="false"
            [imageName]="row.images.leafBanner.image">
          </app-img-on-demand>
        </div>
      </div>

      <ion-card-header *ngIf="row.title?.text">
        <ion-card-title class="ion-text-center">
          {{ row.title.text }}
        </ion-card-title>
      </ion-card-header>
    </ion-card>

  </div>
</ion-item>
</ion-list>