<ion-header class="ion-main-header sticky-header margin-top-medium margin-bottom-smallest">
    <ion-toolbar class="modal-bottom-sheet">
        <div class="d-flex ion-align-items-start ion-justify-content-between">
            <div>
                <ion-title class="ion-no-padding padding-top-extra-medium padding-bottom-extra-medium text-size-lg f-w-b text-title">
                    <span>{{ parentProductData._nm || parentProductData.name }} </span>
                </ion-title>
                <h6 class="f-w-b farm-cont d-flex ion-align-items-center margin-top-extra-small" [hidden]="parentProductData._fsHide">
                    <small class="small-text-bg" margin-bottom-smallest [hidden]="!parentProductData.source">
                        <ion-icon color="dark"  class="icon-route icon-sm icons-property margin-right-smallest"></ion-icon>
                        {{parentProductData._source }}
                    </small>
                    <small  class="small-text-bg" [hidden]="!parentProductData.location">
                        <ion-icon color="dark"  class="icon-location icon-sm icons-property margin-right-smallest"></ion-icon>
                        {{ parentProductData.location }}
                    </small>
                </h6>
            </div>
            <div>
                <ion-button (click)="closeModal()" color="dark" fill="clear" class="ion-buttons-arrow-back btn-size btn-arrow-spacing padding-top-extra-medium" slot="end">
                    <ion-icon color="dark" class="icon-close icons-property icon-md no-ripple-effect"
                        slot="icon-only"></ion-icon>
                </ion-button>
            </div>
        </div>
    </ion-toolbar>
</ion-header>
<div class="product-variant-listing content-wrap" (scroll)="scrollHandler($event)">
    <div> 
        <mb-pl-vlist class="product-variant-wrap" [componentType]="componentType" [products]="variantProduct" [eventSource]="eventSource" [eventData]="eventData" [itemType]="itemType" [showDivider]="true"></mb-pl-vlist>
    </div>
</div>

<ion-footer>
    <ion-button type="button" size="default" shape="round" fill="outline" expand="block" (click)="closeModal()"
        class="mw-large p-button vertical-spacing"> Go back </ion-button>
</ion-footer>
