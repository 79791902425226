export const PROFILE_APIS = {
  CHANGE_PASSWORD: 'user/change_password',
  LOGOUT: 'user/logout',
  GET_HOLIDAY: 'user/holidays',
  ADD_HOLIDAY: 'user/add_holiday',
  GET_REFERRAL_DETAILS: 'user/get_referral_details',
  REFERRAL_STATUS:'user/refer_status',
  GET_REFER_CONTENT:'home/refer',
  DEACTIVATE_ACCOUNT: 'close-account',
  CANCEL_DEACTIVATE_ACCOUNT: 'close-account/cancel',
  GET_BANK_DETAILS_OR_NOT: 'user/neft-details-required',
  GENERATE_EMAIL_OTP: 'user/generate_email_otp',
  VERIFY_EMAIL_OTP: 'user/verify_email_otp',
};