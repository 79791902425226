//#region Imports

//#region Angular Imports
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  NgZone,
} from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { SearchBarComponentInput } from '@Globals/interfaces/listing/interfaces';

import { Utilities } from '@Globals/classes/utilities';

declare var google;
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbf-search',
  templateUrl: './search.component.html',
  styleUrls: [ './search.component.scss' ]
})
export class SearchComponent extends DefaultComponent {
  @Input() searchData: SearchBarComponentInput = {
    placeholder: '',
    text: ''
  };
  @Output() searchDataChange = new EventEmitter();
  enteredText = '';
  @Input() autoFocus = true;
  @Input() needDebounce = true;
  @ViewChild('searchInput', { static: false }) searchInput;
  @Output() searchText: EventEmitter<string> = new EventEmitter<string>();
  @Output() triggerSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() getFocus = new EventEmitter();
  @Input() isGoogleAddressAutoComplete: boolean = false;
  @Input() latLng: any = null;
  @Output() autocompleteItemsOutput: EventEmitter<any[]> = new EventEmitter<any[]>();
  lat: string;
  long: string;  
  autocompleteItems: any[];
  location: any;
  placeid: any;
  GoogleAutocomplete: any;
  timeout = null;
  map: any;
  address:string;

  constructor (private zone: NgZone) { 
    super();
  }

  mbOnInit() { 
  }

  setFocusonInput() {
    this.setFocusOnSearch();
  }

  mbAfterViewInit() {
    if(this.autoFocus) {
      setTimeout(() => {
        this.setFocusOnSearch();
        })
    }
    
    if(this.isGoogleAddressAutoComplete) {
      this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
      this.autocompleteItems = [];
    }
  }
  
  //AUTOCOMPLETE, SIMPLY LOAD THE PLACE USING GOOGLE PREDICTIONS AND RETURNING THE ARRAY.
  UpdateSearchResults(){
    if (this.searchData.text == '') {
      this.autocompleteItems = [];
      return;
    }
    this.GoogleAutocomplete.getPlacePredictions({ input: this.searchData.text },
    (predictions, status) => {
      this.autocompleteItems = [];
      if(predictions && predictions.length) {
        predictions.forEach((prediction) => {
          this.autocompleteItems.push(prediction);
        });
      }
      this.autocompleteItemsOutput.next(this.autocompleteItems);
    });
  }
  
  //lET'S BE CLEAN! THIS WILL JUST CLEAN THE LIST WHEN WE CLOSE THE SEARCH BAR.
  ClearAutocomplete(){
    this.autocompleteItems = []
    this.searchData.text = '';
  }

  clearText() {
    this.searchData.text = '';
    this.searchText.emit('');
  }

  setFocusOnSearch() {
    setTimeout(() => {
      this.searchInput && this.searchInput.setFocus();
    }, 1000);
  }

  setFocus() {
    this.getFocus.emit(true);
  }
  
  search(event) {
    if(Utilities.isEmptyString(event.target.value)) {
      event.target.value = event.target.value.trim();
      this.clearText();
    } else {
    let searchInput = event.target.value;
    if (event.keyCode === 13) {
      this.enteredText = searchInput;
      this.triggerSearch && this.triggerSearch.emit(Utilities.removeEmojis(searchInput));
    } else {
      if (this.needDebounce) {
        this.clearTimeoutTimer();
        this.timeout = setTimeout(() => {
          this.zone.run(() => {
            this.enteredText = searchInput;
            if (this.searchData.text.length >= 3) {
              this.searchDataChange.emit({
                placeholder: this.searchData?.placeholder,
                text: Utilities.removeEmojis(searchInput)
              });
              this.searchText.emit(Utilities.removeEmojis(searchInput));
            }
          });
        }, 1000);
      } else {
        this.enteredText = searchInput;
        this.searchDataChange.emit({
          placeholder: this.searchData?.placeholder,
          text: Utilities.removeEmojis(searchInput)
        });
        this.searchText.emit(Utilities.removeEmojis(searchInput));
      }
    }
  }}

  clearTimeoutTimer(){
    if(this.timeout) {
      clearInterval(this.timeout);
      this.timeout = null;
    }
  }

  mbOnDestroy(): void {
   this.clearTimeoutTimer();
  }
}
