//#region Imports

//#region Angular Imports
import { Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from '@Globals/providers/master-data.service';
import { ShoppingCreditsServices } from '@Globals/providers/shopping-credits.service';
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-wallet-breakup',
  templateUrl: './wallet-breakup.component.html',
  styleUrls: ['./wallet-breakup.component.scss'],
})
export class WalletBreakupComponent {
  @Input() eventSource;
 
  public CASHBACK_GUIDELINES_SUBHEADING: string =
    'Applicable to all services and items, except Oils & Ghee, Fresh Milk & Baby Food.';
  
  constructor(
    private modalCtrl: ModalController,
    public shoppingCreditsServices: ShoppingCreditsServices,
    public masterDataService: MasterDataService,
    public dataService: DataService,
  ) { }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }
}
