import { ProductOffers } from '../../../product-offers/src';

export class ProductOrder {
    __typename = 'ProductOrder';
    productId: number; // Please note that this is not orderId but productId itself, for using fragments on client side
    quantity: number;
    isPreOrder: boolean;
    startDate?: string;
    endDate?: string;
    offers?: ProductOffers;

    setProductOrderFromApi(productApi) {
        this.productId = productApi.product_id || productApi.id;
        this.quantity = +productApi.quantity || 0;
        this.isPreOrder = productApi.pre_order;
        this.startDate = productApi.order_start_date;
        this.endDate = productApi.order_end_date;

        const offers = new ProductOffers();
        this.offers = offers.setProductOffersFromApi(productApi);

        return this;
    }
}
