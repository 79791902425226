import { Injectable, NgZone } from '@angular/core';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { RegisterPlugins } from '../../classes/register-plugins/register-plugins';
import { APIs } from '../../constants/APIs';
import { ANDROID, EVENT_NAMES, GET_ALL_BASKETS_PERIOD, HOME_OFFER_STRIP_CONTENTS, HOME_STRIP_CONTENTS, IOS, LOGOUT_MODE, SOCIAL_SHARING_CHANNELS, STORAGE_KEYS } from '../../constants/constants';
import { Banner, Basket, BasketSubcategory, Category, ImpulseProducts, MahaCashback, MasterData, PaymentOption, PersonalDetails, RecurringOrder, StaticMasterData,JioStrip, Savings, TargetMembership } from '../../interfaces/interfaces';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../http/http.service';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { CleverTap } from '@ionic-native/clevertap/ngx';
import { NavController, Platform } from '@ionic/angular';
import { Utilities } from '../../classes/utility/utilities';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { DataService } from '../data/data.service';
import { EventsService } from '../events/events.service';
import * as moment from 'moment';
import { SuperChargeType } from '@Globals/constants/payment/enums';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { LISTING_ROUTES } from 'src/app/listing/constants/listing-routes';
import { HTTPResponse } from '@ionic-native/http/ngx';
import { EVENT_SOURCES, MBPASS_OFFER_CATEGORY_TYPE, MBPASS_OFFER_TYPE, MBPASS_TYPE, YFO_CATEGORY_ID, COLLECTION_LOCATION_TYPE } from 'src/app/listing/constants/listing-constants';
import { BannerType, EventTrackingPlugins, MB_PASS_TYPE } from '../../constants/enums';
import { LISTING_APIS } from 'src/app/listing/constants/listing-APIs';
import { CollectionDisplayType, OfferType, ProductListType } from '../../../listing/constants/listing-enums';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { HOME_COLLECTION_EXPIRATION_TIME, MASTER_DATA_BANNER_EXPIRATION_TIME, MASTER_DATA_CATEGORY_EXPIRATION_TIME } from 'src/app/registration/constants/registration-constants';
import { StorageService } from '../storage/storage.service';
import { PAYMENT_VALUES, PG_WISE_JUSPAY_PAY_LOAD } from '@Globals/constants/payment/constants';
import { SelectTabOption } from '@Layouts/one/providers/layout.service';
import { TaskRunner } from '../task-runner/task-runner.service';
import { PAYMENT_APIS } from '@Globals/constants/payment/apis';
import { map } from 'rxjs/operators';
import { WebAppflyerService } from '@Globals/providers/web-appflyer-service';
import { MEMBERSHIP_CACHE_TYPES } from '@Globals/constants/react/widget-map.constants';
@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
  masterData: MasterData;
  recurringOrder: Array<RecurringOrder>;
  brandLaunchdata: any;
  productLaunchdata: any;
  preorderedBaskets: Array<Basket>;
  navigateFromDeeplink: boolean = false;
  public masterDataUpdateObs: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  mahaCashBackObs: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public checkUPIAppAvailability: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  subscribeToSaveTrigger: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mahaCashback: MahaCashback
  selectedCategory: Category;
  selectedSubcategoryIndex: number;
  public brandLaunchSub: BehaviorSubject<number> = new BehaviorSubject(null);
  public productLaunchSub: BehaviorSubject<number> = new BehaviorSubject(null);
  public selectedSubcategoryIndexSub: BehaviorSubject<number> = new BehaviorSubject(-1);
  public selectedCollectionIndexSub: BehaviorSubject<number> = new BehaviorSubject(-1);
  public selectedTabSub: BehaviorSubject<number> = new BehaviorSubject(SelectTabOption.Home);
  public categoriesLoadedSub: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public bannersLoadedSub: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public refreshBalance: Subject<string> = new Subject<string>();
  public deviceBack: Subject<boolean> = new Subject<boolean>();
  tomorrowDate: moment.Moment;
  showPagerOnHomeStrip = false;
  mbPassType = MB_PASS_TYPE;
  isCacheExpiredOrNot = {
    category: this.isCacheExpired(STORAGE_KEYS.MASTER_DATA_CATEGORY_CACHE_EXPIRATION, MASTER_DATA_CATEGORY_EXPIRATION_TIME),
    banner: this.isCacheExpired(STORAGE_KEYS.MASTER_DATA_BANNER_CACHE_EXPIRATION, MASTER_DATA_BANNER_EXPIRATION_TIME),
    collection: this.isCacheExpired(STORAGE_KEYS.HOME_COLLECTION_CACHE_EXPIRATION, HOME_COLLECTION_EXPIRATION_TIME)
  };
  categories: Array<Category> = this.isCacheExpiredOrNot.category ? null : this.getDataFromLocalStorage(STORAGE_KEYS.CATEGORIES);
  categoriesCollection: Array<{}> = this.getDataFromLocalStorage(STORAGE_KEYS.CATEGORIES_COLLECTION) || [];
  banners: Array<Banner> = this.isCacheExpiredOrNot.banner ? null : this.getDataFromLocalStorage(STORAGE_KEYS.BANNERS);
  staticMasterData: StaticMasterData = this.getDataFromLocalStorage(STORAGE_KEYS.STATIC_MASTER_DATA);
  baskets: Array<Basket> = [];
  impulse_products: Array<ImpulseProducts> = [];
  basket_subcategories: Array<BasketSubcategory> = [];
  homeCollections: any = this.isCacheExpiredOrNot.collection ? {} : this.getDataFromLocalStorage(STORAGE_KEYS.HOME_COLLECTION);
  userHub: number = 1;
  paymentMethodList: Array<any>;
  paymentOptions: Array<PaymentOption>;
  paymentOffers: Array<{}> = [];
  txnId: number;
  dataToSendForTrackPayment;
  preFetchInitiatedOrNot = false;
  successResponse;
  trackPayment;
  hero = {
    banner: this.getDataFromLocalStorage(STORAGE_KEYS.HERO_BANNER),
    collection: this.getDataFromLocalStorage(STORAGE_KEYS.HERO_COLLECTION),
  };
  previouslyOrderedCollection = this.getDataFromLocalStorage(STORAGE_KEYS.PREVIOUSLY_ORDERED_COLLECTION);
  isJioPayEnabled = false;
  paymentOptionSection = {
    wallet: {
      options: [],
      offers: [],
      commonOffers: []
    },
    upi: {
      options: {
        intent: [],
        collect: []
      },
      offers: [],
      commonOffers: []
    },
    payLater: {
      options: [],
      offers: [],
      commonOffers: []
    },
    CREDIT_CARD: {
      options: [],
      offers: [],
      commonOffers: []
    },
    DEBIT_CARD: {
      options: [],
      offers: [],
      commonOffers: []
    },
    SODEXO: {
      options: [],
      offers: [],
      commonOffers: []
    },
    cred: {
      options: [],
      offers: [],
      commonOffers: []
    },
    netBanking: {
      options: [],
      offers: [],
      commonOffers: []
    },
    redeemRewards: {
      options: [],
      offers: [],
      commonOffers: [],
      visible: false
    }
  };
  hasServiceWorkerSupport = false;
  personal_details: PersonalDetails;
  isValidAddress:boolean=true;
  localAddressStatus:number;
  firstAtbSucces: boolean = false;
  lastCheckoutVisitDate:string;
  cashbackSavings: number;
  private segmentsData;
  isShownJioStrip:boolean= false;
  categoryType;
  saving: Savings = this.getDataFromLocalStorage(STORAGE_KEYS.SAVINGS_DATA);
  jioStripData:JioStrip={
    mbpassBasketValue:0,
    currentCashback:0,
    chashBackCount:false
  };
  mbpassCategoryNames:string='';
  paymentConfig = {
    showOffers: false,
    showCommonOffers: false,
    showUpiOptions: false,
    showPaymentOffers: false
  };
 
  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private appsflyer: Appsflyer,
    private appAvailability: AppAvailability,
    private firebase: FirebaseX,
    private cleverTap: CleverTap,
    private platform: Platform,
    private dataService: DataService,
    private zone: NgZone,
    private eventService: EventsService,
    private storageService: StorageService,
    private socialSharing: SocialSharing,
    private router: Router,
    private navCtrl: NavController,
    private networkInterface: NetworkInterface,
    private taskRunner: TaskRunner,
    private webAppflyerService: WebAppflyerService
  ) {
    if ('serviceWorker' in navigator) {
      this.hasServiceWorkerSupport = true;
    }
  } 

  navigateToHome = () => {
    this.navCtrl.navigateRoot('/' + LISTING_ROUTES.HOME);
  };

  resetMasterData() {
    this.recurringOrder = null;
    this.preorderedBaskets = null;
    this.dataService.recurringRecommendations = {};
    this.dataService.activeOffers = [];
  }

  resetCacheData() {
    this.categories = null;
    this.categoriesCollection = [];
    this.banners = null;
    this.homeCollections = {};
    this.baskets = [];
    this.staticMasterData = null;
    this.isCacheExpiredOrNot = {
      category: true,
      banner: true,
      collection: true
    };
    this.hero = {banner : null, collection: null};
    this.isJioPayEnabled = false;
    this.dataService.targetMembership.next(null);
    this.removeDataFromLocalStorage(STORAGE_KEYS.TARGET_MEMBERSHIP);
    this.removeDataFromLocalStorage(STORAGE_KEYS.TARGET_MEMBERSHIP_COMPLETED_POPUP);
    this.removeDataFromLocalStorage(STORAGE_KEYS.TARGET_MEMBERSHIP_EXTENDED_POPUP);
    this.removeDataFromLocalStorage(STORAGE_KEYS.TARGET_MEMBERSHIP_COMPLETED_LOTTIE);
    this.removeDataFromLocalStorage(STORAGE_KEYS.STATIC_MASTER_DATA_VERSION);
    this.removeDataFromLocalStorage(STORAGE_KEYS.TWID_ELIGIBILITY_CACHE_EXPIRATION);
    this.removeDataFromLocalStorage(STORAGE_KEYS.MASTER_DATA_CATEGORY_CACHE_EXPIRATION);
    this.removeDataFromLocalStorage(STORAGE_KEYS.MASTER_DATA_BANNER_CACHE_EXPIRATION);
    this.removeDataFromLocalStorage(STORAGE_KEYS.HOME_COLLECTION_CACHE_EXPIRATION);
    this.removeDataFromLocalStorage(STORAGE_KEYS.HERO_BANNER);
    this.removeDataFromLocalStorage(STORAGE_KEYS.HERO_COLLECTION);
  }

  checkExpirationStatus() {
    this.isCacheExpiredOrNot = {
      category: this.isCacheExpired(STORAGE_KEYS.MASTER_DATA_CATEGORY_CACHE_EXPIRATION, MASTER_DATA_CATEGORY_EXPIRATION_TIME),
      banner: this.isCacheExpired(STORAGE_KEYS.MASTER_DATA_BANNER_CACHE_EXPIRATION, MASTER_DATA_BANNER_EXPIRATION_TIME),
      collection: this.isCacheExpired(STORAGE_KEYS.HOME_COLLECTION_CACHE_EXPIRATION, HOME_COLLECTION_EXPIRATION_TIME)
    };
  }

  executeParallelCalls () {
    //Category call
    this.getCateogries();
    
    //Banner call
   // this.getBanners();
    if (this.authService.isLoggedIn()) {
      //Basket call
      this.getBaskets();

      //Segments call
      this.getCustomerSegments().subscribe((segments)=>{
        this.segmentsData = segments;
        this.getCategoriesCollection();
      });
    }
  }

  getCateogries() {
    return new Promise((resolve)=>{
      this.httpService.post(APIs.HOME_CATEGORIES, { hideLoader : true }).subscribe((response: HTTPResponse) => {
        if(response && response.data && response.data.data ) { 
          if (this.isCacheExpired(STORAGE_KEYS.MASTER_DATA_CATEGORY_CACHE_EXPIRATION, MASTER_DATA_CATEGORY_EXPIRATION_TIME)) {
            this.categories = response.data.data;
            this.categoriesLoadedSub.next(true);
            this.updateCacheExpirationTime(STORAGE_KEYS.MASTER_DATA_CATEGORY_CACHE_EXPIRATION);
          }
          this.setDataInLocalStorage(STORAGE_KEYS.CATEGORIES, response.data.data);
        }
        resolve(true);
      });
    });
  }
  
  getCategoriesCollection() {
    this.getSegmentsIds().then((segmentIds)=>{
      const dataToSend = {
        segmentsIds: segmentIds,
        locationType: COLLECTION_LOCATION_TYPE.CATEGORY_COLLECTION,
        hideLoader : true
      };
      this.httpService.post(LISTING_APIS.COLLECTION_LOCATION_TYPE, dataToSend).subscribe((success: any) => {
        if (success?.data?.status) {
          if(success.data?.data?.length) {
            success.data.data?.forEach((collection) => { 
              if (collection['table'] && typeof (collection['table']) === 'string') {
                collection['table'] = Utilities.parseJSONString('"' + collection['table'] + '"', true);
              }
              collection['dataType'] = "collection";
              collection['table'].displayType = CollectionDisplayType.CategoryCollection; 
            });
            if(JSON.stringify(this.categoriesCollection) !== JSON.stringify(success.data.data)) {
              this.categoriesCollection = success.data.data;
              this.categoriesLoadedSub.next(true);
            }
          } else if(this.categoriesCollection !== success.data.data) {
            this.categoriesCollection = [];
            this.categoriesLoadedSub.next(true);
          }
          this.setDataInLocalStorage(STORAGE_KEYS.CATEGORIES_COLLECTION, success.data.data);
        }
      });
    });
  }

  getBanners() {
    this.httpService.post(APIs.HOME_BANNERS, { hideLoader : true, version: environment.codePushVersion }).subscribe((response: HTTPResponse) => {
      if(response && response.data && response.data.data) { 
        let homeBanners = [];
        let heroBanner = null;
        response.data.data.forEach((banner , index) => {
          if(banner.bannerType == BannerType.HeroBanner){
            banner.index = index;
            heroBanner = banner;
          } else {
            homeBanners.push(banner);
          }
        });
        
        if (this.isCacheExpired(STORAGE_KEYS.MASTER_DATA_BANNER_CACHE_EXPIRATION, MASTER_DATA_BANNER_EXPIRATION_TIME)) {
          this.banners = homeBanners;
          this.bannersLoadedSub.next(true);
          this.updateCacheExpirationTime(STORAGE_KEYS.MASTER_DATA_BANNER_CACHE_EXPIRATION);
        }
        this.setDataInLocalStorage(STORAGE_KEYS.BANNERS, homeBanners);

        if(heroBanner) {
          if (heroBanner.data && typeof (heroBanner.data) === 'string') {
            heroBanner.data = JSON.parse(heroBanner.data);
          }
          this.setDataInLocalStorage(STORAGE_KEYS.HERO_BANNER, heroBanner);
          this.hero.banner = heroBanner;
        } else {
          this.removeDataFromLocalStorage(STORAGE_KEYS.HERO_BANNER);
          this.hero.banner = null;
        }
      }
    });
  }

  getBaskets() {
    return new Promise((resolve)=>{
      this.httpService.get(LISTING_APIS.TOMORROW_BASKET, { hideLoader: true }).subscribe((success: HTTPResponse) => {
        if (success && success.data?.status) {
          this.baskets[0] = success.data.data; 
          this.dataService.runChangeDetForBasketUpdate.next(true);  
        }
        resolve(true);
      });
    });
  } 

  getHomeMasterData(){
    this.httpService.post(APIs.HOME_MASTER_DATA, {hideLoader: true}).subscribe((response: HTTPResponse) => {
      if (response && response.data && response.data.data) {
        this.staticMasterData = response.data.data;
        this.setDataInLocalStorage(STORAGE_KEYS.STATIC_MASTER_DATA, this.staticMasterData);
        this.taskRunner.defer(() => {
          this.masterData.superChargeType = SuperChargeType.Super_Charge_Expiry;
          if (this.staticMasterData?.isAppInbox && this.masterData?.isIntercom) {
            this.masterData.isIntercom = false;
          }
        });     
      }
    });
  }

  getMasterData(needsToHideLoader = true, registerCleverTap, cacheImages = false, makeParallelCalls = true, fromDataUIInterval = false, source = "Unknown") {
    this.eventService.trackEvents(EVENT_NAMES.DATA_UI_CALLED, { message: "Master Data Called", source: source }, false, fromDataUIInterval ? EventTrackingPlugins.Firebase :EventTrackingPlugins.All);
    if(makeParallelCalls){
      this.executeParallelCalls();
    }
    if(!this.authService.userData.cityObj) {
      const cityInfo = localStorage.getItem(STORAGE_KEYS.CITY);
      cityInfo && (this.authService.userData.cityObj = JSON.parse(cityInfo));
    }
    const requestParams: any = {
      city_id: this.authService.userData.cityObj
        ? this.authService.userData.cityObj.id
        : '',
      mb_version: { app_store: environment.appStoreVersion, code_push: environment.codePushVersion },
      source: this.dataService.currentPlatform
    };
    if (needsToHideLoader) {
      requestParams.hideLoader = true;
    }
    this.httpService.post(APIs.HOME_DATA, requestParams).subscribe(
      (response: any) => {
        this.eventService.trackEvents(EVENT_NAMES.DATA_UI_LOADED, { message: "Master Data Loaded", userAvailable: response && response.data?.user ? 'true': 'false', source: source }, false, fromDataUIInterval ? EventTrackingPlugins.Firebase :EventTrackingPlugins.All );
        if(!this.staticMasterData || (this.getDataFromLocalStorage(STORAGE_KEYS.STATIC_MASTER_DATA_VERSION) !== response.data?.version?.masterDataVersion)){
          this.setDataInLocalStorage(STORAGE_KEYS.STATIC_MASTER_DATA_VERSION, response.data?.version?.masterDataVersion);
          this.getHomeMasterData();
        }
        this.showPagerOnHomeStrip = false;
        // this.firebase.stopTrace(FIREBASE_TRACES.DATA_UI);
        this.masterData = (response && response.data) || this.masterData || {};

        this.taskRunner.defer(() => {
          this.clubSameOfferAcrossSubcategories();
        });
 
        this.taskRunner.defer(() => {
          this.homeStripGeneration();
        });

        this.taskRunner.defer(() => {
          this.masterDataUpdateObs.next(
            !!(this.masterData && Object.keys(this.masterData).length)
          );
        });
 
        if (this.masterData?.user) {
          this.userHub = this.masterData?.user?.hubId;
          this.masterData.userHub = this.masterData?.user?.hubId;
          this.authService.userData.cityObj = {
            id: response.data.user.city_id,
            name: response.data.user.city
          };
          this.setDataInLocalStorage(STORAGE_KEYS.CITY,this.authService.userData.cityObj);
          if(!localStorage.getItem(STORAGE_KEYS.RC_CUSTOMER_OFFER_DATE)){
            localStorage.setItem(STORAGE_KEYS.RC_CUSTOMER_OFFER_DATE,JSON.stringify( this.masterData[STORAGE_KEYS.RC_CUSTOMER_OFFER_DATE]));
          }
          
          this.taskRunner.defer(() =>this.getUserMembershipPlan());
          // this.getProductLaunchData();
          // this.getBrandLaunchData();
          this.taskRunner.defer(() => this.getMbPass());

          this.taskRunner.defer(() => {
            if (this.masterData?.user
              && this.masterData?.user?.throttle_status && (this.masterData?.user?.paid === 0 || this.masterData?.user?.inactivity_flag)) {
              this.masterData.user.throttle_status = 1;
            } else {
              this.masterData.user.throttle_status = 0;
            }
            if (this.masterData && this.masterData.userHub && this.masterData.bulk_order_hubs) {
              this.dataService.doesUserBelongToBulkBuyHub = (this.masterData.bulk_order_hubs.indexOf(this.masterData.userHub.toString()) > -1);
            }
          });

          // adding maxLength to top up for having length check in amount input box with addition of 1 because of rupee symbol in input area
          this.taskRunner.defer(() => {
            this.tomorrowDate = moment(this.baskets?.length && this.baskets[0].date);
          });
  
          this.taskRunner.defer(() => {
            this.checkvpsData(); 
          })
          
          
          this.taskRunner.defer(() => {
            this.dataService.updateFlagIsFrozenCheckEnabled();
          });

          this.taskRunner.defer(() => {
            this.updateMembershipAppliedFlag();
          });

          this.taskRunner.defer(() => {
            this.checkSavings();
          });
          
          this.taskRunner.defer(() => {
            this.checkIfReferringPossibleDirectlyViaDefinedSocialSharingChannels();
          });
          
          this.taskRunner.defer(() => {
            this.registerPlugins(registerCleverTap);
          });
          
          // Using this variable for AB testing of Search textfield being used on Single click or Double Click
          this.dataService.doubleClickJourneyOnSearch = (this.masterData.user.id % 7 === 0);

          this.taskRunner.defer(() => {
            this.getAllPreorderBaskets();
          });
          
          this.taskRunner.defer(() => {
            (Object.keys(this.dataService.activeOffersObject).length === 0 || (this.dataService.offersLastUpdatedOn !== moment().format('MM/DD/YYYY'))) && this.getOfferList();
          });
          
          // this.taskRunner.defer(() => {
          //   this.getRecurringRecommendations();
          // });
          
          this.taskRunner.defer(() => {
            this.recurringOrder && this.recurringCampaignModified();
          });
          
          this.taskRunner.defer(() => {
            this.getRecurringOrders();
          });
          
          this.taskRunner.defer(() => {
            !this.paymentOptions && this.getPaymentOptions();
          })
        } else {
          this.authService.isLogout.next(LOGOUT_MODE.DATA_UI_LOGOUT);
        }
        this.fireUserBalanceEvent(this.masterData?.balance);
        if (Utilities.isApp()) {
          cacheImages && response && response.data && this.cacheImages(response.data);
        }
      },
      () => {
        this.masterDataUpdateObs.next(true);
      }
    );
  }

  cacheImages(responseData) {
    if (this.hasServiceWorkerSupport) {
      return true;
    }
    responseData.banners && responseData.banners.length && responseData.banners.forEach(banner => {
      if (banner?.img && !(this.storageService.imageStorage.hasOwnProperty(banner.img))) {
        this.storageService.downloadImage({
          'imageName': banner.img,
          'actualImgSrc': environment.bannerPath + banner.img,
          'fileStorageDirectoryName': this.dataService.fileStorageDirectories.BANNER
        }, false);
      }
    });
    responseData.categories && responseData.categories.length && responseData.categories.forEach(category => {
      category.subcategories && category.subcategories.length && category.subcategories.forEach(subCategory => {
        if (subCategory?.img && !(this.storageService.imageStorage.hasOwnProperty(subCategory.img))) {
          this.storageService.downloadImage({
            'imageName': subCategory.img,
            'actualImgSrc': environment.subcatPath + subCategory.img,
            'fileStorageDirectoryName': this.dataService.fileStorageDirectories.CATEGORY
          }, false);
        }
      });
    });
  }

  checkSavings(){
    if(this.isSavingExpired() || !this.saving){
      // saving api call
      this.httpService.get(LISTING_APIS.SAVINGS, { hideLoader: true }).subscribe((success: HTTPResponse) => {
        if (success && success.data?.status) {
          this.saving = success.data?.data;
          //set api data in local
          this.setDataInLocalStorage(STORAGE_KEYS.SAVINGS_DATA,success.data.data);
          //set api call time
          this.updateCacheExpirationTime(STORAGE_KEYS.SAVINGS_LAST_UPDATED_ON);
        }
      });
    }
  }

  isSavingExpired(){
    let expired = false;
    const savingLastCalledOn = this.getDataFromLocalStorage(STORAGE_KEYS.SAVINGS_LAST_UPDATED_ON);
    if(savingLastCalledOn && this.saving){
      if(moment().diff(moment(savingLastCalledOn),'minutes') >= this.saving.ttl){
        expired = true;
      }
    }
    return expired;
  }

  checkvpsData() {
    if(this.categories && this.baskets && this.categories.length && this.baskets.length) {
      this.checkJioStrip();
    } else {
      Promise.all([this.getBaskets() && this.getCateogries()]).then(res =>{
        this.checkJioStrip();
      })
    }
  }

  updateMembershipAppliedFlag() {
    if(this.masterData && this.baskets && this.baskets.length) {
      this.checkMembershipAppliedFlag();
    } else {
      Promise.all([this.getBaskets()]).then(res =>{
        this.checkMembershipAppliedFlag();
      })
    }
  }

  checkMembershipAppliedFlag() {
    if (this.masterData.current_membership?.status === 2 ||
      (this.masterData.current_membership?.status === 1 && this.baskets[0]?.is_otdf === 0) ||
      (this.masterData.current_membership?.status === 0 && this.masterData.user.cinfo.arm === 1 && this.baskets[0]?.is_otdf === 0)
    ) {
      this.dataService.isMembershipApplied = true;
    } else {
      this.dataService.isMembershipApplied = false;
    }
  }

  checkJioStrip(){
    if(this.masterData?.customer_mb_pass && this.masterData?.customer_mb_pass?.validity === 1 && this.masterData?.customer_mb_pass?.sd ===1){
      this.isShownJioStrip = true;
      if(this.masterData?.customer_mb_pass?.offerCategoryType === MBPASS_OFFER_CATEGORY_TYPE.MIN_BASKET_VALUE || this.masterData?.customer_mb_pass?.offerCategoryValue.includes(-1)){
        this.categoryType = MBPASS_TYPE.PLATFORM;
        if(this.baskets?.length){
          this.jioStripData.mbpassBasketValue = (this.baskets[0]?.bill_details?.sub_total - this.baskets[0]?.bill_details?.sub_savings) || 0;
        } else{
          this.jioStripData.mbpassBasketValue = 0;
        }
        this.currentCashback();
      } else {
        this.categoryType = MBPASS_TYPE.CAT_SUBCAT;
        if(this.baskets?.length && this.baskets[0]?.products.length){
          this.calculateBasket();
        }
        this.mbPassOfferCategoryNames();
      }
    }
  }

  calculateBasket(){
    let basketValue = 0;
    this.baskets[0]?.products.forEach((product:any) => {
        if((this.masterData?.customer_mb_pass?.offerCategoryType === MBPASS_OFFER_CATEGORY_TYPE.CATEGORY && this.masterData?.customer_mb_pass?.offerCategoryValue.includes(product.cid)) && !product?.sample){
          basketValue += Number.parseFloat(''+product.price) * product.quantity;
        }
        if ((this.masterData?.customer_mb_pass?.offerCategoryType === MBPASS_OFFER_CATEGORY_TYPE.SUBCATEGORY && this.masterData?.customer_mb_pass?.offerCategoryValue.includes(product?.scid)) && !product?.sample) {
          basketValue += Number.parseFloat(''+product.price) * product.quantity;
        }
    })
    this.jioStripData.mbpassBasketValue = Number.parseInt(''+basketValue);
    this.currentCashback();
  }

  currentCashback(){
    if(this.baskets[0]?.products.length){
      if(this.masterData?.customer_mb_pass?.offerType === MBPASS_OFFER_TYPE.PERCENTAGE){
        this.jioStripData.currentCashback = parseFloat(((this.masterData?.customer_mb_pass?.offerValue/100)* this.jioStripData.mbpassBasketValue).toFixed(2));
      }
    } else if(this.baskets[0]?.products.length === 0){
      this.jioStripData.currentCashback =0;
    }
  }

  mbPassOfferCategoryNames() {
    this.mbpassCategoryNames = '';
    if(this.masterData?.customer_mb_pass?.offerCategoryValue.length === 1){
      if (this.categoryType === MBPASS_TYPE.CAT_SUBCAT) {
        if (this.masterData?.customer_mb_pass?.offerCategoryType === MBPASS_OFFER_CATEGORY_TYPE.CATEGORY) {
          this.categories.forEach((category) => {
            if (this.masterData?.customer_mb_pass?.offerCategoryValue.includes(category.id)&& !this.mbpassCategoryNames.includes(category.name)) {
              this.mbpassCategoryNames += category.name;
            }
          });
        } else {
          this.categories.forEach((category) => {
            category.subcategories.forEach((subCategory) => {
              if ((category?.id && this.masterData?.customer_mb_pass?.offerCategoryValue.includes(subCategory.id))) {
                this.mbpassCategoryNames += subCategory.name;
              }
            });
          });
        }
      }
    }
    
  }

  cashbackCharacterCount() {
    if (document.getElementById('cashback-count')) {
        let count = document.getElementById('cashback-count').textContent.length;
        let maxCharacter = 54;
        if (window.innerWidth <= 393) {
            maxCharacter = 45;
        }
        if (window.innerWidth >= 767 && window.innerWidth <= 820) {
            maxCharacter = 101;
        };
        if (count > maxCharacter) {
            this.jioStripData.chashBackCount = true;
        }
    }
  }

  isCacheExpired(key: string, timer: number) {
    let _isCacheExpired = true;
    const _cacheExpirationDate = this.getDataFromLocalStorage(key);
    if (_cacheExpirationDate) {
      const duration = moment.duration(moment().diff(moment(_cacheExpirationDate)));
      const milliSecondsDiff = duration.asMilliseconds();
      if (milliSecondsDiff && milliSecondsDiff < timer) {
        _isCacheExpired = false;
      }
    }
    return _isCacheExpired;
  }

  updateCacheExpirationTime(key: string) {
    this.setDataInLocalStorage(key, moment());
  }

  getDataFromLocalStorage(key) {
    const data = localStorage.getItem(key);  
    if(data && data !== "undefined") {
      return JSON.parse(data);
    }
    return null;
  }

  setDataInLocalStorage(key: string, data: any) {
    this.taskRunner.defer(() => localStorage.setItem(key, JSON.stringify(data)));
  }

  removeDataFromLocalStorage(key: string) {
    this.taskRunner.defer(() => localStorage.removeItem(key));
  }

  async registerPlugins(registerCleverTap) {
    const segmentIds = await this.getSegmentsIds();
    if(segmentIds) {
      this.masterData.segmentIds = segmentIds;
      new RegisterPlugins(
        this.platform,
        this.eventService,
        this.appsflyer,
        this.firebase,
        this.cleverTap,
        this.networkInterface
      ).doRegisterPlugins(this.masterData, registerCleverTap);
    }
  }

  getAllPreorderBaskets() {
    !this.preorderedBaskets && this.httpService.post(APIs.BASKETS,
      {
        period: GET_ALL_BASKETS_PERIOD.MONTH,
        pre_order: true,
        only_product_ids: true,
        hideLoader: !(this.router.url.indexOf(LISTING_ROUTES.YOUR_CART) > -1)
      }).subscribe((response: HTTPResponse) => {
        if (response?.data?.status) {
          this.preorderedBaskets = response.data.data;
        }
      });
  }

  // get recommendations for recurring
  getRecurringRecommendations() {
    !this.dataService.recurringRecommendations.position && this.httpService.get(APIs.REC_RECOMMENDATIONS, {
      hideLoader: true
    }).subscribe(
      (res: HTTPResponse) => {
        if (res?.data?.status) {
          this.dataService.recurringRecommendations = res.data.data;
          for (const item of res.data.data.recRecommends) {
            this.dataService.recRecommendationObject[ item.id ] = item;
          }
          res.data.data.recRecommends.forEach((product) => {
            if (this.masterData?.user && this.masterData.user?.is_mbeyond && product.mbeyondSp) {
              product.sp = product.mbeyondSp;
            }
            this.dataService.recRecommendationObject[ product.id ] = product;
          });
        }
      });
  }

  getRecurringOrders(forceUpdate = false) {
    (forceUpdate || !this.recurringOrder) && this.httpService.get(LISTING_APIS.CALENDAR_ORDER_LIST, {
      hideLoader: true
    }).subscribe(
      (res: HTTPResponse) => {
        if (res?.data?.status) {
          if (res.data.data.scheduledOrders) {
            for (const orders of res.data.data.scheduledOrders) {
              orders.frequency = 'once';
            }
          } else {
            res.data.data.scheduledOrders = [];
          }
          if (res.data.data.recurringOrders) {
            for (const orders of res.data.data.recurringOrders) {
              if (orders.frequency === 'weekly' && orders.frequencyMeta === 4) {
                orders.frequency = 'daily';
              }
            }
          } else {
            res.data.data.recurringOrders = [];
          }
          this.recurringOrder = res.data.data.recurringOrders.concat(res.data.data.scheduledOrders);
          this.recurringCampaignModified();
          if (forceUpdate) {
            this.dataService.recurringOrdersChange.next(true); 
          }
        }

      }
    );
  }

  getBrandLaunchData() {
    !this.brandLaunchdata && this.httpService.get(`${LISTING_APIS.BRAND_LAUNCH}?hubId=${this.userHub}&cityId=${(this.authService.userData.cityObj ? this.authService.userData.cityObj.id : '1')}`,
      {
        hideLoader: true
      }).subscribe((response: HTTPResponse) => {
        if (response.data && response.data?.status) {
          this.brandLaunchdata = response.data.data;
          this.brandLaunchSub?.next(this.brandLaunchdata);
        }
      });
  }

  getProductLaunchData() {
    !this.productLaunchdata && this.httpService.get(`${LISTING_APIS.PRODUCT_LAUNCH}?hubId=${this.userHub}&cityId=${(this.authService.userData.cityObj ? this.authService.userData.cityObj.id : '1')}`,
      {
        hideLoader: true
      }).subscribe((response: HTTPResponse) => {
        if (response.data && response.data?.status) {
          this.productLaunchdata = response.data.data;
          this.productLaunchSub?.next(this.productLaunchdata);
        }
      });
  }

  getOfferList(forcecall = false) {
    (!this.dataService.activeOffers?.length || forcecall) && this.httpService.get(APIs.ACTIVE_OFFERS, {
      hideLoader: true,
    }).subscribe(
      (res: HTTPResponse) => {
        if(this.dataService.activeOffers?.length) {
          return;
        }
        if (res?.data?.status) {
          this.dataService.offersLastUpdatedOn = moment().format('MM/DD/YYYY');
          for (const offer of res.data.data) {
            this.dataService.activeOffersObject[ offer.id ] = offer;
            if (offer.homeVisibility && offer.type !== 6 && offer.type !== 4) {
              this.dataService.activeOffers.push(offer);
            }
            if (offer.type === 3 || offer.type === 5) {
              this.updateTypeMapping(OfferType.brandOrManufacturer, offer.offerDetails.listId, offer.id);
            } else if (offer.type !== 4) {
              for (const typeId of offer.offerDetails.typeIds) {
                this.updateTypeMapping(offer.type, typeId, offer.id);
              }
            }
          }
          this.dataService.offerUpdate.next(true);
        }
      });
  }

  updateTypeMapping(type, typeId, offerId) {
    if (type in this.dataService.offerTypeMapping) {
      this.dataService.offerTypeMapping[ type ] = { ...this.dataService.offerTypeMapping[ type ], [ typeId ]: offerId };
    } else {
      this.dataService.offerTypeMapping[ type ] = {};
      this.dataService.offerTypeMapping[ type ][ typeId ] = offerId;
    }
  }

  getJioCashback() {
    this.httpService.post(APIs.JIO_MAHACASHBACK, { hideLoader: true }).subscribe((res: HTTPResponse) => {
      if (res && res.data) {
        this.mahaCashback = res.data?.data;
        this.eventService.trackEvents(EVENT_NAMES.JIO_CASHBACK, { jioCashback: res.data.data.balance.toFixed(2) });
        this.dataService.firebaseMasterData.jioCashBackConstruct.MODIFIED_THRESHOLD_VALUE = this.dataService.firebaseMasterData.jioCashBackConstruct.THRESHOLD_VALUE;
        if (res.data?.data?.balance && 0 < res.data.data.balance && res.data.data.balance <= this.dataService.firebaseMasterData.jioCashBackConstruct.SECONDARY_THRESHOLD) {
          this.dataService.firebaseMasterData.jioCashBackConstruct.MODIFIED_THRESHOLD_VALUE = this.dataService.firebaseMasterData.jioCashBackConstruct.THRESHOLD_VALUE + res.data?.data?.balance;
        } else if (res.data?.data?.balance && res.data?.data?.balance > this.dataService.firebaseMasterData.jioCashBackConstruct.SECONDARY_THRESHOLD) {
          this.dataService.firebaseMasterData.jioCashBackConstruct.MODIFIED_THRESHOLD_VALUE = this.dataService.firebaseMasterData.jioCashBackConstruct.THRESHOLD_VALUE + this.dataService.firebaseMasterData.jioCashBackConstruct.INITIAL_THRESHOLD;
        }
        this.mahaCashBackObs.next(true);
      }
    });
  }

  homeStripGeneration() {
    if (this.masterData?.user && this.masterData?.current_membership) {
      let whichMembershipObjectUse: any = {};
      if (this.masterData?.memberships && this.masterData?.memberships.length) {
        whichMembershipObjectUse = this.masterData?.memberships[ this.masterData?.memberships.length - 1 ];
      } else {
        whichMembershipObjectUse = this.masterData?.current_membership;
      }
      this.masterData.current_membership.endDate && (this.masterData.current_membership.daysLeft =
        moment(this.masterData.current_membership.endDate).diff(moment().startOf('day'), 'days'));
      this.masterData.current_membership.displayStartDate =
        moment(this.masterData.current_membership.startDate).format('DD MMM \'YY');
      this.masterData.current_membership.displayEndDate = moment(this.masterData.current_membership.endDate).format('DD MMM \'YY');
      // Status 0: Non-member and Not added, 1: Non-member and added, 2: Membership applied
      this.masterData.current_membership.isMember = this.masterData.current_membership?.status === 2;
      // State 0,1: Existing Non-Member, 2: Existing Applied Member, 3: New non-member, 4: New Applied member
      switch (this.masterData?.current_membership?.status) {
        case 0:
        case 1:
          this.masterData.current_membership.state = this.masterData.current_membership.sp === 0 ? 3 : 0;
          break;
        case 2:
          this.masterData.current_membership.state = this.masterData.current_membership.sp === 0 ? 4 : 2;
          break;
      }
      this.zone.run(() => {
        this.dataService.homeStripContent = Object.assign({}, HOME_STRIP_CONTENTS);
        this.dataService.homeStripContent[ this.masterData?.current_membership?.state ]
          && (this.dataService.homeStripContent[ this.masterData.current_membership.state ] =
            Utilities.replaceKeysWithValues(this.dataService.homeStripContent[ this.masterData?.current_membership?.state ], {
              daysLeft: this.masterData?.current_membership?.daysLeft
            }));
        if (this.masterData?.wallet_increase_offers && this.masterData.wallet_increase_offers.length) {
          const homeOfferStripContent = Object.assign({}, HOME_OFFER_STRIP_CONTENTS);
          if (this.masterData?.current_membership?.daysLeft > 0 && this.masterData?.current_membership?.isMember) {
            this.dataService.homeOfferStripContent = Utilities.replaceKeysWithValues(homeOfferStripContent.hasSubscription, { daysLeft: this.masterData?.current_membership?.daysLeft });
          } else {
            this.dataService.homeOfferStripContent = homeOfferStripContent.hasNotSubscription;
          }
        }
      });
    
      this.showPagerOnHomeStrip = this.masterData && this.masterData?.wallet_increase_offers &&
      this.masterData?.wallet_increase_offers.length && this.masterData?.current_membership && this.masterData?.current_membership?.isMember;
      if(this.masterData?.upcoming_membership?.endDate){
        this.masterData.current_membership.daysLeft =
        moment(this.masterData.upcoming_membership.endDate).diff(moment().startOf('day'), 'days');
      }

    }
  }

  getMbPass(callingWhenPurchased = false) {
    if (this.masterData?.customer_mb_pass
      && this.masterData?.customer_mb_pass?.status) {
      this.dataService.mbPassData = this.masterData?.customer_mb_pass;
      this.dataService.mbPassData.purchased = true;
    } else if (this.masterData?.mbpass_customer_segments && this.masterData?.mbpass_customer_segments.length) {
      this.dataService.mbPassData = this.masterData?.mbpass_customer_segments[ 0 ];
      this.dataService.mbPassData.purchased = false;
    } else {
      this.dataService.mbPassData = {};
    }
    if (typeof this.dataService.mbPassData.multipleScreenDescription === 'string') {
      this.dataService.mbPassData.multipleScreenDescription = Utilities.parseJSONString('"' + this.dataService.mbPassData.multipleScreenDescription + '"', true);
      this.dataService.mbPassData.totalSavingPercentage = this.dataService.mbPassData.totalSavings / this.dataService.mbPassData.maxCashback * 100;
    }
    if (!callingWhenPurchased) {
      this.dataService.mbPassData.applyCategoryAndSubcategory = {};
      if (this.dataService.mbPassData.offerCategoryValue) {
        this.dataService.mbPassData.offerCategoryValue.forEach(element => {
          this.dataService.mbPassData.applyCategoryAndSubcategory[ element ] = true;
        });
      }
      let newCategory: any = {
        name: null,
        id: 0,
        subcategories: []
      };
      if (this.dataService.mbPassData.offerCategoryValue) {
        if (this.dataService.mbPassData.offerCategoryValue.length > 1) {
          if (this.dataService.mbPassData.offerCategoryType === this.mbPassType.SUB_CATEGORY) {
            this.categories?.forEach((category) => {
              category.subcategories.forEach((subcategory) => {
                if (this.dataService.mbPassData.applyCategoryAndSubcategory[ subcategory.id ]) {
                  newCategory.subcategories.push(subcategory);
                }
              });
            });
          } else if (this.dataService.mbPassData.offerCategoryType === this.mbPassType.CATEGORY) {
            this.categories?.forEach((category) => {
              if (this.dataService.mbPassData.applyCategoryAndSubcategory[ category.id ]) {
                this.dataService.mbPassData.applyCategoryAndSubcategory[ 0 ] = true;
                newCategory.subcategories = newCategory.subcategories.concat(category.subcategories);
              }
            });
          }
        } else if (this.dataService.mbPassData.offerCategoryType === this.mbPassType.CATEGORY) {
          this.categories?.forEach((category, index) => {
            if (this.dataService.mbPassData.applyCategoryAndSubcategory[ category.id ]) {
              newCategory = category;
              this.categories?.splice(index, 1);
            }
          });
        } else if (this.dataService.mbPassData.offerCategoryType === this.mbPassType.SUB_CATEGORY) {
          this.categories?.forEach((category) => {
            category.subcategories.forEach((subcategory) => {
              if (this.dataService.mbPassData.applyCategoryAndSubcategory[ subcategory.id ]) {
                newCategory.subcategories.push(subcategory);
              }
            });
          });
        }
        if (newCategory?.subcategories?.length) {
          this.categories?.unshift(newCategory);
        }
      }
      this.dataService.refreshMbPass.next(true);
    }
  }

  // Method to loop through the payment options available and take action as per availabilityCheck flag.
  async checkAppAvailabilityBasedOnPaymentOptions() {
    this.paymentOptions.forEach((res, index) => {
      if (!!res && !!res.availabilityCheck && !!res.packageName && Utilities.isApp()) {
        if(this.platform.is(IOS)) {
          this.checkIfAppInstalled(res.packageName, index);
        } else {
          this.mergingOffers(res);
          this.paymentOptions[ index ].isAllowed = false;
        }
      } else {
        this.mergingOffers(res);
        this.paymentOptions[ index ].isAllowed = true;
      }
    });
    Utilities.offerSort(this.paymentOffers);
  }

  createPaymentOption(paymentOption) {
    switch (paymentOption.value) {
      case PG_WISE_JUSPAY_PAY_LOAD.mobikwik.pgName:
      case PG_WISE_JUSPAY_PAY_LOAD.paytm.pgName:
      case PG_WISE_JUSPAY_PAY_LOAD.airtel_money.pgName:
      case PG_WISE_JUSPAY_PAY_LOAD.freecharge.pgName:
      case PG_WISE_JUSPAY_PAY_LOAD.amazon_pay.pgName:
        this.paymentOptionSection.wallet.options.push({
          icon: PG_WISE_JUSPAY_PAY_LOAD[ paymentOption.value ].icon,
          option: paymentOption
        });
        if (paymentOption.offers) {
          this.paymentOptionSection.wallet.offers = this.paymentOptionSection.wallet.offers.concat(paymentOption.offers);
        }
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.paytm_upi.pgName:
      case PG_WISE_JUSPAY_PAY_LOAD.google_pay.pgName:
      case PG_WISE_JUSPAY_PAY_LOAD.phone_pe.pgName:
        this.paymentOptionSection.upi.options.intent.push({
          icon: PG_WISE_JUSPAY_PAY_LOAD[ paymentOption.value ].icon,
          option: paymentOption
        });
        if (paymentOption.offers) {
          paymentOption.offers = paymentOption.offers.map((offer: any) => ({...offer, type: paymentOption.value, visible: this.platform.is(ANDROID) ? false : true}));
          this.paymentOptionSection.upi.offers = this.paymentOptionSection.upi.offers.concat(paymentOption.offers);
        }
        if(paymentOption.value === PG_WISE_JUSPAY_PAY_LOAD.google_pay.pgName) {
          PG_WISE_JUSPAY_PAY_LOAD.google_pay.payload.payWithApp = paymentOption.packageName;
        }
        if(paymentOption.value === PG_WISE_JUSPAY_PAY_LOAD.paytm_upi.pgName) {
          PG_WISE_JUSPAY_PAY_LOAD.paytm_upi.payload.payWithApp = paymentOption.packageName;
        }
        if(paymentOption.value === PG_WISE_JUSPAY_PAY_LOAD.phone_pe.pgName) {
          PG_WISE_JUSPAY_PAY_LOAD.phone_pe.payload.payWithApp = paymentOption.packageName;
        }
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.UPI.pgName:
        this.paymentOptionSection.upi.options.collect.push({
          icon: PG_WISE_JUSPAY_PAY_LOAD[ paymentOption.value ].icon,
          option: paymentOption
        });
        if (paymentOption.offers) {
          paymentOption.offers = paymentOption.offers.map((offer: any) => ({...offer, type: paymentOption.value}));
          this.paymentOptionSection.upi.offers = this.paymentOptionSection.upi.offers.concat(paymentOption.offers);
        }
        if (paymentOption.commonOffers) {
          paymentOption.commonOffers = paymentOption.commonOffers.map((offer: any) => ({...offer, type: paymentOption.value}));
          this.paymentOptionSection.upi.commonOffers = this.paymentOptionSection.upi.commonOffers.concat(paymentOption.commonOffers);
        }
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.lazy_pay.pgName:
        this.paymentOptionSection.payLater.options.push({
          icon: PG_WISE_JUSPAY_PAY_LOAD.lazy_pay.icon,
          option: paymentOption
        });
        if (paymentOption.offers) {
          this.paymentOptionSection.payLater.offers = this.paymentOptionSection.payLater.offers.concat(paymentOption.offers);
        }
        if(paymentOption.commonOffers) {
          this.paymentOptionSection.payLater.commonOffers = this.paymentOptionSection.payLater.commonOffers.concat(paymentOption.commonOffers);
        }
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName:
        this.paymentOptionSection.CREDIT_CARD.options.push({
          icon: PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.icon,
          option: paymentOption
        });
        if (paymentOption.offers) {
          this.paymentOptionSection.CREDIT_CARD.offers = this.paymentOptionSection.CREDIT_CARD.offers.concat(paymentOption.offers);
        }
        if(paymentOption.commonOffers) {
          this.paymentOptionSection.CREDIT_CARD.commonOffers = this.paymentOptionSection.CREDIT_CARD.commonOffers.concat(paymentOption.commonOffers);
        }
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName:
        this.paymentOptionSection.DEBIT_CARD.options.push({
          icon: PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.icon,
          option: paymentOption
        });
        if (paymentOption.offers) {
          this.paymentOptionSection.DEBIT_CARD.offers = this.paymentOptionSection.DEBIT_CARD.offers.concat(paymentOption.offers);
        }
        if(paymentOption.commonOffers) {
          this.paymentOptionSection.DEBIT_CARD.commonOffers = this.paymentOptionSection.DEBIT_CARD.commonOffers.concat(paymentOption.commonOffers);
        }
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.SODEXO.pgName:
        this.paymentOptionSection.SODEXO.options.push({
          icon: PG_WISE_JUSPAY_PAY_LOAD.SODEXO.icon,
          option: paymentOption
        });
        if (paymentOption.offers) {
          this.paymentOptionSection.SODEXO.offers = this.paymentOptionSection.SODEXO.offers.concat(paymentOption.offers);
        }
        if(paymentOption.commonOffers) {
          this.paymentOptionSection.SODEXO.commonOffers = this.paymentOptionSection.SODEXO.commonOffers.concat(paymentOption.commonOffers);
        }
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.NET_BANKING.pgName:
        this.paymentOptionSection.netBanking.options.push({
          icon: PG_WISE_JUSPAY_PAY_LOAD.NET_BANKING.icon,
          option: paymentOption
        });
        if (paymentOption.offers) {
          this.paymentOptionSection.netBanking.offers = this.paymentOptionSection.netBanking.offers.concat(paymentOption.offers);
        }
        if(paymentOption.commonOffers) {
          this.paymentOptionSection.netBanking.commonOffers = this.paymentOptionSection.netBanking.commonOffers.concat(paymentOption.commonOffers);
        }
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.cred.pgName:
        this.paymentOptionSection.cred.options.push({
          icon: PG_WISE_JUSPAY_PAY_LOAD.cred.icon,
          option: paymentOption
        });
        if (paymentOption.offers) {
          this.paymentOptionSection.cred.offers = this.paymentOptionSection.cred.offers.concat(paymentOption.offers);
        }
        if (paymentOption.commonOffers) {
          this.paymentOptionSection.cred.commonOffers = this.paymentOptionSection.cred.commonOffers.concat(paymentOption.commonOffers);
        }
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.TWID.pgName:
        this.paymentOptionSection.redeemRewards.options.push({
          icon: PG_WISE_JUSPAY_PAY_LOAD.TWID.icon,
          option: paymentOption
        });
        if (paymentOption.offers) {
          this.paymentOptionSection.redeemRewards.offers = this.paymentOptionSection.redeemRewards.offers.concat(paymentOption.offers);
        }
        if (paymentOption.commonOffers) {
          this.paymentOptionSection.redeemRewards.commonOffers = this.paymentOptionSection.redeemRewards.commonOffers.concat(paymentOption.commonOffers);
        }
        break;
    }
  }

  getPaymentOptions(hideLoaderParam?) {
    let dataToSend  = {
      source: this.dataService.currentPlatform, mb_version: { app_store: environment.appStoreVersion, code_push: environment.codePushVersion }
    }
    if(!hideLoaderParam){
      dataToSend['hideLoader'] = true;
    }
    this.httpService.post(APIs.PAYMENT_OPTIONS, dataToSend).subscribe((response: HTTPResponse) => {
      if (response?.data?.status) {
        this.paymentMethodList = response.data.data;
        this.paymentMethodList.sort((a, b) => a.sortOrder - b.sortOrder);
        this.paymentOptions = [];
        this.paymentOffers = [];
        this.resetPaymentOptionSection();
        if(response.data.allOffers && response.data.allOffers.length) {
          response.data.allOffers.forEach((offer) => {
            this.paymentOffers.push({ offer: offer.offer, offerDescription: offer.offer_description , name: 'All',sortOrder:offer.sortOrder, createdOn:offer.createdOn, visible: true});
          });
        }
        this.paymentMethodList.forEach(method => {
          method.paymentOptionBeanList?.forEach((paymentOptionBean) => {
            this.paymentOptions.push({...paymentOptionBean, ...{visible: paymentOptionBean.value === PAYMENT_VALUES.TWID ? false: true}});
          });
          method.paymentOptionBeanList?.forEach((paymentOption) => {
            if(response.data.allOffers) {
              paymentOption['commonOffers'] =  response.data.allOffers;
            } else {
              paymentOption['commonOffers'] = [];
            }
            method.allMethodOffers && (paymentOption['commonOffers'] = paymentOption['commonOffers'].concat(method.allMethodOffers));
          });
          method.allMethodOffers?.length &&  method.allMethodOffers.forEach(offerData => {
            this.paymentOffers.push({ offer: offerData.offer, offerDescription: offerData.offer_description , name: 'All',sortOrder:offerData.sortOrder, createdOn:offerData.createdOn, visible: true});
          });
        });
        this.checkAppAvailabilityBasedOnPaymentOptions();
        this.preFetchInitiatedOrNot && this.platform.is(ANDROID) && this.checkUPIAppAvailability.next(true);
      }
    });
  }


  // This method is to check is app installed on the users device or not.
  checkIfAppInstalled(packageName, index) {
    this.appAvailability.check(packageName).then(
      () => {
        this.paymentOptions[ index ].isAllowed = true;
        this.mergingOffers(this.paymentOptions[ index ]);
      },
      () => {
        this.paymentOptions[ index ].isAllowed = false;
      }
    );
  }

  mergingOffers(paymentOptions) {
    this.createPaymentOption(paymentOptions);
    paymentOptions.offers && paymentOptions.offers.forEach((offer) => {
      let visible = true;
      if(paymentOptions.value === PAYMENT_VALUES.TWID || paymentOptions.value === PAYMENT_VALUES.PAYTM_UPI || paymentOptions.value === PAYMENT_VALUES.GOOGLE_PAY || paymentOptions.value === PAYMENT_VALUES.PHONEPE){
        visible = false;
      }
      this.paymentOffers.push({ offer: offer.offer, offerDescription: offer.offer_description , name: paymentOptions.name, sortOrder:offer.sortOrder, createdOn:offer.createdOn, type: paymentOptions.value, visible: visible});
    });
  }

  checkAppFeasibleForPayment(options) {
    this.paymentOptionSection.upi.options.intent.forEach((intent) => {
      const index = options.findIndex(option => option.packageName === intent.option.packageName);
      intent.option.isAllowed = false;
      if(index > -1) {
        intent.option.isAllowed = true;
        this.paymentConfig.showUpiOptions = true;
        this.updateOfferVisibility(true,intent.option.value);
      } else{
        this.updateOfferVisibility(false,intent.option.value);
      }
    });
    this.paymentConfig.showPaymentOffers = this.paymentOffers.filter((offer: any) => offer.visible)?.length ? true : false;
    this.dataService.runChangeDetForPaymentData.next(true);
  }

  updateOfferVisibility(isVisible, pgValue){
    this.paymentOffers?.forEach((offer:any) => {
      if(offer.type === pgValue) {
        offer.visible = isVisible;
      }
    });
    this.paymentOptionSection.upi.offers.forEach((offer: any)=>{
      if(offer.type === pgValue) {
        offer.visible = isVisible;  
        isVisible && (this.paymentConfig.showOffers = true);
      }
    });
    this.paymentOptionSection.upi.commonOffers.forEach((offer: any)=>{
      if(offer.type === pgValue) {
        offer.visible = isVisible;
        isVisible && (this.paymentConfig.showCommonOffers = true);
      }
    });
  }

  getSelectedIndexes(subCategoryId) {
    this.selectedCategory = this.categories?.find(category => {
      if (category.id) {
        this.selectedSubcategoryIndex = category.subcategories.findIndex(
          subcategory => {
            return subCategoryId === subcategory.id;
          }
        );
        return this.selectedSubcategoryIndex > -1;
      }
    });
  }

  getCategoryDetails(categoryId) {
    const categoryDetails = this.categories?.find(category => {
      return category.id === categoryId;
    });
    return categoryDetails;
  }

  updatePhpTokenToJavaToken() {
    return this.httpService.get(APIs.LEGACY_TOKEN, { hideLoader: true });
  }

  async checkIfReferringPossibleDirectlyViaDefinedSocialSharingChannels() {
    if (Utilities.isApp()) {
      Object.keys(SOCIAL_SHARING_CHANNELS).forEach((channel) => {
        this.checkIfSharingViaGivenChannelPossible(SOCIAL_SHARING_CHANNELS[ channel ]);
      });
    }
  }

  checkIfSharingViaGivenChannelPossible(channel) {
    this.socialSharing.canShareVia(channel[ this.dataService.currentPlatform ]).then(() => {
      channel.isAvailable = true;
    }).catch(() => {
      channel.isAvailable = false;
    });
  }

  clubSameOfferAcrossSubcategories() {
    if (this.masterData.catalogOffer) {
      this.masterData.activeOffer = this.masterData.catalogOffer;
      (this.masterData.catalogOffer.type === 'all') &&
        (this.masterData.activeOffer.cta = this.navigateToHome);
    }
    const subCategoriesWithSameOffer: { number?: Category } = {};
    this.categories?.forEach((category) => {
      if (!this.masterData.activeOffer) {
        this.masterData.activeOffer = category.catalogOffer;
        if (this.masterData.activeOffer) {
          this.masterData.activeOffer.type = 'category';
          (this.masterData.activeOffer.cta = () => {
            this.selectedCategory = category;
            this.selectedSubcategoryIndex = 0;
            this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.CATEGORY + category.id);
          });
        }
      } else {
        this.masterData.activeOffer.cta = this.navigateToHome;
      }
      category.subcategories.forEach((subcategory, subCategoryIndex) => {
        if (subcategory.catalogOffer && subcategory.catalogOffer.id) {
          subcategory.categoryId = category.id;
          subcategory.subCategoryIndex = subCategoryIndex;
          if (subCategoriesWithSameOffer[ subcategory.catalogOffer.id ]) {
            subCategoriesWithSameOffer[ subcategory.catalogOffer.id ].subcategories.push(subcategory);
          } else {
            subCategoriesWithSameOffer[ subcategory.catalogOffer.id ] = { subcategories: [ subcategory ], catalogOffer: subcategory.catalogOffer };
          }
        }
      });
    });
    if (!Utilities.isEmpty(subCategoriesWithSameOffer)) {
      for (const offerId in subCategoriesWithSameOffer) {
        if (subCategoriesWithSameOffer.hasOwnProperty(offerId)) {
          this.masterData.activeOffer = subCategoriesWithSameOffer[ offerId ].catalogOffer;
          this.masterData.activeOffer.type = 'subcategory';
          if (subCategoriesWithSameOffer[ offerId ].subcategories?.length >= 2) {
            this.categories?.unshift(subCategoriesWithSameOffer[ offerId ]);
            this.masterData.activeOffer.cta = () => {
              const redirectToSubcategoryId = this.categories[ 0 ].subcategories[ 0 ].id;
              this.getSelectedIndexes(redirectToSubcategoryId);
              this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.CATEGORY + redirectToSubcategoryId);
            };
          } else {
            this.masterData.activeOffer.cta = () => {
              this.selectedCategory = this.categories?.find((cat) => {
                return cat.id === subCategoriesWithSameOffer[ offerId ].subcategories[ 0 ].categoryId;
              });
              this.selectedSubcategoryIndex = subCategoriesWithSameOffer[ offerId ].subcategories[ 0 ].subCategoryIndex;
              this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.SUBCATEGORY + subCategoriesWithSameOffer[ offerId ].subcategories[ 0 ].id);
            };
          }
        }
      }
    }
  }
  getRecurringCampaignOfferDetails() {
    this.httpService.post(APIs.CASHBACK_OFFERS, { hideLoader: false }).subscribe((res: HTTPResponse) => {
      if (res && res.data) {
        if (res.data[ 'rec' ]) {
          if (!localStorage.getItem(STORAGE_KEYS.SUBSCRIBE_AND_SAVE_NUDGE)) {
            this.dataService.subscribeAndSaveNudge = true;
          }
        }
        localStorage.setItem(STORAGE_KEYS.RECURRING_CAMPAIGN, JSON.stringify(res.data));
        this.subscribeToSaveTrigger.next(true);
      }
    });
  }

  fireUserBalanceEvent(balance) {
    this.eventService.trackEvents(EVENT_NAMES.USER_BALANCE, {
      userBalance: balance
    });
  }

  recurringCampaignModified() {
    const recurringOffer = JSON.parse(localStorage.getItem(STORAGE_KEYS.RECURRING_CAMPAIGN));
    const rcEffectiveDate = JSON.parse(localStorage.getItem(STORAGE_KEYS.RC_EFFECTIVE_DATE));
    let customerOfferDate=null;
    if(localStorage.getItem(STORAGE_KEYS.RC_CUSTOMER_OFFER_DATE) && localStorage.getItem(STORAGE_KEYS.RC_CUSTOMER_OFFER_DATE) !== 'null' && localStorage.getItem(STORAGE_KEYS.RC_CUSTOMER_OFFER_DATE) !== 'undefined'){
       customerOfferDate = JSON.parse(localStorage.getItem(STORAGE_KEYS.RC_CUSTOMER_OFFER_DATE));
    }

      if (recurringOffer && recurringOffer[ STORAGE_KEYS.RC_MODIFIED_DATE ]) {
        if (!moment(new Date(this.masterData[ 'recOfferModifiedDate' ])).isSame(new Date(recurringOffer[ STORAGE_KEYS.RC_MODIFIED_DATE ]))) {
          //This code required in future
          // this.dataService.subscribeAndSaveNudge = true;
          this.getRecurringCampaignOfferDetails();
        }   
        if (recurringOffer) {
          this.subscribeToSaveTrigger.next(true);
        }
      } else if (!(recurringOffer && recurringOffer[STORAGE_KEYS.RC_MODIFIED_DATE]) && this.masterData['recOfferModifiedDate']) {
        let rcDataUiDate = localStorage.getItem(STORAGE_KEYS.DATA_UI_RC_MODIFIED_DATE);
        if(rcDataUiDate && rcDataUiDate !== 'null' && rcDataUiDate !== 'undefined'){
          rcDataUiDate = JSON.parse(rcDataUiDate);
       } else{
        rcDataUiDate =null;
       }

        if(!moment(new Date(this.masterData[ STORAGE_KEYS.RC_MODIFIED_DATE ])).isSame(new Date(rcDataUiDate)) ){
        localStorage.setItem(STORAGE_KEYS.DATA_UI_RC_MODIFIED_DATE,JSON.stringify(this.masterData[STORAGE_KEYS.RC_MODIFIED_DATE]));
        this.offerReload(recurringOffer);
      }
      }
      if(!moment(new Date(this.masterData[ STORAGE_KEYS.RC_CUSTOMER_OFFER_DATE ])).isSame(new Date(customerOfferDate)) ){
        localStorage.setItem(STORAGE_KEYS.RC_CUSTOMER_OFFER_DATE,JSON.stringify(this.masterData[STORAGE_KEYS.RC_CUSTOMER_OFFER_DATE]));
        this.offerReload(recurringOffer);
      }

      if (recurringOffer && recurringOffer?.rec?.ef_to && moment(new Date()).isAfter(new Date(recurringOffer?.rec?.ef_to)) || rcEffectiveDate) {
        localStorage.setItem(STORAGE_KEYS.RC_EFFECTIVE_DATE, JSON.stringify(false));
        this.offerReload(recurringOffer);
      }   
  }

  offerReload(recurringOffer){
    this.getRecurringCampaignOfferDetails();
    if (recurringOffer) {
      this.subscribeToSaveTrigger.next(true);
    }

  }

  resetPaymentOptionSection() {
    this.paymentOptionSection = {
      wallet: {
        options: [],
        offers: [],
        commonOffers: []
      },
      upi: {
        options: {
          intent: [],
          collect: [],
        },
        offers: [],
        commonOffers: []
      },
      payLater: {
        options: [],
        offers: [],
        commonOffers: []
      },
      CREDIT_CARD: {
        options: [],
        offers: [],
        commonOffers: []
      },
      DEBIT_CARD: {
        options: [],
        offers: [],
        commonOffers: []
      },
      SODEXO: {
        options: [],
        offers: [],
        commonOffers: []
      },
      cred: {
        options: [],
        offers: [],
        commonOffers: []
      },
      netBanking: {
        options: [],
        offers: [],
        commonOffers: []
      },
      redeemRewards: {
        options: [],
        offers: [],
        commonOffers: [],
        visible: false
      }
    };
    this.paymentConfig.showOffers = false;
    this.paymentConfig.showPaymentOffers = false;
    this.paymentConfig.showCommonOffers = false;
    this.paymentConfig.showUpiOptions = false;
  }

  getProfileDetails() {
    this.httpService.get(APIs.SAVE_PROFILE)
      .subscribe((response: any) => {
        if (response.data) {
          this.personal_details = response.data;
          if(parseInt(this.personal_details?.number_of_family_members)  > 6 ) 
          this.personal_details.number_of_family_members = ">6";
          this.personal_details.characterstics && this.personal_details.characterstics?.split(",");
        }
      })
  }

  getUserMembershipPlan() {
    const discount = [];
    this.dataService.membershipDiscount ={};
      this.httpService.get(LISTING_APIS.GET_MEMBERSHIP_PLANS).subscribe((response:HTTPResponse) => {
        if (response?.data?.length) {
          this.dataService.membershipPlans = response.data;
          response.data.map(plan => {
            if (plan?.discountAmt) {
              this.dataService.membershipDiscount['isGrossDiscountExits'] = true;
            }
            if (plan?.segmentDiscountAmt) {
              this.dataService.membershipDiscount['isExclusiveDiscountExits'] = true;
            }
            discount.push(plan?.discount);
          });
          this.masterData.maxMembershipPercentValue = Math.max(...discount);
          if (this.masterData?.current_membership) {
            this.masterData.current_membership.isNew = true;
          }
          if (response?.data?.length === 0 && this.masterData?.current_membership) {
            this.masterData.current_membership.isNew = false;
          }
        }
    });
  }

  customerCurrentMembership(membershipData,setDaysLeft = true){
    const currentMembership = this.masterData.current_membership;
    if(currentMembership?.status !== membershipData?.status || 
      currentMembership?.endDate !== membershipData?.endDate || 
      currentMembership?.startDate !== membershipData?.startDate) {
      this.dataService.refetchMembershipStrip.next(MEMBERSHIP_CACHE_TYPES.ALL);
    }
    this.masterData.current_membership.status = membershipData?.status;
    this.masterData.current_membership.endDate = membershipData.endDate;
    this.masterData.current_membership.startDate = membershipData.startDate;
    this.masterData.current_membership.validity = membershipData.validity;
    this.masterData.current_membership.amount = membershipData.amount;
    this.masterData.current_membership.mid = membershipData.mid;
    this.masterData.current_membership.sp = membershipData.sp;
    setDaysLeft &&  this.masterData.current_membership.endDate && (this.masterData.current_membership.daysLeft =
      moment(this.masterData.current_membership.endDate).diff(moment().startOf('day'), 'days'));
  }

  hideUserSegmentedMembershipStrip(){
    this.masterData.current_membership['touchpoint_visibility'].CART_PAGE = false;
    this.masterData.current_membership['touchpoint_visibility'].HOME_PAGE = false;
    this.masterData.current_membership['touchpoint_visibility'].PROFILE_PAGE = false;
  }
  
  getRedirectionToPlayStoreOrAppStore() {
    if(Utilities.isAndroid()) {
      window.location.href = this.webAppflyerService.oneLinkResultUrl ? this.webAppflyerService.oneLinkResultUrl : environment.playStoreRedirection;
    } else {
      window.location.href = this.webAppflyerService.oneLinkResultUrl ? this.webAppflyerService.oneLinkResultUrl : environment.appStoreRedirection;
    }
  }

  bottomStrip(){
    this.httpService.post(APIs.CAMPAIGN_STRIP,{})
    .subscribe((response: HTTPResponse) => {
      if (response?.status) {
        this.masterData.customer_campaign_cashback  = response.data.data;
      }
    })
  }

  getSegmentsIds() {
    return new Promise((resolve)=>{
      if(this.segmentsData) {
        resolve(this.segmentsData.map(segments => segments.segmentId));
      }
      else {
        this.getCustomerSegments().subscribe((segment)=>{
          resolve(this.segmentsData.map(segments => segments.segmentId));
        });
      }
    });
  }

  getCustomerSegmentsGroupBy(): Observable<object>  {
    if(this.segmentsData) {
      return of(Utilities.groupByKey(this.segmentsData, 'segmentType'));
    }
    else {
      this.getCustomerSegments().subscribe((segment)=>{
        return of(Utilities.groupByKey(this.segmentsData, 'segmentType'));
      });
    }
  }
  
  getCustomerSegments(): Observable<object> {
    return this.httpService.post(`${PAYMENT_APIS.CUSTOMER_SEGMENT}`, { hideLoader: true }).pipe(map((object:any) => {
      if(object && object.data && object.data?.status && object.data?.data && object.data.data.length) {
        this.segmentsData = object.data.data;
        return this.segmentsData;
      }
      this.segmentsData = [];
      return [];
    }));
  }

  fetchCustomerSegments()  {
    return new Promise((resolve)=>{
      if(this.segmentsData) {
        resolve(this.segmentsData);
      }
      else {
        this.getCustomerSegments().subscribe((segment)=>{
          resolve(segment);
        });
      }
    });
  }
  
  trackProductImpression() {
    for (let keyword in this.dataService.trackedProductImpression) {
      let eventArgs = {};
      switch (this.dataService.productImpressionConfigData.componentType) {
        case ProductListType.Search_Page:
          eventArgs = {
            keyword: keyword,
            products: this.dataService.trackedProductImpression[keyword],
            Source: EVENT_SOURCES.SEARCH
          };
          break;
        case ProductListType.Product_Listing:
          eventArgs = {
            subcategory_name: keyword,
            products: this.dataService.trackedProductImpression[keyword],
            category_name: this.selectedCategory?.name,
            Source: EVENT_SOURCES.CATEGORY
          };
          break;
        case ProductListType.Collection:
          if (this.dataService.productImpressionConfigData.banner && this.dataService.productImpressionConfigData.banner.collectionId && this.dataService.productImpressionConfigData.banner.name) {
            eventArgs = {
              collection_id: keyword,
              products: this.dataService.trackedProductImpression[keyword],
              collection_name: this.dataService.productImpressionConfigData.banner?.name,
              Source: EVENT_SOURCES.COLLECTION
            };
          } else {
            eventArgs = {
              banner_id: keyword,
              products: this.dataService.trackedProductImpression[keyword],
              banner_name: this.dataService.productImpressionConfigData.banner.name,
              Source: EVENT_SOURCES.BANNERS
            };
          }
          break;
      }
      eventArgs['products'] = JSON.stringify(eventArgs['products']);
      this.eventService.trackEvents(EVENT_NAMES.PRODUCT_IMPRESSION, { ...eventArgs }, false, EventTrackingPlugins.Firebase);
    }
    this.dataService.productImpressionConfigData = { componentType: null, banner: {} };
    this.dataService.trackedProductImpression = {};
  }
}
