//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultWidgetModule } from '@Lib/defaults/default-widget.module';
import { ImgOnDemandModule } from '@Src/main/modules/shared/img-on-demand/img-on-demand.module';
import { SocialSharingButtonModule } from '@Src/main/modules/shared/social-sharing-button/social-sharing-button.module';

import { SwiperModule } from 'swiper/angular';
import { InViewportModule } from 'ng-in-viewport';
//#endregion Module Imports

//#region Component Imports
import { BannerComponent } from '@Widgets/blocks/collection/banner/banner.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  declarations: [BannerComponent],
  imports: [
    CommonModule,
    IonicModule,
    ImgOnDemandModule,
    SocialSharingButtonModule,
    SwiperModule,
    InViewportModule
  ],
  exports: [BannerComponent]
})
export class CollectionBannerModule extends DefaultWidgetModule { }
