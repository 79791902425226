import { CORE_ROUTES } from '../../core/constants/coreRoutes';

export const PROFILE_ROUTES = {
    BASE: `/${CORE_ROUTES.USER}/`,
    SETTING: 'setting',
    CHANGE_PASSWORD: 'change-password',
    HOLIDAY: 'holiday',
    INVITE: 'invite',
    REFER: 'refer',
    CONTACT: 'contact',
    ACCOUNT_CLOSURE: 'account-closure'
};
