//#region Imports

//#region Angular Imports
import { Injectable } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';

//#endregion Ionic Imports

//#region Library Imports
import { Observable, Subject, timer } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { WalletEligibilityComponent } from '@Shared/payment/wallet-eligibility/wallet-eligibility.component';
import { NetBankingComponent } from '@Shared/payment/net-banking/net-banking.component';
import { CardDetailsComponent } from '@Shared/payment/card-details/card-details.component';
import { UpiVerificationComponent } from '@Shared/payment/upi-verification/upi-verification.component';
import { WalletLinkingComponent } from '@Shared/payment/wallet-linking/wallet-linking.component';
import { FaqModalComponent } from '@Shared/faq-modal/faq-modal.component';
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { HttpService } from '@Globals/providers/http.service';
import { ShoppingCreditsServices } from '@Globals/providers/shopping-credits.service';
//#endregion Providers Imports

//#region Other Imports
import { PAYMENT_APIS } from '@Globals/constants/payment/apis';
import { MODAL_IDS, PG_WISE_JUSPAY_PAY_LOAD } from '@Globals/constants/payment/constants';
import { Utilities } from '@App/shared/classes/utility/utilities';

//#endregion Other Imports

//#endregion Imports
@Injectable({
  providedIn:'root'
})

export class PaymentModalService {
  public cityDataUpdate = new Subject();
  errorMessage = new Subject();

  constructor (
    protected modalCtrl: ModalController,
    protected dataService: DataService,
    protected masterDataService: MasterDataService,
    private httpService: HttpService,
    private shoppingCreditsServices: ShoppingCreditsServices
  ) {}

  initiatePayment(pg, onDidDismissCallback, amount) {    
    switch (pg.value) {
      case PG_WISE_JUSPAY_PAY_LOAD.SODEXO.pgName:
      case PG_WISE_JUSPAY_PAY_LOAD.EDENRED.pgName:
      case PG_WISE_JUSPAY_PAY_LOAD.CREDIT_CARD.pgName:
      case PG_WISE_JUSPAY_PAY_LOAD.DEBIT_CARD.pgName:
      case PG_WISE_JUSPAY_PAY_LOAD.AUTO_DEBIT.pgName:
        if (Utilities.isApp()) {
        this.openCardDetailsModal(pg, onDidDismissCallback, amount);
        } else {
          onDidDismissCallback(pg.value);
        }
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.NET_BANKING.pgName:
        this.openNetBankingModal(pg, onDidDismissCallback);
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.lazy_pay.pgName:
        this.openWalletEligibilityModal(onDidDismissCallback, amount, pg.value);
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.UPI.pgName:
        this.openUpiVerification(pg, onDidDismissCallback);
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.paytm.pgName:
        if (pg.linked) {
          const fillData = PG_WISE_JUSPAY_PAY_LOAD.paytm.payload;
          PG_WISE_JUSPAY_PAY_LOAD.paytm.webPayload.directWalletToken = pg.token;
          fillData.directWalletToken = pg.token;
          this.shoppingCreditsServices.walletRefreshRequired = pg.walletId;
          PG_WISE_JUSPAY_PAY_LOAD.paytm.payload = fillData;
          onDidDismissCallback(pg.value);
        } else {
          this.openWalletLinking(pg, onDidDismissCallback);
        }
        break;
      case PG_WISE_JUSPAY_PAY_LOAD.TWID.pgName:
          PG_WISE_JUSPAY_PAY_LOAD.TWID.payload.directWalletToken = pg.token;
          this.shoppingCreditsServices.walletRefreshRequired = pg.walletId;
          onDidDismissCallback(pg.value);
        break;
      default:
        onDidDismissCallback(pg.value);
    }
  }

  async openWalletEligibilityModal(successCallBack, amount, pg?) {
    this.checkWalletEligibility(amount).subscribe((success: any) => {
      if (success.data.status && success.data.data && success.data.data[ 0 ]) {
        timer(1000).subscribe(() => {
          this.errorMessage.next(success.data.data[ 0 ]);
        });
      }
    });
    const modal = await this.modalCtrl.create({
      component: WalletEligibilityComponent,
      cssClass: 'modal-custom modal-bottom-sheet wallet-eligibility',
      backdropDismiss: false,
      breakpoints: [ 0, 0.5, 0.9, 1 ],
      initialBreakpoint: 0.9,
      handle: false,
      componentProps: {
        errorMessage: this.errorMessage
      },
      id: MODAL_IDS.PAYMENT_TYPE
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      successCallBack(pg);
    }
  }

  async openNetBankingModal(pg, successCallBack) {
    const modal = await this.modalCtrl.create({
      component: NetBankingComponent,
      breakpoints: [ 0, 0.5, 0.9, 1 ],
      initialBreakpoint: 0.9,
      cssClass: 'modal-bottom-sheet',
      handle: false,
      componentProps: {
        pg
      },
      id: MODAL_IDS.PAYMENT_TYPE
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      successCallBack(data);
    }
  }

  async openUpiVerification(pg, successCallBack) {
    const modal = await this.modalCtrl.create({
      component: UpiVerificationComponent,
      breakpoints: [ 0, 0.45, 1 ],
      initialBreakpoint: 0.45,
      handle: false,
      cssClass: 'modal-bottom-sheet custom-upi-height',
      componentProps: {
        pg
      },
      id: MODAL_IDS.PAYMENT_TYPE
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      successCallBack(data);
    }
  }

  checkWalletEligibility(amount: string): Observable<object> {
    return this.httpService.post(PAYMENT_APIS.WALLET_ELIGIBILITY, { amount, hideLoader: true });
  }

  async openCardDetailsModal(pg, onDidDismissCallback, amount) {
    const modal = await this.modalCtrl.create({
      component: CardDetailsComponent,
      breakpoints: [ 1 ],
      initialBreakpoint: 1,
      handle: false,
      cssClass: 'modal-bottom-sheet',
      componentProps: {
        shoppingCreditAmount: amount,
        pg
      },
      id: MODAL_IDS.PAYMENT_TYPE
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      onDidDismissCallback(data);
    }
  }

  async openWalletLinking(pg, successCallBack) {
    const modal = await this.modalCtrl.create({
      component: WalletLinkingComponent,
      breakpoints: [ 0, 0.5],
      initialBreakpoint: 0.5,
      handle: false,
      cssClass: 'modal-bottom-sheet paytm-wallet-linking',
      componentProps: {
        pg
      },
      id: MODAL_IDS.PAYMENT_TYPE
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      successCallBack(data);
    }
  }

  async openFaq(faqData) {
    const modal = await this.modalCtrl.create({
      component: FaqModalComponent,
      componentProps: {
        faqData : faqData,
        isModal: true
      },
      id: MODAL_IDS.FAQ_DATA
    });
    await modal.present();
  }
}
