//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultRoutingModule } from '@Lib/defaults/default-routing.module';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthGuardService } from '@Globals/providers/auth-guard/auth-guard.service';
//#endregion Providers Imports

//#region Other Imports
import { CORE_ROUTES } from '@Globals/constants/listing/routes';
import { LISTING_ROUTES, REGISTRATION_ROUTES } from '@Globals/constants/listing/routes';
import { REACT_WIDGET_MAP } from '@Globals/constants/react/widget-map.constants';
import { GeolocationModule } from './geolocation/geolocation.module';
//#endregion Other Imports

//#endregion Imports

const routes: Routes = [
  {
    path: CORE_ROUTES.SIGNUP_JOURNEY, 
    data: { componentName: REACT_WIDGET_MAP.SIGNUP_JOURNEY },
    loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
  },
  {
    path: CORE_ROUTES.BOARDING,
    data: { componentName: REACT_WIDGET_MAP.LOGIN },
    loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.SUCCESS + '/:id',
    loadChildren: () => import('@Main/pages/registration/home-animation/home-animation.module').then(m => m.HomeAnimationModule),
  },

  {
    path: CORE_ROUTES.REGISTRATION + '/' + REGISTRATION_ROUTES.ACCEPT_INVITE + '/:inviteCode',
    data: { componentName: REACT_WIDGET_MAP.GEOLOCATION },
    loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.REGISTRATION + '/' + REGISTRATION_ROUTES.GEOLOCATION + '/:addressSelectionType',
    data: { componentName: REACT_WIDGET_MAP.GEOLOCATION },
    loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.REGISTRATION + '/' + REGISTRATION_ROUTES.LOCATION_ACCESS,
    data: { componentName: REACT_WIDGET_MAP.GEOLOCATION },
    loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.REGISTRATION + '/' + REGISTRATION_ROUTES.FRAUD_ALERT,
    data: { componentName: REACT_WIDGET_MAP.FRAUD_USER },
    loadChildren: () => import('@Main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.REGISTRATION + '/' + REGISTRATION_ROUTES.ADD_ADDRESS,
    data: { componentName: REACT_WIDGET_MAP.GEOLOCATION },
    loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  }
]

@NgModule({
    imports: [RouterModule.forChild(routes),GeolocationModule ],
    exports: [RouterModule]
})
export class RegistrationRoutingModule extends DefaultRoutingModule { }