//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
import { LayoutModule } from '@Layouts/one/layout.module';
import { HeaderModule } from '@MbUi/headers/two/header.module';
import { BottomSheetHeaderModule } from '@Shared/bottom-sheet-header/bottom-sheet-header.module';
//#endregion Module Imports

//#region Component Imports
import { MembershipTermsAndConditionComponent } from './membership-terms-and-condition.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule, 
    LayoutModule,
    HeaderModule,
   // RecurringCampaignDetailsRoutingModule,
    BottomSheetHeaderModule,
  ],
  exports: [MembershipTermsAndConditionComponent],
  declarations: [MembershipTermsAndConditionComponent],
  providers: []
})
export class MembershipTermsAndConditionModule extends DefaultModule {}
