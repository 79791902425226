<ng-container *ngIf="masterDataService.masterData?.user?.is_valid_address === AddressStatus.UnderVerification">
  <ion-item lines="none" color="none" class="wallet-info-strip padding-top-extra-small">
    <ion-icon class="icon-caution-address icons-property icon-dark margin-right-small"></ion-icon>
    <ion-label class="ion-no-margin">
      <h2 class="f-w-s-b color-dark-300 text-size-sm" >Address Under Verification! </h2>
      <p class="f-w-n color-dark-300">Shopping will be activated once verified.</p>
    </ion-label>
  </ion-item> 
</ng-container>
<ng-container *ngIf="masterDataService.masterData?.user?.is_valid_address === AddressStatus.Rejected">
  <ion-row class="d-flex ion-justify-content-between ion-align-items-center address-unverified ctn-gt-space-p">
    <p class="f-w-n text-size-sm margin-right-extra-small text-size-ext-sm color-light">
      Sorry, we couldn't verify your address!
    </p>
    <ion-button
      type="button"
      fill="clear"
      size="small"
      class="ion-no-padding address-btn no-ripple-effect f-w-s-b text-size-ext-sm color-light"
      (click)="updateAddress()"
    >
    Try Again
    </ion-button>
  </ion-row>
</ng-container>
