
//#region Imports

//#region Angular Imports
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultRoutingModule } from '@Lib/defaults/default-routing.module';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthGuardService } from '@Globals/providers/auth-guard/auth-guard.service';
//#endregion Providers Imports

//#region Other Imports
import { CORE_ROUTES, LISTING_ROUTES } from '@Globals/constants/listing/routes';
import { REACT_WIDGET_MAP } from '@Globals/constants/react/widget-map.constants';
//#endregion Other Imports

//#endregion Imports

const subscriptionRoutes: Routes = [
    {
        path: CORE_ROUTES.LISTING + "/" + LISTING_ROUTES.SET_SUBSCRIPTION_PLAN + '/:productId' + '/:index' + '/:source',
        data: { componentName: REACT_WIDGET_MAP.SET_SUBSCRIPTION_PLAN },
        loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
        canActivate: [AuthGuardService],
    },
    {
      path: CORE_ROUTES.LISTING + "/" + LISTING_ROUTES.SET_SUBSCRIPTION_PLAN + '/:productId' + '/:source',
      data: { componentName: REACT_WIDGET_MAP.SET_SUBSCRIPTION_PLAN },
      loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
      canActivate: [AuthGuardService],
    },
    {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.SUBSCRIBE_TO_SAVE + '/:source',
        data: { componentName: REACT_WIDGET_MAP.SUBSCRIBE_TO_SAVE },
        loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.RC_DETAILS,
        data: { componentName: REACT_WIDGET_MAP.SUBSCRIBE_TO_SAVE_EDUCATION },
        loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.RECURRING_ORDERS,
        data: { componentName: REACT_WIDGET_MAP.SUBSCRIPTION_ORDERS },
        loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
        canActivate: [AuthGuardService]
      },
    {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.RECURRING_SEARCH,
        data: { componentName: REACT_WIDGET_MAP.SUBSCRIPTION_SEARCH },
        loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.RC_SAVING,
        data: { componentName: REACT_WIDGET_MAP.SUBSCRIPTION_CASHBACK },
        loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(subscriptionRoutes)],
    exports: [RouterModule]
})
export class SubscriptionRoutingModule extends DefaultRoutingModule { }