import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataService } from './data/data.service';
import { MasterDataService } from './master-data/master-data.service';
@Injectable({
  providedIn: 'root'
})
export class BottomSheetService {

  constructor(private modalCtrl: ModalController) { }

  async openBottomSheet(component, breakpoints, initialBreakpoint, data?, callback?,backDropRequired=true, handle=false,reqCss?) {
    let modalCss = 'modal-bottom-sheet ';
    if(reqCss){
      modalCss = modalCss + reqCss
    }
    const modal = await this.modalCtrl.create({
      component: component,
      breakpoints: breakpoints,
      initialBreakpoint: initialBreakpoint,
      componentProps: { data },
      backdropDismiss:backDropRequired,
      cssClass: modalCss,
      handle:handle,
    });
    await modal.present();
    modal.onDidDismiss().then(res => {
      if (callback) {
        callback(res);
      }
    });
  }

  async openPromoCodeList(component) {
    const modal = await this.modalCtrl.create({
      component: component,
      breakpoints: [0, 0.5, 0.8, 1],
      initialBreakpoint: 0.8,
      handle: false,
      cssClass: 'modal-bottom-sheet '
    });
    await modal.present();
  }

  async openLoginBottomSheet(component, breakpoints, initialBreakpoint, data?, cssClass?) {
    const modal = await this.modalCtrl.create({
        component: component,
        breakpoints: breakpoints,
        initialBreakpoint: initialBreakpoint,
        componentProps: { data },
        backdropDismiss:true,
        cssClass: cssClass || 'modal-bottom-sheet non-logged-in-user',
        handle:false,
      });
      await modal.present();
  }
}
 