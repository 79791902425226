//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthGuardService } from '@Globals/providers/auth-guard/auth-guard.service';
//#endregion Providers Imports

//#region Other Imports
import { CORE_ROUTES, LISTING_ROUTES, REGISTRATION_ROUTES } from '@Globals/constants/listing/routes';
import { REACT_WIDGET_MAP } from '@Globals/constants/react/widget-map.constants';
//#endregion Other Imports

//#endregion Imports

const routes: Routes = [
  {
    path: CORE_ROUTES.CATEGORY, 
    data: { componentName: REACT_WIDGET_MAP.PRODUCT_CATEGORY },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.HOME_PAGE,
    data: { componentName: REACT_WIDGET_MAP.HOME },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.CART_PAGE,
    data: { componentName: REACT_WIDGET_MAP.HOME_CART },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.YOUR_CART,
    data: { componentName: REACT_WIDGET_MAP.CART },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
     path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.SUBCATEGORY + ':subcategoryId/:categoryId/:source',
     data: { componentName: REACT_WIDGET_MAP.CATEGORY_PRODUCT_LIST },
     loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.SUBCATEGORY + ':subcategoryId/:categoryId',
    data: { componentName: REACT_WIDGET_MAP.CATEGORY_PRODUCT_LIST},
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.CATEGORY + ':categoryId/:source',
    data: { componentName: REACT_WIDGET_MAP.CATEGORY_PRODUCT_LIST },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  }, 
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.CATEGORY + ':categoryId',
    data: { componentName: REACT_WIDGET_MAP.CATEGORY_PRODUCT_LIST },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.SEARCH,
    data: { componentName: REACT_WIDGET_MAP.SEARCH },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.ACCOUNT_HISTORY + '/:slideIndex',
    data: { componentName: REACT_WIDGET_MAP.ACCOUNT_HISTORY },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.ACCOUNT_HISTORY,
    data: { componentName: REACT_WIDGET_MAP.ACCOUNT_HISTORY },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.ACCOUNT_HISTORY_DETAILS + '/:orderID' ,
    data: { componentName: REACT_WIDGET_MAP.ORDER_DETAILS },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.M_BEYOND,
    loadChildren: () => import('../main/pages/m-beyond/m-beyond.module').then(m => m.MBeyondModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.M_BEYOND_ALL_COLLECTION,
    loadChildren: () => import('../main/pages/m-beyond-all-collection/m-beyond-all-collection.module').then(m => m.MBeyondAllCollectionModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.OFFERS,
    loadChildren: () => import('../main/pages/offers/offers.module').then(m => m.OffersModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.SIMILAR_PRODUCTS_LIST + '/:productId',
    loadChildren: () => import('../main/pages/similar-products-list/similar-products-list.module').then(m => m.SimilarProductsListModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.SIMILAR_PRODUCTS_LIST + ':productId',
    loadChildren: () => import('../main/pages/similar-products-list/similar-products-list.module').then(m => m.SimilarProductsListModule),
    canActivate: [AuthGuardService]
  },
  
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.PRODUCT_DETAIL + "/:productId" + "/:source" + "/:index" + "/:id",
    data: { componentName: REACT_WIDGET_MAP.PRODUCT_DETAIL },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.PRODUCT_DETAIL + "/:productId" + "/:source"+ "/:index",
    data: { componentName: REACT_WIDGET_MAP.PRODUCT_DETAIL },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },

  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.PRODUCT_DETAIL + "/:productId" + "/:source"+ "/:bannerId",
    data: { componentName: REACT_WIDGET_MAP.PRODUCT_DETAIL },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.PRODUCT_DETAIL + "/:productId" + "/:source",
    data: { componentName: REACT_WIDGET_MAP.PRODUCT_DETAIL },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.PRODUCT_DETAIL + "/:productId" + "/:cityId",
    data: { componentName: REACT_WIDGET_MAP.PRODUCT_DETAIL },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.PRODUCT_DETAIL + "/:productId",
    data: { componentName: REACT_WIDGET_MAP.PRODUCT_DETAIL },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  }, 
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.SCHEDULE_PRODUCT,
    loadChildren: () => import('../main/modules/shared/product-listing/schedule-product/schedule-product.module').then(m => m.ScheduleProductModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.PAYMENT,
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.MORE_PAGE,
    data: { componentName: REACT_WIDGET_MAP.USER_MENU },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING,
    loadChildren: () => import('./listing/listing.module').then(m => m.ListingModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.REGISTRATION,
    data: { componentName: REACT_WIDGET_MAP.GEOLOCATION },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.USER,
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.RECYCLE_BAGS,
    data: { componentName: REACT_WIDGET_MAP.GO_GREEN },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.RECYCLE_BAG,
    data: { componentName: REACT_WIDGET_MAP.RECYCLE_BAG },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.RECYCLE_TETRA_BAG,
    data: { componentName: REACT_WIDGET_MAP.RECYCLE_CARTON },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.RECYCLE_TETRA_BAG + '/know-more',
    data: { componentName: REACT_WIDGET_MAP.RECYCLE_CARTON_KNOW_MORE },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.NPS_SURVEY,
    loadChildren: () => import('../main/pages/nps-survey/nps-survey.module').then(m => m.NpsSurveyModule),
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.EXTERNAL,
    data: { componentName: REACT_WIDGET_MAP.EXTERNAL },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.CALENDAR_JOURNEY,
    data: { componentName: REACT_WIDGET_MAP.CALENDAR_JOURNEY },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.MB_PASS + "/history",
    data: { componentName: REACT_WIDGET_MAP.VALUE_PASS_HISTORY },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.MB_PASS + "/:source",
    data: { componentName: REACT_WIDGET_MAP.VALUE_PASS },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.MB_PASS,
    data: { componentName: REACT_WIDGET_MAP.VALUE_PASS },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.SSPH_CHARGES,
    loadChildren: () => import('../main/pages/ssph/ssph.module').then(m => m.SSPHModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.FAQ + '/:faqType',
    data: { componentName: REACT_WIDGET_MAP.FAQ },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.MEMBERSHIP_DETAILS_POPUP,
    loadChildren: () => import('../main/pages/membership/components/membership-profile-info/membership-profile-info.module').then(m => m.MembershipProfileInfoModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.MEMBERSHIP,
   data: { componentName: REACT_WIDGET_MAP.MEMBERSHIP },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.REGISTRATION + '/' + REGISTRATION_ROUTES.SELECT_TOWER,
    data: { componentName: REACT_WIDGET_MAP.GEOLOCATION },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.REGISTRATION + '/' + REGISTRATION_ROUTES.REQUEST_TOWER_ADDRESS,
    data: { componentName: REACT_WIDGET_MAP.GEOLOCATION },
    loadChildren: () => import('../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
  },
   {
    path: CORE_ROUTES.SAVINGS,
    data: { componentName: REACT_WIDGET_MAP.SAVING },
    loadChildren: () =>import("../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
  },
  {
    path: CORE_ROUTES.DISCOUNTED_PRODUCT  + '/:id',
    data: { componentName: REACT_WIDGET_MAP.BILL_BUSTER },
    loadChildren: () => import("../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
    canActivate: [AuthGuardService],
  },
  {
    path: CORE_ROUTES.HOLIDAY ,
    data: { componentName: REACT_WIDGET_MAP.SET_USER_HOLIDAYS },
    loadChildren: () => import("../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
    canActivate: [AuthGuardService],
  },
  {
    path: CORE_ROUTES.HOLIDAY_LIST,
    data: { componentName: REACT_WIDGET_MAP.USER_HOLIDAYS_LIST },
    loadChildren: () => import("../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
},
{
  path:  LISTING_ROUTES.COLLECTION_LISTING_NAV_SINGLE_URL + ':id/:source',
  data: { componentName: REACT_WIDGET_MAP.COLLECTION_SINGLE_CELL },
  loadChildren: () => import("../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
  canActivate: [AuthGuardService]
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
