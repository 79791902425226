import { Utilities } from "@Widgets/react-renderer/providers/graph-transformers/utilities/src";
import { AppConfigs } from "../../../config/src";
import { ProductRefillTypes } from "../enums/product.enums";

export class ProductRefill {
    text?: string;
    type?: number;
    date?: string;

    setProductRefillFromApi(productApi) {
        const { text, refillType } = this.updateRefillTypeAndUIMessage(productApi);
        this.text = text || '';
        this.type = refillType || 0;
        this.date = productApi.refillDate || '';
        return this;
    }

    updateRefillTypeAndUIMessage(productApi) {
        let text: string | null = null;
        let { refillType } = productApi as { refillType: number };
        try {
            if (productApi.oos || productApi.forcedOos) {
                if (
                    productApi.refillType === ProductRefillTypes.WillReturnInXDays &&
                    productApi.refillDate
                ) {
                    // converting string date to date object
                    const currentDate = new Date();
                    const refillDate = new Date(productApi.refillDate);
                    // checking if refill date is greater than current date else changing refillType to 6(LeadTimeNotKnown)
                    if (productApi.refillDate > currentDate) {
                        // checking if refill date is greater and only 1 day ahead of current date then changing refillType to 3(NextDay)
                        if (Utilities.date.dateDiffInDays(currentDate, refillDate) === 1) {
                            refillType = ProductRefillTypes.NextDay;
                        } else {
                            const refillMeta =
                                AppConfigs.TYPE_CONFIGS.PRODUCT.REFILL_TYPES_META_DATA[
                                    `type${refillType}`
                                ];
                            text =
                                refillMeta.text.replace(
                                    refillMeta.replacer,
                                    Utilities.date.formatDateDoM(productApi.refillDate),
                                ) || '';
                        }
                    } else {
                        refillType = ProductRefillTypes.LeadTimeNotKnown;
                    }
                }
                if (refillType !== ProductRefillTypes.WillReturnInXDays) {
                    // if there is no type coming and baOos is coming and its 0 then type 3(NextDay) else type 6(LeadTimeNotKnown)
                    if (!refillType) {
                        refillType = productApi.baOos
                            ? ProductRefillTypes.LeadTimeNotKnown
                            : ProductRefillTypes.NextDay;
                    }
                    const refillMeta =
                        AppConfigs.TYPE_CONFIGS.PRODUCT.REFILL_TYPES_META_DATA[`type${refillType}`];
                    if (refillMeta) {
                        text = refillMeta.text;
                    }
                }
            }
        } catch (e) {
            text = null;
        }
        return { refillType, text };
    }
}
