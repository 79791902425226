//#region Imports

//#region Angular Imports
import { Component, Input,ChangeDetectionStrategy } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { noop } from 'rxjs';
import { DomSanitizer,SafeHtml } from '@angular/platform-browser';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
import { BackType } from '@Globals/constants/listing/enums';
//#endregion Other Imports

//#endregion Imports


@Component({
  selector: 'mbs-terms-condition-popup',
  templateUrl: './terms-condition-popup.component.html',
  styleUrls: ['./terms-condition-popup.component.scss'],
})
export class TermsConditionPopupComponent extends DefaultComponent {

  @Input() description;
  backAction = BackType.Close;
  sanitizeDescription: SafeHtml;
  constructor (
    private modalCtrl: ModalController, public sanitizer: DomSanitizer,) {
      super();
  }

  mbOnInit() {
    this.sanitizeDescription = this.sanitizer.bypassSecurityTrustHtml(this.description);
  }

  closeTnc() {
    this.modalCtrl.dismiss();
  }

}
