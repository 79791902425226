//#region Imports

//#region Angular Imports
import { Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultWidgetComponent } from '@Lib/defaults/default-widget.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'wgt-base-block',
  template: '<p>Base Works</p>',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent extends DefaultWidgetComponent {
  @Input() schema;
  @Input() panelSchema;
  @Input() dashboard;
  @Input('data') _data;
  @Input('props') _props;

  get data() {
    return this._data;
  }

  set data(data) {
    this._data = data;
  }

  get props() {
    return this._props;
  }

  set props(props) {
    this._props = props;
  }
}
