<ion-header class="ion-header-modal">
    <ion-toolbar>
        <ion-buttons>
            <ion-button (click)="closeModal(false)">
                <ion-icon class="icon-close icons-property icon-xmd" color="dark"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-horizontal refferal-block">
    <ion-item lines="none" class="ion-no-padding">
        <ion-label class="ion-text-wrap ion-no-margin ion-align-self-start">
            <small>
                Congratulations! You are family now!
            </small>
            <p class="mrg-top-bottom">One step away to claim your referral reward…</p>
            <p class="f-w-b">Add money to your MB Wallet
                & get cashback of {{masterDataService.masterData.welcome_code?.detail?.cashback | currency: 'INR':'symbol':'1.0'}}
                !</p>
        </ion-label>
        <ion-img alt="gift icon" class="ion-align-self-start" margin-left-large slot="end" [src]="'assets/img/gift.png'"></ion-img>
    </ion-item>


</ion-content>

<ion-footer mode="ios" class="ion-padding" padding-bottom-medium>
    <ion-button color="primary" type="button" size="large" expand="block" (click)="closeModal(true)">Add Money Now</ion-button>
</ion-footer>
