import { CORE_ROUTES } from '../../core/constants/coreRoutes';

export const REGISTRATION_ROUTES = {
  BASE: `/${CORE_ROUTES.REGISTRATION}/`,
  LOG_IN: 'login',
  LOG_IN_MOBILE: 'login-mobile',
  LOG_IN_VERIFY_OTP: 'login-verify-otp',
  SELECT_SOCIETY: 'select-society',
  SIGN_UP_MOBILE: 'signup-mobile',
  SIGN_UP_VERIFY_OTP: 'signup-verify-otp',
  SIGN_UP_DETAILS: 'signup-details',
  ACCEPT_INVITE: 'accept-invite',
  LOCATION_ACCESS: 'location-access',
  GEOLOCATION: 'geolocation',
  SELECT_TOWER:'select-tower',
  REQUEST_TOWER_ADDRESS:'request-tower-address',
  FRAUD_ALERT: 'fraud-alert',
  ADD_ADDRESS: 'add-address',
};
