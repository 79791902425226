<ion-header class="ion-header-modal ion-header-modal-modal">
  <ion-toolbar>
    <ion-buttons class="ion-align-self-start" slot="end">
      <ion-button (click)="close()">
        <ion-icon class="icon-close icons-property icon-xmd" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="padding-bottom-medium">
  <div class="card-custom padding-horizontal-medium">
    <em> {{masterDataService.masterData.welcome_code?.title || dataService.firebaseMasterData?.welcome_code?.title}}</em>
    <h1 class="padding-top-small">{{masterDataService.masterData.welcome_code?.sub_title || dataService.firebaseMasterData?.welcome_code?.sub_title}}</h1>
    <div class="ion-margin-top">
      <strong>Delivery starts {{ masterDataService.masterData.user?.delivery_start_date | amDateFormat: 'Do MMMM YYYY' }}</strong>
    </div>
  </div>
  <div class="padding-horizontal-medium">
  <p class="ion-margin-top">{{masterDataService.masterData.welcome_code?.description || dataService.firebaseMasterData?.welcome_code?.description}}</p>
  </div>
</ion-content>

