import { Product } from '../../../product/src';
import { ProductOrder } from '../../../product-order/src';

export class BasketProduct extends Product {
    __typename = 'BasketProduct';
    order: ProductOrder;

    setBasketProductFromProductApi(product) {
        this.setProductFromProductApi(product);

        const order = new ProductOrder();
        this.order = order.setProductOrderFromApi(product);

        return this;
    }
}
