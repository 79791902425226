<ion-list class="ion-no-padding ion-list-image-card ion-list-grid gvc layout-spacer"  *ngIf="data?.showCollection">
  <ion-list-header class="ion-list-header" *ngIf="data?.name">
    <ion-row class="ion-justify-content-between full-width ion-align-items-center">
      <div>
        <span class="padding-right-small">{{ data.name }}</span>
        <p *ngIf="data?.description">
          {{ data.description }}
        </p>
      </div>
      <ion-button size="small" fill="clear" class="ion-no-padding no-ripple-effect view-all"
        (click)="navigateToCollectionList()" color="dark">View All</ion-button>
    </ion-row>
  </ion-list-header>
  <ion-item class="ion-no-padding" lines="none" mode="md">
    <mb-pl-two-row [products]="data?.productList" [eventData]="eventData"></mb-pl-two-row>
  </ion-item>
</ion-list>