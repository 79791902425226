<div class="geofence-delivery">
<div class="imp-strip d-flex">
  <ng-lottie [ngStyle]="{height: 26+'px',width:100+'%'}" class="lottie-animation lottie-area"  [options]="importLottieConfig"> </ng-lottie>
</div>

<div class="geofence-pin d-flex ion-align-item-center ion-justify-content-center margin-top-base">
  <ng-lottie class="lottie-animation lottie-area" [ngStyle]="{height: 108+'px',width: 160+'px'}" [options]="mapLottieConfig"> </ng-lottie>
</div>

<div class="padding-horizontal-base">
  <h2 class="ion-text-center text-size-lg color-dark-300 margin-top-base">Drag pin to your house location.</h2>
  <p class="ion-text-center text-size-md margin-top-small margin-bottom-base"> Accurate location will help us to reach your doorstep without any hassle every morning.</p> 
  <ion-button type="button" 
  fill="solid" 
  size="large" 
  mode="md"
  (click)="closeModal()"
  shape="round" expand="block" class="mw-large">Ok, Got it!</ion-button>
</div>
</div>