export const LISTING_APIS = {
    ACCOUNT_HISTORY: 'user/account_history_new',
    ORDER_DETAILS: 'orders/details_new',
    PRODUCTS_SEARCH: 'products/search',
    SEARCH_AUTOCOMPLETE: 'search/suggestions?searchWord=',
    ADD_TO_BASKET: 'baskets/add',
    PRODUCT_REQUEST: 'products/request',
    RECURRING_MODIFY: 'recurring_orders/modify',
    RECURRING_ORDERS: 'recurring_orders',
    RECURRING_STOP: 'recurring_orders/stop',
    PRODUCT_DETAILS: 'products/get',
    PRODUCT_VARIANT_DETAILS: 'products/variants',
    OFFERS: 'products/offers',
    TODAYS_NEW: 'orders/todays_new',
    SIMILAR_PRODUCTS: 'products/similar-products',
    RELATED_PRODUCTS: 'products/related-products',
    USER_RATING: 'user/rating',
    TRENDING_PRODUCTS: 'products/trending-products?subcategoryId=',
    COLLECTION_LISTING: 'collection/home',
    COLLECTION_LOCATION_TYPE: 'collection/location-type',
    COLLECTION_BY_ID: 'collection',
    COLLECTION_PLP: 'list',
    ADD_MEMBERSHIP: 'membership/add',
    COLLECTION_PLP_PRODUCTS: 'products',
    CATEGORY_PRODUCTS: 'category/products',
    OPTIN_OPTOUT_COVID_FEE: 'basket/fee',
    COLLECTION_SUBCATEGORY: 'collection/subcategory',
    COLLECTION_RELATED: 'collection/related-collection/',
    REMOVE_CANCEL_PRODUCTS: 'basket/removed_products/remove_view',
    PRODUCT_DAILY_LIMIT: 'product/stock',
    GET_ALL_COLLECTION_PLP: 'leaf/all-products',
    TOMORROW_BASKET: 'tomorrow-basket',
    BUY_MB_PASS: 'customer-pass',
    MB_PASS_HISTORY: 'customer-pass/expire',
    PRODUCTS: 'products',
    SUBCATEGORIES: 'subcategories',
    LIKE_COLLECTION: 'collection/leaf',
    BRAND_LAUNCH: 'brands/launch-type',
    BRAND_ALL_PRODUCT: 'brands/all-products',
    PRODUCT_LAUNCH: 'multi/products/launch',
    ALL_PRODUCT_LAUNCH: 'multi/products/launch/all-products',
    CALENDAR_ORDER: 'calender/order',
    CALENDAR_STOP: 'calender/stop',
    CALENDAR_ORDER_LIST: 'calender/view_orders',
    CALENDAR_PAUSE: 'calender/pause',
    CALENDAR_RESUME: 'calender/resume',
    SPONSORED_DATA: 'collection/sponsor/type/search',
    SPONSORED_DATA_ALL: 'collection/sponsor/type/search/all',
    SPONSORED_ALL_PRODUCTS: 'collection/sponsor/type/all-products',
    RC_SAVING_TOTAL_AMOUNT: 'orders/benefit/summery',
    RC_SAVING: 'orders/benefit',
    RECYCLE_BAGS: 'recycle-request',
    ONE_PAGER_BY_ID: 'collection/location-type',
    USER_NPS_RATING:'nps-survey',
    GET_MEMBERSHIP_PLANS: 'user/membership',
    GET_ORDER_COUNT:'orders/count',
    UPDATE_NOCHECKOUT_STATUS:'user/customer-info',
    MEMBERSHIP_REMOVAL_CHECK:'basket/membership_removal_check',
    DEALS_OF_THE_DAY:'collection/deal/products',
    SAVINGS:'user/saving/strip',
    GENERATE_INVOICE_NUMBER:'invoice/order',
    LIST_PRODUCT:'list',
    COLLECTION_EXPIRE:'collection',
    BANNER_EXPIRE:'banners',
    POPULAR_SEARCH: 'search/popular/suggestions',
    PRODUCT_STOCK_AVAILABILTY: 'recurring_orders/check/availability',
};
