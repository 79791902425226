//#region Imports

//#region Angular Imports
import { Injectable, OnInit } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { Subject } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Injectable({
  providedIn: 'root'
})
export class ModalService implements OnInit {
  public cityDataUpdate = new Subject();
  errorMessage = new Subject();
  constructor (
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    document
      .getElementsByTagName('ion-app')
      .item(0)
      .classList.add('disable-scroll');
  }

  async openModal(opts) {
    const modal = await this.modalCtrl.create(opts);
    await modal.present();
  }

  async openModalWithCallback(opts, successCallBack?) {
    const modal = await this.modalCtrl.create(opts);
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
        successCallBack && successCallBack(data);
    }
  }

//   async openCityModal(componentFactory: any, serviceable, isGps?) {
//     this.openModal(componentFactory, { serviceable, isGps }, CITY_MODAL_CSS_CLASS, (data)=>{
//         this.cityDataUpdate.next(true);
//     });
//     // const modal = await this.modalCtrl.create({
//     //   component: SelectCityComponent,
//     //   backdropDismiss: false,
//     //   cssClass: CITY_MODAL_CSS_CLASS,
//     //   componentProps: componentProps
//     // });
//     // await modal.present();
//     // const { data } = await modal.onDidDismiss();
//     // if (data) {
//     // }
//   }
  
  dismissTopModal(successCallBack?) {
    this.modalCtrl
      .getTop()
      .then(topModal => {
        successCallBack && successCallBack(topModal, this.modalCtrl);
      })
      .catch(() => { });
  }
}
