//#region Imports

//#region Angular Imports
import { Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-jio-mart-header',
  templateUrl: './jio-mart-header.component.html',
  styleUrls: ['./jio-mart-header.component.scss'],
})
export class JioMartHeaderComponent extends DefaultComponent {

  constructor(public dataService: DataService) {
    super();
   }

  mbOnInit() {}

}
