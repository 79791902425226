//#region Imports
//#region Angular Imports
import { Component, ViewChild } from '@angular/core';
import { IonContent, ModalController, NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router'; 
//#endregion Angular Imports
//#region Ionic Imports
import { HTTPResponse } from '@ionic-native/http';
//#endregion Ionic Imports
//#region Library Imports
import { Subject, Subscription } from 'rxjs';
//#endregion Library Imports
//#region Module Imports
//#endregion Module Imports
//#region Component Imports
import { PaymentOptionsComponent } from '@Pages/payment/shopping-credits/components/payment-options/payment-options.component';
import { DefaultPage } from '@Lib/defaults/default.page';
//#endregion Component Imports
//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { ShoppingCreditsServices } from '@Globals/providers/shopping-credits.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { EventsService } from '@Globals/providers/events.service';
import { LISTING_ROUTES } from '@Globals/constants/listing/routes';
import { BottomSheetService } from '@Globals/providers/bottom-sheet.service'; 
import { SelectTabOption } from '@Layouts/one/providers/layout.service';
import { PaymentModalService } from '@Globals/providers/payment-modal/payment-modal-service';
//#endregion Providers Imports
//#region Other Imports
import {
  EVENT_NAMES
} from '@Globals/constants/listing/constants';
import { 
  MODAL_IDS,OFFER_SOURCE,EVENT_SOURCE,
  EVENT_ARGUMENTS,SHOPPING_CREDIT,
  SHOPPING_CREDIT_ERROR_MESSAGES,PAYMENT_VALUES, PAYMENT_GATEWAY
} from '@Globals/constants/payment/constants';
import { FaqType } from '@Globals/constants/listing/enums';
import { PAYMENT_ROUTES } from '@Globals/constants/payment/routes';
import { InitiateTransactionDataToSend } from '@Globals/interfaces/payment/interfaces';
import { PaymentOption } from '@Globals/interfaces/listing/interfaces';
import { Utilities } from '@Globals/classes/utilities';
import { PromocodeListingComponent } from '@Features/payment/promocode-listing/promocode-listing.component';
import { SCROLL_UP_DURATION } from '@App/listing/constants/listing-constants';
//#endregion Other Imports
//#endregion Imports

@Component({
  selector: 'app-shopping-credits',
  templateUrl: './shopping-credits.page.html',
  styleUrls: ['./shopping-credits.page.scss']
})
export class ShoppingCreditsPage extends DefaultPage { 
  @ViewChild('pageContent', { static: false }) pageContentRef: IonContent;
  subscription;
  shoppingCreditsConstants = SHOPPING_CREDIT;
  paymentGateway = '';
  superChargeId = null;
  showError = {
    min_amount_error: false,
    max_amount_error: false,
    min_cash_collection_error: false,
    max_cash_collection_error: false
  };
  getStatusOfAutoDebit = new Subject();
  eventSource = EVENT_SOURCE.PAYMENTS;
  eventSourceSCPage = EVENT_SOURCE.SHOPPING_CREDIT_PAGE;
  shoppingCreditAmount: any;
  paymentBanner: Array<{}> = []; 
  data = {};
  SHOPPING_CREDIT_ERROR_MESSAGES = SHOPPING_CREDIT_ERROR_MESSAGES;
  selectedPGFromDeepLink: PaymentOption;
  source = OFFER_SOURCE.SHOPPING_CREDITS;
  updateShoppingCreditAmount = evt => {
    this.shoppingCreditAmount = evt.target.value = evt.target.value.replace(
      /[^0-9]/g,
      ''
    );
  };

  trackPayment = (paymentResponse, txnId, paymentData) => {
    const paymentdata = {
      txnId,
      amount: paymentData.amount,
      payment_option: paymentData.pg,
      category: 'Event',
      'PaymentGateway': paymentData.PaymentGateway || PAYMENT_GATEWAY.JUSPAY,
      Source: paymentData.source ? paymentData.source : '',
      errorStatus: paymentData.errorStatus ? paymentData.errorStatus : '',
      errorMessage: paymentData.errorMessage ? paymentData.errorMessage : ''
    };
    if (paymentResponse === 'success') {
      if(paymentData.pg === PAYMENT_VALUES.CRED) {
        const offerText = this.shoppingCreditsServices.getCredOffer();
        offerText && (paymentdata['cred_offer'] = offerText.offer);
      }
      this.eventsService.trackEvents(EVENT_NAMES.PAYMENT_SUCCESS, paymentdata);
    }  else {
      this.eventsService.trackEvents(EVENT_NAMES.PAYMENT_FAILURE, paymentdata);
    }
  };

  submit = () => {
    this.eventsService.trackEvents(EVENT_NAMES.ADD_MONEY_CLICK, { amount: this.shoppingCreditAmount });
    if (
      this.shoppingCreditAmount <
      parseInt(this.shoppingCreditsServices.topUpRange?.min_amount, 10)
    ) {
      this.showError.min_amount_error = true;
      this.eventsService.trackEvents(EVENT_NAMES.MINIMUM_TOPUP, { amount: this.shoppingCreditAmount });
      this.resetError();
      return;
    } else if (
      this.shoppingCreditAmount >
      parseInt(this.shoppingCreditsServices.topUpRange?.max_amount, 10)
    ) {
      this.showError.max_amount_error = true;
      this.eventsService.trackEvents(EVENT_NAMES.MAXIMUM_TOPUP, { amount: this.shoppingCreditAmount });
      this.resetError();
      return;
    } else {
      if(this.masterDataService.isJioPayEnabled) {
        const eventArgs = {
          'User Id': this.masterDataService.masterData?.user?.id,
          Amount: this.shoppingCreditAmount,
          'PaymentGateway': PAYMENT_GATEWAY.JIOPAY,
          Source: EVENT_ARGUMENTS.SHOPPING_CREDITS
        };
        this.eventsService.trackEvents(EVENT_NAMES.PAYMENT_START, eventArgs);
        this.shoppingCreditsServices.openPaymentOptionsModalJio(this.shoppingCreditAmount, this.trackPayment);
      } else {
        if (this.selectedPGFromDeepLink) {
          this.paymentModalBaseService.initiatePayment(this.selectedPGFromDeepLink, (pgValue) => {
            this.onDidDismissOfPaymentOptionsModal({ data: pgValue });
          }, this.shoppingCreditAmount);
        } else {
          this.openPaymentOptionsModal();
          this.eventsService.trackEvents(EVENT_NAMES.ADD_MONEY_SCREEN_VISITS, {});
        }
      }
    }
  };
  onDidDismissOfPaymentOptionsModal = (pg) => {
    if (pg.data) {
      const dataToSend: InitiateTransactionDataToSend = {
        amount: this.shoppingCreditAmount,
        pg: pg.data
      };
      if (this.superChargeId) {
        dataToSend.supercharge_id = this.superChargeId;
        this.eventsService.trackEvents(EVENT_NAMES.SUPERCHARGE_PAYMENT_MODE, { mode: EVENT_SOURCE.ONLINE });
        this.eventsService.trackEvents(EVENT_NAMES.SUPERCHARGE_PAYMENT_START, { package: this.shoppingCreditAmount });
      }
      this.paymentGateway = pg.data;
      const eventArgs = {
        'User Id': this.masterDataService.masterData?.user?.id,
        Amount: this.shoppingCreditAmount,
        'Payment Option': pg.data,
        'PaymentGateway': PAYMENT_GATEWAY.JUSPAY,
        Source: EVENT_ARGUMENTS.SHOPPING_CREDITS
      };
      if(pg.data === PAYMENT_VALUES.CRED ) {
        const offerText = this.shoppingCreditsServices.getCredOffer();
        offerText && (eventArgs['cred_offer'] = offerText.offer);
      }
      this.eventsService.trackEvents(EVENT_NAMES.PAYMENT_START, eventArgs);
      this.shoppingCreditsServices.transactionProcess(
        dataToSend,
        this.trackPayment
      );
    }
    this.getStatusOfAutoDebit.next(true);
  };
  subscriptions: Subscription[] = [];
  timeoutForResetError = null;
  layoutDefaultProps = {
    headerVisible : false, 
    footerVisible : true,
    footer : 1,
    tab : SelectTabOption.Wallet
  };
  constructor(
    private modalCtrl: ModalController,
    public masterDataService: MasterDataService,
    public shoppingCreditsServices: ShoppingCreditsServices,
    public dataService: DataService,
    private eventsService: EventsService,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private bottomSheetService: BottomSheetService,
    public paymentModalBaseService: PaymentModalService
  ) {
    super();
    this.shoppingCreditsServices.checkJiopaySegment();
  }

  mbOnInit(): void {
    const selectedTabSubscription = this.masterDataService.selectedTabSub.subscribe(selectedTab => {
      setTimeout(() => {
        this.pageContentRef?.scrollToTop(SCROLL_UP_DURATION);
      }, 100);
    });
    this.subscriptions.push(selectedTabSubscription);
    if (!Utilities.isEmpty(this.dataService.pgFromDeepLink)) {
      this.selectedPGFromDeepLink = Object.assign({}, this.dataService.pgFromDeepLink);
      this.dataService.pgFromDeepLink = null;
    }
    this.superChargeId = this.route.snapshot.params.superChargeId;
    if(this.superChargeId) {
      this.shoppingCreditAmount = this.masterDataService.masterData.supercharge?.schemes[0].amount;
    }
    if (this.masterDataService.masterData?.wallet_increase_offers && this.masterDataService.masterData.wallet_increase_offers.length) {
      const activateOfferIndex = Math.round(this.masterDataService.masterData?.wallet_increase_offers.length / 2);
      this.shoppingCreditAmount = this.masterDataService.masterData?.wallet_increase_offers[activateOfferIndex - 1].amount;
    }
    if (this.route.snapshot.params.memberShipId && this.route.snapshot.params.openPaymentOption) {
      const walletOffer = this.masterDataService.masterData?.wallet_increase_offers?.find(ele => ele.membership_id === parseInt(this.route.snapshot.params.memberShipId, 10));
      this.shoppingCreditAmount = walletOffer.amount;
      this.openPaymentOptionsModal();
    }
    this.getPaymentBanner();
    const openPromoListingSubscription = this.dataService.openPromoListing.subscribe(res => {
      if (res) {
        this.openPromoCodeBottomSheet();
        this.dataService.openPromoModal = false;
      }
    });
    this.subscriptions.push(openPromoListingSubscription);
  }
  
  ionViewWillEnter() {
    if(!this.shoppingCreditsServices.topUpRange){
      this.shoppingCreditsServices.getTopUp().then((data)=>{ 
        if(data){
          this.shoppingCreditAmount = data;
        }});
    }else{
      this.shoppingCreditAmount = parseInt(this.shoppingCreditsServices.topUpRange.amount,10)
    }
    if (this.dataService.openPromoModal) {
      this.openPromoCodeBottomSheet();
      this.dataService.openPromoModal = false;
    }
    this.getStatusOfAutoDebit.next(true);
    this.getBalance();
  }
  
  getPaymentBanner() {
    this.masterDataService.getSegmentsIds().then((segmentIds)=>{
      const getPaymentBannerSubscription = this.shoppingCreditsServices.getPaymentBanner(segmentIds).subscribe((success: HTTPResponse) => {
        this.paymentBanner = success.data || []; 
        this.data = {
          banners : this.paymentBanner || [],
          source : this.eventSource
        };
      });
      this.subscriptions.push(getPaymentBannerSubscription);
    });
  }

 
  setValue(amount: string | number) {
    this.shoppingCreditAmount = amount;
    this.eventsService.trackEvents(EVENT_NAMES.DENOMINATION_CLICKED, { amount: amount });
  }


  getBalance() {
    const getBalanceSubscription = this.shoppingCreditsServices.getBalance().subscribe((success: any) => {
      if (success && success.data.status) {
        if(this.masterDataService?.masterData && ('balance' in this.masterDataService.masterData)){
          this.masterDataService.masterData.balance = success.data.data;
        }
        this.masterDataService.masterData.walletInfo = success.data.walletInfo;
      }
    });
    this.subscriptions.push(getBalanceSubscription);
  }

  resetError() {
    this.timeoutForResetError = setTimeout(() => {
      this.showError = {
        min_amount_error: false,
        max_amount_error: false,
        min_cash_collection_error: false,
        max_cash_collection_error: false
      };
    }, 3000);
  }

  async openPaymentOptionsModal() {
    if(!this.masterDataService.paymentOptions){
      this.masterDataService.getPaymentOptions(true);
    }
    const modal = await this.modalCtrl.create({
      component: PaymentOptionsComponent,
      componentProps: {
        shoppingCreditAmount: this.shoppingCreditAmount
      },
      id: MODAL_IDS.PAYMENT_OPTIONS
    });
    await modal.present();
    modal.onDidDismiss().then(
      this.onDidDismissOfPaymentOptionsModal
    );
  }

  navigateToTransactionHistory() {
    this.eventsService.trackEvents(EVENT_NAMES.TRANSACTION_CLICKED, {
      Source: EVENT_SOURCE.SHOPPING_CREDITS,
      'User Id': this.masterDataService.masterData?.user?.id
    });
    this.navCtrl.navigateForward(PAYMENT_ROUTES.BASE + PAYMENT_ROUTES.TRANSACTION_HISTORY);
  }

  navigateToExternalUrl() {
    this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.FAQ + `/${FaqType.ShoppingCredits}`, { animated: false });
  }

  openPromoCodeBottomSheet() {
    this.bottomSheetService.openPromoCodeList(PromocodeListingComponent);
    this.eventsService.trackEvents(EVENT_NAMES.PROMO_CODE_VIEW, {});
  }

  clearTimers() {
    if (this.timeoutForResetError) {
      clearTimeout(this.timeoutForResetError);
      this.timeoutForResetError = null;
    }
  }

  mbOnDestroy(): void {
    this.clearTimers();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
