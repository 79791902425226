export enum UserDetailsModalSourceType {
  SettingsPage,
  ShoppingCreditsPage
  
}

export enum EventTrackingPlugins {
  All,
  AppsFlyer,
  CleverTap,
  Firebase,
  UserExperior
}

export enum ImageOnDemandType {
  PLP = 'PLP',
  PDP = 'PDP'
}

export enum AutoDebitStatus {
  New,
  Activated,
  ActivationPending,
  ActivationFailed,
  InActive,
  Error
}

export enum SEGMENT_TYPES {
  SUPERCHARGE_SEGMENT = 2,
  AUTO_DEBIT_SEGMENT = 6
}

export enum MB_PASS_TYPE {
  CATEGORY = 1,
  SUB_CATEGORY = 2,
  PRODUCT = 3,
  MIN_BASKET_VALUE = 4,
  COLLECTION = 5
}

export enum WALLET_LINKING {
  MOBILE,
  OTP
}
export enum JOURNEY_TYPE {
  Calendar
}

export enum SheetState {
  Bottom = 0,
  Docked = 1,
  Top = 2
}

export enum BackType {
  Default,
  Close
}

export enum ProfileField{
  GENDER,
  FOOD_PREFERENCE,
  MARITAL_STATUS,
  FAMILY_MEMBER_COUNT,
  ANNIVERSARY,
  BIRTHDAY,
  CHARACTERISTICS,
}

export enum MediaType {
  IMAGE = 1,
  VIDEO,
  LOTTIE
}

export enum BannerType {
  HeroBanner = 11
}

export enum HeroMediaType {
  IMAGE = 1,
  LOTTIE
}

export enum LeafMediaType {
  IMAGE,
  VIDEO,
  LOTTIE
}