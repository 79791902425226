//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import { MomentModule } from 'ngx-moment';
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { ScheduleProductComponent } from '@Shared/product-listing/schedule-product/schedule-product.component';
import { ProductCounterModule } from '../product-counter/product-counter.module';
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MomentModule,
    ProductCounterModule
  ],
  exports: [ScheduleProductComponent],
  declarations: [ScheduleProductComponent],
})
export class ScheduleProductModule extends DefaultModule { }