<mbs-footer-strip 
   class="mahacashback-postion" *ngIf="showBottomNav && (currentTab === selectTabOption.Home || currentTab === selectTabOption.Category)">
</mbs-footer-strip>

<ion-toolbar *ngIf="showBottomNav" class="ios-sb-bar">
   <ion-buttons>
      <ion-button (click)="changeTab(selectTabOption.Home)" class="no-ripple-effect" [ngClass]="{'btm-active' :currentTab === selectTabOption.Home}">
         <div>
            <div class="ion-holder">
               <ion-icon *ngIf="currentTab !== selectTabOption.Home;else anim_home" class="icon-hm-nw icons-property " ></ion-icon>
            </div>
            <ng-template #anim_home>
               <ng-lottie class="ion-holder fade-in-anim" [options]="{path: 'assets/lottie/home.json', loop: false, autoplay: currentTab === selectTabOption.Home}" width="24" height="24">
               </ng-lottie>
            </ng-template>
            <p>Home</p>
         </div>
      </ion-button>
      <ion-button (click)="changeTab(selectTabOption.Category)" class="no-ripple-effect" [ngClass]="{'btm-active' :currentTab === selectTabOption.Category}">
         <div>
            <div class="ion-holder">
               <ion-icon *ngIf="currentTab !== selectTabOption.Category;else anim_category" class="icon-categories icons-property"></ion-icon>
            </div>
            
            <ng-template #anim_category>
               <ng-lottie class="ion-holder fade-in-anim" [options]="{path: 'assets/lottie/category.json', loop: false, autoplay: currentTab === selectTabOption.Category}" width="24" height="24">
               </ng-lottie>
            </ng-template> 
            <p>Categories</p>
         </div>
      </ion-button>
      <ion-button (click)="changeTab(selectTabOption.Basket)" class="no-ripple-effect" [ngClass]="{'btm-active' :currentTab === selectTabOption.Basket}">
         <div>
            <div class="ion-holder">
               <ion-icon *ngIf="currentTab !== selectTabOption.Basket;else anim_basket" class="icon-basket icons-property"></ion-icon>
            </div>
            
            <ng-template #anim_basket>
               <ng-lottie class="ion-holder fade-in-anim" [options]="{path: 'assets/lottie/basket.json', loop: false, autoplay: currentTab === selectTabOption.Basket}" width="24" height="24">
               </ng-lottie>
            </ng-template>
            <p>Basket</p>
            <ion-badge *ngIf="
            masterDataService.baskets?.length &&
            (masterDataService.baskets)[0] &&
            (masterDataService.baskets)[0].quantity > 0
         " class="ion-no-padding ion-text-center">{{
            masterDataService.baskets?.length &&
            (masterDataService.baskets)[0]?.quantity
            }}</ion-badge>
         </div>
      </ion-button>
      <ion-button (click)="changeTab(selectTabOption.Wallet)" class="no-ripple-effect" [ngClass]="{'btm-active' :currentTab === selectTabOption.Wallet}">
         <div>
            <div class="ion-holder">
               <ion-icon  *ngIf="currentTab !== selectTabOption.Wallet;else anim_wallet" class="icon-wallet-nw icons-property"></ion-icon>
            </div>
            
            <ng-template #anim_wallet>
               <ng-lottie class="ion-holder fade-in-anim" [options]="{path: 'assets/lottie/wallet.json', loop: false, autoplay: currentTab === selectTabOption.Wallet}" width="24" height="24">
               </ng-lottie>
            </ng-template>
            <p>Wallet</p>
         </div>
      </ion-button>
      <ion-button (click)="changeTab(selectTabOption.More)" class="no-ripple-effect more-btn-width" [ngClass]="{'btm-active' :currentTab === selectTabOption.More}">
         <div>
            <div class="ion-holder">
               <ion-icon *ngIf="currentTab !== selectTabOption.More;else anim_more" class="icon-more-nw"></ion-icon>
            </div>
            
            <ng-template #anim_more>
               <ng-lottie class="ion-holder fade-in-anim" [options]="{path: 'assets/lottie/more.json', loop: false, autoplay: currentTab === selectTabOption.More}" width="24" height="24">
               </ng-lottie>
            </ng-template>
            <p>More</p>
         </div>
      </ion-button>
   </ion-buttons>
</ion-toolbar>