import { Injectable } from '@angular/core';
import { APIs } from '@App/shared/constants/APIs';
import { HttpService } from '@Globals/providers/http.service';
import { HTTPResponse } from '@ionic-native/http';
import { MEMBERSHIP_APIS } from '@Pages/membership/membership-constants/membership-constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private httpService: HttpService) { }

  customerRating(){
   return this.httpService.post(APIs.CUSTOMER_RATING,{hideLoader: true});
  }

  cashbackSavings(){
    return this.httpService.get(APIs.CASHBACK_SAVING,{hideLoader: true});
  }

  getTargetMembership(): Observable<any> {
    return this.httpService.get(MEMBERSHIP_APIS.TARGET_MEMBERSHIP);
  }
}
