//#region Imports

//#region Angular Imports
import { Injectable } from "@angular/core";
//#endregion Angular Imports

//#region Ionic Imports
import { Subject, noop } from "rxjs";
import { ModalController, NavController, Platform } from "@ionic/angular";
//#endregion Ionic Imports

//#region Library Imports
import { take } from "rxjs/operators";
//#endregion Library Imports

//#region Module Imports
import { SmsRetriever } from "@ionic-native/sms-retriever/ngx";
import { Appsflyer } from "@ionic-native/appsflyer/ngx";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { LogoutPlugins } from "@Globals/classes/logout-plugins";
//#endregion Module Imports

//#region Component Imports
import { PaymentOptionsComponent } from "@Pages/payment/shopping-credits/components/payment-options/payment-options.component";
import { LowBalanceComponent } from "@Shared/payment/low-balance/low-balance.component";
//#endregion Component Imports

//#region Providers Imports
import { DataService } from "@Globals/providers/data.service";
import { EventsService } from "@Globals/providers/events.service";
import { AuthService } from "@Globals/providers/auth.service";
import { MasterDataService } from "@Globals/providers/master-data.service";
import { VideoService } from "@App/listing/providers/video.service";
import { DeepLinkService } from "@Globals/providers/deep-link.service";
import { FirebaseService } from "@Globals/providers/firebase.service";
import { LoginService } from "@Globals/providers/login.service";
import { HttpService } from "@Globals/providers/http.service";
import { ToastService } from "@Globals/providers/toast.service";
import { NavigatorService } from "@Globals/providers/navigator.service";
import { ApolloClientService } from "./apollo-cache.service";
import { RecurringService } from "@Globals/providers/recurring.service";
import { ShoppingCreditsServices } from "@Globals/providers/shopping-credits.service";
import { ProductListManagerService } from "@Features/product-list/providers/product-list-manager.service";
import { AlertService } from "@Globals/providers/alert.service";
import { PermissionsService } from "@Globals/providers/profile/permissions.service";

import { ALERT_BUTTON_TEXT } from "@App/shared/constants/constants";
import { SIGNUP_ERRORS } from "@App/registration/constants/registration-constants";
import { LocationService } from "@Globals/providers/location.service";
import { LocationComponentType } from "@Globals/constants/registration/enums";
import { SignupService } from "@Globals/providers/signup.service";
import { AddressStatus } from "@Globals/constants/registration/constants";
import { HttpClient } from "@angular/common/http";
import { Diagnostic } from '@ionic-native/diagnostic/ngx';

//#endregion Providers Imports

//#region Other Imports
import { environment } from "@Env/environment";
import { Utilities } from "@Globals/classes/utilities";
import {
  MODALS,
  STORAGE_KEYS,
  SOCIAL_SHARING_CHANNELS,
  IOS,
  EVENT_ARGUMENTS,
  MODAL_IDS,
  WHATSAPP,
} from "@Globals/constants/listing/constants";
import {
  EVENT_NAMES,
  INVITE_CHOOSER_TITLE,
  REMINDER_MESSAGE,
  SHARE_SUBJECT,
} from "@App/profile/constants/profile-constants";
import {
  APPSFLYER,
  FIREBASE_REMOTE_CONFIG_CALL,
  LOGIN_SIGNUP_A2B_POPUP,
} from "@App/core/constants/core-constants";
import { Device } from "@ionic-native/device/ngx";
import {
  EVENT_NAMES as LISTING_EVENT_NAMES,
  EVENT_SOURCES,
} from "@Globals/constants/listing/constants";
import {
  ProductListType,
  RecurringViewType,
  WhatsappSharingComponentTypes,
} from "@Globals/constants/listing/enums";

import { InitiateTransactionDataToSend } from "@Globals/interfaces/payment/interfaces";
import {
  PAYMENT_GATEWAY,
  PAYMENT_VALUES,
  SUCCESS_PURCHASED_MB_PASS,
} from "@Globals/constants/payment/constants";

import { EventTrackingPlugins } from "@App/shared/constants/enums";
import {
  LISTING_ROUTES,
  CORE_ROUTES,
  PROFILE_ROUTES,
} from "@Globals/constants/listing/routes";
import { REGISTRATION_ROUTES } from "@Globals/constants/registration/routes";
import { PAYMENT_ROUTES } from "@Globals/constants/payment/routes";
import { SimilarProducts } from "@App/listing/interfaces/listing-interface";

import {
  productDossierToProductApi,
  productToProductApi,
} from "./api-transformers/product";
import { InfoPopupComponent } from "@Shared/info-popup/info-popup.component";
import { LOGOUT_MODE, PAYMENT_CACHE } from "@App/shared/constants/constants";
import { DATA_UI_EVENT_SOURCE_TYPES } from "@App/registration/constants/registration-constants";
import {
  SOCIAL_SHARING_DESCRIPTION,
  SOCIAL_SHARING_MESSAGE,
} from "@App/listing/constants/listing-constants";
import { basketToBasketApi } from "./api-transformers/basket";
import { File, FileEntry } from "@ionic-native/file/ngx";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { LoggingService } from "@Globals/providers/logging.service";
import { AccountHistoryService } from "@Pages/account-history/providers/account-history.service";
import { StorageService } from "@Globals/providers/storage.service";
import { HTTPResponse } from "@ionic-native/http";
import { AppAvailability } from "@ionic-native/app-availability/ngx";
import { showEmptyCartPopup } from '@mb-lib/apollo-client';
import { showUpcomingDeliveryPopup } from '@mb-lib/apollo-client';
import { showAddressUnverifyPopup } from '@mb-lib/apollo-client';
//#endregion Other Imports

//#endregion Imports

declare var window: any;
declare type ToastOptions = {
  msg: string;
  className?: string;
  position?: string;
  duration?: number;
};
const keyboardShowEventAdded = new Map();
const keyboardHideEventAdded = new Map();
declare var navigator: Navigator;

@Injectable()
export class EventManagerService {
  lowBalanceModelOpen = false;

  constructor(
    public navCtrl: NavController,
    public dataService: DataService,
    public eventsService: EventsService,
    public authService: AuthService,
    public smsRetriever: SmsRetriever,
    public masterDataService: MasterDataService,
    public deeplinkService: DeepLinkService,
    public firebaseService: FirebaseService,
    public loginService: LoginService,
    public device: Device,
    public httpService: HttpService,
    public socialSharing: SocialSharing,
    public appsFlyer: Appsflyer,
    public navigatorService: NavigatorService,
    public apolloClient: ApolloClientService,
    public toastService: ToastService,
    public videoService: VideoService,
    public recurringService: RecurringService,
    public modalCtrl: ModalController,
    public shoppingCreditsServices: ShoppingCreditsServices,
    public plmService: ProductListManagerService,
    public camera: Camera,
    public alertService: AlertService,
    private permissionsService: PermissionsService,
    private file: File,
    private fileOpener: FileOpener,
    private platform: Platform,
    private loggingService: LoggingService,
    private accountHistoryService: AccountHistoryService,
    private storageService: StorageService,
    private appAvailability: AppAvailability,
    private locationService: LocationService,
    private signupService: SignupService,
    private HttpClient: HttpClient, 
    private diagnostic: Diagnostic,
  ) {}

  private navigateToPreviousUrl() {
    if (
      window.location.href.indexOf(LISTING_ROUTES.SUBCATEGORY) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.CATEGORY) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.COLLECTION_VIDEO) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.COLLECTION_LISTING) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.RECURRING_ORDERS) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.ACCOUNT_HISTORY) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.COLLECTIONPLP) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.YOUR_CART) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.SUBSCRIBE_TO_SAVE) > -1 ||
      window.location.href.endsWith(PAYMENT_ROUTES.SUPER_CHARGE) ||
      window.location.href.indexOf(PAYMENT_ROUTES.SHOPPING_CREDITS) > -1
    ) {
      this.navCtrl.pop();
    } else if (
      window.location.href.indexOf(REGISTRATION_ROUTES.SIGN_UP_DETAILS) ===
        -1 &&
      window.location.href.indexOf(REGISTRATION_ROUTES.SELECT_SOCIETY) === -1
    ) {
      this.navCtrl.pop();
    } else if (
      window.location.href.indexOf(REGISTRATION_ROUTES.SELECT_SOCIETY) > -1
    ) {
      this.navCtrl.navigateBack(CORE_ROUTES.BOARDING);
    } else {
      this.navCtrl.navigateBack(
        REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.SELECT_SOCIETY
      );
    }
  }

  private navigateByUrl(url, options) {
    const paramData = this.dataService.paramData;
    this.dataService.paramData = { ...paramData, ...options };
    if (url === "::ng::open-clevertap") {
      return this.dataService.openUserInbox();
    }
    if (options && options.root === true) {
      return this.navCtrl.navigateRoot(url,options);
    }
    return this.navCtrl.navigateForward(url, options);
  }

  private trackEvents(
    name: string,
    source?: string,
    data?: unknown,
    options?: {
      sendUid?: boolean;
      plugin?: EventTrackingPlugins;
    }
  ) {
    data = data || {};
    if (!!source) {
      (data as Record<string, string>)["Source"] = source;
    }
    return this.eventsService.trackEvents(
      name,
      data,
      !!options?.sendUid,
      options?.plugin || undefined
    );
  }

  private generateAppHash() {
    // setup timeout functionality here if needed and possible
    return new Promise((resolve) => {
      this.smsRetriever
        .getAppHash()
        .then((hashCode: string) => {
          resolve(hashCode);
        })
        .catch()
        .finally(() => {
          resolve("");
        });
    });
  }
  private startWatchingOtp() {
    return new Promise((resolve) => {
      this.smsRetriever
        .startWatching()
        .then((sms: any) => {
          const retrievedOTP = sms.Message.match(/\d{4}/)[0];
          if (retrievedOTP) {
            resolve(retrievedOTP);
          } else {
            resolve("");
          }
        })
        .catch(() => {
          resolve("");
        });
    });
  }

  private setAuthKey(authKey: string) {
    const authKeyResult = this.setupAuthKey(authKey);
    this._loginHook();
    return authKeyResult;
  }
  private setupAuthKey(authKey: string) {
    const authKeySetupOutput = this.authService.setAuthKey(authKey);
    this.authService.isAuthenticated.next(true);
    return authKeySetupOutput;
  }

  _loginHook() {
    this.masterDataService.resetCacheData();
    this.masterDataService.masterDataUpdateObs.next(false);
    this.masterDataService.masterDataUpdateObs
      .pipe(take(2))
      .subscribe((dataLoaded) => {
        if (dataLoaded) {
          this.eventsService.trackEvents(
            LISTING_EVENT_NAMES.LOGIN_DATA_UI_SUB,
            { message: "Login response recieved" },
            false,
            EventTrackingPlugins.All
          );
          this.firebaseService.registerForPushNotification(
            FIREBASE_REMOTE_CONFIG_CALL.All
          );
          localStorage.setItem(
            STORAGE_KEYS.USER_DATA,
            JSON.stringify(this.masterDataService.masterData?.user)
          );
          this.dataService._isJioUserSubject.getValue() &&
            localStorage.setItem(
              STORAGE_KEYS.JIO_NUMBER,
              this.authService.userData.mobileNumber
            );
          let url = "";
          if (
            this.deeplinkService.navigateProductIdDetails &&
            this.deeplinkService.navigateProductIdDetails.id
          ) {
            this.dataService.selectedPDPProduct.next({
              productId: this.deeplinkService.navigateProductIdDetails.id,
              productIndex: 0,
              source: undefined,
              eventData: undefined,
              itemType: undefined,
              product: undefined,
              appliedFilter: undefined,
              basketIndex: undefined,
            });
            url =
              "/" +
              CORE_ROUTES.LISTING +
              "/" +
              LISTING_ROUTES.PRODUCT_DETAIL +
              `/${this.deeplinkService.navigateProductIdDetails.id}`;
          } else if (this.authService.jioMartData.isSelfHelpRedirect) {
            url =
              "/" +
              LISTING_ROUTES.HOME +
              "/" +
              MODALS.HELP +
              "/" +
              `${Utilities.createRandom4DigitNumber()}`;
          } else {
            url = "/" + LISTING_ROUTES.HOME;
          }
          this.navCtrl.navigateRoot(url);
        }
        this.authService.setAuthSubscriber();
      });
    this.masterDataService.getMasterData(
      false,
      true,
      false,
      true,
      false,
      DATA_UI_EVENT_SOURCE_TYPES.LOGIN_HOOK
    );
    this.masterDataService.getRecurringCampaignOfferDetails();
  }
  private setRefreshKey(refreshKey: string, refreshTime: number) {
    return this.authService.setRefreshKey(
      refreshKey,
      Utilities.addMinutes(
        new Date(),
        refreshTime - this.httpService.retryConfig.timeBeforeTokenCall
      )
    );
  }

  private getAppVersion() {
    return environment.codePushVersion;
  }

  private getAppBinaryVersion() {
    return environment.appStoreVersion;
  }
 
  private getBasket(index: number) {
    if (
      this.masterDataService.baskets &&
      index in this.masterDataService.baskets
    ) {
      return this.masterDataService.baskets[index];
    }
    return null;
  }

  private getConfig(key: string) {
    if (
      this.dataService.firebaseMasterData &&
      key in this.dataService.firebaseMasterData
    ) {
      return this.dataService.firebaseMasterData[key];
    }
    return null;
  }

  private showFraudUserAlert(error, errorData) {
    let transformedErrorData = {};
    if (errorData && errorData.length) {
      errorData.forEach((errorPair) => {
        transformedErrorData[errorPair.phone] = errorPair.address;
      });
    }
    const fraudDetail = {
      data: {
        error,
        error_data: transformedErrorData,
      },
    };
    this.loginService.showFraudAlert(fraudDetail);
  }

  private fetchDeviceDetails() {
    const deviceDetails = {
      udid: this.device.uuid || "web_login",
      deviceModel: this.device.model,
      isVirtual: this.device.isVirtual,
      manufacturer: this.device.manufacturer,
      platform: this.device.platform,
    };
    return deviceDetails;
  }

  private getAppPlatform() {
    return this.dataService.currentPlatform;
  }

  private setUserPhoneNumber(phone) {
    localStorage.setItem('userMobileNumber',phone);
    this.authService.userData.mobileNumber = phone;
  }
 
  getCatgeoryId(subCategoryId) {
    let selectedCategory;
    for (const category of this.masterDataService.categories) {
      const subCategoryDetail = category.subcategories.find((subCat) => {
        return +subCat.id === +subCategoryId;
      });
      if (subCategoryDetail) {
        selectedCategory = category;
        break;
      }
    }
    return selectedCategory ? selectedCategory?.id : undefined;
  }

  private getSocialSharingChannels() {
    const channels = {};
    Object.keys(SOCIAL_SHARING_CHANNELS).forEach((channel) => {
      const channelData = SOCIAL_SHARING_CHANNELS[channel];
      channels[channel] = {
        available: channelData["isAvailable"],
        android: channelData["android"],
        ios: channelData["ios"],
      };
    });
    return channels;
  }

  private copyToClipboard(data: string, eventName: string) {
    return new Promise((resolve) => {
      try {
        this.dataService.copyCode(data, eventName);
        resolve("success");
      } catch (error) {
        resolve("");
      }
    });
  }

  private sendReferralReminder(image) {
    return new Promise((resolve) => {
      try {
        this.eventsService.trackEvents(
          EVENT_NAMES.REFERRAL_SEND_REMINDER_START,
          {}
        );
        this._openShareOptions(
          { message: REMINDER_MESSAGE },
          null,
          true,
          image
        );
        resolve("success");
      } catch (error) {
        resolve("");
      }
    });
  }

  private createAndShareReferralLink(description, image, channel?) {
    return new Promise((resolve) => {
      const inviteOptions = {
        channel: APPSFLYER.REFERRAL,
        campaign: APPSFLYER.REFERRAL,
        userParams: {
          referrer_id: this.masterDataService.masterData?.user?.id,
          referrer_name: this.masterDataService.masterData?.user?.name,
          referrer_address: this.masterDataService.masterData?.user?.address,
          referrer_society: this.masterDataService.masterData?.user?.society,
          referrer_city: this.masterDataService.masterData?.user?.city,
          referrer_code: this.masterDataService.masterData?.user?.referrer_code,
          redirect_url: CORE_ROUTES.BOARDING,
        },
      };
      this.appsFlyer
        .generateInviteLink(inviteOptions)
        .then((inviteLink) => {
          this.eventsService.trackEvents(EVENT_NAMES.REFERRAL_INVITE_START, {});
          const options = {
            message: description,
            subject: SHARE_SUBJECT,
            url: inviteLink,
            chooserTitle: INVITE_CHOOSER_TITLE,
          };
          this._openShareOptions(options, channel, false, image);
          resolve("success");
        })
        .catch((error) => {
          resolve("");
        });
    });
  }

  _openShareOptions(options, channel, isSendingReminder, image) {
    switch (channel) {
      case SOCIAL_SHARING_CHANNELS.whatsapp[this.dataService.currentPlatform]:
      case SOCIAL_SHARING_CHANNELS.messenger[this.dataService.currentPlatform]:
        if (this.dataService.currentPlatform === IOS) {
          if (
            channel ===
            SOCIAL_SHARING_CHANNELS.whatsapp[this.dataService.currentPlatform]
          ) {
            // in case of ios referal image does not support so change back to normal
            if (
              channel ===
              SOCIAL_SHARING_CHANNELS.whatsapp[this.dataService.currentPlatform]
            ) {
              this.socialSharing
                .shareViaWhatsApp(options.message, null, options.url)
                .then(() => {
                  this._trackReferralInviteComplete(channel, false);
                })
                .catch();
            }
          }
        } else {
          this.socialSharing
            .shareVia(
              channel,
              options.message,
              options.subject,
              environment.REFER_IMAGE_URL + image,
              options.url
            )
            .then(() => {
              this._trackReferralInviteComplete(channel, false);
            })
            .catch();
        }
        break;
      default:
        this.socialSharing
          .shareWithOptions(options)
          .then((channelInfo) => {
            if (channelInfo && channelInfo.app) {
              // extract channel name under group 1 of applied regex if available else will pass channelInfo.app as it is in event
              const appInfoArray = channelInfo.app.match(/{(.*)\//);
              const channelName =
                (appInfoArray && appInfoArray[1]) || channelInfo.app;
              if (isSendingReminder) {
                this.eventsService.trackEvents(
                  EVENT_NAMES.REFERRAL_SEND_REMINDER_COMPLETE,
                  { channel_name: channelName }
                );
              } else {
                this._trackReferralInviteComplete(channelName, true);
              }
            }
          })
          .catch();
    }
  }

  _trackReferralInviteComplete(channel, isSourceRequired) {
    const eventData: any = { channel_name: channel };
    isSourceRequired && (eventData.source = EVENT_NAMES.REFERRAL_INVITE_START);
    this.eventsService.trackEvents(
      EVENT_NAMES.REFERRAL_INVITE_COMPLETE,
      eventData
    );
  }

  private handleCollectionSelection(reactCollection) {
    if (reactCollection.design.displayType === 109) {
      return;
    }
    this.dataService.collectionsConfigData = {
      leafRequest: [],
      listImageUrl: null,
      collection: null,
    };
    const collection = { ...reactCollection };
    collection["metaData"] = {};
    collection["table"] = {
      displayType:
        reactCollection.design.displayType === 104
          ? 4
          : reactCollection.design.displayType,
      rows: [{ columns: [] }],
    };
    collection["metaData"]["cells"] = [];
    collection.design.rows[0].columns.forEach((col, colIndex) => {
      let colAdhoc = {} as { bannerType?: string };
      try {
        colAdhoc = col.adhoc ? JSON.parse(col.adhoc) : {};
      } catch (e) {
        colAdhoc = {};
      }
      const column = {
        identifier: col.identifier,
        image: {
          leafBanner: {
            listId: +col.listId,
            bannerType: colAdhoc?.bannerType ? colAdhoc.bannerType : null,
          },
        },
        listPageBannerImage: "",
        collectionLeafId: +col.leafId,
      };
      collection.metaData.cells.push(column);
      this.dataService.collectionsConfigData.leafRequest.push({
        leafId: +col.leafId,
        listId: +col.listId,
      });

      if (colIndex === 0) {
        collection["table"].rows[0] = collection["table"].rows[0] || [
          { columns: [] },
        ];
        collection["table"].rows[0].columns[0] = column;
      }
    });
    if (
      reactCollection.design.displayType === 12 ||
      reactCollection.design.displayType === 16
    ) {
      let videoURL =
        reactCollection.design?.rows?.[0].columns?.[0].assets.video?.url;
      if (
        reactCollection.design?.rows?.[0]?.columns?.[0]?.assets?.video.type ===
        1
      ) {
        collection.video = videoURL;
      } else {
        collection.playbackURL = videoURL;
      }
      collection.image =
        reactCollection.design?.rows?.[0]?.columns?.[0]?.assets?.video?.thumbnail;
    }
    this.dataService.collectionsConfigData.collection = collection;
  }

  private navigateDeepLink(url, source = "collection", collectionName = "") {
    this.deeplinkService.navToDeepLinkUrl(url, source, collectionName);
  }

  private setVideoCollectionData(collection) {
    this.videoService.selectedVideoCollectionData.next(
      this.dataService.collectionsConfigData.collection
    );
  }

  private onBrandSelection(data) {
    let brandData: any = {};
    brandData.head = data.title;
    brandData.subHead = data.subtitle;
    brandData.position = data.position;
    brandData.typeId = data.design.displayType === 107 ? 2 : 1;
    brandData.brands = [];
    data.design.rows[0].columns.forEach((col) => {
      let id;
      try {
        id = JSON.parse(col.adhoc).id;
      } catch (e) {}
      brandData.brands.push({
        brandId: +col.identifier,
        brandName: col.title.text,
        id: id,
        listId: +col.listId,
        logo: col.assets.image.url,
        products: [],
      });
    });
    this.dataService.brandLaunchData = brandData;
  }
 
  private addKeyboardShowListener(callback) {
    if (!keyboardShowEventAdded.get(callback)) {
      keyboardShowEventAdded.set(callback, callback);
      window.addEventListener("keyboardDidShow", (event) => {
        callback();
      });
    }
  }

  private addKeyboardHideListener(callback) {
    if (!keyboardHideEventAdded.get(callback)) {
      keyboardHideEventAdded.set(callback, callback);
      window.addEventListener("keyboardDidHide", (event) => {
        callback();
      });
    }
  }

  public onDeviceBackButtonPress(callback) {
    this.navigatorService.setupDeviceBackForReact(callback);
  }

  recommendedProductList: SimilarProducts = {
    product: {
      id: 0,
    },
    title: "Suggestions for you",
  };
  
  shoppingCreditAmount;
  paymentGateway = "";
  public async openPaymentOptionsModal(shoppingCreditAmount) {
    if (this.masterDataService.isJioPayEnabled) {
      const eventArgs = {
        "User Id": this.masterDataService.masterData?.user?.id,
        Amount: shoppingCreditAmount,
        PaymentGateway: PAYMENT_GATEWAY.JIOPAY,
        Source: EVENT_ARGUMENTS.SHOPPING_CREDITS,
      };
      this.eventsService.trackEvents(
        LISTING_EVENT_NAMES.PAYMENT_START,
        eventArgs
      );
      this.shoppingCreditsServices.openPaymentOptionsModalJio(
        shoppingCreditAmount,
        this.trackPayment
      );
    } else {
      this.shoppingCreditAmount = shoppingCreditAmount;
      this.eventsService.trackEvents(
        LISTING_EVENT_NAMES.ADD_MONEY_SCREEN_VISITS,
        {}
      );
      if (!this.masterDataService.paymentOptions) {
        this.masterDataService.getPaymentOptions(true);
      }
      this.dataService.paramData = {
        ...this.dataService.paramData,
        shoppingCreditAmount: +this.shoppingCreditAmount,
        sourceType: 0,
        trackPayment: this.trackPayment
      }
      this.navCtrl.navigateForward(PAYMENT_ROUTES.BASE + PAYMENT_ROUTES.PAYMENT_METHOD);
    }
  }

  onDidDismissOfPaymentOptionsModal = (pg) => {
    if (pg.data) {
      const dataToSend: InitiateTransactionDataToSend = {
        amount: this.shoppingCreditAmount,
        pg: pg.data,
      };
      /* if (this.superChargeId) {
        dataToSend.supercharge_id = this.superChargeId;
        this.eventsService.trackEvents(EVENT_NAMES.SUPERCHARGE_PAYMENT_MODE, { mode: EVENT_SOURCE.ONLINE });
        this.eventsService.trackEvents(E
          
          rENT_NAMES.SUPERCHARGE_PAYMENT_START, { package: this.shoppingCreditAmount });
      } */
      this.paymentGateway = pg.data;
      const eventArgs = {
        "User Id": this.masterDataService.masterData?.user?.id,
        Amount: this.shoppingCreditAmount,
        "Payment Option": pg.data,
        Source: EVENT_ARGUMENTS.SHOPPING_CREDITS,
      };
      if (pg.data === PAYMENT_VALUES.CRED) {
        const offerText = this.shoppingCreditsServices.getCredOffer();
        offerText && (eventArgs["cred_offer"] = offerText.offer);
      }
      this.eventsService.trackEvents(
        LISTING_EVENT_NAMES.PAYMENT_START,
        eventArgs
      );
      this.shoppingCreditsServices.transactionProcess(
        dataToSend,
        this.trackPayment
      );
    }
    // this.getStatusOfAutoDebit.next(true);
  };

  trackPayment = (paymentResponse, txnId, paymentData) => {
    const paymentdata = {
      'User Id': this.masterDataService.masterData.user.id,
      txnId,
      amount: paymentData.amount,
      payment_option: paymentData.pg,
      category: "Event",
      PaymentGateway: paymentData.PaymentGateway || PAYMENT_GATEWAY.JUSPAY,
      Source: paymentData.source ? paymentData.source : "",
      errorStatus: paymentData.errorStatus ? paymentData.errorStatus : "",
      errorMessage: paymentData.errorMessage ? paymentData.errorMessage : "",
    };
    if (paymentResponse === "success") {
      if (paymentData.pg === PAYMENT_VALUES.CRED) {
        const offerText = this.shoppingCreditsServices.getCredOffer();
        offerText && (paymentdata["cred_offer"] = offerText.offer);
      }
      paymentData['af_revenue'] = paymentData.amount;
      paymentData['af_currency'] = 'INR';
      this.eventsService.trackEvents(
        LISTING_EVENT_NAMES.PAYMENT_SUCCESS,
        paymentdata
      );
    } else {
      this.eventsService.trackEvents(
        LISTING_EVENT_NAMES.PAYMENT_FAILURE,
        paymentdata
      );
    }
  };

  private setMBPassData(mbPass) {
    return new Promise((resolve) => {
      try {
        const passData = this.dataService.mbPassData;
        passData.id = null;
        passData.customerId = null;
        passData.date = null;
        passData.startDate = mbPass.startDate;
        passData.endDate = mbPass.endDate;
        passData.savingsCredited = null;
        passData.cashbackCreditType = null;
        passData.cashbackLedger = null;
        passData.offerCategoryValue = mbPass.offerCategoryValue || [null];
        passData.applyCategoryAndSubcategory =
          this.dataService.mbPassData.applyCategoryAndSubcategory;
        this.masterDataService.masterData.customer_mb_pass = passData;
        this.masterDataService.getMbPass(true);
        this.masterDataService.checkJioStrip();
        this.dataService.runChangeDetForMBPass.next(true);
        this.masterDataService.refreshBalance.next(PAYMENT_CACHE.ALL);
        this._successAlertForMbPassPurchased();
        resolve("success");
      } catch (error) {
        resolve("");
      }
    });
  }

  _successAlertForMbPassPurchased() {
    let message = SUCCESS_PURCHASED_MB_PASS.message;
    message = message.replace(
      "$$mb_pass_description$$",
      this.dataService.mbPassData.passDescription
    );
    this.toastService.presentToast(
      message,
      SUCCESS_PURCHASED_MB_PASS.class,
      SUCCESS_PURCHASED_MB_PASS.position
    );
  }

  private getPicture(source: number) {
    return new Promise((resolve) => {
      try {
        const callback = () => {
          this.openCameraGallery(source, resolve);
        };
        if (!this.permissionsService.isCameraAvailable) {
          this.permissionsService.cameraPermission(callback);
        } else {
          this.openCameraGallery(source, resolve);
        }
      } catch (error) {
        resolve("");
      }
    });
  }

  // sourceValue Can be 0:PHOTOLIBRARY Or 1:CAMERA.
  async openCameraGallery(sourceValue: number, resolve) {
    try {
      const data = await this.camera.getPicture({
        quality: environment.CAMERACONFIG.quality,
        targetWidth: environment.CAMERACONFIG.targetWidth,
        targetHeight: environment.CAMERACONFIG.targetHeight,
        destinationType: this.camera.DestinationType.DATA_URL,
        correctOrientation: true,
        sourceType: sourceValue,
      });
      if (data?.endsWith(".svg")) {
        this.toastService.presentToast(
          "SVG file not allowed. Please choose other image."
        );
        return;
      }
      const imageData = {
        file: data,
        fileName: "image" + new Date().getTime(),
      };
      resolve(imageData);
    } catch (err) {
      resolve("");
    }
  }

  accountDeletedPostProcess() {
    new LogoutPlugins(this.appsFlyer).doLogoutPlugins(
      this.masterDataService.masterData?.user
    );
    this.authService.isLogout.next(LOGOUT_MODE.FORCE_LOGOUT);
    this.dataService.resetOffersData();
    this.dataService.clearFirebaseConfigData();
    this.dataService.inviteCodes = [];
    this.masterDataService.paymentOptions = null;
    this.masterDataService.paymentOffers = [];
    this.masterDataService.resetPaymentOptionSection();
    this.masterDataService.resetCacheData();
    this.masterDataService.masterDataUpdateObs.next(false);
    this.dataService.mbPassData = {};
    this.masterDataService.resetMasterData();
    const navigateTo = CORE_ROUTES.SIGNUP_JOURNEY;
    this.navCtrl.navigateRoot(navigateTo);
    this.modalCtrl.getTop().then((topModal) => {
      if (topModal) {
        this.modalCtrl.dismiss();
      }
    });
  }

  navigateToSelfHelp(selfHelpParams) {
    this.dataService.openSelfHelpUrl = selfHelpParams;
    this.navCtrl.navigateForward(PROFILE_ROUTES.BASE + PROFILE_ROUTES.CONTACT);
  }

  setDataForExternalUrl(title, url, backgroundColor, parameters=false) {
    this.dataService.externalUrl.title = title;
    this.dataService.externalUrl.url = url;
    this.dataService.externalUrl.backgroundColor = backgroundColor;
    this.dataService.externalUrl.parameters = parameters;
  }

  logout() {
    this.authService.isLogout.next(LOGOUT_MODE.FORCE_LOGOUT);
  }

  generateLinkAndShare(id: number) {
    let redirectUrl: string;
    const options = {
      message: "",
      subject: null,
      link: "",
      image: null,
    };

    const metaData = {
      id,
      name: "Collection Sharing",
    };

    const componentType = WhatsappSharingComponentTypes.Collection_Listing;

    redirectUrl = LISTING_ROUTES.COLLECTION_LISTING + "/" + metaData.id;

    const eventData: any = {
      type: WhatsappSharingComponentTypes[componentType],
      redirectUrl,
    };
    SOCIAL_SHARING_CHANNELS.whatsapp.isAvailable &&
      (eventData.channel = WHATSAPP);
    this.eventsService.trackEvents(
      LISTING_EVENT_NAMES.SHARING_START,
      eventData
    );

    const share_message = SOCIAL_SHARING_MESSAGE;
    options.message = share_message;

    const dataToSend = {
      dynamicLinkInfo: {
        domainUriPrefix:
          environment.firebaseDeeplinkConfiguration.domainUriPrefix,
        link: `${environment.firebaseDeeplinkConfiguration.link}${redirectUrl}`,
        androidInfo: {
          androidPackageName: "com.milkbasket.app",
        },
        iosInfo: {
          iosBundleId: "com.milkbasket.app",
        },
        socialMetaTagInfo: {
          socialTitle: metaData.name,
          socialImageLink:
            environment.firebaseDeeplinkConfiguration.socialImageLink,
          socialDescription: SOCIAL_SHARING_DESCRIPTION,
        },
      },
    };
    this.HttpClient.post(
      environment.firebaseDeeplinkConfiguration.createLinkApi,
      dataToSend
    ).subscribe((resp: any) => {
      options.link = resp.shortLink;
      this.shareWithOptions({
        message: options.message,
        url: options.link,
      });
    });
  }

  private shareWithOptions(options) {
    this.socialSharing
      .shareWithOptions(options)
      .then((channelInfo) => {
        if (channelInfo && channelInfo.app) {
          // extract channel name under group 1 of applied regex if available else will pass channelInfo.app as it is in event
          const appInfoArray = channelInfo.app.match(/{(.*)\//);
          const channelName =
            (appInfoArray && appInfoArray[1]) || channelInfo.app;
        }
      })
      .catch();
  }

  openSharingOptions(options) {
    const shareData = {
      message: options.message,
      url: options.link,
    };
    this.socialSharing
      .shareWithOptions(shareData)
      .then((channelInfo) => {
        if (channelInfo && channelInfo.app) {
          // extract channel name under group 1 of applied regex if available else will pass channelInfo.app as it is in event
          const appInfoArray = channelInfo.app.match(/{(.*)\//);
          const channelName =
            (appInfoArray && appInfoArray[1]) || channelInfo.app;
          this.trackShareComplete(channelName);
        }
      })
      .catch();
  }

  trackShareComplete(channelName) {
    this.eventsService.trackEvents(EVENT_NAMES.SHARING_COMPLETE, {
      channel: channelName,
    });
  }

  private setBasket(basket) {
    try {
      const basketApi = basketToBasketApi(basket);
      this.masterDataService.baskets[0] = basketApi;
    } catch (error) {
      console.log(error);
    }
  }
  downloadUserStatement(data) {
    this.accountHistoryService
      .generateUserInvoice(data, { type: "order" })
      .subscribe((response: HTTPResponse) => {
        if (response.status) {
          this.accountHistoryService
            .downloadPdf(data)
            .subscribe((response: any) => {
              if (response.body) {
                if (
                  Utilities.isApp() &&
                  !this.storageService.isStorageAvailable
                ) {
                  this.storageService.checkFileStoragePermission(true);
                } else {
                  this.downloadUserInvoice(response.body, data);
                }
              }
            });
        }
      });
  }
  downloadUserInvoice(data, fileName) {
     this.eventsService.trackEvents(LISTING_EVENT_NAMES.ORDER_INVOICE_DOWNLOADED, EVENT_SOURCES.MY_ORDER);
    const contentFile = new Blob([data], { type: "text/csv" });
    if (Utilities.isApp()) {
      const pathFile = this.platform.is(IOS)
        ? this.file.tempDirectory
        : this.file.externalDataDirectory;
      this.file
        .writeFile(pathFile, `${fileName}_mb.pdf`, contentFile, {
          replace: true,
        })
        .then(
          (fileEntry: FileEntry) => {
            this.fileOpener
              .open(pathFile + `${fileName}_mb.pdf`, "application/pdf")
              .then(() => {})
              .catch((error) => {
                this.loggingService.logMessage("Error opening file : " + error);
              });
           
          },
          (error) => {
            this.loggingService.logMessage("Error saving file : " + error);
          }
        );
    } else {
      const url = window.URL.createObjectURL(contentFile);
      const downloadFile = document.createElement("a");
      downloadFile.href = url;
      downloadFile.download = `${fileName}_mb.pdf`;
      document.body.appendChild(downloadFile);
      downloadFile.click();
      document.body.removeChild(downloadFile);
      window.URL.revokeObjectURL(url);
    }
  }

  refetchBasket() {
    this.masterDataService.getBaskets().then((res) => {
      this.plmService.setupTomorrowBasket(true);
      this.dataService.runChangeDetForA2B.next(true);
      this.masterDataService.getRecurringOrders(true);
    });
  }

  checkIfAppInstalled(packageName, index):Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.appAvailability.check(packageName).then(
          () => {
              resolve(true);
          },
          () => {
              resolve(false);
          }
      ).catch(error => {
          console.error('Error checking app availability:', error);
          reject(error); // Reject the promise if there's an error
      });
  });
  }

  onDismissLowBalanceModal(res, callbackFunction, onDismiss?){
    onDismiss && onDismiss(res);
    
    if( res ) {
      if(this.masterDataService.isJioPayEnabled) {
        this.shoppingCreditsServices.openPaymentOptionsModalJio(res.amount, callbackFunction);
      } else {
        this.shoppingCreditsServices.transactionProcess(
          res,
          callbackFunction
        );
      }
    }
  }

  preFetchHyperSdk(){}
  initiateHyperSdk(){}
  processHyperSdk(){}

  processHyperSdkHandler(processSdkPayload, callback){
    this.dataService.successCbResult = {
      ...this.dataService.successCbResult,
      [processSdkPayload.payload.action]: callback,
      [`${processSdkPayload.payload.action}Pending`]: true
    };
    window.cordova.plugins["HyperSDKPlugin"]?.process(JSON.stringify(processSdkPayload));
  }
  
  exitAppHandler(){
    // @ts-ignore
    navigator.app.exitApp();
  }

  // method to get current location
  getGoogleLocation() {
    return new Promise((resolve) => {
      this.locationService.enableLocation(LocationComponentType.DeliveryLocationPage, true);
      this.signupService.updateCoordinates.subscribe((isLocationUpdated)=>{
        if(isLocationUpdated) {
          resolve(this.signupService.latLong);
        }
      })
    });
  }

  adressChangeMasterDataUpdation(addressObj ) {
    return new Promise((resolve)=>{
    this.masterDataService.masterDataUpdateObs.next(false);
    this.masterDataService.getMasterData(
      true,
      true,
      false,
      true,
      false,
      DATA_UI_EVENT_SOURCE_TYPES.GEOLOCATION_SERVICE_CHANGE_ADDRESS
    );
    let previousHubId:number;
    if(this.masterDataService.masterData?.user){
      this.masterDataService.masterData.user.society = addressObj.societyName;
      this.masterDataService.masterData.user.society_id = addressObj.societyId;
      this.masterDataService.masterData.user.city = addressObj.addressCity;
      this.masterDataService.masterData.user.city_id = addressObj.cityId;
      this.masterDataService.masterData.user.address = addressObj.address;
      this.masterDataService.masterData.user.societyType = addressObj.societyType; 
      this.masterDataService.masterData.user.addressUnit = addressObj.addressUnit;
      this.masterDataService.masterData.user.buildingName = addressObj.buildingName;
      this.masterDataService.masterData.user.streetName = addressObj.streetName;
      this.masterDataService.masterData.user.addressFloor = addressObj.address_floor;
      this.masterDataService.masterData.user.landmark = addressObj.addressFloor;
      previousHubId = this.masterDataService.masterData.user.hubId;
      this.masterDataService.masterData.user.hubId = addressObj.hubId;
    }
    if(this.masterDataService.localAddressStatus){
      localStorage.removeItem(STORAGE_KEYS.ADDRESS_STATUS);
      this.masterDataService.localAddressStatus = null;
    }
    this.masterDataService.masterDataUpdateObs
    .pipe(take(2))
    .subscribe(dataLoaded => {
        if (dataLoaded) {
          if (!addressObj.towerId) {
            // this.dataService.addressUnderVerificationAlert.next(true);
            showAddressUnverifyPopup(true);
            this.masterDataService.getBaskets();
            this.authService.userData.tomorrowBasket = {};
            this.dataService.refreshFutureBaskets.next(true);
          } else{
            this.masterDataService.masterData?.user && (this.masterDataService.masterData.user.is_valid_address = AddressStatus.Valid);
            if(!!previousHubId && !!addressObj.hubId && previousHubId != addressObj.hubId) {
              showEmptyCartPopup(true);
            }
          }
          this.dataService.runChangeDetForAddressChange.next(true); 
          resolve(true);
    } else {
      resolve(true);
    }
  });
  });
  }

  onRegisterMasterDataUpdation(addressObj, registrationResponse ) {
    return new Promise((resolve)=>{
    this.authService.isAuthenticated.next(true);
    this.firebaseService.registerForPushNotification(FIREBASE_REMOTE_CONFIG_CALL.All);
    this.masterDataService.masterDataUpdateObs.next(false);
    this.masterDataService.getMasterData(true, true,false,true, false, DATA_UI_EVENT_SOURCE_TYPES.GEOLOCATION_SERVICE_REGISTRATION);
    this.masterDataService.masterDataUpdateObs
    .pipe(take(2))
    .subscribe(dataLoaded => {
        if (dataLoaded) {
            setTimeout(() => {
                this.firebaseService.registerForPushNotification(FIREBASE_REMOTE_CONFIG_CALL.All);
            }, 3000);
            this.authService.removePromoCodeFromLocalstorage();
            if (registrationResponse &&
              registrationResponse.messageCode &&
              (registrationResponse.messageCode ===
                  SIGNUP_ERRORS.NOT_IN_SERVICE_AREA ||
                  registrationResponse.messageCode ===
                  SIGNUP_ERRORS.NO_LONGER_DELIVER ||
                  registrationResponse.messageCode ===
                  SIGNUP_ERRORS.UPCOMING_DELIVERY) &&
                  registrationResponse.message) {
              this.signupService.societyErrorMessage = registrationResponse.message;
              this.dataService.societyErrorCode = registrationResponse.messageCode;
              if(registrationResponse.messageCode === SIGNUP_ERRORS.UPCOMING_DELIVERY) {
                  // this.dataService.showUpcomingSocietyModal.next(true);
                  showUpcomingDeliveryPopup(true);
              } else {
                this.alertService.presentAlert(registrationResponse.message, [
                  ALERT_BUTTON_TEXT
                ]);
              }
          } else {
              this.signupService.societyErrorMessage = '';
              this.dataService.societyErrorCode = '';
          } 
          if(registrationResponse.messageCode === SIGNUP_ERRORS.NOT_IN_SERVICE_AREA || !addressObj.towerId) {
            // this.dataService.addressUnderVerificationAlert.next(true);
              showAddressUnverifyPopup(true);
              resolve(true);
          }
          this.authService.setAuthSubscriber();
            resolve(true);
          }
        })
    });
  }

  // self help camera handling
  checkCameraPermissionAndTakePhotos(photoSource: number = 1) {
    return new Promise((resolve)=>{
      this.diagnostic.isCameraAuthorized(false).then(async (success) => {
        if (!success) {
            this.diagnostic.requestCameraAuthorization().then(async s => {
                this.loggingService.logMessage('Success for requestCameraAuthorization: ', s);
                const imageData = await this.getPictureForSelfHelp(photoSource);
                resolve(imageData);
            }).catch(async (err) => {
                this.loggingService.logMessage('Error in requestCameraAuthorization: ', err);
                const imageData = await this.getPictureForSelfHelp(photoSource);
                resolve(imageData);
            });
        } else {
            this.loggingService.logMessage('Success for isCameraAuthorized: ', success);
            const imageData = await this.getPictureForSelfHelp(photoSource);
            resolve(imageData);
        }
    }).catch(async (err) => {
        this.loggingService.logMessage('Error in isCameraAuthorized: ', err);
        const imageData = await this.getPictureForSelfHelp(photoSource);
        resolve(imageData);
    });
    })
  }

  // self help pictures taken
  async getPictureForSelfHelp(sourceValue:number) {
    return new Promise(async (resolve)=>{
      try {
        if (!Utilities.isApp()) {
          setTimeout(() => {
            const previousRef = document.getElementById('fileBrowser');
            if (previousRef) {
              previousRef.remove();
            }
            const fileExplorer = document.getElementsByClassName('cordova-camera-select')[0]
            fileExplorer.setAttribute('accept', 'image/*')
            fileExplorer.setAttribute('id', 'fileBrowser')
            const fileElementRef = document.getElementById('fileBrowser')
            fileElementRef.setAttribute('style', 'display:none')
            fileElementRef.click();
          })
        }
        const data = await this.camera.getPicture({
          quality: environment.CAMERACONFIG.quality,
          targetWidth: environment.CAMERACONFIG.targetWidth,
          targetHeight: environment.CAMERACONFIG.targetHeight,
          destinationType: this.camera.DestinationType.DATA_URL,
          correctOrientation: true,
          sourceType: sourceValue
        });
        const imageData = { file: data, fileName: 'image' + new Date().getTime() };
        resolve({ image: imageData });
      } catch (err) {
        this.loggingService.logMessage(err);
        resolve('')
      }
    })
  }

  // self help check for download permission
  checkForDownloadPermission() {
    return new Promise((resolve)=>{
      if (Utilities.isApp()) {
        if (!this.storageService.isStorageAvailable) {
          resolve(false);
        } else {
          resolve(true);
        }
      }
    })
  }

  // self help request for download permission
  requestForDirectoriesPermission() {
    return new Promise((resolve)=>{
      if (!this.storageService.isStorageAvailable) {
        this.storageService.checkFileStoragePermission(true);
        resolve(false);
      } else {
        resolve(true);
      }
    })
  }

  // self help request for download permission
  downloadAccountStatement(data) {
    return new Promise(async (resolve)=>{
      if (Utilities.isApp() && !this.storageService.isStorageAvailable) {
        resolve(false);
        this.storageService.checkFileStoragePermission(true);
      } else {
        const success = await this.downloadStatement(data);
        resolve(success);
      }
    });
  }

  downloadStatement = (data) => {
    return new Promise((resolve)=>{
      var fileName = data.data.name;;
      const contentFile = new Blob([data.data.blob], { type: 'text/csv' });
      if (Utilities.isApp()) {
        const pathFile = this.platform.is(IOS) ? this.file.tempDirectory : this.file.externalDataDirectory;
        this.file.writeFile(pathFile, fileName, contentFile, { replace: true })
          .then(
            (fileEntry: FileEntry) => {
              resolve(true);
              this.fileOpener.open(pathFile + fileName, 'application/pdf')
                .then(() => {
                 })
                .catch(error => {
                  this.loggingService.logMessage('Error opening file : ' + error);
                });
            },
            (error) => {
              this.loggingService.logMessage('Error saving file : ' + error);
              resolve(false);
            });
      } else {
        const url = window.URL.createObjectURL(contentFile);
        const downloadFile = document.createElement('a');
        downloadFile.href = url;
        downloadFile.download = fileName;
        document.body.appendChild(downloadFile);
        downloadFile.click();
        document.body.removeChild(downloadFile);
        window.URL.revokeObjectURL(url);
        resolve(true);
      }
    })
  }

  navigateFromSelfHelp(url:string) {
    this.deeplinkService.navToDeepLinkUrl(url, null, null, true);
  }
}
