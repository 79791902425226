<div class="top-fixed">
  <div class="fixed-bottom-sheet">
    <mbs-bottom-sheet-header [backType]="backAction" (closeBottomSheet)="back(false)">
      City Name
    </mbs-bottom-sheet-header>
    <mbf-search (searchText)="searchCity($event)" [autoFocus]='false' [(searchData)]="searchData" (searchDataChange)="searchData=$event"></mbf-search>
  </div>
</div>

<ion-content class="padding-horizontal-medium ">
  <ion-list *ngIf="filteredCityList.length" class="society-list padding-top-medium" lines="none">
    <ng-container *ngFor="let city of filteredCityList">
      <ion-item (click)="selectCity(city)" class="ion-no-padding">
        <ion-label class="ion-no-margin f-w-s-b" slot="start">
          {{city.name}}
        </ion-label>
      </ion-item>
    </ng-container>
  </ion-list>
</ion-content>