import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CalendarModule } from 'ion2-calendar';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CleverTap } from '@ionic-native/clevertap/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Market } from '@ionic-native/market/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import * as StackTrace from 'stacktrace-js';
import { Utilities } from './shared/classes/utility/utilities';
import { noop } from 'rxjs';
import "firebase/remote-config";
import "firebase/analytics";
import { LoginService } from './registration/providers/login/login.service';
import { SignupService } from './registration/providers/signup/signup.service';
import { IonBottomSheetModule } from 'ion-bottom-sheet';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { LottieModule } from 'ngx-lottie';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import player from 'lottie-web';
import { Device } from '@ionic-native/device/ngx';
import { ScrollingModule } from '@angular/cdk/scrolling';
import SwiperCore from "src/lib/overrides/swiper/swiper";

import { SwiperModule, SwiperComponent } from 'swiper/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LayoutService } from "@MbUi/layouts";

import { IonicSwiper } from '@ionic/angular';
import { Autoplay, Pagination } from 'swiper';
SwiperCore.use([Autoplay, Pagination, IonicSwiper]);
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecurringService } from './listing/providers/recurring/recurring.service';
import { NgxLoadingModule } from "ngx-loading";
import { PaymentRoutingModule } from '@Pages/payment/payment-routing.module';
import { SubscriptionRoutingModule } from '@Pages/subscription/subscription-routing.module';
import { CollectionRoutingModule } from '@Pages/collection/collection-routing.module';
import { ProfileRoutingModule } from '@Pages/profile/profile-routing.module';
import { RegistrationRoutingModule } from '@Pages/registration/registration-routing.module';

/* Do not remove */
import { CommonImports } from './common.imports';
import { SplashModule } from '@Pages/splash/splash.module';
import { BottomNavModule } from '@MbUi/bottom-navs/one/bottom-nav.module';
import { HttpClientModule } from '@angular/common/http';
import { ProductVariantListModule } from '@Shared/product-variant-list/product-variant-list.module';
import { WelcomeMessageModule } from '@Pages/home/components/welcome-message/welcome-message.module';
import { ReferralSuccessModule } from '@Pages/home/components/referral-success/referral-success.module';
import { RateAppModule } from '@Pages/home/components/rate-app/rate-app.module';
import { SelectCityModule } from '@Features/registration/select-city/select-city.module';
import { WalletIncreaseModule } from '@Features/payment/wallet-increase/wallet-increase.module';
import { AddressUnverifiedModule } from '@Shared/address-unverified/address-unverified.module';
import { UpcomingDeliveryInfoModule } from '@Shared/upcoming-delivery-info/upcoming-delivery-info.module';
import { MembershipTermsAndConditionModule } from '@Pages/membership/components/membership-terms-and-condition/membership-terms-and-condition.module';
import { DecimalPipe } from '@angular/common';
import { DateFormatPipe } from 'ngx-moment';
/* Do not remove */


export function playerFactory() {
    return player;
}

@Injectable()
export class FirebaseIonicErrorHandler extends ErrorHandler {
    constructor(private firebaseX: FirebaseX) {
        super();
    }

    handleError(error) {
        super.handleError(error);
        try {
            if (Utilities.isApp()) {
                StackTrace.fromError(error.originalError || error).then((stackFrames) => {
                    this.firebaseX.logError(error.toString(), stackFrames).catch(noop);
                }).catch(() => {
                    this.firebaseX.logError(error.toString()).catch(noop);
                });
            }
        } catch (e) {
            noop();
        }
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule, 
        IonicModule.forRoot({ mode: 'md', rippleEffect: false }),
        CoreModule,
        CalendarModule,
        AppRoutingModule,
        PaymentRoutingModule,
        SubscriptionRoutingModule,
        CollectionRoutingModule,
        ProfileRoutingModule,
        RegistrationRoutingModule,
        IonBottomSheetModule,
        LottieModule.forRoot({ player: playerFactory }),
        ScrollingModule,
        SwiperModule,
        SplashModule,
        BottomNavModule,
        ProductVariantListModule,
        WelcomeMessageModule,
        ReferralSuccessModule,
        UpcomingDeliveryInfoModule,
        RateAppModule,
        AddressUnverifiedModule,
        SelectCityModule,
        WalletIncreaseModule,
        MembershipTermsAndConditionModule,
        NgxLoadingModule.forRoot({
                backdropBackgroundColour: "rgba(0,0,0,0.1)",
                primaryColour: "#1DA1F2",
                secondaryColour: "#1DA1F2",
                tertiaryColour: "#1DA1F2",
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: true,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
          //registrationStrategy: 'registerImmediately'
        }),
        HttpClientModule
    ],
    providers: [
        NetworkInterface,
        StatusBar,
        SplashScreen,
        FirebaseDynamicLinks,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: FirebaseIonicErrorHandler },
        HTTP,
        AppAvailability,
        Appsflyer,
        SocialSharing,
        InAppBrowser,
        FirebaseX,
        CleverTap,
        Clipboard,
        Network,
        Deploy,
        Market,
        Diagnostic,
        Camera,
        File,
        FileTransfer,
        FileOpener,
        Geolocation,
        SmsRetriever,
        LoginService,
        SignupService,
        LocationAccuracy,
        Device,
        LayoutService,
        RecurringService,
        DecimalPipe,
        DateFormatPipe,
    ],
    bootstrap: [AppComponent],
    exports: [SwiperComponent]
})
export class AppModule {
    /* Do not remove */
    noopMethod() {
        console.log(CommonImports);
    }
}
