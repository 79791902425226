import { Component, SimpleChanges } from '@angular/core';
import { MBAfterContentChecked, MBAfterContentInit, MBAfterViewChecked, MBAfterViewInit, MBDoCheck, MBOnChanges, MBOnDestroy, MBOnInit } from './interfaces/hooks.interface';

@Component({
  selector: 'default',
  template: '',
  styleUrls: [],
})
export class DefaultPage implements MBOnInit, MBOnChanges, MBDoCheck, MBAfterContentInit, MBAfterContentChecked, MBAfterViewInit, MBAfterViewChecked, MBOnDestroy {
  constructor() { }

  private ngOnInit(): void {
    return this._mbOnInit();
  }

  private ngOnChanges(changes: SimpleChanges): void {
    return this._mbOnChanges(changes);
  }

  private ngDoCheck(): void {
    return this._mbDoCheck();
  }

  private ngAfterContentInit(): void {
    return this._mbAfterContentInit();
  }

  private ngAfterContentChecked(): void {
    return this._mbAfterContentChecked();
  }

  private ngAfterViewInit(): void {
    return this._mbAfterViewInit();
  }

  private ngAfterViewChecked(): void {
    return this._mbAfterViewChecked();
  }

  private ngOnDestroy(): void {
    return this._mbOnDestroy();
  }

  _mbOnInit(): void {
    return this.mbOnInit();
  }

  _mbOnChanges(changes: SimpleChanges): void {
    return this.mbOnChanges(changes);
  }

  _mbDoCheck(): void {
    return this.mbDoCheck();
  }

  _mbAfterContentInit(): void {
    return this.mbAfterContentInit();
  }

  _mbAfterContentChecked(): void {
    return this.mbAfterContentChecked();
  }

  _mbAfterViewInit(): void {
    return this.mbAfterViewInit();
  }

  _mbAfterViewChecked(): void {
    return this.mbAfterViewChecked();
  }

  _mbOnDestroy(): void {
    return this.mbOnDestroy();
  }

  mbOnInit(): void { }

  mbOnChanges(changes: SimpleChanges): void { }

  mbDoCheck(): void { }

  mbAfterContentInit(): void { }

  mbAfterContentChecked(): void { }

  mbAfterViewInit(): void { }

  mbAfterViewChecked(): void { }

  mbOnDestroy(): void { }
}