import { CommunicationFlag } from './communication-flags';
import { UserAddress } from './user-address';
import { UserFlag } from './user-flag';

export class UserDetail {
    __typename = 'UserDetail';
    name: string;
    phone: string;
    id:string;
    email: string;
    paid: boolean;
    walletFreeze: boolean;
    address: UserAddress;
    firstOrderDate: string; // was first_order_date
    deliveryStartDate: string; // was delivery_start_date
    flags: UserFlag;
    accountStatus: number;
    communicationFlags: CommunicationFlag;

    setUserDetailFromApi(userApi) {
        try {
            
            this.name = userApi?.name;
            this.phone = `+91 - ${userApi?.phone as string}`;
            this.email = userApi?.email;
            this.id = userApi.id+'';
            this.walletFreeze = !!userApi?.wf;
            this.paid = !!userApi?.paid;
            this.firstOrderDate = userApi?.first_order_date || '';
            this.deliveryStartDate = userApi?.delivery_start_date || '';
            this.accountStatus = userApi?.closed;

            const address = new UserAddress();
            this.address = address.setAddressFromApi(userApi);

            const flags = new UserFlag();
            this.flags = flags.setUserFlagsFromApi(userApi);

            const communicationFlags = new CommunicationFlag();
            this.communicationFlags = communicationFlags.setCommunicationFlagFromApi(userApi);
            return this;
        } catch {
            return null;
        }
    }
}
