<ion-list class="ion-no-padding payment-offers-card"
[ngClass]="{'only-one-offer': combinedOffers.length === 1}"
 lines="none" *ngIf="combinedOffers.length">
    <ion-list-header *ngIf="showLabel" class="sub-heading f-w-b">Offers ({{combinedOffers.length}})</ion-list-header>
    <ion-item class="ion-no-padding" mode="md">
        <div class="scroll" scroll-x="true">
            <ion-card class="ion-card-image margin-bottom-small ion-no-margin" *ngFor="let paymentOffer of combinedOffers | slice:0:3; let i=index">
                <ion-item color="none" class="ion-no-padding">
                    <ion-icon class="active-offer icon-common-sm" slot="start"></ion-icon>
                    <ion-label> {{paymentOffer.offer}}</ion-label>
                    <ion-icon *ngIf="paymentOffer.offerDescription || paymentOffer.offer_description" class="info-icon margin-0 icon-common-medium" (click)="modalService.openTermConditionPopup(i+1, paymentOffer, source)" slot="end"></ion-icon>
                </ion-item>
            </ion-card>
            <ion-button *ngIf="combinedOffers.length > 3" (click)="openDescription()" fill="clear" slot="end" class="ion-no-padding view-all no-ripple-effect">
                View All
            </ion-button>
        </div>
    </ion-item>
</ion-list>