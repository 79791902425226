import { Injectable } from "@angular/core";
import { AuthService } from "@Globals/providers/auth.service";
import { HTTPResponse } from "@ionic-native/http";
import {
  ADDRESS_VALIDATION_TYPE,
  EMPTYCART_ALERT,
  EVENT_NAMES,
  PROMOCODE_TYPES,
  SIGNUP_ERRORS,
} from "@Globals/constants/registration/constants";
import { Subscription, noop } from "rxjs";
import {
  AddressUpdate,
  SignupComplete,
  UserSignupDetails,
} from "@Globals/interfaces/registration/interfaces";
import {
  ALERT_BUTTON_TEXT,
  GENERATE_CONTENT_FOR_ALERT_LAYER,
  STORAGE_KEYS,
  USER_DETAIL_DEFAULT_VALUES,
} from "@Globals/constants/listing/constants";
import {
  AddressSelectionType,
  SocietyType,
} from "@Globals/constants/registration/enums";
import { take } from "rxjs/operators";
import { REGISTRATION_ROUTES } from "@Globals/constants/registration/routes";
import { LISTING_ROUTES, CORE_ROUTES } from "@Globals/constants/listing/routes";
import { SignupService } from "@Globals/providers/signup.service";
import { EventsService } from "@Globals/providers/events.service";
import { DataService } from "@Globals/providers/data.service";
import { MasterDataService } from "@Globals/providers/master-data.service";
import { AlertService } from "@Globals/providers/alert.service";
import { FirebaseService } from "@Globals/providers/firebase.service";
import { NavController } from "@ionic/angular";
import { AddressStatus } from "@Globals/constants/registration/constants";
declare var window: any;
import { DeepLinkService } from "@Globals/providers/deep-link.service";
import { Device } from "@ionic-native/device/ngx";
import { environment } from "../../../environments/environment";
import { Utilities } from "@Globals/classes/utilities";
import {
  DATA_UI_EVENT_SOURCE_TYPES,
  INVALID_ADDRESS,
} from "@App/registration/constants/registration-constants";
import { LOGOUT_MODE } from "@App/shared/constants/constants";
import { HttpService } from "@Globals/providers/http.service";
import {
  FIREBASE_REMOTE_CONFIG_CALL,
  UPCOMING_DELIVERY_INFO,
} from "@App/core/constants/core-constants";
import { LoginService } from "@App/registration/providers/login/login.service";
import { ADDRESS_CHANGE_REQUEST } from "@App/listing/constants/listing-constants";
import { ModalService } from "@App/shared/providers/modal/modal.service";
import { UpcomingDeliveryInfoComponent } from "@Shared/upcoming-delivery-info/upcoming-delivery-info.component";
import { showEmptyCartPopup } from '@mb-lib/apollo-client';
import { showUpcomingDeliveryPopup } from '@mb-lib/apollo-client';
import { showAddressUnverifyPopup } from '@mb-lib/apollo-client';

  @Injectable()
export class GeolocationService {
  promo_code!: string;
  validPromoCode: boolean;
  promoCodeType: string;
  referStatus: number = null;
  subscriptions: Subscription[] = [];
  cityInfo: any = {};
  userSignupDetails: UserSignupDetails = {
    towerDetails: {},
    localAddressDetails: {},
  };
  isAddNewAddress: boolean = false;
  societyType = SocietyType;
  addressSelectionType = AddressSelectionType;
  constructor(
    private navCtrl: NavController,
    public signupService: SignupService,
    private loginService: LoginService,
    public authService: AuthService,
    private eventsService: EventsService,
    public dataService: DataService,
    private masterDataService: MasterDataService,
    private firebaseService: FirebaseService,
    private alertService: AlertService,
    private deeplinkService: DeepLinkService,
    private httpService: HttpService,
    private device: Device,
    public modalService: ModalService
  ) {}

  registerUser(addressData = null, isValidationRequired = true) {
    this.promo_code =
      this.authService.inviteCode ||
      this.authService.promoCode ||
      this.authService.getPromoCodeFromLocalstorage();
    if (
      this.promo_code &&
      !addressData &&
      this.authService.userData.societyObj.active
    ) {
      const verifyWelcomeCodeSubscription = this.signupService
        .verifyWelcomeCode(
          this.authService.inviteCode || this.authService.promoCode
        )
        .subscribe((verifyResponse: HTTPResponse) => {
          if (verifyResponse?.data?.status) {
            this.validPromoCode = true;
            this.promoCodeType = verifyResponse.data.code_type;
            this.referStatus = verifyResponse.data.referStatus;
            this.eventsService.trackEvents(EVENT_NAMES.REFERRER_STATUS, {
              status: verifyResponse.data.referStatus,
            });
            this.eventsService.trackEvents(EVENT_NAMES.SIGNUP_CODE_APPLIED, {
              type: this.promoCodeType,
              isAutoApplied: "1",
            });
            this.verifyAddress(
              addressData,
              ADDRESS_VALIDATION_TYPE.REGISTER,
              false,
              isValidationRequired
            );
          } else {
            this.verifyAddress(
              addressData,
              ADDRESS_VALIDATION_TYPE.REGISTER,
              false,
              isValidationRequired
            );
          }
        });
      this.subscriptions.push(verifyWelcomeCodeSubscription);
    } else {
      this.verifyAddress(
        addressData,
        ADDRESS_VALIDATION_TYPE.REGISTER,
        false,
        isValidationRequired
      );
    }
  }

  verifyAddress(
    addressData,
    validationType,
    showAlert = false,
    isValidationRequired
  ) {
    let customer_locations = [];
    this.signupService.latLong &&
      customer_locations.push(this.signupService.anfDetails.x);
    this.signupService.anfDetails.z &&
      customer_locations.push(this.signupService.anfDetails.z);
    if (isValidationRequired) {
      let dataToSend;
      if (addressData) {
        dataToSend = {
          address: `${addressData.unit}, ${addressData.tower},${addressData.society_name},${this.cityInfo.name}`,
          city: this.cityInfo.name,
          society: addressData.society_name,
          tower: addressData.tower,
          flatNo: addressData.unit,
        };
      } else {
        dataToSend = {
          address: `${this.userSignupDetails.houseNo}, ${this.userSignupDetails.towerDetails.name},${this.authService.userData.societyObj.name},${this.authService.userData.cityObj.name}`,
          city: this.authService.userData.cityObj.name,
          society: this.authService.userData.societyObj.name,
          tower: this.userSignupDetails.towerDetails.name,
          flatNo: this.userSignupDetails.houseNo,
          societyLatitude: this.authService.userData.societyObj.latitude,
          societyLongitude: this.authService.userData.societyObj.longitude,
        };
      }
      const validateAddressSubscription = this.signupService
        .validateAddress(dataToSend)
        .subscribe((res: any) => {
          if (res?.status && res?.data) {
            this.signupService.anfDetails.y.lat = res.data.latitude;
            this.signupService.anfDetails.y.lng = res.data.longitude;
            customer_locations.push(this.signupService.anfDetails.y);
            this.callBaskedOnType(
              validationType,
              customer_locations,
              addressData,
              showAlert
            );
          } else {
            customer_locations.push(this.signupService.anfDetails.y);
            this.callBaskedOnType(
              validationType,
              customer_locations,
              addressData,
              showAlert
            );
          }
        });
      this.subscriptions.push(validateAddressSubscription);
    } else {
      customer_locations.push(this.signupService.anfDetails.y);
      this.callBaskedOnType(
        validationType,
        customer_locations,
        addressData,
        showAlert
      );
    }
  }

  callBaskedOnType(
    validationType,
    customer_locations,
    addressData,
    showAlert = false
  ) {
    if (validationType === ADDRESS_VALIDATION_TYPE.REGISTER) {
      this.submit(customer_locations, addressData);
    } else {
      this.triggerChangeAddress(customer_locations, showAlert);
    }
  }

  submit(customer_locations, addressData?) {
    window.latLng &&
      window.latLng.Adid &&
      (this.dataService.uuid = window.latLng.Adid);
    let dateToBeSent = {
      city: addressData
        ? this.cityInfo.name
        : this.authService.userData.cityObj.name,
      city_id: addressData
        ? this.cityInfo.city_id
        : this.authService.userData.cityObj.id,
      email: this.authService.userData.mobileNumber + "@milkbasket.com",
      firstname: USER_DETAIL_DEFAULT_VALUES.FIRSTNAME,
      invite_code: "",
      lastname: USER_DETAIL_DEFAULT_VALUES.LASTNAME,
      lat:
        (this.signupService.latLong && this.signupService.latLong.latitude) ||
        0,
      lng:
        (this.signupService.latLong && this.signupService.latLong.longitude) ||
        0,
      mobile: this.authService.userData.mobileNumber,
      referrer_code: "",
      society_id: addressData
        ? addressData.society_id
        : this.authService.userData.societyObj.id,
      society_name: addressData
        ? addressData.society_name
        : this.authService.userData.societyObj.name,
      unit: addressData
        ? addressData.unit
        : this.userSignupDetails.houseNo || "",
      welcome_code: this.validPromoCode ? this.promo_code : "",
      customer_locations: customer_locations,
      udid: this.dataService.uuid,
      device_os: this.dataService.currentPlatform.substring(0, 3).toUpperCase(),
      os_version: this.device.version,
      device_brand_name: this.device.manufacturer,
      device_model_name: this.device.model,
      is_virtual: this.device.isVirtual ? 1 : 0,
      mb_version: {
        app_store: environment.appStoreVersion,
        code_push: environment.codePushVersion,
      },
    };
    if (
      !this.isAddNewAddress &&
      this.authService.userData.societyObj.societyType ===
        this.societyType.Locality
    ) {
      dateToBeSent["society_type"] = addressData
        ? null
        : this.authService.userData.societyObj.societyType;
      dateToBeSent["address_floor"] =
        this.userSignupDetails.localAddressDetails.address_floor || null;
      dateToBeSent["building_name"] =
        this.userSignupDetails.localAddressDetails.buildingName;
      dateToBeSent["street_name"] =
        this.userSignupDetails.localAddressDetails.streetName;
      dateToBeSent["landmark"] =
        this.userSignupDetails.localAddressDetails.landmark || null;
    } else {
      if (
        "towerDetails" in this.userSignupDetails &&
        "tower_id" in this.userSignupDetails.towerDetails &&
        this.userSignupDetails.towerDetails.tower_id === null
      ) {
        dateToBeSent["tower"] = this.userSignupDetails.towerDetails.name;
        dateToBeSent["tower_id"] = this.userSignupDetails.towerDetails.tower_id;
        dateToBeSent["isTowerRequest"] = 1;
      } else {
        dateToBeSent["tower"] = addressData
          ? addressData.tower
          : this.userSignupDetails.towerDetails.name;
        dateToBeSent["tower_id"] = addressData
          ? addressData.tower_id
          : this.userSignupDetails.towerDetails.tower_id || 0;
      }
    }
    if (typeof this.referStatus === "number") {
      dateToBeSent["referralStatus"] = this.referStatus;
    }
    if (this.dataService.isUnserviceableFlow) {
      dateToBeSent["unit"] = addressData.unit ? addressData.unit : "";
      dateToBeSent["tower"] = addressData.tower ? addressData.tower : "";
      dateToBeSent["society_name"] = addressData.society_name
        ? addressData.society_name
        : "";
      dateToBeSent["city"] = addressData.city ? addressData.city : "";
      dateToBeSent["address"] = addressData.address ? addressData.address : "";
      dateToBeSent["lat"] = addressData.lat ? addressData.lat : "";
      dateToBeSent["lng"] = addressData.lng ? addressData.lng : "";
      dateToBeSent["address_floor"] = addressData.address_floor
        ? addressData.address_floor
        : "";
      dateToBeSent["society_id"] = addressData.society_id
        ? addressData.society_id
        : 0;
      dateToBeSent["tower_id"] = addressData.tower_id
        ? addressData.tower_id
        : 0;
      dateToBeSent["isAddressRequest"] = addressData.isAddressRequest
        ? addressData.isAddressRequest
        : 1;
      dateToBeSent["addressCity"] = addressData.addressCity
        ? addressData.addressCity
        : "";
      dateToBeSent["addressUnit"] = addressData.addressUnit
        ? addressData.addressUnit
        : "";
      dateToBeSent["society_type"] = addressData.society_type
        ? addressData.society_type
        : 1;
      dateToBeSent["addressTower"] = addressData.addressTower
        ? addressData.addressTower
        : "";
    }
    const registerUserSubscription = this.signupService
      .registerUser(dateToBeSent)
      .subscribe((response: any) => {
        if (response?.data?.status) {
          this.authService.isLogout.next(LOGOUT_MODE.NO_LOGOUT);
          const trackArgs: SignupComplete = {
            "User Id": response.data.user_id,
            referrerCode:
              this.promoCodeType === PROMOCODE_TYPES.REFERRAL
                ? this.promo_code
                : "",
            isCodeAutoApplied: "1",
            city_name: this.authService.userData.cityObj.name,
            city_id: this.authService.userData.cityObj.id,
            society_id: addressData
              ? addressData.society_id
              : this.authService.userData.societyObj.id,
            society_name: addressData
              ? addressData.society_name
              : this.authService.userData.societyObj.name,
            society_active: addressData
              ? 0
              : this.authService.userData.societyObj.active,
            isAddressValid: addressData
              ? false
              : !!(
                  this.authService.userData.societyObj.id &&
                  this.userSignupDetails.towerDetails.tower_id
                ),
            os_version: this.device.version,
            device_brand_name: this.device.manufacturer,
            device_model_name: this.device.model,
          };
          if (this.promoCodeType === PROMOCODE_TYPES.INVITE) {
            trackArgs.inviteCode = this.promo_code;
          }
          this.authService.setAuthKey(response.data.auth_key);
          this.authService.setRefreshKey(
            response?.data?.refresh_key,
            Utilities.addMinutes(
              new Date(),
              response?.data?.authExpiry -
                this.httpService.retryConfig.timeBeforeTokenCall
            )
          );
          this.authService.isAuthenticated.next(true);
          this.firebaseService.registerForPushNotification(
            FIREBASE_REMOTE_CONFIG_CALL.All
          );
          this.masterDataService.masterDataUpdateObs.next(false);
          this.dataService.draggenedCoordinate = null;
          const masterDataUpdateObsSubscription =
            this.masterDataService.masterDataUpdateObs
              .pipe(take(2))
              .subscribe((dataLoaded) => {
                if (dataLoaded) {
                  setTimeout(() => {
                    this.firebaseService.registerForPushNotification(
                      FIREBASE_REMOTE_CONFIG_CALL.All
                    );
                  }, 3000);
                  // remove used promocode from local storage
                  this.authService.removePromoCodeFromLocalstorage();
                  if (
                    response.data &&
                    response.data.messageCode &&
                    (response.data.messageCode ===
                      SIGNUP_ERRORS.NOT_IN_SERVICE_AREA ||
                      response.data.messageCode ===
                        SIGNUP_ERRORS.NO_LONGER_DELIVER ||
                      response.data.messageCode ===
                        SIGNUP_ERRORS.UPCOMING_DELIVERY) &&
                    response.data.msg
                  ) {
                    this.signupService.societyErrorMessage = response.data.msg;
                    this.dataService.societyErrorCode =
                      response.data.messageCode;
                    if (
                      response.data.messageCode ===
                      SIGNUP_ERRORS.UPCOMING_DELIVERY
                    ) {
                      // this.dataService.showUpcomingSocietyModal.next(true);
                      showUpcomingDeliveryPopup(true);
                    }
                  } else {
                    this.signupService.societyErrorMessage = "";
                    this.dataService.societyErrorCode = "";
                  }
                  if (
                    this.dataService.isUnserviceableFlow &&
                    SIGNUP_ERRORS.NOT_IN_SERVICE_AREA
                  ) {
                    this.dataService.isUnserviceableFlow = false;
                    this.showAddressChangeRequestRecieved();
                    this.navCtrl.navigateRoot("/" + LISTING_ROUTES.HOME);
                  } else if (addressData) {
                    this.navCtrl.navigateForward(
                      REGISTRATION_ROUTES.BASE +
                        REGISTRATION_ROUTES.GEOLOCATION +
                        "/" +
                        AddressSelectionType.AddressAddSuccess
                    );
                  } else {
                    if (this.signupService.societyErrorMessage) {
                      if (
                        this.dataService.societyErrorCode !==
                        SIGNUP_ERRORS.UPCOMING_DELIVERY
                      ) {
                        this.alertService.presentAlert(response.data.msg, [
                          ALERT_BUTTON_TEXT,
                        ]);
                      }
                      if (
                        this.deeplinkService.navigateProductIdDetails &&
                        this.deeplinkService.navigateProductIdDetails.id
                      ) {
                        this.dataService.selectedPDPProduct.next({
                          productId:
                            this.deeplinkService.navigateProductIdDetails.id,
                          productIndex: 0,
                          source: undefined,
                          eventData: undefined,
                          itemType: undefined,
                          product: undefined,
                          appliedFilter: undefined,
                          basketIndex: undefined,
                        });
                        this.navCtrl.navigateRoot(
                          "/" +
                            CORE_ROUTES.LISTING +
                            "/" +
                            LISTING_ROUTES.PRODUCT_DETAIL +
                            `/:${this.deeplinkService.navigateProductIdDetails.id}`
                        );
                      } else {
                        this.navCtrl.navigateRoot("/" + LISTING_ROUTES.HOME);
                      }
                    } else {
                      this.navCtrl.navigateForward(
                        LISTING_ROUTES.BASE + LISTING_ROUTES.SUCCESS + "/1"
                      );
                    }
                  }
                  if (
                    "tower_id" in dateToBeSent &&
                    dateToBeSent["tower_id"] === null
                  ) {
                    showAddressUnverifyPopup(true);

                    /* setTimeout(() => {
                        this.dataService.addressUnderVerificationAlert.next(true);
                    }, 2000); */
                  }
                  setTimeout(() => {
                    this.eventsService.trackEvents(
                      EVENT_NAMES.SIGNUP_COMPLETE,
                      trackArgs
                    );
                  }, 5000);
                }
                this.authService.setAuthSubscriber();
              });
          this.subscriptions.push(masterDataUpdateObsSubscription);
          this.masterDataService.getMasterData(
            true,
            true,
            false,
            true,
            false,
            DATA_UI_EVENT_SOURCE_TYPES.GEOLOCATION_SERVICE_REGISTRATION
          );
        } else if (
          response?.data.error === SIGNUP_ERRORS.DUPLICATE_ADDRESS ||
          response?.data.error === SIGNUP_ERRORS.DUPLICATE_UDID
        ) {
          this.loginService.showFraudAlert(response);
        } else {
          this.alertService.presentAlert(response.data.error_msg, [
            ALERT_BUTTON_TEXT,
          ]);
        }
      });
    this.subscriptions.push(registerUserSubscription);
  }

  showAddressChangeRequestRecieved() {
    ADDRESS_CHANGE_REQUEST.BUTTONS.CLOSE.handler = noop();
    this.alertService.presentAlertConfirm(
      GENERATE_CONTENT_FOR_ALERT_LAYER(ADDRESS_CHANGE_REQUEST.META_DATA),
      [
        ADDRESS_CHANGE_REQUEST.BUTTONS.CLOSE,
        ADDRESS_CHANGE_REQUEST.BUTTONS.CONFIRM,
      ],
      ADDRESS_CHANGE_REQUEST.CSS_CLASS
    );
  }

  triggerChangeAddress(customer_locations, showAlert = false, addressObj?) {
    if (!addressObj) {
      let addressInfo: AddressUpdate = {
        addressCity: this.authService.userData.cityObj.name,
        addressUnit: this.userSignupDetails.houseNo,
        cityId: this.authService.userData.cityObj.id,
        societyId: this.authService.userData.societyObj.id,
        customer_locations: customer_locations,
        society_type: this.authService.userData.societyObj.societyType,
        udid: this.dataService.uuid,
      };
      if (
        this.authService.userData.societyObj.societyType ===
        this.societyType.Locality
      ) {
        addressInfo["address_floor"] =
          this.userSignupDetails.localAddressDetails.address_floor || null;
        addressInfo["building_name"] =
          this.userSignupDetails.localAddressDetails.buildingName;
        addressInfo["street_name"] =
          this.userSignupDetails.localAddressDetails.streetName;
        addressInfo["landmark"] =
          this.userSignupDetails.localAddressDetails.landmark || null;
      } else {
        if (this.userSignupDetails.towerDetails.tower_id === null) {
          addressInfo["isTowerRequest"] = 1;
        }
        addressInfo.addressTower = this.userSignupDetails.towerDetails.name;
        addressInfo.towerId = this.userSignupDetails.towerDetails.tower_id;
      }
      addressObj = addressInfo;
    }
    const updateAddressSubscription = this.signupService
      .updateAddress(addressObj)
      .subscribe((response: any) => {
        if (response?.data?.status) {
          const eventArgs = addressObj;
          eventArgs.customer_locations = JSON.stringify(
            addressObj.customer_locations
          );
          this.eventsService.trackEvents(
            EVENT_NAMES.ADDRESS_UPDATED,
            eventArgs
          );
          if (
            this.masterDataService.masterData &&
            this.masterDataService.masterData.user
          ) {
            this.masterDataService.masterData.user.society =
              this.authService.userData.societyObj.name;
            this.masterDataService.masterData.user.society_id =
              this.authService.userData.societyObj.id;
            this.masterDataService.masterData.user.city =
              this.authService.userData.cityObj.name;
            this.masterDataService.masterData.user.city_id =
              this.authService.userData.cityObj.id;
            this.masterDataService.masterData.user.address =
              this.authService.userData.societyObj.societyType ===
              this.societyType.Locality
                ? this.userSignupDetails.houseNo +
                  "-" +
                  this.userSignupDetails.localAddressDetails.address_floor
                : this.userSignupDetails.towerDetails.name +
                  " - " +
                  this.userSignupDetails.houseNo;
            this.masterDataService.masterData.user.societyType =
              this.authService.userData.societyObj.societyType;
            if (
              this.authService.userData.societyObj.societyType ===
              this.societyType.Locality
            ) {
              this.masterDataService.masterData.user.addressUnit =
                this.userSignupDetails.houseNo;
              this.masterDataService.masterData.user.buildingName =
                this.userSignupDetails.localAddressDetails?.buildingName;
              this.masterDataService.masterData.user.streetName =
                this.userSignupDetails.localAddressDetails?.streetName;
              this.masterDataService.masterData.user.addressFloor =
                this.userSignupDetails.localAddressDetails?.address_floor;
              this.masterDataService.masterData.user.landmark =
                this.userSignupDetails.localAddressDetails?.landmark;
            }
          }
          if (this.masterDataService.localAddressStatus) {
            localStorage.removeItem(STORAGE_KEYS.ADDRESS_STATUS);
            this.masterDataService.localAddressStatus = null;
          }
          if (showAlert) {
            if (
              localStorage.getItem(
                STORAGE_KEYS.MASTER_DATA_BANNER_CACHE_EXPIRATION
              )
            ) {
              localStorage.removeItem(
                STORAGE_KEYS.MASTER_DATA_BANNER_CACHE_EXPIRATION
              );
              this.masterDataService.isCacheExpiredOrNot.banner = true;
              this.masterDataService.banners = null;
            }
            this.masterDataService.masterDataUpdateObs.next(false);
            this.dataService.draggenedCoordinate = null;
            const masterDataUpdateObsSubscription =
              this.masterDataService.masterDataUpdateObs
                .pipe(take(2))
                .subscribe((dataLoaded) => {
                  if (dataLoaded) {
                    this.dataService.runChangeDetForAddressChange.next(true);
                    if (
                      this.dataService.isUnserviceableFlow &&
                      SIGNUP_ERRORS.NOT_IN_SERVICE_AREA
                    ) {
                      this.dataService.isUnserviceableFlow = false;
                      this.showAddressChangeRequestRecieved();
                      this.navCtrl.navigateRoot("/" + LISTING_ROUTES.HOME);
                    } else {
                      this.navCtrl.navigateRoot("/" + LISTING_ROUTES.HOME);
                      if (addressObj?.towerId === null) {
                        // this.dataService.addressUnderVerificationAlert.next(true);
                        showAddressUnverifyPopup(true);
                        this.masterDataService.getBaskets();
                        this.authService.userData.tomorrowBasket = {};
                        this.dataService.refreshFutureBaskets.next(true);
                      } else {
                        // this.dataService.addressUpdateAlert.next(true);
                        showEmptyCartPopup(true);
                      }
                    }
                  }
                });
            this.subscriptions.push(masterDataUpdateObsSubscription);
            this.masterDataService.getMasterData(
              true,
              true,
              false,
              true,
              false,
              DATA_UI_EVENT_SOURCE_TYPES.GEOLOCATION_SERVICE_CHANGE_ADDRESS
            );
          } else {
            this.navCtrl.navigateRoot("/" + LISTING_ROUTES.HOME);
            if (addressObj?.towerId === null) {
              // this.dataService.addressUnderVerificationAlert.next(true);
              showAddressUnverifyPopup(true);
              this.masterDataService.getBaskets();
              this.authService.userData.tomorrowBasket = {};
              this.dataService.refreshFutureBaskets.next(true);
            } else {
              this.masterDataService.masterData?.user &&
                (this.masterDataService.masterData.user.is_valid_address =
                  AddressStatus.Valid);
            }
            this.dataService.runChangeDetForAddressChange.next(true);
            // this.dataService.getHomeSchema.next(true);
          }
        } else if (
          response?.data.error === SIGNUP_ERRORS.DUPLICATE_ADDRESS ||
          response?.data.error === SIGNUP_ERRORS.DUPLICATE_UDID
        ) {
          this.loginService.showFraudAlert(response);
        }
      });
    this.subscriptions.push(updateAddressSubscription);
  }

  changeAddress(towerData: UserSignupDetails) {
    this.userSignupDetails = towerData;
    const isVerifyAddress =
      this.authService.userData.societyObj.societyType ===
      this.societyType.Locality
        ? false
        : true;
    let isMasterCall = true;
    let Alert = EMPTYCART_ALERT.META_DATA;
    if (
      this.authService.userData.societyObj?.deliveryStartDate === null ||
      (this.authService.userData.societyObj?.deliveryStartDate &&
        new Date(this.authService.userData.societyObj?.deliveryStartDate) >
          new Date())
    ) {
      Alert = INVALID_ADDRESS.META_DATA;
      isMasterCall = false;
    }
    if (
      this.authService.userData.societyObj?.hubId !==
        this.masterDataService.masterData.user?.hubId ||
      !isMasterCall
    ) {
      this.eventsService.trackEvents(EVENT_NAMES.HUB_CHANGE_ALERT, {});
      EMPTYCART_ALERT.BUTTONS.CLOSE.handler = () => {
        isMasterCall &&
          this.eventsService.trackEvents(EVENT_NAMES.HUB_CHANGE_CONFIRM, {});
        this.verifyAddress(
          undefined,
          ADDRESS_VALIDATION_TYPE.CHANGE_ADDRESS,
          isMasterCall,
          isVerifyAddress
        );
      };
      EMPTYCART_ALERT.BUTTONS.OKAY.handler = () => {
        isMasterCall &&
          this.eventsService.trackEvents(EVENT_NAMES.HUB_CHANGE_DENIED, {});
        this.navCtrl.navigateRoot("/" + LISTING_ROUTES.HOME);
      };
      this.alertService.presentAlertConfirm(
        GENERATE_CONTENT_FOR_ALERT_LAYER(Alert),
        [EMPTYCART_ALERT.BUTTONS.CLOSE, EMPTYCART_ALERT.BUTTONS.OKAY],
        EMPTYCART_ALERT.CSS_CLASS
      );
    } else {
      this.verifyAddress(
        undefined,
        ADDRESS_VALIDATION_TYPE.CHANGE_ADDRESS,
        false,
        isVerifyAddress
      );
    }
  }

  changeRequestTowerAddress(towerData: UserSignupDetails) {
    this.userSignupDetails = towerData;
    const isVerifyAddress =
      this.authService.userData.societyObj.societyType ===
      this.societyType.Locality
        ? false
        : true;
    if (
      this.authService.userData.societyObj?.hubId !==
      this.masterDataService.masterData.user?.hubId
    ) {
      this.eventsService.trackEvents(EVENT_NAMES.HUB_CHANGE_CONFIRM, {});
      this.verifyAddress(
        undefined,
        ADDRESS_VALIDATION_TYPE.CHANGE_ADDRESS,
        true,
        isVerifyAddress
      );
    } else {
      this.verifyAddress(
        undefined,
        ADDRESS_VALIDATION_TYPE.CHANGE_ADDRESS,
        false,
        isVerifyAddress
      );
    }
  }
}
