
  <ion-list class="ion-no-padding ion-list-image-card video-card-collection gvc-t2 layout-spacer" lines="none">
    <ion-list-header class="view-title ion-list-header">
      <ion-row class="ion-justify-content-between full-width ion-align-items-center">
        <div>
          <span class="padding-right-small">{{ data.displayName }}</span>
        </div>
      </ion-row>
    </ion-list-header>
    <ion-item class="ion-no-padding" color="none">
      <div class="scroll" scroll-x="true">
        <div class="card-flex-view">
          <ng-container *ngFor="let row of data?.table?.rows">
            <!-- We are calculating the height and width of the col as based on the width of the #grid(ViewChild)-->
              <ion-card class="ion-card-custom fixed-size pdt-card to-flex" *ngFor="let column of row.columns" (click)="navigateToCollectionList(column)">
                <div class="img-holder ion-align-items-start">
                  <div class="img-holder-inner ion-align-items-start">
                    <ng-container *ngIf="column?.images?.leafBanner?.image; else defaultImage">
                      <app-img-on-demand [actualImgSrc]="bannerFilePath + column.images.leafBanner.image" [defaultImgSrc]="'assets/img/default-banner.png'"
                        alt_image="img_banner_{{column.images.leafBanner.image}}"
                        [showDefaultImage]="showDefaultImage" [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER" [imageName]="column.images.leafBanner.image">
                      </app-img-on-demand>
                      <span class="play-icon"></span>
                    </ng-container>
                    <ng-template #defaultImage>
                      <span class="play-icon"></span>
                      <img alt="default video collection thumbnail" src="assets/img/default_image.png"/>
                    </ng-template>
                  </div>
                </div>
                <ion-card-header>
                  <ion-card-title text-ellipsis-ml class="f-w-s-b">
                    {{ column.title.text }}
                  </ion-card-title>
                  <ion-card-subtitle class="d-flex ion-justify-content-between ion-align-items-center">
                    <span class="p-count f-w-m text-size-small">{{ column.desc }}</span> 
                    <div class="icon-wrap d-flex ion-justify-content-center ion-align-items-center">
                      <ion-icon class="icons-property icon-arrow-right icon-light icon-xxsm"></ion-icon>
                    </div>
                  </ion-card-subtitle>
                </ion-card-header>
              </ion-card>
          </ng-container>
        </div>
      </div>
    </ion-item>
  </ion-list>

