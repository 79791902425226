import { empty, Observable, Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/providers/http/http.service';
import { MasterDataService } from 'src/app/shared/providers/master-data/master-data.service';
import { LISTING_APIS } from '../../constants/listing-APIs';
import { AuthService } from '../../../shared/providers/auth/auth.service';
import { DataService } from 'src/app/shared/providers/data/data.service';
import { ModalService } from 'src/app/shared/providers/modal/modal.service';
import { EVENT_SOURCES } from '../../constants/listing-constants';
import { environment } from 'src/environments/environment';
import { STORAGE_KEYS } from 'src/app/shared/constants/constants';
import { Injectable } from "@angular/core";
import { recurringCampaignCashbackType } from '../../constants/listing-enums';
import * as moment from 'moment';
import { Utilities } from 'src/app/shared/classes/utility/utilities';


@Injectable({
  providedIn: 'root'
})
export class RecurringService  {
  updateRecurringOrders: Subject<boolean> = new Subject<boolean>();
  removeOnceplanSuccess: Subject<boolean> = new Subject<boolean>();
  recurringCampaignCategories: Subject<number> = new Subject<number>();
  calenderUpdate: Subject<boolean> = new Subject<boolean>();
  overlappingProducts = [];
  currentSlabIndex: number;
  recurringProducts = [];
  slablist = [];
  date = new Date();
  categoryProductCount = {};
  subscribeToSavePagePageView = false;
  recurringCampaignProducts: any;
  subscribeToSaveSubcategoryList = {};
  rcCampaignDetails: any;
  overlappingRecProducts = [];
  activeRecurringProduct = [];
  currentDayPercentProductCount = [];
  futureCurrentSlab: number = null;
  futurePreviousSlab: number = null;
  cashbackType = recurringCampaignCashbackType;
  recWithPaused=[];
  flatProducts=[];
  percentProducts=[];
  currentDayRecurring=[];
  searchText = "";
  from = 0;
  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    public masterDataService: MasterDataService,
    private dataService: DataService,
    private modalService: ModalService
  ) {
  }

  searchProducts(searchStr): Observable<object> {
    if(this.searchText !== searchStr) {
      this.searchText = searchStr;
      this.from = 0;
    } else  {
      this.from += 40;
    }
    let dataToeBeSent = {
      search_text: searchStr,
      city_id:
        this.authService.userData.cityObj &&
        this.authService.userData.cityObj.id,
      from: this.from
    };
    return this.httpService.post(LISTING_APIS.PRODUCTS_SEARCH, dataToeBeSent);
  } 

  recurringOrders(hideLoader = false): Observable<object> {
    if (hideLoader) {
      return this.httpService.get(LISTING_APIS.CALENDAR_ORDER_LIST, {
        hideLoader: true
      });
    } else {
      return this.httpService.get(LISTING_APIS.CALENDAR_ORDER_LIST);
    }
  }

  setRecurring(recurringRequest) {
    if (this.dataService.firebaseMasterData.infoPopUp && this.dataService.firebaseMasterData.infoPopUp.location.addToBasket) {
      if (this.dataService.firebaseMasterData.infoPopUp.isThrottle) {
        if (this.masterDataService.masterData?.user?.throttle_status) {
          this.modalService.infoPopUp(EVENT_SOURCES.SET_RECURRING);
          return empty();
        }
      } else {
        this.modalService.infoPopUp(EVENT_SOURCES.SET_RECURRING);
        return empty();
      }
    }
    recurringRequest.version = environment.codePushVersion;
    return this.httpService.post(
      LISTING_APIS.RECURRING_MODIFY,
      recurringRequest
    );
  }


  setToCalendar(requestPayload) {
    if (this.dataService.firebaseMasterData.infoPopUp && this.dataService.firebaseMasterData.infoPopUp.location.addToBasket) {
      if (this.dataService.firebaseMasterData.infoPopUp.isThrottle) {
        if (this.masterDataService.masterData?.user?.throttle_status) {
          this.modalService.infoPopUp(EVENT_SOURCES.SET_RECURRING);
          return empty();
        }
      } else {
        this.modalService.infoPopUp(EVENT_SOURCES.SET_RECURRING);
        return empty();
      }
    }
    requestPayload.version = environment.codePushVersion;
    return this.httpService.post(
      LISTING_APIS.CALENDAR_ORDER,
      requestPayload
    );
  }

  stopRecurring(recurringOrderId) {
    return this.httpService.post(LISTING_APIS.RECURRING_STOP, {
      recurring_order_id: recurringOrderId,
      version: environment.codePushVersion
    });
  }

  deleteCalendar(recurringOrderId, reasonList) {
    return this.httpService.post(LISTING_APIS.CALENDAR_STOP, {
      recurring_order_id: recurringOrderId,
      version: environment.codePushVersion,
      reasons: reasonList
    });
  }

  resumeCalendar(recurringOrderId) {
    return this.httpService.post(LISTING_APIS.CALENDAR_RESUME, {
      recurring_order_id: recurringOrderId,
    });
  }

  pauseCalendar(dataToSend) {
    return this.httpService.post(LISTING_APIS.CALENDAR_PAUSE, dataToSend);
  }

  updateJourneyTakenFlag() {
    if (this.dataService.calendarCoachmarkObject === null) {
      this.dataService.calendarCoachmarkObject = {};
    }
    this.dataService.calendarCoachmarkObject.calendarJourneyTaken = true;
    localStorage.setItem(STORAGE_KEYS.CALENDAR_COACHMARK_OBJECT, JSON.stringify(this.dataService.calendarCoachmarkObject));
  }


  mergeOrders(scheduledOrders, recurringOrders) {
    if (scheduledOrders) {
      for (const orders of scheduledOrders) {
        orders.frequency = 'once';
      }
    } else { scheduledOrders = []; }
    if (recurringOrders) {
      for (const orders of recurringOrders) {
        if (orders.frequency === 'weekly' && orders.frequencyMeta === 4) {
          orders.frequency = 'daily';
        }
      }
    } else { recurringOrders = []; }
    this.masterDataService.recurringOrder = recurringOrders.concat(scheduledOrders);
  }

  // method to update recurring objects locally without calling api
  updateRecurringObjects(productId) {
    this.dataService.recurringRecommendations.recRecommends = this.dataService.recurringRecommendations.recRecommends.filter(
      (product) => {
        return (product.id !== productId);
      }
    );
    delete this.dataService.recRecommendationObject[productId];
  }

  //method to calculate the flat cashback value if the product set on recurring or added basket
  flatValue(product) {
    let flatProductOverlap = [];
    if (this.masterDataService.recurringOrder && this.masterDataService.recurringOrder.length) {
      flatProductOverlap = this.masterDataService.recurringOrder.filter(recProduct => recProduct.product_id === product.product_id);
    }

    if (flatProductOverlap.length) {
      this.subscribeToSavePagePageView = true;
      this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id]['productOverlaps'] = true;
    }
  }

  intialRecurringCampaignObjectSet() {
    this.recurringCampaignProducts = {};
    this.recurringCampaignProducts = JSON.parse(localStorage.getItem(STORAGE_KEYS.RECURRING_CAMPAIGN));
    this.rcCampaignDetails = {};
    if (this.recurringCampaignProducts && this.recurringCampaignProducts['rec']) {
      if ('tnc' in this.recurringCampaignProducts['rec']) {
        this.rcCampaignDetails.tnc = this.recurringCampaignProducts['rec']['tnc'];
      }
      if ('desc' in this.recurringCampaignProducts['rec']) {
        this.rcCampaignDetails.desc = this.recurringCampaignProducts['rec']['desc'];
      }
      if ('details' in this.recurringCampaignProducts['rec']) {
        this.rcCampaignDetails.details = this.recurringCampaignProducts['rec']['details'];
      }
      this.recurringCampaignValuesSet();
    }
  }
  
  percentProductCountwithoutPause(recPercentProducts){
    let pausedProduct=true;
    if(recPercentProducts.pausePeriod) {
      let startDate = new Date(recPercentProducts.pausePeriod.startDate);
      let endDate = new Date(recPercentProducts.pausePeriod.endDate);
      if(moment(new Date()).isBetween(startDate,endDate) || Utilities.equalDates(new Date(),startDate) || Utilities.equalDates(new Date(),endDate)){
        pausedProduct = false;
      }
    } 
    return pausedProduct;
  }

  checkForProductAvailablity(product){
    const subscriptionProduct = this.recurringCampaignProducts['rec']['products'].filter(item=>item.product_id ===product.product_id);
    let productExistInRecurring=true;
    if(subscriptionProduct.length){
      subscriptionProduct.forEach(recurringProduct => {
        if(!productExistInRecurring && recurringProduct['ef_from'] && new Date(recurringProduct['ef_from']) <= new Date()){
          productExistInRecurring = true;
        }
        if(recurringProduct['ef_till'] && (!Utilities.equalDates(new Date(),new Date(recurringProduct['ef_till'])) && moment(recurringProduct['ef_till']).isBefore(new Date()) )){
          productExistInRecurring =false;
        }
      });
      return productExistInRecurring;
    }
  }
   //Initial method to created recurring mapper used throughout the application (this will get called everytime we add remove Basket or set a product on recurring)
  recurringCampaignValuesSet(update?) {

    if (this.recurringCampaignProducts && this.recurringCampaignProducts['rec']) {
      if ('slabs' in this.recurringCampaignProducts['rec']) {
        this.slablist = this.recurringCampaignProducts['rec']['slabs'];
        this.recurringCampaignProducts['rec']['products'] = this.recurringCampaignProducts['rec']['products'].filter(availableProducts => this.checkForProductAvailablity(availableProducts));
        if (this.masterDataService.recurringOrder) {
          const percentProducts = this.masterDataService.recurringOrder.filter((recProductItem:any) =>  this.recurringCampaignProducts['rec']['products'].some(recProduct => recProduct.cb_type === recurringCampaignCashbackType.PERCENT_TYPE && recProduct.product_id === recProductItem.product_id));
         this.overlappingRecProducts =  percentProducts.filter((recPercentProducts)=>this.percentProductCountwithoutPause(recPercentProducts));
        }
        this.todayBasketCheck();
      }
      if (this.subscribeToSavePagePageView) {
        this.subscribeToSavePagePageView = false;
      }
      this.recurringCampaignProducts['recurringCampaignMapper'] = {};
      this.recurringCampaignProducts['recurringCampaignMapper']['products'] = {};

      if (!update) {
        this.categoryProductCount = {};
        this.flatProducts=[];
        this.percentProducts=[];
      }

      this.recurringCampaignProducts && this.recurringCampaignProducts['rec'] && 
      this.recurringCampaignProducts['rec']['products'] && this.recurringCampaignProducts['rec']['products'].map((product: any) => {
        if (product.cb_type === recurringCampaignCashbackType.PERCENT_TYPE) {
          this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id] = { ...product };
          this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id]['productOverlaps'] = false;
          this.setSlabValue(product.product_id);
        }

        if (product.cb_type === recurringCampaignCashbackType.FLAT_TYPE) {
          if (!this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id]) {
            if (!(product['ef_from'] && new Date(product['ef_from']) > this.date)) {
              this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id] = { ...product };
              this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id]['productOverlaps'] = false;
            }
          } else {
            let flatTypeProduct = {};
            if (product['ef_from'] && new Date(product['ef_from']) > this.date) {
              flatTypeProduct = this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id];
              flatTypeProduct['futureProduct'] = { ...product };
            } else {
              flatTypeProduct = { ...product };
              flatTypeProduct['futureProduct'] = this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id];
            }
            this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id] = flatTypeProduct;
            this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id]['productOverlaps'] = false;
          }
          if( this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id]){
            this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id]['actual_price'] = product['cb_val'];
          }
          this.flatValue(product);
        }
        if(this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id]?.['ef_till'] && (!Utilities.equalDates(this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id]['ef_till'],new Date()) && moment(new Date(this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id]['ef_till'])).isBefore(new Date()) ) ){
          delete this.recurringCampaignProducts['recurringCampaignMapper']['products'][product.product_id];
        }
      });
      
      if (!update) {
        this.createSubcategoryMap();
      }
    }

  }

  createSubcategoryMap(){
    this.categoryProductCount={};
    let recurringProducts = Object.values(this.recurringCampaignProducts['recurringCampaignMapper']['products']);
    recurringProducts && recurringProducts.length &&  recurringProducts.forEach((product:any)=>{
      if (!this.categoryProductCount[product.sub_c_id]) {
        this.categoryProductCount[product.sub_c_id] = { 
          percent_count:0,
          flat_count:0,
          sub_c_id: product.sub_c_id,
        }
      }
      if(product['cb_type'] === this.cashbackType.PERCENT_TYPE){
        this.percentProducts.push(product);
        this.categoryProductCount[product.sub_c_id].percent_count++;
      } else {
        this.flatProducts.push(product);
        this.categoryProductCount[product.sub_c_id].flat_count++;
      }
    });
    this.recurringCampaignCategoryList();
  }
  

  //To create  the categorylist with of subscribe and save product with their product count
  recurringCampaignCategoryList() {
    this.subscribeToSaveSubcategoryList = {};
    this.subscribeToSaveSubcategoryList['percentSubcategory']=[];
    this.subscribeToSaveSubcategoryList['flatSubcategory'] =[];
    for (let subCategoryId in this.categoryProductCount) {
      this.masterDataService.categories?.forEach((category: any) => {
        const sub_cats = category.subcategories.filter(subcat => subcat.id === Number(subCategoryId));
        if (sub_cats.length) {
          if(this.categoryProductCount[subCategoryId]['percent_count'] > 0){
            sub_cats[0]['p_count'] = this.categoryProductCount[subCategoryId]['percent_count'];
            if(!this.subscribeToSaveSubcategoryList['percentSubcategory'].filter(item=>item.id === sub_cats[0]['id']).length){
              this.subscribeToSaveSubcategoryList['percentSubcategory'].push({...sub_cats[0]});
            }
          }
          if(this.categoryProductCount[subCategoryId]['flat_count'] > 0){
            sub_cats[0]['p_count'] = this.categoryProductCount[subCategoryId]['flat_count'];
            if(!this.subscribeToSaveSubcategoryList['flatSubcategory'].filter(item=>item.id === sub_cats[0]['id']).length){
              this.subscribeToSaveSubcategoryList['flatSubcategory'].push({...sub_cats[0]});
            }
          }
        }
      });
    }

    this.subscribeToSaveSubcategoryList['percentSubcategory'].unshift({ name: 'All', id: '', p_count: this.percentProducts.length });
    this.subscribeToSaveSubcategoryList['flatSubcategory'].unshift({ name: 'All', id: '', p_count:  this.flatProducts.length });
  }

  //This method is used the cashback value of perecent type product as per  their slabs
  setSlabValue(product_id) {
    if (this.masterDataService.recurringOrder && this.masterDataService.recurringOrder.some(recProduct => recProduct.product_id === product_id) && this.overlappingRecProducts.length) {
      this.recurringCampaignProducts['recurringCampaignMapper']['products'][product_id]['productOverlaps'] = true;
      this.subscribeToSavePagePageView = true;
      this.recurringCampaignProducts['recurringCampaignMapper']['products'][product_id]['cb_val'] = this.getCurrentSlab()['p_cb'];
      this.recurringCampaignProducts['recurringCampaignMapper']['products'][product_id]['currentSlab'] = this.getCurrentSlab();
    } else {
      this.recurringCampaignProducts['recurringCampaignMapper']['products'][product_id]['cb_val'] = this.nextSlab()['p_cb'];
    }
  }

  //This method is  used to get the current slab of the customer
  getCurrentSlab() {
    let equalProductCount = this.slablist.filter((count, index) => {
      if (count.p_count === this.overlappingRecProducts.length) {
        count['index'] = index;
        return count;
      }
    }
    );
    let currentSlab = {};
    let futureSlab = {};
    const date = new Date();
    if (equalProductCount.length) {
      equalProductCount.forEach(productCount => {
        if (!productCount['ef_from']) {
          currentSlab = productCount;
        } else if (productCount['ef_from'] && (new Date(productCount['ef_from']) < date || Utilities.equalDates(new Date(productCount['ef_from']),date))) {
          currentSlab = productCount;
        } else if (productCount['ef_from'] && new Date(productCount['ef_from']) > date) {
          futureSlab = productCount;
        }
      })
    }
    if (this.overlappingRecProducts.length > this.getMaxSlab()['p_count'] && equalProductCount.length === 0) {
      currentSlab = this.getMaxSlab();
      this.slablist.forEach(slabObj => {
        if (slabObj['p_count'] === currentSlab['p_count']) {
          if (slabObj['ef_from'] && new Date(slabObj['ef_from']) > date) {
            futureSlab = slabObj;
          }
        }
      })
    }
    if (Object.keys(futureSlab).length) {
      currentSlab['futureProduct'] = futureSlab;
    }
    return currentSlab;
  }

  //This method is used to get the next slab of the customer if he add one more percent product
  nextSlab() {
    let nextSlab = {};
    let productCount = 0;

    if (this.getCurrentSlab()) {
      productCount = this.getCurrentSlab()['p_count'];
    }
    if(!productCount){
      productCount =0;
    }
    const date = new Date();
 
    this.slablist.forEach(slabObj => {
      if (slabObj['p_count'] === (1+productCount)) {
        if (!slabObj['ef_from']) {
          nextSlab = slabObj;
        } else if (slabObj['ef_from'] && (new Date(slabObj['ef_from']) < date || Utilities.equalDates(new Date(slabObj['ef_from']),date))) {
          nextSlab = slabObj;
        }
      }
    })
    if (productCount + 1 > this.getMaxSlab()['p_count']) {
      nextSlab = this.getMaxSlab();
    }
    return nextSlab;
  }

  //This method is used to get the previous slab of the customer
  prevSlab() {
    if (this.overlappingRecProducts.length === 0) {
      return false;
    } else {
      let previousSlab = {}
      this.slablist.forEach(slabObj => {
        if (slabObj['p_count'] === this.getCurrentSlab()['p_count'] - 1) {
          if (!slabObj['ef_from']) {
            previousSlab = slabObj;
          } else if (slabObj['ef_from'] && (new Date(slabObj['ef_from']) < new Date() || Utilities.equalDates(new Date(slabObj['ef_from']),new Date()))) {
            previousSlab = slabObj;
          }
        }
      })
      return previousSlab;
    }
  }

  //This method is used to return the maximum slab value the customer can have on percent type products
  getMaxSlab() {
    let maxSlab = { p_count: 0 }
    this.slablist.forEach(slabObj => {
      if ((!slabObj['ef_from'] && maxSlab.p_count <= slabObj['p_count']) || (slabObj['ef_from'] && new Date(slabObj['ef_from']) < new Date() && maxSlab.p_count <= slabObj['p_count'])) {
        maxSlab = slabObj;
      } 
    })
    return maxSlab;
  }

  todayBasketCheck() {
    let runningRecurringItems = [];
    let nextDay = new Date();
    nextDay.setDate((new Date()).getDate() + 1);
    this.overlappingRecProducts.forEach((recProducts: any) => {   
      if ( nextDay > new Date(recProducts['start_date']) ||  Utilities.equalDates(nextDay,recProducts['start_date']) ) {
        runningRecurringItems.push(recProducts);
      }
    });
    this.activeRecurringProduct = runningRecurringItems;
    return runningRecurringItems.length;
  }

  restOfTheMonthRecurringSet(basketDate){
    let runningRecurringItems = [];
    this.masterDataService.recurringOrder && this.masterDataService.recurringOrder.forEach((recurringProduct:any)=>{
        if (this.recurringCampaignProducts?.recurringCampaignMapper?.products?.[recurringProduct.product_id] && this.recurringCampaignProducts?.recurringCampaignMapper?.products?.[recurringProduct.product_id]['cb_type'] === this.cashbackType.PERCENT_TYPE) {
          if (new Date(basketDate) > new Date(recurringProduct['start_date']) ||  Utilities.equalDates(basketDate,recurringProduct['start_date'])) {
            if(recurringProduct.pausePeriod) {
              let startDate = new Date(recurringProduct.pausePeriod.startDate);
              let endDate = new Date(recurringProduct.pausePeriod.endDate);
              if(!moment(new Date(basketDate)).isBetween(startDate,endDate) && !Utilities.equalDates(new Date(basketDate),startDate) && !Utilities.equalDates(new Date(basketDate),endDate)){
                runningRecurringItems.push(recurringProduct);
              }
            }else{
              runningRecurringItems.push(recurringProduct);
            }
          }
        }
    });
   return runningRecurringItems;
  }

  subscribedRecurringProduct(product_id) {
    return this.activeRecurringProduct.some(item => item.product_id === product_id);
  }

  tomorrowBasketProductExistInOffer(product_id){
    if (this.recurringCampaignProducts?.recurringCampaignMapper?.products?.[product_id] && this.recurringCampaignProducts?.recurringCampaignMapper?.products?.[product_id]['cb_type'] === this.cashbackType.PERCENT_TYPE && this.pausedForTomorrowBasket(product_id)) {
      return true;
    }
    return false;
  }

  restOfTheMonthSlabValueSet(recurringBasketProductList, count = 0, tomorrowBasketPercentProductCount,basketDate?) {
    this.currentDayPercentProductCount = []; //current  A2B precent list
    let percentRecurringProducts = [];
    let day = 0;
    let percentProductCount = 0;
    if (tomorrowBasketPercentProductCount) {
      if(this.masterDataService.baskets?.length){
        if(this.masterDataService.baskets[0].removed_products && this.masterDataService.baskets[0].removed_products.length){
          const removedProducts =[];
          this.masterDataService.baskets[0].removed_products.forEach(cancelledProduct => {
            if(this.tomorrowBasketProductExistInOffer(cancelledProduct.product_id)){
              removedProducts.push(cancelledProduct);
            }   
          });
          recurringBasketProductList = [...recurringBasketProductList,...removedProducts ];
        }
      }
      recurringBasketProductList.forEach((item: any) => {
        if (this.tomorrowBasketProductExistInOffer(item.product_id)) {
          this.currentDayPercentProductCount.push(item);
        }
      });
    } else {
      recurringBasketProductList.forEach((item: any) => {
        if (this.recurringCampaignProducts?.recurringCampaignMapper?.products?.[item.product_id] && this.recurringCampaignProducts?.recurringCampaignMapper?.products?.[item.product_id]['cb_type'] === this.cashbackType.PERCENT_TYPE) {
          this.masterDataService.recurringOrder && this.masterDataService.recurringOrder.forEach((recurringProduct: any) => {
            if (recurringProduct.product_id === item.product_id) {
              this.currentDayPercentProductCount.push(item);
            }
          })
        }
      });
    }

    let recurringProductList = [];
    if (tomorrowBasketPercentProductCount) {
      if(day === 0){
        day =1;
      } else{
        day =  (moment().day() + 1); // here 7 represent sunday ;
      }
      let nextDay = new Date();
      nextDay.setDate((new Date()).getDate() + 1);  
      recurringProductList = this.recurringOrderSetForDate(this.activeRecurringProduct,day,nextDay);
    }
    if (!tomorrowBasketPercentProductCount) {
      day =  moment(basketDate).day() ; // here 7 represent sunday ;
      if(day === 0){
        day =7;
      }
      const currentRec =  this.restOfTheMonthRecurringSet(basketDate) ;
      recurringProductList =  this.recurringOrderSetForDate(currentRec, day,basketDate);
    }
    percentRecurringProducts = recurringProductList;

    if ((percentRecurringProducts.length - this.currentDayPercentProductCount.length) > 0) {
      const productDifference = percentRecurringProducts.length - this.currentDayPercentProductCount.length;
      percentProductCount = this.overlappingRecProducts.length - productDifference;
    } else {
      percentProductCount = this.overlappingRecProducts.length;
    }

    let slabCashbackValue = 0;
    if (this.currentDayPercentProductCount.length) {
      for (let i = 0; i < this.slablist.length; i++) {
        if (this.slablist[i]['p_count'] === percentProductCount - count) {
          if (!this.slablist[i]['ef_from']) {
            slabCashbackValue = this.slablist[i]['p_cb'];
          } else if (this.slablist[i]['ef_from'] && (new Date(this.slablist[i]['ef_from']) < new Date() || Utilities.equalDates(new Date(this.slablist[i]['ef_from']),new Date()))) {
            slabCashbackValue =  this.slablist[i]['p_cb'];
          }
        }
      }

    }

    if (count === 0) {
      if (percentProductCount >= this.getMaxSlab()['p_count']) {
        slabCashbackValue = this.getMaxSlab()['p_cb'];
      }
    }
    return slabCashbackValue;
  }

  recurringOrderSetForDate(recurringProductList,day,basketDate) {
    let recurringProductsArray=[]
    recurringProductList.forEach(currentDayRecurringProduct => {
      if (currentDayRecurringProduct.frequency && currentDayRecurringProduct.frequencyMeta === 1) {
        currentDayRecurringProduct['orderDays'].forEach(productOrderDay => {
          if (day === productOrderDay['day'] && productOrderDay['quantity'] > 0) {
            recurringProductsArray.push(currentDayRecurringProduct);
          } 
        });
      } else if (currentDayRecurringProduct.frequency && currentDayRecurringProduct.frequencyMeta === 4) {
        recurringProductsArray.push(currentDayRecurringProduct);
      } else if (currentDayRecurringProduct.frequency && currentDayRecurringProduct.frequency === 'alternate') {
        let todayBasketDate = moment(new Date(basketDate));
        let startDate = moment(new Date(currentDayRecurringProduct.start_date));
        let difference = todayBasketDate.diff(startDate, 'days') // 1
        if(difference >= 0 && difference%2 ===0 ){
          recurringProductsArray.push(currentDayRecurringProduct);
        }
      }
    });
    return recurringProductsArray;
  }

  pausedForTomorrowBasket(product_id){
    let nextDay = new Date();
    nextDay.setDate((new Date()).getDate() + 1); 
    let currentDayRecurringFlag = false; 
    this.masterDataService.recurringOrder && this.masterDataService.recurringOrder.forEach((recurringProduct:any)=>{
      if ( nextDay > new Date(recurringProduct['start_date']) ||  Utilities.equalDates(nextDay,recurringProduct['start_date']) ) {
      if(recurringProduct.product_id === product_id && !recurringProduct.pausePeriod){
      currentDayRecurringFlag = true;
      } else  if(recurringProduct.product_id === product_id && recurringProduct.pausePeriod) {
        let startDate = new Date(recurringProduct.pausePeriod.startDate);
        let endDate = new Date(recurringProduct.pausePeriod.endDate);
       if(moment(new Date(nextDay)).isBetween(startDate,endDate)){
        currentDayRecurringFlag = false;
       } else if(!Utilities.equalDates(new Date(nextDay),startDate)  || !Utilities.equalDates(new Date(nextDay),endDate)){
        currentDayRecurringFlag = true;
       }
      }
    }
    });
    return currentDayRecurringFlag;
  }


  getRecurringProducts(options) {
    return this.httpService.post(LISTING_APIS.PRODUCTS_SEARCH, options);
  }

  getRecurringProductSavings() {
    return this.httpService.get(`${LISTING_APIS.RC_SAVING}?type=RECURRING_CAMPAIGN`);
  }

  getRecurringProductSavingTotal() {
    return this.httpService.get(`${LISTING_APIS.RC_SAVING_TOTAL_AMOUNT}?type=RECURRING_CAMPAIGN`);
  }

  getStockAvailability(options){
    options.version = environment.codePushVersion;
    return this.httpService.post(`${LISTING_APIS.PRODUCT_STOCK_AVAILABILTY}`, options);
  }

}
