export const REGISTRATION_APIS = {
  LOG_IN: 'user/login',
  LOG_IN_TRUECALLER: 'user/login_truecaller',
  GENERATE_LOGIN_OTP: 'user/generate_login_otp',
  REGISTER_MOBILE_NUMBER: 'user/register_mobile',
  REGISTER_TRUECALLER: 'user/register_truecaller',
  SOCIETY_TOWER: 'home/society_tower',
  REGISTER_NEW: 'user/register_new',
  VERIFY_WELCOME_MESSAGE: 'user/verify_welcome_code',
  LOGIN_WITH_OTP: 'user/login_by_otp',
  NEAREST_SOCIETIES: 'home/nearest_societies',
  SEARCH_SOCIETIES: 'home/location_societies',
  SOCIETIES: 'home/societies',
  VERIFY_OTP: 'user/verify_otp',
  GET_INVITE_DETAILS: 'home/get_invite_details',
  LOGIN_JIO: 'user/login_jio',
  UPDATE_ADDRESS: 'user/update-address',
  NON_SERVICABLE_CITY_LIST: 'home/non_serviceable_cities',
  VALIDATE_ADDRESS: 'user/validate-address',
  CHANGE_ADDRESS: 'user/address-request'
};
