//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseComponent } from '@Widgets/blocks/base/base.component';
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { DeepLinkService } from '@App/shared/providers/deeplink/deep-link.service';
import { EventsService } from '@Globals/providers/events.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, EVENT_SOURCES, DEFAULT_BANNER_MAX_LIMIT, STORAGE_KEYS } from '@Globals/constants/listing/constants';
import { WhatsappSharingComponentTypes } from '@Globals/constants/listing/enums';
import { EventTrackingPlugins } from '@Globals/constants/listing/enums';
import { environment } from '@Src/environments/environment';

import { InViewportMetadata } from 'ng-in-viewport';
//#endregion Other Imports

//#endregion Imports

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);
@Component({
  selector: 'wgt-banner-block',
  templateUrl: './banner.component.html',
  styleUrls: ['../base/base.component.scss', './banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent extends BaseComponent {
 
   bannerIndex = 0;
   isTrackingBannerImpression = false;
   bannerComponent = WhatsappSharingComponentTypes.Banner;
   homeBannersSource = EVENT_NAMES.HOME;
   bannerFilePath = environment.bannerPath;
   private swiperRef: any;
   
   bannerMaxLimit;
   constructor(
     private eventsService: EventsService,
     public masterDataService: MasterDataService,
     private deepLinkService: DeepLinkService,
     private changeDetectorRef: ChangeDetectorRef,
     public dataService: DataService,
     public router: Router,
     public zone: NgZone
   ) {
      super();
      this.setMaxLimitForBanners();
    }

   setMaxLimitForBanners() {
    try {
        if(localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG_MAX_LIMIT) && localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG_MAX_LIMIT) != 'null' && localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG_MAX_LIMIT) != 'undefined' && Object.keys(JSON.parse(localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG_MAX_LIMIT))).length) { 
          this.bannerMaxLimit = JSON.parse(localStorage.getItem(STORAGE_KEYS.FIREBASE_CONFIG_MAX_LIMIT)).banners || DEFAULT_BANNER_MAX_LIMIT;
        } else {
          this.bannerMaxLimit = DEFAULT_BANNER_MAX_LIMIT;
        }
    } catch(e) {
        this.bannerMaxLimit = DEFAULT_BANNER_MAX_LIMIT;
    }
   }
    
   mbOnInit(): void {
      this.fetchBanners();
      this.dataService.knockKnock.subscribe(success => {
        if (success) {
          this.swiperRef?.autoplay?.start();
        }
      });
   }
 
  fetchBanners() {
    if(this.data) {
      this.trackBannerImpression();
      return 
    }
    if(this.dashboard.name === 'home' && this.schema.n === 'home_banner_one'){
      this.masterDataService.banners && this.loadBannersData();
      this.masterDataService.bannersLoadedSub.subscribe(dataLoaded => dataLoaded && this.loadBannersData(true));
    }
  } 

  loadBannersData(runChangeDetection = false) {
    this.data = {
      banners : this.masterDataService.banners || [],
      source : EVENT_NAMES.HOME
    };
    this.trackBannerImpression();
    runChangeDetection && this.changeDetectorRef.markForCheck();
  }

  swiperInit(swiper: any) {
    this.swiperRef = swiper;
  }

  slideChanged($event) { 
    this.bannerIndex = $event[0].activeIndex;
    this.trackBannerImpression();
  }

  trackBannerImpression() {
    if(this.data.banners && (this.data.banners.length > 0)) {
      this.eventsService.trackEvents(EVENT_NAMES.BANNER_IMPRESSION, {
        banner_id: this.data.banners[this.bannerIndex] && this.data.banners[this.bannerIndex].id,
        banner_index: this.bannerIndex + 1,
        source: this.data.source
      }, false, EventTrackingPlugins.Firebase);
    }
  }
  
  navigate(event, activeIndex){
    event.preventDefault && event.preventDefault();
    let banner = this.data.banners[activeIndex];
    let eventName: string;
    let eventArgs: any;
    if (banner['collection_id']) {
      this.dataService.collectionsConfigData = {
        leafRequest: [],
        listImageUrl: null,
        collection: null
      };
      const currentLeafIdAndListId = {
        leafId: null,
        listId: null
      };
      eventName = EVENT_NAMES.COLLECTION_VIEW;
      eventArgs = {
        name: banner.name,
        collection_title: banner['collection_title'],
        collection_id: banner['collection_id'],
      };
    } else {
      eventName = EVENT_NAMES.BANNER_VIEWED;
      eventArgs = {
        banner_id: banner.id,
        banner_name: banner.name,
        banner_image: banner.img,
        banner_url: banner.url,
        banner_index: this.bannerIndex + 1,
        banner_source: this.data.source
      };
    }
    this.eventsService.trackEvents(eventName, eventArgs);
    let url = banner.url;
    if (/list/i.test(url)) {
      url = `${url}/${banner.id}` + '/' + (this.bannerIndex + 1);
    }
    if(/product/i.test(url)) {
      const productId = { id: null };
      productId.id = url.split('/product/')[1]; 
      url = "/banner-product/" + `${productId.id}/${banner.id}`;
    }
    this.deepLinkService.navToDeepLinkUrl(url, EVENT_SOURCES.BANNERS, banner.name);
  }

  onIntersection($event) {
    const { [InViewportMetadata]: { entry }, target } = $event;
    const ratio = entry.intersectionRatio;

    this.zone.runOutsideAngular(() => {
      if(ratio >= 0.65) {
        this.swiperRef?.autoplay?.start();
      } else {
        this.swiperRef?.autoplay?.stop();
      }
    });
  }
}