//#region Imports

//#region Angular Imports
import { Component, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { noop } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from 'src/app/shared/providers/master-data/master-data.service';
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
import { PAYMENT_ROUTES } from '@Globals/constants/payment/routes';
import { PaymentOption } from '@Globals/interfaces/listing/interfaces';
import { environment } from '@Env/environment';
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Other Imports

//#endregion Imports


@Component({
  selector: 'mbs-payment-offer-details',
  templateUrl: './payment-offer-details.component.html',
  styleUrls: [ './payment-offer-details.component.scss' ],
})
export class PaymentOfferDetailsComponent extends DefaultComponent {
  @Input() offer: any;
  @Input() pg: PaymentOption;
  pgImagePath = environment.imagePath;
  bannerFilePath = environment.bannerPath;
  closeModal = () => {
    this.modalCtrl.dismiss();
  };
  constructor (
    private modalCtrl: ModalController,
    public masterDataService: MasterDataService,
    private navCtrl: NavController,
    public dataService: DataService,
    public sanitizer: DomSanitizer) {
      super();
  }

  mbOnInit() {
    if (this.offer.description) {
      this.offer.description = this.sanitizer.bypassSecurityTrustHtml(this.offer.description);
    }
  }

  navigateToShoppingCredit() {
    this.pg && (this.dataService.pgFromDeepLink = this.pg);
    if (this.offer && this.offer.membership_id) {
      this.navCtrl.navigateForward(PAYMENT_ROUTES.BASE + PAYMENT_ROUTES.SHOPPING_CREDITS + '/' + this.offer.membership_id + '/true').then(this.closeModal).catch(noop);
    } else {
      this.navCtrl.navigateForward(PAYMENT_ROUTES.BASE + PAYMENT_ROUTES.SHOPPING_CREDITS).then(this.closeModal).catch(noop);
    }
  }

}
