//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { IonicModule } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultModule } from '@Lib/defaults/default.module';

import { ImgOnDemandModule } from '@Main/modules/shared/img-on-demand/img-on-demand.module';

import { ExtraWidthModule } from '@Features/product-list/components/horizontal/extra-width/extra-width.module';
//#endregion Module Imports

//#region Component Imports
import { HorizontalProductsExtraWidthComponent } from '@Widgets/blocks/collection/horizontal-products-extra-width/horizontal-products-extra-width.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ImgOnDemandModule,
    ExtraWidthModule
  ],
  exports: [HorizontalProductsExtraWidthComponent],
  declarations: [HorizontalProductsExtraWidthComponent]
})
export class HorizontalProductsExtraWidthModule extends DefaultModule { }