export enum InvitePageSections {
    Referral_Content = '1',
    How_It_Works = '2',
    Referral_Earnings = '3'
}

export enum RefereeStatusTypes {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed'
}

export enum RefereeReasonTypes {
    Society_Not_Serviceable = '1',
    Your_Society_Not_Serviceable = '2',
    Duplicate_Address = '3',
    EXPIRED = '4'
}

export enum UserAccountStatus {
    Active,
    Closed,
    PendingInstantRefund,
    PendingAfterMembershipExpire
}

export enum ACCOUNT_DEACTIVATION_STATUS {
    NOT_NEEDED_BANK_ACCOUNT = 1,
    BANK_ACCOUNT_NEEDED = 2,
    NOT_NEEDED_BANK_ACCOUNT_ACTIVE_MEMBERSHIP = 3,
    BANK_ACCOUNT_NEEDED_ACTIVE_MEMBERSHIP = 4,
    BANK_ACCOUNT_NEED_SUBSCRIPTION_END_LATER = 5,
    NOT_BANK_ACCOUNT_NEED_SUBSCRIPTION_END_LATER = 6
}
export enum AccountClosure {
    SelectReason,
    HaveMembership,
    DoNotHaveMembership,
    GetBankDetails,
    Deleted
}
