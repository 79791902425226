<ion-header class="ion-main-header sticky-header">
  <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium padding-right-medium pl-0">
        <mbs-bottom-sheet-header (closeBottomSheet)="closeTnc()" [backType]="backAction" class="ion-promo-header has-cross-icon">Terms & Conditions:</mbs-bottom-sheet-header>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding-bottom">
  <div class="tnc-wrap" [innerHTML]="sanitizeDescription"></div>
</ion-content>
