import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../shared/shared.module';
import { UserAddressComponent } from './components/user-address/user-address.component';
import { SwiperModule } from 'swiper/angular';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SharedModule,
        SwiperModule
    ],
    exports: [ UserAddressComponent],
    declarations: [ UserAddressComponent],
    entryComponents: []
})
export class CoreModule {
}
