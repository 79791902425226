//#region Imports

//#region Angular Imports
import { Component, OnInit, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
import { HTTPResponse } from '@ionic-native/http';
//#endregion Ionic Imports

//#region Library Imports
import { timer } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from '@Globals/providers/master-data.service';
import { ToastService } from '@Globals/providers/toast.service';

import { JuspayService } from '@App/shared/providers/juspay/juspay.service';
//#endregion Providers Imports

//#region Other Imports
import { REGISTRATION_CONSTANTS } from '@Globals/constants/registration/constants';
import { WALLET_LINKING } from '@Globals/constants/listing/enums';
import { MODAL_IDS,PG_WISE_JUSPAY_PAY_LOAD} from '@Globals/constants/payment/constants';
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-wallet-linking',
  templateUrl: './wallet-linking.component.html',
  styleUrls: [ './wallet-linking.component.scss' ],
})
export class WalletLinkingComponent extends DefaultComponent {
  @Input() pg: any;
  dataToSend = {
    wallet_id: '',
    otp: ''
  };
  walletLinking = WALLET_LINKING;
  walletState = WALLET_LINKING.MOBILE;
  mobileNumber;
  error = false;
  enableResend = false;

  constructor (
    private modalCtrl: ModalController,
    private juspayService: JuspayService,
    public masterDataService: MasterDataService,
    private toastService: ToastService
    ) {
      super();
    }

  mbOnInit() {
    this.mobileNumber = this.masterDataService.masterData?.user?.phone;
  }

  generateOtp() {
    document.getElementsByClassName('paytm-wallet-linking')[0].classList.add('otp-linking');
    this.juspayService.generateOtp(this.pg.value, this.mobileNumber).subscribe((success: HTTPResponse) => {
      if (success.data && success.data.data && success.data.data.id) {
        this.dataToSend.wallet_id = success.data.data.id;
        this.walletState = WALLET_LINKING.OTP;
        this.enableResend = false;
      } else if (success.data && success.data.error) {
        if (this.pg.value === PG_WISE_JUSPAY_PAY_LOAD.paytm.pgName) {
          this.toastService.presentToast('This number is not registered with Paytm. Please enter a registered number.');
        } else {
          this.toastService.presentToast('This number is not registered with Airtel Payments Bank. Please enter a registered number.');
        }

      }
    });
  }
  restrictToDigits = evt => {
    this.walletState = WALLET_LINKING.MOBILE;
    document.getElementsByClassName('paytm-wallet-linking')[0].classList.remove('otp-linking');
    if (evt.inputType === 'insertFromPaste') {
      evt.target.value = evt.target.value.replace(/[^0-9]/g, '');
    } else {
      evt.target.value =
        parseInt(evt.target.value, REGISTRATION_CONSTANTS.RADIX) || '';
    }
    if (evt.target.value.length === 10) {
      this.mobileNumber = evt.target.value;
    }
  };

  verifyOtp() {
    this.juspayService.verifyOtp(this.dataToSend).subscribe((success: HTTPResponse) => {
      if (success.data && success.data.data && success.data.data.token) {
        const paytmOption = this.masterDataService.paymentOptionSection.wallet.options.find(method => method.option.value === PG_WISE_JUSPAY_PAY_LOAD.paytm.pgName);
        if(paytmOption?.option?.balance){
        paytmOption.option.balance = success.data.data.current_balance;
        }
        paytmOption.option.token = success.data.data.token;
        paytmOption.option.linked = success.data.data.linked;
        this.closeModal();
      } else {
        this.error = true;
        timer(3000).subscribe(() => {
          this.error = false;
        });
      }
    });
  }
  closeModal(pg = null) {
    this.modalCtrl.dismiss(pg, '', MODAL_IDS.PAYMENT_TYPE);
  }

  timerValue(time:number) {
    if(time === 0) {
      this.enableResend = true;
    }
  }
}
