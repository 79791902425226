//#region Imports

//#region Angular Imports
import { Component } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-cashback-wallet-guidelines',
  templateUrl: './cashback-wallet-guidelines.component.html',
  styleUrls: ['./cashback-wallet-guidelines.component.scss'],
})
export class CashbackWalletGuidelinesComponent extends DefaultComponent {
  constructor(
    private modalCtrl: ModalController,
    public dataService: DataService,
    public masterDataService: MasterDataService,
  ) {
    super();
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }
}
