<div class="scroll" scroll-x="true">
    <div class="ion-card-image d-flex ion-align-items-center" >
        <img alt="payback icon" src="/assets/img/payback.png" class="img-payback" />
        <img alt="intermiles icon" src="/assets/img/intermiles.png" class="img-intermiles" />
        <img alt="supercoins icon" src="/assets/img/supercoins.png" class="img-supercoins" />
        <img alt="indusInd_bank icon" src="/assets/img/indusInd_bank.png" class="img-induslnd" />
        <ion-button fill="clear" slot="end" class="ion-no-padding view-all no-ripple-effect text-size-sm f-w-n">
            & more
        </ion-button>
    </div>
</div>