<ion-header class="disable-notch sticky-header">
  <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium padding-horizontal-0">
    <ion-title class="ctn-gt-space-p">
      <h2>Updated Terms & Condition</h2>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="term-content-membership">
  <iframe *ngIf="url" [src]='url' loading="lazy" allowfullscreen class="iframe" id="iframe"></iframe>
</ion-content>
<ion-footer class="ctn-gt-space-p term-footer-membership">
  <ion-toolbar>
    <p class="txt-light">By continuing, you agree to have read and accepted our Terms of Service.</p>
      <ion-button size="large"  class="t-t-n" expand="block"
    (click)="closeModal()">Agree and Continue
  </ion-button>
  <!-- [disabled]="!enableSubmitButton" -->
  </ion-toolbar>
</ion-footer>