//#region Imports

//#region Angular Imports
import { Component, OnInit } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { ModalController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports 
import { EVENT_NAMES } from '@Globals/constants/listing/constants';
import { MasterDataService } from '@Globals/providers/master-data.service'; 
import { EventsService } from '@Globals/providers/events.service';
import { DeepLinkService } from '@Globals/providers/deep-link.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss']
})
export class WelcomeMessageComponent implements OnInit {
  constructor(
    public masterDataService: MasterDataService,
    public deepLinkService: DeepLinkService,
    private modalCtrl: ModalController,
    private eventsService: EventsService
  ) {}
  ionViewWillLeave() {
    if (
      document
        .getElementsByTagName('ion-app')
        .item(0)
        .classList.contains('disable-scroll')
    ) {
      document
        .getElementsByTagName('ion-app')
        .item(0)
        .classList.remove('disable-scroll');
    }
  }
  ngOnInit() {
    if (
      this.masterDataService.masterData &&
      this.masterDataService.masterData.appStartMessage
    ) {
      const eventArgs = {
        action: this.masterDataService.masterData.appStartMessage.action,
        id: this.masterDataService.masterData.appStartMessage.id,
        img: this.masterDataService.masterData.appStartMessage.img,
        title: this.masterDataService.masterData.appStartMessage.title,
        url: this.masterDataService.masterData.appStartMessage.url
      };
      this.eventsService.trackEvents(
        EVENT_NAMES.WELCOME_POPUP_VIEWED,
        eventArgs
      );
    }
    document
      .getElementsByTagName('ion-app')
      .item(0)
      .classList.add('disable-scroll');
  }

  navigate() {
    if (
      this.masterDataService.masterData &&
      this.masterDataService.masterData.appStartMessage
    ) {
      this.masterDataService.masterData.appStartMessage.url &&
        this.deepLinkService.navToDeepLinkUrl(
          this.masterDataService.masterData.appStartMessage.url
        );
    }
    this.modalCtrl.dismiss();
  }
}
