import { ProductCutOff } from './product-cut-off';
import { ProductRefill } from './product-refill';

export class ProductAvailability {
    __typename = 'ProductAvailability';
    isOos: boolean;
    oos: boolean;
    forcedOos: boolean;
    schedule: boolean;
    cutOff?: ProductCutOff; // merged ct, ct_text
    refill?: ProductRefill;
    maxQuantity: number; // was maxQty
    setAvailabilityFromApi(productApi) {
        this.oos = !!productApi.oos;
        this.forcedOos = !!productApi.forcedOos;
        this.schedule = !!productApi.schedule;
        this.isOos = !!(this.oos || this.forcedOos);
        this.maxQuantity = productApi.maxQty || null;
        this.schedule = !!productApi.schedule;
        
        const cutOff = new ProductCutOff();
        this.cutOff = cutOff.setCutOffFromApi(productApi);

        const refill = new ProductRefill();
        this.refill = refill.setProductRefillFromApi(productApi);

        return this;
    }
}
