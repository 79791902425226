<ng-container lines="none" *ngIf="!!wrapper; else extraWidth;">
  <ng-container *ngTemplateOutlet="extraWidth"></ng-container>
</ng-container>

<ng-template #extraWidth>
  
  <div class="ion-item-inner" (click)="hideCounterButton()" *ngIf="products">
    <ion-card  class="ion-no-padding ion-tow-card" *ngFor="let product of products.slice(0,10); trackBy: getProductId; let productIndex = index" (click)="handleProductClick(product, productIndex)">
      <!-- <ion-button 
        [disabled]="masterDataService.masterData?.user?.wf"
        (click)="showCounterButton(product, productIndex);$event.stopPropagation();" size="small" class="ion-no-padding"
        *ngIf="showCounterForProductId !== (product.product_id || product.id) || !authService.userData?.tomorrowBasket || !authService.userData?.tomorrowBasket?.[product.product_id || product.id];else showCounter;">
        <ion-icon *ngIf="!authService.userData?.tomorrowBasket || !authService.userData?.tomorrowBasket?.[product.product_id || product.id];else showCount" class="icon-add icons-property" color="white">
        </ion-icon>
        <ng-template #showCount>
          <p>{{ authService.userData?.tomorrowBasket[product.product_id || product.id] }}</p>
        </ng-template>
      </ion-button>
      <ng-template #showCounter>
        <mb-product-counter 
          [componentType]="topNotchCounterType" 
          [isPlusOneDayDelivery]="product.delivery_types_slot && product.delivery_types_slot[0] === deliveryTypeFrozen &&
            product.ct && product.ct !== '0' && currentHoursAndMinutes > product.ct" 
          [productId]="product.product_id || product.id"
          [counter]="authService.userData?.tomorrowBasket?.[product.product_id || product.id]"
          (counterChange)="triggerCounterChange($event, product, productIndex)" 
          (click)="$event.stopPropagation()">
        </mb-product-counter>
      </ng-template> -->
      <ion-item lines="none" class="ion-no-padding"
        [ngClass]="{ 'disabled' : showCounterForProductId === (product.product_id || product.id)  && authService.userData?.tomorrowBasket?.[product.product_id || product.id] }">
        <ion-thumbnail slot="start">
          <app-img-on-demand 
            [componentType]="PLPTypeImageOnDemand" 
            [actualImgSrc]="plmService.productImageUrl + product.img" 
            [defaultImgSrc]="'assets/img/default-product.svg'" 
            [productId]="product.product_id || product.id" 
            [imageName]="product.img"
            alt_image="img_product_{{product?.img}}"
            [fileStorageDirectoryName]="plmService.fileStorageDirectories.PRODUCT">
          </app-img-on-demand>
        </ion-thumbnail>
        <ion-label>
          <h4 text-ellipsis-ml> {{ product.name }} </h4>
          <div (click)="openProductsVariantModal(product, productIndex); $event.stopPropagation();" class="variants-wrap mb-0" [ngClass]="{'no-variants': !(product?._newVariantProducts?.length)}">
            <div><span class="text-holder">{{ product.wgt }}</span></div>
            <ion-icon *ngIf="product?._newVariantProducts?.length" class="icons-property icon-down-arrow"></ion-icon>
          </div>
          <h2> ₹{{ product.sp }} <del class="f-w-m" *ngIf="product.mrp - product.sp > 0">₹{{ product.mrp }}</del> </h2>
          <div class="sm-counter">
            <mb-product-counter [isPlusOneDayDelivery]="product.delivery_types_slot && product.delivery_types_slot[0] === deliveryTypeFrozen &&
          product.ct && product.ct !== '0' && currentHoursAndMinutes > product.ct"
              [productId]="product.product_id || product.id"
              [counter]="authService.userData?.tomorrowBasket?.[product.product_id || product.id]"
              (counterChange)="triggerCounterChange($event, product, productIndex)" (click)="$event.stopPropagation()">
            </mb-product-counter>
          </div>
        </ion-label>
      </ion-item>
    </ion-card>
  </div>
  
</ng-template>