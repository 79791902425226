<ion-header class="sticky-header">
  <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium padding-horizontal-0">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
          <ion-icon class="icon-close alert-button-close icon-md icons-property" color="dark" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-img class="margin-left-base" src="assets/img/wallet-cashback.svg" slot="start"></ion-img>
    <ion-title class="ion-no-padding margin-left-small">
      <h2>Cashback Wallet</h2>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ctn-gt-space-p fw-list-guide">
  <div class="Cashback-Wallet-list">
    <div *ngIf="dataService.isMembershipApplied; else nonMemberGudelines" >
      <ul class="ion-padding-start f-w-n color-dark-300 text-size-md lh-base" *ngIf="dataService.firebaseMasterData?.MbUniversalWidgets?.widgets?.MbCashbackWalletGuideline?.MEMBERS?.length">
        <li *ngFor="let item of dataService.firebaseMasterData?.MbUniversalWidgets?.widgets?.MbCashbackWalletGuideline?.MEMBERS">{{item}}</li>
      </ul> 
    </div>
    <ng-template #nonMemberGudelines>
      <ul class="ion-padding-start f-w-n color-dark-300 text-size-md lh-base" *ngIf="dataService.firebaseMasterData?.MbUniversalWidgets?.widgets?.MbCashbackWalletGuideline?.NON_MEMBERS?.length">
        <li *ngFor="let item of dataService.firebaseMasterData?.MbUniversalWidgets?.widgets?.MbCashbackWalletGuideline?.NON_MEMBERS">{{item}}</li>
      </ul>
    </ng-template>
  </div>
</ion-content>
