//#region Imports

//#region Angular Imports
import { Injectable } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { HTTPResponse } from '@ionic-native/http';
import { ModalController, NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
import * as moment from 'moment';
import { Observable, empty, noop } from 'rxjs';
import { tap } from 'rxjs/operators';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { InfoPopupComponent } from '@Shared/info-popup/info-popup.component';
import { LowBalanceComponent } from '@Shared/payment/low-balance/low-balance.component';
import { ScheduleProductComponent } from '@Shared/product-listing/schedule-product/schedule-product.component';
import { InfoPopUpPaymentComponent } from '@Features/payment/info-pop-up/info-pop-up.component';
import { InviteComponent } from '@Features/payment/invite/invite.component';
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from '@Globals/providers/master-data.service';
import { DataService } from '@Globals/providers/data.service';
import { AuthService } from '@Globals/providers/auth.service';
import { AlertService } from '@Globals/providers/alert.service';
import { HttpService } from "@Globals/providers/http.service";
import { ModalService } from '@Globals/providers/modal/modal.service';
import { ToastService } from '@Globals/providers/toast.service';
import { EventsService } from '@Globals/providers/events.service';
import { BottomSheetService } from '@Globals/providers/bottom-sheet.service';
import { RecurringService } from '@Globals/providers/recurring.service';

import { ShoppingCreditsServices } from 'src/app/payment/providers/shopping-credits/shopping-credits.service';
//#endregion Providers Imports

//#region Other Imports
import { 
    A2B_API_ERRORS,
    EVENT_NAMES,
    EVENT_SOURCES,
    EVENT_ARGS,
    HOLIDAY_CUT_OFF_TIME_CONFIRMATION,
    NON_MBEYOND_USER_REDIRECTION,
    STORAGE_KEYS,
    GENERATE_CONTENT_FOR_ALERT_LAYER,
    ALERT_BUTTON_TEXT,
    LOGIN_SIGNUP_A2B_POPUP,
    OFFER_REMOVED,
    CASHBACK_REMOVED,
    OFFER_PASS_REMOVED,
    ITEM_TYPE_FOR_BUNDLE_PRODUCT,
    FROZEN_PRODUCT_DELIVERY_INFO,
    VENDOR_OFF,
    MONTH,
    WEEK,
    LOGIN_SIGNUP_CONFIRMATION,
    MAX_QUANTITY_REACHED_ALERT,
    EDIT_RECURRING_ALERT,
    DELETE_RECURRING_ALERT,
    RECURRING_SUCCESS_ALERT,
    SCHEDULE_SUCCESS_ALERT,
    CITY_MODAL_CSS_CLASS,
} from '@Globals/constants/listing/constants';
import {MODAL_IDS} from '@Globals/constants/payment/constants';
import { ScheduleType, RewardType } from '@Globals/constants/listing/enums';
import { LISTING_APIS, APIs } from "@Globals/constants/listing/apis";
import { LISTING_ROUTES, CORE_ROUTES } from "@Globals/constants/listing/routes";

import { Category, ScheduleProduct } from "@Globals/interfaces/listing/interfaces";

import { environment } from '@Env/environment';
import { SSPH_CHARGES_CONFIRMATION,NO_CHECKOUT } from '@App/listing/constants/listing-constants';
import { Utilities } from '@Globals/classes/utilities';
import { DISCOUNTED_PROUDUCT_OFFER_REMOVED } from '@App/shared/constants/constants';
//#endregion Other Imports

//#endregion Imports

@Injectable()
export class CartService { 

    public lowBalanceModelOpen = false;

    constructor (
        protected httpService: HttpService,
        protected eventsService: EventsService,
        protected masterDataService: MasterDataService,
        protected alertService: AlertService,
        protected dataService: DataService,
        protected shoppingCreditsServices: ShoppingCreditsServices,
        protected modalCtrl: ModalController,
        protected authService: AuthService,
        protected navCtrl: NavController,
        protected toastService: ToastService,
        protected modalService: ModalService,
        private recurringService: RecurringService,
        private bottomSheetService: BottomSheetService,
    ) {
    }

    addToBasket(dataToBeSent, basketIndex = -1, isPreorder = false): Observable<object> {
        if (this.dataService.firebaseMasterData.infoPopUp && this.dataService.firebaseMasterData.infoPopUp.location.addToBasket) {
          if (this.dataService.firebaseMasterData.infoPopUp.isThrottle) {
            if (this.masterDataService.masterData?.user?.throttle_status) {
              this.modalService.openModalWithCallback({
                component: InfoPopUpPaymentComponent,
                backdropDismiss: false,
                cssClass: CITY_MODAL_CSS_CLASS,
                componentProps: { source: EVENT_SOURCES.ADD_TO_BASKET }
              });
              return empty();
            }
          } else {
            this.modalService.openModalWithCallback({
              component: InfoPopUpPaymentComponent,
              backdropDismiss: false,
              cssClass: CITY_MODAL_CSS_CLASS,
              componentProps: { source: EVENT_SOURCES.ADD_TO_BASKET }
            });
            return empty();
          }
        }
        if (dataToBeSent.quantity && dataToBeSent.quantity < 0) {
          dataToBeSent.quantity = 0;
        }
        if (Object.keys(this.dataService.activeOffersObject).length) {
          dataToBeSent.offer_ids = [];
          // tslint:disable-next-line: forin
          for (const offer in this.dataService.activeOffersObject) {
            dataToBeSent.offer_ids.push(+offer);
          }
        }
        dataToBeSent.version = environment.codePushVersion;
        return this.httpService.post(LISTING_APIS.ADD_TO_BASKET, dataToBeSent).pipe(tap((response: HTTPResponse) => {
          if (response.data.status) {
            const eventArgs = {
              ...response.data.data[ 0 ].bill_details,
              ...response.data.data[ 0 ].extra_fee,
              productIds: ''
            };
            if (this.masterDataService.masterData?.user && !('last_order_date' in this.masterDataService.masterData?.user) && (!this.masterDataService.firstAtbSucces) && (this.masterDataService?.baskets?.length && this.masterDataService?.baskets[0]?.quantity === 0)) {
              this.alertService.presentAlertConfirm(
                GENERATE_CONTENT_FOR_ALERT_LAYER(
                  NO_CHECKOUT.META_DATA
                ),
                [
                  NO_CHECKOUT.BUTTONS.CONFIRM
                ],
                NO_CHECKOUT.CSS_CLASS
              );
              this.masterDataService.firstAtbSucces = true;
              localStorage.setItem(STORAGE_KEYS.FIRST_ATB_SUCESS, 'true');
            }
            response.data.data[ 0 ].products.forEach(product => eventArgs.productIds += product.id + ',');
            this.eventsService.trackEvents(EVENT_NAMES.CART, eventArgs);
            let spliceIndex = 0;
            // Updated existing master data baskets object with recently changed date's basket
            (basketIndex === -1) && (basketIndex = this.masterDataService.baskets?.findIndex((basket) => {
              (moment(basket.date) < moment(response.data.data[ 0 ].date)) && spliceIndex++;
              return basket.date === response.data.data[ 0 ].date;
            }));
            if (basketIndex > -1) {
              if (response.data.data[ 0 ].products.length > 0 || basketIndex === 0) {
                this.masterDataService.baskets[ basketIndex ] = response.data.data[ 0 ];
              } else {
                this.masterDataService.baskets?.splice(basketIndex, 1);
              }
            } else {
              this.masterDataService.baskets?.splice(spliceIndex, 0, response.data.data[ 0 ]);
            }
            if (isPreorder) {
              // Update preordered object as well
              const preorderBasketIndex = this.masterDataService.preorderedBaskets.findIndex((preorderBasket) => {
                return preorderBasket.date === response.data.data[ 0 ].date;
              });
              if (preorderBasketIndex > -1) {
                this.masterDataService.preorderedBaskets[ preorderBasketIndex ] = response.data.data[ 0 ];
              } else {
                this.masterDataService.preorderedBaskets.push(response.data.data[ 0 ]);
              }
            }
            if (response.data.offers_applied && response.data.offers_applied.length) {
              this.dataService.backButtonOfferHandling = true;
              setTimeout(() => {
                this.dataService.showOfferAppliedState(response.data.offers_applied, environment.productPath);
              })
            } else if (response.data.offers_removed && response.data.offers_removed.length) {
              this.dataService.backButtonOfferHandling = false;
              setTimeout(() => {
                for (const offer of response.data.offers_removed) {
                  const eventArgs = {
                    'Offer id': offer.offer_id,
                    'Offer type': offer.offer_type,
                    'Reward type': offer.benefit_type
                  };
                  if (offer.benefit_type === RewardType.Sample) {
                    this.toastService.presentToast(OFFER_REMOVED.message, OFFER_REMOVED.class, OFFER_REMOVED.position);
                  } else if (offer.benefit_type === RewardType.Cashback) {
                    this.toastService.presentToast(CASHBACK_REMOVED.message, CASHBACK_REMOVED.class, CASHBACK_REMOVED.position);
                  }else if (offer.benefit_type === RewardType.DiscountedProduct) {
                    this.toastService.presentToast(DISCOUNTED_PROUDUCT_OFFER_REMOVED.message, DISCOUNTED_PROUDUCT_OFFER_REMOVED.class, DISCOUNTED_PROUDUCT_OFFER_REMOVED.position);
                  } else {
                    this.toastService.presentToast(OFFER_PASS_REMOVED.message, OFFER_PASS_REMOVED.class, OFFER_PASS_REMOVED.position);
                  }
                  this.eventsService.trackEvents(EVENT_NAMES.SAMPLE_REMOVED, eventArgs);
                }
              })
            }
          }
        }));
    }

    trackBasketEvent(
        eventName,
        product,
        date,
        eventSource,
        index,
        status,
        addBasketType,
        itemType: string,
        isParentId = false,
        eventData?,
        filterName?: string,
        addedBasketValue?: HTTPResponse
      ) {
        let selectedCategory: Category;
        let selectedSubcategoryName: string;
        if (!product.subcategory || !product.category) {
          selectedCategory = this.masterDataService.categories?.find(
            category => {
              return category.id === product.cid;
            }
          );
          if (selectedCategory) {
            const selectedSubcategory = selectedCategory.subcategories.find(
              subCategory => {
                return subCategory.id === product?.scid;
              }
            );
            selectedSubcategoryName =
              (selectedSubcategory && selectedSubcategory.name) || '';
          }
        }
        if (product.prop) {
          itemType = ITEM_TYPE_FOR_BUNDLE_PRODUCT[ product.prop.bundle_view ] || itemType;
        }
        let eventArgs: any = {
          'Product id': product.product_id || product.id,
          'Product weight': product.wgt || product.weight,
          Category: product.category || (selectedCategory && selectedCategory.name),
          SubCategory: product.subcategory || selectedSubcategoryName,
          Price: this.checkTypeAndReturn(product?.sp)  || this.checkTypeAndReturn(product?.price),
          Date: date,
          Source: eventSource,
          'src':eventSource,
          'Product Index': index + 1,
          'Substitute segment': product.segment || '',
          status,
          subcategory_type: product.subcategory_type || '',
          type: addBasketType,
          item_type: itemType || '',
          'Filter Name': filterName || '',
          'Total basket value': new Date(date) > new Date(new Date().getTime() + 24 * 60 * 60 * 1000) ? addedBasketValue?.data[ 0 ][ 'total' ] : (this.masterDataService.baskets?.[ 0 ]?.bill_details?.sub_total - this.masterDataService.baskets?.[ 0 ]?.bill_details?.sub_savings) || 0,
          'hub_id':this.masterDataService.masterData?.user?.hubId,
          'MRP':  this.checkTypeAndReturn( product?.mrp)
        };
        if (product?.variantId) {
          eventArgs.variant_id = product.variantId;
        }
        if (isParentId) {
          eventArgs.parent_product_id = this.dataService.parentProductId;
        }
        if (!itemType) {
          delete eventArgs.item_type;
        }
        if(eventData?.leaf_id) {
          eventArgs.leaf_id = eventData.leaf_id;
        }
        if(eventData?.one_pager_id) {
          eventArgs = {...eventArgs, ...{
            collection_id: +eventData.collection_id,
            collection_name: eventData.collection_title || eventData.name,
            one_pager_id: eventData.one_pager_id,
            one_pager_name:eventData.one_pager_name,
            'Sort order': eventData.sortOrder,
            'Display position' : eventData.displayPosition,
            Source: eventData.source,
          }};
        } else if(eventData?.collection_id) {
          eventArgs.collection_id = +eventData.collection_id;
        } else if(eventData?.brand_id) {
          eventArgs.brand_id = eventData.brand_id;
          eventArgs.type = eventData.type;
        } else if(eventData?.widget_name) {
          eventArgs.widget_name = eventData.widget_name;
          eventArgs.type = eventData.type;
          eventArgs.Source = eventArgs.Source || eventData.source;
        } else if(eventData?.sponsored_list_id) {
          eventArgs.sponsored_list_id = eventData.sponsored_list_id;
        } else if(eventData?.offer_id) {
          eventArgs.offer_id = eventData.offer_id;
          eventArgs.type = eventData.type;
          eventArgs.Source = eventData.source;
        } else if(eventData?.banner_id) {
          eventArgs.banner_id = eventData.banner_id;
          eventArgs.banner_index = eventData.banner_index;
          eventArgs.Source = eventData.source;
        } else if(eventData?.keyword) {
          eventArgs = {...eventArgs, ...eventData};
        } else if(eventData?.pdp) {
          eventArgs.Source = eventData.Source;
        } else if(eventData?.product_launch) {
          eventArgs.Source = eventData?.product_launch;
        }

        this.eventsService.trackEvents(eventName, eventArgs);
    }

    showFrozenProductDeliveryInfo(orderDate) {
        orderDate = new Date(orderDate);
        const date = `${orderDate.getDate()} ${MONTH[ orderDate.getMonth() ]}`;
        (moment(orderDate).diff(moment().startOf('day'), 'days') > 1) && (this.dataService.firebaseMasterData.frozenProductA2BMetaData.dateInfo =
          WEEK[ orderDate.getDay() ]);
        this.dataService.showFrozenProductA2BAlert = false;
        this.dataService.firebaseMasterData.frozenProductA2BMetaData.date = date;
        FROZEN_PRODUCT_DELIVERY_INFO.META_DATA.MESSAGE = this.dataService.firebaseMasterData.frozenProductA2BData.message || '';
        FROZEN_PRODUCT_DELIVERY_INFO.BUTTONS.OKAY.text = this.dataService.firebaseMasterData.frozenProductA2BData.ctaLabel;
        FROZEN_PRODUCT_DELIVERY_INFO.BUTTONS.OKAY.handler = () => {
          localStorage.setItem(STORAGE_KEYS.SHOW_FROZEN_PRODUCT_A2B_ALERT, 'false');
        };
        const frozenProductDeliveryContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
          FROZEN_PRODUCT_DELIVERY_INFO.META_DATA,
          this.dataService.firebaseMasterData.frozenProductA2BMetaData
        );
        this.alertService.presentAlertConfirm(
          frozenProductDeliveryContent,
          [
            FROZEN_PRODUCT_DELIVERY_INFO.BUTTONS.OKAY
          ],
          FROZEN_PRODUCT_DELIVERY_INFO.CSS_CLASS
        );
    }

    trackBasketErrorEvent(eventName, response, product, isAdded, eventSource, index, dataToBeSent) {
        let errorReason: string;
        if (response.data.error === A2B_API_ERRORS.SCHEDULE_NOT_ALLOWED) {
          errorReason = EVENT_ARGS.SCHEDULED;
        } else {
          errorReason = isAdded ? 'add' : 'subtract';
        }
        const eventArgs: any = {
          code: response.data.error,
          reason: errorReason,
          'Product id': product.product_id|| product.id,
          Source: eventSource,
          'src':eventSource,
          index: index + 1,
          Price: this.checkTypeAndReturn(product?.sp) || this.checkTypeAndReturn(product?.price),
          'hub_id':this.masterDataService.masterData?.user?.hubId,
          'tomorrow_date': this.masterDataService?.baskets?.[0]?.date,
          'order_date':dataToBeSent?.date,
          'MRP':this.checkTypeAndReturn(product?.mrp)
        };
        if (response.data.error === A2B_API_ERRORS.INSUFFICIENT_CREDITS) {
          eventArgs.source = EVENT_SOURCES.PAYMENT_POPUP_VIEWED;
        }
        this.eventsService.trackEvents(eventName, eventArgs);
    }

    checkTypeAndReturn(value) {
      if(value) {
        return (typeof value === 'string') ? value : value.toFixed(2);
      }
      return null;
    }

    showVendorOffToast(response, okayHandler) {
        VENDOR_OFF.BUTTONS.OKAY.handler = okayHandler;
        VENDOR_OFF.BUTTONS.CLOSE.handler = noop();
        VENDOR_OFF.META_DATA.MESSAGE =
          response.data.error_msg;
        const vendorOffContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
          VENDOR_OFF.META_DATA,
          { errorDate: response.data.errorData[ 0 ], nextDate: response.data.errorData[ 1 ] }
        );
        this.alertService.presentAlertConfirm(
          vendorOffContent,
          [
            VENDOR_OFF.BUTTONS.OKAY,
            VENDOR_OFF.BUTTONS.CLOSE
          ],
          VENDOR_OFF.CSS_CLASS
        );
    }

    async openLowBalanceModel(payment, errorMsg, callbackFunction) {
        const modal = await this.modalCtrl.create({
          component: LowBalanceComponent,
          cssClass: 'modal-bottom-sheet low-balance-bottom-sheet',
          breakpoints: this.masterDataService.isJioPayEnabled ? [ 0, 0.5, 0.75 ]:  [ 0, 0.75, 0.9 ],
          initialBreakpoint: this.masterDataService.isJioPayEnabled ? 0.5 : 0.75,
          handle: false,
          componentProps: { payment, errorMsg },
          id: MODAL_IDS.LOW_BALANCE_POPUP
        });
        if (!this.lowBalanceModelOpen) {
          await modal.present();
          this.lowBalanceModelOpen = true;
        }
        modal.onDidDismiss().then(res => {
          this.lowBalanceModelOpen = false;
          if( res && res.data) {
            if(this.masterDataService.isJioPayEnabled) {
              this.shoppingCreditsServices.openPaymentOptionsModalJio(res.data.amount, callbackFunction);
            } else {
              this.shoppingCreditsServices.transactionProcess(
                res.data,
                callbackFunction
              );
            }
          }
        });
    }

    checkProductDailyLimitAndOpenScheduleModal(productDetails, eventSource, productIndex, itemType, appliedFilter?, componentType = ScheduleType.PDP, selectedDate?, checkIfProductIsPreordered?) {
        this.authService.isLoggedIn() && this.getProductDailyLimit(productDetails).subscribe((response: HTTPResponse) => {
          productDetails.limitExceededDates = response.data.data || [];
          this.openScheduleProductModal(productDetails, eventSource, productIndex, itemType, appliedFilter, componentType, selectedDate, checkIfProductIsPreordered);
        });
    }

    getProductDailyLimit(product): Observable<object> {
        return this.httpService.post(LISTING_APIS.PRODUCT_DAILY_LIMIT, {
          product_id: product.id,
          start_delivery_date: product.startDate,
          end_delivery_date: product.endDate,
          hub_id: this.masterDataService.userHub
        });
    }

    async openScheduleProductModal(productDetails, eventSource, productIndex, itemType, appliedFilter?, componentType = ScheduleType.PDP, selectedDate?, checkIfProductIsPreordered?) {
        if (this.authService.isLoggedIn()) {
          const modal = await this.modalCtrl.create({
            component: ScheduleProductComponent,
            cssClass: selectedDate ? 'modal-custom modal-schedule-product  pre-modal' : 'modal-custom modal-schedule-product',
            componentProps: {
              productDetails,
              selectedDate,
              componentType
            }
          });
          await modal.present();
          const { data } = await modal.onDidDismiss();
          if (data) {
            this.scheduleProduct(data, productDetails, eventSource, productIndex, itemType, checkIfProductIsPreordered, appliedFilter);
          }
        } else {
          LOGIN_SIGNUP_CONFIRMATION.BUTTONS.OKAY.handler = () => {
            this.modalCtrl.dismiss();
            this.navCtrl.navigateRoot(CORE_ROUTES.BOARDING);
          };
          LOGIN_SIGNUP_CONFIRMATION.BUTTONS.CLOSE.handler = noop();
          const pastCutOffTimeContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
            LOGIN_SIGNUP_CONFIRMATION.META_DATA
          );
          this.alertService.presentAlertConfirm(
            pastCutOffTimeContent,
            [
              LOGIN_SIGNUP_CONFIRMATION.BUTTONS.OKAY,
              LOGIN_SIGNUP_CONFIRMATION.BUTTONS.CLOSE
            ],
            LOGIN_SIGNUP_CONFIRMATION.CSS_CLASS
          );
        }
    }

    scheduleProduct(dataToSend: ScheduleProduct, productDetails, eventSource, productIndex, itemType, callback = null, checkIfProductIsPreordered?, appliedFilter?) {
        if (this.authService.isLoggedIn()) {
          this.addToBasket(dataToSend, -1, !!checkIfProductIsPreordered).subscribe((success: any) => {
            if (success && success.data && success.data.status) {
              this.trackBasketEvent(
                EVENT_NAMES.ADD_TO_BASKET,
                productDetails,
                dataToSend.date,
                eventSource,
                productIndex,
                success.data.status,
                EVENT_NAMES.SCHEDULE,
                itemType,
                (itemType === EVENT_ARGS.RELATED || itemType === EVENT_ARGS.SIMILAR),
                null,
                appliedFilter
              );
              checkIfProductIsPreordered && checkIfProductIsPreordered(productDetails);
              this.dataService.knockKnock.next(productDetails);
              if (productDetails.hasOwnProperty('addedForRecurring')) {
                if (productDetails.hasOwnProperty('hideAlert')) {
                  this.recurringService.removeOnceplanSuccess.next(true);
                } else {
                  this.recurringService.updateRecurringOrders.next(true);
                  if (productDetails.hasOwnProperty('isEdit') && dataToSend.quantity !== 0) {
                    EDIT_RECURRING_ALERT.BUTTONS.CONFIRM.handler = () => {
                      callback();
                    };
                    const recurringEditAlert = GENERATE_CONTENT_FOR_ALERT_LAYER(
                      EDIT_RECURRING_ALERT.META_DATA,
                      { date: moment(this.getDate(dataToSend.date)).format('DD.MM.YY') }
                    );
                    this.alertService.presentAlertConfirm(recurringEditAlert, [
                      EDIT_RECURRING_ALERT.BUTTONS.CONFIRM
                    ], 'alert-custom has-full-height recurring-alert create-calendar');
                  } else if (dataToSend.quantity === 0) {
                    DELETE_RECURRING_ALERT.BUTTONS.CONFIRM.handler = () => {
                      callback();
                    };
                    const recurringDeleteAlert = GENERATE_CONTENT_FOR_ALERT_LAYER(
                      DELETE_RECURRING_ALERT.META_DATA,
                      { date: moment(this.getDate(dataToSend.date)).format('DD.MM.YY') }
                    );
                    this.alertService.presentAlertConfirm(recurringDeleteAlert, [
                      DELETE_RECURRING_ALERT.BUTTONS.CONFIRM
                    ], 'alert-custom has-full-height recurring-alert create-calendar');
                  } else {
                    if (this.dataService.recRecommendationObject.hasOwnProperty(dataToSend.product_id)) {
                      this.recurringService.updateRecurringObjects(dataToSend.product_id);
                    }
                    RECURRING_SUCCESS_ALERT.BUTTONS.CONFIRM.handler = () => {
                      callback();
                    };
                    const recurringSuccessAlert = GENERATE_CONTENT_FOR_ALERT_LAYER(
                      RECURRING_SUCCESS_ALERT.META_DATA,
                      { heading: `You will receive <br>` + 'your order on ' + moment(dataToSend.date).format('DD MMM, YYYY') }
                    );
                    this.alertService.presentAlertConfirm(recurringSuccessAlert, [
                      RECURRING_SUCCESS_ALERT.BUTTONS.CONFIRM
                    ], 'alert-custom has-full-height recurring-alert');
                  }
                }
              } else {
                SCHEDULE_SUCCESS_ALERT.BUTTONS.OKAY.handler = noop();
                SCHEDULE_SUCCESS_ALERT.BUTTONS.CLOSE.handler = noop();
                const scheduleSuccessAlert = GENERATE_CONTENT_FOR_ALERT_LAYER(
                  SCHEDULE_SUCCESS_ALERT.META_DATA,
                  { orderDate: moment(dataToSend.date).format('Do MMMM YYYY') }
                );
                this.alertService.presentAlertConfirm(
                  scheduleSuccessAlert,
                  [
                    SCHEDULE_SUCCESS_ALERT.BUTTONS.OKAY,
                    SCHEDULE_SUCCESS_ALERT.BUTTONS.CLOSE
                  ],
                  SCHEDULE_SUCCESS_ALERT.CSS_CLASS
                );
              }
            } else {
              this.trackBasketErrorEvent(
                EVENT_NAMES.ADD_BASKET_ERROR,
                success,
                productDetails,
                true,
                eventSource,
                productIndex,
                dataToSend
              );
              if (
                success.data.error === A2B_API_ERRORS.INSUFFICIENT_CREDITS_UNPAID &&
                !this.dataService.doesUserBelongToBulkBuyHub
              ) {
                this.modalService.openModal({
                  component: InviteComponent,
                  backdropDismiss: false,
                  cssClass: 'invite-modal',
                  componentProps: { source: EVENT_SOURCES.ADD_TO_BASKET }
                });
              } else if (success.data.error === A2B_API_ERRORS.HOLIDAY_OFF) {
                HOLIDAY_CUT_OFF_TIME_CONFIRMATION.BUTTONS.OKAY.handler = () => {
                  dataToSend.date = success.data.error_next_date;
                  this.scheduleProduct(dataToSend, productDetails, eventSource, productIndex, itemType, null, appliedFilter);
                };
                HOLIDAY_CUT_OFF_TIME_CONFIRMATION.BUTTONS.CLOSE.handler = noop();
                HOLIDAY_CUT_OFF_TIME_CONFIRMATION.META_DATA.MESSAGE =
                  success.data.error_msg;
                const holidayOffTimeContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
                  HOLIDAY_CUT_OFF_TIME_CONFIRMATION.META_DATA,
                  { date: success.data.error_next_date }
                );
                this.alertService.presentAlertConfirm(
                  holidayOffTimeContent,
                  [
                    HOLIDAY_CUT_OFF_TIME_CONFIRMATION.BUTTONS.OKAY,
                    HOLIDAY_CUT_OFF_TIME_CONFIRMATION.BUTTONS.CLOSE
                  ],
                  HOLIDAY_CUT_OFF_TIME_CONFIRMATION.CSS_CLASS
                );
              } else if (success.data.error === A2B_API_ERRORS.VENDOR_OFF) {
                this.showVendorOffToast(success, () => {
                  dataToSend.date = success.data.error_next_date;
                  this.scheduleProduct(dataToSend, productDetails, eventSource, productIndex, itemType, null, appliedFilter);
                });
              } else if (
                success.data.error === A2B_API_ERRORS.MAX_QUANTITY_EXCEEDED
              ) {
                this.showMaxQuantityAlert();
              } else if (
                success.data.error === A2B_API_ERRORS.ORDER_LIMIT_REACHED
              ) {
                this.showOrderLimitReachedAlert(success.data.errorData);
              } else if (
                success.data.error === A2B_API_ERRORS.NOT_INSERVICEABLE_AREA
              ) {
                this.alertService.presentAlert(
                  'Oops! We currently do not deliver in your area.',
                  [ ALERT_BUTTON_TEXT ],
                  ''
                );
              } else if (
                success.data.error === A2B_API_ERRORS.MBEYOND_ONLY_PRODUCT
              ) {
                NON_MBEYOND_USER_REDIRECTION.BUTTONS.OKAY.handler = () => {
                  this.modalCtrl.dismiss();
                  this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.M_BEYOND);
                };
                NON_MBEYOND_USER_REDIRECTION.META_DATA.MESSAGE = success.data.error_msg;
                const nonMbeyondUserErrorContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
                  NON_MBEYOND_USER_REDIRECTION.META_DATA
                );
                this.alertService.presentAlertConfirm(
                  nonMbeyondUserErrorContent,
                  [
                    NON_MBEYOND_USER_REDIRECTION.BUTTONS.OKAY
                  ]
                );
              } else {
                this.toastService.presentToast(success.data.error_msg);
              }
            }
          });
        } else {
          if(!Utilities.isApp() && this.masterDataService.navigateFromDeeplink && Utilities.isMobileBrowser()) {
            this.masterDataService.getRedirectionToPlayStoreOrAppStore();   
          } else {
            this.bottomSheetService.openLoginBottomSheet(InfoPopupComponent, [0, 0.45, 0.50, 1], 0.50, LOGIN_SIGNUP_A2B_POPUP);
          }
        }
    }

    openSSPHConfirmationAlert(isOptInAlert) {
      this.eventsService.trackEvents(isOptInAlert ? EVENT_NAMES.SSPH_CHARGES_OPT_IN_MODAL_VIEWED : EVENT_NAMES.SSPH_CHARGES_MODAL_VIEWED, {});
      SSPH_CHARGES_CONFIRMATION.META_DATA.MESSAGE = isOptInAlert ? this.dataService.firebaseMasterData.ssph_config.optInAlertMessage : this.dataService.firebaseMasterData.ssph_config.alertMessage;
      SSPH_CHARGES_CONFIRMATION.BUTTONS.CONFIRM.handler = () => {
        const dataToSend = {
          basket_id: this.masterDataService.baskets?.[ 0 ]?.basket_id,
          is_covid_fee: isOptInAlert ? 1 : 0
        };
        !isOptInAlert && this.navCtrl.back();
        this.covidFeeOptInOptOut(dataToSend).subscribe((response: HTTPResponse) => {
          if (response.data.bill_details) {
            this.masterDataService.baskets[ 0 ].bill_details = response.data.bill_details;
            this.masterDataService.baskets[ 0 ].total = response.data.bill_details.total;
            this.masterDataService.baskets[ 0 ].is_covid_fee = dataToSend.is_covid_fee;
            this.masterDataService.baskets[ 0 ].is_opted = dataToSend.is_covid_fee;
            this.trackSSPHAlertCloseEvent(isOptInAlert, SSPH_CHARGES_CONFIRMATION.BUTTONS.CONFIRM.text);
          }
        });
      };
      SSPH_CHARGES_CONFIRMATION.BUTTONS.CANCEL.handler = () => {
        !isOptInAlert && this.navCtrl.back();
        this.trackSSPHAlertCloseEvent(isOptInAlert, SSPH_CHARGES_CONFIRMATION.BUTTONS.CANCEL.text);
      };
      this.alertService.presentAlertConfirm(
        GENERATE_CONTENT_FOR_ALERT_LAYER(SSPH_CHARGES_CONFIRMATION.META_DATA),
        [ SSPH_CHARGES_CONFIRMATION.BUTTONS.CANCEL, SSPH_CHARGES_CONFIRMATION.BUTTONS.CONFIRM ],
        SSPH_CHARGES_CONFIRMATION.CSS_CLASS,
        SSPH_CHARGES_CONFIRMATION.BACKDROP_DISMISS
      );
    }

    trackSSPHAlertCloseEvent = (isOptInAlert, action) => {
      this.eventsService.trackEvents(isOptInAlert ? EVENT_NAMES.SSPH_CHARGES_OPT_IN_MODAL_CLOSED : EVENT_NAMES.SSPH_CHARGES_MODAL_CLOSED, { action });
    };

    covidFeeOptInOptOut(dataToBeSend): Observable<object> {
      return this.httpService.post(LISTING_APIS.OPTIN_OPTOUT_COVID_FEE, dataToBeSend);
    }

    getMonthBaskets(hideLoader): Observable<object> {
      const dataToBeSent: any = {
        period: 'Month'
      };
      hideLoader && (dataToBeSent.hideLoader = hideLoader);
      return this.httpService.post(APIs.BASKETS, dataToBeSent);
    }
  
    showMaxQuantityAlert() {
        const maxQuantityReachedContent = GENERATE_CONTENT_FOR_ALERT_LAYER(
          MAX_QUANTITY_REACHED_ALERT.META_DATA
        );
        this.alertService.presentAlert(
          maxQuantityReachedContent,
          MAX_QUANTITY_REACHED_ALERT.BUTTONS,
          MAX_QUANTITY_REACHED_ALERT.CSS_CLASS
        );
    }
    
    addVoucherToBasket(voucherId) {
      const dataToBeSent = {
        date: this.masterDataService.baskets?.length &&
          this.masterDataService.baskets[ 0 ]?.date || '',
        get_product_check: true,
        product_id: voucherId,
        quantity: 1
      };
      this.addToBasket(dataToBeSent).subscribe((response: HTTPResponse) => {
        if (response.data.status) {
          const eventArgs = {
            'Product id': voucherId,
            Source: EVENT_SOURCES.YOUR_CART
          };
          this.eventsService.trackEvents(EVENT_NAMES.ADD_TO_BASKET, eventArgs);
          this.dataService.knockKnock.next(true);
        } else {
          this.trackBasketErrorEvent(EVENT_NAMES.ADD_BASKET_ERROR, response, voucherId, true, EVENT_SOURCES.YOUR_CART, 0, dataToBeSent);
          this.alertService.presentAlert(
            response.data.error_msg,
            [ ALERT_BUTTON_TEXT ],
            ''
          );
        }
      });
    }

    getDate(dateSent) {
        if (moment(dateSent).diff(moment(new Date()), 'days') <= 0) {
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() + 1);
          dateSent = currentDate;
        }
        return dateSent;
    }

    showOrderLimitReachedAlert(errorData) {
        // errorData array will either have amount_{value} or quantity_{value}
        if (errorData && errorData.length) {
          const reasonKeyValue = errorData[ 0 ].replace(/\.\d+/, '').split('_');
          this.dataService.firebaseMasterData.orderLimitReachedAlertData.META_DATA.HEADER_TEXT = this.dataService.firebaseMasterData.orderLimitReachedAlertData.HEADER_TEXTS[ reasonKeyValue[ 0 ] ];
          this.dataService.firebaseMasterData.orderLimitReachedAlertData.META_DATA.MESSAGE = this.dataService.firebaseMasterData.orderLimitReachedAlertData.MESSAGES[ reasonKeyValue[ 0 ] ];
          const contentKeysToBeReplaced = {};
          contentKeysToBeReplaced[reasonKeyValue[0]] = reasonKeyValue[1];
          this.alertService.presentAlert(
            GENERATE_CONTENT_FOR_ALERT_LAYER(this.dataService.firebaseMasterData.orderLimitReachedAlertData.META_DATA, contentKeysToBeReplaced),
            this.dataService.firebaseMasterData.orderLimitReachedAlertData.BUTTONS,
            this.dataService.firebaseMasterData.orderLimitReachedAlertData.CSS_CLASS
          );
        }
      }

  getTomorrowBasket(): Observable<object> {
    return this.httpService.get(LISTING_APIS.TOMORROW_BASKET, { hideLoader: true });
  }
  
  buyMbPass(dataToSend): Observable<object> {
    return this.httpService.post(LISTING_APIS.BUY_MB_PASS, dataToSend);
  }

  getPassHistory(): Observable<object> {
    return this.httpService.get(LISTING_APIS.MB_PASS_HISTORY);
  }

  getOrderCount(){
    return this.httpService.get(LISTING_APIS.GET_ORDER_COUNT);
  }

  updateNocheckoutStatus(status){
    return this.httpService.put(LISTING_APIS.UPDATE_NOCHECKOUT_STATUS,status);
  }

  membershipRemovalCheck(dataToSend={}){
    return this.httpService.post(LISTING_APIS.MEMBERSHIP_REMOVAL_CHECK,dataToSend);
  }

  getBasketSubcat(dataToSend={}): Observable<object> {
    return this.httpService.post(APIs.BASKET_SUBCATEGORIES, dataToSend);
  }

  getImpulseProducts(dataToSend={}): Observable<object> {
    return this.httpService.post(APIs.HOME_IMPULSE_PRODUCTS, {})
  }

  offerBasedNavigation(offerDetail, offerId = null) {
    if (offerId) {
      offerDetail = this.dataService.activeOffersObject[ offerId ];
    }
    const eventArgs  = {
      offer_id: offerDetail.id,
      type: 'sample_offer',
      Source: EVENT_SOURCES.YOUR_CART
    };
    this.eventsService.trackEvents(EVENT_NAMES.SAMPLE_OFFER_CLICKED, eventArgs);
    switch (offerDetail.type) {
      case 1:
        this.masterDataService.selectedCategory = this.masterDataService.getCategoryDetails(offerDetail.offerDetails.typeIds[ 0 ]);
        this.masterDataService.selectedSubcategoryIndex = 0;
        this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.CATEGORY +
          this.masterDataService.selectedCategory.id + '/' + EVENT_SOURCES.SAMPLE_OFFER_CART, { animated: false });
        break;
      case 2:
        this.masterDataService.getSelectedIndexes(offerDetail.offerDetails.typeIds[ 0 ]);
        this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.SUBCATEGORY +
          offerDetail.offerDetails.typeIds[ 0 ] + '/' + EVENT_SOURCES.SAMPLE_OFFER_CART, { animated: false });
        break;
      case 3:
      case 5:
        this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.OFFER_COLLECTION_PLP + offerDetail.offerDetails.listId + '/' + offerDetail.id + '/' + EVENT_SOURCES.SAMPLE_OFFER_CART, { animated: false });
        break;
    }
  }
}