//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Input, Output } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-count-down-timer',
  templateUrl: './count-down-timer.component.html',
  styleUrls: ['./count-down-timer.component.scss'],
})
export class CountDownTimerComponent extends DefaultComponent {
  @Input() progress: number = 60;
  @Output() updateTimer = new EventEmitter();
  intervalId: any;
  constructor() {
    super();
  }

  mbOnInit() {
    this.intervalId = setInterval(() => {
      if (this.progress > 0) {
        this.progress = this.progress - 1;
        this.updateTimer.emit(this.progress);
      } else {
        this.clearIntervalTimer();
      }
    }, 1000);
  }

  clearIntervalTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  mbOnDestroy(): void {
    this.clearIntervalTimer();
  }
}
