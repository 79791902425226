
//#region Imports

//#region Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultRoutingModule } from '@Lib/defaults/default-routing.module';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthGuardService } from '@Globals/providers/auth-guard/auth-guard.service';
//#endregion Providers Imports

//#region Other Imports
import { CORE_ROUTES } from '@Globals/constants/listing/routes';
import { PAYMENT_ROUTES } from '@Globals/constants/payment/routes';
import { REACT_WIDGET_MAP } from '@Globals/constants/react/widget-map.constants';
//#endregion Other Imports

//#endregion Imports

export const paymentRoutes: Routes = [
  {
    path: CORE_ROUTES.SHOPPING_CREDIT,
    data: { componentName: REACT_WIDGET_MAP.SHOPPING_CREDIT },
    loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    //loadChildren: () => import('@Main/pages/payment/shopping-credits/shopping-credits.module').then(m => m.ShoppingCreditsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.PAYMENT + '/' + CORE_ROUTES.SHOPPING_CREDIT,
    data: { componentName: REACT_WIDGET_MAP.SHOPPING_CREDIT },
    loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    // loadChildren: () => import('@Main/pages/payment/shopping-credits/shopping-credits.module').then(m => m.ShoppingCreditsModule),
    canActivate: [AuthGuardService]
  },

  {
    path: CORE_ROUTES.PAYMENT + '/' + CORE_ROUTES.PAYMENT_METHOD,
    data: { componentName: REACT_WIDGET_MAP.PAYMENT_METHOD },
    loadChildren: () => import('../../../main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.PAYMENT,
    loadChildren: () => import('@Main/pages/payment/shopping-credits/shopping-credits.module').then(m => m.ShoppingCreditsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.PAYMENT + '/' + PAYMENT_ROUTES.AUTO_DEBIT,
    loadChildren: () => import('@Main/pages/payment/auto-debit/auto-debit-module').then(m => m.AutoDebitModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.PAYMENT + '/' + PAYMENT_ROUTES.JIO_CASHBACK,
    loadChildren: () => import('@Main/pages/payment/jio-cashback/jio-cashback-module').then(m => m.JioCashbackModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.PAYMENT + '/' + PAYMENT_ROUTES.OFFERS,
    loadChildren: () => import('@Main/pages/payment/payment-offers/payment-offers.module').then(m => m.PaymentOffersModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.PAYMENT + '/' + PAYMENT_ROUTES.TRANSACTION_HISTORY,
    data: { componentName: REACT_WIDGET_MAP.TRANSACTION_HISTORY },
    loadChildren: () => import('@Main/widgets/react-renderer/react-renderer.module').then(m => m.ReactRendererModule),
    canActivate: [AuthGuardService]
  },
  {
    path: CORE_ROUTES.PAYMENT + '/' + PAYMENT_ROUTES.COMPLETE,
    loadChildren: () => import('@Main/pages/payment/payment-complete/payment-complete.module').then(m => m.PaymentCompleteModule),
    canActivate: [AuthGuardService]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(paymentRoutes)],
  exports: [RouterModule]
})
export class PaymentRoutingModule extends DefaultRoutingModule  { }