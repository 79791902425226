export class CommunicationFlag {
    __typename = 'CommunicationFlag';
    email: boolean; // was is_email
    push: boolean;  // was is_push
    sms: boolean;  // was is_sms
    whatsapp: boolean;  // was is_whatsapp

    setCommunicationFlagFromApi(userApi) {
        try {
            this.email = !!userApi?.is_email;
            this.push = !!userApi?.is_push;
            this.sms = !!userApi?.is_sms;
            this.whatsapp = !!userApi?.is_whatsapp;
            return this;
        } catch {
            return null;
        }
    }
}
