
  
  <ng-container *ngIf="masterDataService.masterData?.welcome_code?.detail else offerInfo">
    <ion-item class="offer-item ion-margin-top" lines="none">
    <img alt="offer welcome code" class="offer-icon self-top" slot="start" src="assets/icon/offer.svg" />
    <ion-label class="ion-text-wrap">
      <h2 class="f-w-b ion-text-uppercase">{{ masterDataService.masterData.welcome_code.detail?.code }}</h2>
      <p class="text-ellipsis-ml two-line">{{ masterDataService.masterData.welcome_code.detail?.shortDescription }}</p>
      <ion-button class="text-size-sm f-w-s-b promo-know-more" (click)="openOfferDetail(promoCode)">
        Know More
        <ion-icon slot="end" color="primary" class="icon-arrow-right icons-property"></ion-icon>
      </ion-button>
    </ion-label>
    <ion-button 
    *ngIf="masterDataService.masterData.welcome_code.detail?.isRemovable"
    slot="end" type="button" fill="clear" 
    color="primary"
    (click)="deleteInviteCode()">Remove</ion-button>
  </ion-item>
  </ng-container>
  
  <ng-template #offerInfo>
    <ng-container *ngIf="!masterDataService.masterData?.user?.paid">
      <ng-container *ngIf="(dataService.inviteCodes && dataService.inviteCodes.length); else noInviteCodes">
      <ion-item class="offer-item ion-margin-top" lines="none">
      <img alt="welcome code / invite offer" class="offer-icon" slot="start" src="assets/icon/offer.svg" />
      <ion-label class="ion-text-wrap">
        <h2 class="f-w-s-b">You’ve got {{ dataService.inviteCodes.length }} offers! </h2>
        <p class="text-ellipsis-ml two-line">Add promo code to avail the offer</p>
      </ion-label>
      <ion-button [disabled]="!masterDataService.isValidAddress"
      slot="end" type="button" fill="clear" 
      color="primary"
      (click)="getOffer()">Add</ion-button>
    </ion-item>
    </ng-container>
    <ng-template #noInviteCodes>
      <div class="padding-horizontal-medium">
        <div class="promo-apply-code">
          <ion-text class="padding-bottom-small">Apply Promo Code</ion-text>
        </div>
        <mbs-promo-code-input (errorData)="applyInviteCode()"></mbs-promo-code-input>
      </div>
    </ng-template>
  </ng-container>
  </ng-template>


