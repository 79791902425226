//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { AuthService } from '@Globals/providers/auth.service';
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
import { BackType } from '@Globals/constants/listing/enums';
import { AddressSelectionType } from '@Globals/constants/registration/enums';
import { LISTING_ROUTES } from '@Globals/constants/listing/routes';
import { REGISTRATION_ROUTES } from '@Globals/constants/registration/routes';
import { PROFILE_ROUTES } from '@Globals/constants/profile/routes';

import { Utilities } from '@Globals/classes/utilities';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-bottom-sheet-header',
  templateUrl: './bottom-sheet-header.component.html',
  styleUrls: ['./bottom-sheet-header.component.scss'],
})
export class BottomSheetHeaderComponent extends DefaultComponent {

  @Input() component;
  @Input() showButtons:boolean = true
  @Input() backType = BackType.Default;
  @Output() closeBottomSheet = new EventEmitter();

  constructor(private navCtrl: NavController,
    private authService: AuthService,
    private dataService: DataService) {
    super();
  }

  mbOnInit() { }

  navigateBack() {
    switch (this.backType) {
      case BackType.Close:
        this.closeBottomSheet.emit();
        break;
      default:
        if ((window.location.href.indexOf(REGISTRATION_ROUTES.GEOLOCATION) > -1 &&
          this.component === AddressSelectionType.SelectSociety && this.authService.isLoggedIn())) {
            if(this.dataService.isBackButtonPressed) {
              this.navCtrl.navigateForward(PROFILE_ROUTES.BASE + PROFILE_ROUTES.CONTACT);
              return;
            }
            if(this.dataService.isUnserviceableFlow) {
              this.dataService.isUnserviceableFlow = false;
              this.dataService.goBackSubjectForUnserviceableFlow.next(!this.dataService.isUnserviceableFlow);
              return;
            }
            this.navCtrl.pop();
        } else if ((window.location.href.indexOf(REGISTRATION_ROUTES.GEOLOCATION) > -1 &&
          this.component === AddressSelectionType.SelectSociety && !Utilities.isApp() && !this.authService.isLoggedIn())) {
          this.navCtrl.navigateBack(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.LOCATION_ACCESS);
        } else if (this.component === AddressSelectionType.ConfirmSociety ||
          this.component === AddressSelectionType.AddAddress) {
          this.navCtrl.navigateBack(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.GEOLOCATION + '/' + AddressSelectionType.SelectSociety);
        } else if (this.component === AddressSelectionType.SelectTower) {
          this.navCtrl.navigateBack(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.GEOLOCATION + '/' + AddressSelectionType.ConfirmSociety);
        } else {
          this.navCtrl.back();
        }
    }

  }

}
