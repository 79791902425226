import { RECURRENCE_FREQUENCIES } from '../enums/product-subscription-order';

export class Recurrence {
    type: number; // was frequency
    frequency: number; // was frequencyMeta

    setProductRecurrence(subscriptionProductOrderApi) {
        if (RECURRENCE_FREQUENCIES[subscriptionProductOrderApi.frequency]) {
            this.type = RECURRENCE_FREQUENCIES[subscriptionProductOrderApi.frequency];
        }
        this.frequency = subscriptionProductOrderApi.frequencyMeta;
        return this;
    }
}
