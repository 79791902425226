

//#region Imports

//#region Angular Imports
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
import { DefaultRoutingModule } from '@Lib/defaults/default-routing.module';
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthGuardService } from '@Globals/providers/auth-guard/auth-guard.service';
//#endregion Providers Imports

//#region Other Imports
import { CORE_ROUTES, LISTING_ROUTES } from '@Globals/constants/listing/routes';
import { REACT_WIDGET_MAP } from '@Globals/constants/react/widget-map.constants';
//#endregion Other Imports

//#endregion Imports

const collectionRoutes: Routes = [
    {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.COLLECTIONPLP + ':id/:leafId/:source',
        loadChildren: () => import('@Main/pages/collection/collection-plp/collection-plp.module').then(m => m.CollectionPlpModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.COLLECTIONPLP + ':id/:leafId',
        loadChildren: () => import('@Main/pages/collection/collection-plp/collection-plp.module').then(m => m.CollectionPlpModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.BANNER_COLLECTION_PLP + ':listId/:bannerId',
        data: { componentName: REACT_WIDGET_MAP.BANNER_PRODUCT_LIST },
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.BANNER_COLLECTION_PLP + ':listId/:bannerId/:bannerIndex',
        data: { componentName: REACT_WIDGET_MAP.BANNER_PRODUCT_LIST},
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.OFFER_COLLECTION_PLP + ':listId/:offerId',
        loadChildren: () => import('@Main/pages/collection/collection-plp/collection-plp.module').then(m => m.CollectionPlpModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.OFFER_COLLECTION_PLP + ':listId/:offerId/:source',
        loadChildren: () => import('@Main/pages/collection/collection-plp/collection-plp.module').then(m => m.CollectionPlpModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.BRAND_PLP + ':selectedBrandIndex/:source',
        loadChildren: () => import('@Main/pages/collection/collection-plp/collection-plp.module').then(m => m.CollectionPlpModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.BRAND_PLP + ':selectedBrandIndex',
        loadChildren: () => import('@Main/pages/collection/collection-plp/collection-plp.module').then(m => m.CollectionPlpModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.PRODUCT_LAUNCH_PLP + ':productLaunchId/:source',
        data: { componentName: REACT_WIDGET_MAP.PRODUCT_LAUNCH_LIST},
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    },
    {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.PRODUCT_LAUNCH_PLP + ':productLaunchId',
        data: { componentName: REACT_WIDGET_MAP.PRODUCT_LAUNCH_LIST},
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.SPONSORED_LISTING + ':sponsoredListId',
        data: { componentName: REACT_WIDGET_MAP.SPONSORED_PRODUCT_LIST},
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.COLLECTION_LISTING + '/' + ':locationType',
        data: { componentName: REACT_WIDGET_MAP.COLLECTION_DASHBOARD },
        loadChildren: () => import("../../../main/widgets/react-renderer/react-renderer.module").then((m) => m.ReactRendererModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.COLLECTION_VIDEO + ':listId/:leafId/:collectionId/:source',
        loadChildren: () => import('@Main/pages/collection/collection-videos/collection-videos.module').then(m => m.CollectionVideosModule),
        canActivate: [AuthGuardService]
    }, {
        path: CORE_ROUTES.LISTING + '/' + LISTING_ROUTES.COLLECTION_VIDEO + ':listId/:leafId/:collectionId',
        loadChildren: () => import('@Main/pages/collection/collection-videos/collection-videos.module').then(m => m.CollectionVideosModule),
        canActivate: [AuthGuardService]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(collectionRoutes)],
    exports: [RouterModule]
})
export class CollectionRoutingModule extends DefaultRoutingModule { }