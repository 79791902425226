//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthService } from '@Globals/providers/auth.service';
import { ProductListManagerService } from '@Features/product-list/providers/product-list-manager.service';
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
//#endregion Providers Imports

//#region Other Imports
import { PRODUCT_DETAILS, EVENT_ARGS, EVENT_NAMES } from '@Globals/constants/listing/constants';
import { DeliveryType, ImageOnDemandType, ProductListType } from '@Globals/constants/listing/enums';
import { RecommendationList } from '@App/shared/interfaces/interfaces';
import { Utilities } from '@Globals/classes/utilities';
//#endregion Other Imports

//#endregion Imports
@Component({
  selector: 'wgt-deals-of-the-day',
  templateUrl: './deals-of-the-day.component.html',
  styleUrls: ['./deals-of-the-day.component.scss'],
  providers: [ProductListManagerService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealsOfTheDayComponent {
  @Input() dealsOfTheDayProductList: RecommendationList;
  deliveryType = DeliveryType;
  currentHoursAndMinutes = Utilities.getCurrentHoursAndMinutes();
  public productDetails = PRODUCT_DETAILS;
  @Input() eventSource?: string;
  @Input() eventData: any;
  cardWidth = 1.29;
  PLPTypeImageOnDemand = ImageOnDemandType.PLP;
  itemType = EVENT_ARGS.DEALS_OF_THE_DAY;
  constructor(
    public authService: AuthService,
    protected plmService: ProductListManagerService,
    private cdRef: ChangeDetectorRef,
    private dataService: DataService,
    private eventsService: EventsService
  ) {}

  ngOnInit(): void {
    this.plmService.setupTomorrowBasket();
    this.plmService.getRunChangeDetForA2B().subscribe(res => {
      this.cdRef.markForCheck();
    });
    this.dealsCardWidth();
  }

  triggerCounterChange(event, product, productIndex) {
    this.plmService.counterChange(this, event, product, productIndex);
  }

  triggerRequestProduct(product, isSourceSeeSimilar = false) {
    return this.plmService.requestProduct(this, product, isSourceSeeSimilar);
  }

  removeCancelledProduct(productId) {
    Utilities.retryAPICall(this.plmService.removeCancelProductRequest(productId, true));
  }

  setToCalendar(product, productIndex) {
    this.plmService.setToCalendar(product, productIndex);
  }

  handleItemClick(product, productIndex) {
    return this.plmService.productDetailsModal(
      product.product_id || product.id,
      productIndex,
      this.productDetails,
      this.eventSource,
      this.eventData,
      this.itemType,
      product
    );
  }

  successHandlerForRecommendedProducts = (productList, product) => {
    this.dealsOfTheDayProductList = productList;
    this.dealsOfTheDayProductList.product = product;
  }
 
  dealsCardWidth() {
    if (window.innerWidth === 375) {
      this.cardWidth = 1.34;
    }
    else if (window.innerWidth > 375 && window.innerWidth <= 393) {
      this.cardWidth = 1.41;
    }
    else if (window.innerWidth > 393 && window.innerWidth <= 414) {
      this.cardWidth = 1.49;
    }
    return this.cardWidth;
  }

  async openProductsVariantModal(product, selectedProductIndex) {
    this.dataService.productVariantInfo.isVariantAddedToCart = false;
    this.dataService.productVariantInfo.latestVariantIndexIntoCart = null;
    const variantProduct = product?._newVariantProducts;

    if (variantProduct && variantProduct.length) {
      const modal = await this.plmService.openProductVariantModel(variantProduct, product, ProductListType.Product_Variant_List, this.eventSource, this.eventData);
      await modal.present();
      let eventAgrs = { source: this.eventSource, product_id: product.id, variant_id: product.variantId };
      this.eventsService.trackEvents(EVENT_NAMES.PRODUCT_VARIANT_CLICKED, { ...eventAgrs });

      modal.onDidDismiss().then(res => {
        if (this.dataService.productVariantInfo.isVariantAddedToCart) {
          this.dealsOfTheDayProductList[selectedProductIndex] = variantProduct[this.dataService.productVariantInfo.latestVariantIndexIntoCart];
          this.dealsOfTheDayProductList[selectedProductIndex]._newVariantProducts = variantProduct;
        }
      });
    }
  }

}