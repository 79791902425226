<ion-grid class="ion-no-padding cat-collection">
  <ion-row *ngFor="let row of data?.table?.rows;let rowIndex=index;">
    <ion-col *ngFor="let column of row.columns">
      <ion-card class="ion-no-margin ion-card-image ion-full-card">
        <div *ngIf="isLottieCollection; else showDefault" class="lottie-wrap" >
          <ng-lottie 
              inViewport
              [inViewportOptions]="{ threshold: [0, 0.65], partial: true }"
              (inViewportAction)="onIntersection($event)"
              class="lottie-animation"
              (animationCreated)="animationCreated($event)"
              (click)="navigateToCollectionList(column)" 
              [options]="lottieConfig">
          </ng-lottie> 
        </div>
        <ng-template #showDefault>
          <div class="img-holder onePagerImg">
            <app-img-on-demand 
              (click)="navigateToCollectionList(column)" 
              [actualImgSrc]="bannerFilePath + column.images.leafBanner.image" 
              [defaultImgSrc]="'assets/img/default.svg'"
              [fileStorageDirectoryName]="dataService.fileStorageDirectories.BANNER" 
              alt_image="img_categoryCollection_{{column.images.leafBanner.image}}"
              [imageName]="column.images.leafBanner.image">
            </app-img-on-demand>
          </div>
        </ng-template>
        <ion-card-header class="ion-no-padding">
          <ion-card-title class="text-ellipsis-ml two-line">{{ data.name }}
          </ion-card-title>
        </ion-card-header>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>