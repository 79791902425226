//#region Imports

//#region Angular Imports
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { AuthService } from '@Globals/providers/auth.service';
//#endregion Providers Imports

//#region Other Imports
import { LISTING_ROUTES, CORE_ROUTES } from '@Globals/constants/listing/routes';
import { REGISTRATION_ROUTES } from '@Globals/constants/registration/routes';
//#endregion Other Imports

//#endregion Imports

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private navCtrl: NavController, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const moduleName = state.url.toString().split('/')[ 1 ];
    const pathName = state.url.toString().split('/')[ 2 ];
    if ((moduleName === CORE_ROUTES.BOARDING || moduleName === CORE_ROUTES.SIGNUP_JOURNEY || pathName === REGISTRATION_ROUTES.LOCATION_ACCESS) 
    && this.authService.isLoggedIn()) {
      this.navCtrl.navigateForward('/' + LISTING_ROUTES.HOME);
      return false;
    } else if ((moduleName === CORE_ROUTES.PAYMENT || moduleName === CORE_ROUTES.USER) && !(this.authService.isLoggedIn())) {
      this.navCtrl.navigateForward(CORE_ROUTES.BOARDING);
      return false;
    } else {
      return true;
    }
  }
}
