import { DefaultComponent } from '@Lib/defaults/default.component';
import { DefaultPage } from '@Lib/defaults/default.page';
import { DefaultModule } from '@Lib/defaults/default.module';
import { DefaultRoutingModule } from '@Lib/defaults/default-routing.module';
import { DefaultDirective } from '@Lib/defaults/default.directive';
import { DefaultService } from '@Lib/defaults/default.service';

import * as HomeModule from '@mb-pages/home';
import { ReactRendererModule } from '@Widgets/react-renderer/react-renderer.module';
import { ReactRendererComponent } from '@Widgets/react-renderer/react-renderer.component';

const CommonImports = [
    DefaultComponent,
    DefaultPage,
    DefaultModule,
    DefaultRoutingModule,
    DefaultDirective,
    DefaultService,

    // React Eager Loadable Packages
    ReactRendererModule,
    ReactRendererComponent,
    HomeModule,
];
export { CommonImports };