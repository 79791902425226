//#region Imports

//#region Angular Imports
import { Pipe, PipeTransform } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
//#endregion Providers Imports

//#region Other Imports
//#endregion Other Imports

//#endregion Imports


@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  constructor(private dataService: DataService) {
  }

  transform(initialValue: Array<object>, conditions: Array<any>, scope?: any, isPLP?: boolean) {
    let newValue = [];
    if (initialValue) {
        conditions = conditions.map((condition) => {
            condition.checkForContainedIn && (condition.value = new RegExp(`${condition.value}`, 'gi'));
            return condition;
        });
        newValue = initialValue.filter((element) => {
            return conditions.every(condition => {
                return condition && condition.checkForContainedIn ? element[condition.key].match(condition.value) : (element[condition.key] === condition.value);
            });
        });
        if (scope) {
            setTimeout(() => {
                scope.isEmptyState = (newValue.length === 0);
            });
        }
    }
    if (isPLP) {
        // if current page is PLP then we are sure conditions has only one object with value key having scid value
        if (!this.dataService.categoryProductsSegregatedOnSubCategories[conditions[0].value]) {
            this.dataService.categoryProductsSegregatedOnSubCategories[conditions[0].value] = newValue;
        }
    }
    return newValue;
}

}
