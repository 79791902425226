<ion-list lines="none" class="ion-no-padding ion-list-image-card ion-list-grid gvc layout-spacer">
<ion-list-header class="ion-list-header" *ngIf="data?.name">
  <ion-row class="ion-justify-content-between full-width ion-align-items-center">
    <div>
      <span class="padding-right-small">{{ data.name }}</span>
      <p *ngIf="data?.description">{{ data.description }}</p>
    </div>
  </ion-row>
</ion-list-header>
<ion-item class="ion-no-padding" mode="md">
  <ion-grid class="collection-grid" [ngClass]="{
    'auto-size-img': data?.customizeCollection == 1,
    'ignore-padding': data?.customizeCollection == 2,
    'auto-size-img-n-ignore-padding': data?.customizeCollection == 3
  }"  #grid>
    <ion-row *ngFor="let row of data?.table?.rows;let rowIndex=index;">
      <ion-col *ngFor="let column of row.columns" [ngStyle]="{
'flex-basis':
  ((grid.el.offsetWidth - 24) * column.width) / 100 + 'px'
}">
        <ion-card class="ion-no-margin ion-card-image ion-full-card">
          <p *ngIf="column.cashBack?.text" class="offer-strip">
            {{ column.cashBack.text }}
          </p>
          <ng-container >
            <ng-lottie 
              inViewport
              [inViewportOptions]="{ threshold: [0, 0.65], partial: true }"
              (inViewportAction)="onIntersection($event)"
              class="lottie-animation"
              (animationCreated)="animationCreated($event)"
              (click)="navigateToCollectionList(column)" 
              [options]="lottieConfig">
          </ng-lottie> 
          </ng-container>
          <ion-card-header class="ion-no-padding" *ngIf="column.title?.text">
            <ion-card-title [ngStyle]="{'color':column.title.color}">{{ column.title.text }}
            </ion-card-title>
          </ion-card-header>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>
</ion-list>