//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { MODAL_IDS } from '@Globals/constants/payment/constants';
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-offer-description',
  templateUrl: './offer-description.component.html',
  styleUrls: [ './offer-description.component.scss' ],
})
export class OfferDescriptionComponent extends DefaultComponent {
  @Input() offerList;
  constructor (private modalCtrl: ModalController) {
    super();
  }

  closeModal() {
    this.modalCtrl.dismiss(null, '', MODAL_IDS.OFFER_DESCRIPTION);
  }
}
