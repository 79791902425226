import { ProductSourceType } from '../enums/product.enums';

export class ProductSource {
    source: string;
    location: string;

    setSourceFromApi(productApi) {
        if (!productApi.source && !productApi.location) {
            return null;
        }

        this.source =
            productApi.source && ProductSourceType?.[productApi.source]
                ? ProductSourceType[productApi.source]
                : '';
        this.location = productApi.location || '';
        return this;
    }
}
