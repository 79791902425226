import { ChangeDetectionStrategy, Component } from '@angular/core'; 
import { EVENT_SOURCES } from '@Globals/constants/listing/constants'; 
import { LISTING_ROUTES } from '@Globals/constants/listing/routes';
import { environment } from 'src/environments/environment'; 
import { BaseCollectionComponent } from '../base/base.component';

@Component({
  selector: 'wgt-mBeyond--widget-block',
  templateUrl: './mBeyond-collection.component.html',
  styleUrls: ['./mBeyond-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class mBeyondCollectionComponent extends BaseCollectionComponent  {
  collectionListingEventSource = EVENT_SOURCES.COLLECTION_LISTING;
  bannerFilePath = environment.bannerPath;
  showDefaultImage = true;

  viewAllCollection() {
    this.dataService.mBeyondAllCollection = this.data;
    this.navCtrl.navigateForward(
        LISTING_ROUTES.BASE +
        LISTING_ROUTES.M_BEYOND_ALL_COLLECTION, { animated: false }
      );
  }
}
