export class ProductSubCategoryInfo {
    __typename = 'ProductSubCategoryInfo';
    id?: number; // was scid
    name?: string;
    type?: string; // was subcategory_type

    setSubCategoryFromApi(productApi) {
        if (!productApi.scid) {
            return null;
        }
        this.id = productApi.scid;
        this.name = productApi.subcategory || '';
        this.type = productApi.subcategory_type || '';
        return this;
    }
}
