//#region Imports

//#region Angular Imports
import { Component, OnInit, Output, EventEmitter, NgZone, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
import { HTTPResponse } from '@ionic-native/http';
import { StatusBar } from '@ionic-native/status-bar/ngx';
//#endregion Ionic Imports

//#region Library Imports
import { Subscription } from 'rxjs';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { AuthService } from '@Globals/providers/auth.service';
import { EventsService } from '@Globals/providers/events.service';
import { SignupService } from '@Globals/providers/signup.service';
//#endregion Providers Imports

//#region Other Imports
import { PAGE_TAGS } from '@Globals/constants/listing/constants';
import { INPUT_VALUE } from '@Globals/constants/registration/constants';
import { EVENT_NAMES, EVENT_SOURCES } from '@Globals/constants/registration/constants';
import { AddressSelectionType, SocietyType } from '@Globals/constants/registration/enums';
import { REGISTRATION_ROUTES } from '@Globals/constants/registration/routes';
import { Tower, UserSignupDetails, Floor } from '@Globals/interfaces/registration/interfaces';

import { Utilities } from '@Globals/classes/utilities';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbpc-select-tower',
  templateUrl: './select-tower.component.html',
  styleUrls: ['./select-tower.component.scss']
})
export class SelectTowerComponent extends DefaultComponent {
  towerList: Array<Tower>;
  filteredTowerList: Array<Tower>;
  isManualInput = false;
  showTowerList: boolean = false;
  showFloorList: boolean = false
  viewTypes = AddressSelectionType;
  isEdit: boolean = false;
  subscriptions: Subscription[] = [];
  societyType = SocietyType;
  hasFocus: boolean = false;
  @Output() selectTower = new EventEmitter();
  @Output() addLocality = new EventEmitter();
  @Output() updateAddress = new EventEmitter();
  @Output() changeSheetState = new EventEmitter();
  userSignupDetails: UserSignupDetails = {
    towerDetails: {},
    localAddressDetails: {}
  };
  floorList: Array<Floor> = [{ name: 'Ground floor', floor_number: 'Ground floor' }, { name: 'Floor 1', floor_number: 'Floor 1' }, { name: 'Floor 2', floor_number: 'Floor 2' }, { name: 'Floor 3', floor_number: 'Floor 3' }];
  public Input = INPUT_VALUE;
  @ViewChild('signupDetailsForm', { static: true }) public detailForm: NgForm;
  @ViewChild('addLocalityAddressForm', { static: true }) public localityDetailForm: NgForm;

  @HostListener('click', ['$event.target']) hideTowerList(event) {
    if (this.showTowerList)
      this.showTowerList = false;
  }
  isEnableTowerRequest:boolean=false;

  constructor(
    public dataService: DataService,
    public authService: AuthService,
    private navCtrl: NavController,
    private zone: NgZone,
    public signupService: SignupService,
    private eventsService: EventsService,
    public masterDataService: MasterDataService,
    private statusBar: StatusBar) {
    super();
  }

  mbOnInit() {
    if (this.authService.isLoggedIn()) {
      this.isEdit = true;
    } else if (this.authService.jioMartData.isJioMartUser && this.authService.userObj && !this.authService.userObj.detailsUpdated) {
      this.userSignupDetails.towerDetails.name = this.authService.userObj?.tower;
      this.userSignupDetails.towerDetails.tower_id = this.authService.userObj?.towerId;
      this.userSignupDetails.houseNo = this.authService.userObj?.addressUnit;
      this.authService.userObj.detailsUpdated = true;
    }
    const selectTower = this.signupService.selectedTower.subscribe((selectedTower)=>{
      if(selectedTower){
        this.setTower(selectedTower);
      }
    })
    this.subscriptions.push(selectTower);
    const resetVerifiedForm = this.signupService.cleanTowerData.subscribe((clean)=>{
      if(clean){
        this.userSignupDetails = {
          towerDetails: {},
          localAddressDetails: {}
        };
      }
    })
    this.subscriptions.push(resetVerifiedForm);
    if(!this.signupService.towers){
      this.fetchTowerList();
    }
    if(this.masterDataService.staticMasterData?.towerRequesthubs && this.masterDataService.staticMasterData?.towerRequesthubs !== ''){
      let hubIds = this.masterDataService.staticMasterData?.towerRequesthubs.split(',');
      if(hubIds.includes('-1') || hubIds.includes(JSON.stringify(this.authService.userData.societyObj.hubId))){
        this.isEnableTowerRequest = true;
      }
    }
  }

  fetchTowerList() {
    const getSocietyTowerSubscription = this.signupService.getSocietyTower(this.authService.userData.societyObj.id)
      .subscribe((response: HTTPResponse) => {
        if (response.data.status) {
          this.towerList = response.data.data.society_towers || [];
          this.filteredTowerList = this.towerList;
          this.signupService.towers = response.data.data.society_towers || [];;
        }
      });
    this.subscriptions.push(getSocietyTowerSubscription);
  }


  onFocus() {
    this.changeSheetState.emit(true);
  }

  closeDropDown() {
    this.showFloorList = false;
  }

  searchTower(searchTerm) {
    this.isManualInput = false;
    if (!(Utilities.isEmptyString(searchTerm))) {
      if (searchTerm !== '') {
        this.showTowerList = true;
        this.filteredTowerList = this.towerList && this.towerList.filter(item => {
          return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        }) || [];
        if (!this.filteredTowerList || this.filteredTowerList.length === 0) {
          this.isManualInput = true;
          this.showTowerList = false;
          this.filteredTowerList = this.towerList;
        }
      } else {
        this.filteredTowerList = this.towerList;
      }
    } else {
      this.userSignupDetails.towerDetails.name = '';
      searchTerm = '';
      this.detailForm && this.detailForm.controls['towerName'].setValue('');
      this.filteredTowerList = this.towerList;
    }
  }

  removeSpace(inputType: string, searchText, event, maxLength: number) {
    if (inputType === this.Input.LOCALITY_HOUSE) {
      if (Utilities.isEmptyString(searchText)) {
        searchText = '';
        this.userSignupDetails.houseNo = '';
        this.localityDetailForm && this.localityDetailForm.controls['unit'].setValue('');
      }
      else if (event.length > maxLength) {
        setTimeout(() => {
          this.userSignupDetails.houseNo = event.substring(0, maxLength);
          this.localityDetailForm && this.localityDetailForm.controls['unit'].setValue(event.substring(0, maxLength));
        }, 50);
      }
    }
    else if (inputType === this.Input.BUILDING_NAME) {
      if (Utilities.isEmptyString(searchText)) {
        searchText = '';
        this.userSignupDetails.localAddressDetails.buildingName = '';
        this.localityDetailForm && this.localityDetailForm.controls['buildingName'].setValue('');
      }
      else if (event.length > maxLength) {
        setTimeout(() => {
          this.userSignupDetails.localAddressDetails.buildingName = event.substring(0, maxLength);
          this.localityDetailForm && this.localityDetailForm.controls['buildingName'].setValue(event.substring(0, maxLength));
        }, 50);
      }
    }
    else if (inputType === this.Input.FLOOR) {
      if (Utilities.isEmptyString(searchText)) {
        searchText = '';
        this.userSignupDetails.localAddressDetails.address_floor = '';
        this.localityDetailForm && this.localityDetailForm.controls['address_floor'].setValue('');
      }
      else if (event.target.value.length > maxLength) {
        setTimeout(() => {
          this.userSignupDetails.localAddressDetails.address_floor = event.target.value.substring(0, maxLength);
          this.localityDetailForm && this.localityDetailForm.controls['address_floor'].setValue(event.target.value.substring(0, maxLength));
        }, 50);
      }
    }
    else if (inputType === this.Input.LANDMARK) {
      if (Utilities.isEmptyString(searchText)) {
        searchText = '';
        this.userSignupDetails.localAddressDetails.landmark = searchText;
        this.localityDetailForm && this.localityDetailForm.controls['landmark'].setValue('');
      }
      else if (event.length > maxLength) {
        setTimeout(() => {
          this.userSignupDetails.localAddressDetails.landmark = event.substring(0, maxLength);
          this.localityDetailForm && this.localityDetailForm.controls['landmark'].setValue(event.substring(0, maxLength));
        }, 50);
      }
    }
    else if (inputType === this.Input.STREET) {
      if (Utilities.isEmptyString(searchText)) {
        searchText = '';
        this.userSignupDetails.localAddressDetails.streetName = searchText;
        this.localityDetailForm && this.localityDetailForm.controls['streetName'].setValue('');
      }
      else if (event.length > maxLength) {
        setTimeout(() => {
          this.userSignupDetails.localAddressDetails.streetName = event.substring(0, maxLength);
          this.localityDetailForm && this.localityDetailForm.controls['streetName'].setValue(event.substring(0, maxLength));
        }, 50);
      }
    }
    else if (inputType === this.Input.HOUSE_NO) {
      if (Utilities.isEmptyString(searchText)) {
        this.userSignupDetails.houseNo = '';
        searchText = '';
        this.detailForm && this.detailForm.controls['houseNo'].setValue('');
      }
      else if (event.length > maxLength) {
        setTimeout(() => {
          this.userSignupDetails.houseNo = event.substring(0, maxLength);
          this.detailForm && this.detailForm.controls['houseNo'].setValue(event.substring(0, maxLength));
        }, 50);
      }
    }
  }



  submitInfo() {
    this.isManualInput && this.eventsService.trackEvents(EVENT_NAMES.TOWER_SEARCHED, { searchText: this.userSignupDetails.towerDetails.name, src: "manual" });
    this.selectTower.emit(this.userSignupDetails);
  }

  submitLocalAddress() {
    if (this.isEdit) {
      this.triggerUpdateAddress();
    } else {
      this.addLocality.emit(this.userSignupDetails);
    }
  }

  triggerUpdateAddress() {
    this.updateAddress.emit(this.userSignupDetails);
  }

  setTower(tower: Tower) {
    this.isManualInput = false;
    this.userSignupDetails.towerDetails.name = tower.name;
    this.userSignupDetails.towerDetails.tower_id = tower.tower_id;
    this.eventsService.trackEvents(EVENT_NAMES.TOWER_SEARCHED, { searchText: tower.name, src: "searched" });
  }

  setFloor(floor: Floor) {
    this.showFloorList = !this.showFloorList;
    this.userSignupDetails.localAddressDetails.address_floor = floor.floor_number;
  }

  editSociety() {
    this.eventsService.trackEvents(EVENT_NAMES.CHANGE_SOCIETY, { src: EVENT_SOURCES.TOWER_SELECTION });
    this.authService.source = EVENT_SOURCES.TOWER_SELECTION;
    this.navCtrl.navigateBack(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.GEOLOCATION + '/' + AddressSelectionType.SelectSociety);
  }
  setFocus() {
    this.hasFocus = true;
    this.changeSheetState.emit(true);
  }
  removeFocus() {
    this.hasFocus = false;
  }

  towerSelections(){
    this.navCtrl.navigateForward(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.SELECT_TOWER);
  }

  mbOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
