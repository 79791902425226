import { Utilities } from '../../../../utilities';

export class BillDetails {
    __typename = 'BillDetails';
    subSavings: string;
    subSavingsAmount: number;
    subTotal: string;
    subTotalAmount: number;
    total: string;
    totalAmount: number;

    setBillDetailsFromApi(basketApi) {
        if (!basketApi.bill_details || !Object.keys(basketApi.bill_details).length) {
            return null;
        }
        this.subSavings = Utilities.number.thousandSeparator(+basketApi.bill_details.sub_savings);
        this.subSavingsAmount = +basketApi.bill_details.sub_savings || 0;

        this.subTotal = Utilities.number.thousandSeparator(+basketApi.bill_details.sub_total);
        this.subTotalAmount = +basketApi.bill_details.sub_total || 0;

        this.total = Utilities.number.thousandSeparator(+basketApi.total);
        this.totalAmount = +basketApi.bill_details.total || 0;

        return this;
    }
}
