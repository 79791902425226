import { Component, OnInit } from '@angular/core';
import { EVENT_NAMES, EVENT_SOURCES } from '@Globals/constants/listing/constants';
import { REGISTRATION_ROUTES } from '@Globals/constants/listing/routes';
import { AddressSelectionType } from '@Globals/constants/registration/enums';
import { AuthService } from '@Globals/providers/auth.service';
import { DataService } from '@Globals/providers/data.service';
import { EventsService } from '@Globals/providers/events.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
import { NavController } from '@ionic/angular';
import { AddressStatus } from '@Globals/constants/registration/constants';
import { DefaultComponent } from '@Lib/defaults/default.component';

@Component({
  selector: 'mbs-address-unverified',
  templateUrl: './address-unverified.component.html',
  styleUrls: ['./address-unverified.component.scss'],
})
export class AddressUnverifiedComponent extends DefaultComponent {
  AddressStatus=AddressStatus;
  constructor(
    public authService: AuthService,
    public dataService: DataService,
    private navCtrl: NavController,
    private _eventsService: EventsService,
    public masterDataService:MasterDataService
  ) {
    super();
   }


  updateAddress(){
    this._eventsService.trackEvents(EVENT_NAMES.UPDATE_ADDRESS, { Source: EVENT_SOURCES.ADDRESS_REJECTED_STRIP });
    this.authService.source = EVENT_SOURCES.CHANGE_ADDRESS;
    this.dataService.draggenedCoordinate = null;
    this.navCtrl.navigateForward(REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.GEOLOCATION + '/' + AddressSelectionType.SelectSociety);
  }

}
