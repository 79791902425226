//#region Imports

//#region Angular Imports
import { ChangeDetectionStrategy, Component } from "@angular/core";
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { BaseCollectionComponent } from "@Widgets/blocks/collection/base/base.component";
//#endregion Component Imports

//#region Providers Imports
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES } from "@Globals/constants/listing/constants";
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: "wgt-video-card-block",
  templateUrl: "./video-card.component.html",
  styleUrls: ["../base/base.component.scss", "./video-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoCardComponent extends BaseCollectionComponent {
  eventSource = EVENT_NAMES.HOME;
}
