export enum OtpComponentType {
    Login,
    Signup
}

export enum AddressSelectionType {
    SelectSociety,
    ConfirmSociety,
    SelectTower,
    AddAddress,
    AddressAddSuccess
}

export enum LocationComponentType {
    LocationAccess,
    DeliveryLocationPage
}

export enum SocietyType {
    HighRise = 1,
    RowHousing = 2,
    Locality = 3
}