<ion-header>
  <ion-toolbar class="user-details-wrapper ios-st-bar-0">
    <ion-text>Share Your Details</ion-text>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon class="icon-close icons-property icon-md" color="dark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="ion-no-padding user-detail content-wrap">
  <ion-img src="assets/img/details.png" alt="shared-details-img"></ion-img>
  <form class="form padding-horizontal-base" #userDetailsForm="ngForm" (ngSubmit)="verifyEmailOTP()">
    <div class="f-body f-body-spaccer">
      <div class="f-group">
        <ion-title>Name*</ion-title>
        <ion-item class="ion-no-padding" lines="none">
          <ion-input
            class="ion-no-padding"
            type="text"
            name="fullName"
            [(ngModel)]="userDetails.fullName"
            placeholder="Enter Your Name"
            required
            ></ion-input>
        </ion-item>
      </div>
      <div class="f-group">
        <ion-title>E-mail*</ion-title>
        <ion-item class="ion-no-padding" lines="none">
          <ion-input
            class="ion-no-padding"
            type="email"
            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
            name="emailId"
            [(ngModel)]="userDetails.emailId"
            ngControl="emailId"
            placeholder="Enter Your Email"
            required
            (ionChange)="onEmailChange()"
          ></ion-input>
        </ion-item>
        <ion-text
          *ngIf="
            userDetailsForm.controls['emailId'] &&
            userDetailsForm.controls['emailId'].status === 'INVALID' &&
            (userDetailsForm.controls['emailId'].dirty ||
              userDetailsForm.controls['emailId'].touched)
          "
          color="danger"
          class="help-text"
        >
          <p *ngIf="userDetailsForm.controls['emailId'].errors?.pattern">
            {{ errorMessage?.EMAIL_INVALID }}
          </p>
        </ion-text>
      </div>

    <!-- OTP section -->
    <div *ngIf="showOtpScreen">
      <mbs-email-otp [isError]="otpError?.isError"  [errorMessage]="otpError?.message" (onInputChange)="onOtpChange($event)" ></mbs-email-otp>
      </div>
    </div>

    <div class="f-footer margin-bottom-medium">
      <ion-button *ngIf="(userDetailsForm.invalid || !showOtpScreen); else submitBtn"
      (click)="submit()"
        type="button"
        fill="solid" 
        size="large" 
        expand="full"
        [disabled]="userDetailsForm.invalid"
        mode="md"
        class="mw-large"
        shape="round"
        >{{ isEmailNeedToVerify ? 'Verify' : 'Submit' }}
      </ion-button>
      <ng-template #submitBtn>
        <ion-button
          type="submit"
          fill="solid" 
          size="large" 
          expand="full"
          [disabled]="!(enteredOtp?.validOtp) || otpError?.isError"
          mode="md"
          class="mw-large"
          shape="round"
          >Submit
        </ion-button>
      </ng-template>
    </div>
  </form>
</div>
