import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ANDROID } from '../../constants/constants';
import { InAppBrowserService } from '@Globals/providers/in_app_browser.service';

@Component({
    selector: 'app-app-update',
    templateUrl: './app-update.component.html',
    styleUrls: [ './app-update.component.scss' ],
})
export class AppUpdateComponent {

    constructor(
        private platform: Platform, 
        private inAppBrowserService: InAppBrowserService
    ) {
    } 

    navigateToStore() {
        if (this.platform.is(ANDROID)) {
            window.open('market://details?id=com.milkbasket.app', '_system', 'location=no');
            // @ts-ignore
            navigator.app.exitApp();
        } else {
            this.inAppBrowserService.open('itms-apps://itunes.apple.com/us/app/milkbasket/id1088165798');
        }
    }
}
