//#region Imports

//#region Angular Imports
import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { DataService } from '@Globals/providers/data.service';
import { MasterDataService } from '@Globals/providers/master-data.service';
//#endregion Providers Imports

//#region Other Imports
import { CAMPAIGN_CASHBACK_TYPE } from '@Globals/constants/listing/constants';
import { MBPASS_OFFER_CATEGORY_TYPE, MBPASS_OFFER_TYPE, MBPASS_STRIP_CONTENT, MBPASS_TYPE } from '@App/listing/constants/listing-constants';

import { SwiperComponent } from 'swiper/angular';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbs-footer-strip',
  templateUrl: './footer-strip.component.html',
  styleUrls: ['./footer-strip.component.scss']
})
export class FooterStripComponent extends DefaultComponent {
  jioCashBackConstruct = this.dataService.firebaseMasterData.jioCashBackConstruct;
  effectiveDeduction: number = 0;
  cashbackType = CAMPAIGN_CASHBACK_TYPE;
  offerType = MBPASS_OFFER_TYPE;
  offerCategoryType = MBPASS_OFFER_CATEGORY_TYPE;
  MBPASS_STRIP_CONTENT = MBPASS_STRIP_CONTENT;
  mbpassType = MBPASS_TYPE;
  private swiperRef: any;
  swiperType:any='';

  @ViewChild('swiper', { static: false }) swiper: SwiperComponent;
  autuPlayPros = {
    disableOnInteraction: false,
    pauseOnMouseEnter:false
  }
  slidesIndex = this.dataService.firebaseMasterData.bottomStripSortOder || [1,2];
  @Input() showOTDFStrip:boolean =true;
  stopScroll:boolean = false;

  constructor(
    public masterDataService: MasterDataService,
    public dataService: DataService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }
  //This code required in future 
  mbOnInit(): void {
    this.dataService.runChangeDetForA2B.subscribe(res => {
      this.cdRef.markForCheck();
    })
    this.dataService.scrollStartOnClick.subscribe(otdfStatus=>{
      if(otdfStatus == 'addOTDF' && this.masterDataService.isShownJioStrip){
        this.swiperRef?.autoplay?.start();
      }

      if(otdfStatus == 'removeOTDF' && this.masterDataService.isShownJioStrip){
        this.swiperRef?.autoplay?.stop();     
      }

    })

  }

  swiperInit(swiper: any) {
    this.swiperRef = swiper;
    setTimeout(()=>{
      if(this.masterDataService.baskets[0]?.is_otdf && this.masterDataService.isShownJioStrip && this.showOTDFStrip){
         this.swiperRef?.autoplay?.start();
         this.swiperRef?.loop?.start();
       } else{
         this.swiperRef?.autoplay?.stop();
         this.swiperRef?.loop?.stop();
       }
    },1000)

  }


  _mbAfterContentChecked(): void {
    this.masterDataService.cashbackCharacterCount();
  }

}
