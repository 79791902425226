<ion-header class="ion-main-header sticky-header">
  <ion-toolbar class="modal-bottom-sheet modal-handle padding-top-medium">
    <div class="d-flex">
    <ion-buttons class="ion-buttons-arrow-back" id="custom-main-heading" slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon class="icon-arrow-back icons-property icon-md" color="dark" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-no-padding ">
      UPI
    </ion-title>
  </div>
    <p class="ion-text-wrap ion-margin-top text-size-lg f-w-n color-dark-300">The Best and secure payment mathod</p>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-no-padding padding-horizontal-medium">
  <form class="form" #upiForm="ngForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="verifyAndPayUpi()">
    <div class="f-body">
      <div class="f-group form-content">
        <ion-label position="floating" class="f-w-b margin-bottom-extra-small">Enter Your UPI ID</ion-label>
        <ion-item class="ion-no-padding" lines="none">
          <ion-input type="text" name="fullName" ngControl="upi" [(ngModel)]="upi" placeholder="ENTER YOUR UPI ID" required pattern="[\w.-]*[@][\w]*">
          </ion-input>
        </ion-item>
        <ion-text *ngIf="error.showError" color="danger" class="help-text">
          {{error.errorMessage}}
        </ion-text>
      </div>
      <div class="checkbox-holder margin-vertical-extra-medium">
        <ion-item lines="none" class="ion-no-padding">
          <ion-checkbox slot="start" checked></ion-checkbox>
          <ion-label class="ion-text-wrap">Securely save your VPA</ion-label>
        </ion-item>
      </div>

    </div>
    <div class="f-footer margin-bottom-extra-big-large">
      <ion-button [disabled]="!upiForm.valid" type="submit" shape="round" size="large" mode="md" expand="block">
        Verify and pay
      </ion-button>
    </div>
  </form>
</ion-content>
