//#region Imports

//#region Angular Imports
import { Injectable } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
//#endregion Ionic Imports

//#region Library Imports
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
//#endregion Component Imports

//#region Providers Imports
import { BaseLayoutService } from '@Layouts/providers/base-layout.service';
//#endregion Providers Imports

//#region Other Imports

export enum SelectTabOption {
  Home,
  Category,
  Basket,
  Wallet,
  More
}
//#endregion Other Imports

//#endregion Imports
@Injectable({
  providedIn: 'root'
})
export class LayoutService extends BaseLayoutService {
  public selectedTabSub: BehaviorSubject<SelectTabOption> = new BehaviorSubject(SelectTabOption.Home);
}
