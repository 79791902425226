//#region Imports

//#region Angular Imports
import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { NavController } from '@ionic/angular';
import { CleverTap } from '@ionic-native/clevertap/ngx';
import { PopoverController } from '@ionic/angular';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { CoachmarkPopoverComponent } from '@Shared/coachmark-popover/coachmark-popover.component';
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from '@Globals/providers/master-data.service';
import { DataService } from '@Globals/providers/data.service';
import { AuthService } from '@Globals/providers/auth.service';
import { EventsService } from '@Globals/providers/events.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, EVENT_SOURCES, STORAGE_KEYS } from '@Globals/constants/listing/constants';
import { JOURNEY_TYPE } from '@Globals/constants/listing/enums';
import { LISTING_ROUTES, CORE_ROUTES } from '@Globals/constants/listing/routes';
import { REGISTRATION_ROUTES } from '@Globals/constants/registration/routes';
import { PAYMENT_ROUTES } from '@Globals/constants/payment/routes';

import { Utilities } from '@Globals/classes/utilities';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  SEARCH = LISTING_ROUTES.SEARCH;
  YOUR_CART = LISTING_ROUTES.YOUR_CART;
  @Input() isDrawerNeeded: boolean;
  @Input() showBackButton = true;
  @Input() showSearchBar = false;
  basketCount: number;
  @Input() isSearchAndCartNeeded = false;
  @Input() isCalendarNeeded = false;
  @Input() isShadowAllowed = false;
  @Input() showCrossButton = false;
  @Input() likeAndShareData = { isLiked: null, metaData: null, componentType: null };
  @Input() likeCount;
  @Output() searchAction = new EventEmitter();
  @Output() likeUnlikeCollection = new EventEmitter();
  @Output() bottomSheetToggle = new EventEmitter();
  journeyType = JOURNEY_TYPE.Calendar;
  element: HTMLElement;
  takeToCalendar = true;
  isApp = Utilities.isApp();
  @Input() updateBack = false;
  @Output() overwriteBack = new EventEmitter();
  @Input() isJioBAckButtonNeeded = false;
  @Input() bottomSheetVisiblity = false;

  popover;
  showTermsAndCondition: boolean = false;
  constructor(
    private navCtrl: NavController,
    public masterDataService: MasterDataService,
    public dataService: DataService,
    public authService: AuthService,
    private eventsService: EventsService,
    private cleverTap: CleverTap,
    public popoverController: PopoverController,) { }

  navigateBack() {
    if (
      window.location.href.indexOf(LISTING_ROUTES.SUBCATEGORY) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.CATEGORY) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.COLLECTION_VIDEO) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.COLLECTION_LISTING) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.RECURRING_ORDERS) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.ACCOUNT_HISTORY) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.COLLECTIONPLP) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.YOUR_CART) > -1 ||
      window.location.href.indexOf(REGISTRATION_ROUTES.FRAUD_ALERT) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.SUBSCRIBE_TO_SAVE) > -1 ||
      window.location.href.endsWith(PAYMENT_ROUTES.SUPER_CHARGE) || window.location.href.indexOf(PAYMENT_ROUTES.SHOPPING_CREDITS) > -1
    ) {
      this.navCtrl.pop();
    } else if (
      window.location.href.indexOf(REGISTRATION_ROUTES.SIGN_UP_DETAILS) ===
      -1 &&
      window.location.href.indexOf(REGISTRATION_ROUTES.SELECT_SOCIETY) === -1
    ) {
      this.navCtrl.pop();
    } else if (
      window.location.href.indexOf(REGISTRATION_ROUTES.SELECT_SOCIETY) > -1
    ) {
      this.navCtrl.navigateBack(CORE_ROUTES.BOARDING);
    } else {
      this.navCtrl.navigateBack(
        REGISTRATION_ROUTES.BASE + REGISTRATION_ROUTES.SELECT_SOCIETY
      );
    }
  }

  componentBack() {
    this.overwriteBack.emit();
  }

  showCoachMark() {
    if (this.isCalendarNeeded && this.dataService.calendarCoachmarkObject === null || (this.dataService.calendarCoachmarkObject &&
      !this.dataService.calendarCoachmarkObject.hasOwnProperty('discoveryCoachMark'))) {
      this.takeToCalendar = false;
      this.element = document.getElementById('cal-icon') as HTMLElement;
      this.element && this.element.click();
    }
  }

  callMethod($event) {
    if (this.takeToCalendar) {
      this.openCalendar();
    } else {
      this.presentPopover($event);
      this.takeToCalendar = true;
      setTimeout(
        () => {
          this.popoverController?.dismiss({}, '', 'calendar');
          this.updateCoachMarkInStorage();
        }, 8000);
    }
  }

  navigateTo(mod, isSearchBar = false) {
    let navigateUrl = '';
    switch (mod) {
      case this.SEARCH:
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.SEARCH;
        const eventArgs = {
          Clicked: true,
          Flag: isSearchBar ? 1 : 0
        };
        this.eventsService.trackEvents(EVENT_NAMES.SEARCHED, eventArgs);
        break;
      case this.YOUR_CART:
        this.trackBasketViewed();
        navigateUrl = LISTING_ROUTES.BASE + LISTING_ROUTES.YOUR_CART;
    }
    this.navCtrl.navigateForward(navigateUrl, { animated: false });
  }

  trackBasketViewed() {
    let eventArgs = {};
    if (
      window.location.href.indexOf(LISTING_ROUTES.HOME) > -1 ||
      window.location.href.indexOf(LISTING_ROUTES.SUBCATEGORY) > -1
    ) {
      eventArgs = {
        'User Id': this.masterDataService.masterData?.user?.id,
        Source:
          window.location.href.indexOf(LISTING_ROUTES.HOME) > -1
            ? EVENT_SOURCES.HOMEPAGE
            : EVENT_SOURCES.CATEGORY
      };
    }
    this.eventsService.trackEvents(EVENT_NAMES.BASKET_TOMORROW, eventArgs);
  }

  async presentPopover($event) {
    this.popover = await this.popoverController.create({
      component: CoachmarkPopoverComponent,
      cssClass: 'popover-custom',
      event: $event,
      mode: 'ios',
      translucent: true,
      id: 'calendar'
    });
    return await this.popover.present();
  }

  openCalendar() {
    this.eventsService.trackEvents(EVENT_NAMES.CALENDAR_MY_CALENDAR, { source: 'Homepage' });
    this.navCtrl.navigateForward(LISTING_ROUTES.BASE + LISTING_ROUTES.RECURRING_ORDERS);
  }


  likeCollection() {
    this.likeAndShareData.isLiked = !this.likeAndShareData.isLiked;
    this.likeUnlikeCollection.emit(this.likeAndShareData.isLiked ? 1 : 0);
    this.updateStorage();
  }

  updateStorage() {
    this.dataService.likedCollectionsList = this.dataService.likedCollectionsList || [];
    if (this.likeAndShareData.isLiked) {
      this.likeCount += 1;
      this.dataService.likedCollectionsList.push(this.likeAndShareData.metaData.id);
    } else {
      this.likeCount -= 1;
      const leafIndex = this.dataService.likedCollectionsList.indexOf(this.likeAndShareData.metaData.id);
      if (leafIndex > -1) {
        this.dataService.likedCollectionsList.splice(leafIndex, 1);
      }
    }
    localStorage.setItem(STORAGE_KEYS.LIKED_COLLECTIONS, JSON.stringify(this.dataService.likedCollectionsList));
  }

  updateCoachMarkInStorage() {
    if (this.dataService.calendarCoachmarkObject === null) {
      this.dataService.calendarCoachmarkObject = {};
    }
    this.dataService.calendarCoachmarkObject.discoveryCoachMark = true;
    localStorage.setItem(STORAGE_KEYS.CALENDAR_COACHMARK_OBJECT, JSON.stringify(this.dataService.calendarCoachmarkObject));
  }

  navtoTerms() {
    this.bottomSheetToggle.emit(true);
  }
}
