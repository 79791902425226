export class UserFlag {
    __typename = 'UserFlag';
    showMembership: boolean;
    tncAccepted: boolean; // was acceptTnc
    autoRenewMembership: boolean; // was arm
    isEmailVerified: boolean; // was iev
    noCheckoutVisted: number; // was ncv

    setUserFlagsFromApi(userApi) {
        try {
            this.showMembership = !!userApi?.membershipVisibility;
            this.tncAccepted = !!userApi?.cinfo?.acceptTnc;
            this.autoRenewMembership = !!userApi?.cinfo?.arm;
            this.isEmailVerified = !!userApi?.cinfo?.iev;
            this.noCheckoutVisted = userApi?.cinfo?.ncv;
            return this;
        } catch {
            return null;
        }
    }
}
