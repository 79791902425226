//#region Imports

//#region Angular Imports
import { Component, EventEmitter, Input, Output } from '@angular/core';
//#endregion Angular Imports

//#region Ionic Imports
import { HTTPResponse } from '@ionic-native/http';
//#endregion Ionic Imports

//#region Library Imports
//#endregion Library Imports

//#region Module Imports
//#endregion Module Imports

//#region Component Imports
import { DefaultComponent } from '@Lib/defaults/default.component';
//#endregion Component Imports

//#region Providers Imports
import { MasterDataService } from '@Globals/providers/master-data.service';
import { EventsService } from '@Globals/providers/events.service';
import { AuthService } from '@Globals/providers/auth.service';
import { DataService } from '@Globals/providers/data.service';
import { HttpService } from '@Globals/providers/http.service';
//#endregion Providers Imports

//#region Other Imports
import { EVENT_NAMES, SIGNUP_ADD_ADDRESS_PAGEVIEW, PAGE_TAGS } from '@Globals/constants/listing/constants';
import { BackType } from '@Globals/constants/listing/enums';
import { City } from '@Globals/interfaces/listing/interfaces';
import { REGISTRATION_APIS } from '@Globals/constants/registration/apis';
//#endregion Other Imports

//#endregion Imports

@Component({
  selector: 'mbf-select-city',
  templateUrl: './select-city.component.html',
  styleUrls: ['./select-city.component.scss']
})
export class SelectCityComponent extends DefaultComponent {
  constructor(
    public masterDataService: MasterDataService,
    public authService: AuthService,
    private eventsService: EventsService,
    public dataService: DataService,
    private httpService: HttpService, 
  ) {
    super();
  }
  @Input() serviceable: boolean;
  @Output() onSheetClose = new EventEmitter();
  
  cityList: Array<City> = [];
  filteredCityList: Array<City> = [];
  backAction = BackType.Close;
  searchText = '';
  searchData: any = {
    placeholder: 'Search City',
    text: ''
  };
  mbOnInit() {
    this.getCityList();
    this.eventsService.trackEvents(PAGE_TAGS.SELECT_CITY, {});
    document.getElementsByTagName('body')[0].classList.add('city-height');
  }

  selectCity(selectedCity: City) {
    this.eventsService.trackEvents(
      EVENT_NAMES.SIGN_UP_CITY,
      {
        city_name: selectedCity.name,
        source: SIGNUP_ADD_ADDRESS_PAGEVIEW
      },
      true
    );
    this.back(selectedCity);

  }

  back(selectedCity) {
    this.onSheetClose.emit(selectedCity);
  }

  searchCity(value) {
    this.searchText = value;
    if (this.searchText !== '') {
      this.filteredCityList = this.cityList.length && this.cityList.filter(item => {
        return item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
      }) || []
    } else {
      this.filteredCityList = this.cityList;
    }
  }

  getCityList() {
    this.httpService.get(REGISTRATION_APIS.NON_SERVICABLE_CITY_LIST).subscribe((response:HTTPResponse) => {
      if(response.status) {
        this.cityList = response.data || [];
        this.filteredCityList = this.cityList;
      }
    });
  }

  mbOnDestroy() {
    document.getElementsByTagName('body')[0].classList.add('city-height');
  }

}
